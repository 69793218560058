// Imports
import Vue from 'vue';
import { KycClientModule } from '~/store/modules/kyc-client/kyc-client';
import { HistoryInterfaces } from '~/store/modules/kyc-client/interfaces/history-interfaces';
import configsMixin from '~/components/mixins/configs-mixin';
import { ELeadsKycFieldStatus } from '~/settings/enums/leads-kyc-field-status.enum';
import { IDefaultConfig } from '~/store/modules/configs/interfaces/configs-interface';
import { EAppHistoryKycIcon, EAppHistoryKycColorIcon } from '~/components/parts/app-history-kyc/app-history-kyc.enums';
import { IDataOfFieldValues } from '~/settings/interfaces/kyc-interfaces';

export default Vue.extend({
    mixins: [configsMixin],
    props: {
        isButtonDate: {
            type: Boolean,
            default: false,
        },
        isShowCustomValue: {
            type: Boolean,
            default: true,
        },
        isSecondColumn: {
            type: Boolean,
            default: true,
        },
    },
    data () {
        return {
            noValue: '-',
        };
    },
    computed: {
        historyList (): HistoryInterfaces[] {
            return KycClientModule.getHistoryField;
        }
    },
    methods: {
        getDate (history): string {
            const date = history?.created_at;
            return (date && `${date.date}, ${date.time}`) || this.noValue;
        },
        statusName (statusId: ELeadsKycFieldStatus): string {
            return this.getConfigFieldValueStatuses
                .find((status: IDefaultConfig) => status.id === statusId).name;
        },
        getDataItemHistory (index: number): IDataOfFieldValues {
            return this.historyList[index].data;
        },
        getPreviosDataItemHistory (index: number): IDataOfFieldValues {
            let previosValueIndex = index - 1;

            for (let i = previosValueIndex; i >= 0; i--) {
                if (this.historyList[i].status.id === ELeadsKycFieldStatus.approved) {
                    previosValueIndex = i;
                    break;
                }
            }

            return this.historyList[previosValueIndex].data;
        },
        getValue ({ display, custom }: IDataOfFieldValues): string {
            return `${display} ${custom && this.isShowCustomValue ? custom : ''}`;
        },
        setProTraderFormText (history): void {
            this.$emit('setProTraderFormText', history.data[0].custom);
        },
        getIcon (id: ELeadsKycFieldStatus): string {
            return EAppHistoryKycIcon[ELeadsKycFieldStatus[id]];
        },
        getColorIcon (id: ELeadsKycFieldStatus): string {
            return EAppHistoryKycColorIcon[ELeadsKycFieldStatus[id]];
        }
    }
});
