import Vue from 'vue';
import Vuex from 'vuex';
import leads, { moduleName as leadsModuleName } from './modules/leads';
import clientKyc, { moduleName as clientKycModuleName } from '~/store/modules/client-kyc/client-kyc';
import leadDetails, { moduleName as leadDetailsModuleName } from './modules/leadDetails';
import notifications, { moduleName as notificationsModuleName } from './modules/notifications';
import usersManagement, { moduleName as usersManagementModuleName } from './modules/configs/usersManagement';
import campaign, { moduleName as campaignModuleName } from './modules/configs/campaign';
import affiliates, { moduleName as affiliatesModuleName } from './modules/configs/affiliates';
import auth from './plugins/auth';
import Configs from '~/store/modules/configs/configs';
import usersList, { moduleName as usersListModuleName }  from '~/store/modules/configs/usersList';

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        [leadsModuleName] : leads,
        [clientKycModuleName] : clientKyc,
        [leadDetailsModuleName]: leadDetails,
        [notificationsModuleName] : notifications,
        [usersManagementModuleName]: usersManagement,
        [campaignModuleName]: campaign,
        [usersListModuleName]: usersList,
        [affiliatesModuleName]: affiliates,
        Configs,
    },
    plugins : [
        auth,
    ],
});

// todo dev only
window.__store = store;

export default store;
