import Vue from 'vue';
export default Vue.extend({
    props: {
        addClass: {
            type: String,
        },
        active: {
            type: Boolean,
        },
        isShowDefaultIcon: {
            type: Boolean,
            required: false,
            default: true,
        }
    },
    data () {
        return {
            className: 'search-block',
            isFocus: false,
        };
    },
    computed: {
        allBindings () {
            return { ...this.$props, ...this.$attrs };
        },
        iconColor (): string {
            return this.isFocus || this.active ? 'blue' : 'grey';
        },
    },
    methods: {
        clickOnBody (event: any) {
            const target = event.target;
            if (target.classList.contains === this.className || target.closest(`.${this.className}`)) {
                console.log();
            } else {
                this.clickOutside();
            }
        },
        setFocus () {
            this.isFocus = true;
            this.$nextTick(() => {
                this.$refs.appInput.$el.querySelector('.b-input__text-field').focus();
            });
            setTimeout(() => {
                this.$root.$el.addEventListener('click', this.clickOnBody);
            }, 100);
        },
        clickOutside () { // use by ref in leads-id-filter.ts -> handlerSelectMethod
            this.isFocus = false;
            setTimeout(() => {
                this.$root.$el.removeEventListener('click', this.clickOnBody);
            }, 100);
        },
        handleInputEvent (event: string) {
            this.$emit('input', event);
        },
        clickButton (event) {
            this.$emit('clickButton', event);
        },
        keyPress (event) {
            this.$emit('keyPress', event);
        },
        handleClean () {
            this.$emit('input', null);
        }
    }
});
