import Vue from 'vue';
import rowItemPropsMixin from '@/js/components/tables/mixins/table-cells-mixins/row-item-props-mixin';
import { EConfigType } from '~/store/modules/configs/enums/config-type.enum';

export default Vue.extend({
    mixins: [
        rowItemPropsMixin,
    ],
    computed: {
        isValueObject (): boolean {
            return this.cell?.value && typeof this.cell?.value === EConfigType.object;
        },
        getFlag (): string {
            return this.isValueObject ? this.cell.value?.A2 || this.cell.value?.a2 : this.cell.value;
        },
        getName (): string {
            return this.isValueObject ? this.cell.value?.name : this.cell.value;
        },
    }
});
