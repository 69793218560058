import Vue from 'vue';
import modalLayout from '~/components/modals/root-modal-layout/root-modal-layout.vue';
import modalsMixin from '~/components/mixins/modals';
import currencyHelper from '~/components/mixins/currency-helper';
import configsMixin from '~/components/mixins/configs-mixin';
import leadDetailsMixin from '~/components/mixins/leadDetailsMixin';
import { ICreateAdditionalAccount } from './create-additional-account.interfaces';
import { LeadAccountsModule } from '~/store/modules/lead-accounts/lead-accounts';
import { CreateAdditionalAccountEnum } from './enum/create-additional-account.enum';
import { IConfig } from '~/settings/interfaces/config-default-interfaces';
export default Vue.extend({
    components: {
        modalLayout
    },
    mixins: [
        modalsMixin,
        currencyHelper,
        configsMixin,
        leadDetailsMixin,
    ],
    data (): ICreateAdditionalAccount {
        return {
            accountType: null,
            tradingType: null,
            currency: null,
        };
    },
    computed: {
        accountTypes (): IConfig[] {
            return this.getConfigAccountModeTypes.filter(item => item.id < CreateAdditionalAccountEnum.realDemo);
        }
    },
    methods: {
        async onSubmitCreateAddAccountForm (): Promise<void> {
            const isValid = await this.$refs.observer.validate();
            if (isValid) {
                const payload = {
                    [CreateAdditionalAccountEnum.profitMethodTypeId]: this.tradingType.id,
                    [CreateAdditionalAccountEnum.modeTypeId]: this.accountType.id,
                    [CreateAdditionalAccountEnum.currencyId]: this.currency.id,
                };
                await LeadAccountsModule.createAccount(payload);
                this.closeModal();
                await this.handleFetchDetails();
            }
        }
    }
});
