import { notificationsTotalInterface } from '~/store/modules/notifications/notifications-interfaces';
import { ENotificationsFinanceSoundType } from './enums/notifications-finance-sound-services.enum';
import { NotificationsSoundServices } from './notifications-sound-services';

class NotificationsFinanceSoundServices extends NotificationsSoundServices {

    private soundApprovedDeposit: string = `${this.soundPath}/approve_deposit.mp3`;
    private soundWithdrawal: string = `${this.soundPath}/withdrawal.mp3`;
    private soundFailedDeposit: string = `${this.soundPath}/failed_deposit.mp3`;

    public handlerNotification (payload: notificationsTotalInterface) {
        const statuses = payload.additional;

        for (const status in statuses) {
            switch (status) {
            case ENotificationsFinanceSoundType.has_approved_deposit:
                if (statuses[status]) this.playSoundApprovedDeposit();
                break;
            case ENotificationsFinanceSoundType.has_failed_deposit:
                if (statuses[status]) this.playSoundFailedDeposit();
                break;
            case ENotificationsFinanceSoundType.has_withdrawal:
                if (statuses[status]) this.playSoundWithdrawal();
                break;
            }
        }
    }

    private playSoundApprovedDeposit () {
        this.soundConstructor(this.soundApprovedDeposit);
    }

    private playSoundFailedDeposit () {
        this.soundConstructor(this.soundFailedDeposit);
    }

    private playSoundWithdrawal () {
        this.soundConstructor(this.soundWithdrawal);
    }
}

export const notificationsFinanceSoundServices = new NotificationsFinanceSoundServices();
