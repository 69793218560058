import Vue from 'vue';
import { AffiliatesReportsModule } from '~/store/modules/affiliates/affiliates-reports';
import rootTable from '~/components/tables/root-table/root-table.vue';
import navbarAffiliates from '~/components/affiliates/affiliates-nav/affiliates-nav.vue';
import { ETableType } from '~/components/tables/enums/table-type.enum';
import appMoreOptions from '~/components/parts/app-more-options/app-more-options.vue';
import { affiliatesType, EAffiliateReportsTableType, EReportsType } from '~/settings/configs/affiliates-type';
import { ITableHeaderColumn } from '~/components/tables/interfaces/table-header-column.interfaces';
import { ITableCell } from '~/components/tables/interfaces/table-cell-interfaces';
import { handlerRoutesQuery } from '~/helpers/handler-routes-query';
import { parseDate } from '~/helpers/common';
import { IConfigValue } from '~/settings/interfaces/config-default-interfaces';
import { ICalendarSelectDate } from '~/components/calendar-select/interfaces/calendar-select-interfaces';
import { ETableFilterKeyAffiliatesReports, ETableFilterKeyCampaigns } from '~/components/tables/enums/table-filter-key.enum';
import { ECalendarQuery } from '~/components/calendar-select/enums/calendar-query.enum';
import { affiliatesReportsCampaignInsideTableHeader } from '~/components/tables/_headers/affiliates-reports-campaign-inside';
import { tableParser } from '~/helpers/table-parser';
import { ITableClickRow } from '~/components/tables/interfaces/table-interfaces';
// TODO
import emptyCell from '~/components/tables/table-cells/empty-cell/empty-cell.vue';
import plainText from '~/components/tables/table-cells/plain-text/plain-text.vue';
import plainTextTooltip from '~/components/tables/table-cells/plain-text-tooltip/plain-text-tooltip.vue';
import plainSumPercentage from '~/components/tables/table-cells/plain-sum-percentage/plain-sum-percentage.vue';
import plainSum from '~/components/tables/table-cells/plain-sum/plain-sum.vue';
import { setQuaryPerPage } from '~/components/tables/helpers/setQuaryPerPage';
import { ETableFilterKeyPagination } from '~/components/tables/enums/table-filter-key.enum';

export default Vue.extend({
    components: {
        rootTable,
        navbarAffiliates,
        appMoreOptions,
        emptyCell,
        plainText,
        plainSum,
        plainTextTooltip,
        plainSumPercentage,
    },
    data () {
        return {
            affiliatesTypeSelect: affiliatesType[0],
            affiliatesTypeOptions: affiliatesType,
            selectedDate: {
                [ETableFilterKeyAffiliatesReports.startDate]: null,
                [ETableFilterKeyAffiliatesReports.endDate]: null,
            },
            selectedCalendarType: null,
            formatDate: 'YYYY-MM-DD',
        };
    },
    computed: {
        reportsTableHeader (): ITableHeaderColumn[] {
            return this.tableType === ETableType.affiliatesReports ?
                AffiliatesReportsModule.getReportsHeader :
                AffiliatesReportsModule.getReportsHeaderCampaign;
        },
        reportsTableRows (): ITableCell[] {
            return this.tableType === ETableType.affiliatesReports ?
                AffiliatesReportsModule.getReportsRows :
                AffiliatesReportsModule.getReportsRowsCampaign;
        },
        tableType (): string {
            return EAffiliateReportsTableType[this.affiliatesTypeSelect?.id];
        },
        getAffiliatesReportIsLoaded (): boolean {
            return AffiliatesReportsModule.getAffiliatesReportIsLoaded;
        },
        getReportCampaigns (): ITableCell[] {
            return tableParser(affiliatesReportsCampaignInsideTableHeader, AffiliatesReportsModule.getAffiliatesReportsCampaigns);
        },
        isByAffiliateTable (): boolean {
            return this.affiliatesTypeSelect?.id === EAffiliateReportsTableType['affiliates-reports'];
        }
    },
    mounted (): void {
        this.loadReportsAll();
        this.checkCurrentTable();
    },
    methods: {
        handleQuery () {
            for (const i in this.selectedDate) {
                if (!this.selectedDate[i]) {
                    handlerRoutesQuery(i, null);
                }
            }
        },
        clearDate (): void {
            this.selectedDate = {
                [ETableFilterKeyAffiliatesReports.startDate]: null,
                [ETableFilterKeyAffiliatesReports.endDate]: null,
                [ECalendarQuery.calendarType]: null,
            };
            this.handleQuery();
        },
        calendarSelect (date: ICalendarSelectDate) {
            this.selectedDate = {
                [ETableFilterKeyAffiliatesReports.startDate]: date.formattedDate.startDate,
                [ETableFilterKeyAffiliatesReports.endDate]: date.formattedDate.endDate,
                [ECalendarQuery.calendarType]: `${date.calendar_type}`,
            };
            this.handleQuery();
            this.loadReports();
        },
        startAll () {
            const currentDay = new Date();
            const firstDay = new Date(2015, 0, 1);

            return {
                start_date: firstDay,
                finish_date: currentDay,
            };
        },
        checkCurrentTable () {
            const query = this.$route.query;

            if (query?.type) {
                this.affiliatesTypeSelect = affiliatesType.find(item => item.value === query.type);
            }

            this.selectedCalendarType = query?.calendar_type;
        },
        generateTypeForQuery (event: IConfigValue) {
            const query = this.$route.query;
            const type = event?.value || query?.type || this.affiliatesTypeSelect.value;

            const startQuery = {
                ...query,
                type: type,
            };

            return startQuery;
        },
        generateDateForQuery () {
            const query = this.$route.query;
            const date = this.startAll();

            const start_date = this.selectedDate.start_date ||
                query?.start_date ||
                parseDate(date.start_date, this.formatDate)
                    .format(this.formatDate);

            const finish_date = this.selectedDate.finish_date ||
                query?.finish_date ||
                parseDate(date.finish_date, this.formatDate)
                    .format(this.formatDate);

            return {
                start_date: start_date,
                finish_date: finish_date,
            };
        },
        generateCalendarTypeForQuery () {
            const query = this.$route.query;
            return this.selectedDate.calendar_type || query?.calendar_type;
        },
        generateQueryForAllAffiliates (event: IConfigValue) {
            const startQuery = this.generateTypeForQuery(event);

            for (const i in startQuery) {
                handlerRoutesQuery(i, startQuery[i]);
            }
        },
        generateQuery (event: IConfigValue) {
            const type = this.generateTypeForQuery(event);
            const dates = this.generateDateForQuery();
            const calendarType = this.generateCalendarTypeForQuery();

            const startQuery = {
                ...type,
                calendar_type: calendarType,
                ...dates
            };

            for (const i in startQuery) {
                handlerRoutesQuery(i, startQuery[i]);
            }
        },
        handlerLoadReports (event: IConfigValue = null) {
            setQuaryPerPage(this.tableType);

            const query = this.$route.query;
            const type = event?.value || query?.type || this.affiliatesTypeSelect.value;
            const date = this.generateDateForQuery();

            const newQuery = {
                ...query,
                ...date
            };

            switch (type) {
            case EReportsType.campaign:
                AffiliatesReportsModule.loadCampaignsReports(newQuery);
                break;
            case EReportsType.affiliate:
            default:
                AffiliatesReportsModule.loadAffiliatesReports(newQuery);
                break;
            }
        },
        clearRowsTable (): void {
            this.$refs.rootTable.deleteRowItemSlot();
            this.$refs.rootTable.resetAllRow();
        },
        loadReportsAllOnCloseAfterReset (event: IConfigValue = null) {
            this.clearDate();
            this.handlerLoadReports(event);
        },
        loadReportsAllByChangeBy (event: IConfigValue) {
            handlerRoutesQuery(ETableFilterKeyPagination.perPage, null);
            handlerRoutesQuery(ETableFilterKeyPagination.page, null);
            this.loadReportsAll(event);
            this.clearRowsTable();
        },
        loadReportsAll (event: IConfigValue = null) {
            this.generateQueryForAllAffiliates(event);
            this.handlerLoadReports(event);
        },
        loadReports (event: IConfigValue = null) {
            this.generateQuery(event);
            this.handlerLoadReports(event);
        },
        async clickRow (event: ITableClickRow) {
            if (!this.isByAffiliateTable) return;

            const campaignsCell = event.row
                .find(cell => cell.name === ETableFilterKeyAffiliatesReports.is_campaigns_reports);
            if (
                this.$refs.rootTable.getRowItemSlot !== event.index &&
                !!campaignsCell?.value
            ) {
                this.$refs.rootTable.selectRow({
                    rowId: campaignsCell.ID,
                    multiple: false,
                });
                await this.loadCampaignsReportsById(campaignsCell.ID);
                this.$refs.rootTable.setRowItemSlot(event.index);
                this.$nextTick(() => {
                    this.scrollToLastRow();
                });
            } else {
                this.clearRowsTable();
            }
        },
        async loadCampaignsReportsById (id: number): Promise<void> {
            const date = this.generateDateForQuery();
            await AffiliatesReportsModule.loadCampaignsReportsById({
                ...date,
                [ETableFilterKeyCampaigns.affiliate_id]: id,
            });
        },
        scrollToLastRow () {
            const lastRow = this.$refs.rootTable.$el.querySelector('.b-main-table__row--shadow-bottom');
            const rect = lastRow?.getBoundingClientRect();
            if (rect && (rect.bottom + rect.height) > document.documentElement.clientHeight) {
                this.$refs.rootTable.scrollTo({
                    top: rect.bottom,
                });
            }
        },
        isFirstChild (index: number) {
            return index === 0;
        },
        isLastChild (index: number) {
            return index === this.getReportCampaigns.length - 1;
        },
    }
});
