import Vue from 'vue';
import './settings/settings';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import * as Vuebar from 'vuebar';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import router from './router';
import App from './App.vue';
import store from './store';
import VueScrollbar from 'vue2-scrollbar';
import VueSlider from 'vue-slider-component';
import AppMultiselect from './components/parts/app-multiselect/app-multiselect.vue';
import AppInput from './components/parts/app-input/app-input.vue';
import AppButton from './components/parts/app-button/app-button.vue';
import AppCheckbox from '~/components/parts/app-checkbox/app-checkbox.vue';
import NotificationsCount from '~/components/parts/notifications-count/notifications-count.vue';
import Notifications from 'vue-notification';
import TheWrapper from '~/components/theWrapper.vue';
import 'vue-slider-component/theme/default.css';
import rootTable from '~/components/tables/root-table/root-table.vue';
import '~/helpers/vee-validate-rules';
import CalendarSelect from '~/components/calendar-select/calendar-select.vue';
import SearchTable from '~/components/parts/search/search.vue';
import SearchLive from '~/components/parts/search-live/search-live.vue';
import AppAccountStatusSelect from '~/components/parts/app-account-status-select/app-account-status-select.vue';
import AppIcon from '~/components/parts/icon-svg/icon-svg.vue';
import AppCopyrights from '~/components/parts/app-copyrights/app-copyrights.vue';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/swiper-bundle.css';
import VueDragscroll from 'vue-dragscroll';
import MaterialIcon from '~/components/parts/material-icon/material-icon.vue';
import VModal from 'vue-js-modal';

Vue.use(VModal, {
    dialog: true,
    dynamic: true,
    injectModalsContainer: true,
    clickToClose: false,
});
Vue.use(VueDragscroll);
// @ts-ignore
Vue.use(Vuebar);
Vue.use(VueAwesomeSwiper);
Vue.component('VueScrollbar', VueScrollbar);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.component('AppMultiselect', AppMultiselect);
Vue.component('AppInput', AppInput);
Vue.component('AppButton', AppButton);
Vue.component('AppCheckbox', AppCheckbox);
Vue.component('TheWrapper', TheWrapper);
Vue.component('VueSlider', VueSlider);
Vue.component('CalendarSelect', CalendarSelect);
Vue.component('NotificationsCount', NotificationsCount);
Vue.component('SearchTable', SearchTable);
Vue.component('SearchLive', SearchLive);
Vue.component('AppAccountStatusSelect', AppAccountStatusSelect);
Vue.component('AppIcon', AppIcon);
Vue.component('MaterialIcon', MaterialIcon);
Vue.component('AppCopyrights', AppCopyrights);

Vue.component('RootTable', rootTable);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.use(Notifications);
Vue.directive('click-outside', {
    bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
            // here I check that click was outside the el and his children
            if (!(el == event.target || el.contains(event.target))) {
                // and if it did, call method provided in attribute value
                vnode.context[binding.expression](event);
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
    },
    unbind: function (el) {
        // @ts-ignore
        document.body.removeEventListener('click', el.clickOutsideEvent);
    },
});
Vue.config.productionTip = false;

// https://vuejs.org/v2/style-guide/#Base-component-names-strongly-recommended
function baseComponentRegistration () {
    const requireComponent = require.context('./components', true, /^Base[A-Z]/);
    requireComponent.keys().forEach(function (fileName) {
        let baseComponentConfig = requireComponent(fileName);
        baseComponentConfig = baseComponentConfig.default || baseComponentConfig;
        const baseComponentName = baseComponentConfig.name || (
            fileName
                .replace(/^.+\//, '')
                .replace(/\.\w+$/, '')
        );
        Vue.component(baseComponentName, baseComponentConfig);
    });
}
baseComponentRegistration();

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
