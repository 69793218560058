import { Module, VuexModule, getModule, Action } from 'vuex-module-decorators';
import store from '~/store/index';
import Rest from '~/helpers/Rest';
import {
    IPostSendEmail,
    IResponseSendEmail
} from '~/store/modules/marketing/marketing-interfaces';
import { urlMarketingSendEmail } from '~/settings/ajax-urls';

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: 'MarketingSendEmail',
})

class MarketingSendEmail extends VuexModule {
    @Action
    async sendEmail (payload: IPostSendEmail): Promise<IResponseSendEmail> {
        const { data } = await Rest.POST(urlMarketingSendEmail, payload)
            .then(Rest.middleThen)
            .catch(Rest.simpleCatch);

        return data;
    }
}

export const MarketingSendEmailModule = getModule(MarketingSendEmail);
