import Vue from 'vue';
import click2call from '~/components/parts/click2call/click2call.vue';
import togglePhoneMixin from '~/components/mixins/togglePhoneMixin';
import { LeadDetailsEnums } from '~/components/leadsItem/LeadDetails/enums/lead-details.enums';
import rowItemPropsMixin from '@/js/components/tables/mixins/table-cells-mixins/row-item-props-mixin';
import { COLUMN_PHONE } from '~/store/modules/_columnNames';

export default Vue.extend({
    components: {
        click2call
    },
    mixins: [
        togglePhoneMixin,
        rowItemPropsMixin,
    ],
    data () {
        return {
            typeLeadDetails: LeadDetailsEnums
        };
    },
    computed: {
        isFirst (): boolean {
            return this.cell.name === COLUMN_PHONE;
        }
    },
});
