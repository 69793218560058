// Imports
import Vue from 'vue';
import AccountingCommissionDatabase from './accounting-commission-database-table/accounting-commission-database-table.vue';
import Pagination from '../../parts/pagination/pagination.vue';

// Exports
export default Vue.extend({
    components: {
        'accounting-commission-database-table': AccountingCommissionDatabase,
        'pagination': Pagination,
    }
});
