/*
* used in leads.js and in clientKycMixin.ts
* */

export const TARGET_MARKET_CLASSIFICATION_ID = 'target_market_classification_id';

export const COLUMN_ID = 'id';
export const COLUMN_EMAIL = 'email';
export const COLUMN_NAME = 'name';
export const COLUMN_COMPANY_NAME = 'company_name';
export const COLUMN_INCORPORATION_COUNTRY_ID = 'incorporation_country_id';
export const COLUMN_BUSINESS_ADDRESS = 'business_address';
export const COLUMN_BUSINESS_PHONE = 'business_phone';
export const COLUMN_BUSINESS_PHONE_COUNTRY_ID = 'business_phone_country_id';
export const COLUMN_PHONE = 'phone_mobile';
export const COLUMN_SECOND_PHONE = 'phone_second';
export const COLUMN_SECOND_PHONE_FULL = 'phone_second_full';
export const COLUMN_MODE_TYPE_ID = 'mode_type_ids';
export const COLUMN_TRADING_TYPE = 'trading_type';
export const COLUMN_MT_SUBJECT_TYPE = 'mt_subject_type';
export const COLUMN_LAST_DEPOSIT_AMOUNT = 'ltd_amount';

export const COLUMN_RISK_ID = 'risk_id';
export const COLUMN_RESIDENCE_COUNTRY_ID = 'residence_country_id';
export const COLUMN_REGISTERED_AT = 'registered_at';
export const COLUMN_TIMEZONE_ID = 'timezone_id';
export const COLUMN_LEGAL_SUBJECT = 'legal_subject';
export const COLUMN_LEGAL_SUBJECT_ID = 'legal_subject_id';
export const COLUMN_VERIFY_STATUS_ID = 'verify_status_id';
export const COLUMN_VERIFY_STATUS_CRYPTO = 'verify_status_crypto';
export const COLUMN_VERIFY_STATUS_CRYPTO_ID = 'verify_status_crypto_id';
export const COLUMN_PROFESSIONAL_LEVEL_ID = 'professional_level_id';
export const COLUMN_LEAD_STATUS_ID = 'lead_status_id';
export const COLUMN_COMPLICATEDNESS_ID = 'complicatedness_id';
export const COLUMN_LIVE_ACCOUNTS_COUNT = 'live_accounts_count';
export const COLUMN_DEMO_ACCOUNTS_COUNT = 'demo_accounts_count';
export const COLUMN_FIRST_LIVE_DEPOSIT_DATE = 'ftd_at';
export const COLUMN_FIRST_LIVE_DEPOSIT_AMOUNT = 'ftd_amount';
export const COLUMN_LAST_LIVE_DEPOSIT_DATE = 'ltd_at';
export const COLUMN_LAST_LIVE_DEPOSIT_AMOUNT = 'ltd_amount';
export const COLUMN_TOTAL_LIVE_DEPOSITS = 'total_deposit_amount';
export const COLUMN_LAST_LIVE_WITHDRAWAL_DATE = 'ltw_at';
export const COLUMN_LAST_LIVE_WITHDRAWAL_AMOUNT = 'ltw_amount';
export const COLUMN_PENDING_WITHDRAWALS = 'pending_withdrawals';
export const COLUMN_EQUITY = 'equity';
export const COLUMN_LANGUAGE_A2 = 'language_a2';
export const COLUMN_CAMPAIGN = 'campaign';
export const COLUMN_AFFILIATE = 'affiliate';
export const COLUMN_OPEN_ORDERS = 'open_orders';
export const COLUMN_FREE_MARGIN = 'free_margin';
export const COLUMN_CUSTOMER_SUPPORT_USER_FULL_NAME = 'customer_support_user_full_name';
export const COLUMN_SALES_USER_FULL_NAME = 'sales_user_full_name';
export const COLUMN_RETENTION_USER_FULL_NAME = 'retention_user_full_name';
export const COLUMN_DEPOSITED = 'deposited';
export const COLUMN_TARGET_MARKET = 'target_market';
export const COLUMN_PAYMENT_TYPE = 'payment_type';
export const COLUMN_ACTIVITY = 'active';
export const COLUMN_CLIENT_PROFITABILITY = 'client_profitability';
export const COLUMN_PEPS = 'political_exposed';
export const COLUMN_ANNUAL_INVESTMENT = 'annual_investment';
export const COLUMN_ANNUAL_INCOME = 'annual_income';
export const COLUMN_NET_WEALTH = 'net_wealth';
export const COLUMN_SAVINGS_INVESTMENTS = 'savings_investments';
export const COLUMN_TEST = 'is_test';

export const COLUMN_TITLE = 'title_id';
export const COLUMN_FNAME = 'fname';
export const COLUMN_MNAME = 'mname';
export const COLUMN_LNAME = 'lname';
export const COLUMN_BIRTH_DATE = 'birth_date';
export const COLUMN_BIRTH_COUNTRY_ID = 'birth_country_id';
export const COLUMN_CITIZENSHIP_IDS = 'citizenship_country_ids';
export const COLUMN_CITIZENSHIP_EQUAL_RESIDENCE = 'citizenship_equal_residence';
export const COLUMN_PHONE_MOBILE_COUNTRY = 'phone_mobile_country_id';
export const COLUMN_PHONE_MOBILE = 'phone_mobile';
export const COLUMN_PHONE_SECOND_COUNTRY = 'phone_second_country';
export const COLUMN_PHONE_SECOND_COUNTRY_ID = 'phone_second_country_id';
export const COLUMN_PHONE_SECOND = 'phone_second';
export const COLUMN_RESIDENCE_PLACE = 'residence_place';
export const COLUMN_RESIDENCE_ADDRESS = 'residence_address';
export const COLUMN_RESIDENCE_ZIP = 'residence_zip';
export const COLUMN_HEARD_FROM_ID = 'heard_from_id';
export const COLUMN_IS_FATCA = 'is_fatca';
export const COLUMN_USA_TIN = 'usa_tin';
export const COLUMN_TINS = 'tins';
export const COLUMN_IS_TIN_AVAILABLE = 'is_tin_available';
export const COLUMN_IS_TIN_AVAILABLE_STRING = 'is_tin_available_string';
export const COLUMN_TIN_ABSENCE_REASON_ID = 'tin_absence_reason_id';
export const COLUMN_TIN_ABSENCE_REASON_VALUE = 'tin_absence_reason_value';
export const COLUMN_POLITICAL_EXPOSED = 'political_exposed';
export const COLUMN_IDENTITY_NUMBER = 'identity_number';
export const COLUMN_DRIVING_LICENSE = 'driving_license';
export const COLUMN_PASSPORT = 'passport';
export const COLUMN_ASSESSMENT_QUESTIONS = 'assessmentQuestions';
export const COLUMN_INVESTMENT_QUESTIONS = 'investmentQuestions';
export const COLUMN_FINANCIAL_QUESTIONS = 'financialQuestions';
export const COLUMN_EMPLOYMENT_VALUE = 'employment_value';
export const COLUMN_INDUSTRY_ID = 'industry_id';
export const COLUMN_INDUSTRY_VALUE = 'industry_value';
export const COLUMN_FUND_TYPE_IS_BANK_TRANSFER = 'fund_type_is_bank_transfer';
export const COLUMN_FUND_TYPE_BANK_NAME = 'fund_type_bank_name';
export const COLUMN_FUND_TYPE_BANK_LOCATION = 'fund_type_bank_location';
export const COLUMN_FUND_TYPE_IS_CARD = 'fund_type_is_card';
export const COLUMN_FUND_TYPE_IS_E_WALLETS = 'fund_type_is_e_wallets';
export const COLUMN_FUNDING_METHODS = 'funding_methods';
export const FUNDING_E_WALLETS = 'E-Wallets';
export const FUNDING_CARD = 'Credit Card / Debit Card';
export const FUNDING_BANK = 'Bank Transfer';
export const COLUMN_ANSWERS = 'answers';
export const COLUMN_COMPLETE_QUESTIONNAIRE_AT = 'complete_questionnaire_at';
export const COLUMN_VERIFIED_STATUS_BY_USER = 'verified_status_by_user';
export const COLUMN_AQM_ON_BEHALF_OF = 'aqm_on_behalf_of';
export const COLUMN_AQM_ON_BEHALF_OF_CAPACITY = 'aqm_on_behalf_of_capacity';
export const COLUMN_AQM_SUBMIT_STATEMENT = 'aqm_submit_statement';
export const COLUMN_CHECKBOX_CONSENTS = 'checkbox_consents';
export const COLUMN_AWARENESS_MESSAGES = 'awareness_messages';
export const COLUMN_PRO_REQUEST_TYPE_ID = 'professional_level_request_type_id';
export const COLUMN_COMPLETED_QUESTIONNAIRE = 'completed_questionnaire';
export const AS_COLUMN_TITLE = 'title_id';
export const AS_COLUMN_COMPLICATEDNESS = 'complicatedness';
export const AS_COLUMN_BIRTH_COUNTRY_ID = 'birth_country_id';
export const AS_COLUMN_RESIDENCE_COUNTRY_ID = 'residence_country_id';
export const AS_COLUMN_PHONE_MOBILE_COUNTRY = 'phone_mobile_country_id';
export const AS_COLUMN_PHONE_SECOND_COUNTRY = 'phone_second_country';
export const AS_COLUMN_PHONE_SECOND_COUNTRY_ID = 'phone_second_country_id';
export const AS_COLUMN_TIN_ABSENCE_REASON = 'tin_absence_reason_id';
export const COLUMN_EMPLOYMENT_STATUS_ID = 'employment_status_id';
export const AS_COLUMN_INDUSTRY_ID = 'industry_id';
export const AS_COLUMN_FUND_SOURCE_ID = 'fund_source_id';
export const AS_COLUMN_VERIFY_STATUS = 'verify_status';
export const AS_COLUMN_LEAD_STATUS = 'lead_status';
export const AS_COLUMN_RISK = 'risk';
export const AS_COLUMN_LEGAL_SUBJECT = 'legal_subject';
export const AS_COLUMN_LEGAL_SUBJECT_ID = 'legal_subject_id';
export const AS_COLUMN_HEARD_FROM_ID = 'heard_from_id';
export const AS_COLUMN_PROFESSIONAL_LEVEL = 'professional_level';
export const GO_PRO_ANSWERS = 'go_pro_answers';
export const AS_COLUMN_APPROVED_DEPOSIT_AMOUNT = 'approved_deposit_amount';
export const COLUMN_CAMPAIGN_NAME = 'campaign_name';

export const COLUMN_VERIFY_STATUS_REJECT_REASON = 'verify_status_reject_reason';
export const COLUMN_IP_ADDRESS = 'ip_address';

export const AS_COLUMN_COLUMN_MAP = {
    [AS_COLUMN_TITLE] : COLUMN_TITLE,
    [AS_COLUMN_COMPLICATEDNESS] : COLUMN_COMPLICATEDNESS_ID,
    [AS_COLUMN_BIRTH_COUNTRY_ID] : COLUMN_BIRTH_COUNTRY_ID,
    [AS_COLUMN_RESIDENCE_COUNTRY_ID] : AS_COLUMN_RESIDENCE_COUNTRY_ID,
    [COLUMN_RESIDENCE_COUNTRY_ID] : COLUMN_RESIDENCE_COUNTRY_ID,
    [AS_COLUMN_PHONE_MOBILE_COUNTRY] : COLUMN_PHONE_MOBILE_COUNTRY,
    [AS_COLUMN_PHONE_SECOND_COUNTRY] : COLUMN_PHONE_SECOND_COUNTRY,
    [AS_COLUMN_PHONE_SECOND_COUNTRY_ID] : AS_COLUMN_PHONE_SECOND_COUNTRY_ID,
    [AS_COLUMN_TIN_ABSENCE_REASON] : COLUMN_TIN_ABSENCE_REASON_ID,
    [COLUMN_EMPLOYMENT_STATUS_ID] : COLUMN_EMPLOYMENT_STATUS_ID,
    [AS_COLUMN_INDUSTRY_ID] : COLUMN_INDUSTRY_ID,
    [AS_COLUMN_FUND_SOURCE_ID] : AS_COLUMN_FUND_SOURCE_ID,
    [AS_COLUMN_VERIFY_STATUS] : COLUMN_VERIFY_STATUS_ID,
    [AS_COLUMN_LEAD_STATUS] : COLUMN_LEAD_STATUS_ID,
    [AS_COLUMN_RISK] : COLUMN_RISK_ID,
    [AS_COLUMN_LEGAL_SUBJECT] : COLUMN_LEGAL_SUBJECT,
    [AS_COLUMN_HEARD_FROM_ID] : COLUMN_HEARD_FROM_ID,
    [AS_COLUMN_PROFESSIONAL_LEVEL] : COLUMN_PROFESSIONAL_LEVEL_ID,
    [COLUMN_INCORPORATION_COUNTRY_ID]: COLUMN_INCORPORATION_COUNTRY_ID,
    [COLUMN_BUSINESS_PHONE_COUNTRY_ID]: COLUMN_BUSINESS_PHONE_COUNTRY_ID,
};

export const DATE_COLUMNS = [
    COLUMN_BIRTH_DATE,
];

export const BOOLEAN_COLUMNS = [
    COLUMN_POLITICAL_EXPOSED,
    COLUMN_AQM_ON_BEHALF_OF,
    COLUMN_FUND_TYPE_IS_BANK_TRANSFER,
    COLUMN_FUND_TYPE_IS_CARD,
    COLUMN_FUND_TYPE_IS_E_WALLETS,
];

export const LEADS_COLUMN_IDS = [
    COLUMN_ID,
    COLUMN_RESIDENCE_COUNTRY_ID,
    COLUMN_TIMEZONE_ID,
    COLUMN_NAME,
    COLUMN_REGISTERED_AT,
    COLUMN_PHONE,
    COLUMN_PHONE_SECOND,
    COLUMN_EMAIL,
    COLUMN_MODE_TYPE_ID,
    COLUMN_TRADING_TYPE,
    COLUMN_LEGAL_SUBJECT,
    COLUMN_LEGAL_SUBJECT_ID,
    COLUMN_VERIFY_STATUS_ID,
    COLUMN_PROFESSIONAL_LEVEL_ID,
    COLUMN_LEAD_STATUS_ID,
    COLUMN_COMPLICATEDNESS_ID,
    COLUMN_LIVE_ACCOUNTS_COUNT,
    COLUMN_DEMO_ACCOUNTS_COUNT,
    COLUMN_FIRST_LIVE_DEPOSIT_DATE,
    COLUMN_FIRST_LIVE_DEPOSIT_AMOUNT,
    COLUMN_LAST_LIVE_DEPOSIT_DATE,
    COLUMN_LAST_LIVE_DEPOSIT_AMOUNT,
    COLUMN_TOTAL_LIVE_DEPOSITS,
    COLUMN_LAST_LIVE_WITHDRAWAL_DATE,
    COLUMN_LAST_LIVE_WITHDRAWAL_AMOUNT,
    COLUMN_PENDING_WITHDRAWALS,
    COLUMN_EQUITY,
    COLUMN_LANGUAGE_A2,
    COLUMN_CAMPAIGN,
    COLUMN_AFFILIATE,
    COLUMN_OPEN_ORDERS,
    COLUMN_FREE_MARGIN,
    COLUMN_CUSTOMER_SUPPORT_USER_FULL_NAME,
    COLUMN_SALES_USER_FULL_NAME,
    COLUMN_RETENTION_USER_FULL_NAME,
    COLUMN_DEPOSITED,
];

export const KYC_COLUMN_IDS = [
    COLUMN_TITLE,
    COLUMN_FNAME,
    COLUMN_MNAME,
    COLUMN_LNAME,
    COLUMN_BIRTH_DATE,
    AS_COLUMN_RESIDENCE_COUNTRY_ID,
    COLUMN_BIRTH_COUNTRY_ID,
    COLUMN_CITIZENSHIP_IDS,
    COLUMN_CITIZENSHIP_EQUAL_RESIDENCE,
    COLUMN_EMAIL,
    COLUMN_PHONE_MOBILE_COUNTRY,
    COLUMN_PHONE_MOBILE,
    COLUMN_PHONE_SECOND_COUNTRY,
    COLUMN_PHONE_SECOND,
    COLUMN_RESIDENCE_PLACE,
    COLUMN_RESIDENCE_ADDRESS,
    COLUMN_RESIDENCE_ZIP,
    COLUMN_HEARD_FROM_ID,
    COLUMN_USA_TIN,
    COLUMN_TINS,
    COLUMN_IDENTITY_NUMBER,
    COLUMN_DRIVING_LICENSE,
    COLUMN_PASSPORT,
];

export const COLUMN_TYPE_INPUT = 'input';
export const COLUMN_TYPE_SELECT = 'select';
export const COLUMN_TYPE_DATE_RANGE = 'date_range';

export const BOOLEAN_YES_ID = 1;
export const BOOLEAN_NO_ID = 2;

export const asColumnToColumn = (asColumn) => {
    return AS_COLUMN_COLUMN_MAP[asColumn] || asColumn;
};

export const getBooleanOptions = () => {
    return [
        {
            id : BOOLEAN_YES_ID,
            name : 'Yes',
            toString () {
                return this.name;
            }
        },
        {
            id : BOOLEAN_NO_ID,
            name : 'No',
            toString () {
                return this.name;
            }
        }
    ];
};

export const booleanColumnToOption = (bool) => { // return -> IBooleanOption
    return bool ? getBooleanOptions()[0] : getBooleanOptions()[1];
};

export const getBooleanFromOption = option => {
    return option.id === BOOLEAN_YES_ID;
};
