import { render, staticRenderFns } from "./calendar-events.html?vue&type=template&id=b12592e8&lang=html&"
import script from "./calendar-events.ts?vue&type=script&lang=ts&"
export * from "./calendar-events.ts?vue&type=script&lang=ts&"
import style0 from "./calendar-events.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports