import {
    Module,
    VuexModule,
    Mutation,
    Action,
    getModule,
} from 'vuex-module-decorators';
import store from '~/store/index';
import Rest from '~/helpers/Rest';
import {
    urlDashboardPayments,
    urlDashboardClients,
    urlDashboardPlatform,
    urlDashboardVolume,
    urlDashboardEvents,
} from '~/settings/ajax-urls';
import {
    dashboardPaymentsFormatted,
    dashboardClientsFormatted,
    dashboardPlatformFormatted
} from './helpers/dashboard-payments-formatted';
import {
    IDashboardWidgetsItems,
    IDashboardSummaryStatisticFormattedObject,
    IDashboardSummaryVolume,
    IDashboardEvents,
    IDashboardSummaryPlatformFormatted,
} from '~/store/modules/dashboard/interfaces';

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: 'dashboard',
})
class Dashboard extends VuexModule {
    dashboardPayments: IDashboardSummaryStatisticFormattedObject = {};
    dashboardClients: IDashboardSummaryStatisticFormattedObject = {};
    dashboardPlatform: IDashboardSummaryPlatformFormatted = {};
    dashboardVolume: IDashboardSummaryVolume = {
        trading_volume: [],
    };
    dashboardEvents: IDashboardEvents[] = [];
    dashboardClientsWidgets: IDashboardWidgetsItems = {};

    get getDashboardPayments (): IDashboardSummaryStatisticFormattedObject {
        return this.dashboardPayments;
    }

    get getDashboardClients (): IDashboardSummaryStatisticFormattedObject {
        return this.dashboardClients;
    }

    get getDashboardPlatform (): IDashboardSummaryPlatformFormatted {
        return this.dashboardPlatform;
    }

    get getDashboardVolume (): IDashboardSummaryVolume {
        return this.dashboardVolume;
    }

    get getDashboardEvents (): IDashboardEvents[] {
        return this.dashboardEvents;
    }

    get getDashboardClientsWidgets (): IDashboardWidgetsItems {
        return this.dashboardClientsWidgets;
    }

    @Mutation
    setDashboardPayments (payload: IDashboardSummaryStatisticFormattedObject) {
        this.dashboardPayments = payload;
    }

    @Mutation
    setDashboardClients (payload: IDashboardSummaryStatisticFormattedObject) {
        this.dashboardClients = payload;
    }

    @Mutation
    setDashboardPlatform (payload: IDashboardSummaryPlatformFormatted) {
        this.dashboardPlatform = payload;
    }

    @Mutation
    setDashboardVolume (payload: IDashboardSummaryVolume) {
        this.dashboardVolume = payload;
    }

    @Mutation
    setDashboardEvents (payload: IDashboardEvents[]) {
        this.dashboardEvents = payload;
    }

    @Mutation
    setDashboardClientsWidgets (payload: IDashboardWidgetsItems) {
        this.dashboardClientsWidgets = payload;
    }

    @Action
    async loadDashboardPayments (query): Promise<void> {
        const { data } = await Rest.GET(urlDashboardPayments(query))
            .then(Rest.middleThen)
            .catch(error => {
                Rest.simpleCatch(error);
            });

        const formatted = dashboardPaymentsFormatted(data);
        this.setDashboardPayments(formatted);
    }

    @Action
    async loadDashboardClients (query): Promise<void> {
        const { data } = await Rest.GET(urlDashboardClients(query))
            .then(Rest.middleThen)
            .catch(error => {
                Rest.simpleCatch(error);
            });
        const { summaryBlocks, widgets } = dashboardClientsFormatted(data);

        this.setDashboardClients(summaryBlocks);
        this.setDashboardClientsWidgets(widgets);
    }

    @Action
    async loadDashboardPlatform (query): Promise<void> {
        const { data } = await Rest.GET(urlDashboardPlatform(query))
            .then(Rest.middleThen)
            .catch(error => {
                Rest.simpleCatch(error);
            });

        this.setDashboardPlatform(dashboardPlatformFormatted(data));
    }

    @Action
    async loadDashboardVolume (query): Promise<void> {
        const { data } = await Rest.GET(urlDashboardVolume(query))
            .then(Rest.middleThen)
            .catch(error => {
                Rest.simpleCatch(error);
            });
        this.setDashboardVolume(data);
    }

    @Action
    async loadDashboardEvents (query): Promise<void> {
        const { data } = await Rest.GET(urlDashboardEvents(query))
            .then(Rest.middleThen)
            .catch(error => {
                Rest.simpleCatch(error);
            });
        this.setDashboardEvents(data);
    }
}
export const DashboardModule = getModule(Dashboard);
