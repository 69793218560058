import stdModelMixin from './stdModelMixin';
import fieldTypesMixin from './fieldTypesMixin';


export default {
    mixins : [
        stdModelMixin,
        fieldTypesMixin,
    ],
};
