import Vue from 'vue';
import modalLayout from '~/components/modals/root-modal-layout/root-modal-layout.vue';

import modalsMixin from '~/components/mixins/modals';
import { ClientKYCFinancesModule } from '~/store/modules/client-kyc-finances/client-kyc-finances';
import { EModalProps } from '~/helpers/modal-services/enums/modal-props.enum';

export default Vue.extend({
    components: {
        modalLayout
    },
    mixins: [modalsMixin],
    data () {
        return {
            reason: '',
            isReasonError: false,
        };
    },
    methods: {
        onInput () {
            this.isReasonError = false;
        },
        ok () {
            if (!this.reason && this.isReason) {
                this.isReasonError = true;

                return;
            }
            this.closeModal();
            this.$attrs['modal-props'].ok(this.reason);
        },
        handleChange () {
            this.$attrs['modal-props'][EModalProps.reason] = this.reason;
            ClientKYCFinancesModule.setReasonText(this.reason);
        },
    }
});
