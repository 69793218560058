import { ITableHeaderColumn } from '~/components/tables/interfaces/table-header-column.interfaces';

export const defaultTableHeader: ITableHeaderColumn[] = [
    {
        id: 1,
        name: '',
        value: '',
        cellData: '',
        cellType: 'empty-cell',
    },
    {
        id: 2,
        name: '',
        value: '',
        cellData: '',
        cellType: 'empty-cell',
    },
    {
        id: 3,
        name: '',
        value: '',
        cellData: '',
        cellType: 'empty-cell',
    },
    {
        id: 4,
        name: '',
        value: '',
        cellData: '',
        cellType: 'empty-cell',
    },
    {
        id: 5,
        name: '',
        value: '',
        cellData: '',
        cellType: 'empty-cell',
    },
    {
        id: 6,
        name: '',
        value: '',
        cellData: '',
        cellType: 'empty-cell',
    },
    {
        id: 7,
        name: '',
        value: '',
        cellData: '',
        cellType: 'empty-cell',
    },
    {
        id: 8,
        name: '',
        value: '',
        cellData: '',
        cellType: 'empty-cell',
    },
    {
        id: 9,
        name: '',
        value: '',
        cellData: '',
        cellType: 'empty-cell',
    },
    {
        id: 10,
        name: '',
        value: '',
        cellData: '',
        cellType: 'empty-cell',
        cellClass: 'w-100'
    },
];
