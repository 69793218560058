import Vue from 'vue';
import Pagination from '~/components/parts/pagination/pagination.vue';

import { AccountingManagersModules } from '~/store/modules/accounting/accounting-managers';

export default Vue.extend({
    components: {
        'pagination': Pagination
    },
    computed: {
        getAccountingManagersTableHeader (): Array<object> {
            return AccountingManagersModules.getterHeader;
        },
        getAccountingManagersTable (): Array<object> {
            return AccountingManagersModules.getterTable;
        }
    },
    created (): void {
        this.setTable();
    },
    methods: {
        setTable (): void {
            AccountingManagersModules.actionSetManagersTable();
        }
    }
});
