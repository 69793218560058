import { Module, VuexModule, getModule, Mutation, Action }  from 'vuex-module-decorators';
import store from '~/store/index';
import Rest from '~/helpers/Rest';
import { INotificationAffiliate } from '~/store/modules/notifications/notifications-interfaces';
import { urlNotificationAffiliates } from '~/settings/ajax-urls';
import { EStatusNotification } from '~/components/notifications/enums/notifications.enum';
import { IPagination } from '~/settings/interfaces/pagination-interfaces';

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: 'NotificationsAffiliates',
})

class NotificationsAffiliates extends VuexModule {
    notifications: INotificationAffiliate[] = [];
    notificationsPagination: IPagination = {
        current_page: null,
        from: null,
        last_page: null,
        per_page: null,
        to: null,
        total: null,
    };

    get readNotifications (): INotificationAffiliate[] {
        return Object.values(this.notifications)
            .filter(item => item.status.id === EStatusNotification.Read);
    }

    get unReadNotifications (): INotificationAffiliate[] {
        return Object.values(this.notifications)
            .filter(item => item.status.id === EStatusNotification.Pending ||
                item.status.id === EStatusNotification.New);
    }

    get getNotificationsPagination (): IPagination {
        return this.notificationsPagination;
    }

    @Mutation
    set (payload: INotificationAffiliate[]) {
        this.notifications = payload;
    }

    @Mutation
    setNotificationsPagination (payload: IPagination) {
        this.notificationsPagination = payload;
    }

    @Action ({ commit: 'set' })
    async load (query: object): Promise<INotificationAffiliate[]> {
        const { data, meta } = await Rest.GET(urlNotificationAffiliates(query))
            .then(Rest.middleThen)
            .catch(Rest.simpleCatch);

        this.setNotificationsPagination(meta);

        return data;
    }
}

export const NotificationsAffiliatesModule = getModule(NotificationsAffiliates);
