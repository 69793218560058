export enum UploadedDocumentsUploaderEnum {
    typeId = 'type_id',
    expireAt = 'expired_at',
    document = 'documents[]',
    emailable = 'is_emailable'
}

export enum DocumentTypesEnum {
    first = 1,
    passportId = 4
}
