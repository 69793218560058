import Vue from 'vue';
// @ts-ignore
import modalLayout from '~/components/modals/root-modal-layout/root-modal-layout';

import modalsMixin from '~/components/mixins/modals';

import Rest from '~/helpers/Rest';
import { urlImportHeader, urlLeadsImport } from '~/settings/ajax-urls';


export default Vue.extend({
    components: {
        modalLayout
    },
    mixins: [modalsMixin],
    data () {
        return {
            isUploaded: false,
            salesRep: '',
            campaign: '',
            affiliate: '',
            selectedFromExcelIndex: null,
            selectedExistingIndex: null,
            pairs: [],
            errors: {},
            error: '',
            options: {
                salesRep: [
                    {
                        name: 'First Rep',
                        value: 1
                    },
                    {
                        name: 'Second Rep',
                        value: 2
                    },
                    {
                        name: 'Third Rep',
                        value: 3
                    },
                ],

                affiliate: [
                    // {
                    //     name: 'First Affiliate',
                    //     value: 1
                    // },
                    // {
                    //     name: 'Second Affiliate',
                    //     value: 2
                    // },
                    // {
                    //     name: 'Third Affiliate',
                    //     value: 3
                    // },
                ],
                campaign: [
                    {
                        name: 'First Campaign',
                        value: 1
                    },
                    {
                        name: 'Second Campaign',
                        value: 2
                    },
                    {
                        name: 'Third Campaign',
                        value: 3
                    },
                ],

                status: [
                    {
                        name: '1',
                        value: 1
                    },
                ],
                importedFields: {},
                bindingFields: {}
            }
        };
    },
    computed: {
        isBodyShouldAppear () {
            return Object.keys(this.options.bindingFields).length;
        }
    },

    methods: {
        beforeClose () {
            this.isUploaded = false;
            this.errors = {};
            this.error = '';
        },
        handleUpload (event) {
            event.preventDefault();
            this.$refs.hiddenInputFile.click();
        },
        handleChange (event) {
            const fileUploaded = event.target.files[0];
            Rest.SheetFile(urlImportHeader, fileUploaded).then(result => {
                this.isUploaded = true;
                if (result?.data) {
                    this.setOptionsData(result.data);
                }
            });
        },

        handleSend () {
            const { importedFields, bindingFields } = this.options;
            const pairObject = this.pairs.reduce((acc, { from, to }) => {
                const toValue = bindingFields[to].value;
                const fromValue = importedFields[from].value;
                return {
                    ...acc,
                    [toValue]: fromValue
                };
            }, {});

            const sendingObject = {
                ...pairObject,
                campaign_id: this.salesRep.id,
                sales_user_id: this.campaign.id,
            };

            Rest.POST(urlLeadsImport, sendingObject).then(
                res => res.json()
            ).then(({ message, errors }) => {
                if (message === 'ok') {
                    this.resetOptions();
                    this.pairs = [];
                    this.closeModal();
                } else {
                    this.errors = errors;
                    this.error = message;
                }
            }).catch(e => console.error(e));
        },

        setOptionsData (data) {
            const { default_header, excel_header, users, campaigns } = data;
            this.setBindingFields(excel_header);
            this.setImportedFields(default_header);
            this.setUserOptions(users, campaigns);
        },
        setUserOptions (usersOptions, campaignOptions) {
            this.options.salesRep = usersOptions;
            this.options.campaign = campaignOptions;
        },
        setBindingFields (excelFieldsArray) {
            if (excelFieldsArray?.length) {
                const obj = {};
                excelFieldsArray.forEach((field, ind) => {
                    const idx = ind + 1;
                    obj[idx] = ({
                        value: field,
                        name: field,
                        isImported: false
                    });
                });
                this.options.importedFields = obj;
            }
        },
        setImportedFields (defaultFieldsObj) {
            const obj = {};
            Object.keys(defaultFieldsObj).forEach((field, ind) => {
                const idx = ind + 1;
                obj[idx] = {
                    value: field,
                    name: defaultFieldsObj[field],
                    isImported: false
                };
            });

            this.options.bindingFields = obj;
        },
        resetOptions () {
            this.options.importedFields.forEach(element => {
                element.isImported = false;
            });
            this.options.bindingFields.forEach(element => {
                element.isImported = false;
            });
        },
        initiateTransfer () {
            const fromValue = this.options.importedFields[this.selectedFromExcelIndex];
            const toValue = this.options.bindingFields[this.selectedExistingIndex];
            fromValue.isImported = true;
            toValue.isImported = true;
            // todo might be needed to change from index to value

            this.pairs.push({
                from: this.selectedFromExcelIndex,
                to: this.selectedExistingIndex
            });
            this.selectedFromExcelIndex = null;
            this.selectedExistingIndex = null;
        },
        undoImport (item) {
            const newPairs = this.pairs.filter(element => {
                const isRemovableElement = element.from === item;
                if (isRemovableElement) {
                    const importedElement = this.options.importedFields[element.from];
                    importedElement.isImported = false;
                    const boundElement = this.options.bindingFields[element.to];
                    boundElement.isImported = false;
                }
                return !isRemovableElement;
            });
            this.pairs = newPairs;
        },
        onBoundValueClick (id) {
            this.selectedExistingIndex = id;
        },
        onExcelValueClick (item, id) {
            item.isImported ? this.undoImport(id) : this.selectedFromExcelIndex = id;
        }
    }
});


