import Vue from 'vue';

export default Vue.extend({
    methods: {
        handleClick () {
            this.$emit('click');
            this.$emit('selectAll');
        }
    }
});
