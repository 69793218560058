<template>
    <a
        href="#"
        class="add-btn"
        @click.prevent="$emit('onAdd')"
    >
        <material-icon
            symbol="add"
            size="sm"
            color="black"
        />
    </a>
</template>
<script>
export default {};
</script>

<style>
    .add-btn {
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
