import Vue from 'vue';
import { WireTransferInterfaces } from './interfaces/wire-transfer-interfaces';
import addPaymentTab from '~/components/mixins/add-payment-tab';
import formsHelper from '~/components/mixins/forms-helper';
import currencyHelper from '~/components/mixins/currency-helper';
import paymentMethodFormsMixin from '../payment-method-forms-mixin';

export default Vue.extend({
    mixins: [
        addPaymentTab,
        formsHelper,
        currencyHelper,
        paymentMethodFormsMixin,
    ],
    props: {
        payload: {
            type: Object,
        }
    },
    data (): WireTransferInterfaces {
        return {
            method_id: null,
            id: null,
            bank_name: '',
            bank_address: '',
            swift: '',
            iban: '',
            owner_name: '',
        };
    },
});
