// Imports
import Vue from 'vue';
import { CalendarEventsInterfaces } from './interfaces/calendar-events-interfaces';
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

import { CalendarModule } from '~/store/modules/calendar/calendar';

import CalendarAddEventQuick from '~/components/calendar-add-event-quick/calendar-add-event-quick.vue';
import CalendarAddEventFull from '~/components/calendar-add-event-full/calendar-add-event-full.vue';

// MOCK DATA
import * as mockCalendar from '~/mock-data/mock-calendar';
// Exports
export default Vue.extend({
    components: {
        FullCalendar,
        CalendarAddEventQuick,
        CalendarAddEventFull
    },
    props: {
        isFullForm: {
            type: Boolean,
            required: true
        }
    },
    data (): CalendarEventsInterfaces {
        return {
            className: 'b-calendar-events',
            isEventQuickVisible: false,
            isEventFullVisible: false,
            // new event quick popup
            quickNewEvent: {
                styles: {
                    left: 0,
                    top: 0,
                },
                side: '',
                width: '465',
                date: ''
            }
        };
    },
    computed: {
        currentView (): string {
            return CalendarModule.getCurrentViewCalendar;
        },
        config () {
            return {
                ...this.configOptions,
                ...this.eventHandlers
            };
        },
        getCalendarEvents (): Array<object> {
            return CalendarModule.getCalendarEvents;
        },

        configOptions () {
            return {
                editable: true,
                selectable: true,
                selectMirror: true,
                dayMaxEvents: true,
                events: this.getCalendarEvents,
                weekends: this.weekendsVisible,
                plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
                headerToolbar: {
                    left: 'prev,next today',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay'
                },
                initialView: this.currentView,
                eventLimit: 4,
                eventLimitText: function (numEvents) {
                    return `View ${numEvents} more events...`;
                },
            };
        },
        eventHandlers () {
            return {
                dateClick: this.handleDateClick,
                eventClick: this.onEventClick,
                eventDrop: this.onEventDrop,
                eventResize: this.onEventDrop,
                select: this.onDateSelect
            };
        }
    },
    watch: {
        isFullForm (val) {
            this.isEventFullVisible = val;
        },
        currentView (val) {
            const calendarApi = this.$refs.fullCalendar.getApi();
            calendarApi.changeView(val);
        }
    },
    mounted () {
        this.loadEvents();
    },
    methods: {
        loadEvents () {
            CalendarModule.loadCalendarEvents();
        },

        addEventQuickShow () {
            this.isEventQuickVisible = true;
        },

        addEventQuickHide () {
            this.isEventQuickVisible = false;
        },

        addEventFullShow () {
            this.isEventQuickVisible = false;
            this.isEventFullVisible = true;
        },

        addEventFullHide () {
            this.isEventFullVisible = false;
        },
        
        handlePositionAddEventQuick (payload: any) {
            const target = payload.jsEvent.target;
            const currentElement = target.closest('.fc-daygrid-day');
            const parentRow = target.closest('tr');
      
            if (currentElement.offsetWidth < parentRow.offsetWidth / 6) {
                this.quickNewEvent.styles.left = (currentElement.offsetLeft + currentElement.offsetWidth);

                const allRows = document.querySelectorAll('tr');
                const lastRow = allRows[allRows.length - 1];

                const isLastRow = parentRow.offsetTop === lastRow.offsetTop;

                if (isLastRow) {
                    this.quickNewEvent.styles.top = parentRow.offsetTop;
                } else {
                    this.quickNewEvent.styles.top = parentRow.offsetTop + currentElement.offsetHeight / 2;
                }

                // leif or right side
                if (this.quickNewEvent.styles.left > parentRow.offsetWidth / 2) {
                    this.quickNewEvent.side = 'left';
                    this.quickNewEvent.styles.left = currentElement.offsetLeft - this.quickNewEvent.width;
                } else {
                    this.quickNewEvent.side = 'right';
                }

                this.quickNewEvent.styles.left = `${this.quickNewEvent.styles.left}px`;
                this.quickNewEvent.styles.top = `${this.quickNewEvent.styles.top}px`;
            }
        },
        handleNewEventDate (payload: any) {
            this.quickNewEvent.date = payload;
        },
        addEventQuick (payload: Object) {
            // MOCK-DATA
            // @ts-ignore
            payload['id'] = mockCalendar.createEventId();
            CalendarModule.addEvent(payload);
        },
        handleDateClick (payload: any) {
            this.handleNewEventDate(payload.date);
            this.handlePositionAddEventQuick(payload);
            this.addEventQuickShow();

            if (this.$refs.calendarEventQuick) {
                const parentWithScroll = this.$refs.calendarEventQuick.$el
                    .closest('.vb-content');

                this.checkAndScrollToShowElement(
                    this.$refs.calendarEventQuick.$el.firstChild.firstChild,
                    parentWithScroll
                );
            }
        },
        onDateSelect (payload) {
            setTimeout(() => {
                this.handleDateClick(payload);
            }, 100);
        },
        onEventDrop ({ event }) {
            return this.updateEvent(event);
        },
        checkAndScrollToShowElement (element: HTMLElement, parentWithScroll: HTMLElement) {
            const rect = element.getBoundingClientRect();
            const windowHeight = window.innerHeight;
        
            let scrollNeeded = false;
            let scrollDirection = 'down';
          
            if (rect.top < rect.height  / 2) {
                scrollNeeded = true;
                scrollDirection = 'up';
            }
          
            if (rect.bottom > windowHeight) {
                scrollNeeded = true;
                scrollDirection = 'down';
            }
        
            if (scrollNeeded) {
                const scrollAmount = scrollDirection === 'up'
                    ? rect.top - rect.height / 2
                    : rect.height;
        
                parentWithScroll.scrollBy({
                    top: scrollAmount,
                    behavior: 'smooth'
                });
            }
        },
    }
});
