import Vue from 'vue';
import rootTable from '~/components/tables/root-table/root-table.vue';
import navbarAffiliates from '~/components/affiliates/affiliates-nav/affiliates-nav.vue';
import { AffiliatesPageModule } from '~/store/modules/affiliates/affiliates-page';
import configsMixin from '~/components/mixins/configs-mixin';
import { ETableType } from '~/components/tables/enums/table-type.enum';
import { ModalsModule } from '~/store/modules/modals';
import { ITableHeaderColumn } from '~/components/tables/interfaces/table-header-column.interfaces';
import { ITableCell } from '~/components/tables/interfaces/table-cell-interfaces';
import affiliateDetailsMixin from '~/components/mixins/affiliate-details-mixin';
import { BonusPlanModule } from '~/store/modules/bonus-plan/bonus-plan';
import { AffiliatesBonusPlansModule } from '~/store/modules/affiliates/affiliates-bonus-plans';
import { setQuaryPerPage } from '~/components/tables/helpers/setQuaryPerPage';

export default Vue.extend({
    components: {
        rootTable,
        navbarAffiliates,
    },
    mixins: [
        configsMixin,
        affiliateDetailsMixin,
    ],
    computed: {
        getAffiliatesIsLoaded (): boolean {
            return AffiliatesPageModule.getAffiliatesIsLoaded;
        },
        affiliatesTableHeader (): ITableHeaderColumn[] {
            return AffiliatesPageModule.getterHeader;
        },
        affiliatesTableRow (): ITableCell[] {
            return AffiliatesPageModule.getterAffiliatesRows;
        },
        tableType (): string {
            return ETableType.affiliates;
        },
    },
    mounted (): void {
        setQuaryPerPage(this.tableType);
        AffiliatesPageModule.loadAffiliates(this.$route.query);
        this.loadBonusPlan();
    },
    methods: {
        async loadBonusPlan () {
            await BonusPlanModule.loadBonusPlan()
                .then(() => {
                    AffiliatesBonusPlansModule.setActiveFirstBonusPlan();
                });
        },
        showAddNewAffiliates (): void {
            ModalsModule.openModal({
                modalType: 'addNewAffiliatesModal',
                props: {
                    title: 'Add New Affiliate',
                    body: {
                        subtitle: 'subtitle'
                    },
                    isFooter: false,
                    isHeader: true
                },
                modalSettings: {
                    props: {
                        minWidth: ModalsModule.modalWidth.lg,
                    },
                },
            });
        },
        showEditAffiliates (id: number): void {
            ModalsModule.openModal({
                modalType: 'affiliatesEditModal',
                props: {
                    title: 'Edit Affiliate',
                    body: {
                        id,
                    },
                    isFooter: false,
                    isHeader: true
                },
                modalSettings: {
                    props: {
                        minWidth: ModalsModule.modalWidth.lg,
                    },
                },
            });
        },
        editMultipleAffiliates (): void {
            ModalsModule.openModal({
                modalType: 'affiliatesMassEditModal',
                props: {
                    title: 'Mass Edit Affiliates',
                    body: {},
                    isFooter: false,
                    isHeader: true
                },
                modalSettings: {
                    props: {
                        minWidth: ModalsModule.modalWidth.md,
                    },
                },
            });
        },
    }
});
