<template>
    <div>
        <details-general-phones-item
            label="Mobile"
            :phone-country="leadDetails.phone_mobile_country"
            :phone-body="leadDetails.phone_mobile"
            :phone-full="leadDetails.phone_mobile_full"
            :loading="phoneMobileLoading"
            :add-button="!isOpenedSecond"
            :lead-id="leadDetails.id"
            :is-first="true"
            @confirm="confirmPhoneMobile"
            @add="openSecond"
        />
        <div
            v-if="isOpenedSecond"
        >
            <details-general-phones-item
                label="Home Number"
                icon="fax"
                :is-first="false"
                :phone-country="leadDetails.phone_second_country"
                :phone-body="leadDetails.phone_second"
                :phone-full="leadDetails.phone_second_full"
                :loading="phoneSecondLoading"
                :lead-id="leadDetails.id"
                @confirm="confirmPhoneSecond"
            />
        </div>
    </div>
</template>

<script>
import DetailsGeneralPhonesItem from './DetailsGeneralPhonesItem';
import leadDetailsMixin from '../../mixins/leadDetailsMixin';
import { NotificationPrefabricated } from '~/helpers/modal-notification-services/modal-notification-services';

export default {
    components: {
        DetailsGeneralPhonesItem
    },
    mixins : [
        leadDetailsMixin,
    ],
    data () {
        return {
            phoneMobileLoading: false,
            phoneSecondLoading: false,
            forcedOpenSecond : false
        };
    },
    computed: {
        isOpenedSecond () {
            return this.forcedOpenSecond || this.leadDetails.phone_second_full;
        }
    },
    methods: {
        openSecond () {
            this.forcedOpenSecond = true;
        },
        confirmPhoneMobile (phone_mobile_country_id, phone_mobile) {
            this.phoneMobileLoading = true;
            this.updateLeadDetails({ phone_mobile_country_id, phone_mobile })
                .then((response) => {
                    if (Object.keys(response).length) {
                        NotificationPrefabricated.KycPhoneSuccess();
                    }
                })
                .finally(() => {
                    this.phoneMobileLoading = false;
                    // TODO
                    this.handleFetchDetails();
                });
        },
        confirmPhoneSecond (phone_second_country_id, phone_second) {
            this.phoneSecondLoading = true;
            this.updateLeadDetails({ phone_second_country_id, phone_second })
                .catch(error => {
                    NotificationPrefabricated.Error(error);
                })
                .finally(() => {
                    this.phoneSecondLoading = false;
                    NotificationPrefabricated.KycPhoneSuccess();
                    // TODO
                    this.handleFetchDetails();
                });
        },
    },
};
</script>
