import Vue from 'vue';
import itemMixin from '~/components/notifications/notifications-table/_item/notifications-table-item-mixin';
import affiliatesStatus from '@/js/components/tables/table-cells/affiliates-status/affiliates-status.vue';

export default Vue.extend({
    components: {
        affiliatesStatus,
    },
    mixins: [
        itemMixin,
    ],
    methods: {
        handleRedirect (affiliateId: number, redirect: string): void {
            this.$router.push(`/affiliates/${redirect}?affiliate_id=${affiliateId}`);
        }
    }
});
