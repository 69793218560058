import { ITableHeaderColumn } from '~/components/tables/interfaces/table-header-column.interfaces';
import { tableHeaderParser } from '~/components/tables/helpers/table-header-parser';

export const affiliatesReportsCampaignInsideTableHeader: ITableHeaderColumn[] = tableHeaderParser([
    {
        id: 2,
        name: '',
        value: '',
        cellData: '',
        cellType: 'empty-cell',
        cellClass: 'b-main-table__action-item b-main-table__action-item--sm'
    },
    {
        id: 2,
        name: '',
        value: '',
        cellData: '',
        cellType: 'empty-cell',
    },
    {
        id: 1,
        name: 'campaign_name',
        value: 'Campaign Name',
        cellType: 'plain-text-tooltip',
        cellData: 'full_name',
        cellWidth: '100px',
        cellClass: 'up-first-latter',
    },
    {
        id: 9,
        name: 'bonus_plan',
        value: 'Bonus Plan',
        cellType: 'plain-text',
        cellData: 'bonus_plan_type',
        cellWidth: '100px',
    },
    {
        id: 3,
        name: 'new_leads',
        value: 'Leads',
        cellType: 'plain-text',
        cellData: 'leads_count',
        textAlign: 'right',
        cellWidth: '100px',
    },
    {
        id: 6,
        name: 'ftd',
        value: 'FTD',
        cellType: 'plain-text',
        cellData: 'ftd_count',
        textAlign: 'right',
        cellWidth: '100px',
    },
    {
        id: 7,
        name: 'ftd_ratio',
        value: 'FTD Ratio',
        cellType: 'plain-sum-percentage',
        cellData: 'ftd_ratio',
        textAlign: 'right',
        cellWidth: '100px',
    },
    {
        id: 5,
        name: 'ftd_amount',
        value: 'FTD Amount',
        cellType: 'plain-text',
        cellData: 'ftd_amount',
        textAlign: 'right',
        cellWidth: '100px',
    },
    {
        id: 4,
        name: 'deposit_amount',
        value: 'Deposits',
        cellType: 'plain-text',
        cellData: 'deposit_amount',
        textAlign: 'right',
        cellWidth: '100px',
    },
    {
        id: 8,
        name: 'profit_amount',
        value: 'Profit',
        cellType: 'plain-sum',
        cellData: 'profit_amount',
        textAlign: 'right',
        cellWidth: '100px',
    },
    {
        id: 10,
        name: 'bonus_plan_min_deposit',
        value: 'Min. Deposit',
        cellType: 'plain-text',
        cellData: 'bonus_plan_min_deposit',
        textAlign: 'right',
        cellWidth: '100px',
    },
    {
        id: 11,
        name: 'average_commission',
        value: 'Average Comm.',
        cellType: 'plain-text',
        textAlign: 'right',
        cellData: 'average_commission',
        cellWidth: '148px',
    },
    {
        id: 12,
        name: 'commission',
        value: 'Commission',
        cellType: 'plain-text',
        cellData: 'commission',
        textAlign: 'right',
        cellWidth: '100px',
    },
    {
        id: 13,
        name: 'roi_amount',
        value: 'ROI',
        cellType: 'plain-sum-percentage',
        cellData: 'roi_amount',
        textAlign: 'right',
        cellWidth: '100px',
    },
]);
