import Vue from 'vue';
import modalLayout from '~/components/modals/root-modal-layout/root-modal-layout.vue';
import modalsMixin from '~/components/mixins/modals';
import { IUsersPayload } from '~/store/modules/users/users-interface';
import { UsersModule } from '~/store/modules/users/users';
import { BonusPlanModule } from '~/store/modules/bonus-plan/bonus-plan';
import { ILanguages } from '~/settings/interfaces/config-default-interfaces';
import { IBonusPlan } from '~/store/modules/bonus-plan/interfaces/bonus-plan-interfaces';
import campaignMixin from '~/components/mixins/campaignMixin';
import configsMixin from '~/components/mixins/configs-mixin';
import { DepartmentsModule } from '~/store/modules/departments/departments';
import { EUserTitlesModals } from '~/settings/enums/user-titles-modals.enum';
import { isPermissionEditPassword } from '~/helpers/user-permission';
import { IDepartmentConfigs } from '~/store/modules/configs/interfaces/configs-interface';

export default Vue.extend({
    components: {
        modalLayout
    },
    mixins: [
        modalsMixin,
        campaignMixin,
        configsMixin,
    ],
    data () {
        return {
            user: {
                fname: '',
                lname: '',
                username: '',
                email: '',
                phone: '',
                password: '',
                country: '',
                language_a2: '',
                department: null,
                bonus_plan: '',
                ext: '',
                activity: '',
                legal_subject: [],
                campaign: [],
                complicatedness: [],
                user_manager: '',
                live_chat_id: '',
            }
        };
    },
    computed: {
        isPermissionEditPassword,
        isLanguage: {
            get (): ILanguages {
                return this.getConfigLanguages.find(language => language.A2 === this.user.language_a2);
            },
            set (value: ILanguages) {
                this.user.language_a2 = value?.A2 || null;
            }
        },
        getBonusPlanList (): IBonusPlan[] {
            return BonusPlanModule.getBonusPlanList;
        },
        isValidation (): string {
            return this.modalData.isEdit ? '' : 'min:6';
        },
        usersManagement () {
            return this.user.department ? DepartmentsModule.getUsersManagement : [];
        },
        isRequiredPassword (): string {
            return this.title === EUserTitlesModals.add
                ? 'min:6|required|password-validate'
                : '';
        },
    },
    created (): void {
        this.handleUserData();
        this.handleChangeDepartment(this.user.department);
    },
    methods: {
        async handleChangeDepartment (event: IDepartmentConfigs): Promise<void> {
            if (event?.id) {
                await DepartmentsModule.handleChangeDepartment({
                    department_id: event.id
                });
            }
        },
        handleRemoveDepartments (): void {
            this.user.department = null;
            this.user.user_manager = null;
        },
        handleUserData () { // only on edit user, not new user
            if (this.modalData.userId) {
                const user = UsersModule.getUsersList.find(user => user.id === this.modalData.userId);
                Object.assign(this.user, user);
            }
        },
        handlerCreatePayload (): IUsersPayload {
            return {
                fname: this.user.fname,
                lname: this.user.lname,
                username: this.user.username,
                activity: this.user.activity?.value || '',
                email: this.user.email,
                phone: Number(this.user.phone),
                password: this.user.password,
                country_id: this.user.country?.id || '',
                language_a2: this.user.language_a2,
                department_id: this.user.department?.id || '',
                user_manager_id: this.user.user_manager?.id || '',
                bonus_plan_id: this.user.bonus_plan?.id || '',
                live_chat_id: this.user.live_chat_id ? Number(this.user.live_chat_id) : '',
                ext:this.user.ext ? Number(this.user.ext) : '',
                legal_subject_ids: this.user.legal_subject?.map(subject => subject.id) || '',
                campaign_ids: this.user.campaign?.map(subject => subject.id) || '',
                complicatedness_ids: this.user.complicatedness?.map(subject => subject.id) || '',
            };
        },
        async handlerCreateNewUser (): Promise<void> {
            const payload = this.handlerCreatePayload();
            await UsersModule.createNewUser(payload)
                .then((response) => {
                    if (response) {
                        this.closeModal();
                        UsersModule.loadUsers(this.$route.query);
                    }
                });

        },
        async handlerUpdateUser (): Promise<void> {
            const payload = this.handlerCreatePayload();
            await UsersModule.updateUser({
                id: this.modalData.userId,
                payload: payload
            })
                .then((response) => {
                    if (response) {
                        this.closeModal();
                        UsersModule.loadUsers(this.$route.query);
                    }
                });

        },
        async handlerSubmitForm (): Promise<void> {
            const isValid = await this.$refs.observer.validate();
            if (isValid) {
                if (this.modalData.isEditModal) {
                    this.handlerUpdateUser();
                } else {
                    this.handlerCreateNewUser();
                }
            }

        },
    },
});
