import Vue from 'vue';
import DatePicker from 'vue2-datepicker';
import AppAuthDownload from '~/components/parts/app-auth-download/app-auth-download.vue';
import { UploadedDocumentsTypeIdsEnum } from '~/components/uploaded-documents/uploaded-documents.enum';
import configsMixin from '~/components/mixins/configs-mixin';
import { isPermissionEditComplianceDocs, isPermissionDeleteComplianceDocs } from '~/helpers/user-permission';
import { DocumentsTypesInterface } from '~/store/modules/documents/interfaces';

export default Vue.extend({
    components: {
        DatePicker,
        AppAuthDownload
    },
    mixins: [configsMixin],
    props: {
        modalProps: {
            type: Object,
            required: true
        },
        statusId: {
            type: Number,
            required: true,
            default: 0
        },
        documentTypeId: {
            type: Number,
            required: true,
            default: 0
        },
        documentTypes: {
            type: Array,
            default: () => [],
        }
    },
    data (): Object {
        return {
            changeType: false,
            docTypeSelected: '',
            text: '',
            step: '',
            selectedDate: '',
            sendNotification: false,
            rejectionReason: '',
            rejectionReasonText: '',
            notes: [
                {
                    comment: 'This document is blurry, please check again before.',
                    date: '29.07.2018',
                    author: 'Constantinos',
                    delete: false
                },
                {
                    comment: 'This document is blurry, please check again before.',
                    date: '28.07.2018',
                    author: 'Marilena',
                    delete: true
                }
            ]
        };
    },
    computed: {
        isPermissionEditComplianceDocs,
        isPermissionDeleteComplianceDocs,
        handleDocTypeSelected: {
            get () {
                return this.documentTypes
                    .find(item => item.id === this.documentTypeId);
            },
            set (value: DocumentsTypesInterface) {
                this.docTypeSelected = value;
            },
        },
        isRejectionReasonOther (): boolean {
            return this.rejectionReason.id === UploadedDocumentsTypeIdsEnum.AuthorisationDepositCards;
        },
        isRejectionReasonText (): string {
            return this.isRejectionReasonOther ? this.rejectionReasonText : this.rejectionReason.name;
        },
    },
    methods: {
        disabledDate (date: Date): boolean {
            const yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);
            return date <= yesterday;
        },
        isStatus (type: number): string {
            return this.statusId === type ? 'disabled' : '';
        },
        closeModal () {
            this.$emit('closeModal');
        },
        deleteDocument () {
            this.$emit('deleteDocument');
        },
        approveDocument () {
            const payload = {
                expire_at: this.selectedDate,
                is_emailable: this.sendNotification,
            };
            this.$emit('changeIsReview', false);
            this.$emit('approve', payload);
        },
        rejectDocument () {
            const payload = {
                is_emailable: this.sendNotification,
                status_description: this.isRejectionReasonText,
            };
            this.$emit('changeIsReview', false);
            this.$emit('reject', payload);
        },
        changeDocType (event: DocumentsTypesInterface) {
            this.$emit('change', {
                type_id: event.id,
            });
            this.changeType = !this.changeType;
        }
    }
});
