import { IFields } from '~/store/modules/configs/interfaces/configs-interface';
import { IFieldValues, IKycResponce, IKycResponceWithFieldsConfigs } from '~/settings/interfaces/kyc-interfaces';

export const modifyKycResponse = (
    data: IKycResponce,
    configFields: IFields[]
): IKycResponceWithFieldsConfigs => {
    const { field_values, ...otherData } = data;
    const dataWithoutFieldValues = otherData as IKycResponceWithFieldsConfigs;

    for (const item of configFields) {
        dataWithoutFieldValues[item.id] = {
            field: item,
            ...field_values.find((value: IFieldValues) => value.field_id === item.id),
        };
    }

    return dataWithoutFieldValues;
};
