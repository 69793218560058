import { notificationsTotalInterface } from '~/store/modules/notifications/notifications-interfaces';
import { NotificationsEnum } from '~/components/notifications/enums/notifications.enum';
import { notificationsFinanceSoundServices } from '~/helpers/notifications-sound-services/notifications-finance-sound-services';
import { notificationsRegistrationSoundServices } from '~/helpers/notifications-sound-services/notifications-registration-sound-services';

export const handlerPendingNotifications = (payload: notificationsTotalInterface[]): void => {
    const fundingNotification = payload.find(item =>
        item.type_id === Number(NotificationsEnum.TYPE_FUNDING));
    const registrationNotification = payload.find(item =>
        item.type_id === Number(NotificationsEnum.TYPE_REGISTER));

    if (Object.keys(fundingNotification?.additional)?.length) {
        notificationsFinanceSoundServices.handlerNotification(fundingNotification);
    }
    if (Object.keys(registrationNotification?.additional)?.length) {
        notificationsRegistrationSoundServices.handlerNotification(registrationNotification);
    }
};
