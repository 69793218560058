import { getStdDefaultState, getStdNames, getStdGetters, getStdMutations, getStdActions } from '../_stdModuleUtils';
import { urlAffiliatesConfig } from '~/settings/ajax-urls';
import { ORDERING_ALPHABETICAL } from '~/settings/constants';

export const moduleName = 'affiliates';
export const names = getStdNames(moduleName);

export default {
    state: {
        ...getStdDefaultState(),
    },
    getters: {
        ...getStdGetters({
            names,
            idKey: 'name',
            ordering: ORDERING_ALPHABETICAL,
            defaultKey: 'is_default',
        }),
    },
    mutations: {
        ...getStdMutations({ names }),
    },
    actions: {
        ...getStdActions({ names, ajaxUrl: urlAffiliatesConfig }),
    },
};
