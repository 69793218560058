import Vue from 'vue';
import AppAuthDownload from '~/components/parts/app-auth-download/app-auth-download.vue';
import { DocModalStatusEnum } from '../doc-modal.enum';
import { isPermissionEditComplianceDocs, isPermissionDeleteComplianceDocs } from '~/helpers/user-permission';

export default Vue.extend({
    components: {
        AppAuthDownload
    },
    props: {
        statusId: {
            type: Number,
            required: true,
            default: 0
        },
        modalProps: {
            type: Object,
            required: true
        },
    },
    data () {
        return {
            text: '',
            notes: [
                {
                    comment: 'This document is blurry, please check again before.',
                    date: '29.07.2018',
                    author: 'Constantinos',
                    delete: false
                },
                {
                    comment: 'This document is blurry, please check again before.',
                    date: '28.07.2018',
                    author: 'Marilena',
                    delete: true
                },
                {
                    comment: 'This document is blurry, please check again before.',
                    date: '29.07.2018',
                    author: 'Constantinos',
                    delete: false
                },
                {
                    comment: 'This document is blurry, please check again before.',
                    date: '28.07.2018',
                    author: 'Marilena',
                    delete: true
                },
                {
                    comment: 'This document is blurry, please check again before.',
                    date: '29.07.2018',
                    author: 'Constantinos',
                    delete: false
                },
                {
                    comment: 'This document is blurry, please check again before.',
                    date: '28.07.2018',
                    author: 'Marilena',
                    delete: true
                }
            ]
        };
    },
    computed: {
        isPermissionEditComplianceDocs,
        isPermissionDeleteComplianceDocs,
        isApprovedStatus (): boolean {
            return this.statusId === DocModalStatusEnum.Approved;
        },
    },
    methods: {
        deleteDocument () {
            this.$emit('deleteDocument');
        },
        closeModal () {
            this.$emit('closeModal');
        },
        statusDocName (type: number): string {
            switch (type) {
            case DocModalStatusEnum.Approved: return 'Approved Document';
            case DocModalStatusEnum.Rejected: return 'Rejected Document';
            }
        },
        changeStatusDocument () {
            this.$emit('changeIsReview', true);
        }
    }
});
