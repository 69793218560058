// imports
import Vue from 'vue';
import Multiselect from 'vue-multiselect';
import Search from '~/components/parts/search/search.vue';
import AccountingBonusPlanTableItem from './accounting-bonus-plan-table-item/accounting-bonus-plan-table-item.vue';

// Exports
export default Vue.extend({
    components: {
        'search': Search,
        'accounting-bonus-plan-table-item': AccountingBonusPlanTableItem,
        Multiselect,
    },
    data () {
        return {
            options: [1, 2, 3],
            nameSelected: '',
            tableItems: [
                {
                    id: 5,
                    name: 'Serg. M',
                    ftdItem1: {
                        valueMin: 0,
                        valueMax: 10,
                        value: 5,
                        formatter: '${value}',
                        dotOptions: [
                            {
                                tooltip: 'always'
                            }
                        ]
                    },
                    ftdItem2: {
                        valueMin: 10,
                        valueMax: 20,
                        value: 15,
                        formatter: '${value}',
                        dotOptions: [
                            {
                                tooltip: 'always'
                            }
                        ]
                    },
                    revenueItem1: {
                        valueMin: 10000,
                        valueMax: 20000,
                        value: 15000,
                        formatter: '${value}',
                        dotOptions: [
                            {
                                tooltip: 'always'
                            }
                        ]
                    },
                    revenueItem2: {
                        valueMin: 20000,
                        valueMax: 30000,
                        value: 25000,
                        formatter: '${value}',
                        dotOptions: [
                            {
                                tooltip: 'always'
                            }
                        ]
                    },
                    statusOptions: [
                        {
                            name: 'Verified',
                            value: 'verified'
                        },
                        {
                            name: 'Pending',
                            value: 'pending'
                        },
                        {
                            name: 'Terminated',
                            value: 'terminated'
                        }
                    ],
                    value: {
                        id: 1,
                        name: 'Unverified',
                    },
                },
                {
                    id: 6,
                    name: 'Anna. Sm',
                    ftdItem1: {
                        valueMin: 0,
                        valueMax: 10,
                        value: 5,
                        formatter: '${value}',
                        dotOptions: [
                            {
                                tooltip: 'always'
                            }
                        ]
                    },
                    ftdItem2: {
                        valueMin: 10,
                        valueMax: 20,
                        value: 15,
                        formatter: '${value}',
                        dotOptions: [
                            {
                                tooltip: 'always'
                            }
                        ]
                    },
                    revenueItem1: {
                        valueMin: 10000,
                        valueMax: 20000,
                        value: 15000,
                        formatter: '${value}',
                        dotOptions: [
                            {
                                tooltip: 'always'
                            }
                        ]
                    },
                    revenueItem2: {
                        valueMin: 20000,
                        valueMax: 30000,
                        value: 25000,
                        formatter: '${value}',
                        dotOptions: [
                            {
                                tooltip: 'always'
                            }
                        ]
                    },
                    statusOptions: [
                        {
                            name: 'Verified',
                            value: 'verified'
                        },
                        {
                            name: 'Pending',
                            value: 'pending'
                        },
                        {
                            name: 'Terminated',
                            value: 'terminated'
                        }
                    ],
                    value: {
                        id: 2,
                        name: 'Pending',
                    },
                }
            ]
        };
    }
});
