import Vue from 'vue';
import { EMultiRangeType } from './enum/multi-range-type.enum';
import { EMultiRangeQueryOrder } from './enum/multi-range-query-order.enum';
import { handlerRoutesQuery } from '~/helpers/handler-routes-query';
import generateUniqueIdMixin from '~/components/mixins/generate-unique-id-mixin';

export default Vue.extend({
    mixins: [generateUniqueIdMixin],
    props: {
        filterKey: {
            type: String,
        }
    },
    data () {
        return {
            className: 'b-multi-range-filter',
            classResetButton: 'reset-button',
            placeholder: 'Select',
            selectedValue: null,
            visibleContent: false,
            singleLabel: '',
            fields: {
                from: null,
                to: null,
            },
            options: [
                {
                    id: EMultiRangeType.equal,
                    name: 'Equal',
                    value: EMultiRangeType.equal,
                },
                {
                    id: EMultiRangeType.not_equal,
                    name: 'Not Equal',
                    value: EMultiRangeType.not_equal,
                },
                {
                    id: EMultiRangeType.less,
                    name: 'Less Than',
                    value: EMultiRangeType.between,
                },
                {
                    id: EMultiRangeType.bigger,
                    name: 'Bigger Than',
                    value: EMultiRangeType.between,
                },
                {
                    id: EMultiRangeType.between,
                    name: 'Between',
                    value: EMultiRangeType.between,
                },
            ],
        };
    },
    computed: {
        isVisibleContent (): boolean {
            return this.visibleContent;
        },
        isBetweenType (): boolean {
            return this.selectedValue?.id === EMultiRangeType.between;
        },
        isEqualType (): boolean {
            return this.selectedValue?.id === EMultiRangeType.equal;
        },
        isNotEqualType (): boolean {
            return this.selectedValue?.id === EMultiRangeType.not_equal;
        },
        isLessType (): boolean {
            return this.selectedValue?.id === EMultiRangeType.less;
        },
        isBiggerType (): boolean {
            return this.selectedValue?.id === EMultiRangeType.bigger;
        },
        // FROM FIELD
        isFromFieldVisible (): boolean {
            return this.isBetweenType ||
                this.isEqualType ||
                this.isNotEqualType ||
                this.isBiggerType;
        },
        // TO FIELD
        isToFieldVisible (): boolean {
            return this.isBetweenType ||
                this.isLessType;
        },
        isSingleLabel (): string {
            return this.singleLabel || this.placeholder;
        },
        isApplied (): boolean {
            return Object.keys(this.$route.query).includes(this.filterKey);
        }
    },
    mounted (): void {
        this.$nextTick(() => {
            this.handlerRouterQuery();
            this.setFieldFocus();
        });
    },
    methods: {
        clickOnBody (event: any): void {
            const target = event.target;
            if (target.classList.contains === this.generatedUniqueId || target.closest(`.${this.generatedUniqueId}`)) {
                return;
            } else {
                this.hideContent();
            }
        },
        handleClick (event): void {
            const target = event.target;
            if (
                target.classList.contains === `${this.className}__header--active` ||
                target.classList.contains === this.classResetButton ||
                target.closest(`.${this.className}__header--active`) ||
                target.closest(`.${this.classResetButton}`)
            ) {
                this.hideContent();
            } else {
                this.handlerShowContent();

            }
        },
        handlerShowContent (): void {
            this.showContent();
            this.handlerFieldFocused();

            setTimeout(() => {
                this.$root.$el.addEventListener('click', this.clickOnBody);
            }, 100);
        },
        showContent (): void {
            this.visibleContent = true;
        },
        hideContent (): void {
            this.visibleContent = false;
            setTimeout(() => {
                this.$root.$el.removeEventListener('click', this.clickOnBody);
            }, 100);
        },
        resetFields (): void {
            this.fields.from = null;
            this.fields.to = null;
        },
        handlerResetBase (): void {
            this.singleLabel = null;
            this.selectedValue = this.options[0];
            this.resetFields();
            handlerRoutesQuery(this.filterKey, null);
        },
        handlerReset (): void {
            this.handlerResetBase();
            this.$emit('clickButtonSearch');
        },
        getQueryFormatted (): number[] | string[] {
            const type = EMultiRangeType[this.selectedValue.value];
            switch (this.selectedValue.id) {
            case EMultiRangeType.equal:
            case EMultiRangeType.not_equal:
                return [type, this.fields.from];
            case EMultiRangeType.less:
                return [type, '', this.fields.to];
            case EMultiRangeType.bigger:
                return [type, this.fields.from, ''];
            case EMultiRangeType.between:
                return [type, this.fields.from, this.fields.to];
            }
        },

        singleLabelFormatted (): string {
            return this.isBetweenType ?
                `${this.selectedValue.name} ${this.fields.from} - ${this.fields.to}` :
                `${this.selectedValue.name} ${this.fields.from || this.fields.to}`;
        },

        setSingleLabel (): void {
            this.singleLabel = this.singleLabelFormatted();
        },

        applyFilter (): void {
            if (this.fields.from || this.fields.to) {
                const query = this.getQueryFormatted();
                handlerRoutesQuery(this.filterKey, query);
                this.setSingleLabel();
                this.$emit('clickButtonSearch');
                this.hideContent();
            }
        },
        getPreFillType (payload: number[] | string[]): number {
            const type = payload[EMultiRangeQueryOrder.type];
            if (EMultiRangeType[type] === EMultiRangeType.between) {
                if (payload[EMultiRangeQueryOrder.from] && payload[EMultiRangeQueryOrder.to]) {
                    return EMultiRangeType.between;
                } else if (!payload[EMultiRangeQueryOrder.from] && payload[EMultiRangeQueryOrder.to]) {
                    return EMultiRangeType.less;
                } else if (payload[EMultiRangeQueryOrder.from] && !payload[EMultiRangeQueryOrder.to]) {
                    return EMultiRangeType.bigger;
                }
            }
            return EMultiRangeType[type];
        },
        preFillFields (payload: number[] | string[]): void {
            const type = this.getPreFillType(payload);
            this.selectedValue = this.options.find(item => item.id === type);
            this.fields.from = payload[EMultiRangeQueryOrder.from];
            this.fields.to = payload[EMultiRangeQueryOrder.to];
        },
        handlerRouterQuery (): void {
            const query = this.$router.currentRoute.query;
            const currentQuery = query[this.filterKey];
            if (currentQuery) {
                this.preFillFields(currentQuery);
                this.setSingleLabel();
            }
        },
        handlerFieldFocused () {
            this.$nextTick(() => this.setFieldFocus());
        },
        setFieldFocus () {
            if (this.isFromFieldVisible) {
                const element = this.$refs.multiRangeFilterFieldFrom?.$el;
                if (element) element.querySelector('.b-input__text-field').focus();
            } else {
                const element = this.$refs.multiRangeFilterFieldTo?.$el;
                if (element) element.querySelector('.b-input__text-field').focus();
            }
        }
    }
});
