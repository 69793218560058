// Imports
import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import { tableParser } from '~/helpers/table-parser';

import { LeadsKycOtpTableHeader, LeadsKycOtpTableItems } from '~/mock-data/mock-leads-table';
import store from '~/store';

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: 'leadsOTPList',
})

class KycOtpLeadsTable extends VuexModule {
    LeadsKycOtpTableHeader: Array<object> = LeadsKycOtpTableHeader;
    LeadsKycOtpTableItems: Array<object> = LeadsKycOtpTableItems;
    currentTable: Array<object> = [];

    get getterHeader (): Array<object> {
        return this.LeadsKycOtpTableHeader;
    }

    get getterLeadsList (): Array<object> {
        return  this.LeadsKycOtpTableItems;
    }

    get getterTable (): Array<object> {
        return this.currentTable;
    }

    @Mutation
    setKycOtpLeadsTable (table: Array<object>) {
        this.currentTable = table;
    }

    @Action ({ commit: 'setKycOtpLeadsTable' })
    actionSetKycOtpLeadsTable () {
        const parsedRows = tableParser(this.getterHeader, this.getterLeadsList);
        return parsedRows;
    }
}

export const KycOtpLeadsTableModule = getModule(KycOtpLeadsTable);
