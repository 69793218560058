export class NotificationsSoundServices {
    public soundPath: string = '/sound';

    public soundConstructor (src: string): void {
        const audio = new Audio(src);
        audio.muted = false;
        audio.autoplay = true;
        audio.play();
    }
}
