import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from '../settings/routes';
import leads from '../components/leads/leads.vue';
import leadsItem from '../components/leadsItem/Item.vue';
import leadsItemDetails from '../components/leadsItem/ItemDetails.vue';
import leadsItemKyc from '../components/leadsItem/ItemKyc.vue';
import leadsItemTrading from '../components/leadsItem/leads-item-trading/item-trading';
import leadsItemFinance from '../components/leadsItem/ItemFinance.vue';
import leadsMarketing from '../components/leadsItem/lead-marketing/marketing.vue';
import leadsItemActivityLog from '../components/leadsItem/leads-activity-log/leads-activity-log.vue';
import Accounting from '../components/accounting/accounting';
import Notifications from '../components/notifications/notifications';
import AccountingReports from '../components/accounting/accounting-reports/accounting-reports';
import AccountingCommissionDatabase from '../components/accounting/accounting-commission-database/accounting-commission-database';
import AccountingManagers from '../components/accounting/accounting-managers/accounting-managers';
import AccountingBonusPlan from '../components/accounting/accounting-bonus-plan/accounting-bonus-plan.vue';
import AccountingEmployees from '../components/accounting/accounting-employees/accounting-employees.vue';
import AffiliatesPage from '../components/affiliates/affiliates-page/affiliates-page.vue';
import AffiliatesBonusPlan from '../components/affiliates/affiliates-bonus-plan/affiliates-bonus-plan';
import DocumentsComponent from '~/pages/settings/settings-documents/settings-documents.vue';
import settingsSymbols from '~/pages/settings/settings-symbol/settings-symbol.vue';
import Users from '../components/users/users.vue';
import Login from '../components/auth/auth-login/auth-login.vue';
import NothingFound from '../components/nothingFound.vue';
import Affiliates from '~/components/affiliates/affiliates';
import AffiliatesReports from '~/components/affiliates/affiliates-reports/affiliates-reports';
import AffiliatesCompliance from '~/components/affiliates/affiliates-compliance/affiliates-compliance';
import AffiliatesCampaigns from '~/components/affiliates/affiliates-campaigns/affiliates-campaigns';
import Withdrawals from '../components/withdrawals/withdrawals.vue';
import AffiliatesStatistics from '~/components/affiliates/affiliates-statistics/affiliates-statistics.vue';
import EmailsTemplates from '~/components/emails/emails-templates/emails-templates.vue';
import EmailsInbox from '~/pages/emails/emails-inbox/emails-inbox.vue';
import EmailMass from '~/pages/emails/email-mass/email-mass.vue';
import EmailIndividual from '~/pages/emails/email-individual/email-individual.vue';
import SettingsGroup from '~/pages/settings/settings-group/settings-group.vue';
import Reports from '~/components/reports/reports.vue';
import calendar from '~/pages/calendar/calendar.vue';
import dashboard from '~/pages/dashboard/dashboard.vue';
import Emails from '~/pages/emails/emails.vue';
import Settings from '~/pages/settings/settings.vue';
import unauthorizedAccess from '~/pages/unauthorized-access/unauthorized-access.vue';
import LeadsFiltersTemplates from '~/components/leads/leads-filters-templates-page/leads-filters-templates-page.vue';
import notitficationsTable from '~/components/notifications/notifications-table/notifications-table.vue';
import notitficationsActivityLog from '~/components/notifications/notifications-activity-log/notifications-activity-log.vue';

import { ProfileModule } from '../store/modules/profile/profile';
import { AppModule } from '../store/modules/app/app';
import { handlerRouters } from '~/helpers/handler-routes';

Vue.use(VueRouter);


const router = new VueRouter({
    routes: [
        {
            ...routes.main,
            redirect: routes.leads.path,
            meta: { ...routes.main.meta },
            component: leads,
        },
        {
            ...routes.dashboard,
            meta: { ...routes.dashboard.meta },
            component: dashboard,
        },
        {
            ...routes.calendar,
            meta: { ...routes.calendar.meta },
            component: calendar,
        },

        {
            ...routes.affiliatesMarketing,
            meta: { ...routes.affiliatesMarketing.meta },
            component: AffiliatesStatistics
        },

        {
            ...routes.leads,
            meta: { ...routes.leads.meta },
            component: leads,
        },
        {
            ...routes.leadsItem,
            meta: { ...routes.leadsItem.meta },
            component: leadsItem,
            children: [
                {
                    ...routes.leadsItem.subRoutes.details,
                    meta: { ...routes.leadsItem.subRoutes.details.meta },
                    component: leadsItemDetails
                },
                {
                    ...routes.leadsItem.subRoutes.kyc,
                    meta: { ...routes.leadsItem.subRoutes.kyc.meta },
                    component: leadsItemKyc
                },
                {
                    ...routes.leadsItem.subRoutes.trading,
                    meta: { ...routes.leadsItem.subRoutes.trading.meta },
                    component: leadsItemTrading
                },
                {
                    ...routes.leadsItem.subRoutes.finance,
                    meta: { ...routes.leadsItem.subRoutes.finance.meta },
                    component: leadsItemFinance
                },
                {
                    ...routes.leadsItem.subRoutes.marketing,
                    meta: { ...routes.leadsItem.subRoutes.marketing.meta },
                    component: leadsMarketing
                },
                {
                    ...routes.leadsItem.subRoutes.activity,
                    meta: { ...routes.leadsItem.subRoutes.activity.meta },
                    component: leadsItemActivityLog
                },
            ],
        },
        {
            ...routes.leadsFiltersTemplates,
            meta: { ...routes.leadsFiltersTemplates.meta },
            component: LeadsFiltersTemplates
        },
        {
            ...routes.affiliatesItem,
            meta: { ...routes.affiliatesItem.meta },
            redirect: routes.affiliatesItem.subRoutes.affiliates.path,
            component: Affiliates,
            children: [
                {
                    ...routes.affiliatesItem.subRoutes.reports,
                    meta: { ...routes.affiliatesItem.subRoutes.reports.meta },
                    component: AffiliatesReports
                },
                {
                    ...routes.affiliatesItem.subRoutes.compliance,
                    meta: { ...routes.affiliatesItem.subRoutes.compliance.meta },
                    component: AffiliatesCompliance
                },
                {
                    ...routes.affiliatesItem.subRoutes.affiliates,
                    meta: { ...routes.affiliatesItem.subRoutes.affiliates.meta },
                    component: AffiliatesPage
                },
                {
                    ...routes.affiliatesItem.subRoutes.bonus,
                    meta: { ...routes.affiliatesItem.subRoutes.bonus.meta },
                    component: AffiliatesBonusPlan
                },
                {
                    ...routes.affiliatesItem.subRoutes.campaigns,
                    meta: { ...routes.affiliatesItem.subRoutes.campaigns.meta },
                    component: AffiliatesCampaigns
                }
            ]
        },
        {
            ...routes.users,
            meta: { ...routes.users.meta },
            component: Users,
        },
        {
            ...routes.reports,
            meta: { ...routes.reports.meta },
            component: Reports,
        },
        {
            ...routes.accounting,
            meta: { ...routes.accounting.meta },
            redirect: routes.accounting.subRoutes.reports.path,
            component: Accounting,
            children: [
                {
                    ...routes.accounting.subRoutes.reports,
                    meta: {
                        ...routes.accounting.subRoutes.reports.meta
                    },
                    component: AccountingReports
                },
                {
                    ...routes.accounting.subRoutes.commission,
                    meta: {
                        ...routes.accounting.subRoutes.commission.meta
                    },
                    component: AccountingCommissionDatabase
                },
                {
                    ...routes.accounting.subRoutes.managers,
                    meta: {
                        ...routes.accounting.subRoutes.managers.meta
                    },
                    component: AccountingManagers
                },
                {
                    ...routes.accounting.subRoutes.bonus,
                    meta: {
                        ...routes.accounting.subRoutes.bonus.meta
                    },
                    component: AccountingBonusPlan
                },
                {
                    ...routes.accounting.subRoutes.employees,
                    meta: {
                        ...routes.accounting.subRoutes.employees.meta
                    },
                    component: AccountingEmployees
                }
            ]
        },
        {
            ...routes.emails,
            meta: { ...routes.emails.meta },
            component: Emails,
            redirect: routes.emails.subRoutes.templates.path,
            children: [
                {
                    ...routes.emails.subRoutes.templates,
                    meta: {
                        ...routes.emails.subRoutes.templates.meta
                    },
                    component: EmailsTemplates
                },
                {
                    ...routes.emails.subRoutes.individual,
                    meta: {
                        ...routes.emails.subRoutes.individual.meta
                    },
                    component: EmailIndividual
                },
                {
                    ...routes.emails.subRoutes.mass,
                    meta: {
                        ...routes.emails.subRoutes.mass.meta
                    },
                    component: EmailMass
                },
                {
                    ...routes.emails.subRoutes.inbox,
                    meta: {
                        ...routes.emails.subRoutes.inbox.meta
                    },
                    component: EmailsInbox
                }
            ]
        },
        {
            ...routes.withdrawals,
            meta: { ...routes.withdrawals.meta },
            component: Withdrawals,
        },
        {
            ...routes.settings,
            meta: { ...routes.settings.meta },
            redirect: routes.settings.subRoutes.group.path,
            component: Settings,
            children: [
                {
                    ...routes.settings.subRoutes.group,
                    meta: {
                        ...routes.settings.subRoutes.group.meta
                    },
                    component: SettingsGroup
                },
                {
                    ...routes.settings.subRoutes.documents,
                    meta: { ...routes.settings.subRoutes.documents.meta },
                    component: DocumentsComponent
                },
                {
                    ...routes.settings.subRoutes.symbols,
                    meta: { ...routes.settings.subRoutes.symbols.meta },
                    component: settingsSymbols
                }
            ]
        },
        {
            ...routes.notifications,
            meta: { ...routes.notifications.meta },
            component: Notifications,
            children: [
                {
                    ...routes.notifications.subRoutes.clients,
                    meta: { ...routes.notifications.subRoutes.clients.meta },
                    component: notitficationsTable
                },
                {
                    ...routes.notifications.subRoutes.activityLog,
                    meta: { ...routes.notifications.subRoutes.activityLog.meta },
                    component: notitficationsActivityLog
                },
            ]
        },
        {
            ...routes.login,
            meta: { ...routes.login.meta },
            component: Login,
        },
        {
            ...routes.nothingFound,
            meta: { ...routes.nothingFound.meta },
            component: NothingFound,
        },
        {
            ...routes.unauthorizedAccess,
            meta: { ...routes.unauthorizedAccess.meta },
            component: unauthorizedAccess,
        },
    ],
    mode: 'history' /* Remove "#" in URL path */
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
router.beforeEach((to, from, next, ...rest) => {

    if (AppModule.getIsInited) {
        if (to.matched.some(record => record.meta.requiresAuth)) {
            if (ProfileModule.getIsAuth) {
                handlerRouters(to.name);
                next();
            } else {
                next({
                    path: routes.login.path,
                    query: { redirect: to.path }
                });
            }
        } else {
            if (ProfileModule.getIsAuth) {
                next({
                    path: routes.main.path,
                });
            } else {
                handlerRouters(to.name);
                next();
            }

        }
    } else {
        next();
    }
});

// todo dev only
window.__router = router;
export default router;
