import Vue from 'vue';
import addPaymentTab from '~/components/mixins/add-payment-tab';
import formsHelper from '~/components/mixins/forms-helper';
import currencyHelper from '~/components/mixins/currency-helper';
import { IPaymentRequisiteResponse } from '~/store/modules/payment-requisites/payment-requisites-interfaces';
import { EPaymentMethod, EPaymentMethodName } from '~/settings/enums/payment-methods.enum';
import configsMixin from '~/components/mixins/configs-mixin';

export default Vue.extend({
    mixins: [addPaymentTab, formsHelper, currencyHelper, configsMixin],
    data (): Object {
        return {
            email: '',
            provider: null
        };
    },
    computed: {
        eWallets (): IPaymentRequisiteResponse[] {
            return this.getConfigPaymentProviders
                .filter(requisite => requisite.id === EPaymentMethod.skrill
                    || requisite.id === EPaymentMethod.neteller
                );
        },
        isPaymentMethodEWallet (): string {
            return this.isMethodsSelected.id === EPaymentMethodName.eWallet
                ? 'no-capitalize-third-option no-capitalize-tags-and-option'
                : '';
        }
    },
});
