import {
    IBonusPlan,
    IBonusPlanUpdate,
} from '~/store/modules/bonus-plan/interfaces/bonus-plan-interfaces';
import { bonusPlanFormattedCountry } from './bonus-plan-formatted-country';

export const bonusPlanFormattedHybrid = (payload: IBonusPlan): IBonusPlanUpdate => {
    const {
        id,
        name,
        bonus_type,
        revenues,
        currency,
        countries,
        min_volume,
        min_deposit,
        is_fixed,
        other_country_value,
    } = payload;
    return {
        id: id || null,
        name,
        revenues,
        min_volume,
        min_deposit,
        is_fixed,
        other_country_value,
        bonus_type_id: bonus_type.id,
        countries: bonusPlanFormattedCountry(countries),
        currency_id: currency.id,
    };
};
