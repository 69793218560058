import Vue from 'vue';
import AppDatePicker from '~/components/parts/app-date-picker/app-date-picker.vue';
import addPaymentTab from '~/components/mixins/add-payment-tab';
import formsHelper from '~/components/mixins/forms-helper';
import currencyHelper from '~/components/mixins/currency-helper';

export default Vue.extend({
    components: {
        AppDatePicker
    },
    filters: {
        creditCardFilter (value) {
            value = value.replaceAll(/\D/ig, '');

            const first = value.split('', 6).join('');
            const last = value.slice(6, 10);
            if (value.length > 6) {
                return `${first}******${last}`;
            } else {
                return value;
            }
        }
    },
    mixins: [addPaymentTab, formsHelper, currencyHelper],
    data (): Object {
        return {
            card_mask: '',
            expire_at: null,
            owner_name: '',
        };
    },
});
