import Vue from 'vue';
import ItemNavbar from '../ItemNavbar.vue';
import rootTable from '~/components/tables/root-table/root-table.vue';
import { ITableHeaderColumn } from '~/components/tables/interfaces/table-header-column.interfaces';
import { ITableCell } from '~/components/tables/interfaces/table-cell-interfaces';
import { ETableType } from '~/components/tables/enums/table-type.enum';
import { LeadsActivityLogsModule } from '~/store/modules/leads-activity-log/leads-activity-log';
import { setQuaryPerPage } from '~/components/tables/helpers/setQuaryPerPage';

export default Vue.extend({
    components: {
        ItemNavbar,
        rootTable,
    },
    computed: {
        tableHeader (): ITableHeaderColumn[] {
            return LeadsActivityLogsModule.getLeadsActivityLogHeader;
        },
        tableRows (): ITableCell[] {
            return LeadsActivityLogsModule.getActivityLogRows;
        },
        tableType (): string {
            return ETableType.leadActivityLog;
        },
        isLoaded (): boolean {
            return LeadsActivityLogsModule.getActivityLogIsLoaded;
        },
    },
    mounted (): void {
        setQuaryPerPage(this.tableType);
        LeadsActivityLogsModule.loadLeadsActivityLogs(this.$route.query);
    },
    destroyed (): void {
        this.resetLeadsActivityLog();
    },
    methods: {
        resetLeadsActivityLog (): void {
            LeadsActivityLogsModule.resetLeadsActivityLog();
        },
    }
});
