export default {
    data () {
        return {
            // the idea from here https://riptutorial.com/vue-js/example/28978/using-computed-setters-for-v-model
            // do not use _ underscore to mark as private https://forum.vuejs.org/t/underscore-prefixes-for-vue-data/4018
            tempValue : null,
            tempSelected : null,
            tempRadio : null,
        };
    },
    computed: {
        value : {
            get () {
                return this.tempValue === null
                    ? this.getInitialValue()
                    : this.tempValue;
            },
            set (value) {
                this.tempValue = value;
            }
        },
        selected: {
            get () {
                return this.tempSelected === null
                    ? this.getInitialSelected()
                    : (
                        this.tempSelected === 0 // for multiselect clear
                            ? null
                            : this.tempSelected
                    );
            },
            set (option) {
                this.tempSelected = option;
            },
        },
    },
    methods : {
        getInitialValue () {
            throw new Error('Method \'methods.getInitialValue\' should be implemented, if use \'value\' v-model.');
        },
        getInitialSelected () {
            throw new Error('Method \'methods.getInitialSelected\' should be implemented, if use \'selected\' v-model.');
        },
        restoreValue () {
            this.tempValue = null;
        },
        restoreSelected () {
            this.tempSelected = null;
        },
        clearSelected () {
            this.tempSelected = 0;
        },
        changeValue (value) {
            this.tempValue = value;
        },
        changeSelected (selected) {
            this.tempSelected = selected;
        }
    }
};
