import Vue from 'vue';
import notes from '~/components/notes/notes.vue';
import { NotesModule } from '~/store/modules/notes/notes';
import { ProfileModule } from '~/store/modules/profile/profile';
import { ENotesTitle } from '~/components/notes/notes-title.enum';
import { EDepartments } from '~/settings/enums/departments.enum';
import { EAdminUsername } from '~/settings/enums/admin-username.enum';
import { INotesGroup } from '~/store/modules/notes//notes-interfaces';
import {
    departmentNotes,
    departmentSales,
    departmentCustomerSupport,
} from '~/settings/department-notes';
import { helperGenerateDefaultNotes } from '~/store/modules/notes/helpers/helper-generate-default-notes';

export default Vue.extend({
    components: {
        notes,
    },
    data () {
        return {
            eNoteTitle: ENotesTitle,
        };
    },
    computed: {
        isDepartmentNotes (): number[] {
            return departmentNotes;
        },
        getNotesByGroup (): INotesGroup {
            // TODO
            return this.isDepartmentNotes.reduce((departmentArr) => {
                departmentArr[EDepartments.compliance] = Object.values(NotesModule.getNotes)
                    .find(obj => obj.user_department_id === EDepartments.compliance) ||
                    helperGenerateDefaultNotes(EDepartments.compliance);

                departmentArr[EDepartments.sales] = Object.values(NotesModule.getNotes)
                    .find(obj => obj.user_department_id === EDepartments.sales ||
                        obj.user_department_id === EDepartments.salesManagement) ||
                    helperGenerateDefaultNotes(EDepartments.sales);

                departmentArr[EDepartments.customerSupport] = Object.values(NotesModule.getNotes)
                    .find(obj => obj.user_department_id === EDepartments.customerSupport ||
                            obj.user_department_id === EDepartments.customerCareManagement) ||
                    helperGenerateDefaultNotes(EDepartments.customerSupport);

                return departmentArr;
            }, {});
        },
        isUserDepartment (): number {
            return ProfileModule.getProfile.department_id;
        },
        isUserName (): string {
            return ProfileModule.getProfile.username;
        }
    },
    mounted (): void {
        this.loadNotes();
    },
    methods: {
        loadNotes (): void {
            const leadId = this.$router.currentRoute.params.id;
            NotesModule.loadNotes(leadId);
        },
        isAccessWriteComment (departmentId: number): boolean {
            // TODO
            const isDepartmentSales = departmentSales.some(item => item === this.isUserDepartment) &&
            departmentSales.some(item => item === departmentId);
            const isDepartmentCustomerSupport = departmentCustomerSupport.some(item => item === this.isUserDepartment) &&
                departmentCustomerSupport.some(item => item === departmentId);

            return departmentId === this.isUserDepartment ||
                EDepartments.compliance === this.isUserDepartment ||
                EDepartments.Brokerage === this.isUserDepartment ||
                EAdminUsername.admin === this.isUserName ||
                isDepartmentSales ||
                isDepartmentCustomerSupport;
        }
    }
});
