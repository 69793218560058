import Vue from 'vue';
import modalLayout from '~/components/modals/root-modal-layout/root-modal-layout.vue';
import modalsMixin from '~/components/mixins/modals';
import datePickerMixin from '~/components/mixins/date-picker-mixin';
import SymbolsConfigsMixin from '~/components/mixins/symbols-configs-mixin';
import { SymbolsDisplayModule } from '~/store/modules/symbols-display/symbols-display';
import { SymbolsModule } from '~/store/modules/symbols/symbols';
import {
    parseDate,
    createDateObj,
} from '~/helpers/common';
import { ISymbol } from '~/store/modules/symbols/symbol-interfaces';
import { ISymbolDisplay } from '~/store/modules/symbols-display/symbols-display-interfaces';
import { removeEmptyFields } from '~/helpers/remove-empty-fields';
import appTradingHours from '~/components/parts/app-trading-hours/app-trading-hours.vue';
import { ESymbolModalType } from '~/settings/enums/symbol-modal-type.enum';
import { ESymbolsPayloadKey } from '~/store/modules/symbols/symbols.enum';
import { isEqualWith } from 'lodash';

export default Vue.extend({
    components: {
        modalLayout,
        appTradingHours,
    },
    mixins: [
        modalsMixin,
        SymbolsConfigsMixin,
        datePickerMixin,
    ],
    data () {
        return {
            requiredFields: [
                ESymbolsPayloadKey.type,
                ESymbolsPayloadKey.subtype,
                ESymbolsPayloadKey.trading_type,
                ESymbolsPayloadKey.exchange,
                ESymbolsPayloadKey.symbol_mt5,
                ESymbolsPayloadKey.display_symbol_name,
                ESymbolsPayloadKey.description_generic,
                ESymbolsPayloadKey.sector,
                ESymbolsPayloadKey.industry,
                ESymbolsPayloadKey.product,
                ESymbolsPayloadKey.spread_novice,
                ESymbolsPayloadKey.spread_experience,
                ESymbolsPayloadKey.trading_hours_gmt2,
            ],
            eSymbolsPayloadKey: ESymbolsPayloadKey,
            dateFormat: 'YYYY-MM-DD',
            searchIndex: 0,
            previewImg: null,
            symbolsDisplayResult: [],
            symbolsDisplayPagination: {
                current_page: null,
                from: null,
                last_page: null,
                per_page: null,
                to: null,
                total: null,
            },
            currentFormData: {
                file: null,
                type: null,
                subtype: null,
                trading_type: null,
                exchange: null,
                symbol_mt5: null,
                display_symbol_name: null,
                description_generic: null,
                description_detail: null,
                sector: null,
                industry: null,
                product: null,
                isin: null,
                spread_novice: null,
                spread_experience: null,
                dividend: null,
                dividend_date: null,
                ex_div_date: null,
                trading_hours_gmt2: null,
                is_allowed_on_website: false,
            },
            inputSymbolData: {
                file: null,
                type: null,
                subtype: null,
                trading_type: null,
                exchange: null,
                symbol_mt5: null,
                display_symbol_name: null,
                description_generic: null,
                description_detail: null,
                sector: null,
                industry: null,
                product: null,
                isin: null,
                spread_novice: null,
                spread_experience: null,
                dividend: null,
                dividend_date: null,
                ex_div_date: null,
                trading_hours_gmt2: null,
                is_allowed_on_website: false,
            },
        };
    },
    computed: {
        isResultLastPage (): boolean {
            return this.symbolsDisplayPagination.last_page === this.symbolsDisplayPagination.current_page;
        },
        currentModalType (): string {
            return this.modalData.type;
        },
        isMassEditModalType (): boolean {
            return this.currentModalType === ESymbolModalType.massEdit;
        },
        isEditModalType (): boolean {
            return this.currentModalType === ESymbolModalType.edit;
        },
        validationInput (): string {
            return this.currentModalType === ESymbolModalType.create ?
                'required' : '';
        },
        editedValueTradingHours (): boolean {
            if (!this.isEditModalType) return false;
            return !this.compareObjectItem(
                this.currentFormData.trading_hours_gmt2,
                this.inputSymbolData.trading_hours_gmt2,
                ESymbolsPayloadKey.trading_hours_gmt2,
            );
        },
        editedValue (): (key: ESymbolsPayloadKey) => boolean {
            switch (this.currentModalType) {
            case ESymbolModalType.edit:
            case ESymbolModalType.massEdit:
                return (key: ESymbolsPayloadKey): boolean =>
                    this.currentFormData[key] !== this.inputSymbolData[key];
            default:
                return () => false;
            }
        },
        enableConfirmButton (): boolean {
            switch (this.currentModalType) {
            case ESymbolModalType.create:
                return this.requiredFields.every(key => !!this.currentFormData[key]);
            case ESymbolModalType.edit:
                return !isEqualWith(
                    this.currentFormData,
                    this.inputSymbolData,
                    this.compareObjectItem
                );
            case ESymbolModalType.massEdit:
                return Object.values(this.currentFormData).some(item => !!item);
            default:
                return true;
            }

        },
    },
    created (): void {
        this.typeDefinition();
        this.fetchSymbolsDisplay();
    },
    methods: {
        compareObjectItem (
            valueFirst: string | number,
            valueSecond: string | number,
            key: string
        ): boolean {
            if (key === ESymbolsPayloadKey.trading_hours_gmt2) {
                return String(valueFirst).replace(/ /g, '') ===
                    String(valueSecond).replace(/ /g, '');
            }
        },
        changeAllowedOnWebsite (event: boolean): void {
            this.$set(
                this.currentFormData,
                ESymbolsPayloadKey.is_allowed_on_website,
                event
            );
        },
        typeDefinition (): void {
            switch (this.modalData.type) {
            case ESymbolModalType.duplicate:
            case ESymbolModalType.edit:
                this.getSymbolDataById();
                break;

            }
        },
        getSymbolDataById (): void {
            const {
                display_symbol,
                ex_div_date,
                dividend_date,
                ...symbol
            } = SymbolsModule.getSymbols
                .find(symbol => symbol.id === this.modalData.id);

            for (const item in this.currentFormData) {
                this.$set(this.currentFormData, item, symbol[item]);
                this.$set(this.inputSymbolData, item, symbol[item]);
            }

            this.$set(
                this.currentFormData,
                ESymbolsPayloadKey.display_symbol_name,
                display_symbol?.name
            );
            this.$set(
                this.inputSymbolData,
                ESymbolsPayloadKey.display_symbol_name,
                display_symbol?.name
            );

            this.previewImg = display_symbol?.url;

            if (ex_div_date) {
                const ex_date = createDateObj(ex_div_date, this.dateFormat).toDate();
                this.$set(
                    this.currentFormData,
                    ESymbolsPayloadKey.ex_div_date,
                    ex_date
                );
                this.$set(
                    this.inputSymbolData,
                    ESymbolsPayloadKey.ex_div_date,
                    ex_date
                );
            }
            if (dividend_date) {
                const div_date = createDateObj(dividend_date, this.dateFormat).toDate();
                this.$set(
                    this.currentFormData,
                    ESymbolsPayloadKey.dividend_date,
                    div_date
                );
                this.$set(
                    this.inputSymbolData,
                    ESymbolsPayloadKey.dividend_date,
                    div_date
                );
            }
            this.previewImg = display_symbol?.url;
        },
        resetSearchIndex (): void {
            this.searchIndex = 0;
        },
        setSearchIndex (index: number): void {
            this.searchIndex = index;
        },
        changeFile (event) {
            this.previewImg = URL.createObjectURL(event.target.files[0]);

            this.$set(
                this.currentFormData,
                ESymbolsPayloadKey.file,
                event.target.files[0],
            );
        },

        loadFile () {
            if (!this.isMassEditModalType) {
                this.$refs.inputFile.click();
            }
        },

        selectTag (event: string, key: string) {
            this.$set(this.currentFormData, key, event);
        },

        async fetchSymbolsDisplay (
            query: { [ key in string]: string },
            resetResult: boolean,
        ) {
            const {
                data,
                meta,
            } = await SymbolsDisplayModule.fetch(query);

            if (resetResult) {
                this.symbolsDisplayResult = data;
            } else {
                this.symbolsDisplayResult.push(...data);
            }
            this.symbolsDisplayPagination = meta;
        },

        selectTradingSymbolName (symbol: ISymbolDisplay): void {
            this.previewImg = symbol.url;
        },

        async searchSymbolsDisplay (event: string): Promise<void> {
            this.setSearchIndex(event.length);

            setTimeout(() => {
                if (event.length === this.searchIndex) {
                    this.fetchSymbolsDisplay({
                        name: event,
                    },
                    true
                    );
                }
            }, 500);
        },

        async loadMoreSymbolsDisplay (): Promise<void> {
            const {
                current_page,
                last_page,
            } = this.symbolsDisplayPagination;
            if (current_page < last_page) {
                await this.fetchSymbolsDisplay({
                    page: current_page + 1,
                });
            }
        },

        async sendForm (payload): Promise<ISymbol> {
            switch (this.currentModalType) {
            case ESymbolModalType.create:
            case ESymbolModalType.duplicate:
                return await SymbolsModule.create(payload);
            case ESymbolModalType.edit:
                return await SymbolsModule.update({
                    ...payload,
                    id: this.modalData.id,
                });
            case ESymbolModalType.massEdit:
                return await SymbolsModule.massiveUpdate({
                    ...payload,
                    symbol_ids: this.modalData.ids,
                });
            }
        },

        async saveModal (): Promise<void> {
            const isValid = await this.$refs.observer.validate();
            const hasSomeValue = Object.values(this.currentFormData).some(item => !!item);

            if (isValid && hasSomeValue) {
                const payload = removeEmptyFields({
                    ...this.currentFormData,
                    display_symbol_name: this.currentFormData.display_symbol_name?.name || this.currentFormData.display_symbol_name,
                    ex_div_date: this.currentFormData.ex_div_date && parseDate(this.currentFormData.ex_div_date, this.dateFormat).format(this.dateFormat),
                    dividend_date: this.currentFormData.dividend_date && parseDate(this.currentFormData.dividend_date, this.dateFormat).format(this.dateFormat),
                    symbol_mt5: !this.isMassEditModalType ? this.currentFormData.symbol_mt5 : null,
                });

                await this.sendForm(payload)
                    .then(result => {
                        if (result) {
                            this.closeModal();
                            SymbolsModule.fetch(this.$route.query);
                        }
                    });
            }
        },
    }
});
