import Vue from 'vue';
import settingsNavbar from '~/components/settings/settings-navbar/settings-navbar.vue';
import settingsGroup from '~/components/settings/settings-group/settings-group.vue';

export default Vue.extend({
    components: {
        settingsNavbar,
        settingsGroup
    }
});
