<template>
    <div>
        <h1>Sorry, this page do not exists</h1>
        <router-link :to="mainPage" />
    </div>
</template>

<script>
import routes from '../settings/routes';

export default {
    data () {
        return {
            mainPage : routes.main.path,
        };
    }
};
</script>
