import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '~/store/index';
import Rest from '~/helpers/Rest';
import { urlUsersDepartment } from '~/settings/ajax-urls';
import { IUsersDepartment, IDepartment } from './users-department-interfaces';
@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: 'usersDepartment',
})
class usersDepartment extends VuexModule {
    usersDepartment!: IUsersDepartment;

    get getUsersDepartment (): IUsersDepartment {
        return this.usersDepartment;
    }

    get getUsersDepartmentCsRep (): IDepartment[] {
        return this.getUsersDepartment.cs_rep;
    }

    get getUsersDepartmentRetentionRep (): IDepartment[] {
        return this.getUsersDepartment.retention_rep;
    }

    get getUsersDepartmentSalesRep (): IDepartment[] {
        return this.getUsersDepartment.sales_rep;
    }

    @Mutation
    setUsersDepartment (payload: IUsersDepartment) {
        this.usersDepartment = payload;
    }

    @Action ({ commit: 'setUsersDepartment' })
    async loadUsersDepartment (): Promise<IUsersDepartment> {
        return await Rest.GET(urlUsersDepartment)
            .then((response) => response.json())
            .then((responseJSON) => {
                return responseJSON.data;
            })
            .catch(Rest.simpleCatch);
    }

}

export const UsersDepartmentModule = getModule(usersDepartment);
