// Imports
import Vue from 'vue';
import { DashboardModule } from '~/store/modules/dashboard/dashboard';
import { IDashboardEvents } from '~/store/modules/dashboard/interfaces';

// Exports
export default Vue.extend({
    computed: {
        getDashboardEvents (): IDashboardEvents[] {
            return DashboardModule.getDashboardEvents;
        }
    }
});
