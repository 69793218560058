import { arrayToMap, getSortCB } from '~/helpers/common';
import Rest from '../../helpers/Rest';
import { ORDERING_ASC } from '~/settings/constants';

/**
=======STANDARD module=======
* */
export const getDefaultLoad = () => ({
    isLoading: false,
    isLoaded: false,
    errorLoad: '',
});

export const getStdDefaultState = () => ({
    ...getDefaultLoad(),
    entities : {},
    entity : {},
});

export const getStdNames = (moduleName, merge = { getters : {}, mutations : {}, actions : {} }) => ({
    moduleName,
    getters : {
        load : `${moduleName}/load`,
        entities : `${moduleName}/entities`,
        entitiesItem : `${moduleName}/entitiesItem`,
        entity : `${moduleName}/entity`,
        ...merge.getters,
    },
    mutations : {
        setLoad : `${moduleName}/setLoad`,
        setEntities : `${moduleName}/setEntities`,
        setEntitiesItem : `${moduleName}/setEntitiesItem`,
        setEntity : `${moduleName}/setEntity`,
        clearEntity: `${moduleName}/clearEntity`,
        ...merge.mutations,
    },
    actions : {
        handleFetch : `${moduleName}/handleFetch`,
        clearModule : `${moduleName}/clearModule`,
        loadingStart : `${moduleName}/loadingStart`,
        loadingFinish : `${moduleName}/loadingFinish`,
        loadingFail : `${moduleName}/loadingFail`,
        setEntity : `${moduleName}/setEntity`,
        clearEntity : `${moduleName}/clearEntity`,
        ...merge.actions,
    }
});

export const getStdGetters = ({
    names,
    idKey = 'id',             // sort by key
    ordering = ORDERING_ASC,  // type sort
    stringify = null,           // TO DO if change to .ts file, change on ? instead = null
    defaultKey = '',          // will be first in list by key, TO DO if change to .ts file, change on ? instead = ''
}) => ({
    [names.getters.load] : ({ isLoading, isLoaded, errorLoad }) => ({ isLoading, isLoaded, errorLoad }),
    [names.getters.entities] : state => {
        const result = Object.values(state.entities)
            .sort(getSortCB(idKey, ordering)).map(item => ({
                ...item,
                toString () {
                    return typeof stringify === 'function' ?
                        stringify(item) :
                        stdToString(item);
                } 
            }));

        return defaultKey ?
            result.sort((a, b) => b[defaultKey] - a[defaultKey]) :
            result;
    },
    [names.getters.entitiesItem] : state => id => {
        let item = state.entities[id];
        if (!item) return item;

        return {
            ...item,
            toString () {
                return typeof stringify === 'function' ? stringify(item) : stdToString(item);
            }
        };
    },
    [names.getters.entity] : state => state.entity,
});

export const getStdMutations = ({ names, idKey = 'id' }) => ({
    [names.mutations.setLoad] : (state, { isLoading, isLoaded, errorLoad }) => {
        if (typeof isLoading !== 'undefined') state.isLoading = isLoading;
        if (typeof isLoaded !== 'undefined') state.isLoaded = isLoaded;
        if (typeof errorLoad !== 'undefined') state.errorLoad = errorLoad;
    },
    [names.mutations.setEntities] : (state, { entities = [] }) => {
        state.entities = { ...arrayToMap(entities, idKey) };
    },
    [names.mutations.setEntitiesItem] : (state, { id, entitiesItem = {} }) => {
        state.entities = { ...state.entities, [id] : { ...entitiesItem } };
    },
    [names.mutations.setEntity] : (state, entity = {}) => {
        state.entity = { ...state.entity, ...entity };
    },
    [names.mutations.clearEntity]: (state) => {
        state.entity = {};
    }
});

export const getStdActions = ({ names, ajaxUrl = '', formatResponse = null, isSingleEntity = false }) => ({
    [names.actions.handleFetch] : (context, { getAjaxUrl } = {}) => {
        const { commit, dispatch } = context;
        dispatch(names.actions.loadingStart);

        if (typeof getAjaxUrl === 'function') ajaxUrl = getAjaxUrl();

        if (ajaxUrl) {
            Rest.GET(ajaxUrl)
                .then(Rest.middleThen)
                .then(response => {
                    // if (!Object.values(response.data).length) throw new Error(`empty ${names.moduleName} response`);

                    const result = typeof formatResponse === 'function'
                        ? formatResponse(response.data)
                        : response.data;

                    if (isSingleEntity) {
                        commit(names.mutations.setEntity, result);
                    } else {
                        commit(names.mutations.setEntities, {
                            entities : result
                        });
                    }

                    dispatch(names.actions.loadingFinish);
                })
                .catch(error => {
                    dispatch(names.actions.loadingFail, error);
                    Rest.simpleCatch(error);
                });
        }
    },
    [names.actions.loadingStart] : ({ commit }) => {
        commit(names.mutations.setLoad, { isLoading: true, isLoaded: false, errorLoad: '' });
    },
    [names.actions.loadingFinish] : ({ commit }) => {
        commit(names.mutations.setLoad, { isLoading: false, isLoaded: true, errorLoad: '' });
    },
    [names.actions.loadingFail] : ({ commit }, error) => {
        commit(names.mutations.setLoad, { isLoading: false, isLoaded: false, errorLoad: error });
    },
    [names.actions.clearModule] : ({ commit }) => {
        commit(names.mutations.setLoad, { isLoading: false, isLoaded: false, errorLoad: '' });
        commit(names.mutations.setEntities, { entities: [] });
        commit(names.mutations.clearEntity, {});
    },
    [names.actions.setEntity] : ({ commit }, entity) => {
        commit(names.mutations.setEntity, entity);
    },
    [names.actions.clearEntity]: ({ commit }) => {
        commit(names.mutations.clearEntity);
    }
});

export const stdToString = (item) => {
    return item.name;
};
/**
 =======END STANDARD module=======
 * */
