// Imports
import Vue from 'vue';
import dashboardSummaryMixin from '~/components/mixins/dashboard-summary-mixin';
import { DashboardModule } from '~/store/modules/dashboard/dashboard';
import {
    IDashboardRegistrations,
    IDashboardSummaryStatisticItem,
} from '~/store/modules/dashboard/interfaces';
import {
    EDashboardClientsItemsKey,
} from '~/store/modules/dashboard/enums/dashboard-payments-items.enum';
import chartsMixin from '~/components/mixins/charts-mixins';

// Exports
export default Vue.extend({
    mixins: [
        dashboardSummaryMixin,
        chartsMixin,
    ],
    props: {
        toolTipDate: {
            type: String,
            required: true,
        },
    },
    computed: {
        getDashboardClientsWidgets (): IDashboardRegistrations[] {
            return DashboardModule.getDashboardClientsWidgets[EDashboardClientsItemsKey.client_count_per_day] as IDashboardRegistrations[];
        },
        getDashboardClientsRegistered (): IDashboardSummaryStatisticItem {
            return DashboardModule.getDashboardClients[EDashboardClientsItemsKey.client_registered_count];
        }
    },
    watch: {
        getDashboardClientsWidgets (payload: IDashboardRegistrations[]) {
            this.updateChart(
                payload,
                'count',
                'day'
            );
        }
    },
    mounted () {
        this.initChart(
            'registrationChart',
            this.chartType.bar,
            'Registrations',
        );
    },

});
