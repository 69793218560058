import Vue from 'vue';
import { modalWidth } from './enums/modal-settings';
import {
    IModal,
    IModalProps,
    IModalPositionProps,
} from './interfaces/interfaces';

export class generateModalProps {
    private props: IModalProps = {
        minWidth: modalWidth.md,
        height: 'auto',
    };

    private setPositionModal (event): IModalPositionProps {
        const {
            clientY,
            clientX,
            modalWidth,
        } = event.modalSettings.positionModal;

        const widthPercent = (clientX - modalWidth) / document.body.clientWidth;
        const widthPercentRight = (clientX) / document.body.clientWidth;
        const widthResult = widthPercent >= 0 ?
            (document.body.clientWidth + modalWidth) * widthPercent / document.body.clientWidth :
            (document.body.clientWidth * widthPercentRight / document.body.clientWidth) - widthPercent;

        const heightPercent = clientY / document.body.clientHeight;
        const heightResult = document.body.clientHeight * heightPercent / document.body.clientHeight;

        return {
            shiftX: widthResult,
            shiftY: heightResult,
        };
    }

    public getModalProps (event: IModal): IModalProps {
        let props = event.modalSettings?.props || {};
        if (event.modalSettings?.positionModal) {
            const positions = this.setPositionModal(event);
            props = {
                ...props,
                ...positions,
            };
        }

        if (props.minHeight) delete this.props.height;

        for (const item in props) {
            Vue.prototype.$set(this.props, item, props[item]);
        }

        return this.props;
    }
}
