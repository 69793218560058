import Vue from 'vue';
import kycTabItemEditButton from '@/js/components/KYC/KycTabItem/KycTabItemActionButtons/kyc-tab-item-edit-button/kyc-tab-item-edit-button.vue';
import kycTabItemHistoryButton from '@/js/components/KYC/KycTabItem/KycTabItemActionButtons/kyc-tab-item-history-button/kyc-tab-item-history-button.vue';
import kycTabItemApplyButton from '@/js/components/KYC/KycTabItem/KycTabItemActionButtons/kyc-tab-item-apply-button/kyc-tab-item-apply-button.vue';
import kycTabItemCancelButton from '@/js/components/KYC/KycTabItem/KycTabItemActionButtons/kyc-tab-item-cancel-button/kyc-tab-item-cancel-button.vue';

export default Vue.extend({
    components: {
        kycTabItemEditButton,
        kycTabItemHistoryButton,
        kycTabItemApplyButton,
        kycTabItemCancelButton,
    },
});
