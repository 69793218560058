import { NotificationPrefabricated } from '~/helpers/modal-notification-services/modal-notification-services';
import { EResponseStatus } from '~/settings/enums/response-status.enum';

export const handlerRestResponseErrors = (response: Response): void => {
    response.json()
        .then(responseJson => {
            const errors = responseJson.errors;
            if (errors) {
                const error = typeof Object.values(errors)[0] === 'string' ?
                    Object.values(errors)[0] :
                    Object.values(errors)[0][0];
                NotificationPrefabricated.Error(error);
            }
        });
};
export const handlerRestResponse = (response: Response, success?: () => Object): any => {
    const isError = response.status >= EResponseStatus.badRequest;
    if (isError) {
        handlerRestResponseErrors(response);
        return false;
    } else {
        success && success();
        return response.json();
    }
};

export const handlerRestResponseBlob = (response: Response, success?: () => Object): any => {
    const isError = response.status >= EResponseStatus.badRequest;
    if (isError) {
        handlerRestResponseErrors(response);
    } else {
        success?.();
        return response.blob();
    }
};
