import { ConfigsModule } from '~/store/modules/configs/configs-module';
import {
    ICheckboxConsents,
    ICountries,
    IDefaultConfig,
    ILeadsConfigsAnswers,
    IConfigsAttributes,
    ILeadsConfigsQuestion,
    IDepartmentConfigs,
    IDocumentation,
    IDefaultConfigIsDescribable,
    ILanguages,
    ILeadsConfigs,
    ILeveragesFormatted,
    IPaymentMethods,
    IPaymentProviders,
    IPermissionsGrouped,
    IDefaultConfigValue,
    ITimezone,
    IFields,
} from '~/store/modules/configs/interfaces/configs-interface';
import { campaignDisabledOptions } from '~/settings/configs/campaign-disabled-options';
import { affiliatesCampaignsType } from '~/settings/configs/affiliates-campaigns-type';
import { formatOffset } from '~/helpers/format';
import { names as affiliateConfig } from '~/store/modules/configs/affiliates';
import { mapGetters } from 'vuex';

export default {
    computed: {
        // TODO
        ...mapGetters({
            getConfigAffiliates: affiliateConfig.getters.entities,
        }),

        getConfigAccountCurrencies (): IDefaultConfig[] {
            return ConfigsModule.getAccountCurrencies;
        },
        getConfigAccountModeTypes (): IDefaultConfig[] {
            return ConfigsModule.getAccountModeTypes;
        },
        getConfigAccountProfitMethodTypes (): IDefaultConfig[] {
            return ConfigsModule.getAccountProfitMethodTypes;
        },
        getConfigAffiliateTypes (): IDefaultConfig[] {
            return ConfigsModule.getAffiliateTypes;
        },
        getConfigAffiliateStatuses (): IDefaultConfig[] {
            return ConfigsModule.getAffiliateStatuses;
        },
        getConfigAffiliateDocumentRejectedReasons (): IDefaultConfig[] {
            return ConfigsModule.getAffiliateDocumentRejectedReasons;
        },
        getConfigAffiliateDocumentStatuses (): IDefaultConfig[] {
            return ConfigsModule.getAffiliateDocumentStatuses;
        },
        getConfigAffiliateDocumentTypes (): IDefaultConfig[] {
            return ConfigsModule.getAffiliateDocumentTypes;
        },
        getConfigAnswers (): ILeadsConfigsAnswers[] {
            return ConfigsModule.getAnswers;
        },
        getConfigAttributes (): IConfigsAttributes[] {
            return ConfigsModule.getAttributes;
        },
        getConfigBonusTypes (): IDefaultConfig[] {
            return ConfigsModule.getBonusTypes;
        },
        getConfigCheckboxConsents (): ICheckboxConsents[] {
            return ConfigsModule.getCheckboxConsents;
        },
        getConfigClientDocumentRejectedReasons (): IDefaultConfig[] {
            return ConfigsModule.getClientDocumentRejectedReasons;
        },
        getConfigClientDocumentStatuses (): IDefaultConfig[] {
            return ConfigsModule.getClientDocumentStatuses;
        },
        getConfigClientDocumentTypes (): IDefaultConfig[] {
            return ConfigsModule.getClientDocumentTypes;
        },
        getConfigComplicatedness (): IDefaultConfig[] {
            return ConfigsModule.getComplicatedness;
        },
        getConfigCountries (): ICountries[] {
            return ConfigsModule.getCountries;
        },
        getConfigCurrencies (): IDefaultConfig[] {
            return ConfigsModule.getCurrencies;
        },
        getConfigDepartments (): IDepartmentConfigs[] {
            return ConfigsModule.getDepartments;
        },
        getConfigDocumentation (): IDocumentation[] {
            return ConfigsModule.getDocumentation;
        },
        getConfigEmploymentStatuses (): IDefaultConfigIsDescribable[] {
            return ConfigsModule.getEmploymentStatuses;
        },
        getConfigFundSources (): IDefaultConfig[] {
            return ConfigsModule.getFundSources;
        },
        getConfigHeardFrom (): IDefaultConfig[] {
            return ConfigsModule.getHeardFrom;
        },
        getConfigIndustries (): IDefaultConfigIsDescribable[] {
            return ConfigsModule.getIndustries;
        },
        getConfigLanguages (): ILanguages[] {
            return ConfigsModule.getLanguages;
        },
        getConfigLeadStatuses (): IDefaultConfig[] {
            return ConfigsModule.getLeadStatuses;
        },
        getConfigLeadsFiltersConfig (): ILeadsConfigs {
            return ConfigsModule.getLeadsFiltersConfig;
        },
        getConfigLeadsFiltersConfigDeposited (): IDefaultConfig[] {
            return ConfigsModule.getLeadsFiltersConfig.deposited;
        },
        getConfigLeadsFiltersConfigTargetMarket (): IDefaultConfig[] {
            return ConfigsModule.getLeadsFiltersConfig.target_market;
        },
        getConfigLeadsFiltersConfigAffiliate (): IDefaultConfig[] {
            return ConfigsModule.getLeadsFiltersConfig.affiliate;
        },
        getConfigLeadsFiltersConfigActivity (): IDefaultConfig[] {
            return ConfigsModule.getLeadsFiltersConfig.activity;
        },
        getConfigLeadsFiltersConfigClientProfitability (): IDefaultConfig[] {
            return ConfigsModule.getLeadsFiltersConfig.client_profitability;
        },
        getConfigLeadsFiltersConfigPeps (): IDefaultConfig[] {
            return ConfigsModule.getLeadsFiltersConfig.peps;
        },
        getConfigLeadsFiltersConfigsAnnualInvestment (): ILeadsConfigsQuestion[] {
            return ConfigsModule.getLeadsFiltersConfig.annual_investment;
        },
        getConfigLeadsFiltersConfigsAnnualIncome (): ILeadsConfigsQuestion[] {
            return ConfigsModule.getLeadsFiltersConfig.annual_income;
        },
        getConfigLeadsFiltersConfigsNetWealth (): ILeadsConfigsQuestion[] {
            return ConfigsModule.getLeadsFiltersConfig.net_wealth;
        },
        getConfigLeadsFiltersConfigsSavingsInvestments (): ILeadsConfigsQuestion[] {
            return ConfigsModule.getLeadsFiltersConfig.savings_investments;
        },
        getConfigLegalDocumentsStatuses (): IDefaultConfig[] {
            return ConfigsModule.getLegalDocumentsStatuses;
        },
        getConfigLegalSubjects (): IDefaultConfig[] {
            return ConfigsModule.getLegalSubjects;
        },
        getConfigLeveragesFormatted (): ILeveragesFormatted[] {
            return ConfigsModule.getLeveragesFormatted;
        },
        getConfigMTSubjectTypes (): IDefaultConfig[] {
            return ConfigsModule.getMTSubjectTypes;
        },
        getConfigPaymentMethods (): IPaymentMethods[] {
            return ConfigsModule.getPaymentMethods;
        },
        getConfigPaymentProviders (): IPaymentProviders[] {
            return ConfigsModule.getPaymentProviders;
        },
        getConfigPaymentStatuses (): IDefaultConfig[] {
            return ConfigsModule.getPaymentStatuses;
        },
        getConfigPaymentTypes (): IDefaultConfig[] {
            return ConfigsModule.getPaymentTypes;
        },
        getConfigPermissions (): IDefaultConfig[] {
            return ConfigsModule.getPermissions;
        },
        getConfigPermissionsGrouped (): IPermissionsGrouped {
            return ConfigsModule.getPermissionsGrouped;
        },
        getConfigFieldValueStatuses (): IDefaultConfig[] {
            return ConfigsModule.getFieldValueStatuses;
        },
        getConfigProfessionalLevelRequestTypes (): IDefaultConfig[] {
            return ConfigsModule.getProfessionalLevelRequestTypes;
        },
        getConfigProfessionalLevels (): IDefaultConfig[] {
            return ConfigsModule.getProfessionalLevels;
        },
        getConfigQuestions (): ILeadsConfigsQuestion[] {
            return ConfigsModule.getQuestions;
        },
        getConfigRisks (): IDefaultConfig[] {
            return ConfigsModule.getRisks;
        },
        getConfigReportColumns (): IDefaultConfig[] {
            return ConfigsModule.getReportColumns;
        },
        getConfigTimezones (): ITimezone[] {
            return ConfigsModule.getTimezones
                .map((timezone: ITimezone) => ({
                    ...timezone,
                    formatOffset: formatOffset(timezone?.offset),
                }));
        },
        getConfigTinAbsenceReasons (): IDefaultConfigIsDescribable[] {
            return ConfigsModule.getTinAbsenceReasons;
        },
        getConfigTitles (): IDefaultConfig[] {
            return ConfigsModule.getTitles;
        },
        getConfigVerifyStatuses (): IDefaultConfig[] {
            return ConfigsModule.getVerifyStatuses;
        },
        getConfigFields (): IFields[] {
            return ConfigsModule.getFields;
        },
        getConfigCampaignDisabledOptions (): IDefaultConfigValue[] {
            return campaignDisabledOptions;
        },
        getConfigAffiliatesCampaignsType (): IDefaultConfigValue[] {
            return affiliatesCampaignsType;
        },
    }
};
