// Imports
import Vue from 'vue';

// Exports
export default Vue.extend({
    props: {
        minFirst: {
            type: Number,
            required: true
        },
        maxFirst: {
            type: Number,
            required: true
        },
        text: {
            type: String,
            required: true
        },
        model: {
            type: Number,
            required: true
        },
        dotOptions: {
            type: Array,
            required: true
        },
        tooltipFormatter: {
            type: String,
            required: true
        },
        valueTextMin: {
            type: Number,
            required: true
        },
        valueTextMax: {
            type: Number,
            required: true
        },


        modelSecond: {
            type: Number,
            required: true
        },
        minSecond: {
            type: Number,
            required: true
        },
        maxSecond: {
            type: Number,
            required: true
        }
    }
});
