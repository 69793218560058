import Vue from 'vue';
import wireTransfer from '~/components/_forms/payment-method-forms/wire-transfer/wire-transfer.vue';
import cryptoWallet from '~/components/_forms/payment-method-forms/crypto-wallet/crypto-wallet.vue';
import { EPaymentMethodName } from '~/settings/enums/payment-methods.enum';
import financeEditModalForms from '~/components/mixins/finance-edit-modal-forms';
import { PaymentMethodsModule } from '@/js/store/modules/configs/payment-methods/payment-methods';
import { ELeadsPaymentsModal } from '~/settings/enums/leads-payments-modal.enum';
import modalLayout from '~/components/modals/root-modal-layout/root-modal-layout.vue';

export default Vue.extend({
    components: {
        wireTransfer,
        cryptoWallet,
        modalLayout,
    },
    mixins: [
        financeEditModalForms,
    ],
    props: {
        payload: {
            type: Object,
            required: true,
        }
    },
    computed: {
        getCurrentForm (): string {
            return EPaymentMethodName[this.payload.paymentMethodId];
        },
    },
    methods: {
        resetModal () {
            this.$emit('changeModal', {
                name: ELeadsPaymentsModal.paymentMethods,
            });
        },
        goBackToPaymentMethods (): void {
            PaymentMethodsModule.mutationPaymentProviderMethodSelected(null);

            this.resetModal();
        },
        async handlerSubmitForm (): Promise<void> {
            const isValid = await this.$refs.observer.validate();

            if (isValid) {
                const result = await this.onSubmitPaymentRequisiteForm()
                    .then(res => res);

                if (result) {
                    this.resetModal();
                }
            }

            PaymentMethodsModule.mutationPaymentProviderMethodSelected(null);
        },
    },
});
