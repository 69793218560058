import { handlerUserPermissionsRes } from './handler-user-permissions-rest';
import { NotificationPrefabricated } from '~/helpers/modal-notification-services/modal-notification-services';

const acceptH = 'Accept';
const contentTypeH = 'Content-Type';
const xRequestedWithH = 'X-Requested-With';
const authorizationH = 'Authorization';

class Rest {
    constructor () {
        this._accept = 'application/json, text/plain, */*';
        this._contentType = 'application/json;charset=UTF-8';
        this._xRequestedWith = 'XMLHttpRequest';
        this._token = null;
        let bu = process.env.API_URL;
        this._baseUrl = bu === '/' ? bu : bu.replace(/\/$/, '');
    }

    GET = (url) => {
        let f = fetch(this.getUrl(url), {
            method : 'GET',
            headers : this.getHeaders(),
        });
        handlerUserPermissionsRes(f);
        return f;
    }

    POST = (url, body = {}, method = 'POST') => {
        let f = fetch(this.getUrl(url), {
            method : method,
            headers : this.getHeaders(),
            body : JSON.stringify(body)
        });
        return f;
    }

    MultiPOST = (url, body = {}, method = 'POST') => {
        const formData = new FormData();
        Object.keys(body).forEach(item => {
            formData.append(item, body[item]);
        });
        return fetch(this.getUrl(url), {
            method: method,
            headers: {
                'Process-Data' : false,
                'Cache' : false,
                'Accept': '*/*',
                [xRequestedWithH] : this._xRequestedWith,
                [authorizationH] : this.getAuthorizationH(),
            },
            body: formData
        });
    };

    PUT = (url, body = {}) => {
        return this.POST(url, body, 'PUT');
    }

    DELETE = (url, body = {}) => {
        return this.POST(url, body, 'DELETE');
    }

    // TODO refactor to multiple
    FILE = (url, file) => {

        let f = fetch(this.getUrl(url), {
            method : 'POST',
            headers : {
                'Process-Data' : false,
                'Cache' : false,
                'Accept': '*/*',
                [xRequestedWithH] : this._xRequestedWith,
                [authorizationH] : this.getAuthorizationH()
            },
            body : file
        });
        return f;
    };

    SheetFile = (url, file) => {
        const formData = new FormData();
        formData.append('file', file);
        return  fetch(this.getUrl(url), {
            method : 'POST',
            headers : {
                'Process-Data' : false,
                'Cache' : false,
                'Accept': '*/*',
                [xRequestedWithH] : this._xRequestedWith,
                [authorizationH] : this.getAuthorizationH()
            },
            body : formData
        }).then(res => {
            if (res.status === 200) {
                return res.json();
            }
        }).catch(e => {
            console.error(e);
        });
    }

    getUrl = url => {
        return `${this._baseUrl}/${url.replace(/^\//, '')}`;
    }

    getHeaders = () => ({
        [acceptH] : this._accept,
        [contentTypeH] : this._contentType,
        [xRequestedWithH] : this._xRequestedWith,
        [authorizationH] : this.getAuthorizationH()
    })

    getAuthorizationH = () => `Bearer ${this.getToken()}`

    setToken = (token) => this._token = token
    getToken = () => this._token

    middleThen = response => {
        if (response.status >= 400) {
            throw new Error(response.statusText);
        }
        return response.json();
    }

    middleJson = response => {
        return response.json();
    }

    middleNoContent = response => {
        if (response.status >= 400) throw new Error(response.statusText);
    }

    simpleCatch = error => console.warn(error)

    simpleCatchNotification = (error) => {
        setTimeout(() => {
            NotificationPrefabricated.Error(`${error.message}, Please try again!`);
        }, 0);
    }
}

const rest = new Rest();


// TODO dev only
window.__Rest = rest;


export default rest;
