import { render, staticRenderFns } from "./affiliates.html?vue&type=template&id=29811fcc&lang=html&"
import script from "./affiliates.ts?vue&type=script&lang=ts&"
export * from "./affiliates.ts?vue&type=script&lang=ts&"
import style0 from "./affiliates.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports