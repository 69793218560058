import Vue from 'vue';
import { ICountries } from '~/store/modules/configs/interfaces/configs-interface';
import rowItemPropsMixin from '@/js/components/tables/mixins/table-cells-mixins/row-item-props-mixin';

export default Vue.extend({
    mixins: [
        rowItemPropsMixin,
    ],
    computed: {
        isValue (): object {
            return this.cell.value;
        },
        hasIp (): boolean {
            return !!this.cell.value;
        },
        isCurrentCountry (): ICountries | null {
            return this.hasIp ? this.isValue.country : null;
        },
        isFlag (): string {
            return this.isCurrentCountry ?
                this.isCurrentCountry.A2.toLowerCase()
                : null;
        },
        isIpAddress (): string {
            return this.hasIp ? this.isValue.ip : '';
        },
        isRegionName (): string {
            return this.hasIp ? this.isValue.custom_properties.city : '';
        },
        isCountryName (): string {
            return this.isCurrentCountry ?  this.isCurrentCountry.name : '';
        }
    }
});
