import Vue from 'vue';
import appButton from '~/components/parts/app-button/app-button.vue';
import { EmailsInboxModule } from '~/store/modules/emails/emails-inbox';
import toggle from '~/components/mixins/toggleMixin';

export default Vue.extend({
    components: {
        appButton
    },
    mixins: [
        toggle
    ],
    props: {
        entity: {
            type: Object,
            required: true
        }
    },
    methods: {
        handleReading (id: number) {
            EmailsInboxModule.setEmailsListReadStatus(id);
        },
        handleUnReading (id: number) {
            EmailsInboxModule.setEmailsListUnReadStatus(id);
        },
        showSubComments () {
            this.toggleOpen();
        }
    }
});
