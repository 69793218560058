// Imports
import Vue from 'vue';
import rowItemPropsMixin from '@/js/components/tables/mixins/table-cells-mixins/row-item-props-mixin';

// Export
export default Vue.extend({
    mixins: [
        rowItemPropsMixin,
    ],
});
