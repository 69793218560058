import Vue from 'vue';
import { WithdrawalsInterfaces } from './interfaces/withdrawals-interfaces';
import subNavbar from '~/components/subNavbar/subNavbar.vue';
import subNavbarLink from '~/components/subNavbar/subNavbarLink.vue';
import subNavbarSeparator from '~/components/subNavbar/subNavbarSeparator.vue';
import withdrawalsTable from './withdrawals-table/withdrawals-table.vue';
import { ETableType } from '~/components/tables/enums/table-type.enum';
import {
    handlerRoutesQuery,
} from '~/helpers/handler-routes-query';
import { ETableFilterKeyWithdrawals } from '~/components/tables/enums/table-filter-key.enum';
import { PaymentStatusEnum } from '~/settings/enums/payment-status-enum';
import { ETableFilterKeyPagination } from '~/components/tables/enums/table-filter-key.enum';
import { setQuaryPerPage } from '~/components/tables/helpers/setQuaryPerPage';
import { handlerLoadTable } from '~/components/tables/helpers/handler-load-table';

export default Vue.extend({
    components: {
        'sub-navbar': subNavbar,
        'sub-navbar-link': subNavbarLink,
        'sub-navbar-separator': subNavbarSeparator,
        withdrawalsTable,
    },
    data (): WithdrawalsInterfaces {
        return {
            className: 'b-withdrawals',
            tabs: {
                withdrawalsHistory: false,
                withdrawalsPending: true,
            },
        };
    },
    computed:{
        tableType (): string {
            return this.tabs.withdrawalsHistory ?
                ETableType.withdrawalsHistory :
                ETableType.withdrawalsPending;
        },
        isWithdrawalsHistory (): boolean {
            return this.tabs.withdrawalsHistory;
        },
        isWithdrawalsPending (): boolean {
            return this.tabs.withdrawalsPending;
        },
    },
    created (): void {
        this.setQueryForActiveTab();
        this.loadWithdrawals();
    },
    methods: {
        async loadWithdrawals () {
            setQuaryPerPage(this.tableType);
            handlerLoadTable(this.tableType);
        },
        changeTab (): void {
            Object.keys(this.tabs).forEach((item: string) =>
                this.tabs[item] = !this.tabs[item]);
        },
        setQueryForActiveTab (): void {
            if (this.isWithdrawalsPending) {
                handlerRoutesQuery(
                    ETableFilterKeyWithdrawals.status_ids,
                    [
                        String(PaymentStatusEnum.pending),
                        String(PaymentStatusEnum.preApproved),
                    ],
                );
            } else {
                this.$refs.withdrawalsTable.$refs.rootTable.clearSimpleQuery(ETableFilterKeyWithdrawals.status_ids);
            }
        },
        handleTabs (): void {
            this.changeTab();
            this.setQueryForActiveTab();
            handlerRoutesQuery(ETableFilterKeyPagination.page, null);
            handlerRoutesQuery(ETableFilterKeyPagination.perPage, null);
            setQuaryPerPage(this.tableType);
            handlerLoadTable(this.tableType);
            this.$refs.withdrawalsTable.$refs.rootTable.reInitScrollbar();
        },
    }
});
