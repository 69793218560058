import { getStdActions, getStdDefaultState, getStdGetters, getStdMutations, getStdNames } from './_stdModuleUtils';
import { urlClientDetails, urlLeadUpdate } from '~/settings/ajax-urls';
import Rest from '../../helpers/Rest';
import { getCountryFlag } from '~/helpers/images';
import { handlerRestResponse } from '~/helpers/handler-rest-response';


export const moduleName = 'leadDetails';


export const names = getStdNames(moduleName);

export const actionHandleUpdate = `${moduleName}/handleUpdate`;
export const handleFetchLeadDetails = `${moduleName}/handleFetchLeadDetails`;

export default {
    state: {
        ...getStdDefaultState(),
    },
    getters: {
        ...getStdGetters({ names }),
        [names.getters.entity] : (state) => {
            const {
                phone_mobile,
                phone_mobile_full,
                phone_mobile_country,
                phone_second,
                phone_second_full,
                phone_second_country,
                residence_country,
                verify_status,
                verify_status_crypto,
                live_accounts = [],
                demo_accounts = [],
                complicatedness,
                legal_subject,
                individual_full_name,
                company_name,
                ...rest
            } = state.entity;

            return {
                ...rest,
                verify_status,
                verify_status_crypto,
                phone_mobile_country,
                phone_mobile,
                phone_mobile_full,
                phone_second_country,
                phone_second,
                phone_second_full,
                residence_country,
                live_accounts,
                demo_accounts,
                complicatedness,
                legal_subject,
                individual_full_name,
                company_name,
            };
        },
    },
    mutations: {
        ...getStdMutations({ names }),
    },
    actions: {
        ...getStdActions({ names, ajaxUrl : urlClientDetails, formatResponse : formatLeadDetails, isSingleEntity : true }),

        [handleFetchLeadDetails] : async ({ commit, dispatch }, clientId) => {
            return await Rest.GET(urlClientDetails(clientId))
                .then(Rest.middleThen)
                .then(result => {
                    commit(names.mutations.setEntity, result.data);
                    dispatch(names.actions.loadingFinish);
                    return result.data;
                })
                .catch(error => {
                    dispatch(names.actions.loadingFail, error);
                    Rest.simpleCatch(error);
                });
        },

        [actionHandleUpdate] : (
            { commit, state },
            { phone_mobile_country_id,
                phone_second_country_id,
                phone_mobile,
                phone_second,
                residence_country_id,
                language_a2,
                sales_user_id,
                retention_user_id,
                customer_support_user_id,
                complicatedness_id,
                legal_subject_id,
                client_at,
                login_at,
                created_at,
                email_verified_at,
                is_on_webtrader,
                company_name,
                full_name,
            }) => {

            let entity = {};

            if (typeof phone_mobile_country_id !== 'undefined') entity = { ...entity, phone_mobile_country_id };
            if (typeof phone_mobile !== 'undefined') entity = { ...entity, phone_mobile };
            if (typeof phone_second_country_id !== 'undefined') entity = { ...entity, phone_second_country_id };
            if (typeof phone_second !== 'undefined') entity = { ...entity, phone_second };
            if (typeof residence_country_id !== 'undefined') entity = { ...entity, residence_country_id };
            if (typeof language_a2 !== 'undefined') entity = { ...entity, language_a2 };
            if (typeof sales_user_id !== 'undefined') entity = { ...entity, sales_user_id };
            if (typeof retention_user_id !== 'undefined') entity = { ...entity, retention_user_id };
            if (typeof customer_support_user_id !== 'undefined') entity = { ...entity, customer_support_user_id };
            if (typeof complicatedness_id !== 'undefined') entity = { ...entity, complicatedness_id };
            if (typeof legal_subject_id !== 'undefined') entity = { ...entity, legal_subject_id };
            if (typeof client_at !== 'undefined') entity = { ...entity, client_at };
            if (typeof login_at !== 'undefined') entity = { ...entity, login_at };
            if (typeof created_at !== 'undefined') entity = { ...entity, created_at };
            if (typeof email_verified_at !== 'undefined') entity = { ...entity, email_verified_at };
            if (typeof is_on_webtrader !== 'undefined') entity = { ...entity, is_on_webtrader };
            if (typeof company_name !== 'undefined') entity = { ...entity, company_name };
            if (typeof full_name !== 'undefined') entity = { ...entity, full_name };

            if (!Object.values(entity).length) return;

            // return Promise.reject("some error")

            return Rest.PUT(urlLeadUpdate(state.entity.id), entity)
                .then(handlerRestResponse)
                .then((responseJson) => {
                    let updatedEntity = responseJson.data || {};
                    commit(names.mutations.setEntity, updatedEntity);

                    return updatedEntity;
                });
        }
    },

};

/*
* Utils
* */

function formatLeadDetails (
    {
        id,
        full_name,
        phone_mobile,
        phone_mobile_full,
        phone_mobile_country,
        phone_second,
        phone_second_full,
        phone_second_country,
        email,
        language,
        residence_country,
        verify_status,
        verify_status_crypto,
        live_accounts = [],
        demo_accounts = [],
        user_sales,
        user_retention,
        user_customer_support,
        total_deposits,
        total_withdrawals,
        pending_withdrawals,
        total_equity,
        total_free_margin,
        top_tradable_instruments,
        social,
        professional_level,
        complicatedness,
        legal_subject,
        client_at,
        login_at,
        created_at,
        email_verified_at,
        is_on_webtrader,
        individual_full_name,
        company_name,
        referrer_url,
        browser,
        browser_version,
        os_name,
        os_version,
    } = {}
) {
    return {
        id,
        full_name,
        phone_mobile,
        phone_mobile_full,
        // TODO
        phone_mobile_country: phone_mobile_country ? {
            ...phone_mobile_country,
            image : getCountryFlag(phone_mobile_country.A2),
        } : null,
        phone_second,
        phone_second_full,
        // TODO
        phone_second_country: phone_second_country ? {
            ...phone_second_country,
            image : getCountryFlag(phone_second_country.A2),
        } : null,
        email,
        language,
        // TODO
        residence_country: residence_country ? {
            ...residence_country,
            image : getCountryFlag(residence_country.A2),
        } : null,
        verify_status,
        verify_status_crypto,
        live_accounts,
        demo_accounts,
        user_sales,
        user_retention,
        user_customer_support,
        total_deposits,
        total_withdrawals,
        pending_withdrawals,
        total_equity,
        total_free_margin,
        top_tradable_instruments,
        social,
        professional_level,
        complicatedness,
        legal_subject,
        client_at,
        login_at,
        created_at,
        email_verified_at,
        is_on_webtrader,
        individual_full_name,
        company_name,
        referrer_url,
        browser,
        browser_version,
        os_name,
        os_version,
    };
}
