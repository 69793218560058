import { convertStringToArray } from '../convert-string-to-array';
import { ETableFilterKeyAffiliatesReports } from '~/components/tables/enums/table-filter-key.enum';

export const handlerAffiliatesReports = (inputs: object): object => {
    for (const input in inputs) {
        switch (input) {
        case ETableFilterKeyAffiliatesReports.new_leads:
        case ETableFilterKeyAffiliatesReports.campaigns_reports:
        case ETableFilterKeyAffiliatesReports.ftd:
        case ETableFilterKeyAffiliatesReports.ftd_ratio:
        case ETableFilterKeyAffiliatesReports.ftd_amount:
        case ETableFilterKeyAffiliatesReports.deposit_amount:
        case ETableFilterKeyAffiliatesReports.profit_amount:
        case ETableFilterKeyAffiliatesReports.average_commission:
        case ETableFilterKeyAffiliatesReports.commission:
        case ETableFilterKeyAffiliatesReports.roi_amount:
        case ETableFilterKeyAffiliatesReports.bonus_plan_min_deposit:
            if (inputs[input]) inputs[input] = convertStringToArray(inputs[input]);
            break;
        case ETableFilterKeyAffiliatesReports.type:
            delete inputs[input];
            break;
        default:
            break;
        }
    }
    return inputs;
};
