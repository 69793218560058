import {
    IBonusPlanCountries,
} from '~/store/modules/bonus-plan/interfaces/bonus-plan-interfaces';

export const bonusPlanFormattedCountry = (payload: IBonusPlanCountries[]): IBonusPlanCountries[] => {
    return payload.map(item => {
        return {
            id: item.country.id,
            value: item.value,
        };
    }) as IBonusPlanCountries[];
};
