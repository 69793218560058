import Vue from 'vue';
import appButton from '~/components/parts/app-button/app-button.vue';
import emailsInboxItem from './emails-inbox-item/emails-inbox-item.vue';

export default Vue.extend({
    components: {
        appButton,
        emailsInboxItem,
    },
    props: {
        title: {
            type: String
        },
        read: {
            type: Boolean,
            required: true
        },
        entities: {
            type: Array
        }
    }
});
