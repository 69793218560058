// Imports
import Vue from 'vue';
import { mapActions } from 'vuex';
import { ETableFilterKeyLeads } from '~/components/tables/enums/table-filter-key.enum';
import { handlerRoutesQuery } from '~/helpers/handler-routes-query';
import {
    actionHandleFetchleads,
} from '~/store/modules/leads';
import { EButtonStatusType, EButtonStatusIcon } from './button-status-type.enum';
import { ETableFilterKeyPagination } from '~/components/tables/enums/table-filter-key.enum';

export default Vue.extend({
    props: {
        entities: {
            type: Array,
            required: true
        }
    },
    computed: {
        isButtonActive (): (id: number) => boolean {
            return (id: number): boolean => {
                return this.$route.query[ETableFilterKeyLeads.verify_status_id] &&
                    Number(this.$route.query[ETableFilterKeyLeads.verify_status_id]) === id;
            };
        },
    },
    methods: {
        ...mapActions({
            handleFetchleads: actionHandleFetchleads,
        }),
        loadLeads () {
            const query = this.$router.currentRoute.query;

            if (query[ETableFilterKeyPagination.page]) {
                handlerRoutesQuery(ETableFilterKeyPagination.page, null);
            }
            this.handleFetchleads({
                ...query,
                [ETableFilterKeyPagination.page]: null,
            });
        },
        clearFilterSelect () {
            handlerRoutesQuery(ETableFilterKeyLeads.verify_status_id, null);
            this.loadLeads();
        },
        filterByStatus (selectedOption) {
            if (this.$refs[`appButtonVerifyStatus_${selectedOption.verify_status_id}`][0].active) {
                this.clearFilterSelect();
            } else {
                handlerRoutesQuery(ETableFilterKeyLeads.verify_status_id, [selectedOption.verify_status_id]);
                this.loadLeads();
            }
        },
        isButtonType (id: number): string {
            return EButtonStatusType[id];
        },
        isButtonTypeIcon (id: number): string {
            return EButtonStatusIcon[id];
        },
    },
});
