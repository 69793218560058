<template>
    <b-tabs
        class="kyc_tabs"
        @input="tabInput"
    >
        <b-tab :active="activeTab === 0">
            <template slot="title">
                <icon-ad />
                <span>Personal, Account & Billing Information</span>
                <div
                    v-if="personalFieldsPendingStatusesLength"
                    class="quantity-pending"
                >
                    {{ personalFieldsPendingStatusesLength }}
                </div>
            </template>
            <kyc-item-personal-details v-if="!isKycLoading" />
            <small-loader v-else />
        </b-tab>
        <b-tab
            v-if="!isBusinessClientType"
            :active="activeTab === 1"
        >
            <template slot="title">
                <icon-profile />
                <span>Economic & Financial Background</span>
                <div
                    v-if="economicFieldsPendingStatusesLength"
                    class="quantity-pending"
                >
                    {{ economicFieldsPendingStatusesLength }}
                </div>
            </template>
            <kyc-item-background-financial v-if="!isKycLoading" />
            <small-loader v-else />
        </b-tab>
        <b-tab :active="activeTab === 2">
            <template slot="title">
                <icon-ok />
                <span>Declarations, Authorizations & More</span>
                <div
                    v-if="declarationsFieldsPendingStatusesLength"
                    class="quantity-pending"
                >
                    {{ declarationsFieldsPendingStatusesLength }}
                </div>
            </template>
            <kyc-item-declarations-more v-if="!isKycLoading" />
            <small-loader v-else />
        </b-tab>
    </b-tabs>
</template>

<script>

import KycItemPersonalDetails from '../KYC/KycPersonalDetails';
import KycBackgroundFinancial from '../KYC/KycBackgroundFinancial';
import KycDeclarationsMore from '../KYC/KycDeclarationsMore';
import SmallLoader from '../parts/loaderSmall';
import { mapActions, mapGetters } from 'vuex';
import {
    names as clientKycNames,
    actionInitialKyc as initialKyc,
} from '~/store/modules/client-kyc/client-kyc';
import IconAd from '../parts/iconAd';
import IconProfile from '../parts/iconProfile';
import IconOk from '../parts/iconOk';
import { names } from '~/store/modules/leadDetails';
import { ELegalSubject } from '~/settings/enums/legal-subject.enum';
import { ELeadsKycFieldStatus } from '~/settings/enums/leads-kyc-field-status.enum';
import { EKycFieldsValuesId } from '~/settings/enums/kyc-fields_values-id.enums';
import {
    COLUMN_ASSESSMENT_QUESTIONS,
    COLUMN_INVESTMENT_QUESTIONS,
    COLUMN_FINANCIAL_QUESTIONS,
} from '~/store/modules/_columnNames';

export default {
    components: {
        'kyc-item-personal-details': KycItemPersonalDetails,
        'kyc-item-background-financial': KycBackgroundFinancial,
        'kyc-item-declarations-more': KycDeclarationsMore,
        'small-loader': SmallLoader,
        'icon-ad': IconAd,
        'icon-profile': IconProfile,
        'icon-ok': IconOk,
    },
    computed: {
        ...mapGetters({
            kycEntity: clientKycNames.getters.entity,
            kycLoading: clientKycNames.getters.load,
            leadDetails : names.getters.entity,
        }),
        isKycLoading () {
            return this.kycLoading.isLoading;
        },
        activeTab () {
            return +localStorage.getItem('kyc-active-tab') || 0;
        },
        isBusinessClientType () {
            return this.leadDetails.legal_subject?.id === ELegalSubject.business;
        },
        personalFieldsPendingStatusesLength () {
            return this.kycEntity && this.getFieldsPendingStatusesLength([
                this.getFieldStatusId(this.kycEntity[EKycFieldsValuesId.title_id]),
                this.getFieldStatusId(this.kycEntity[EKycFieldsValuesId.fname]),
                this.getFieldStatusId(this.kycEntity[EKycFieldsValuesId.mname]),
                this.getFieldStatusId(this.kycEntity[EKycFieldsValuesId.lname]),
                this.getFieldStatusId(this.kycEntity[EKycFieldsValuesId.phone_mobile]),
                this.getFieldStatusId(this.kycEntity[EKycFieldsValuesId.phone_second]),
                this.getFieldStatusId(this.kycEntity[EKycFieldsValuesId.birth_date]),
                this.getFieldStatusId(this.kycEntity[EKycFieldsValuesId.birth_country_id]),
                this.getFieldStatusId(this.kycEntity[EKycFieldsValuesId.residence_country_id]),
                this.getFieldStatusId(this.kycEntity[EKycFieldsValuesId.residence_place]),
                this.getFieldStatusId(this.kycEntity[EKycFieldsValuesId.residence_address]),
                this.getFieldStatusId(this.kycEntity[EKycFieldsValuesId.residence_zip]),
                this.getFieldStatusId(this.kycEntity[EKycFieldsValuesId.heard_from_id]),
                this.getFieldStatusId(this.kycEntity[EKycFieldsValuesId.citizenship_country_ids]),
                this.getFieldStatusId(this.kycEntity[EKycFieldsValuesId.political_exposed]),
                this.getFieldStatusId(this.kycEntity[EKycFieldsValuesId.identity_number]),
                this.getFieldStatusId(this.kycEntity[EKycFieldsValuesId.driving_license]),
                this.getFieldStatusId(this.kycEntity[EKycFieldsValuesId.passport]),
                this.getFieldStatusId(this.kycEntity[EKycFieldsValuesId.tins]),
                this.getFieldStatusId(this.kycEntity[EKycFieldsValuesId.tin_absence_reason_id]),
            ]);
        },
        economicFieldsPendingStatusesLength () {
            return this.kycEntity && this.getFieldsPendingStatusesLength([
                ...this.kycEntity[COLUMN_ASSESSMENT_QUESTIONS]
                    .map(question => this.getFieldStatusId(question)),
                ...this.kycEntity[COLUMN_INVESTMENT_QUESTIONS]
                    .map(question => this.getFieldStatusId(question)),
                this.getFieldStatusId(this.kycEntity[EKycFieldsValuesId.employment_status_id]),
                this.getFieldStatusId(this.kycEntity[EKycFieldsValuesId.industry_id]),
                this.getFieldStatusId(this.kycEntity[EKycFieldsValuesId.fund_source_id]),
                ...this.kycEntity[COLUMN_FINANCIAL_QUESTIONS]
                    .map(question => this.getFieldStatusId(question)),
                this.getFieldStatusId(this.kycEntity[EKycFieldsValuesId.fund_type_bank_name]),
                this.getFieldStatusId(this.kycEntity[EKycFieldsValuesId.fund_type_bank_location]),
                this.getFieldStatusId(this.kycEntity[EKycFieldsValuesId.target_market_classification_id]),
            ]);
        },
        declarationsFieldsPendingStatusesLength () {
            return this.kycEntity && this.getFieldsPendingStatusesLength([
                this.getFieldStatusId(this.kycEntity[EKycFieldsValuesId.aqm_on_behalf_of]),
                this.getFieldStatusId(this.kycEntity[EKycFieldsValuesId.aqm_on_behalf_of_capacity]),
                this.getFieldStatusId(this.kycEntity[EKycFieldsValuesId.go_pro_answers]),
            ]);
        },
    },
    created () {
        this.initialKyc(this.$route.params.id);
    },
    methods: {
        ...mapActions({
            handleFetchClientKyc : clientKycNames.actions.handleFetch,
            initialKyc,
        }),
        tabInput (tabIndex) {
            localStorage.setItem('kyc-active-tab', tabIndex);
        },
        getFieldStatusId (field) {
            return field?.status?.id;
        },
        getFieldsPendingStatusesLength (fieldsStatuses) {
            return fieldsStatuses.filter(item => item === ELeadsKycFieldStatus.pending).length;
        },
    },
};
</script>
