import { extend } from 'vee-validate';

export const checkRepeatName = extend('isNameUsed', {
    validate (value, { listNames }: any) {
        return listNames && !listNames.some((item: string): boolean => (
            item.toLowerCase() === value.toLowerCase()
        ));
    },
    params: ['listNames'],
    message: 'Bonus plan name already in use',
});
