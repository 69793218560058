// Imports
import Vue from 'vue';
import { DocumentRequestsModule } from '~/store/modules/documents/document-requests';
import pendingDocumentsTableItem from './pending-documents-table-item/pending-documents-table-item.vue';

// Exports
export default Vue.extend({
    components: {
        pendingDocumentsTableItem,
    },
    props: {
        tablePending: {
            type: Array,
            required: true
        }
    },
    created () {
        this.handleFetch();
    },
    methods: {
        handleFetch () {
            DocumentRequestsModule.actionFetchDocumentsRequests(this.$route.params.id);
        },
        removeTodoItem (id) {
            this.$emit('remove-item', id);
        }
    }
});
