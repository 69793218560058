import Vue from 'vue';
import appDropdownBtn from '~/components/parts/app-dropdown-btn/app-dropdown-btn.vue';
import { EMoreOptions } from '~/settings/enums/more-options.enum';

export default Vue.extend({
    components: {
        appDropdownBtn,
    },
    data () {
        return {
            optionsMore: [
                {
                    id: EMoreOptions.print,
                    name: 'Print',
                },
                {
                    id: EMoreOptions.savePdf,
                    name: 'Save as PDF',
                },
                {
                    id: EMoreOptions.export,
                    name: 'Export to Excel',
                },
            ],
        };
    },
    methods: {
        handleMoreOptions (event) {
            this.$emit('select', event);
        },
    },
});
