<template>
    <div class="details_content d-flex flex-row flex-wrap">
        <div class="col-6 col-xl-3 details_section lead-details-column">
            <details-general v-if="!isDetailsLoading" />
            <loader-small v-else />
        </div>
        <div class="col-6 col-xl-3 details_section">
            <details-accounts v-if="!isDetailsLoading" />
            <loader-small v-else />
        </div>
        <div class="col-12 col-xl-6 details_section details_comment">
            <notes-about-client v-if="isPermissionNotes" />
            <div
                v-if="isPermissionEnableEmails"
                class="wrapper-editor wrapper-editor--margin-top"
            >
                <send-mail-editor
                    @sendEmail="sendEmail($event)"
                />
            </div>
        </div>
    </div>
</template>

<script>
import DetailsGeneral from '../leadsItem/LeadDetails/DetailsGeneral';
import DetailsAccounts from '../leadsItem/LeadDetails/DetailsAccounts';
import notesAboutClient from '../notes-about-client/notes-about-client';
import { mapActions, mapGetters } from 'vuex';
import { names as leadDetailsNames } from '../../store/modules/leadDetails';
import sendMailEditor from '../parts/sendMailEditor';
import LoaderSmall from '../parts/loaderSmall';
import routes from '~/settings/routes';
import { isPermissionNotes, isPermissionEnableEmails } from '~/helpers/user-permission';
import { SendMailModule } from '~/store/modules/send-mail/send-mail';

export default {
    components: {
        LoaderSmall,
        DetailsGeneral,
        DetailsAccounts,
        notesAboutClient,
        sendMailEditor,
    },
    computed : {
        ...mapGetters({
            detailsLoad : leadDetailsNames.getters.load,
        }),
        isPermissionNotes,
        isPermissionEnableEmails,
        isDetailsLoading () {
            return this.detailsLoad.isLoading;
        }
    },
    methods: {
        ...mapActions({
            handleFetchDetails: leadDetailsNames.actions.handleFetch
        }),
        back () {
            this.$router.push(routes.customers.path);
        },
        sendEmail (payload) {
            SendMailModule.clientSendEmail({
                template: 'clients_email', // TODO must be removed
                leadId: this.$route.params.id,
                ...payload,
            });
        }
    },
};
</script>
