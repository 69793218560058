import loaderSmallRelative from '~/components/parts/loaderSmallRelative.vue';
import appHistoryKyc from '~/components/parts/app-history-kyc/app-history-kyc.vue';
import toggleMixin from '~/components/mixins/toggleMixin';
import fieldModelMixin from '~/components/mixins/modelMixins/fieldModelMixin';
import clientKycActionsMixin from '~/components/mixins/clientKycActionsMixin';
import loadingMixin from '~/components/mixins/loadingMixin';
import clientKycMixin from '~/components/mixins/client-kyc-mixin/client-kyc-mixin';
import KycTabItemReadOnly from '~/components/KYC/KycTabItem/KycTabItemReadOnly.vue';
import appMultiSelect from '~/components/parts/app-multiselect/app-multiselect.vue';
import appInput from '~/components/parts/app-input/app-input.vue';
import toggleHistoryMixin from '~/components/mixins/toggle-history-mixin';
import { QuestionEnum } from './question.enum';
import kycTabItemActionButtonsMixin from '@/js/components/KYC/KycTabItem/KycTabItemActionButtons/kyc-tab-item-action-buttons-mixin';
import { ILeadsConfigsAnswers } from '~/store/modules/configs/interfaces/configs-interface';
import { getStringFromFieldValueData } from '~/components/KYC/kyc-helpers';
import { EFieldValueDataKeys } from '~/components/KYC/kyc-enums';
import { ELeadsKycFieldStatus } from '~/settings/enums/leads-kyc-field-status.enum';

const TRANSACTION_QUESTION_ID = 16;

export default {
    components: {
        KycTabItemReadOnly,
        loaderSmallRelative,
        appMultiSelect,
        appInput,
        appHistoryKyc
    },
    mixins : [
        toggleMixin,
        fieldModelMixin,
        clientKycActionsMixin,
        loadingMixin,
        clientKycMixin,
        toggleHistoryMixin,
        kycTabItemActionButtonsMixin,
    ],
    props: {
        initial : {
            type: [String, Number, Object, Array],
            required : true,
        },
        className: {
            type : String,
            required: false,
        },
    },
    data (): Object {
        return {
            NOTHING : '-',
        };
    },
    computed : {
        isEditable (): boolean {
            return this.initial.field.is_editable;
        },
        isHasHistory (): boolean {
            return this.initial.field.has_history;
        },
        questionId (): number {
            return this.initial.id;
        },
        displayValue (): string {
            return getStringFromFieldValueData(this.initial, {
                visibleCustom: true,
                customBrackets: true,
            }) || getStringFromFieldValueData(this.initial)
              || this.NOTHING;
        },
        optionsById (): ILeadsConfigsAnswers[] {
            return this.answersById(
                this.initial.field.id,
                this.initial.field.name
            );
        },
        selectedDescribable (): boolean {
            return this.selected?.describable;
        },
        questionTitle (): string {
            return this.initial.field.id === QuestionEnum.AverageNetDisposable
                ? 'Average yearly Net Disposable Income (total income minus expenses i.e. living, rent, debt etc.):'
                : this.initial.field.title;
        },
        isReviewedStatus (): boolean {
            return this.status?.id === ELeadsKycFieldStatus.reviewed;
        },
    },
    methods : {
        getInitialValue (): number|null {
            return +getStringFromFieldValueData(this.initial, {
                key: EFieldValueDataKeys.custom
            });
        },
        getInitialSelected (): number|null {
            return this.initial?.data && this.optionsById
                .find(option => option.name === this.initial.data[0].display) || {};
        },
        edit (): void {
            this.toggleOpen();
        },
        apply (): void {
            this.startLoading();
            const answer = {
                question_id : this.selected.question_id,
                answer_id : this.selected?.id,
                custom : this.selected?.describable ? this.value : null
            };
            this.updateClientKyc({ column : this.COLUMN_ANSWERS, value : [answer] })
                .then(() => {
                    this.endLoading();
                    this.toggleOpen();
                });
        },
        cancel (): void {
            this.restoreValue();
            this.restoreSelected();
            this.toggleOpen();
        },
        remove (): void {
            this.clearSelected();
        },
    }
};
