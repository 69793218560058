<template>
    <div class="tab-content">
        <div
            v-bar
            class="content-scroll kyc-content-scroll--questionnaire"
        >
            <div class="content-scroll__content">
                <template v-if="isBusinessClientType">
                    <div class="row">
                        <div class="col-6">
                            <div class="pr-15-procent">
                                <kyc-tab-item-input-select
                                    class="text-capitalize"
                                    label="Business Name"
                                    :initial="companyName"
                                    :guide="getGuide(COLUMN_COMPANY_NAME)"
                                    :disabled="!isPermissionEditComplianceDocs"
                                />
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="pr-15-procent">
                                <kyc-tab-item-input-select
                                    label="Country of Incorporation"
                                    :initial="incorporationCountryId"
                                    :guide="getGuide(COLUMN_INCORPORATION_COUNTRY_ID)"
                                    :disabled="!isPermissionEditComplianceDocs"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="pr-15-procent">
                                <kyc-tab-item-input-select
                                    class="text-capitalize"
                                    label="Business Address"
                                    :initial="businessAddress"
                                    :guide="getGuide(COLUMN_BUSINESS_ADDRESS)"
                                    :disabled="!isPermissionEditComplianceDocs"
                                />
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="pr-15-procent">
                                <kyc-tab-item-phone
                                    label="Business Phone"
                                    class-name="column_w6"
                                    :phone="false"
                                    :phone-column="COLUMN_BUSINESS_PHONE"
                                    :phone-country-column="COLUMN_BUSINESS_PHONE_COUNTRY_ID"
                                    :initial="businessPhone"
                                    :options="getConfigCountries"
                                    :is-first="false"
                                    :lead-id="clientKyc.id"
                                    :disabled="!isPermissionEditClientEmailPhone"
                                />
                            </div>
                        </div>
                    </div>
                </template>
                <div class="row">
                    <div class="col-6">
                        <div class="pr-15-procent no-border-bottom-last-child">
                            <kyc-tab-subtitle subtitle="Personal Information" />
                            <kyc-tab-item-read-only
                                label="Lead ID"
                                :disabled="true"
                                :initial="leadId"
                            />
                            <kyc-tab-item-input-select
                                label="Title"
                                :initial="title"
                                :disabled="!isPermissionEditComplianceDocs"
                                :guide="getGuide(AS_COLUMN_TITLE)"
                            />
  
                            <kyc-tab-item-input-select
                                class="text-capitalize"
                                label="First Name"
                                :initial="fname"
                                :guide="getGuide(COLUMN_FNAME)"
                                :disabled="!isPermissionEditComplianceDocs"
                            />
                            <kyc-tab-item-input-select
                                class="text-capitalize"
                                label="Middle Name"
                                :initial="mname"
                                :guide="getGuide(COLUMN_MNAME)"
                                :disabled="!isPermissionEditComplianceDocs"
                            />
                            <kyc-tab-item-input-select
                                class="text-capitalize"
                                label="Last Name"
                                :initial="lname"
                                :guide="getGuide(COLUMN_LNAME)"
                                :disabled="!isPermissionEditComplianceDocs"
                            />
  
                            <kyc-tab-item-read-only
                                label="Email"
                                :initial="email"
                                :disabled="true"
                            />
  
                            <kyc-tab-item-phone
                                label="Mobile Number"
                                class-name="column_w6"
                                :phone="false"
                                :phone-column="COLUMN_PHONE_MOBILE"
                                :phone-country-column="AS_COLUMN_PHONE_MOBILE_COUNTRY"
                                :initial="phone_mobile"
                                :options="getConfigCountries"
                                :is-first="true"
                                :lead-id="clientKyc.id"
                                :disabled="!isPermissionEditClientEmailPhone"
                            />
  
                            <kyc-tab-item-phone
                                label="Home Number"
                                class-name="column_w6"
                                :phone="false"
                                :is-first="false"
                                :lead-id="clientKyc.id"
                                :phone-column="COLUMN_PHONE_SECOND"
                                :phone-country-column="AS_COLUMN_PHONE_SECOND_COUNTRY_ID"
                                :initial="phone_second"
                                :options="getConfigCountries"
                                :disabled="!isPermissionEditClientEmailPhone"
                            />
  
                            <kyc-tab-item-date
                                label="Date of Birth"
                                :initial="birth_date"
                                :column="COLUMN_BIRTH_DATE"
                                :disabled="!isPermissionEditComplianceDocs"
                            />
  
                            <kyc-tab-item-input-select
                                label="Place of Birth"
                                :initial="birth_country_id"
                                :guide="getGuide(AS_COLUMN_BIRTH_COUNTRY_ID)"
                                :disabled="!isPermissionEditComplianceDocs"
                            />
  
                            <kyc-tab-item-input-select
                                label="Country of Residence"
                                :initial="residence_country_id"
                                :guide="getGuide(COLUMN_RESIDENCE_COUNTRY_ID)"
                                :disabled="!isPermissionEditComplianceDocs"
                            />
  
                            <kyc-tab-item-input-select
                                label="City of Residence"
                                :initial="residence_place"
                                :guide="getGuide(COLUMN_RESIDENCE_PLACE)"
                                :disabled="!isPermissionEditComplianceDocs"
                            />
  
                            <kyc-tab-item-input-select
                                label="Residental Address"
                                :initial="residence_address"
                                :guide="getGuide(COLUMN_RESIDENCE_ADDRESS)"
                                :disabled="!isPermissionEditComplianceDocs"
                            />
  
                            <kyc-tab-item-input-select
                                label="Zip / Postal Code"
                                :initial="residence_zip"
                                :guide="getGuide(COLUMN_RESIDENCE_ZIP)"
                                :disabled="!isPermissionEditComplianceDocs"
                            />
  
                            <kyc-tab-item-input-select
                                label="How did you hear about us"
                                :initial="heard_from_id"
                                :guide="getGuide(AS_COLUMN_HEARD_FROM_ID)"
                                :disabled="!isPermissionEditComplianceDocs"
                            />
  
                            <kyc-tab-item-input-select
                                label="I have one citizenship & its the same as my country of residence"
                                :guide="getGuide(COLUMN_CITIZENSHIP_EQUAL_RESIDENCE)"
                                :initial="citizenship_equal_residence"
                                :disabled="!isPermissionEditComplianceDocs"
                            />

                            <kyc-tab-item-list
                                label="Citizenship"
                                class="kyc-tab-item__tins"
                                :initial="citizenship_country_ids"
                                :column="COLUMN_CITIZENSHIP_IDS"
                                :disabled="!isPermissionEditComplianceDocs"
                            />
  
                            <kyc-tab-item-input-select
                                label="Political Exposed"
                                :initial="political_exposed"
                                :guide="getGuide(COLUMN_POLITICAL_EXPOSED)"
                                :disabled="!isPermissionEditComplianceDocs"
                            />

                            <kyc-tab-item-input-select
                                label="ID"
                                :initial="identity_number"
                                :guide="getGuide(COLUMN_IDENTITY_NUMBER)"
                                :disabled="!isPermissionEditComplianceDocs"
                            />

                            <kyc-tab-item-input-select
                                label="Driving License"
                                :initial="driving_license"
                                :guide="getGuide(COLUMN_DRIVING_LICENSE)"
                                :disabled="!isPermissionEditComplianceDocs"
                            />

                            <kyc-tab-item-input-select
                                label="Passport"
                                :initial="passport"
                                :guide="getGuide(COLUMN_PASSPORT)"
                                :disabled="!isPermissionEditComplianceDocs"
                            />
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="pr-15-procent">
                            <kyc-tab-subtitle subtitle="Fatca & CRS" />

                            <kyc-tab-item-list
                                label="TINs"
                                class="kyc-tab-item__tins"
                                :initial="is_tin_available && tins"
                                :column="COLUMN_TINS"
                                :disabled="!isPermissionEditComplianceDocs"
                            />
    
                            <kyc-tab-item-read-only
                                label="TIN Available"
                                :initial="is_tin_available_string"
                            />
    
                            <kyc-tab-item-input-select
                                label="Reason for not having TIN"
                                :initial="!is_tin_available && tin_absence_reason_id"
                                :guide="getGuide(AS_COLUMN_TIN_ABSENCE_REASON)"
                                :disabled="!isPermissionEditComplianceDocs"
                            />

                            <kyc-tab-item-read-only
                                label="FATCA: Are you a citizen of the USA or a resident of the USA for tax purposes?"
                                :initial="is_fatca"
                                :disabled="!isPermissionEditComplianceDocs"
                            />
    
                            <kyc-tab-item-read-only
                                label="USA Tax ID Number"
                                :initial="usa_tin"
                                :disabled="!isPermissionEditComplianceDocs"
                            />
                            <account-item
                                title="Live Accounts"
                                account-type="live_accounts"
                                is-accounts-live
                            />
                            <account-item
                                title="Demo Accounts"
                                account-type="demo_accounts"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import clientKycMixin from '~/components/mixins/client-kyc-mixin/client-kyc-mixin';
import KycTabSubtitle from './KycTabSubtitle';
import KycTabItemReadOnly from './KycTabItem/KycTabItemReadOnly';
import KycTabItemDate from './KycTabItem/KycTabItemDate';
import KycTabItemInputSelect from './KycTabItem/KycTabItemInputSelect';
import AccountItem from '~/components/KYC/AccountItem';
import KycTabItemPhone from '~/components/KYC/KycTabItem/KycTabItemPhone';
import configsMixin from '~/components/mixins/configs-mixin';
import { ELegalSubject } from '~/settings/enums/legal-subject.enum';
import { mapGetters } from 'vuex';
import { names } from '~/store/modules/leadDetails';
import kycTabItemList from '~/components/KYC/KycTabItem/kyc-tab-item-list/kyc-tab-item-list.vue';
import {
    isPermissionEditComplianceDocs,
    isPermissionEditClientEmailPhone,
} from '~/helpers/user-permission';
import {
    COLUMN_BIRTH_DATE,
    COLUMN_TINS,
} from '~/store/modules/_columnNames';

export default {
    components: {
        KycTabItemPhone,
        AccountItem,
        KycTabItemInputSelect,
        KycTabItemDate,
        KycTabItemReadOnly,
        KycTabSubtitle,
        kycTabItemList,
    },
    mixins : [
        configsMixin,
        clientKycMixin,
    ],
    data: () => ({
        COLUMN_BIRTH_DATE,
        COLUMN_TINS,
    }),
    computed: {
        ...mapGetters({
            leadDetails : names.getters.entity,
        }),
        isPermissionEditComplianceDocs,
        isPermissionEditClientEmailPhone,
        isBusinessClientType () {
            return this.leadDetails.legal_subject?.id === ELegalSubject.business;
        },
    }
};
</script>
