// Imports
import Vue from 'vue';

import { KycOtpLeadsTableModule } from '~/store/modules/kyc-trading/kyc-details-otp-table';

// Exports
export default Vue.extend({
    components: {

    },
    props: {
        title: {
            type: String,
            required: true
        }
    },
    data () {
        return {};
    },

    computed: {
        getKYCLeadsTableHeader (): Array<object> {
            return KycOtpLeadsTableModule.getterHeader;
        },
        getKYCLeadsTable (): Array<object> {
            return KycOtpLeadsTableModule.getterTable;
        }
    },
    created (): void {
        this.setTable();
    },
    methods: {
        setTable (): void {
            KycOtpLeadsTableModule.actionSetKycOtpLeadsTable();
        }
    }
});
