import Vue from 'vue';
import modalLayout from '~/components/modals/root-modal-layout/root-modal-layout.vue';
import modalsMixin from '~/components/mixins/modals';

export default Vue.extend({
    components: {
        modalLayout
    },
    mixins: [modalsMixin],
    data () {
        return {
            assign_user_id: null,
        };
    },
    methods: {
        async ok () {
            const isValid = await this.$refs.observer.validate();
            if (isValid) {
                this.closeModal();
                this.$attrs['modal-props'].ok(this.assign_user_id.id);
            }
        }
    }
});
