import router from '~/router';
import routes from '~/settings/routes';
import { EResponseStatus } from '~/settings/enums/response-status.enum';

export const handlerUserPermissionsRes = (response: Promise<Response>): void => {
    response.then((res) => {
        if (res.status === EResponseStatus.forbidden) {
            router.push(routes.unauthorizedAccess.path);
        }
    });
};
