// Imports
import Vue from 'vue';
import financeContentItem from '~/components/finance-content-item/finance-content-item.vue';
import AffiliatesHeader from '~/components/_tabs-headers/affiliates-header-tabs/affiliates-header-tabs.vue';

import { AffiliatesBonusPlansModule } from '~/store/modules/affiliates/affiliates-bonus-plans';
import { IBonusPlan, IBonusPlanUpdate } from '~/store/modules/bonus-plan/interfaces/bonus-plan-interfaces';
import { ModalsModule } from '~/store/modules/modals';
import { BonusPlanModule } from '~/store/modules/bonus-plan/bonus-plan';
// Exports
export default Vue.extend({
    components: {
        financeContentItem,
        AffiliatesHeader
    },
    data () {
        return {
            currentCategory: '',
        };
    },
    computed: {
        bonusPlans (): IBonusPlan[] {
            return AffiliatesBonusPlansModule.getBonusPlanItems;
        },
        isActiveBonusPlan (): number {
            return AffiliatesBonusPlansModule.getActiveBonusPlan;
        },
        getActiveCategory (): number {
            return AffiliatesBonusPlansModule.getActiveCategory;
        },
    },
    methods: {
        clickBonusPlan (id : number) {
            AffiliatesBonusPlansModule.setActiveBonusPlan(id);
        },
        createBonusPlan (activeCategory: number): void {
            ModalsModule.openModal({
                modalType: 'createBonusPlan',
                props: {
                    title: 'Add New Bonus Plan',
                    body: {
                        activeCategory,
                    },
                    isFooter: false,
                    isHeader: true,
                    isScroll: true,
                    ok: (payload: IBonusPlanUpdate) => {
                        this.handlerCreateBonusPlan(payload);
                    }
                },
                addClass: 'b-create-bonus-plan',
            });
        },
        async handlerCreateBonusPlan (payload: IBonusPlanUpdate): Promise<void> {
            await BonusPlanModule.createBonusPlan(payload)
                .then(response => {
                    if (response) {
                        ModalsModule.closeModal('createBonusPlan');
                        BonusPlanModule.loadBonusPlan();
                    }
                });
        }
    }
});
