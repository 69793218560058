import { ITableHeaderColumn } from '~/components/tables/interfaces/table-header-column.interfaces';
import { tableHeaderParser } from '../../helpers/table-header-parser';

export const leadsCreditCardsTableHeader: ITableHeaderColumn[] = tableHeaderParser(
    [
        {
            id: 1,
            name: 'type',
            value: 'Type',
            cellType: 'plain-text',
            cellData: 'subtype',
        },
        {
            id: 2,
            name: 'number',
            value: 'Card Number',
            cellType: 'plain-text',
            cellData: 'card_mask',
        },
        {
            id: 3,
            name: 'expiry',
            value: 'Expiry Date',
            cellType: 'plain-text',
            cellData: 'expiry_at',
            textAlign: 'center',
        },
        {
            id: 4,
            name: 'owner',
            value: 'Card Owner',
            cellType: 'plain-text',
            cellData: 'owner_name',
            textAlign: 'left',
        },
    ],
    6
);
