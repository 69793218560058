<template>
    <div class="d-flex flex-row justify-content-between align-items-center">
        <div class="details_navbar d-flex flex-row align-items-center">
            <slot name="return-navigation" />

            <router-link
                :to="details"
                tag="button"
                class="details_nav-link mr-3"
                :class="{'details_nav-link__button--disabled' : !isPermissionEditComplianceReadOnly}"
                :disabled="!isPermissionEditComplianceReadOnly"
            >
                Details
            </router-link>

            <router-link
                :to="kyc"
                tag="button"
                class="details_nav-link mr-3"
                :class="{'details_nav-link__button--disabled' : !isPermissionEditComplianceReadOnly}"
                :disabled="!isPermissionEditComplianceReadOnly"
            >
                Compliance
            </router-link>

            <router-link
                :to="trading"
                tag="button"
                class="details_nav-link mr-3"
                :class="{'details_nav-link__button--disabled' : !isPermissionEditComplianceReadOnly}"
                :disabled="!isPermissionEditComplianceReadOnly"
            >
                Trading
            </router-link>

            <router-link
                :to="finance"
                tag="button"
                class="details_nav-link mr-3"
                :class="{'details_nav-link__button--disabled' : !isPermissionEditComplianceReadOnly}"
                :disabled="!isPermissionEditComplianceReadOnly"
            >
                Finance
            </router-link>

            <router-link
                :to="marketing"
                tag="button"
                class="details_nav-link mr-3"
                :class="{'details_nav-link__button--disabled' : !isPermissionEditComplianceReadOnly}"
                :disabled="!isPermissionEditComplianceReadOnly"
            >
                Marketing
            </router-link>
            <router-link
                :to="activity"
                tag="button"
                class="details_nav-link mr-3"
                :class="{'details_nav-link__button--disabled' : !isEnableActivityLogTab}"
                :disabled="!isEnableActivityLogTab"
            >
                Activity Log
            </router-link>
        </div>

        <slot name="position-center" />

        <div class="details_new-tab d-flex flex-row align-items-center">
            <slot name="kyc-controls" />

            <app-button
                v-if="isDetails && isVerifyClient"
                type="button"
                icon="/img/clients/finance/Add_payment_method_plus.svg"
                modify="outline-primary"
                @click="openAddAccountModal"
            >
                Create Account
            </app-button>
        </div>
    </div>
</template>

<script>
import routes from '../../settings/routes';
import { ModalsModule } from '~/store/modules/modals';
import { ERouterNameFromPermission } from '~/settings/enums/router-name.enum';
import leadDetailsMixin from '@/js/components/mixins/leadDetailsMixin';
import { EAccountStatus } from '@/js/settings/enums/account-status.enum';
import {
    isPermissionEditComplianceReadOnly,
    isPermissionActivityLog,
    isPermissionAdmin,
} from '~/helpers/user-permission';

export default {
    mixins: [
        leadDetailsMixin,
    ],
    computed: {
        isPermissionEditComplianceReadOnly,
        isPermissionActivityLog,
        isPermissionAdmin,
        isVerifyClient () {
            return this.leadDetails?.verify_status?.id === EAccountStatus.verified;
        },
        details () {
            return routes.leadsItem.subRoutes.details._getPath(this.$route.params.id);
        },
        kyc () {
            return routes.leadsItem.subRoutes.kyc._getPath(this.$route.params.id);
        },
        trading () {
            return routes.leadsItem.subRoutes.trading._getPath(this.$route.params.id);
        },
        finance () {
            return routes.leadsItem.subRoutes.finance._getPath(this.$route.params.id);
        },
        marketing () {
            return routes.leadsItem.subRoutes.marketing._getPath(this.$route.params.id);
        },
        activity () {
            return routes.leadsItem.subRoutes.activity._getPath(this.$route.params.id);
        },
        isDetails () {
            return this.$route.name === ERouterNameFromPermission.leadsItemsDetails;
        },
        isEnableActivityLogTab () {
            return this.isPermissionActivityLog || this.isPermissionAdmin;
        },
    },
    methods: {
        openAddAccountModal () {
            ModalsModule.openModal({
                modalType: 'createAdditionalAccountModal',
                props: {
                    title: 'Create Account',
                    body: {},
                    isFooter: true,
                    isHeader: true
                },
            });
        }
    }
};
</script>
