export const AffiliatesMarketingRows = [
    {
        id: 1,
        campaignName: 'Buy Bitcoin Facebook',
        affiliateName: 'Matthew Whysall',
        bonusPlan: 'Bonus Plan',
        campaignType: 'Facebook',
        description: 'Very Very Very Long Campaign',
        size: 'Campaign Size',
        screenshot: {
            id: 123,
            text: 'test_campaign_name.png'
        },
        token: 'AAATROttFIReulmokhttZgOEDfiYoFbQ',
        userName: 'Matthew Whysall',
        impressions: 5,
        clicks: 5,
        bannersCtr: 67,
        landingPage: 'UK Securities Trading Desktop LP 01',
        lpImpressions: 5,
        leads: 5,
        bannersCtl: 5,
        conversions: 24,
        conversionsRate: 12,
        avgFtd: 3,
        totalDeposits: '$13,000',
        checked: false
    },
    {
        id: 2,
        campaignName: 'Buy Bitcoin Tweeter',
        affiliateName: 'Matthew Whysall',
        bonusPlan: 'Bonus Plan',
        campaignType: 'Tweeter',
        description: 'Long Campaign',
        size: 'Campaign Size',
        screenshot: {
            id: 3456,
            text: 'test_campaign_name.png'
        },
        token: 'AAATROttFIReulmokhttZgOEDfiYoFbQ',
        userName: 'Matthew Whysall',
        impressions: 12,
        clicks: 7,
        bannersCtr: 67,
        landingPage: 'UK Securities Trading Desktop LP 01',
        lpImpressions: 5,
        leads: 6,
        bannersCtl: 7,
        conversions: 24,
        conversionsRate: 12,
        avgFtd: 3,
        totalDeposits: '$10,000',
        checked: false
    },
    {
        id: 3,
        campaignName: 'UK Securities Trading Desktop',
        affiliateName: 'Joel Stransky',
        bonusPlan: 'Bonus Plan',
        campaignType: 'Taboola',
        description: 'Long Campaign',
        size: 'Campaign Size',
        screenshot: {
            id: 3456,
            text: 'test_campaign_name.png'
        },
        token: 'AAATROttFIReulmokhttZgOEDfiYoFbQ',
        userName: 'Joel Stransky',
        impressions: 12,
        clicks: 7,
        bannersCtr: 67,
        landingPage: 'UK Securities',
        lpImpressions: 5,
        leads: 6,
        bannersCtl: 7,
        conversions: 24,
        conversionsRate: 12,
        avgFtd: 3,
        totalDeposits: '$7,000',
        checked: false
    },
    {
        id: 4,
        campaignName: 'Buy Bitcoin Facebook',
        affiliateName: 'Matthew Whysall',
        bonusPlan: 'Bonus Plan',
        campaignType: 'Facebook',
        description: 'Very Very Very Long Campaign',
        size: 'Campaign Size',
        screenshot: {
            id: 123,
            text: 'test_campaign_name.png'
        },
        token: 'AAATROttFIReulmokhttZgOEDfiYoFbQ',
        userName: 'Matthew Whysall',
        impressions: 5,
        clicks: 5,
        bannersCtr: 67,
        landingPage: 'UK Securities Trading Desktop LP 01',
        lpImpressions: 5,
        leads: 5,
        bannersCtl: 5,
        conversions: 24,
        conversionsRate: 12,
        avgFtd: 3,
        totalDeposits: '$13,000',
        checked: false
    },
    {
        id: 5,
        campaignName: 'Buy Bitcoin Tweeter',
        affiliateName: 'Matthew Whysall',
        bonusPlan: 'Bonus Plan',
        campaignType: 'Tweeter',
        description: 'Long Campaign',
        size: 'Campaign Size',
        screenshot: {
            id: 3456,
            text: 'test_campaign_name.png'
        },
        token: 'AAATROttFIReulmokhttZgOEDfiYoFbQ',
        userName: 'Matthew Whysall',
        impressions: 12,
        clicks: 7,
        bannersCtr: 67,
        landingPage: 'UK Securities Trading Desktop LP 01',
        lpImpressions: 5,
        leads: 6,
        bannersCtl: 7,
        conversions: 24,
        conversionsRate: 12,
        avgFtd: 3,
        totalDeposits: '$10,000',
        checked: false
    },
    {
        id: 6,
        campaignName: 'UK Securities Trading Desktop',
        affiliateName: 'Joel Stransky',
        bonusPlan: 'Bonus Plan',
        campaignType: 'Taboola',
        description: 'Long Campaign',
        size: 'Campaign Size',
        screenshot: {
            id: 3456,
            text: 'test_campaign_name.png'
        },
        token: 'AAATROttFIReulmokhttZgOEDfiYoFbQ',
        userName: 'Joel Stransky',
        impressions: 12,
        clicks: 7,
        bannersCtr: 67,
        landingPage: 'UK Securities',
        lpImpressions: 5,
        leads: 6,
        bannersCtl: 7,
        conversions: 24,
        conversionsRate: 12,
        avgFtd: 3,
        totalDeposits: '$7,000',
        checked: false
    },
    // /
    {
        id: 7,
        campaignName: 'Buy Bitcoin Facebook',
        affiliateName: 'Matthew Whysall',
        bonusPlan: 'Bonus Plan',
        campaignType: 'Facebook',
        description: 'Very Very Very Long Campaign',
        size: 'Campaign Size',
        screenshot: {
            id: 123,
            text: 'test_campaign_name.png'
        },
        token: 'AAATROttFIReulmokhttZgOEDfiYoFbQ',
        userName: 'Matthew Whysall',
        impressions: 5,
        clicks: 5,
        bannersCtr: 67,
        landingPage: 'UK Securities Trading Desktop LP 01',
        lpImpressions: 5,
        leads: 5,
        bannersCtl: 5,
        conversions: 24,
        conversionsRate: 12,
        avgFtd: 3,
        totalDeposits: '$13,000',
        checked: false
    },
    {
        id: 8,
        campaignName: 'Buy Bitcoin Tweeter',
        affiliateName: 'Matthew Whysall',
        bonusPlan: 'Bonus Plan',
        campaignType: 'Tweeter',
        description: 'Long Campaign',
        size: 'Campaign Size',
        screenshot: {
            id: 3456,
            text: 'test_campaign_name.png'
        },
        token: 'AAATROttFIReulmokhttZgOEDfiYoFbQ',
        userName: 'Matthew Whysall',
        impressions: 12,
        clicks: 7,
        bannersCtr: 67,
        landingPage: 'UK Securities Trading Desktop LP 01',
        lpImpressions: 5,
        leads: 6,
        bannersCtl: 7,
        conversions: 24,
        conversionsRate: 12,
        avgFtd: 3,
        totalDeposits: '$10,000',
        checked: false
    },
    {
        id: 9,
        campaignName: 'UK Securities Trading Desktop',
        affiliateName: 'Joel Stransky',
        bonusPlan: 'Bonus Plan',
        campaignType: 'Taboola',
        description: 'Long Campaign',
        size: 'Campaign Size',
        screenshot: {
            id: 3456,
            text: 'test_campaign_name.png'
        },
        token: 'AAATROttFIReulmokhttZgOEDfiYoFbQ',
        userName: 'Joel Stransky',
        impressions: 12,
        clicks: 7,
        bannersCtr: 67,
        landingPage: 'UK Securities',
        lpImpressions: 5,
        leads: 6,
        bannersCtl: 7,
        conversions: 24,
        conversionsRate: 12,
        avgFtd: 3,
        totalDeposits: '$7,000',
        checked: false
    },
    {
        id: 10,
        campaignName: 'Buy Bitcoin Facebook',
        affiliateName: 'Matthew Whysall',
        bonusPlan: 'Bonus Plan',
        campaignType: 'Facebook',
        description: 'Very Very Very Long Campaign',
        size: 'Campaign Size',
        screenshot: {
            id: 123,
            text: 'test_campaign_name.png'
        },
        token: 'AAATROttFIReulmokhttZgOEDfiYoFbQ',
        userName: 'Matthew Whysall',
        impressions: 5,
        clicks: 5,
        bannersCtr: 67,
        landingPage: 'UK Securities Trading Desktop LP 01',
        lpImpressions: 5,
        leads: 5,
        bannersCtl: 5,
        conversions: 24,
        conversionsRate: 12,
        avgFtd: 3,
        totalDeposits: '$13,000',
        checked: false
    },
    {
        id: 11,
        campaignName: 'Buy Bitcoin Tweeter',
        affiliateName: 'Matthew Whysall',
        bonusPlan: 'Bonus Plan',
        campaignType: 'Tweeter',
        description: 'Long Campaign',
        size: 'Campaign Size',
        screenshot: {
            id: 3456,
            text: 'test_campaign_name.png'
        },
        token: 'AAATROttFIReulmokhttZgOEDfiYoFbQ',
        userName: 'Matthew Whysall',
        impressions: 12,
        clicks: 7,
        bannersCtr: 67,
        landingPage: 'UK Securities Trading Desktop LP 01',
        lpImpressions: 5,
        leads: 6,
        bannersCtl: 7,
        conversions: 24,
        conversionsRate: 12,
        avgFtd: 3,
        totalDeposits: '$10,000',
        checked: false
    },
    {
        id: 12,
        campaignName: 'UK Securities Trading Desktop',
        affiliateName: 'Joel Stransky',
        bonusPlan: 'Bonus Plan',
        campaignType: 'Taboola',
        description: 'Long Campaign',
        size: 'Campaign Size',
        screenshot: {
            id: 3456,
            text: 'test_campaign_name.png'
        },
        token: 'AAATROttFIReulmokhttZgOEDfiYoFbQ',
        userName: 'Joel Stransky',
        impressions: 12,
        clicks: 7,
        bannersCtr: 67,
        landingPage: 'UK Securities',
        lpImpressions: 5,
        leads: 6,
        bannersCtl: 7,
        conversions: 24,
        conversionsRate: 12,
        avgFtd: 3,
        totalDeposits: '$7,000',
        checked: false
    },
    {
        id: 13,
        campaignName: 'Buy Bitcoin Facebook',
        affiliateName: 'Matthew Whysall',
        bonusPlan: 'Bonus Plan',
        campaignType: 'Facebook',
        description: 'Very Very Very Long Campaign',
        size: 'Campaign Size',
        screenshot: {
            id: 123,
            text: 'test_campaign_name.png'
        },
        token: 'AAATROttFIReulmokhttZgOEDfiYoFbQ',
        userName: 'Matthew Whysall',
        impressions: 5,
        clicks: 5,
        bannersCtr: 67,
        landingPage: 'UK Securities Trading Desktop LP 01',
        lpImpressions: 5,
        leads: 5,
        bannersCtl: 5,
        conversions: 24,
        conversionsRate: 12,
        avgFtd: 3,
        totalDeposits: '$13,000',
        checked: false
    },
    {
        id: 14,
        campaignName: 'Buy Bitcoin Tweeter',
        affiliateName: 'Matthew Whysall',
        bonusPlan: 'Bonus Plan',
        campaignType: 'Tweeter',
        description: 'Long Campaign',
        size: 'Campaign Size',
        screenshot: {
            id: 3456,
            text: 'test_campaign_name.png'
        },
        token: 'AAATROttFIReulmokhttZgOEDfiYoFbQ',
        userName: 'Matthew Whysall',
        impressions: 12,
        clicks: 7,
        bannersCtr: 67,
        landingPage: 'UK Securities Trading Desktop LP 01',
        lpImpressions: 5,
        leads: 6,
        bannersCtl: 7,
        conversions: 24,
        conversionsRate: 12,
        avgFtd: 3,
        totalDeposits: '$10,000',
        checked: false
    },
    {
        id: 15,
        campaignName: 'UK Securities Trading Desktop',
        affiliateName: 'Joel Stransky',
        bonusPlan: 'Bonus Plan',
        campaignType: 'Taboola',
        description: 'Long Campaign',
        size: 'Campaign Size',
        screenshot: {
            id: 3456,
            text: 'test_campaign_name.png'
        },
        token: 'AAATROttFIReulmokhttZgOEDfiYoFbQ',
        userName: 'Joel Stransky',
        impressions: 12,
        clicks: 7,
        bannersCtr: 67,
        landingPage: 'UK Securities',
        lpImpressions: 5,
        leads: 6,
        bannersCtl: 7,
        conversions: 24,
        conversionsRate: 12,
        avgFtd: 3,
        totalDeposits: '$7,000',
        checked: false
    },
    {
        id: 16,
        campaignName: 'Buy Bitcoin Facebook',
        affiliateName: 'Matthew Whysall',
        bonusPlan: 'Bonus Plan',
        campaignType: 'Facebook',
        description: 'Very Very Very Long Campaign',
        size: 'Campaign Size',
        screenshot: {
            id: 123,
            text: 'test_campaign_name.png'
        },
        token: 'AAATROttFIReulmokhttZgOEDfiYoFbQ',
        userName: 'Matthew Whysall',
        impressions: 5,
        clicks: 5,
        bannersCtr: 67,
        landingPage: 'UK Securities Trading Desktop LP 01',
        lpImpressions: 5,
        leads: 5,
        bannersCtl: 5,
        conversions: 24,
        conversionsRate: 12,
        avgFtd: 3,
        totalDeposits: '$13,000',
        checked: false
    },
    {
        id: 17,
        campaignName: 'Buy Bitcoin Tweeter',
        affiliateName: 'Matthew Whysall',
        bonusPlan: 'Bonus Plan',
        campaignType: 'Tweeter',
        description: 'Long Campaign',
        size: 'Campaign Size',
        screenshot: {
            id: 3456,
            text: 'test_campaign_name.png'
        },
        token: 'AAATROttFIReulmokhttZgOEDfiYoFbQ',
        userName: 'Matthew Whysall',
        impressions: 12,
        clicks: 7,
        bannersCtr: 67,
        landingPage: 'UK Securities Trading Desktop LP 01',
        lpImpressions: 5,
        leads: 6,
        bannersCtl: 7,
        conversions: 24,
        conversionsRate: 12,
        avgFtd: 3,
        totalDeposits: '$10,000',
        checked: false
    },
    {
        id: 17,
        campaignName: 'UK Securities Trading Desktop',
        affiliateName: 'Joel Stransky',
        bonusPlan: 'Bonus Plan',
        campaignType: 'Taboola',
        description: 'Long Campaign',
        size: 'Campaign Size',
        screenshot: {
            id: 3456,
            text: 'test_campaign_name.png'
        },
        token: 'AAATROttFIReulmokhttZgOEDfiYoFbQ',
        userName: 'Joel Stransky',
        impressions: 12,
        clicks: 7,
        bannersCtr: 67,
        landingPage: 'UK Securities',
        lpImpressions: 5,
        leads: 6,
        bannersCtl: 7,
        conversions: 24,
        conversionsRate: 12,
        avgFtd: 3,
        totalDeposits: '$7,000',
        checked: false
    },
    {
        id: 18,
        campaignName: 'Buy Bitcoin Facebook',
        affiliateName: 'Matthew Whysall',
        bonusPlan: 'Bonus Plan',
        campaignType: 'Facebook',
        description: 'Very Very Very Long Campaign',
        size: 'Campaign Size',
        screenshot: {
            id: 123,
            text: 'test_campaign_name.png'
        },
        token: 'AAATROttFIReulmokhttZgOEDfiYoFbQ',
        userName: 'Matthew Whysall',
        impressions: 5,
        clicks: 5,
        bannersCtr: 67,
        landingPage: 'UK Securities Trading Desktop LP 01',
        lpImpressions: 5,
        leads: 5,
        bannersCtl: 5,
        conversions: 24,
        conversionsRate: 12,
        avgFtd: 3,
        totalDeposits: '$13,000',
        checked: false
    },
    {
        id: 19,
        campaignName: 'Buy Bitcoin Tweeter',
        affiliateName: 'Matthew Whysall',
        bonusPlan: 'Bonus Plan',
        campaignType: 'Tweeter',
        description: 'Long Campaign',
        size: 'Campaign Size',
        screenshot: {
            id: 3456,
            text: 'test_campaign_name.png'
        },
        token: 'AAATROttFIReulmokhttZgOEDfiYoFbQ',
        userName: 'Matthew Whysall',
        impressions: 12,
        clicks: 7,
        bannersCtr: 67,
        landingPage: 'UK Securities Trading Desktop LP 01',
        lpImpressions: 5,
        leads: 6,
        bannersCtl: 7,
        conversions: 24,
        conversionsRate: 12,
        avgFtd: 3,
        totalDeposits: '$10,000',
        checked: false
    },
    {
        id: 20,
        campaignName: 'UK Securities Trading Desktop',
        affiliateName: 'Joel Stransky',
        bonusPlan: 'Bonus Plan',
        campaignType: 'Taboola',
        description: 'Long Campaign',
        size: 'Campaign Size',
        screenshot: {
            id: 3456,
            text: 'test_campaign_name.png'
        },
        token: 'AAATROttFIReulmokhttZgOEDfiYoFbQ',
        userName: 'Joel Stransky',
        impressions: 12,
        clicks: 7,
        bannersCtr: 67,
        landingPage: 'UK Securities',
        lpImpressions: 5,
        leads: 6,
        bannersCtl: 7,
        conversions: 24,
        conversionsRate: 12,
        avgFtd: 3,
        totalDeposits: '$7,000',
        checked: false
    },
    {
        id: 21,
        campaignName: 'Buy Bitcoin Facebook',
        affiliateName: 'Matthew Whysall',
        bonusPlan: 'Bonus Plan',
        campaignType: 'Facebook',
        description: 'Very Very Very Long Campaign',
        size: 'Campaign Size',
        screenshot: {
            id: 123,
            text: 'test_campaign_name.png'
        },
        token: 'AAATROttFIReulmokhttZgOEDfiYoFbQ',
        userName: 'Matthew Whysall',
        impressions: 5,
        clicks: 5,
        bannersCtr: 67,
        landingPage: 'UK Securities Trading Desktop LP 01',
        lpImpressions: 5,
        leads: 5,
        bannersCtl: 5,
        conversions: 24,
        conversionsRate: 12,
        avgFtd: 3,
        totalDeposits: '$13,000',
        checked: false
    },
    {
        id: 22,
        campaignName: 'Buy Bitcoin Tweeter',
        affiliateName: 'Matthew Whysall',
        bonusPlan: 'Bonus Plan',
        campaignType: 'Tweeter',
        description: 'Long Campaign',
        size: 'Campaign Size',
        screenshot: {
            id: 3456,
            text: 'test_campaign_name.png'
        },
        token: 'AAATROttFIReulmokhttZgOEDfiYoFbQ',
        userName: 'Matthew Whysall',
        impressions: 12,
        clicks: 7,
        bannersCtr: 67,
        landingPage: 'UK Securities Trading Desktop LP 01',
        lpImpressions: 5,
        leads: 6,
        bannersCtl: 7,
        conversions: 24,
        conversionsRate: 12,
        avgFtd: 3,
        totalDeposits: '$10,000',
        checked: false
    },
    {
        id: 23,
        campaignName: 'UK Securities Trading Desktop',
        affiliateName: 'Joel Stransky',
        bonusPlan: 'Bonus Plan',
        campaignType: 'Taboola',
        description: 'Long Campaign',
        size: 'Campaign Size',
        screenshot: {
            id: 3456,
            text: 'test_campaign_name.png'
        },
        token: 'AAATROttFIReulmokhttZgOEDfiYoFbQ',
        userName: 'Joel Stransky',
        impressions: 12,
        clicks: 7,
        bannersCtr: 67,
        landingPage: 'UK Securities',
        lpImpressions: 5,
        leads: 6,
        bannersCtl: 7,
        conversions: 24,
        conversionsRate: 12,
        avgFtd: 3,
        totalDeposits: '$7,000',
        checked: false
    },
    {
        id: 24,
        campaignName: 'UK Securities Trading Desktop',
        affiliateName: 'Joel Stransky',
        bonusPlan: 'Bonus Plan',
        campaignType: 'Taboola',
        description: 'Long Campaign',
        size: 'Campaign Size',
        screenshot: {
            id: 3456,
            text: 'test_campaign_name.png'
        },
        token: 'AAATROttFIReulmokhttZgOEDfiYoFbQ',
        userName: 'Joel Stransky',
        impressions: 12,
        clicks: 7,
        bannersCtr: 67,
        landingPage: 'UK Securities',
        lpImpressions: 5,
        leads: 6,
        bannersCtl: 7,
        conversions: 24,
        conversionsRate: 12,
        avgFtd: 3,
        totalDeposits: '$7,000',
        checked: false
    },
];
