// Imports
import Vue from 'vue';
// Exports
export default Vue.extend({
    props: {
        isLabel: {
            type: String
        },
        noMargin: {
            type: Boolean,
        }
    },
    computed: {
        allBindings () {
            // Need to proxify both props and attrs, for example for showLabels
            return { ...this.$props, ...this.$attrs };
        },
        isNoMargin (): boolean {
            return this.noMargin;
        }
    },
    methods: {
        handleInputEvent (event) {
            this.$emit('change', event.target.checked);
        },
    },
});
