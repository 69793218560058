import { AppModule } from '~/store/modules/app/app';

export default {
    props: {
        symbol: {
            type: String,
            required: true,
        },
        color: {
            type: String,
            required: false,
            default: 'inherit',
        },
        size: {
            type: String,
            required: false,
            default: 'md'
        },
        title: {
            type: String,
            required: false,
            default: '',
        },
        addClass: {
            type: String,
        },
        cursor: {
            type: String,
        },
        delay: {
            type: Boolean,
        },
        customClassPopover: {
            type: String,
            default: '',
        },
    },
    data (): Object {
        return {
            className: 'b-material-icon',
            defaultClass: 'material-symbols-rounded',
        };
    },
    computed: {
        getDelayShow (): number {
            return this.delay ? 700 : 0;
        },

        getIsAfterLoadingContent (): boolean {
            return AppModule.getIsAfterLoadingContent;
        },
    },
};
