import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import { WithdrawalDepositTabHelpers } from './withdrawal-deposit-tab-helpers';
import { IWithdrawalDepositTab } from './withdrawalDepositTab.interfaces';
import store from '~/store/index';

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: 'WithdrawalDepositTab',
})
class WithdrawalDepositTab extends VuexModule {
    allTabs: IWithdrawalDepositTab[] = WithdrawalDepositTabHelpers;
    activeTab: number|null = null;

    get isActiveTab (): number|null {
        return this.activeTab;
    }

    get getTabs (): IWithdrawalDepositTab[] {
        return this.allTabs;
    }

    @Mutation
    mutationActiveTab (payload: number) {
        this.activeTab = payload;
    }

    @Action ({ commit: 'mutationActiveTab' })
    setActiveTab (payload: number) {
        return payload;
    }

}
export const WithdrawalDepositTabModule = getModule(WithdrawalDepositTab);
