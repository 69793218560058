import { PaymentMethodsModule } from '~/store/modules/configs/payment-methods/payment-methods';
import { EPaymentMethodName } from '~/settings/enums/payment-methods.enum';
import configsMixin from '~/components/mixins/configs-mixin';

export default {
    mixins: [
        configsMixin,
    ],
    computed: {
        isMethodsSelected: {
            get () {
                return PaymentMethodsModule.getPaymentMethodSelected;
            },
            set (value) {
                const isValue = value ||
                    PaymentMethodsModule.getPaymentMethodSelectedDefault;

                PaymentMethodsModule.setPaymentProviderMethodSelected(isValue);
            }
        },
        isMethodsSelectedValue () {
            return EPaymentMethodName[this.isMethodsSelected?.id];
        },
        isMethodsSelect () {
            return this.getConfigPaymentMethods
                .filter(payment => EPaymentMethodName[payment.id]);
        },
    }
};
