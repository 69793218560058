import { NotificationPrefabricated } from '~/helpers/modal-notification-services/modal-notification-services';
import {
    COLUMN_CITIZENSHIP_IDS,
    COLUMN_BIRTH_DATE,
    COLUMN_PHONE_MOBILE,
    COLUMN_PHONE_SECOND,
    COLUMN_TINS,
    AS_COLUMN_PHONE_MOBILE_COUNTRY,
    AS_COLUMN_PHONE_SECOND_COUNTRY,
} from '~/store/modules/_columnNames';

export const handlerSuccessNotifications = (column: string): void => {
    switch (column) {
    case COLUMN_CITIZENSHIP_IDS:
        NotificationPrefabricated.KycCitizenshipEditSuccess();
        break;
    case COLUMN_BIRTH_DATE:
        NotificationPrefabricated.KycDateEditSuccess();
        break;
    case COLUMN_TINS:
        NotificationPrefabricated.KycTinsEditSuccess();
        break;
    case COLUMN_PHONE_MOBILE:
    case COLUMN_PHONE_SECOND:
    case AS_COLUMN_PHONE_MOBILE_COUNTRY:
    case AS_COLUMN_PHONE_SECOND_COUNTRY:
        NotificationPrefabricated.KycPhoneSuccess();
        break;
    default:
        NotificationPrefabricated.KycInputEditSuccess();
        break;
    }

};
