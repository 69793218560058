<template>
    <div class="d-flex flex-column w-100 client_filters">
        <b-row class="mx-0 justify-content-between">
            <div class="row left_side left-column align-items-center">
                <div class="col-3">
                    <app-multiselect
                        v-model="fieldsSelected"
                        :options="isFilters"
                        :multiple="true"
                        label="value"
                        track-by="name"
                        searchable
                        :allow-empty="true"
                        :close-on-select="false"
                        :clear-on-select="false"
                        placeholder="Filters"
                        :clear-button="true"
                        no-margin
                        @onClear="resetFilters"
                    />
                </div>

                <button
                    class="btn btn-custom-outline-color btn-custom-outline-color--primary d-flex flex-row align-items-center ml-2 mr-3 filter-btn-lg"
                    @click="saveTemplate()"
                >
                    <material-icon
                        symbol="check_circle"
                        class="mr-2"
                    />
                    Save As Template
                </button>

                <app-button
                    modify="outline-green"
                    @click="loadLeads"
                >
                    <material-icon
                        symbol="refresh"
                    />
                </app-button>
            </div>
            <div class="d-flex flex-row align-items-stretch right-column right_side">
                <leads-filters-clients-status
                    v-if="buttonEntities"
                    :entities="buttonEntities"
                />
                <div
                    v-if="dropdownEntities"
                    class="ml-2 d-flex align-items-center position-relative closed-button"
                >
                    <app-button
                        ref="appButton"
                        class="multiselect-button b-button--pr-lg"
                        :class="{ 'active' : isFilterSelectActive}"
                        size="xxs"
                        modify="outline-red-bright"
                        :active="isFilterSelectActive"
                        @click="handlerFilterByStatus"
                    >
                        <material-icon
                            symbol="highlight_off"
                            class="mr-2"
                        />
                        {{ isFilterSelectLabel }}
                    </app-button>
                    <app-multiselect
                        v-model="failedAccsSelected"
                        class="filters-multiselect multiselect__rounded"
                        :options="dropdownEntities"
                        label="name"
                        track-by="verify_status_id"
                        :searchable="false"
                        :allow-empty="true"
                        :close-on-select="true"
                        :show-labels="false"
                        :custom-label="nameWithPerc"
                        no-margin
                        @select="filterByStatus"
                        @remove="clearFilterSelect"
                    />
                </div>
                <app-dropdown-btn
                    icon="more_vert"
                    custom-size="lg"
                    class="ml-2"
                    :options="isExportOptions()"
                    label="name"
                    track-by="name"
                    @select="handleLeadsEditMultipleModal($event)"
                />
            </div>
        </b-row>
    </div>
</template>

<script>
import { ModalsModule } from '~/store/modules/modals';
import {
    actionHandleFetchleads,
    actionHandleSelectedColumns,
    getterSelectedColumnsInFilter,
    actionExportSelectedRows,
    getterLeadsVerifyStatusesFilters,
    getterColumnsInFilter,
    actionHandleCleanSelectedColumns,
} from '~/store/modules/leads';
import {
    exportOptions,
    failedAccsOptions
} from './leadsFilterMultiselectOptions';
import { mapActions } from 'vuex';
import { ETableFilterKeyLeads, ETableFilterKeyPagination } from '~/components/tables/enums/table-filter-key.enum';
import LeadsFiltersClientsStatus from '~/components/leads/leads-filters-clients-status/leads-filters-clients-status.vue';
import { handlerRoutesQuery } from '~/helpers/handler-routes-query';
import appDropdownBtn from '~/components/parts/app-dropdown-btn/app-dropdown-btn.vue';
import { ELeadsFiltersClientStatusButton } from '~/settings/enums/leads-filters-client-status-button.enum';
import { EAccountStatus } from '~/settings/enums/account-status.enum';
import { isPermissionExportToExcel, isPermissionImportFromExcel } from '~/helpers/user-permission';

export default {
    components: {
        LeadsFiltersClientsStatus,
        appDropdownBtn,
    },
    data () {
        return {
            title: 'Leads',
            exportSelected: null,
            failedAccsSelected: '',
            componentOptions: {
                exportOptions,
                failedAccsOptions
            }
        };
    },
    computed: {
        isPermissionExportToExcel,
        isPermissionImportFromExcel,
        isFilters () {
            return this.$store.getters[getterColumnsInFilter];
        },
        fieldsSelected: {
            get () {
                return this.$store.getters[getterSelectedColumnsInFilter];
            },
            set (value) {
                this.$store.dispatch(actionHandleSelectedColumns, value);
            }
        },
        dropdownEntities () {
            return this.$store.getters[getterLeadsVerifyStatusesFilters].dropdown;
        },
        buttonEntities () {
            return this.$store.getters[getterLeadsVerifyStatusesFilters].buttons;
        },
        isFilterSelectLabel () {
            return this.failedAccsSelected ?
                `${ ELeadsFiltersClientStatusButton[this.failedAccsSelected.verify_status_id] || this.failedAccsSelected.name } ${ this.failedAccsSelected.percentage.toFixed(2) }%` :
                `${this.dropdownEntities[0].name || 'Closed'} ${this.dropdownEntities[0].percentage.toFixed(2) || '0'}%`;
        },
        isFilterSelectActive () {
            const verifyStatusIds = this.$route.query[ETableFilterKeyLeads.verify_status_id];
            return verifyStatusIds &&
                (
                    verifyStatusIds.includes(`${EAccountStatus.closed}`) ||
                    verifyStatusIds.includes(`${EAccountStatus.rejected}`) ||
                    verifyStatusIds.includes(`${EAccountStatus.rejected15D}`) ||
                    verifyStatusIds.includes(`${EAccountStatus.rejectedUnsuitableServices}`)
                );
        },
    },
    methods: {
        ...mapActions({
            handleFetchleads: actionHandleFetchleads,
        }),
        loadLeads () {
            const query = this.$router.currentRoute.query;

            if (query[ETableFilterKeyPagination.page]) {
                handlerRoutesQuery(ETableFilterKeyPagination.page, null);
            }
            this.handleFetchleads({
                ...query,
                [ETableFilterKeyPagination.page]: null,
            });
        },
        isExportOptions () {
            let array = [];
            if (this.isPermissionExportToExcel) {
                array.push(exportOptions.export);
            }
            if (this.isPermissionImportFromExcel) {
                array.push(exportOptions.import);
            }
            return array;
        },
        saveTemplate () {
            ModalsModule.openModal({
                modalType: 'leadsFiltersTemplateSave',
                props: {
                    title: 'Save As Template',
                    body: {

                    },
                    isHeader: true,
                    isFooter: true
                },
                modalSettings: {
                    props: {
                        minWidth: ModalsModule.modalWidth.xs,
                    },
                },
            });
        },
        handlerFilterByStatus () {
            if (this.$refs.appButton.active) {
                this.clearFilterSelect();
            } else {
                const selected = this.failedAccsSelected || this.dropdownEntities[0];
                this.filterByStatus(selected);
            }
        },
        handlerFilterByStatusDefault () {
            this.failedAccsSelected = this.dropdownEntities[0];
        },
        filterByStatus (selectedOption) {
            handlerRoutesQuery(ETableFilterKeyLeads.verify_status_id, selectedOption.verify_status_id);
            this.loadLeads();
        },
        clearFilterSelect () {
            handlerRoutesQuery(ETableFilterKeyLeads.verify_status_id, null);
            this.loadLeads();
        },
        nameWithPerc (option) {
            return `${ option.name } ${ option.percentage }%`;
        },

        handleLeadsEditMultipleModal (event) {
            if (event.modal) {
                ModalsModule.openModal({
                    modalType: event.modal.modalType,
                    props: {
                        title: event.modal.title,
                        body: {

                        },
                        isHeader: true,
                        isFooter: true
                    },
                    modalSettings: {
                        props: {
                            minWidth: ModalsModule.modalWidth.lg,
                        },
                    },
                    addClass: 'modal_filters'
                });
            }
            else {
                this.$store.dispatch(actionExportSelectedRows);
            }
        },
        resetFilters () {
            this.$store.dispatch(actionHandleCleanSelectedColumns);
        },
    }
};
</script>
