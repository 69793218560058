import Vue from 'vue';
import { PaymentStatusEnum } from '~/settings/enums/payment-status-enum';
import rowItemPropsMixin from '@/js/components/tables/mixins/table-cells-mixins/row-item-props-mixin';
import { isPermissionFinance } from '~/helpers/user-permission';

export default Vue.extend({
    mixins: [
        rowItemPropsMixin,
    ],
    props: {
        cell: {
            type: Object,
            required: false,
        }
    },
    computed: {
        isPermissionFinance,
        isStatus () {
            return this.cell.value.status_id === PaymentStatusEnum.approved || this.cell.value.status_id === PaymentStatusEnum.cancelled;
        }
    },
    methods: {
        approve () {
            this.$emit('approve', this.cell);
        },
        reject () {
            this.$emit('reject', this.cell);
        }
    }
});
