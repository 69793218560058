import router from '~/router';
import routes from '~/settings/routes';
import { ERouterNameByPermissionId, ERouterNameFromPermission } from '~/settings/enums/router-name.enum';
import { EPermissions } from '~/settings/enums/permissions.enum';
import { isUserPermissionDepartment, isUserPermission } from '~/helpers/user-permission';
import { ProfileModule } from '~/store/modules/profile/profile';
import { EAdminUsername } from '~/settings/enums/admin-username.enum';
import { EDepartments } from '~/settings/enums/departments.enum';

// permissionsSome - required some
// permissionsEvery - required every
const hasRoutePermission = (permissionsSome: number[] = [], permissionsEvery: number[] = []): void => {
    const isPermissionsEvery = permissionsEvery.length > 0;
    const isPermissionsSome = permissionsSome.length > 0;
    let isResult = false;

    const isResultSome = isPermissionsSome ?
        permissionsSome.some(permission => isUserPermission(permission)) :
        false;
    const isResultEvery = isPermissionsEvery ?
        permissionsEvery.every(permission => isUserPermission(permission)) :
        false;

    if (isPermissionsEvery && isPermissionsSome) {
        isResult = isResultEvery && isResultSome;
    } else if (!isPermissionsEvery && isPermissionsSome) {
        isResult = isResultSome;
    } else if (isPermissionsEvery && !isPermissionsSome) {
        isResult = isResultEvery;
    }

    if (!isResult) {
        router.push(routes.unauthorizedAccess.path);
    }
};

const hasRoutePermissionDepartment = (permissionsSome: number[] = [], permissionsEvery: number[] = []): void => {
    const isPermissionsEvery = permissionsEvery.length > 0;
    const isPermissionsSome = permissionsSome.length > 0;
    let isResult = false;

    const isResultSome = isPermissionsSome ?
        permissionsSome.some(permission => isUserPermissionDepartment(permission)) :
        false;
    const isResultEvery = isPermissionsEvery ?
        permissionsEvery.every(permission => isUserPermissionDepartment(permission)) :
        false;

    if (isPermissionsEvery && isPermissionsSome) {
        isResult = isResultEvery && isResultSome;
    } else if (!isPermissionsEvery && isPermissionsSome) {
        isResult = isResultSome;
    } else if (isPermissionsEvery && !isPermissionsSome) {
        isResult = isResultEvery;
    }

    if (!isResult) {
        router.push(routes.unauthorizedAccess.path);
    }
};

const hasRoutePermissionAdmin = (): void => {
    if (ProfileModule.getProfile.username !== EAdminUsername.admin) {
        router.push(routes.unauthorizedAccess.path);
    }
};


export const handlerRouters = (name: string): void => {
    switch (name) {
    case ERouterNameFromPermission.users:
        hasRoutePermission([ERouterNameByPermissionId[name]]);
        break;
    case ERouterNameFromPermission.leads:
    case ERouterNameFromPermission.leadsItem:
    case ERouterNameFromPermission.leadsItemsDetails:
        hasRoutePermission([ERouterNameByPermissionId[ERouterNameFromPermission.leads]]);
        break;
    case ERouterNameFromPermission.leadsItemsActivity:
        hasRoutePermission([
            EPermissions.activityLog,
        ]);
        break;
    case ERouterNameFromPermission.leadsItemsKyc:
    case ERouterNameFromPermission.leadsItemsMarketing:
    case ERouterNameFromPermission.compliance:
        hasRoutePermission([ERouterNameByPermissionId[ERouterNameFromPermission.compliance]]);
        break;
    case ERouterNameFromPermission.affiliates:
    case ERouterNameFromPermission.affiliatesPage:
        hasRoutePermission([
            EPermissions.affiliate,
        ]);
        break;
    case ERouterNameFromPermission.affiliatesCampaigns:
        hasRoutePermission([], [
            ERouterNameByPermissionId[ERouterNameFromPermission.affiliates],
            ERouterNameByPermissionId[ERouterNameFromPermission.affiliatesCampaigns],
        ]);
        break;
    case ERouterNameFromPermission.affiliatesReports:
    case ERouterNameFromPermission.affiliatesCompliance:
    case ERouterNameFromPermission.affiliatesBonusPlan:
        hasRoutePermissionDepartment([
            EDepartments.compliance,
            EDepartments.management,
        ]);
        break;
    case ERouterNameFromPermission.emails:
    case ERouterNameFromPermission.emailsMass:
    case ERouterNameFromPermission.emailsIndividual:
    case ERouterNameFromPermission.emailsInbox:
    case ERouterNameFromPermission.emailsChat:
    case ERouterNameFromPermission.emailsTemplates:
        hasRoutePermission([ERouterNameByPermissionId[ERouterNameFromPermission.emails]]);
        break;
    case ERouterNameFromPermission.calendar:
    case ERouterNameFromPermission.withdrawals:
    case ERouterNameFromPermission.accounting:
    case ERouterNameFromPermission.accountingReports:
    case ERouterNameFromPermission.commissionDatabase:
    case ERouterNameFromPermission.bonusPlan:
    case ERouterNameFromPermission.reports:
        hasRoutePermission([ERouterNameByPermissionId[name]]);
        break;
    case ERouterNameFromPermission.leadsItemsFinance:
        hasRoutePermission([
            EPermissions.compliancePayments,
            ERouterNameByPermissionId[ERouterNameFromPermission.leadsItemsFinance],
        ],
        [
            ERouterNameByPermissionId[ERouterNameFromPermission.compliance]
        ]);
        break;
    case ERouterNameFromPermission.notifications:
    case ERouterNameFromPermission.notificationsClients:
        hasRoutePermission([
            EPermissions.notificationsFunding,
            EPermissions.notificationsKyc,
            EPermissions.notificationsRegister
        ]);
        break;
    case ERouterNameFromPermission.settings:
        hasRoutePermission([
            EPermissions.settings,
        ]);
        break;
    case ERouterNameFromPermission.settingsGroup:
        hasRoutePermission([
            EPermissions.settingsGroup,
        ]);
        break;
    case ERouterNameFromPermission.documentsComponent:
        hasRoutePermission([
            EPermissions.settingsDocuments,
        ]);
        break;
    case ERouterNameFromPermission.settingsSymbols:
        hasRoutePermission([
            EPermissions.settingsSymbols,
        ]);
        break;
    case ERouterNameFromPermission.leadsItemsTrading:
        hasRoutePermission([],
            [
                ERouterNameByPermissionId[ERouterNameFromPermission.compliance],
                EPermissions.trading,
            ]);
        break;
    }

};
