import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import { arrayToMap, getSortCB } from '~/helpers/common';
import store from '~/store';
import Rest from '~/helpers/Rest';
import {
    urlDocumentsRequests,
    urlDocumentsCreatedRequests,
    urlDocumentRequestDelete,
} from '~/settings/ajax-urls';
import { DocumentRequestsMessageEnum } from './document-requests.enum';
import { NotificationPrefabricated } from '~/helpers/modal-notification-services/modal-notification-services';

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: 'DocumentRequests'
})

class DocumentRequests extends VuexModule {
    isLoading: Boolean = false;
    isLoaded: Boolean = false;
    errorLoad: String = '';
    entities: Object = {};

    get getterDocumentRequests (): Array<object> {
        if (this.entities) {
            return Object.values(this.entities).sort(getSortCB('id')).map(item => {
                return {
                    ...item as {},
                    type: item.type
                };
            });
        }
        return [];
    }
    @Mutation
    mutationSetEntities (payload: Array<any>) {
        this.entities = { ...arrayToMap(payload, 'id') };
    }
    @Mutation
    mutationSetEntitiesItem ({ id, entitiesItem }) {
        this.entities = { ...this.entities, [id] : { ...entitiesItem } };
    }
    @Mutation
    mutationRemoveEntitiesItem (id: number) {
        const entities = { ...this.entities };
        delete entities[id];
        this.entities = { ...entities };
    }
    @Action({ commit: 'mutationSetEntities', rawError: true })
    async actionFetchDocumentsRequests (id: number) {
        const res = await Rest.GET(urlDocumentsRequests(id));
        const { data } = await res.json();
        return data;
    }
    @Action({ commit: 'mutationSetEntitiesItem', rawError: true })
    async actionHandleCreatedDocumentsRequest ({ client_id, type_id, comment }) {
        const body = {
            type_id: type_id,
            comment: comment
        };
        const response = await Rest.POST(urlDocumentsCreatedRequests(client_id), body);

        if (response.status >= 400) {
            await NotificationPrefabricated.Error(DocumentRequestsMessageEnum.Error);
        }

        await NotificationPrefabricated.PendingDocumentsSuccess();

        const { data } = await response.json();
        return { id: data['id'], entitiesItem: data };
    }
    @Action
    actionHandlerRemovedDocumentRequest ({ id, client_id }) {
        Rest.DELETE(urlDocumentRequestDelete(client_id, id))
            .then(Rest.middleNoContent)
            .then(() => {
                this.mutationRemoveEntitiesItem(id);
            })
            .catch(Rest.simpleCatch);
    }
}
export const DocumentRequestsModule = getModule(DocumentRequests);
