// Imports
import Vue from 'vue';

export default Vue.extend({
    data () {
        return {
            bookNowHidingTemporaryFlag: true
        };
    }
});
