// Imports
import Vue from 'vue';
import { ModalsModule } from '~/store/modules/modals';
import { DepartmentsModule } from '~/store/modules/departments/departments';
import { IDepartments, IDepartmentsPayload } from '~/store/modules/departments/interfaces/departments-interfaces';
import { EAccessSettingsModalTypes } from '~/settings/enums/access-settings-modal-types.enum';
import configsMixin from '~/components/mixins/configs-mixin';
import { handlerPermissionsGrouped } from '~/store/modules/configs/helper/handler-permissions-grouped';

// Exports
export default Vue.extend({
    mixins: [
        configsMixin,
    ],
    props: {
        department: {
            type: Object,
            required: true,
        },
    },
    data () {
        return {
            permissionsPercentAccess: {
                users: 0,
                clients: 0,
                menu: 0,
            },
        };
    },
    created (): void {
        this.percentAccess();
    },
    methods: {
        percentAccess (): void {
            const departmentPermissionGrouped = handlerPermissionsGrouped(this.department.permissions);
            this.permissionsPercentAccess = {
                users: this.formulaPercentAccess(departmentPermissionGrouped.users.length, this.getConfigPermissionsGrouped.users.length),
                clients: this.formulaPercentAccess(departmentPermissionGrouped.clients.length, this.getConfigPermissionsGrouped.clients.length),
                menu: this.formulaPercentAccess(departmentPermissionGrouped.menu.length, this.getConfigPermissionsGrouped.menu.length),
            };
        },
        formulaPercentAccess (departmentPermission: number, permissionsGroup: number): number {
            return Math.ceil((departmentPermission / (permissionsGroup / 100)) / 10);
        },
        async loadDepartments (): Promise<void> {
            await DepartmentsModule.loadDepartments();
        },
        async loadDepartmentDetails (id: number): Promise<IDepartments> {
            return await DepartmentsModule.loadDepartmentDetails(id);
        },
        getFirstLetter (value: string): string {
            const stringsArray = value.split(' ');
            let str = '';
            stringsArray.forEach(item => {
                str += item.charAt(0);
            });
            return str;
        },
        async saveSettingsDepartmentGroup (payload: IDepartmentsPayload) {
            await DepartmentsModule.updateDepartment({
                id: this.department.id,
                permissions: payload.permissions,
                name: payload.name,
                email: payload.email,
            });
            await this.loadDepartments();
            this.percentAccess();
        },
        async accessSetting (id: number): Promise<void> {
            const departmentDetails = await this.loadDepartmentDetails(id);
            ModalsModule.openModal({
                modalType: 'accessSettingsModal',
                props: {
                    title: 'Access Settings',
                    body: {
                        type: EAccessSettingsModalTypes.departmentGroup,
                        name: departmentDetails.name,
                        email: departmentDetails.email,
                        permissions: departmentDetails.permissions,
                    },
                    isHeader: true,
                    isFooter: true,
                    ok: (payload: IDepartmentsPayload) => this.saveSettingsDepartmentGroup(payload)
                },
                modalSettings: {
                    props: {
                        minWidth: ModalsModule.modalWidth.lg,
                    },
                },
                addClass: 'modal-users-access'
            });
        },
        openActionModal (event, id: number): void {
            ModalsModule.openModal({
                modalType: 'actionModal',
                props: {
                    body: {
                        text: 'Are you sure you want to delete this group?',
                    },
                    isHeader: true,
                    isFooter: false,
                    confirm: () => {
                        this.removeGroupItem(id);
                    }
                },
                modalSettings: {
                    props: {
                        minWidth: ModalsModule.modalWidth.xs,
                    },
                    positionModal: {
                        clientX: event.clientX,
                        clientY: event.clientY,
                        modalWidth: ModalsModule.modalWidth.xs,
                    }
                },
            });
        },
        removeGroupItem (id: number): void {
            console.log(id);
        },
    },
});
