import Vue from 'vue';
import EmailsEditor from '../emails-editor/emails-editor.vue';
import Attachments from '~/components/attachments/attachments.vue';
import appMultiSelect from '~/components/parts/app-multiselect/app-multiselect.vue';
import { EmailFilterClientsModule } from '~/store/modules/email-filter-clients/email-filter-clients';
import { IPagination } from '~/settings/interfaces/pagination-interfaces';
import { SendMailModule } from '~/store/modules/send-mail/send-mail';
import { SendMailIndividualEnums } from '~/store/modules/send-mail/send-mail.enums';
import { removeEmptyFields } from '~/helpers/remove-empty-fields';
import { IEmailTemplatesPostRequest } from '~/store/modules/emails/emails-interfaces';

export default Vue.extend({
    components: {
        appMultiSelect,
        EmailsEditor,
        Attachments
    },
    data () {
        return {
            searchResult: [],
            selectedLead: {},
            leadId: '',
            f_name: '',
            l_name: '',
            email: '',
            isDragging: false,
        };
    },
    computed: {
        getPagination (): IPagination {
            return EmailFilterClientsModule.getPagination;
        },
        isResultLastPage (): boolean {
            return this.getPagination.last_page === this.getPagination.current_page;
        },
        totalResult (): number {
            return this.getPagination.total;
        },
        isNotEmptySearchResult (): boolean {
            return this.searchResult.length > 0;
        },
    },
    methods: {
        resetSearchResult (): void {
            this.searchResult = [];
        },
        resetSelectedLead (): void {
            this.selectedLead = {};
        },
        async onFilter (): Promise<void> {
            this.resetSearchResult();
            this.resetSelectedLead();
            this.fetchClients();
        },
        async fetchClients (page: number = 1): Promise<void> {
            const payload = removeEmptyFields({
                [SendMailIndividualEnums.ClientId]: this.leadId,
                [SendMailIndividualEnums.FirstName]: this.f_name,
                [SendMailIndividualEnums.LastName]: this.l_name,
                [SendMailIndividualEnums.Email]: this.email,
            });

            const { data } = await EmailFilterClientsModule.fetchFilteredClients({
                ...payload,
                page,
            });
            this.searchResult.push(...data);
        },
        async loadMore () {
            const {
                current_page,
                last_page,
            } = this.getPagination;
            if (current_page < last_page) {
                await this.fetchClients(current_page + 1);
            }
        },
        sendEmail (payload: IEmailTemplatesPostRequest) {
            SendMailModule.clientSendEmail({
                template: 'clients_email', // TODO must be removed
                leadId: this.selectedLead.id,
                ...payload,
            });
        },
        changeDragging (event: boolean): void {
            this.isDragging = event;
        },
    }
});
