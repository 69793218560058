<template>
    <div class="tab-content">
        <div
            v-bar
            class="content-scroll kyc-content-scroll--questionnaire"
        >
            <div
                ref="contentScroll"
                class="content-scroll__content"
            >
                <div class="row">
                    <div
                        :class="[
                            getColumns,
                            'pl-0',
                            isSecondColumn ? 'kyc-item-width-85-procent' : 'kyc-item-width-100-procent',
                        ]"
                    >
                        <div v-if="!accountDeclarationTemporaryHideFlag">
                            <div>
                                <kyc-tab-subtitle subtitle="Account Holder Declaration" />
    
                                <kyc-tab-item-input-select
                                    label="Form Completed by Another Person"
                                    :guide="getGuide(COLUMN_AQM_ON_BEHALF_OF)"
                                    :disabled="!isPermissionEditComplianceDocs"
                                />
    
                                <kyc-tab-item-input-select
                                    label="Capacity / Authority"
                                    :guide="getGuide(COLUMN_AQM_ON_BEHALF_OF_CAPACITY)"
                                    :disabled="!isPermissionEditComplianceDocs"
                                />
    
                                <div
                                    v-for="awarenessMessage in awareness_messages"
                                    :key="`${awarenessMessage.id}${awarenessMessage.version}`"
                                    class="kyc-tab-item kyc-tab-item-awareness"
                                >
                                    <div class="kyc-tab-subtitle">
                                        <h3
                                            class="kyc-tab-subheader"
                                            v-html="awarenessMessage.name"
                                        />
                                    </div>
                                    <div class="kyc-tab-info">
                                        <div
                                            class="kyc-tab-info__label"
                                            v-html="awarenessMessage.version_text"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div :class="[widthColumn, 'no-border-bottom-last-child']">
                            <kyc-tab-subtitle subtitle="Questionnaire Declarations" />
                            <kyc-tab-item-read-only
                                label="Completed Client Questionnaire on Date:"
                                :initial="isCompleteQuestionnaireDate"
                            />
                            <kyc-tab-item-verified
                                label-since="Verified since and by:"
                                :initial="verified_status_by_user"
                            />
                            <kyc-tab-item-read-only
                                label="From IP Address:"
                                :initial="ipAddressInBrackets"
                            />
    
                            <div
                                v-for="checkboxConsent in getConfigCheckboxConsents"
                                :key="`${checkboxConsent.id}${checkboxConsent.version}`"
                            >
                                <kyc-tab-item-terms-and-condition
                                    v-if="showTermsAndCondition(checkboxConsent.id)"
                                    label="I agree to the"
                                    url-name="Terms & Conditions"
                                    :initial="terms_and_condition"
                                    :ip-address="ip_address"
                                />
                                <div class="kyc-tab-item">
                                    <div class="kyc-tab-info">
                                        <div
                                            class="kyc-tab-info__label mb-2"
                                            v-html="checkboxConsent.version_text"
                                        />
                                        <div class="kyc-tab-info__value">
                                            {{ isClientCheckboxConsents(checkboxConsent.id) }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <kyc-tab-subtitle
                                subtitle="Authorized Signatories"
                                content-title
                            />

                            <kyc-tab-item-signatories
                                :first-name="fname"
                                :middle-name="mname"
                                :last-name="lname"
                                :email="email"
                            />

                            <template
                                v-if="awareness_messages_account_holder_declaration.length"
                            >
                                <kyc-tab-subtitle
                                    subtitle="Declarations"
                                    content-title
                                />
    
                                <div
                                    :class="[
                                        'kyc-tab-item',
                                        'kyc-tab-item--pt-0',
                                        'kyc-tab-item--pl-0',
                                        'declaration-wrapper',
                                    ]"
                                >
                                    <kyc-tab-item-declarations
                                        v-for="declaration in awareness_messages_account_holder_declaration"
                                        :key="declaration.id"
                                        :declaration="declaration"
                                        :current-declaration="currentDeclaration || {}"
                                        @toggleDeclaration="toggleDeclaration"
                                    />
                                </div>
                            </template>

                            <kys-tab-item-requests
                                v-if="go_pro_answers && go_pro_answers.status"
                                :initial="go_pro_answers"
                                :is-second-column="isSecondColumn"
                                @toggleProTraderForm="toggleProTraderForm"
                                @setProTraderFormText="setProTraderFormText"
                            />
                        </div>
                    </div>

                    <div class="col-6">
                        <div :class="widthColumn">
                            <div
                                v-if="currentDeclaration"
                                v-click-outside="hideCurrentDeclaration"
                            >
                                <kyc-tab-subtitle
                                    :subtitle="currentDeclaration.name"
                                />
        
                                <kyc-tab-item-text
                                    :text="currentDeclaration.version_text"
                                    class-name="kyc-tab-info__label mb-2 declaration-column"
                                />
                            </div>
                            
                            <div
                                v-if="proTraderFormText"
                                v-click-outside="hideProTraderForm"
                            >
                                <kyc-tab-subtitle
                                    subtitle="ProTrader Form"
                                />
    
                                <kyc-tab-item-text
                                    :text="proTraderFormText"
                                    class-name="pro-trader-form-column"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import KycTabSubtitle from './KycTabSubtitle';
import KycTabItemVerified from './KycTabItem/kyc-tab-item-verified/kyc-tab-item-verified.vue';
import KycTabItemInputSelect from './KycTabItem/KycTabItemInputSelect';
import clientKycMixin from '~/components/mixins/client-kyc-mixin/client-kyc-mixin';
import KycTabItemReadOnly from './KycTabItem/KycTabItemReadOnly';
import KycTabItemTermsAndCondition from './KycTabItem/kyc-tab-item-terms-and-condition/kyc-tab-item-terms-and-condition.vue';
import KycTabItemText from './KycTabItem/kyc-tab-item-text/kyc-tab-item-text.vue';
import { QuestionnaireEnumId } from './kyc-enums';
import configsMixin from '~/components/mixins/configs-mixin';
import kycTabItemSignatories from './KycTabItem/kyc-tab-item-signatories/kyc-tab-item-signatories';
import { sliceHoursMinutes } from '~/helpers/sliceHoursMinutes';
import kycTabItemDeclarations from './KycTabItem/kyc-tab-item-declarations/kyc-tab-item-declarations';
import { getStringFromFieldValueData } from '~/components/KYC/kyc-helpers';
import kysTabItemRequests from '@/js/components/KYC/KycTabItem/kyc-tab-item-requests/kyc-tab-item-requests.vue';
import { isPermissionEditComplianceDocs } from '~/helpers/user-permission';

export default {
    components: {
        KycTabItemReadOnly,
        KycTabItemInputSelect,
        KycTabSubtitle,
        KycTabItemTermsAndCondition,
        KycTabItemText,
        KycTabItemVerified,
        kycTabItemSignatories,
        kycTabItemDeclarations,
        kysTabItemRequests,
    },
    mixins: [
        clientKycMixin,
        configsMixin,
    ],
    data () {
        return {
            accountDeclarationTemporaryHideFlag: true,
            currentDeclaration: null,
            noValue: 'N/A',
            proTraderFormText: null,
        };
    },
    computed: {
        isPermissionEditComplianceDocs,
        isCompleteQuestionnaireDate () {
            return this.complete_questionnaire_at
                ? `Date: ${this.complete_questionnaire_at.date} Time: ${sliceHoursMinutes(this.complete_questionnaire_at.time)} ${getStringFromFieldValueData(this.ip_address) && '| IP: ' + getStringFromFieldValueData(this.ip_address)}`
                : this.noValue;
        },
        isSecondColumn () {
            return !!this.currentDeclaration || !!this.proTraderFormText;
        },
        getColumns () {
            return this.isSecondColumn ? 'col-6' : 'col-12';
        },
        ipAddressInBrackets () {
            return getStringFromFieldValueData(this.ip_address, {
                visibleCustom: true,
                customBrackets: true,
                defaultReturn: true,
            });
        },
        widthColumn () {
            return this.isSecondColumn ? 'pr-15-procent' : '';
        },
    },
    methods: {
        showTermsAndCondition (questionnaireId) {
            return questionnaireId === QuestionnaireEnumId.AcceptRisks;
        },
        isClientCheckboxConsents (configId) {
            return !!this.checkbox_consents
                && this.checkbox_consents.some(item => item.id === configId)
                ? `Yes ${this.complete_questionnaire_at ? `| ${this.isCompleteQuestionnaireDate}` : ''}`
                : 'No';
        },
        toggleDeclaration (declaration) {
            this.currentDeclaration = this.currentDeclaration === declaration
                ? null
                : declaration;
        },
        hideCurrentDeclaration (event) {
            if (this.currentDeclaration && !event.target.attributes.getNamedItem('data-open-declaration')) {
                this.currentDeclaration = null;
            }
        },
        toggleProTraderForm (text) {
            this.proTraderFormText = this.proTraderFormText === text
                ? null
                : text;
        },
        hideProTraderForm (event) {
            if (
                this.proTraderFormText
                && !event.target.attributes.getNamedItem('data-open-pro-request')
                && !event.target.closest('.history-parent__list')
            ) {
                this.proTraderFormText = null;
            }
        },
        setProTraderFormText (proTraderFormText) {
            this.proTraderFormText = proTraderFormText;
        },
    }
};
</script>
