// Imports
import { Module, VuexModule, getModule, Action, Mutation } from 'vuex-module-decorators';
import { tableParser } from '~/helpers/table-parser';
import { affiliatesCampaignsTableHeader } from '~/components/tables/_headers/affiliates-campaigns';
import store from '~/store';
import Rest from '~/helpers/Rest';
import {
    urlCampaignsList,
    urlUsersConfig,
    urlCampaignsPost,
    urlCampaignUpdate,
    urlCampaignMassiveUpdate,
    urlCampaignDelete,
    urlCampaignMassiveDelete,
} from '~/settings/ajax-urls';

import {
    ICreateCampaignBody,
    IDeleteBody,
    IEditBulkBody,
    IEditSingleBody
} from '~/components/modals/affiliates-campaigns/interfaces';
import { ITableFilterQuery } from '~/components/tables/interfaces/table-filter-query.interfaces';
import { ETableType } from '~/components/tables/enums/table-type.enum';
import { handlerRestResponse } from '~/helpers/handler-rest-response';
import { NotificationPrefabricated } from '~/helpers/modal-notification-services/modal-notification-services';
import { ICountries, ILanguages } from '~/store/modules/configs/interfaces/configs-interface';
import { IPagination } from '~/settings/interfaces/pagination-interfaces';
import { showNothingWasFound } from '~/components/tables/helpers/showNothingWasFound';
import { EIdKey } from '~/settings/enums/id-key-for-table-parser-enum';


type TObjectWithId = {
    'id': number,
    'name': string
}

interface ICampaignItem {
    id: number,
    name: string,
    language: ILanguages,
    country: ICountries,
    comment: string,
    affiliate: TObjectWithId | null,
    user:  TObjectWithId | null,
    token: string,
    type: string | null,
    active: boolean,
    is_disabled: number,
    source: string,
}

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: 'affiliatesCampaigns',
})
class affiliatesCampaigns extends VuexModule {
    campaignsTableRows: Array<ICampaignItem> = [];
    users: Array<object> = [];
    loaded: boolean = false;
    affiliatesCampaignsPagination: IPagination = {
        current_page: null,
        from: null,
        last_page: null,
        per_page: null,
        to: null,
        total: null,
    };

    get getterHeader (): Array<object> {
        return affiliatesCampaignsTableHeader;
    }

    get getCampaignsList (): Array<ICampaignItem> {
        return this.campaignsTableRows;
    }

    get getterRows (): Array<object> {
        const parsedRows = tableParser(affiliatesCampaignsTableHeader, this.campaignsTableRows, EIdKey.id, ETableType.campaigns);
        return parsedRows;
    }

    get getUsers () {
        return this.users;
    }

    get getCampaignsIsLoaded (): boolean {
        return this.loaded;
    }

    get getAffiliatesCampaignsPagination (): IPagination {
        return this.affiliatesCampaignsPagination;
    }

    @Action
    async fetchCampaignsList (query: ITableFilterQuery = null) {
        this.setLoadedState(false);
        const { data, meta } = await Rest.GET(urlCampaignsList(query))
            .then(Rest.middleThen)
            .catch(Rest.simpleCatch)
            .finally(() => {
                this.setLoadedState(true);
                NotificationPrefabricated.cleanAllNotify();
            });

        showNothingWasFound(data.length);
        this.mutationTableList(data);
        this.setAffiliatesCampaignsPagination(meta);
    }

    @Action
    async fetchUsersAndAffiliatesList () {
        const response = await Promise.all([
            Rest.GET(urlUsersConfig())
        ]);
        const usersList = await response[0].json();
        this.mutationUsersList(usersList.data);
    }

    @Action
    async saveNewlyCreatedCampaign (body: ICreateCampaignBody) {
        return await Rest.POST(
            urlCampaignsPost,
            body
        )
            .then(response => {
                return handlerRestResponse(response, NotificationPrefabricated.createdAffiliateCampaignSuccess);
            })
            .catch(Rest.simpleCatch);
    }

    @Action
    async editCampaign ({ id, body }:{id: number, body: IEditSingleBody}) {
        const response = await Rest.PUT(
            urlCampaignUpdate(id),
            body
        );
        const { data } = await response.json();
        return data;
    }

    @Action
    async editCampaignBulk (payload: IEditBulkBody) {
        const response = await Rest.PUT(
            urlCampaignMassiveUpdate(payload.campaign_ids),
            payload,
        );

        const { data } = await response.json();
        return data;
    }

    @Action({ rawError: true })
    async deleteCampaign ({ id, body }: {id: number, body: IDeleteBody}) {
        try {
            await Rest.DELETE(
                urlCampaignDelete(id),
                body
            );
        } catch (e) {
            console.error(e);
        }
        return true;
    }

    @Action({ rawError: true })
    async deleteBulkCampaign (body: IDeleteBody) {
        try {
            await Rest.DELETE(
                urlCampaignMassiveDelete,
                body
            );
        } catch (e) {
            console.error(e);
        }
        return true;
    }

    @Mutation
    mutationTableList (affiliates) {
        this.campaignsTableRows = affiliates;
    }

    @Mutation
    mutationUsersList (usersConfig) {
        this.users = usersConfig;
    }

    @Mutation
    setLoadedState (payload: boolean) {
        this.loaded = payload;
    }

    @Mutation
    setAffiliatesCampaignsPagination (payload: IPagination) {
        this.affiliatesCampaignsPagination = payload;
    }
}

export const affiliatesCampaignsModule = getModule(affiliatesCampaigns);
