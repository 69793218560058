import Vue from 'vue';
import { LeadsFiltersTemplateSaveInterfaces } from './interfaces/leads-filters-template-save-interfaces';
import { getterSelectedColumnsInFilter, getterColumnsInFilter } from '~/store/modules/leads';
import { LeadsFilterTemplatesModule } from '~/store/modules/leads-filter-templates/leads-filter-templates';
import modalLayout from '~/components/modals/root-modal-layout/root-modal-layout.vue';
import modalsMixin from '~/components/mixins/modals';

export default Vue.extend({
    components: {
        modalLayout,
    },
    mixins: [modalsMixin],
    data (): LeadsFiltersTemplateSaveInterfaces {
        return {
            templateName: ''
        };
    },
    computed: {
        isFilters (): Array<any> {
            return this.$store.getters[getterColumnsInFilter];
        },
        filterSelected: {
            get (): Array<any> {

                return LeadsFilterTemplatesModule.getFiltersToModal;
            },
            set (value: Array<any>) {
                LeadsFilterTemplatesModule.setFiltersToModal(value);
            }
        }
    },
    created () {
        this.handleShowModal();
    },
    methods: {
        saveTemplate () {
            if (this.templateName) {
                LeadsFilterTemplatesModule.saveTemplate(this.templateName);
                this.closeModal();
            }
        },
        handleShowModal () {
            const filters: Array<any> = this.$store.getters[getterSelectedColumnsInFilter];
            LeadsFilterTemplatesModule.setFiltersToModal(filters);
        },
    }
});
