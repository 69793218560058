<template>
    <div class="tab-content">
        <div
            v-bar
            class="content-scroll kyc-content-scroll--questionnaire"
        >
            <div class="content-scroll__content">
                <div class="row">
                    <div class="col-6">
                        <div class="pr-15-procent">
                            <kyc-tab-subtitle subtitle="Assessment" />

                            <template v-if="isLoadAssessmentQuestions">
                                <kyc-tab-item-question-answer
                                    v-for="(assessmentQuestion, index) in assessmentQuestions"
                                    :key="assessmentQuestion.field_id + assessmentQuestion.field.name + index"
                                    :disabled="isDisabled(assessmentQuestion)"
                                    :initial="assessmentQuestion"
                                />
                            </template>

                            <kyc-tab-subtitle subtitle="Investment Knowledge" />

                            <template v-if="isLoadInvestmentQuestions">
                                <kyc-tab-item-question-answer
                                    v-for="(investmentQuestion, index) in investmentQuestions"
                                    :key="investmentQuestion.field_id + investmentQuestion.field.name + index"
                                    :disabled="isDisabled(investmentQuestion)"
                                    :initial="investmentQuestion"
                                />
                            </template>
                        </div>
                    </div>

                    <div class="col-6">
                        <div class="pr-15-procent no-border-bottom-last-child">
                            <kyc-tab-subtitle subtitle="Economic & Financial Profile" />

                            <kyc-tab-item-input-select
                                label="Employment Status"
                                :initial="employment_status_id"
                                :guide="getGuide(COLUMN_EMPLOYMENT_STATUS_ID)"
                                :disabled="!isPermissionEditComplianceDocs"
                            />

                            <kyc-tab-item-input-select
                                label="Industry"
                                :initial="industry_id"
                                :guide="getGuide(AS_COLUMN_INDUSTRY_ID)"
                                :disabled="!isPermissionEditComplianceDocs"
                            />

                            <kyc-tab-item-input-select
                                label="Primary Source of Funds"
                                :initial="fund_source_id"
                                :guide="getGuide(AS_COLUMN_FUND_SOURCE_ID)"
                                :disabled="!isPermissionEditComplianceDocs"
                            />

                            <template v-if="isLoadfinancialQuestions">
                                <kyc-tab-item-question
                                    v-for="(financialQuestion, index) in financialQuestions"
                                    :key="index"
                                    :disabled="isDisabled(financialQuestion)"
                                    :initial="financialQuestion"
                                />
                            </template>

                            <kyc-tab-item-read-only
                                label="Funding methods"
                                :initial="clientFundingMethods"
                                :disabled="!isPermissionEditComplianceDocs"
                            />

                            <kyc-tab-item-input-select
                                v-if="!!fund_type_is_bank_transfer"
                                label="Name of Bank"
                                :initial="fund_type_bank_name"
                                :guide="getGuide(COLUMN_FUND_TYPE_BANK_NAME)"
                                :disabled="!isPermissionEditComplianceDocs"
                            />

                            <kyc-tab-item-input-select
                                v-if="!!fund_type_is_bank_transfer"
                                label="Bank Location"
                                :initial="fund_type_bank_location"
                                :guide="getGuide(COLUMN_FUND_TYPE_BANK_LOCATION)"
                                :disabled="!isPermissionEditComplianceDocs"
                            />

                            <kyc-tab-item-target-market
                                v-if="target_market_classification_id"
                                :initial="target_market_classification_id"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import clientKycMixin from '~/components/mixins/client-kyc-mixin/client-kyc-mixin';
import KycTabSubtitle from './KycTabSubtitle';
import KycTabItemQuestionAnswer from './KycTabItem/KycTabItemQuestionAnswer';
import KycTabItemQuestion from './KycTabItem/kyc-tab-item-question/kyc-tab-item-question.vue';
import KycTabItemInputSelect from './KycTabItem/KycTabItemInputSelect';
import KycTabItemReadOnly from '~/components/KYC/KycTabItem/KycTabItemReadOnly';
import kycTabItemTargetMarket from '~/components/KYC/KycTabItem/kyc-tab-item-target-market/kyc-tab-item-target-market.vue';
import { isPermissionEditComplianceDocs } from '~/helpers/user-permission';

export default {
    components: {
        KycTabItemInputSelect,
        KycTabItemQuestionAnswer,
        KycTabSubtitle,
        KycTabItemReadOnly,
        KycTabItemQuestion,
        kycTabItemTargetMarket,
    },
    mixins : [
        clientKycMixin,
    ],
    computed: {
        isPermissionEditComplianceDocs,
    },
    methods: {
        isDisabled (question) {
            return this.isEditable(question) && !this.isPermissionEditComplianceDocs;
        },
        isEditable (question) {
            return question?.field?.is_editable;
        },
    },
};
</script>
