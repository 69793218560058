import { ORDERING_ASC, ORDERING_ALPHABETICAL } from '~/settings/constants';
import moment, { Moment } from 'moment';

export const getTimeStampFromDate = (date: string, time = '00:00:00'): number => {
  
    const dateString = date + ' ' + time;

    return moment(dateString, 'DD/MM/YYYY hh:mm:ss').unix();
};

export const arrayToMap = (arr = [], idKey = 'id'): { [key: string]: any } => {
    const res = arr.reduce((acc, item) => {
        const key = item[idKey];
        acc[key] = item;
        return acc;
    }, {});
    return res;
};

/*
* https://stackoverflow.com/a/30810322
* */
export const fallbackCopyTextToClipboard = (text: string): Promise<void> | Promise<never> | void => {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    textArea.style.position = 'fixed'; // to prevent scrolling down
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        const successful = document.execCommand('copy');
        return successful ? Promise.resolve() : Promise.reject();
    } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
    } finally {
        document.body.removeChild(textArea);
    }
};
export const copyTextToClipboard = (text: string): Promise<void> | Promise<never> | void => {
    if (!navigator.clipboard) return fallbackCopyTextToClipboard(text);

    return navigator.clipboard.writeText(text)
        .then(function () {
            console.log('Async: Copying to clipboard was successful!');
            return Promise.resolve();
        })
        .catch(err => {
            console.log(err);
            return fallbackCopyTextToClipboard(text);
        });
};

export const getSortCB = (key: string, ordering: string = ORDERING_ASC): (a: any, b: any) => number => {
    if (ordering === ORDERING_ALPHABETICAL) {
        return (a, b) => a[key].localeCompare(b[key]);
    }

    return (a, b) => ordering === ORDERING_ASC ? a[key] - b[key] : b[key] - a[key];
};

export const parseDate = (dateStr: string | Date, format: string): Moment => {
    if (!dateStr) return null;

    const parsed = moment(dateStr, format);

    return parsed.isValid() ? parsed : null;
};

export const createDateObj = (date: string, format: string): Moment => {
    console.log(moment(date, format));

    return moment(date, format);
};

export const changeStringFormatDate = (payload: string = ''): string => {
    return payload
        .replace(/(\d+)/g, '0$1')
        .replace(/\d+(\d{2})/g, '$1')
        .replaceAll('-', '.');
};

export const capitalize = (string: string): string => {
    return !string ? '' : string.charAt(0).toUpperCase() + string.slice(1);
};

export const daysToMilliseconds = (days: number): number => {
    return days * 24 * 60 * 60 * 1000;
};
