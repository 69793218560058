import { getterPagination } from '~/store/modules/leads';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { ReportsModule } from '~/store/modules/reports/reports';
import { ETableType } from '~/components/tables/enums/table-type.enum';
import { UsersModule } from '~/store/modules/users/users';
import { KycTradingModule } from '~/store/modules/kyc-trading/kyc-trading';
import { ClientKYCFinancesModule } from '~/store/modules/client-kyc-finances/client-kyc-finances';
import { withdrawalsModule } from '~/store/modules/withdrawals/withdrawals';
import { AffiliatesPageModule } from '~/store/modules/affiliates/affiliates-page';
import { affiliatesCampaignsModule } from '~/store/modules/affiliates/affiliates-campaigns';
import { AffiliatesReportsModule } from '~/store/modules/affiliates/affiliates-reports';
import { LeadsActivityLogsModule } from '~/store/modules/leads-activity-log/leads-activity-log';
import { NotificationsMessagesModule } from '~/store/modules/notifications/notifications';
import { NotificationsAffiliatesModule } from '~/store/modules/notifications/notifications-affiliates';
import { NotificationsActivityLogModule } from '~/store/modules/notifications/notifications-activity-log';
import { tablePerPageOptions, tablePerPageOptionsName } from '~/settings/table-per-page-options';
import { ITablePerPageOptions } from '~/settings/interfaces/table-per-page-options.iterfaces';
import { ETableFilterKeyPagination } from '~/components/tables/enums/table-filter-key.enum';
import { StorageService } from '~/helpers/storage-service';
import { handlerRoutesQuery } from '~/helpers/handler-routes-query';
import { handlerLoadTable } from '~/components/tables/helpers/handler-load-table';
import { SymbolsModule } from '~/store/modules/symbols/symbols';

export default Vue.extend({
    props: {
        tableType: String,
    },
    data (): Object {
        return {
            eTableType: ETableType,
        };
    },
    computed: {
        ...mapGetters({
            isPaginationLeads: getterPagination,
        }),

        perPage: {
            get (): ITablePerPageOptions {
                const perPage = this.$route.query.per_page ||
                    StorageService.getItem(this.localStorageItemName) ||
                    this.isPagination.per_page ||
                    this.getPerPagesOptions[1].value;

                return {
                    name: tablePerPageOptionsName(perPage),
                    value: perPage
                };
            },
            set (perPageValue: ITablePerPageOptions): void {
                StorageService.setItem(this.localStorageItemName, perPageValue.value);

                handlerRoutesQuery(
                    ETableFilterKeyPagination.perPage,
                    String(perPageValue.value)
                );

                handlerRoutesQuery(ETableFilterKeyPagination.page, null);
                this.loadTable(this.tableType);
            }
        },
        isPagination () {
            switch (this.tableType) {
            case ETableType.leadsTable:
                return this.isPaginationLeads;

            case ETableType.reports:
                return ReportsModule.getPagination;

            case ETableType.usersTable:
                return UsersModule.getUsersPagination;

            case ETableType.OpenOrders:
            case ETableType.PendingOrders:
            case ETableType.OrderHistory:
                return KycTradingModule.getTradingPagination;

            case ETableType.paymentHistory:
                return ClientKYCFinancesModule.getHistoryPagination;

            case ETableType.withdrawalsHistory:
            case ETableType.withdrawalsPending:
                return withdrawalsModule.getWithdrawalsPagination;

            case ETableType.affiliates:
                return AffiliatesPageModule.getAffiliatesPagination;

            case ETableType.campaigns:
                return affiliatesCampaignsModule.getAffiliatesCampaignsPagination;

            case ETableType.affiliatesReports:
                return AffiliatesReportsModule.getAffiliatesReportsPagination;
            case ETableType.affiliatesReportsCampaign:
                return AffiliatesReportsModule.getCampaignsReportsPagination;

            case ETableType.leadActivityLog:
                return LeadsActivityLogsModule.getActivityLogPagination;

            case ETableType.notificationsClients:
                return NotificationsMessagesModule.getNotificationsPagination;

            case ETableType.notificationsActivity:
                return NotificationsActivityLogModule.getNotificationsPagination;

            case ETableType.notificationsAffiliates:
                return NotificationsAffiliatesModule.getNotificationsPagination;

            case ETableType.symbols:
                return SymbolsModule.getPagination;
            default:
                return console.log('');
            }
        },
        getPerPagesOptions (): ITablePerPageOptions[] {
            return tablePerPageOptions;
        },
        localStorageItemName (): string {
            return `${this.tableType}_${ETableFilterKeyPagination.perPage}`;
        },
        isHasRecords () {
            return this.isPagination?.total;
        },
        amountRecords (): string {
            const total = this.isPagination.total;
            const per_page = Number(this.isPagination.per_page);
            const current_page = this.isPagination.current_page;
            const x = per_page * (current_page - 1) + 1;
            const y = per_page * current_page;

            return `(${x} - ${y > total ? total : y} of ${total} records)`;
        },
    },
    methods: {
        async loadTable (table: string = ''): Promise<void> {
            await handlerLoadTable(table);
        },
        async paginationSetPage (page: string, table: string): Promise<void> {
            handlerRoutesQuery(ETableFilterKeyPagination.page, page);
            await handlerLoadTable(table);
        },
    }
});
