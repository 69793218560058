export enum ECalendarOptions {
    today = 0,
    week = 1,
    month = 2,
    lastMonth_3 = 3,
    lastMonth_6 = 4,
    ytd = 5,
    custom = 6,
    select = 10,
}

export enum ECalendarOptionsDays {
    today = ECalendarOptions.today,
    lastDays_7 = 11,
    lastDays_30 = 12,
    lastDays_90 = 13,
    lastDays_120 = 14,
    ytd = ECalendarOptions.ytd,
    custom = ECalendarOptions.custom,
}

export enum ECalendarOptionsAmountDays {
    lastDays_7 = 7,
    lastDays_30 = 30,
    lastDays_90 = 90,
    lastDays_120 = 120,
}
