import Vue from 'vue';
import rowItemPropsMixin from '@/js/components/tables/mixins/table-cells-mixins/row-item-props-mixin';

export default Vue.extend({
    mixins: [
        rowItemPropsMixin,
    ],
    data () {
        return {
            activeImageSrc: '/img/clients/online.svg',
            inactiveImageSrc: '/img/clients/offline.svg'
        };
    },
    computed: {
        activityImageSource (): String {
            return this.activity.name == 'Active' ? this.activeImageSrc : this.inactiveImageSrc;
        }
    },
});
