<template>
    <svg
        data-name="Layer 28"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32.67 32.67"
    >
        <path
            d="M16.23 0a16.34 16.34 0 0 0 0 32.68 16.8 16.8 0 0 0 10.07-3.35 1.473 1.473 0 1 0-1.79-2.34 13.86 13.86 0 0 1-8.28 2.75 13.4 13.4 0 1 1 13.48-13.5v1.48a2.95 2.95 0 1 1-5.9 0v-7.38a1.48 1.48 0 1 0-2.95 0v.09a7.59 7.59 0 0 0-4.63-1.57 7.38 7.38 0 0 0 0 14.76 7.64 7.64 0 0 0 5.68-2.54 5.91 5.91 0 0 0 10.76-3.36v-1.48A16.4 16.4 0 0 0 16.23 0zm0 20.69a4.45 4.45 0 0 1 0-8.9 4.61 4.61 0 0 1 4.63 4.45 4.61 4.61 0 0 1-4.63 4.43z"
            fill="#2362b7"
        />
    </svg>
</template>

<script>
export default {

};
</script>
