import Vue from 'vue';
import Attachments from '~/components/attachments/attachments.vue';
import sendMailEditor from '~/components/parts/sendMailEditor.vue';
// @ts-ignore
import modalLayout from '~/components/modals/root-modal-layout/root-modal-layout';

import modalsMixin from '~/components/mixins/modals';
export default Vue.extend({
    components: {
        modalLayout,
        sendMailEditor,
        Attachments
    },
    mixins: [modalsMixin],
});
