import {
    getStdActions,
    getStdDefaultState,
    getStdGetters,
    getStdMutations,
    getStdNames
} from '~/store/modules/_stdModuleUtils';
import Rest from '~/helpers/Rest';
import { urlClient, urlClientKyc } from '~/settings/ajax-urls';
import {
    AS_COLUMN_COLUMN_MAP,
    AS_COLUMN_PROFESSIONAL_LEVEL,
    AS_COLUMN_RISK,
    AS_COLUMN_LEGAL_SUBJECT_ID,
    asColumnToColumn,
    BOOLEAN_COLUMNS,
    booleanColumnToOption,
    COLUMN_CITIZENSHIP_IDS,
    COLUMN_FUND_TYPE_IS_CARD,
    COLUMN_FUND_TYPE_IS_E_WALLETS,
    COLUMN_IS_FATCA,
    COLUMN_PROFESSIONAL_LEVEL_ID,
    COLUMN_RISK_ID,
    COLUMN_TINS,
    DATE_COLUMNS,
    FUNDING_BANK,
    FUNDING_CARD,
    FUNDING_E_WALLETS,
    getBooleanFromOption,
    COLUMN_IP_ADDRESS,
    COLUMN_VERIFY_STATUS_CRYPTO_ID,
    COLUMN_IS_TIN_AVAILABLE,
    COLUMN_IS_TIN_AVAILABLE_STRING,
    COLUMN_ASSESSMENT_QUESTIONS,
    COLUMN_INVESTMENT_QUESTIONS,
    COLUMN_FINANCIAL_QUESTIONS,
    COLUMN_COMPLETE_QUESTIONNAIRE_AT,
    AS_COLUMN_APPROVED_DEPOSIT_AMOUNT,
    COLUMN_CAMPAIGN_NAME,
} from '~/store/modules/_columnNames';
import moment from 'moment';
import { NotificationPrefabricated } from '~/helpers/modal-notification-services/modal-notification-services';
import { handlerSuccessNotifications } from '~/store/modules/kyc-client/helpers/handler-success-notifications';
import { EConfigsMethods } from '~/store/modules/configs/enums/configs-methods.enum';
import { handlerFindInConfig } from '~/store/modules/configs/helper/handler-find-in-config';
import { modifyKycResponse } from '~/store/modules/client-kyc/helpers/modify-kyc-response';
import { EKycFieldsValuesId } from '~/settings/enums/kyc-fields_values-id.enums';
import { ActionContext } from 'vuex';
import { IActionHandleUpdatePayload, TValueOfActionHandleUpdatePayload } from '~/store/modules/client-kyc/interfaces/action-handle-update-payload.interface';
import { EConfigType } from '~/store/modules/configs/enums/config-type.enum';
import { ITinValue } from '@/js/components/KYC/KycTabItem/kyc-tab-item-list/kyc-tab-item-list.interfaces';

export const moduleName = 'clientKyc';

export const names = getStdNames(moduleName);
export const getterEntity = `${moduleName}/entity`;
export const getterFundingMethods = `${moduleName}/fundingMethods`;
export const getterRelatedFields = `${moduleName}/relatedFields`;
export const getterIsBusiness = `${moduleName}/isBusiness`;
export const getterAwarenessMessages = `${moduleName}/awarenessMessages`;
export const actionHandleUpdate = `${moduleName}/handleUpdate`;
export const actionLoadKyc = `${moduleName}/loadKyc`;
export const actionInitialKyc = `${moduleName}/initialKyc`;
const getIsEmailAble = 'ComplianceUploadedDocuments/getIsEmailAble';

interface IStdDefaultState {
  isLoading: boolean,
  isLoaded: boolean,
  errorLoad: string,
  entities : any,
  entity : any,
}

export default {
    state: {
        ...getStdDefaultState(),
    },
    getters: {
        ...getStdGetters({ names }),
        [names.getters.entity]: (state: IStdDefaultState): any => {
            const {
                [EKycFieldsValuesId.birth_date]: birth_date,
                [EKycFieldsValuesId.residence_country_id]: residence_country_id,
                [EKycFieldsValuesId.citizenship_country_ids]: citizenship_country_ids,
                // [EKycFieldsValuesId.political_exposed]: political_exposed,
                [COLUMN_IS_TIN_AVAILABLE] : is_tin_available,
                [COLUMN_IS_FATCA] : is_fatca,
                [EKycFieldsValuesId.question1]: question1,
                [EKycFieldsValuesId.question2]: question2,
                [EKycFieldsValuesId.question3]: question3,
                [EKycFieldsValuesId.question4]: question4,
                [EKycFieldsValuesId.question5]: question5,
                [EKycFieldsValuesId.question6]: question6,
                [EKycFieldsValuesId.question7]: question7,
                [EKycFieldsValuesId.question8]: question8,
                [EKycFieldsValuesId.question9]: question9,
                [EKycFieldsValuesId.question10]: question10,
                [EKycFieldsValuesId.question11]: question11,
                [EKycFieldsValuesId.question12]: question12,
                [EKycFieldsValuesId.question13]: question13,
                [EKycFieldsValuesId.question14]: question14,
                [EKycFieldsValuesId.question15]: question15,
                [EKycFieldsValuesId.question19]: question19,
                [EKycFieldsValuesId.question20]: question20,
                [EKycFieldsValuesId.question21]: question21,
                [EKycFieldsValuesId.question22]: question22,
                [EKycFieldsValuesId.question23]: question23,
                [EKycFieldsValuesId.fund_type_is_bank_transfer]: fund_type_is_bank_transfer,
                [COLUMN_FUND_TYPE_IS_CARD]: fund_type_is_card,
                [COLUMN_FUND_TYPE_IS_E_WALLETS]: fund_type_is_e_wallets,
                [COLUMN_COMPLETE_QUESTIONNAIRE_AT]: complete_questionnaire_at,
                [COLUMN_IP_ADDRESS]: ip_address,
                [COLUMN_PROFESSIONAL_LEVEL_ID] : professional_level_id,
                [COLUMN_RISK_ID]: risk_id,
                [EKycFieldsValuesId.aqm_on_behalf_of] : aqm_on_behalf_of,
                [AS_COLUMN_APPROVED_DEPOSIT_AMOUNT]: approved_deposit_amount,
                [COLUMN_CAMPAIGN_NAME]: campaign_name,
            } = state.entity;
            return {
                ...state.entity,

                birth_date_formatted: moment.isMoment(birth_date) ? birth_date.format('YYYY-MM-DD') : null,
                citizenship_equal_residence: booleanColumnToOption(
                    residence_country_id?.data &&
                  citizenship_country_ids?.data &&
                  citizenship_country_ids?.data
                      .find(country => country.value === residence_country_id.data[0].value)
                ),
                [EKycFieldsValuesId.citizenship_country_ids]: citizenship_country_ids || {},
                [COLUMN_IS_TIN_AVAILABLE_STRING] : booleanColumnToOption(is_tin_available),
                [COLUMN_IS_FATCA] : booleanColumnToOption(is_fatca),
                questions: [
                    question1,
                    question2,
                    question3,
                    question4,
                    question5,
                    question6,
                    question7,
                    question8,
                    question9,
                    question10,
                    question11,
                    question12,
                    question13,
                    question14,
                    question15,
                    question19,
                    question20,
                    question21,
                    question22,
                    question23,
                ],
                [COLUMN_ASSESSMENT_QUESTIONS]: [
                    question1,
                    question2,
                    question3,
                    question4,
                    question5,
                ],
                [COLUMN_INVESTMENT_QUESTIONS]: [
                    question6,
                    question7,
                    question8,
                    question9,
                ],
                [COLUMN_FINANCIAL_QUESTIONS]: [
                    question10,
                    question11,
                    question12,
                    question23,
                    question13,
                    question14,
                    question15,
                ],
                [EKycFieldsValuesId.fund_type_is_bank_transfer]: fund_type_is_bank_transfer == null ?
                    null :
                    booleanColumnToOption(fund_type_is_bank_transfer),
                [COLUMN_FUND_TYPE_IS_CARD] : fund_type_is_card == null ? null : booleanColumnToOption(fund_type_is_card),
                [COLUMN_FUND_TYPE_IS_E_WALLETS] : fund_type_is_e_wallets == null ? null : booleanColumnToOption(fund_type_is_e_wallets),
                [COLUMN_COMPLETE_QUESTIONNAIRE_AT]: complete_questionnaire_at || null,
                [COLUMN_IP_ADDRESS]: ip_address || null,
                [AS_COLUMN_PROFESSIONAL_LEVEL] : handlerFindInConfig(EConfigsMethods.getProfessionalLevels, professional_level_id),
                [AS_COLUMN_RISK] : handlerFindInConfig(EConfigsMethods.getRisks, risk_id),
                [EKycFieldsValuesId.aqm_on_behalf_of]: aqm_on_behalf_of == null ?
                    null :
                    booleanColumnToOption(aqm_on_behalf_of),
                [AS_COLUMN_APPROVED_DEPOSIT_AMOUNT]: approved_deposit_amount || 0,
                [COLUMN_CAMPAIGN_NAME]: campaign_name || '',
            };
        },
        [getterFundingMethods]: (state: IStdDefaultState): string[] => {
            const  {
                [EKycFieldsValuesId.fund_type_is_bank_transfer]: bankTransfer,
                [EKycFieldsValuesId.fund_type_is_card]: cardTransfer,
                [EKycFieldsValuesId.fund_type_is_e_wallets]: walletTransfer
            } = state.entity;

            const objectOfFunds = {
                [FUNDING_E_WALLETS]: walletTransfer,
                [FUNDING_BANK]: bankTransfer,
                [FUNDING_CARD]: cardTransfer
            };
            return Object.keys(objectOfFunds)
                .filter(fundType => objectOfFunds[fundType]);
        },
    },
    mutations: {
        ...getStdMutations({ names }),
    },
    actions: {
        ...getStdActions({ names, isSingleEntity: true }),

        [actionHandleUpdate] (
            { commit, rootGetters }: ActionContext<any, any>,
            payload: IActionHandleUpdatePayload | IActionHandleUpdatePayload[]
        ): Promise<void> {
            const { id } = rootGetters[names.getters.entity];
            if (!Array.isArray(payload)) {
                const { column, value } = payload;
                payload = [{ column, value }];
            }

            let body = {};

            payload.forEach(({ column, value }) => {
                let newValue = null;

                if (AS_COLUMN_COLUMN_MAP[column]) {
                    column = asColumnToColumn(column);
                    newValue = idFromValue(value);
                }

                if (DATE_COLUMNS.includes(column)) {
                    newValue = value instanceof Date ? moment(value).format('YYYY-MM-DD') : null;
                }

                if (BOOLEAN_COLUMNS.includes(column) && typeof value === EConfigType.object) {
                    newValue = getBooleanFromOption(value);
                }

                if (Array.isArray(value)) {
                    if (column === COLUMN_TINS) {
                        newValue = (value as ITinValue[]).map(({ tin, tin_country }) => ({
                            tin,
                            country_id : idFromValue(tin_country),
                        }));
                    }
  
                    if (column === COLUMN_CITIZENSHIP_IDS) {
                        newValue = value.map(item => item);
                    }
                }

                if (column === AS_COLUMN_LEGAL_SUBJECT_ID || column === COLUMN_VERIFY_STATUS_CRYPTO_ID) {
                    newValue = (value as { id: number }).id;
                }

                // TODO
                const is_emailable = rootGetters[getIsEmailAble];

                body = {
                    ...body,
                    [column] : newValue || value,
                    is_emailable,
                };
            });

            return Rest.PUT(urlClient(id), body)
                .then(Rest.middleThen)
                .then(response => {
                    let newData = response.data || {};
                    newData = modifyKycResponse(newData, rootGetters[EConfigsMethods.getFields]);
                    commit(names.mutations.setEntity, newData);

                    handlerSuccessNotifications(payload[0].column);
                })
                .catch(error => {
                    NotificationPrefabricated.Error(error);
                });
        },
        [actionLoadKyc] ({ commit, dispatch, rootGetters }: ActionContext<any, any>, clientId: string):  Promise<void> {
            return Rest.GET(urlClientKyc(clientId))
                .then(Rest.middleThen)
                .then(response => {
                    let newData = response.data || {}; // IKycResponce
                    newData = modifyKycResponse(newData, rootGetters[EConfigsMethods.getFields]);
                    commit(names.mutations.setEntity, newData);
                })
                .catch(error => {
                    dispatch(names.actions.loadingFail, error);
                    Rest.simpleCatch(error);
                });
        },
        [actionInitialKyc] ({ dispatch }: ActionContext<any, any>, clientId: string): void {
            dispatch(names.actions.loadingStart);
            dispatch(actionLoadKyc, clientId)
                .then(() => dispatch(names.actions.loadingFinish));
        }
    },
};

export const idFromValue = (value: TValueOfActionHandleUpdatePayload | number): TValueOfActionHandleUpdatePayload | number => {
    return typeof value === EConfigType.object &&
      value !== null &&
      Object.prototype.hasOwnProperty.call(value, 'id') ?
        (value as { id: number }).id :
        value;
};
