import Vue from 'vue';
import { NotificationsMessagesModule } from '~/store/modules/notifications/notifications';
import { NotificationsAffiliatesModule } from '~/store/modules/notifications/notifications-affiliates';
import { NotificationsDataInterfaces, INotificationAffiliate } from '~/store/modules/notifications/notifications-interfaces';
import {
    NotificationsEnum,
    ENotificationsTableHeaderType,
    NumbersNotificationEnum,
} from '~/components/notifications/enums/notifications.enum';
import defaultTableHeader from './_header/default/default.vue';
import affiliatesTableHeader from './_header/affiliates/affiliates.vue';
import defaultTableItem from './_item/default/default.vue';
import affiliatesTableItem from '~/components/notifications/notifications-table/_item/affiliates/affiliates.vue';

export default Vue.extend({
    components: {
        defaultTableItem,
        defaultTableHeader,
        affiliatesTableHeader,
        affiliatesTableItem,
    },
    computed: {
        isAffiliates (): boolean {
            return +this.$route.query.type_id === ENotificationsTableHeaderType.affiliates;
        },
        read (): Array<NotificationsDataInterfaces> | INotificationAffiliate[] {
            return this.isAffiliates ?
                NotificationsAffiliatesModule.readNotifications :
                NotificationsMessagesModule.getterNotificationsReadList;
        },
        unread (): Array<NotificationsDataInterfaces> | INotificationAffiliate[] {
            return this.isAffiliates ?
                NotificationsAffiliatesModule.unReadNotifications :
                NotificationsMessagesModule.getterNotificationsUnReadList;
        },
        isTableType (): string {
            const queryTypeId = this.$route.query.type_id;

            switch (queryTypeId) {
            case NotificationsEnum.TYPE_AFFILIATES:
                return ENotificationsTableHeaderType[+queryTypeId];
            default:
                return ENotificationsTableHeaderType[NumbersNotificationEnum.Zero];
            }
        },
    },
});
