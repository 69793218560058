import Vue from 'vue';
import ItemNavbar from '@/js/components/leadsItem/ItemNavbar.vue';
import BreadcrumbsStepsThree from './breadcrumbs-steps-three/breadcrumbs-steps-three.vue';
import BreadcrumbsStepsFour from './breadcrumbs-steps-four/breadcrumbs-steps-four.vue';
import BreadcrumbsStepsFive from './breadcrumbs-steps-five/breadcrumbs-steps-five.vue';
import UserProfilePreview from '~/components/user-profile-preview/user-profile-preview.vue';
import { mapGetters } from 'vuex';
import { names } from '~/store/modules/leadDetails';

export default Vue.extend({
    components: {
        ItemNavbar,
        BreadcrumbsStepsThree,
        BreadcrumbsStepsFour,
        BreadcrumbsStepsFive,
        UserProfilePreview
    },
    data () {
        return {

        };
    },
    computed: {
        ...mapGetters({
            leadDetails : names.getters.entity,
        }),
        infoRegistrationLead (): object {
            const { 
                referrer_url,
                browser,
                browser_version,
                os_name,
                os_version,
            } = this.leadDetails;

            return [
                referrer_url,
                browser,
                browser_version,
                os_name,
                os_version,
            ];
        }
    },
});
