export enum EDashboardSummaryStatisticItemName {
    deposit_amount = 'Total Deposits',
    depositors_count = 'Depositors',
    ftd_amount = 'FTDs Amount',
    ftd_count = 'FTDs Count',
    net_deposit_amount = 'Net Deposits',
    withdraw_amount = 'Total Withdrawals',
    withdraw_pending_count = 'Withdrawals Pending',
    withdrawers_count = 'Withdrawers',
    client_pending_count = 'Pending',
    client_registered_count = 'Registrations',
    client_verified_count = 'Verified Accounts',
    documents_pending_count = 'Pending Documents',
    client_inactive_count = 'Inactive Clients',
}

export enum EDashboardPaymentsItemsKey {
    deposit_amount = 'deposit_amount',
    depositors_count = 'depositors_count',
    ftd_amount = 'ftd_amount',
    ftd_count = 'ftd_count',
    net_deposit_amount = 'net_deposit_amount',
    withdraw_amount = 'withdraw_amount',
    withdraw_pending_count = 'withdraw_pending_count',
    withdrawers_count = 'withdrawers_count',
}

export enum EDashboardPaymentsItemsKeyNoDecimal {
    depositors_count = 'depositors_count',
    ftd_count = 'ftd_count',
    withdrawers_count = 'withdrawers_count',
}

export enum EDashboardClientsItemsKey {
    client_count_by_campaign = 'client_count_by_campaign',
    client_count_by_country = 'client_count_by_country',
    client_count_by_lead_status = 'client_count_by_lead_status',
    client_count_by_social_media = 'client_count_by_social_media',
    client_pending_count = 'client_pending_count',
    client_registered_count = 'client_registered_count',
    client_verified_count = 'client_verified_count',
    documents_pending_count = 'documents_pending_count',
    client_count_per_day = 'client_count_per_day',
}

export enum EDashboardPlatformItemsKey {
    client_inactive_count = 'client_inactive_count',
}


export enum EDashboardVolumeItemsKey {
    trading_volume = 'trading_volume',
}

export enum EDashboardSummaryStatisticItemRedirect {
    withdraw_pending_count = '/notifications?type_id=3',
    documents_pending_count = '/notifications?type_id=2',
    client_registered_count = '/notifications?type_id=1',
}
