












import loaderBig from './components/loaderBig.vue';
import rootModal from '~/components/modals/root-modal/root-modal.vue';
import { AppModule } from '~/store/modules/app/app';
import toastNotifications from './components/notifications.vue';
import notificationMessage from '~/components/parts/notification-message/notification-message.vue';
import { handlerRouters } from '~/helpers/handler-routes';
import { isEnvModeTypeDevelopment } from '~/helpers/is-env-mode-type';
import { NotificationsMessagesModule } from './store/modules/notifications/notifications';
import { handlerPendingNotifications } from './helpers/handler-pending-notification';
import { ProfileModule } from './store/modules/profile/profile';
import {
    isPermissionAdmin,
    isPermissionNotificationsRegister,
    isPermissionNotificationsKyc,
    isPermissionNotificationsFunding,
} from './helpers/user-permission';

export default {
    components: {
        loaderBig,
        rootModal,
        toastNotifications,
        notificationMessage
    },
    computed: {
        isPermissionAdmin,
        isPermissionNotificationsRegister,
        isPermissionNotificationsKyc,
        isPermissionNotificationsFunding,
        isInited (): Boolean {
            return AppModule.getIsInited;
        },
        isLoading (): Boolean {
            return AppModule.getIsLoading;
        },
        isNotifications (): boolean {
            return this.isPermissionNotificationsKyc ||
                this.isPermissionNotificationsRegister ||
                this.isPermissionNotificationsFunding;
        }
    },
    async created () {
        this.isDevelopmentMode();
        if (!this.isInited) {
            await AppModule.handleInit(true);
            this.checkRoute();
        }
        setTimeout(() =>  {
            AppModule.handleSetAfterLoadingContent(true);
        }, 1000); // TODO

        if (
            this.isInited &&
            ProfileModule.getProfile.id &&
            !this.isPermissionAdmin &&
            this.isNotifications
        ) {
            this.addEventForFirstClick();
        }

    },
    methods: {
        async soundPendingNotifications () {
            this.$root.$el.removeEventListener('click', this.soundPendingNotifications);

            const notifications = NotificationsMessagesModule.getAllNotification;
            const data = notifications.length ?
                notifications :
                await NotificationsMessagesModule.setNotificationsTotal();

            handlerPendingNotifications(data);
        },
        addEventForFirstClick () {
            this.$root.$el.addEventListener('click', this.soundPendingNotifications);
        },
        checkRoute () {
            const routeName = this.$route.name;
            handlerRouters(routeName);
        },
        isDevelopmentMode () {
            const isModeTypeDevelopment = isEnvModeTypeDevelopment();

            if (isModeTypeDevelopment) {
                document.title += ' - DEV';
            }
        },
    }
};
