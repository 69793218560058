import { Module, VuexModule, getModule, Action, Mutation } from 'vuex-module-decorators';
import store from '~/store';
import Rest from '~/helpers/Rest';
import {
    urlSymbolsMassiveUpdate,
    urlImportSymbols,
    urlSymbolsUpdate,
    urlSymbols,
} from '~/settings/ajax-urls';
import { NotificationPrefabricated } from '~/helpers/modal-notification-services/modal-notification-services';
import { handlerRestResponse } from '~/helpers/handler-rest-response';
import { IPagination } from '~/settings/interfaces/pagination-interfaces';
import { ITableFilterQuery } from '~/components/tables/interfaces/table-filter-query.interfaces';
import { ISymbol } from '~/store/modules/symbols/symbol-interfaces';
import { handlerSymbols } from '~/helpers/handlers-queries-to-fetch/handler-symbols';

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: 'symbols',
})

class Symbols extends VuexModule {

    symbols: ISymbol[] =[];
    pagination: IPagination = {
        current_page: null,
        from: null,
        last_page: null,
        per_page: null,
        to: null,
        total: null,
    };
    loaded: boolean = false;

    get getIsLoaded (): boolean {
        return this.loaded;
    }

    get getSymbols (): ISymbol[] {
        return this.symbols;
    }

    get getPagination (): IPagination {
        return this.pagination;
    }

    @Mutation
    setPagination (payload: IPagination) {
        this.pagination = payload;
    }

    @Mutation
    setSymbols (payload: ISymbol[]) {
        this.symbols = payload;
    }

    @Mutation
    setLoadedState (payload: boolean) {
        this.loaded = payload;
    }

    @Action
    async create (payload): Promise<ISymbol> {
        return await Rest.MultiPOST(urlSymbols(), payload)
            .then(response => {
                return handlerRestResponse(response, NotificationPrefabricated.createSymbolsSuccess);
            });
    }

    @Action
    async update (payload): Promise<ISymbol> {
        const { id, ...rest } = payload;
        return await Rest.MultiPOST(urlSymbolsUpdate(id), rest)
            .then(response => {
                return handlerRestResponse(response, NotificationPrefabricated.updateSymbolsSuccess);
            });
    }

    @Action
    async massiveUpdate (payload): Promise<ISymbol> {
        return await Rest.PUT(urlSymbolsMassiveUpdate, payload)
            .then(response => {
                return handlerRestResponse(response, NotificationPrefabricated.updateSymbolsSuccess);
            });
    }

    @Action
    async fetch (query: ITableFilterQuery = null): Promise<void> {
        this.setLoadedState(false);
        query = handlerSymbols(query);
        const { data, meta } = await Rest.GET(urlSymbols(query))
            .then(Rest.middleThen)
            .catch(Rest.simpleCatch)
            .finally(() => {
                NotificationPrefabricated.cleanAllNotify();
            });
        this.setSymbols(data);
        this.setPagination(meta);
        this.setLoadedState(true);
    }

    @Action
    async import (fileUploaded: File): Promise<void> {
        await Rest.FILE(urlImportSymbols, fileUploaded)
            .then(response => {
                return handlerRestResponse(response, NotificationPrefabricated.ImportSymbolsSuccess);
            })
            .catch(Rest.simpleCatchNotification)
            .finally(() => {
                NotificationPrefabricated.cleanAllNotify();
            });
    }

    @Action
    reset (): void {
        this.setSymbols([]);
    }

}

export const SymbolsModule = getModule(Symbols);
