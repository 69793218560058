import Vue from 'vue';

export default Vue.extend({
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    computed: {
        dateInitial (): string {
            return `${this.item.created_at.date} | ${this.item.created_at.time}`;
        }
    }
});
