import Vue from 'vue';
import rowItemPropsMixin from '@/js/components/tables/mixins/table-cells-mixins/row-item-props-mixin';


export default Vue.extend({
    mixins: [
        rowItemPropsMixin,
    ],
    computed: {
        getStyleClass () {
            return this.cell.value.type == 'up' ? 'affiliate-profit__success' : 'affiliate-profit__danger';
        }
    }
});
