<template>
    <div>
        {{ placeholder }}
    </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
    props: {
        placeholder: String
    }
});
</script>
