import routes from '~/settings/routes';
import { EAffiliatesCompliance } from '~/settings/enums/affiliates-compliance.enum';

export default {
    methods: {
        goToAffiliateDetails (rowId: number): void {
            const route = this.$router.resolve({
                path: routes.affiliatesItem.subRoutes.compliance.path,
                query: {
                    [EAffiliatesCompliance.affiliate_id]: `${rowId}`,
                }
            });
            window.open(route.href, '_blank');
        },
    }
};
