import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '~/store/index';
import Rest from '~/helpers/Rest';
import { urlEmailsFilterClients } from '~/settings/ajax-urls';
import { IPagination } from '~/settings/interfaces/pagination-interfaces';
import {
    IEmailsSearchResponse,
} from './interfaces';

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: 'EmailFilterClients',
})
class EmailFilterClients extends VuexModule {
    pagination: IPagination = {
        current_page: null,
        from: null,
        last_page: null,
        per_page: null,
        to: null,
        total: null,
    };

    get getPagination (): IPagination {
        return this.pagination;
    }

    @Mutation
    setPagination (payload: IPagination) {
        this.pagination = payload;
    }

    @Action
    async fetchFilteredClients (query: object): Promise<IEmailsSearchResponse> {
        const { data, meta } = await Rest.GET(urlEmailsFilterClients(query))
            .then(Rest.middleThen)
            .catch(Rest.simpleCatch);
        this.setPagination(meta);
        return { data, meta };
    }
}

export const EmailFilterClientsModule = getModule(EmailFilterClients);
