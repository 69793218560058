import { PaymentRequisitesModule } from '~/store/modules/payment-requisites/payment-requisites';
import { ModalsModule } from '~/store/modules/modals';
import rowItemPropsMixin from '@/js/components/tables/mixins/table-cells-mixins/row-item-props-mixin';

export default {
    mixins: [
        rowItemPropsMixin,
    ],
    data (): object {
        return {
            value: this.cell.value,
            isSelected: null,
        };
    },
    methods: {
        edit (event: MouseEvent): void {
            ModalsModule.openModal({
                modalType: 'actionModal',
                props: {
                    body: {
                        text: 'Are you sure you want to ' +
                              `${(event.target as HTMLInputElement).checked ? 'CHECK' : 'UNCHECK'} ` +
                              'the 1k call confirmation?',
                    },
                    isHeader: true,
                    isFooter: false,
                    close: () => {
                        this.handleClose();
                    },
                    confirm: () => {
                        this.setCallConfirmed();
                    }
                },
                modalSettings: {
                    props: {
                        minWidth: ModalsModule.modalWidth.xxs,
                    },
                    positionModal: {
                        clientX: event.clientX,
                        clientY: event.clientY,
                        modalWidth: ModalsModule.modalWidth.xxs,
                    }
                },
            });
        },
        handleClose (): void {
            this.value = !this.value;
        },

        handleInputEvent (value: boolean): void {
            this.value = value;
            this.isSelected = value;
        },

        async setCallConfirmed (): Promise<void> {
            await PaymentRequisitesModule.editPaymentRequisite({
                id: this.cell.ID,
                'call_confirmed': this.isSelected
            });

            this.$emit('callConfirmed');
        },
    },
};
