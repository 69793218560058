import { render, staticRenderFns } from "./e-wallet.html?vue&type=template&id=2bcdf8cc&lang=html&"
import script from "./e-wallet.ts?vue&type=script&lang=ts&"
export * from "./e-wallet.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports