import { Module, VuexModule, MutationAction } from 'vuex-module-decorators';
import store from '~/store/index';
import Rest from '~/helpers/Rest';
import { urlConfigsAll } from '~/settings/ajax-urls';
import {
    IConfigs,
    ILeadsConfigs,
    ICountries,
    ILanguages,
    ILeadsConfigsAnswers,
    IConfigsAttributes,
    ILeadsConfigsQuestion,
    IDefaultConfig,
    IDefaultConfigIsDescribable,
    ICheckboxConsents,
    IPaymentMethods,
    IPaymentProviders,
    IDepartmentConfigs,
    IDocumentation,
    ILeveragesFormatted,
    IPermissionsGrouped,
    ITimezone,
    IFields,
} from './interfaces/configs-interface';
import { handlerPermissionsGrouped } from '~/store/modules/configs/helper/handler-permissions-grouped';
import { handlerConfigDate } from './helper/handler-config-data';
@Module({
    namespaced: true,
    store,
    name: 'Configs',
    stateFactory : true,
})

export default class Configs extends VuexModule {
    public account_currencies: IDefaultConfig[] = [];
    public account_mode_types: IDefaultConfig[] = [];
    public account_profit_method_types: IDefaultConfig[] = [];
    public affiliate_document_rejected_reasons: IDefaultConfig[] = [];
    public affiliate_document_statuses: IDefaultConfig[] = [];
    public affiliate_document_types: IDefaultConfig[] = [];
    public affiliate_types: IDefaultConfig[] = [];
    public affiliate_statuses: IDefaultConfig[] = [];
    public answers: ILeadsConfigsAnswers[] = [];
    public attributes: IConfigsAttributes[] = [];
    public bonus_types: IDefaultConfig[] = [];
    public checkbox_consents: ICheckboxConsents[] = [];
    public client_document_rejected_reasons: IDefaultConfig[] = [];
    public client_document_statuses: IDefaultConfig[] = [];
    public client_document_types: IDefaultConfig[] = [];
    public complicatedness: IDefaultConfig[] = [];
    public countries: ICountries[] = [];
    public currencies: IDefaultConfig[] = [];
    public departments: IDepartmentConfigs[] = [];
    public documentation: IDocumentation[] = [];
    public employment_statuses: IDefaultConfigIsDescribable[] = [];
    public fund_sources: IDefaultConfigIsDescribable[] = [];
    public heard_from: IDefaultConfig[] = [];
    public industries: IDefaultConfigIsDescribable[] = [];
    public languages: ILanguages[] = [];
    public lead_statuses: IDefaultConfig[] = [];
    public leads_filters: IDefaultConfig[] = [];
    public leads_filters_config: ILeadsConfigs = {
        deposited: [],
        target_market: [],
        affiliate: [],
        activity: [],
        client_profitability: [],
        peps: [],
        annual_investment: [],
        annual_income: [],
        net_wealth: [],
        savings_investments: [],
    };
    public legal_documents_statuses: IDefaultConfig[] = [];
    public legal_documents_types: IDefaultConfig[] = [];
    public legal_subjects: IDefaultConfig[] = [];
    public leverages: ILeveragesFormatted[] = [];
    public market_classifications: IDefaultConfig[] = [];
    public mt_subject_types: IDefaultConfig[] = [];
    public payment_methods: IPaymentMethods[] = [];
    public payment_providers: IPaymentProviders[] = [];
    public payment_requisite_statuses: IDefaultConfigIsDescribable[] = [];
    public payment_statuses: IDefaultConfig[] = [];
    public payment_types: IDefaultConfig[] = [];
    public permissions: IDefaultConfig[] = [];
    public field_value_statuses: IDefaultConfig[] = [];
    public professional_level_request_types: IDefaultConfig[] = [];
    public professional_levels: IDefaultConfig[] = [];
    public questions: ILeadsConfigsQuestion[] = [];
    public report_columns: IDefaultConfig[] = [];
    public risks: IDefaultConfig[] = [];
    public timezones: ITimezone[] = [];
    public tin_absence_reasons: IDefaultConfigIsDescribable[] = [];
    public titles: IDefaultConfig[] = [];
    public verify_statuses: IDefaultConfig[] = [];
    public fields: IFields[] = [];

    get getAccountCurrencies (): IDefaultConfig[] {
        return this.account_currencies;
    }

    get getAccountModeTypes (): IDefaultConfig[] {
        return this.account_mode_types;
    }

    get getAccountProfitMethodTypes (): IDefaultConfig[] {
        return this.account_profit_method_types;
    }

    get getAffiliateDocumentRejectedReasons (): IDefaultConfig[] {
        return this.affiliate_document_rejected_reasons;
    }

    get getAffiliateDocumentStatuses (): IDefaultConfig[] {
        return this.affiliate_document_statuses;
    }

    get getAffiliateDocumentTypes (): IDefaultConfig[] {
        return this.affiliate_document_types;
    }

    get getAffiliateTypes (): IDefaultConfig[] {
        return this.affiliate_types
            .sort((a, b) => a.id - b.id);
    }

    get getAffiliateStatuses (): IDefaultConfig[] {
        return this.affiliate_statuses;
    }

    get getAnswers (): ILeadsConfigsAnswers[] {
        return this.answers;
    }

    get getAttributes (): IConfigsAttributes[] {
        return this.attributes;
    }

    get getBonusTypes (): IDefaultConfig[] {
        return this.bonus_types;
    }

    get getCheckboxConsents (): ICheckboxConsents[] {
        return this.checkbox_consents;
    }

    get getClientDocumentRejectedReasons (): IDefaultConfig[] {
        return this.client_document_rejected_reasons;
    }

    get getClientDocumentStatuses (): IDefaultConfig[] {
        return this.client_document_statuses;
    }

    get getClientDocumentTypes (): IDefaultConfig[] {
        return this.client_document_types;
    }

    get getComplicatedness (): IDefaultConfig[] {
        return this.complicatedness;
    }

    get getCountries (): ICountries[] {
        return this.countries;
    }

    get getCurrencies (): IDefaultConfig[] {
        return this.currencies;
    }

    get getDepartments (): IDepartmentConfigs[] {
        return this.departments;
    }

    get getDocumentation (): IDocumentation[] {
        return this.documentation;
    }

    get getEmploymentStatuses (): IDefaultConfigIsDescribable[] {
        return this.employment_statuses;
    }

    get getFundSources (): IDefaultConfig[] {
        return this.fund_sources;
    }

    get getHeardFrom (): IDefaultConfig[] {
        return this.heard_from;
    }

    get getIndustries (): IDefaultConfigIsDescribable[] {
        return this.industries;
    }

    get getLanguages (): ILanguages[] {
        return this.languages;
    }

    get getLeadStatuses (): IDefaultConfig[] {
        return this.lead_statuses;
    }

    get getLeadsFiltersConfig (): ILeadsConfigs {
        return this.leads_filters_config;
    }

    get getLegalDocumentsStatuses (): IDefaultConfig[] {
        return this.legal_documents_statuses;
    }

    get getLegalSubjects (): IDefaultConfig[] {
        return this.legal_subjects;
    }

    get getLeveragesFormatted (): ILeveragesFormatted[] {
        return this.leverages;
    }

    get getMTSubjectTypes (): IDefaultConfig[] {
        return this.mt_subject_types;
    }

    get getPaymentMethods (): IPaymentMethods[] {
        return this.payment_methods;
    }

    get getPaymentProviders (): IPaymentProviders[] {
        return this.payment_providers;
    }

    get getPaymentStatuses (): IDefaultConfig[] {
        return this.payment_statuses;
    }

    get getPaymentTypes (): IDefaultConfig[] {
        return this.payment_types;
    }
    get getPermissions (): IDefaultConfig[] {
        return this.permissions;
    }
    get getPermissionsGrouped (): IPermissionsGrouped {
        return handlerPermissionsGrouped(this.permissions);
    }
    get getFieldValueStatuses (): IDefaultConfig[] {
        return this.field_value_statuses;
    }
    get getProfessionalLevelRequestTypes (): IDefaultConfig[] {
        return this.professional_level_request_types;
    }
    get getProfessionalLevels (): IDefaultConfig[] {
        return this.professional_levels;
    }
    get getQuestions (): ILeadsConfigsQuestion[] {
        return this.questions;
    }
    get getReportColumns (): IDefaultConfig[] {
        return this.report_columns;
    }
    get getRisks (): IDefaultConfig[] {
        return this.risks;
    }

    get getTimezones (): ITimezone[] {
        return this.timezones;
    }

    get getTinAbsenceReasons (): IDefaultConfigIsDescribable[] {
        return this.tin_absence_reasons;
    }
    get getTitles (): IDefaultConfig[] {
        return this.titles;
    }
    get getVerifyStatuses (): IDefaultConfig[] {
        return this.verify_statuses;
    }
    get getFields (): IFields[] {
        return this.fields;
    }

    @MutationAction({ mutate: [
        'account_currencies',
        'account_mode_types',
        'affiliate_document_rejected_reasons',
        'affiliate_document_statuses',
        'affiliate_document_types',
        'account_profit_method_types',
        'affiliate_types',
        'affiliate_statuses',
        'answers',
        'attributes',
        'bonus_types',
        'checkbox_consents',
        'client_document_rejected_reasons',
        'client_document_statuses',
        'client_document_types',
        'complicatedness',
        'countries',
        'currencies',
        'departments',
        'documentation',
        'employment_statuses',
        'fund_sources',
        'heard_from',
        'industries',
        'languages',
        'lead_statuses',
        'leads_filters',
        'leads_filters_config',
        'legal_documents_statuses',
        'legal_documents_types',
        'legal_subjects',
        'leverages',
        'market_classifications',
        'mt_subject_types',
        'payment_methods',
        'payment_providers',
        'payment_requisite_statuses',
        'payment_statuses',
        'payment_types',
        'permissions',
        'field_value_statuses',
        'professional_level_request_types',
        'professional_levels',
        'questions',
        'report_columns',
        'risks',
        'timezones',
        'tin_absence_reasons',
        'titles',
        'verify_statuses',
        'fields'
    ], })
    public async loadConfigs (): Promise<IConfigs> {
        const { data } = await Rest.GET(urlConfigsAll)
            .then(Rest.middleThen)
            .catch(error => {
                Rest.simpleCatch(error);
            });
        return handlerConfigDate(data);
    }
}
