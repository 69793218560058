import { StorageService } from '~/helpers/storage-service';
import router from '~/router';
import { handlerRoutesQuery } from '~/helpers/handler-routes-query';
import { ETableFilterKeyPagination } from '~/components/tables/enums/table-filter-key.enum';

export const setQuaryPerPage = (table: string): void => {
    const localStorageItemName = `${table}_${ETableFilterKeyPagination.perPage}`;

    if (
        !router.currentRoute.query.per_page &&
        StorageService.getItem(localStorageItemName)
    ) {
        handlerRoutesQuery(
            ETableFilterKeyPagination.perPage,
            StorageService.getItem(localStorageItemName)
        );
    }
};
