import Vue from 'vue';
import { ProfitEnum } from './profit.enum';
import rowItemPropsMixin from '@/js/components/tables/mixins/table-cells-mixins/row-item-props-mixin';

export default Vue.extend({
    mixins: [
        rowItemPropsMixin,
    ],
    computed: {
        isPercentage (): boolean {
            return this.cell.name === ProfitEnum.ChangePercentage;
        },
        isProfit (): boolean {
            return this.cell.name === ProfitEnum.Profit;
        },
        isImage (): string {
            return this.cell.value[this.cell.name] > 0 || this.cell.value > 0 ? '/img/clients/trading/up.png' : '/img/clients/trading/down.png';
        },
        profitValue (): string {
            return this.cell.value.profit.replace('-', '');
        },
        currencySing (): string {
            return this.cell.value.currency.sign || '';
        }
    }
});
