import { extend } from 'vee-validate';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

/**
 *Phone number validation
 **/
const isFirstNumber = /^[0-9]/;
export const phoneNumberCode = extend('phone-code-validation', {
    validate : (value, args: any) => {
        let countryCode = args.countryCode ? args.countryCode : '';
        if (countryCode) {
            countryCode = isFirstNumber.test(countryCode) ? `+${countryCode}` : countryCode;
        }
        return !!countryCode;
    },
    params: ['countryCode']
});

export const phoneNumberValidation = extend('phone-number-validation', {
    validate: async (value, args: any) => {
        const countryCode = args.countryCode ? args.countryCode : '';
        const phoneNumber = await parsePhoneNumberFromString(`${countryCode}${value}`);
        return phoneNumber ? phoneNumber.isValid() : false;
    },
    params: ['countryCode']
});
