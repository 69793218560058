import router from '~/router';

export const handlerRoutesQuery = (key: string, value: string | string[]): void => {
    const isQuery = { ...router.currentRoute.query };
    const resultQuery = Array.isArray(value) ? value.join() : value;

    if (resultQuery) {
        isQuery[key] = resultQuery;
    } else {
        delete isQuery[key];
    }

    if (isQuery && Object.keys(isQuery).length > 0) {
        for (const query in isQuery) {
            if (Array.isArray(isQuery[query])) {
                isQuery[query] = (isQuery[query] as string[]).join();
            } else {
                isQuery[query] = isQuery[query] as string;
            }
        }
    }

    router.replace({
        query: { ...isQuery },
    })
        .catch (() => console.log());
};

export const handlerRoutesQueryClean = (): void => {
    router.replace({
        query: {},
    })
        .catch (() => console.log());
};
