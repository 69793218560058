import Vue from 'vue';
import rowItemPropsMixin from '@/js/components/tables/mixins/table-cells-mixins/row-item-props-mixin';

export default Vue.extend({
    mixins: [
        rowItemPropsMixin,
    ],
    data () {
        return {
            visible: false,
        };
    },
    computed: {
        isValue (): string[] {
            return this.cell.value ?
                this.cell.value.split(',') : [];
        },
    },
    methods: {
        toggleState () {
            this.visible = !this.visible;
        },
        toggleStateFalse () {
            this.visible = false;
        },
        isAddComma (index: number): string {
            return index !== this.isValue.length - 1 ? ',' : '';
        }
    }
});
