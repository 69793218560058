export enum KycTradingEnums {
    Accounts = 'accounts',
    StartDate = 'start_date',
    EndDate = 'end_date',
    Page = 'page',
    LeadId = 'leadId',
    Type = 'type',
    PerPage = 'per_page',
    total = 'total'
}

export enum TradingTabsNameEnums {
    OpenOrders = 'openOrders',
    PendingOrders = 'pendingOrders',
    OrderHistory = 'orderHistory'
}

export enum ETradingTabId {
    openOrders = 1,
    pendingOrders = 2,
    orderHistory = 3
}

export enum TradingSumInfoEnums {
    leverage = 'Leverage',
    balance = 'Balance',
    margin = 'U. Margin',
    f_margin = 'F. Margin',
    equity = 'Equity',
    closed_pl = 'C. P/L',
    closed_commission = 'C. Fees',
    closed_swap = 'C. Swap',
    open_pl = 'O. P/L',
    o_swap = 'O. Swap',
    currency = 'currency',
}

export enum ETradingSumInfoKey {
    leverage = 'leverage',
    balance = 'balance',
    closed_commission = 'closed_commission',
    closed_pl = 'closed_pl',
    closed_swap = 'closed_swap',
    currency = 'currency',
    equity = 'equity',
    f_margin = 'f_margin',
    margin = 'margin',
    open_pl = 'open_pl',
    o_swap = 'o_swap',
}
