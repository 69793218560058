import { EConfigType } from '~/store/modules/configs/enums/config-type.enum';
import { ILeadsConfigs } from '~/store/modules/configs/interfaces/configs-interface';

export const leadsFiltersConfigHelper = (configs: ILeadsConfigs): ILeadsConfigs => {
    const _config = {} as ILeadsConfigs;
    Object.keys(configs).forEach((config) => {
        _config[config] = configs[config] ?
            configs[config].map(item => {
                return handlerConfigItem(item);
            }) :
            [];
    });
    return _config;
};

const handlerConfigItem = (config) => {
    switch (typeof config) {
    case EConfigType.string:
        return { id: config, name: config };
    case EConfigType.object:
        return config;
    }
};
