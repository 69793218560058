import { Module, VuexModule, getModule, Action, Mutation } from 'vuex-module-decorators';
import store from '~/store/index';
import Rest from '~/helpers/Rest';
import {
    IPostTemplate,
    IMarketingTemplate,
    IPutTemplate
} from '~/store/modules/marketing/marketing-interfaces';
import { urlMarketingTemplates, marketingTemplate } from '~/settings/ajax-urls';

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: 'MarketingTemplates',
})

class MarketingTemplates extends VuexModule {
    marketingTemplates: IMarketingTemplate[] = [];

    get getMarketingTemplates (): IMarketingTemplate[] {
        return this.marketingTemplates;
    }

    @Mutation
    setMarketingTemplates (payload: IMarketingTemplate[]): void {
        this.marketingTemplates = payload;
    }

    @Mutation
    addMarketingTemplate (payload: IMarketingTemplate): void {
        this.marketingTemplates.unshift(payload);
    }

    @Mutation
    editMarketingTemplate (payload: IMarketingTemplate): void {
        const index = this.marketingTemplates
            .findIndex(template => template.id === payload.id);
        this.marketingTemplates.splice(index, 1, payload);
    }

    @Action({ commit: 'setMarketingTemplates' })
    async loadMarketingTemplates (): Promise<IMarketingTemplate[]> {
        const { data } = await Rest.GET(urlMarketingTemplates)
            .then(Rest.middleThen)
            .catch(Rest.simpleCatch);

        return data;
    }

    @Action({ commit: 'addMarketingTemplate' })
    async createMarketingTemplate (payload: IPostTemplate): Promise<IMarketingTemplate> {
        const { data } = await Rest.POST(urlMarketingTemplates, payload)
            .then(Rest.middleThen)
            .catch(Rest.simpleCatch);

        return data;
    }

    @Action({ commit: 'editMarketingTemplate' })
    async changeMarketingTemplate ({ subject, content, templateId }: IPutTemplate): Promise<IMarketingTemplate> {
        const { data } = await Rest.PUT(
            marketingTemplate(templateId),
            { subject, content }
        )
            .then(Rest.middleThen)
            .catch(Rest.simpleCatch);

        return data;
    }
}

export const MarketingTemplatesModule = getModule(MarketingTemplates);
