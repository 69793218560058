export interface FilterTradingInterfaces {
    accounts?: Array<number|string>|string;
    start_date?: string|null;
    end_date?: string|null;
    page?: number|string;
    per_page?: number|string;
}

export interface AjaxUrlForOrdersInterface {
    query: Object|null,
    type?: string,
    leadId: string|number
}

export interface DataForOrdersInterface {
    data: KycTradingInterfaces,
    meta: TradingPaginationInterface
}

export interface KycTradingInterfaces {
    acc_number: number,
    order_number: number,
    date_time: string|null,
    comment: string|null,
    size: string|number|null,
    o_price : string|number|null,
    stop_loss: string|number|null,
    take_profit: string|number|null,
    fee: string|number|null,
    swap: string|number|null,
    profit: string|number|null,
    profit_type: string|number|null
}

export interface TradingSumInfoInterface {
    leverage: number|null,
    balance: number|null,
    margin: number|null,
    f_margin: number|null,
    equity: number|null,
    closed_pl: number|null,
    closed_commission: number|null,
    closed_swap: number|null,
    open_pl: number|null
}

export interface TradingPaginationInterface {
    current_page: number;
    from: number;
    last_page: number;
    per_page: number;
    to: number;
    total: number;
}

export interface CalendarSelectEventInterface {
    default: Object,
    formattedDate: Object
}

export const DEFAULT_PER_PAGE = 20;

export interface TradingQueryPayload {
    key: string|null;
    value: string|Array<string|number|null>|null;
}

export interface handleTradingQueryInterface {
    tradingQuery: Object|null
}
