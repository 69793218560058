import Vue from 'vue';
import navbarAffiliates from '~/components/affiliates/affiliates-nav/affiliates-nav.vue';
import UploadedDocuments from '~/components/uploaded-documents/uploaded-documents.vue';
import configsMixin from '~/components/mixins/configs-mixin';
import appPhone from '~/components/parts/app-phone/app-phone.vue';
import { ELegalSubject } from '~/settings/enums/legal-subject.enum';
import { IBonusPlan } from '~/store/modules/bonus-plan/interfaces/bonus-plan-interfaces';
import { BonusPlanModule } from '~/store/modules/bonus-plan/bonus-plan';
import { affiliatesDocumentsModule } from '~/store/modules/affiliates/affiliates-documents';
import { AffiliatesPageModule } from '~/store/modules/affiliates/affiliates-page';
import { IAffiliateCreate, IAffiliatesSearchResponse, IAffiliates } from '~/store/modules/affiliates/interfaces/affiliates-interfaces';
import { parseDate } from '~/helpers/common';
import { handlerRoutesQuery } from '~/helpers/handler-routes-query';
import { EAffiliatesCompliance } from '~/settings/enums/affiliates-compliance.enum';
import { IDefaultConfig } from '~/store/modules/configs/interfaces/configs-interface';
import datePickerMixin from '~/components/mixins/date-picker-mixin';
import {
    IAffiliatesDocument,
    IAffiliatesDocumentCreate,
} from '~/store/modules/affiliates/interfaces/affiliates-documents-interfaces';
import AppAuthImg from '~/components/parts/app-auth-img/app-auth-img.vue';
import { DocumentTypesSort } from '~/store/modules/documents/document-type-helper';
import { DocumentsTypesInterface } from '~/store/modules/documents/interfaces';
import { ModalsModule } from '~/store/modules/modals';
import { DocumentsTypesModule } from '~/store/modules/documents/documents-types';
import { UploadedDocumentsTypeIdsEnum } from '~/components/uploaded-documents/uploaded-documents.enum';

export default Vue.extend({
    components: {
        navbarAffiliates,
        UploadedDocuments,
        appPhone,
        AppAuthImg,
    },
    mixins: [
        configsMixin,
        datePickerMixin,
    ],
    data () {
        return {
            id: null,
            type: null,
            full_name: '',
            dob: null,
            email: '',
            phone_mobile_country: null,
            phone_mobile: '',
            country: null,
            language: null,
            bonus_plan: null,
            bonus: null,
            status: null,
            activity: null,
            website: '',
            representative_name: '',
            searchResult: [],
            selectedAffiliate: null,
            isEdit: false,
            currentAffiliate: null,
        };
    },
    computed: {
        isAutoEmailCategoryActive (): boolean {
            return DocumentsTypesModule.getterDocumentsTypeSelected === UploadedDocumentsTypeIdsEnum.AutoEmails;
        },
        isBusinessType (): boolean {
            return this.type?.id === ELegalSubject.business;
        },
        isRepresentativeNameValidation (): string {
            return this.isBusinessType ? 'required' : '';
        },
        getBonusPlanList (): IBonusPlan[] {
            return BonusPlanModule.getBonusPlanList;
        },
        isAffiliateNameLabel (): string {
            return this.isBusinessType ? 'Company Name' : 'Affiliate Name';
        },
        getAffiliatesDocuments (): IAffiliatesDocument[] {
            return affiliatesDocumentsModule.getAffiliateDocumentList;
        },
        getDocumentTypes (): DocumentsTypesInterface[] {
            return DocumentTypesSort(this.getConfigAffiliateDocumentTypes);
        }
    },
    watch: {
        getBonusPlanList: {
            immediate: true,
            handler (value: IBonusPlan[]) {
                if (value.length > 0) {
                    this.preFillAffiliate();
                }
            }
        }
    },
    created () {
        if (this.$route.query.affiliate_id) {
            this.loadAffiliate(this.$route.query.affiliate_id);
            this.loadAffiliatesDocuments();
        }
    },
    mounted () {
        this.searchAffiliate();
    },
    methods: {
        changeEditState (payload: boolean): void {
            this.isEdit = payload;
        },
        cancelEditAffiliate (): void {
            this.changeEditState(false);
            this.$refs.observer.reset();
            this.isDateError = false;
            Object.assign(this.$data, this.currentAffiliate);
        },
        async preFillAffiliate () {
            const { affiliate_id } = this.$route.query;
            if (affiliate_id) {
                await this.loadAffiliate(affiliate_id);
            }
        },
        searchCustomLabel (payload: IAffiliatesSearchResponse) {
            return `${payload.full_name} | ${payload.email}`;
        },
        async setAffiliateDetails (payload: IAffiliates): Promise<void> {
            await Object.assign(this.$data, payload);
            const _dob = payload.dob.split('/');
            this.dob = new Date(Number(_dob[2]), Number(_dob[1]) - 1, Number(_dob[0]));

            this.currentAffiliate = payload;
            this.setBonusPlanType();
        },
        setBonusPlanType (): void {
            const bonusType = this.getBonusPlanList
                .find(bonusPlan => bonusPlan.id === this.bonus.id);

            this.bonus_plan = bonusType?.bonus_type;
            this.currentAffiliate.bonus_plan = bonusType?.bonus_type;
        },
        changePhoneMobile (value: string) {
            this.phone_mobile = value;
        },
        async searchAffiliate (query: string = ''): Promise<void> {
            this.searchResult = await AffiliatesPageModule.searchAffiliates(query);
        },
        async selectAffiliate (event: IAffiliatesSearchResponse): Promise<void> {
            handlerRoutesQuery(EAffiliatesCompliance.affiliate_id, `${event.id}`);
            await this.loadAffiliate(event.id);
            await this.loadAffiliatesDocuments();

        },
        async loadAffiliate (affiliateId: number): Promise<void> {
            await AffiliatesPageModule.loadAffiliateDetails(affiliateId)
                .then(response => {
                    this.setAffiliateDetails(response);
                });
        },
        async loadAffiliatesDocuments (): Promise<void> {
            const query = this.$route.query;
            await affiliatesDocumentsModule.affiliatesDocumentsList({
                affiliate: query[EAffiliatesCompliance.affiliate_id],
            });
        },
        async createAffiliateDocument (payload: IAffiliatesDocumentCreate): Promise<void> {
            const query = this.$route.query;
            affiliatesDocumentsModule.affiliatesDocumentCreate({
                documents: payload,
                affiliate: query[EAffiliatesCompliance.affiliate_id],
            });
        },
        getAffiliateDocumentFile (documentId: number): void {
            const document = this.getAffiliatesDocuments
                .find(item => item.id === documentId);
            if (!document?.url) {
                const query = this.$route.query;
                affiliatesDocumentsModule.affiliatesDocumentsFile({
                    documentId: documentId,
                    affiliate: query[EAffiliatesCompliance.affiliate_id],
                });
            }
        },
        selectDocument (document: IAffiliatesDocument) {
            if (this.isAutoEmailCategoryActive) {
                this.showEmailModal(document);
            } else {
                this.showAffiliateDocumentModal(document);
            }
        },
        changeType (event: number) {
            DocumentsTypesModule.actionChangeDocumentsType(event);
        },
        showAffiliateDocumentModal (document: IAffiliatesDocument) {
            ModalsModule.openModal({
                modalType: 'affiliateDocumentModal',
                props: {
                    title: '',
                    body: document,
                    isFooter: false,
                    isHeader: false
                },
                modalSettings: {
                    props: {
                        minWidth: ModalsModule.modalWidth.xl,
                    },
                },
                addClass: 'docModal'
            });
        },
        showEmailModal (document: IAffiliatesDocument) {
            ModalsModule.openModal({
                modalType: 'lightBox',
                props: {
                    title: '',
                    body: document,
                    isFooter: false,
                    isHeader: false,
                    isScroll: true,
                },
                modalSettings: {
                    props: {
                        minWidth: ModalsModule.modalWidth.lg,
                    },
                },
                addClass: 'light-box'
            });
        },
        async handlerSubmitForm (): Promise<void> {
            const isValid = await this.$refs.observer.validate();
            this.isDateError = !this.dob ? true : false;
            if (isValid && !this.isDateError) {
                const payload = {
                    full_name: this.full_name,
                    email: this.email,
                    country_id: this.country.id,
                    language_a2: this.language.A2,
                    website: this.website,
                    phone_mobile_country_id: this.phone_mobile_country.id,
                    phone_mobile: Number(this.phone_mobile),
                    bonus_id: this.bonus.id,
                    status_id: this.status.id,
                    type_id: this.type.id,
                    dob: parseDate(this.dob, 'YYYY-MM-DD').format('YYYY-MM-DD'),
                    representative_name: this.isBusinessType ? this.representative_name : this.full_name,
                };
                this.onSubmitForm(payload);

            }
        },
        async onSubmitForm (payload: IAffiliateCreate): Promise<void> {
            await AffiliatesPageModule.updateAffiliateDetails({
                id: this.id,
                payload,
            })
                .then(response => {
                    if (response) {
                        this.setAffiliateDetails(response);
                        this.changeEditState(false);
                    }
                });
        },
        selectBonusType (event: IDefaultConfig): void {
            this.bonus_plan = event;
        },
        selectBonusPlan (event: IBonusPlan): void {
            this.bonus_plan = event.bonus_type;
        },
        setRepresentativeName (payload: string): void {
            if (!this.isBusinessType) {
                this.representative_name = payload;
            }
        },
    },
});
