import Vue from 'vue';
import modalLayout from '~/components/modals/root-modal-layout/root-modal-layout.vue';
import modalsMixin from '~/components/mixins/modals';

export default Vue.extend({
    components: {
        modalLayout,
    },
    mixins: [
        modalsMixin,
    ],
    data () {
        return {
            name: '',
        };
    },
    methods: {
        close (): void {
            this.closeModal();

            if (this.modalProps.close) {
                this.modalProps.close();
            }
        },
        accept (): void {
            this.closeModal();
            this.modalProps.confirm(this.name);
        },
    },
});
