<template>
    <the-wrapper>
        <template #subheader>
            <div class="single-lead">
                <div
                    class="details"
                >
                    <item-navbar />
                </div>
            </div>
        </template>
        <div class="single-lead">
            <div
                class="details"
            >
                <details-user />
            </div>
        </div>
    </the-wrapper>
</template>

<script>
import ItemNavbar from './ItemNavbar';
import detailsUser from '../details/details-user.vue';
import leadDetailsMixin from '~/components/mixins/leadDetailsMixin';

export default {
    components: {
        ItemNavbar,
        detailsUser
    },
    mixins: [
        leadDetailsMixin,
    ],
    created () {
        this.getLeadDetails();
    }
};
</script>
