import { mapActions } from 'vuex';
// helpers
import { addNameKeyWithValueOfDisplay, getDataFromFieldValue } from '~/components/KYC/kyc-helpers';
import { IDataOfFieldValuesWithName } from '~/settings/interfaces/kyc-interfaces';
// store
import { names as clientKycNames } from '~/store/modules/client-kyc/client-kyc';
import { COLUMN_CITIZENSHIP_IDS, COLUMN_TINS } from '~/store/modules/_columnNames';
// Interfaces, enums
import { ICountries, IDefaultConfigValue } from '~/store/modules/configs/interfaces/configs-interface';
import { ITinValue } from '~/components/KYC/KycTabItem/kyc-tab-item-list/kyc-tab-item-list.interfaces';
import { EConfigType } from '~/store/modules/configs/enums/config-type.enum';
// mixins
import toggleMixin from '~/components/mixins/toggleMixin';
import clientKycActionsMixin from '~/components/mixins/clientKycActionsMixin';
import loadingMixin from '~/components/mixins/loadingMixin';
import toggleHistoryMixin from '~/components/mixins/toggle-history-mixin';
import kycTabItemActionButtonsMixin from '~/components/KYC/KycTabItem/KycTabItemActionButtons/kyc-tab-item-action-buttons-mixin';
// components
import loaderSmallRelative from '~/components/parts/loaderSmallRelative.vue';
import appHistoryKyc from '~/components/parts/app-history-kyc/app-history-kyc.vue';
import kycTabItemListItem from '~/components/KYC/KycTabItem/kyc-tab-item-list/kyc-tab-item-list-item/kyc-tab-item-list-item.vue';

export default {
    components: {
        loaderSmallRelative,
        appHistoryKyc,
        kycTabItemListItem,
    },
    mixins: [
        toggleMixin,
        clientKycActionsMixin,
        loadingMixin,
        toggleHistoryMixin,
        kycTabItemActionButtonsMixin,
    ],
    props: {
        label : {
            type : String,
            required: true,
        },
        initial : {
            type: [Object, Array, Boolean],
        },
        column : {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data (): Object {
        return {
            NOTHING : 'N/A',
            tempItems : [null],
            isResetFirstItem: false,
        };
    },
    computed: {
        items : {
            get (): null[] | IDataOfFieldValuesWithName[] {
                if (this.isResetFirstItem && !this.tempItems[0]) {
                    return this.tempItems;
                } else if (
                    getDataFromFieldValue(this.initial) &&
                    !this.tempItems[0]
                ) {
                    return addNameKeyWithValueOfDisplay(this.initial.data);
                }

                return this.tempItems;
            },
            set (items: IDataOfFieldValuesWithName | null[]): void {
                this.tempItems = items;
            }
        },
        fieldValue (): number | null {
            return this.initial?.id;
        },
        isEditable (): boolean {
            return this.initial?.field?.is_editable;
        },
    },
    methods : {
        ...mapActions({
            handleFetchClientKyc : clientKycNames.actions.handleFetch,
        }),
        getValueForApply (): ITinValue[] | number[] {
            switch (this.column) {
            case COLUMN_TINS:
                return this.items
                    .map((item: IDataOfFieldValuesWithName) => {
                        if (item?.value) {
                            return  {
                                tin: item.custom,
                                tin_country: item.value
                            };
                        }
                    })
                    .filter(item => !!item);

            case COLUMN_CITIZENSHIP_IDS:
                return this.items
                    .filter((item: IDataOfFieldValuesWithName) => item !== null)
                    .map(item => item.id || item.value);

            default:
                return [];
            }
        },
        apply (): void {
            this.startLoading();

            this.updateClientKyc({
                column: this.column,
                value: this.getValueForApply(),
            })
                .then(() => {
                    this.restore();
                    this.endLoading();
                    this.toggleOpen();
                })
                .catch(() => {
                    this.restore();
                });

            this.changeIsResetFirstItem(false);
        },
        cancel (): void {
            this.restore();
            this.toggleOpen();
            this.changeIsResetFirstItem(false);
        },
        update (index: number, item: ICountries | IDefaultConfigValue): void {
            this.tempItems = this.items;
            let newItem = item;

            if (this.column === COLUMN_TINS) {
                newItem = typeof newItem === EConfigType.string
                    ? {
                        ...this.items[index],
                        custom: newItem,
                    } : {
                        ...newItem,
                        custom: this.items[index]?.custom || ''
                    };
            }

            this.$set(this.tempItems, index, newItem);
            this.items = this.tempItems;
        },
        add (): void {
            if (this.items) {
                this.tempItems = [
                    ...this.items,
                    this.getEmpty(),
                ];
            } else {
                this.tempItems = [
                    this.getEmpty(),
                ];
            }

            this.items = this.tempItems;
        },
        remove (index: number): void {
            this.tempItems = this.items;
            this.$delete(this.tempItems, index);

            if (!index && !this.tempItems.length) {
                this.changeIsResetFirstItem(true);
                this.tempItems = [null];
            }
        },
        getEmpty (): null {
            return null;
        },
        restore (): void {
            this.tempItems = [null];
        },
        changeIsResetFirstItem (value: boolean): void {
            this.isResetFirstItem = value;
        },
    }
};
