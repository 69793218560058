import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '~/store/index';
import Rest from '~/helpers/Rest';
import { urlEmailTemplates, urlEmailTemplate } from '~/settings/ajax-urls';
import { IEmailTemplates, IEmailTemplatesPostRequest, IEmailTemplatesPutRequest } from '~/store/modules/emails/emails-interfaces';
import { NotificationPrefabricated } from '~/helpers/modal-notification-services/modal-notification-services';
import { EResponseStatus } from '~/settings/enums/response-status.enum';

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: 'emailsTemplates',
})

class EmailsTemplates extends VuexModule {
    emailTemplates: IEmailTemplates[] = [];

    get getterEmailTemplates (): IEmailTemplates[] {
        return this.emailTemplates;
    }

    @Mutation
    setEmailTemplates (payload: IEmailTemplates[]): void {
        this.emailTemplates = payload;
    }

    @Mutation
    addEmailTemplate (payload: IEmailTemplates): void {
        this.emailTemplates.push(payload);
    }

    @Mutation
    editEmailTemplate (payload: IEmailTemplates): void {
        const index = this.emailTemplates
            .findIndex(template => template.id === payload.id);
        this.emailTemplates.splice(index, 1, payload);
    }

    @Mutation
    excludeEmailTemplates (id: number) {
        this.emailTemplates = this.emailTemplates
            .filter(item => item.id !== id);
    }

    @Action
    async deleteEmailTemplates (id: number): Promise<void> {
        await Rest.DELETE(urlEmailTemplate(id))
            .then(response => {
                if (response?.status < EResponseStatus.badRequest) {
                    this.excludeEmailTemplates(id);
                    NotificationPrefabricated.deletedEmailTemplateSuccess();
                }
            })
            .catch(Rest.simpleCatch);
    }

    @Action
    async loadEmailTemplates (): Promise<void> {
        const { data } = await Rest.GET(urlEmailTemplates)
            .then(Rest.middleThen)
            .catch(Rest.simpleCatch);
        this.setEmailTemplates(data);
    }

    @Action
    async createEmailTemplate (payload: IEmailTemplatesPostRequest): Promise<IEmailTemplates> {
        const { data } = await Rest.POST(urlEmailTemplates, payload)
            .then(Rest.middleThen)
            .catch(Rest.simpleCatch);

        this.addEmailTemplate(data);

        return data;
    }

    @Action
    async changeEmailTemplate ({ subject, content, templateId }: IEmailTemplatesPutRequest): Promise<void> {
        const { data } = await Rest.PUT(urlEmailTemplate(templateId), {
            subject,
            content,
        })
            .then(Rest.middleThen)
            .catch(Rest.simpleCatch);

        this.editEmailTemplate(data);
    }
}

export const EmailsTemplatesModule = getModule(EmailsTemplates);
