import Vue from 'vue';
import appInput from '~/components/parts/app-input/app-input.vue';
import appMultiSelect from '~/components/parts/app-multiselect/app-multiselect.vue';
import appButton from '~/components/parts/app-button/app-button.vue';
import configsMixin from '~/components/mixins/configs-mixin';
import campaignMixin from '~/components/mixins/campaignMixin';
import usersListMixin from '~/components/mixins/usersListMixin';
import { SendMailIndividualEnums } from '~/store/modules/send-mail/send-mail.enums';
import { IEmailsSearchResult } from '~/store/modules/email-filter-clients/interfaces';
import { EmailFilterClientsModule } from '~/store/modules/email-filter-clients/email-filter-clients';
import { IPagination } from '~/settings/interfaces/pagination-interfaces';
import { EEmailType } from '~/components/emails/email-type.enum';
import { MarketingListsModule } from '~/store/modules/marketing/marketing-lists';
import { removeEmptyFields } from '~/helpers/remove-empty-fields';
import { IEmailFilterInterface } from '~/store/modules/send-mail/send-mail-interfaces';
import { IMarketingCreateList } from '~/store/modules/marketing/marketing-interfaces';
import { booleanOptionsWithId } from '~/settings/configs/boolean-options';

export default Vue.extend({
    components: {
        appInput,
        appMultiSelect,
        appButton
    },
    mixins: [
        configsMixin,
        campaignMixin,
        usersListMixin
    ],
    props: {
        emailType: {
            type: String,
            default: EEmailType.MailGun,
        }
    },
    data () {
        return {
            depositOptions: booleanOptionsWithId,
            countries: null,
            languages: null,
            fname: null,
            mname: null,
            lname: null,
            email: null,
            phone_mobile: null,
            clientId: null,
            leadStatus: null,
            deposit: null,
            campaigns: null,
            complicatedness: null,
            platform: null,
            users: null,
            except_ids: [],
            selectedLeads: [],
            searchResult: [],

            // for MailChimp
            create_name: '',
            create_company: '',
            create_address: '',
            create_city: '',
            create_zip: '',
            create_state: '',
            create_country: null,
            create_from_name: '',
            create_from_email: '',
            create_subject: '',
            create_language: null,
        };
    },
    computed: {
        getPagination (): IPagination {
            return EmailFilterClientsModule.getPagination;
        },
        isResultLastPage (): boolean {
            return this.getPagination.last_page === this.getPagination.current_page;
        },
        totalResult (): number {
            return this.getPagination.total;
        },
        isNotEmptySearchResult (): boolean {
            return !!this.searchResult.length;
        },
        isMailChimp (): boolean {
            return this.emailType === EEmailType.MailChimp;
        },
        baseFields (): IEmailFilterInterface {
            return {
                [SendMailIndividualEnums.UserIds]: this.users?.map(item => item.id) || '',
                [SendMailIndividualEnums.CampaignIds]: this.campaigns?.map(item => item.id) || '',
                [SendMailIndividualEnums.ComplicatednessIds]: this.complicatedness?.map(item => item.id) || '',
                [SendMailIndividualEnums.Deposit]: this.deposit ? Boolean(this.deposit?.id) : null,
                [SendMailIndividualEnums.LeadStatusIds]: this.leadStatus?.map(item => item.id) || '',
                [SendMailIndividualEnums.MtSubjectTypeIds]: this.platform?.map(item => item.id) || '',
                [SendMailIndividualEnums.LanguagesA2]: this.languages?.map(item => item.A2) || '',
                [SendMailIndividualEnums.ResidenceCountryIds]: this.countries?.map(item => item.id) || '',
                [SendMailIndividualEnums.ClientId]: this.clientId,
                [SendMailIndividualEnums.FirstName]: this.fname,
                [SendMailIndividualEnums.Email]: this.email,
                [SendMailIndividualEnums.PhoneMobile]: this.phone_mobile,
                [SendMailIndividualEnums.MiddleName]: this.mname,
                [SendMailIndividualEnums.LastName]: this.lname,
                [SendMailIndividualEnums.ExceptIds]: this.except_ids,
            };
        },
    },
    methods: {
        onFilter (): void {
            this.resetExceptIds();
            this.resetSearchResult();
            this.resetSelectedLeads();
            this.onSearch();
        },
        onSearch (page: number = 1): void {
            const setForm: IEmailFilterInterface = this.baseFields;
            const allValuesEmpty = Object.values(setForm).every(item => !item);

            if (!allValuesEmpty) {
                const payload = removeEmptyFields(setForm);
                this.fetchClients({
                    ...payload,
                    page,
                });
            }
        },
        async createList (): Promise<void> {
            const payload = removeEmptyFields({
                ...this.baseFields,
                name: this.create_name,
                company: this.create_company,
                address: this.create_address,
                city: this.create_city,
                zip: this.create_zip,
                state: this.create_state,
                country: this.create_country?.name,
                from_name: this.create_from_name,
                from_email: this.create_from_email,
                subject: this.create_subject,
                language: this.create_language?.name,
            }) as IMarketingCreateList;

            const responseData = await MarketingListsModule.createMarketingList(payload);

            this.$emit('createList', {
                list_id: responseData?.id || 5, // when back ready stay responseData
                from_name: this.create_from_name,
                from_email: this.create_from_email,
                subject: this.create_subject,
            });
        },
        async fetchClients (payload: IEmailFilterInterface) {
            const { data } = await EmailFilterClientsModule.fetchFilteredClients(payload);
            this.searchResult.push(...data);
            this.selectedLeads.push(...data);
            this.$emit('filterList', this.isNotEmptySearchResult);
        },
        selectLead (lead: IEmailsSearchResult): void {
            this.except_ids = this.except_ids.filter(except_id => except_id !== lead.id);
        },
        setExceptId (event: IEmailsSearchResult) {
            this.except_ids.push(event.id);
        },
        resetExceptIds (): void {
            this.except_ids = [];
        },
        resetSearchResult (): void {
            this.searchResult = [];
        },
        resetSelectedLeads (): void {
            this.selectedLeads = [];
        },
        async loadMore () {
            const {
                current_page,
                last_page,
            } = this.getPagination;
            if (current_page < last_page) {
                await this.onSearch(current_page + 1);
            }
        }
    }
});
