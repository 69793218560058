import Vue from 'vue';
import modalLayout from '~/components/modals/root-modal-layout/root-modal-layout.vue';
import modalsMixin from '~/components/mixins/modals';
import { generateToken } from '~/helpers/tokenGeneration';
import { affiliatesCampaignsModule } from '~/store/modules/affiliates/affiliates-campaigns';
import configsMixin from '~/components/mixins/configs-mixin';
import appDropdownBtn from '~/components/parts/app-dropdown-btn/app-dropdown-btn.vue';
import {
    ICountries,
    IDefaultConfig,
    IDefaultConfigValue,
    ILanguages,
} from '~/store/modules/configs/interfaces/configs-interface';
import campaignTokenCopyMixin from '~/components/mixins/campaign-token-copy-mixin';
import { ELanguages } from '~/settings/enums/languages.enum';
import { ECountriesId } from '~/settings/enums/countries.enum';
import { EAffiliateId } from '~/settings/enums/affiliate-id.enum';
import { campaignDisabledOptions } from '~/settings/configs/campaign-disabled-options';

export default Vue.extend({
    components: {
        modalLayout,
        appDropdownBtn,
    },
    mixins: [
        modalsMixin,
        configsMixin,
        campaignTokenCopyMixin,
    ],
    data () {
        return {
            isGenerateAAAToken: false,
            file: '',
            tokenValue: '',
            chosenUser: null,
            chosenAffiliate: null,
            campaignLanguage: null,
            campaignCountry: null,
            source: null,
            size: '',
            campaignType: '',
            campaignName: '',
            comment: '',
            campaignStatus: campaignDisabledOptions[0],

            dropzoneOptions: {
                acceptedFiles: 'image/jpeg, image/png, image/jpg',
                autoProcessQueue: false,
                url: 'https://',
                thumbnailWidth: 150,
                maxFilesize: 15,
                maxFiles: 1,
            }
        };
    },
    computed: {
        getUsersList () {
            return affiliatesCampaignsModule.getUsers;
        },
        isTokenCopyOptions (): IDefaultConfigValue[] {
            return this.geTokenCopyOptions(this.tokenValue);
        },
        isChosenAffiliateAAATradeMarketingDep (): boolean {
            return this.chosenAffiliate?.id === EAffiliateId.AAATradeMarketingDep;
        }
    },
    created () {
        this.getCountryDefault();
        this.getCampaignLanguageDefault();
    },
    methods: {
        getCampaignLanguageDefault (): void {
            this.campaignLanguage = this.getConfigLanguages.find(
                (language: ILanguages) => language.A2 === ELanguages.english
            );
        },
        getCountryDefault (): void {
            this.campaignCountry = this.getConfigCountries.find(
                (country: ICountries) => country.id === ECountriesId.cyprus
            );
        },
        resetCheckboxAndFieldToken () {
            this.handlerChangeToken(false);
            this.resetTokenValue();
        },
        chooseAffiliate (affiliate: IDefaultConfig): void {
            if (affiliate.id === EAffiliateId.AAATradeMarketingDep) {
                this.handlerChangeToken(true);
                this.generateAaaTradeToken();
            }

            if (
                this.isChosenAffiliateAAATradeMarketingDep &&
                affiliate.id !== EAffiliateId.AAATradeMarketingDep
            ) {
                this.resetCheckboxAndFieldToken();
            }

            this.chosenAffiliate = affiliate;
        },
        clearChosenAffiliate () {
            if (this.isChosenAffiliateAAATradeMarketingDep) {
                this.resetCheckboxAndFieldToken();
            }

            this.chosenAffiliate = null;
        },
        handlerChangeToken (event) {
            this.isGenerateAAAToken = event;
        },
        generateAaaTradeToken () {
            if (this.isGenerateAAAToken) {
                this.tokenValue = generateToken(32, 'aA', 'AAATR');
            } else {
                this.tokenValue = generateToken(32, 'aA');
            }
        },
        resetTokenValue () {
            this.tokenValue = '';
        },
        async saveModal  () {
            const isValid = await this.$refs.observer.validate();
            if (isValid) {
                const formData = {
                    name: this.campaignName,
                    type: this.campaignType?.value,
                    language_a2: this.campaignLanguage?.A2,
                    country_id: this.campaignCountry?.id,
                    affiliate_id: this.chosenAffiliate.id,
                    user_id: this.chosenUser.id,
                    token: this.tokenValue,
                    comment: this.comment,
                    is_disabled: this.campaignStatus?.value,
                    source: this.source || 'N/A',
                };
                affiliatesCampaignsModule.saveNewlyCreatedCampaign(formData).then(res => {
                    if (res) {
                        this.closeModal();
                        affiliatesCampaignsModule.fetchCampaignsList(this.$route.query);
                    }
                });
            }
        },
    }
});
