// Imports
import { Module, VuexModule, getModule  } from 'vuex-module-decorators';
import store from '~/store';
import { tableParser } from '~/helpers/table-parser';
import { affiliatesMarketingTableHeader } from '~/components/tables/_headers/affiliates-marketing';
// MOCK
import { AffiliatesMarketingRows } from '~/mock-data/mock-affiliates-marketing';
import { AffiliatesMarketingInterface } from './interfaces/affiliates-marketing-interface';

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: 'AffiliatesMarketing',
})

class AffiliatesMarketing extends VuexModule {
    affiliatesMarketingRows: Array<AffiliatesMarketingInterface> = AffiliatesMarketingRows;

    get getterAffiliatesMarketingHeader (): Array<object> {
        return affiliatesMarketingTableHeader;
    }

    get getterAffiliatesMarketingRows (): Array<object> {
        return tableParser(affiliatesMarketingTableHeader, this.affiliatesMarketingRows);
    }
}

export const AffiliatesMarketingModule = getModule(AffiliatesMarketing);
