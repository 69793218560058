export enum EAccountStatus {
    unverified = 1,
    pending = 2,
    verified = 3,
    closed = 4,
    rejected = 5,
    rejectedUnsuitableServices = 6,
    rejected15D = 7,
}

export enum EAccountStatusIcons {
    unverified = 'error_outline',
    pending = 'schedule',
    verified = 'task_alt',
    closed = 'cancel',
    rejected = 'cancel',
    rejectedUnsuitableServices = 'cancel',
    rejected15D = 'cancel',
}

export enum EAccountStatusColor {
    unverified = 'purple',
    pending = 'yellow',
    verified = 'green',
    closed = 'red',
    rejected = 'red',
    rejectedUnsuitableServices = 'red',
    rejected15D = 'red',
}
