import Vue from 'vue';
import dashboardSummaryMixin from '~/components/mixins/dashboard-summary-mixin';

export default Vue.extend({
    mixins: [
        dashboardSummaryMixin,
    ],
    props: {
        summary: {
            type: Object,
            required: true
        },
        name: {
            type: String,
            required: false
        },
        isPercentage: {
            type: Boolean,
            default: true
        },
        toolTipDate: {
            type: String,
            required: true,
        },
    },
    methods: {
        goTo (route: string) {
            if (route) this.$router.replace(route);
        }
    },
});
