import Vue from 'vue';
import { ModalsModule } from '~/store/modules/modals';
import { UsersModule } from '~/store/modules/users/users';
import { IUsers } from '~/store/modules/users/users-interface';
import { EAccessSettingsModalTypes } from '~/settings/enums/access-settings-modal-types.enum';
import { IDepartmentsPayload } from '~/store/modules/departments/interfaces/departments-interfaces';
import { NotificationPrefabricated } from '~/helpers/modal-notification-services/modal-notification-services';
import { EUserTitlesModals } from '~/settings/enums/user-titles-modals.enum';
import rowItemPropsMixin from '@/js/components/tables/mixins/table-cells-mixins/row-item-props-mixin';
import { isPermissionEditUserAccess, isPermissionEditUserInformation, isPermissionDeleteUsers } from '~/helpers/user-permission';

export default Vue.extend({
    mixins: [
        rowItemPropsMixin,
    ],
    computed: {
        isPermissionEditUserAccess,
        isPermissionEditUserInformation,
        isPermissionDeleteUsers,
        userList (): IUsers[] {
            return UsersModule.getUsersList.filter(user => user.id !== this.cell.ID);
        }
    },
    methods: {
        async loadUserDetails () {
            await UsersModule.loadUserDetails(this.cell.ID);
        },
        async saveSettingsUsers (permissions: number[]) {
            await UsersModule.updateUserPermissions({
                id: this.cell.ID,
                payload: permissions
            });
        },
        async accessSetting (): Promise<void> {
            await this.loadUserDetails();
            const userDetails = UsersModule.getUserDetails;
            ModalsModule.openModal({
                modalType: 'accessSettingsModal',
                props: {
                    title: 'Access Settings',
                    body: {
                        type: EAccessSettingsModalTypes.users,
                        permissions: userDetails.permissions,
                    },
                    isHeader: true,
                    isFooter: true,
                    ok: (payload: IDepartmentsPayload) => this.saveSettingsUsers(payload.permissions),
                },
                modalSettings: {
                    props: {
                        minWidth: ModalsModule.modalWidth.lg,
                    },
                },
                addClass: 'modal-users-access'
            });
        },
        edit (): void {
            ModalsModule.openModal({
                modalType: 'addEditUserModal',
                props: {
                    title: EUserTitlesModals.edit,
                    body: {
                        isEdit: true,
                        userId: this.cell.ID,
                        isEditModal: true,
                        button: {
                            text: 'Edit'
                        }
                    },
                    isHeader: true,
                    isFooter: true,
                },
                modalSettings: {
                    props: {
                        minWidth: ModalsModule.modalWidth.lg,
                    },
                },
            });
        },
        deleteUser (): void {
            ModalsModule.openModal({
                modalType: 'deleteUserModal',
                props: {
                    title: 'Delete User',
                    body: {
                        data: 'Please assign the clients to the new user',
                        userList: this.userList,
                    },
                    isHeader: true,
                    isFooter: true,
                    ok: async (assignUserId: number) => {
                        NotificationPrefabricated.deleteUser({
                            id: this.cell.ID,
                            assignUserId: assignUserId,
                        });
                    },
                },
                modalSettings: {
                    props: {
                        minWidth: ModalsModule.modalWidth.xs,
                    },
                },
            });
        }
    }
});
