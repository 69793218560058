import { convertStringToArray } from '../convert-string-to-array';
import {
    ETableFilterKeyWithdrawals
} from '~/components/tables/enums/table-filter-key.enum';

export const handlerPayments = (inputs: object): object => {
    for (const input in inputs) {
        switch (input) {
        case ETableFilterKeyWithdrawals.status_ids:
            if (inputs[input]) inputs[input] = convertStringToArray(inputs[input]);
            break;
        default:
            break;
        }
    }

    return inputs;
};
