import Vue from 'vue';
import itemMixin from '~/components/notifications/notifications-table/_item/notifications-table-item-mixin';
import { NotificationsMessagesModule } from '~/store/modules/notifications/notifications';

export default Vue.extend({
    mixins: [
        itemMixin,
    ],
    methods: {
        handleRedirect (leadId: number, notificationId: number, redirect: string): void {
            this.$router.push(`/leads/${leadId}/${redirect}`);
            NotificationsMessagesModule.handleNotificationOpen(notificationId);
        }
    }
});
