import Vue from 'vue';
import modalLayout from '~/components/modals/root-modal-layout/root-modal-layout.vue';
import modalsMixin from '~/components/mixins/modals';
import usersManagementMixin from '~/components/mixins/usersManagementMixin';
import { IUsersMassUpdatePayload } from '~/store/modules/users/users-interface';
import { UsersModule } from '~/store/modules/users/users';
import campaignMixin from '~/components/mixins/campaignMixin';
import configsMixin from '~/components/mixins/configs-mixin';

export default Vue.extend({
    components: {
        modalLayout
    },
    mixins: [
        modalsMixin,
        usersManagementMixin,
        campaignMixin,
        configsMixin,
    ],
    data () {
        return {
            user: {
                department: '',
                activity: '',
                legal_subject: [],
                campaign: [],
                user_manager: '',
            },
        };
    },
    methods: {
        handlerCreatePayload (): IUsersMassUpdatePayload {
            const payload = {
                users_ids: this.modalData.usersIds,
                activity: this.user.activity.value,
                department_id: this.user.department.id,
                user_manager_id: this.user.user_manager.id,
                legal_subject_ids: this.user.legal_subject.map(subject => subject.id),
                campaign_ids: this.user.campaign.map(subject => subject.id),
            };
            return payload;
        },
        async handlerMassEditUsers (): Promise<void> {
            const payload = this.handlerCreatePayload();
            await UsersModule.usersMassiveUpdate(payload)
                .then(() => {
                    this.closeModal();
                    UsersModule.loadUsers(this.$route.query);
                });

        },
        async handlerSubmitForm (): Promise<void> {
            const isValid = await this.$refs.observer.validate();
            if (isValid) {
                this.handlerMassEditUsers();
            }

        },
    },
});
