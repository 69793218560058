import Vue from 'vue';
import toggleMixin from '~/components/mixins/toggleMixin';
import { ITimePayload } from '~/settings/interfaces/config-default-interfaces';
import {
    regSeparationByTwoPoints,
    regTimeRange,
    regBreakTime,
} from '~/helpers/regular-expressions';

export default Vue.extend({
    mixins: [
        toggleMixin
    ],
    props: {
        label: {
            type: String,
        },
        placeholder: {
            type: String,
        },
        value: {
            type: String,
        }
    },
    data () {
        return {
            worksAllTime: false,
            twentyFourSeven: '24/7',
            className: 'b-trading-hours',
            validDays: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
            validDayShortName: ['M', 'T', 'W', 'T', 'F', 'S', 'S'], // TODO
            selectedDays: [],
            tradingHours: {
                from: '',
                to: '',
            },
            breakFirst: false,
            breakSecond: false,
            breakHoursFirst: {
                from: '',
                to: '',
            },
            breakHoursSecond: {
                from: '',
                to: '',
            },
            resultDays: '',
        };
    },
    computed: {
        getPreview (): string {
            if (this.worksAllTime) return this.twentyFourSeven;

            const tradingHours = this.tradingHours.from ?
                `: ${this.formattedHours(this.tradingHours)}` :
                '';

            const breakHoursFirst = this.breakFirst  && this.breakHoursFirst.from ?
                `; Break ${this.formattedHours(this.breakHoursFirst)}` :
                '';

            const breakHoursSecond = this.breakSecond && this.breakHoursSecond.from ?
                `, ${this.formattedHours(this.breakHoursSecond)}` :
                '';

            return `${this.resultDays}${tradingHours}${breakHoursFirst}${breakHoursSecond}`;
        },
    },
    watch: {
        getPreview: {
            immediate: true,
            handler (value: string) {
                this.$emit('input', value); // v-model
            }
        }
    },
    mounted (): void {
        this.parseInputData();
    },
    methods: {
        parseInputData () {
            if (this.value) {
                const inputData = this.value.replace(/ /g, '');
                if (inputData === this.twentyFourSeven) {
                    this.changeAllTime(true);
                } else {
                    this.parseDaysFromString(inputData);
                    this.parseTimeFromString(inputData);
                }
            }
        },
        parseDaysFromString (input: string): void {
            if (!input) return;

            const dayMatches = input.match(regSeparationByTwoPoints);

            if (dayMatches) {
                const days = dayMatches[0].split(/,/);
                days.forEach((dayRange) => {
                    const [startDay, endDay] = dayRange
                        .split(/-/)
                        .map((day) => day.trim());

                    const startIndex = startDay.length === 1 ?
                        this.validDayShortName.indexOf(startDay) :
                        this.validDays.indexOf(startDay);
                    const endIndex = endDay.length === 1 ?
                        this.validDayShortName.indexOf(endDay) :
                        this.validDays.indexOf(endDay);

                    if (startIndex !== -1) {
                        this.selectedDays.push(this.validDays[startIndex]);
                    }

                    if (endDay && endIndex !== -1) {
                        for (let i = startIndex + 1; i <= endIndex; i++) {
                            this.selectedDays.push(this.validDays[i]);
                        }
                    }
                });
                this.generatePreviewDays();
            }
        },
        parseTimeFromString (input: string): void {
            const timeMatches = input.match(regTimeRange);
            const breakMatches = input.match(regBreakTime);

            if (timeMatches) {
                const tradingHours = timeMatches[0]
                    .split('-')
                    .map((time) => time.trim());
                this.tradingHours.from = tradingHours[0];
                this.tradingHours.to = tradingHours[1];
            }

            if (breakMatches) {
                this.breakFirst = true;
                const breakHoursFirst = breakMatches[0]
                    .replace('Break', '')
                    .split(',');
                this.breakHoursFirst.from = breakHoursFirst[0]
                    .split('-')[0]
                    .trim();
                this.breakHoursFirst.to = breakHoursFirst[0]
                    .split('-')[1]
                    .trim();
                if (breakHoursFirst.length > 1) {
                    this.breakSecond = true;
                    const breakHoursSecond = breakHoursFirst[1]
                        .split('-');
                    this.breakHoursSecond.from = breakHoursSecond[0]
                        .trim();
                    this.breakHoursSecond.to = breakHoursSecond[1]
                        .trim();
                }
            }
        },
        formattedHours (hours: ITimePayload): string {
            return hours.from ?
                `${hours.from}-${hours.to}` :
                '';
        },
        changeBreakFirst (event: boolean) {
            this.breakFirst = event;
            if (!event) {
                this.breakHoursFirst = {
                    from: '',
                    to: '',
                };

                this.$nextTick(() => {
                    if (this.breakSecond) {
                        this.breakHoursFirst = this.breakHoursSecond;
                        this.changeBreakSecond(false);
                        this.breakFirst = true;
                    }
                });
            }
        },
        changeAllTime (event: boolean) {
            this.worksAllTime = event;
        },
        changeBreakSecond (event: boolean) {
            this.breakSecond = event;
            if (!event) {
                this.breakHoursSecond = {
                    from: '',
                    to: '',
                };
            }
        },
        isSelectedDay (day: string): boolean {
            return this.selectedDays.includes(day);
        },
        selectDay (day: string): void {
            if (this.worksAllTime) return;

            const index = this.selectedDays
                .findIndex(item => item === day);
            if (index < 0) {
                this.selectedDays.push(day);
            } else {
                this.$delete(this.selectedDays, index);
            }
            this.generatePreviewDays();
        },
        clickOnBody (event: Event) {
            const target = <HTMLInputElement>event.target;
            if (
                target.classList.contains === this.className ||
                target.closest(`.${this.className}`)
            ) {
                return;
            } else {
                this.close();
            }
        },
        open (): void {
            if (!this.isOpen) {
                this.show();
                setTimeout(() => {
                    this.$root.$el.addEventListener('click', this.clickOnBody);
                }, 100);
            }
        },
        close () {
            this.hide();
            setTimeout(() => {
                this.$root.$el.removeEventListener('click', this.clickOnBody);
            }, 100);
        },

        generatePreviewDays (): void {
            const sortedDays = this.selectedDays.sort(
                (a, b) => this.validDays.indexOf(a) - this.validDays.indexOf(b)
            );

            let result = sortedDays[0];
            let startDay = sortedDays[0];
            let endDay = sortedDays[0];

            for (let i = 1; i < sortedDays.length; i++) {
                const currentDay = sortedDays[i];
                const prevDay = sortedDays[i - 1];
                const nextDay = this.validDays[this.validDays.indexOf(prevDay) + 1];

                if (currentDay === nextDay) {
                    endDay = currentDay;
                } else {
                    if (startDay !== endDay) {
                        result += `-${endDay}`;
                    }
                    result += `, ${currentDay}`;
                    startDay = currentDay;
                    endDay = currentDay;
                }
            }

            if (startDay !== endDay) {
                result += `-${endDay}`;
            }

            this.resultDays = result || '';
        },
    }
});
