import {
    KycTradingEnums,
    TradingSumInfoEnums,
    TradingTabsNameEnums,
    ETradingSumInfoKey,
    ETradingTabId,
} from '~/store/modules/kyc-trading/kyc-trading.enums';
import { KycTradingModule } from '~/store/modules/kyc-trading/kyc-trading';
import { ITableHeaderColumn } from '~/components/tables/interfaces/table-header-column.interfaces';
import { ITableCell } from '~/components/tables/interfaces/table-cell-interfaces';
import { ILeverageItem } from '~/components/leadsItem/leads-item-trading/leverage-item/interface/leverage-item-interface';
import { formatHelper } from '~/components/leadsItem/leads-item-trading/format-helper';
import {
    CalendarSelectEventInterface,
    FilterTradingInterfaces,
} from '~/store/modules/kyc-trading/kyc-trading-interfaces';
import { IFormattedDate } from '~/components/calendar-select/interfaces/calendar-select-interfaces';
import { EventNameEnums } from '~/components/mixins/finance/finance-mixin.enums';
import { handlerRoutesQuery } from '~/helpers/handler-routes-query';
import { IPagination } from '~/settings/interfaces/pagination-interfaces';
import { ILeadAccount } from '~/store/modules/lead-accounts/lead-accounts-interfaces';
import { setQuaryPerPage } from '~/components/tables/helpers/setQuaryPerPage';
import { ETableFilterKeyPagination } from '~/components/tables/enums/table-filter-key.enum';

export default {
    data (): Object {
        return {
            dateSelected: '',
            filterTradingParam: KycTradingEnums,
            EventNameEnums: EventNameEnums,
            TradingTabsNameEnums: TradingTabsNameEnums,
            TradingSumInfoEnums: TradingSumInfoEnums,
            TradingSumInfoKey: ETradingSumInfoKey,
            table: TradingTabsNameEnums.OpenOrders,
            currentTab: TradingTabsNameEnums.OpenOrders,
            tradingInfoOrders: [
                ETradingSumInfoKey.leverage,
                ETradingSumInfoKey.balance,
                ETradingSumInfoKey.margin,
                ETradingSumInfoKey.f_margin,
                ETradingSumInfoKey.closed_commission,
                ETradingSumInfoKey.closed_swap,
                ETradingSumInfoKey.o_swap,
                ETradingSumInfoKey.closed_pl,
                ETradingSumInfoKey.open_pl,
                ETradingSumInfoKey.equity,
            ],
        };
    },
    computed: {
        accSelected (): ILeadAccount[] {
            const result = KycTradingModule.getAccounts.map(id => {
                return this.accounts.find(account => {
                    return +account.id === +id;
                });
            });

            return result[0] ? result : [];
        },
        isTableType (): string {
            return KycTradingModule.getCurrentTradingTab;
        },
        date (): IFormattedDate {
            return {
                startDate: KycTradingModule.getStartDay,
                endDate: KycTradingModule.getEndDay,
            };
        },
        tradingInfo (): Array<object> {
            return KycTradingModule.getTradingInfo;
        },
        tradingInfoOpenPl (): string {
            return KycTradingModule.getTradingInfo['open_pl'];
        },
        trading_info_balance (): string {
            return KycTradingModule.getTradingInfo['balance'];
        },
        trading_info_closed_pl (): string {
            return KycTradingModule.getTradingInfo['closed_pl'];
        },
        trading_info_closed_swap (): string {
            return KycTradingModule.getTradingInfo['closed_swap'];
        },
        trading_info_closed_commission (): string {
            return KycTradingModule.getTradingInfo['closed_commission'];
        },
        trading_currency_name (): string {
            const currency_name = KycTradingModule.getTradingInfo[TradingSumInfoEnums.currency]
                ? KycTradingModule.getTradingInfo[TradingSumInfoEnums.currency]
                : '-';
            return currency_name.name;
        },
        trading_currency_sign (): string {
            const currency_sign = KycTradingModule.getTradingInfo[TradingSumInfoEnums.currency]
                ? KycTradingModule.getTradingInfo[TradingSumInfoEnums.currency]
                : '-';
            return currency_sign.sign;
        },
        isTradingPosition (): string {
            return  TradingTabsNameEnums.OpenOrders;
        },
        isTradingPending (): string {
            return TradingTabsNameEnums.PendingOrders;
        },
        isTradingHistory (): string {
            return TradingTabsNameEnums.OrderHistory;
        },
        tradingTableHeader (): ITableHeaderColumn[] {
            switch (this.isTableType) {
            case this.isTradingPosition: {
                return KycTradingModule.getPositionHeader;
            }
            case this.isTradingPending: {
                return KycTradingModule.getPendingHeader;
            }
            case this.isTradingHistory: {
                return KycTradingModule.getHistoryHeader;
            }
            }
        },
        getTradingTable (): ITableCell[] {
            switch (this.isTableType) {
            case this.isTradingPosition: {
                return KycTradingModule.getTradingPositionTable;
            }
            case this.isTradingPending: {
                return KycTradingModule.getTradingPendingTable;
            }
            case this.isTradingHistory: {
                return KycTradingModule.getHistoryTradingTable;
            }
            }
        },
        isPeriodVisible (): boolean {
            return this.activeTab === TradingTabsNameEnums.OrderHistory;
        },
        isPagination (): IPagination {
            return KycTradingModule.getTradingPagination;
        },
        getTradingIsLoaded (): boolean {
            return KycTradingModule.getTradingIsLoaded;
        },
        activeTab: {
            get (): string {
                return ETradingTabId[this.$route.query.type] || this.currentTab;
            },
            set (tab: string): void {
                this.currentTab = tab;
            }
        }
    },
    methods: {
        handleTradingQuery (key: keyof FilterTradingInterfaces, value: Array<string>|string): void {
            KycTradingModule.handleTradingQuerySimple({ key, value });
            handlerRoutesQuery(key, value);
        },
        resetDatesQuery (table: string): void {
            if (
                table !== TradingTabsNameEnums.OrderHistory &&
                this.$route.query[KycTradingEnums.StartDate] || 
                this.$route.query[KycTradingEnums.EndDate]
            ) {
                handlerRoutesQuery(KycTradingEnums.StartDate, null);
                handlerRoutesQuery(KycTradingEnums.EndDate, null);
            }
        },
        loadTrading (): void {
            setQuaryPerPage(this.isTableType);
            this.setQueryTypeByTable(this.activeTab);
            const query = this.$route.query;
            KycTradingModule.handleTradingQuery(query);
            KycTradingModule.setTradingSumInfo(query);
            KycTradingModule.loadOrders({ query, type: this.activeTab });
        },
        async loadTableTrading (table: string): Promise<void> {
            handlerRoutesQuery(ETableFilterKeyPagination.perPage, null);
            setQuaryPerPage(table);
            const query = this.$route.query;
            await KycTradingModule.loadOrders({ query, type: table });
        },
        setTable (table?: string): void {
            if (table === TradingTabsNameEnums.OrderHistory) {
                this.addQueryForHistoryLoad();
            } else {
                this.resetDatesQuery(table);
            }

            this.setQueryTypeByTable(table);
            this.loadTableTrading(table);
            this.setActiveTab(table);
        },
        async filterAccounts (selectedOptions:  ILeadAccount[]): Promise<void> {
            if (!selectedOptions.length) {
                this.handleTradingQuery(KycTradingEnums.Accounts, []);
            } else {
                const accounts = selectedOptions.map(item => `${item.id}`);
                this.handleTradingQuery(KycTradingEnums.Accounts, accounts);
            }

            const query = this.$route.query;
            await KycTradingModule.setTradingSumInfo(query);
            await this.setTable(this.isTableType);
        },
        calendarSelect (event: CalendarSelectEventInterface): void {
            this.dateSelected = event.formattedDate;
            this.handleTradingQuery(KycTradingEnums.StartDate, this.dateSelected.startDate);
            this.handleTradingQuery(KycTradingEnums.EndDate, this.dateSelected.endDate);
            this.setTable(this.isTableType);
        },
        getLeverageItem (index: string, item: string|number): ILeverageItem {
            return {
                id: item,
                value: this.handlerFormatHelper(TradingSumInfoEnums.leverage, item),
                formatted: this.handlerFormatHelper(TradingSumInfoEnums.leverage, item),
            };
        },
        handlerFormatHelper (type: string, value: string|number): string {
            return formatHelper(type, value);
        },
        setQueryTypeByTable (table: string): void {
            const newRoute = {
                query: {
                    ...this.$route.query,
                    type: ETradingTabId[table],
                }
            };

            if (this.$router.resolve(newRoute).href !== this.$route.fullPath) {
                this.$router.replace(newRoute);
            }
        },
        addQueryForHistoryLoad (): void {
            this.handleTradingQuery(
                KycTradingEnums.StartDate,
                this.dateSelected.startDate || KycTradingModule.getStartDay
            );

            this.handleTradingQuery(
                KycTradingEnums.EndDate,
                this.dateSelected.endDate || KycTradingModule.getEndDay
            );
        },
        setActiveTab (tab: string): void {
            this.activeTab = tab;
        }
    }
};
