<template>
    <div class="d-flex flex-column">
        <div class="details_user">
            <table class="details_user-date-table">
                <tr>
                    <td
                        class="details_user-date-table-item"
                    >
                        Registered
                    </td>

                    <td
                        class="details_user-date-table-item"
                        :class="[hasClientDateClass(leadDetails.created_at)]"
                    >
                        {{ hasClientDate(leadDetails.created_at) }}
                    </td>

                    <td class="details_user-date-table-item">
                        on
                    </td>

                    <td class="details_user-date-table-item details_user-date-table-item--value">
                        {{ getDate(leadDetails.created_at) }}
                    </td>
                </tr>
                <tr>
                    <td
                        class="details_user-date-table-item"
                    >
                        Verified email
                    </td>

                    <td
                        class="details_user-date-table-item"
                        :class="[hasClientDateClass(leadDetails.email_verified_at)]"
                    >
                        {{ hasClientDate(leadDetails.email_verified_at) }}
                    </td>

                    <td class="details_user-date-table-item">
                        on
                    </td>

                    <td class="details_user-date-table-item details_user-date-table-item--value">
                        {{ getDate(leadDetails.email_verified_at) }}
                    </td>
                </tr>
                <tr>
                    <td
                        class="details_user-date-table-item"
                    >
                        Accepted T&C
                    </td>

                    <td
                        class="details_user-date-table-item"
                        :class="[hasClientDateClass(leadDetails.client_at)]"
                    >
                        {{ hasClientDate(leadDetails.client_at) }}
                    </td>

                    <td class="details_user-date-table-item">
                        on
                    </td>

                    <td class="details_user-date-table-item details_user-date-table-item--value">
                        {{ getDate(leadDetails.client_at) }}
                    </td>
                </tr>

                <tr>
                    <td
                        class="details_user-date-table-item"
                    >
                        Last login
                    </td>

                    <td
                        class="details_user-date-table-item"
                        :class="[hasClientDateClass(leadDetails.login_at)]"
                    >
                        {{ hasClientDate(leadDetails.login_at) }}
                    </td>

                    <td class="details_user-date-table-item">
                        on
                    </td>

                    <td class="details_user-date-table-item details_user-date-table-item--value">
                        {{ getDate(leadDetails.login_at) }}
                    </td>
                </tr>
            </table>
        </div>

        <details-general-phones />
        <details-general-email />
        <details-general-country />
    </div>
</template>

<script>
import DetailsGeneralPhones from './DetailsGeneralPhones';
import DetailsGeneralEmail from './DetailsGeneralEmail';
import DetailsGeneralCountry from './DetailsGeneralCountry';
import leadDetailsMixin from '../../mixins/leadDetailsMixin';

export default {
    components : {
        DetailsGeneralCountry,
        DetailsGeneralEmail,
        DetailsGeneralPhones,
    },
    mixins : [
        leadDetailsMixin,
    ],
};
</script>
<style lang="scss">
    .details_name {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
    }

    .details_id {
        &:before {
            content: '|';
            position: relative;
            bottom: 1px;
        }
    }

    .details_contacts {
        &__row {
            margin-bottom: 1.5rem;
            display: flex;
            flex-wrap: nowrap;
            position: relative;
            align-items: center;

            .phone-column {
                line-height: 1;
            }

            .flex-column {
                flex: 1 1;
            }

            .details_contacts__label {
                justify-content: space-between;
                align-items: center;
                flex: 1;

                &--email {
                    overflow: hidden;
                }
            }

            .details_contacts__row-items {
                justify-content: flex-end;
                max-width: calc(100% - 9rem);
                margin-left: auto;

                .b-input-wrap {
                    width: 50%;
                }
            }

            .details_category {
                margin-right: 1rem;
                flex: 1;
            }

            &--open {
                .details_contacts__row-items {
                    flex: 1;
                }
            }

            &:not(.details_contacts__row--open) {
                .details_contacts__row-items {
                    overflow: hidden;
                }
            }
        }
    }
</style>
