export const booleanOptions = [
    {
        name: 'Yes',
        value: '1',
    },
    {
        name: 'No',
        value: '0',
    },
];

// use EBooleanOptions(boolean-options.enum), if you need id 
export const booleanOptionsWithId = [
    {
        name: 'Yes',
        id: 1,
    },
    {
        name: 'No',
        id: 0,
    },
];
