import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '~/store/index';
import Rest from '~/helpers/Rest';
import { urlTermsAndConditions, urlFieldValues, urlUsersConfig } from '~/settings/ajax-urls';
import { IUsersManager } from '~/store/modules/departments/interfaces/departments-interfaces';
import { HistoryInterfaces } from './interfaces/history-interfaces';
import router from '~/router';

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: 'clientKYCDetails',
})

class KycClient extends VuexModule {
    usersList: IUsersManager[] = [];
    historyKyc: HistoryInterfaces[] = [];
    historyKycTermsAndConditions: HistoryInterfaces[] = [];

    get getUsersList (): IUsersManager[] {
        return this.usersList;
    }

    get getHistoryField (): HistoryInterfaces[] {
        return this.historyKyc;
    }

    get getHistoryTermsAndConditions (): HistoryInterfaces[] {
        return this.historyKycTermsAndConditions;
    }

    @Mutation
    mutationSetFieldHistory (payload: HistoryInterfaces[]) {
        this.historyKyc = payload;
    }

    @Mutation
    mutationSetTermsAndCondition (payload: HistoryInterfaces[]) {
        this.historyKycTermsAndConditions = payload;
    }

    @Mutation
    setUsersList (payload: IUsersManager[]) {
        this.usersList = payload;
    }

    @Action ({ commit: 'mutationSetTermsAndCondition' })
    async setHistoryTermsAndCondition () {
        const { data } = await Rest.GET(urlTermsAndConditions(+router.currentRoute.params.id))
            .then(Rest.middleThen)
            .catch(Rest.simpleCatch);

        return data;
    }

    @Action ({ commit: 'mutationSetFieldHistory' })
    async setHistoryField (field: number|string): Promise<HistoryInterfaces> {
        const { data } = await Rest.GET(urlFieldValues(+router.currentRoute.params.id, field))
            .then(Rest.middleThen)
            .catch(Rest.simpleCatch);

        return data;
    }

    @Action ({ commit: 'setUsersList' })
    async handleSetUsersList (query = {}): Promise<IUsersManager> {
        const { data } = await Rest.GET(urlUsersConfig(query))
            .then(Rest.middleThen)
            .catch(Rest.simpleCatch);

        return data;
    }

    @Action
    async handleUpdateField ({ field, status, reason }): Promise<void> {
        return await Rest.PUT(urlFieldValues(+router.currentRoute.params.id, field), {
            status_id: status,
            status_reason: reason
        })
            .then(Rest.middleThen)
            .catch(Rest.simpleCatch);
    }

    @Action ({ commit: 'mutationSetFieldHistory' })
    handleClearHistoryField (payload) {
        return payload;
    }
}

export const KycClientModule = getModule(KycClient);
