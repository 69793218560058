import Vue from 'vue';
import modalsMixin from '~/components/mixins/modals';
import AppAuthImg from '~/components/parts/app-auth-img/app-auth-img.vue';
import modalLayout from '~/components/modals/root-modal-layout/root-modal-layout.vue';

export default Vue.extend({
    components: {
        modalLayout,
        AppAuthImg
    },
    mixins: [modalsMixin],
});
