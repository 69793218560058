import router from '~/router';
import store from '~/store';
import { ETableType } from '~/components/tables/enums/table-type.enum';
import { UsersModule } from '~/store/modules/users/users';
import { withdrawalsModule } from '~/store/modules/withdrawals/withdrawals';
import { affiliatesCampaignsModule } from '~/store/modules/affiliates/affiliates-campaigns';
import { actionHandleFetchleads } from '~/store/modules/leads';
import { ClientKYCFinancesModule } from '~/store/modules/client-kyc-finances/client-kyc-finances';
import { KycTradingModule } from '~/store/modules/kyc-trading/kyc-trading';
import { AffiliatesPageModule } from '~/store/modules/affiliates/affiliates-page';
import { AffiliatesReportsModule } from '~/store/modules/affiliates/affiliates-reports';
import { ReportsModule } from '~/store/modules/reports/reports';
import { LeadsActivityLogsModule } from '~/store/modules/leads-activity-log/leads-activity-log';
import { SymbolsModule } from '~/store/modules/symbols/symbols';
import { prepareQueryWithDate } from './prepare-query-with-date';
import { NotificationsMessagesModule } from '~/store/modules/notifications/notifications';
import { NotificationsAffiliatesModule } from '~/store/modules/notifications/notifications-affiliates';
import { NotificationsActivityLogModule } from '~/store/modules/notifications/notifications-activity-log';

export const handlerLoadTable = async (table: string): Promise<void> => {
    let query = router.currentRoute.query;

    switch (table) {
    case ETableType.usersTable:
        await UsersModule.loadUsers(query);
        break;
    case ETableType.withdrawalsHistory:
        await withdrawalsModule.loadWithdrawalsHistoryTable(query);
        break;
    case ETableType.withdrawalsPending:
        await withdrawalsModule.loadWithdrawalsPendingTable(query);
        break;
    case ETableType.paymentHistory:
        await ClientKYCFinancesModule.loadPaymentsHistory(query);
        break;
    case ETableType.OpenOrders:
    case ETableType.PendingOrders:
    case ETableType.OrderHistory:
        await KycTradingModule.loadOrders({ query, type: table });
        break;
    case ETableType.leadsTable:
        // TODO
        await store.dispatch(actionHandleFetchleads, query);
        break;
    case ETableType.affiliates:
        await  AffiliatesPageModule.loadAffiliates(query);
        break;
    case ETableType.campaigns:
        await affiliatesCampaignsModule.fetchCampaignsList(query);
        break;
    case ETableType.affiliatesReports:
        query = prepareQueryWithDate(query);
        await  AffiliatesReportsModule.loadAffiliatesReports(query);
        break;
    case ETableType.affiliatesReportsCampaign:
        query = prepareQueryWithDate(query);
        await  AffiliatesReportsModule.loadCampaignsReports(query);
        break;
    case ETableType.reports:
        await ReportsModule.loadReports(query);
        break;
    case ETableType.leadActivityLog:
        await LeadsActivityLogsModule.loadLeadsActivityLogs(query);
        break;
    case ETableType.notificationsClients:
        await NotificationsMessagesModule.setNotificationsList(query);
        break;
    case ETableType.notificationsActivity:
        await NotificationsActivityLogModule.load(query);
        break;
    case ETableType.notificationsAffiliates:
        await NotificationsAffiliatesModule.load(query);
        break;
    case ETableType.symbols:
        await SymbolsModule.fetch(query);
    }
};
