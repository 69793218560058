let todayStr = new Date().toISOString().replace(/T.*$/, ''); // YYYY-MM-DD of today
let eventGuid = 0;

export const EVENTS_INIT = [
    {
        id: createEventIdPrivate(),
        title: 'test1',
        description: '',
        end: `${todayStr} 18:30`,
        start: `${todayStr} 08:30`,
        all_day: 0
    },
    /* {
        id: createEventIdPrivate(),
        title: 'test2',
        description: '',
        end: `${todayStr} 18:30`,
        start: `${todayStr} 08:30`,
        all_day: 0
    },
    {
        id: createEventIdPrivate(),
        title: 'test3',
        description: '',
        end: `${todayStr} 18:30`,
        start: `${todayStr} 08:30`,
        all_day: 0
    },
    {
        id: createEventIdPrivate(),
        title: 'test',
        description: '',
        end: `${todayStr} 18:30`,
        start: `${todayStr} 08:30`,
        all_day: 0
    },
    {
        id: createEventIdPrivate(),
        title: 'test4',
        description: '',
        end: `${todayStr} 18:30`,
        start: `${todayStr} 08:30`,
        all_day: 0
    }*/
];

function createEventIdPrivate () {
    return String(eventGuid++);
}
export function createEventId () {
    return createEventIdPrivate();
}
