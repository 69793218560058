import { ITableHeaderColumn } from '~/components/tables/interfaces/table-header-column.interfaces';
import { tableHeaderParser } from '~/components/tables/helpers/table-header-parser';

export const leadsActivityLogTableHeader: ITableHeaderColumn[] = tableHeaderParser([
    {
        id: 1,
        name: 'created_at',
        value: 'Date / Time',
        cellType: 'date-time-cell',
        cellData: 'created_at',
        textAlign: 'center',
    },
    {
        id: 2,
        name: 'causer',
        value: 'User',
        cellType: 'key-from-object',
        cellData: 'causer',
        cellDataKey: 'full_name',
    },
    {
        id: 3,
        name: 'description',
        value: 'Action',
        cellType: 'plain-text',
        cellData: 'description',
    },
    {
        id: 4,
        name: 'display',
        value: 'Description',
        cellType: 'plain-text',
        cellData: 'display',
    },
    {
        id: 5,
        name: 'ip_address',
        value: 'IP Address',
        cellType: 'ip-address',
        cellData: 'ip_address',
    },
]);
