import Vue from 'vue';
import rowItemPropsMixin from '@/js/components/tables/mixins/table-cells-mixins/row-item-props-mixin';

export default Vue.extend({
    mixins: [
        rowItemPropsMixin,
    ],
    data () {
        return {
            text: {
                true: 'Yes',
                false: 'No',
            }
        };
    },
    computed: {
        isText (): string {
            return typeof this.cell.value === 'boolean' ?
                this.text[this.cell.value] :
                '-';
        }
    }
});
