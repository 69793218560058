import Vue from 'vue';
import { ECampaignEnabledOptions, ECampaignTypeIcons } from '~/settings/enums/campaign-enabled-options.enum';
import { EIconsSettingsColor } from '~/settings/enums/status-icons-settings.enum';
import rowItemPropsMixin from '@/js/components/tables/mixins/table-cells-mixins/row-item-props-mixin';

export default Vue.extend({
    mixins: [
        rowItemPropsMixin,
    ],
    computed: {
        isValue (): string {
            return ECampaignEnabledOptions[this.cell.value];
        },
        typeIcon (): string {
            return this.isValue ? ECampaignTypeIcons[this.isValue] : '';
        },
        iconColor (): string {
            return this.typeIcon ? EIconsSettingsColor[this.typeIcon] : '';
        }
    }
});
