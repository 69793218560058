export enum EButtonStatusType {
    'outline-purple' = 1,
    'outline-yellow-bright' = 2,
    'outline-green' = 3,
}

export enum EButtonStatusIcon {
    'task_alt' = 3,
    'schedule' = 2,
    'error_outline' = 1,
}
