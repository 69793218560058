import { sliceHoursMinutes } from '@/js/helpers/sliceHoursMinutes';
import { ENamesAwarenessMessages } from '~/components/KYC/kyc-enums';
import { getterEntity } from '~/store/modules/client-kyc/client-kyc';

export default {
    props: {
        declaration: {
            type: Object,
            required: true,
        },
        currentDeclaration: {
            type: Object,
            required: true,
        },
    },
    computed: {
        getNameDeclaration (): string {
            return this.declaration.name.includes(ENamesAwarenessMessages.W8BEN)
                ? this.declaration.name.slice(0, ENamesAwarenessMessages.W8BEN.length)
                : this.declaration.name;
        },
        getTitleForArrow (): string {
            return `${ this.currentDeclaration?.id === this.declaration.id ? 'Close' : 'Open'} text declaration`;
        },
        IpAdress (): string {
            return this.$store.getters[getterEntity].ip_address?.data
                ? '| IP: ' + this.$store.getters[getterEntity].ip_address.data[0].display
                : '';
        },
        infoText (): string {
            return `Yes | Date: ${this.declaration.created_at.date} Time: ${sliceHoursMinutes(this.declaration.created_at.time)} ${this.IpAdress}`;
        },
    },
    methods: {
        toggleDeclaration (): void {
            this.$emit('toggleDeclaration', this.declaration);
        }
    },
};
