import Chart from 'chart.js';
import rowItemPropsMixin from '@/js/components/tables/mixins/table-cells-mixins/row-item-props-mixin';

export default {
    mixins: [
        rowItemPropsMixin,
    ],
    data (): object {
        return {
            chart: {
                type: 'pie',
                data: {
                    datasets: [{
                        data: [this.cell.value.answeredAmount, this.cell.value.unansweredAmount],
                        backgroundColor: ['#6dbf5a', '#ffffff'],
                        borderColor: '#d4d4e5',
                        borderAlign: 'center',
                        borderWidth: 1
                    }],
                },
                options: {
                    segmentShowStroke: false,
                    maintainAspectRatio: false,
                    legend: {
                        display: false,
                    },
                    scales: {
                        display: false,
                        xAxes: [
                            {
                                display: false,
                            },
                        ],
                        yAxes: [
                            {
                                display: false,
                            },
                        ],
                    },
                },
            },
        };
    },
    computed: {
        effectivePercent (): string {
            return (this.cell.value.answeredAmount / (this.cell.value.unansweredAmount + this.cell.value.answeredAmount) * 100).toFixed(0);
        },
        ineffectivePercent (): number {
            return 100 - this.effectivePercent;
        }
    },
    mounted (): void {
        const canvas = <HTMLCanvasElement> document.getElementById(`answered${this.$attrs.index}`);
        const ctx = canvas.getContext('2d');
        this.chart.link = new Chart(ctx, this.chart);
    }
};
