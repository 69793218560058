<template>
    <div>
        <div
            v-for="(notification, index) of notifications"
            :key="notification.id"
            class="toast-notification"
            :style="{ top: getNotificationPosition(index) }"
            :class="[`toast-notification__${notification.type}`]"
        >
            <div class="toast-notification__wrapper">
                <transition
                    name="fade"
                    appear
                >
                    <div class="toast-notification__body">
                        {{ notification.text }}
                        <div class="toast-notification__close">
                            <b-icon
                                icon="x"
                                variant="secondary"
                                aria-hidden="true"
                                @click="closeNotification(index)"
                            />
                        </div>
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
import { getterNotifications, actionRemoveNotification } from '../store/modules/notifications';
import { mapGetters, mapActions } from 'vuex';
export default {
    computed: {
        ...mapGetters({
            notifications: getterNotifications,
        })
    },
    methods: {
        ...mapActions({
            closeNotification: actionRemoveNotification
        }),
        getNotificationPosition (notificationIndex) {
            return `${notificationIndex * 60 + 60}px`;
        },
    }
};
</script>
