import Vue from 'vue';
import rowItemPropsMixin from '@/js/components/tables/mixins/table-cells-mixins/row-item-props-mixin';
import configsMixin from '~/components/mixins/configs-mixin';
import { ICountries } from '~/store/modules/configs/interfaces/configs-interface';

export default Vue.extend({
    mixins: [
        rowItemPropsMixin,
        configsMixin,
    ],
    computed: {
        getKey (): string {
            return this.cell?.cellDataKey || 'id';
        },
        getCurrentCountry (): ICountries {
            return this.getConfigCountries
                .find(country => country[this.getKey] === this.cell.value);
        },
        getFlag (): string {
            return this.getCurrentCountry?.A2;
        },
        getName (): string {
            return this.getCurrentCountry?.name;
        }
    }
});
