import Vue from 'vue';
import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '~/store';
import Rest from '~/helpers/Rest';
import { urlAutomaticEmails, urlAutomaticEmailsFile } from '~/settings/ajax-urls';
import { AutomaticEmailsInterfaces, IAutomaticEmailsFilePayload } from './emails-interfaces';
import { IAffiliatesDocumentFileAddSrc } from '~/store/modules/affiliates/interfaces/affiliates-documents-interfaces';

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: 'AutomaticEmails',
})


export class AutomaticEmails extends VuexModule {
    entities: Array<AutomaticEmailsInterfaces> = [];

    get getAutomaticEmailsList (): Array<AutomaticEmailsInterfaces> {
        return this.entities;
    }

    @Mutation
    setDocumentSrc (payload: IAffiliatesDocumentFileAddSrc): void {
        const index = this.entities
            .findIndex(item => item.id === payload.documentId);
        Vue.prototype.$set(this.entities[index], 'url', payload.src);
    }

    @Mutation
    mutationAutomaticEmailsList (payload: Array<AutomaticEmailsInterfaces>): void {
        this.entities = payload;
    }

    @Action ({ commit: 'mutationAutomaticEmailsList' })
    async setAutomaticEmailsList (leadId: number): Promise<AutomaticEmailsInterfaces> {
        const data: AutomaticEmailsInterfaces = await Rest.GET(urlAutomaticEmails(leadId))
            .then(Rest.middleThen)
            .then(response => {
                if (!Object.values(response.data).length) throw new Error(`empty ${name} response`);
                return response.data;
            })
            .catch(error => {
                Rest.simpleCatch(error);
            });

        return data;
    }

    @Action
    async automaticEmailDocumentsFile (payload: IAutomaticEmailsFilePayload): Promise<void> {
        const documentId = payload.documentId;
        await Rest.GET(urlAutomaticEmailsFile(payload.clientId, documentId))
            .then(res => res.blob())
            .then(blob => {
                const src = URL.createObjectURL(blob);
                this.setDocumentSrc({
                    documentId,
                    src,
                });
            })
            .catch(error => {
                Rest.simpleCatch(error);
            });
    }
}

export const automaticEmailsModule = getModule(AutomaticEmails);
