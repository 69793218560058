import { Module, VuexModule, Mutation, Action, getModule, MutationAction } from 'vuex-module-decorators';
import store from '~/store/index';
import { defaultAuthForm } from './profile.enums';
import { defaultProfile } from './profile-helpers';
import { defaultProfileInterface, IReportTemplates } from './profile-interfaces';
import router from '~/router/index';
import routes from '~/settings/routes';
import Rest from '~/helpers/Rest';
import {
    urlLogout,
    urlProfile,
    urlLogin,
    urlForgotPassword,
    urlResetPassword
} from '~/settings/ajax-urls';
import Local from '~/helpers/Local';
import { formatErrorMessage } from '~/helpers/format';
import { IConfig } from '~/settings/interfaces/config-default-interfaces';

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: 'Profile',
})
class Profile extends VuexModule {
    profile: defaultProfileInterface = defaultProfile as defaultProfileInterface;
    passwordResetSuccess: String = defaultAuthForm.passwordResetSuccess;

    get getProfile (): defaultProfileInterface {
        return this.profile;
    }
    get getIsAuth (): Boolean {
        return !!this.profile['id'];
    }
    get getAuthForm (): String {
        return this.passwordResetSuccess;
    }

    get getUserPermission (): IConfig[] {
        return this.profile.permissions;
    }

    get getReportTemplates (): IConfig[] {
        return this.profile.report_templates;
    }

    @Mutation
    setProfile (payload: defaultProfileInterface) {
        this.profile = payload;
    }
    @Mutation
    setAuthForm (password: String) {
        if (typeof password !== 'undefined') this.passwordResetSuccess = password;
    }
    @Mutation
    setReportTemplate (payload: IReportTemplates): void {
        this.profile.report_templates.push(payload);
    }
    @Action
    handleSetLogin (payload: defaultProfileInterface) {
        this.setProfile(payload);
        const redirect = router['history'].current.query.redirect ? router['history'].current.query.redirect : routes.dashboard.path;
        router.push(redirect);
    }
    @Action({ commit: 'setProfile' })
    handleLogout () {
        Rest.POST(urlLogout)
            .then(Rest.middleNoContent)
            .then(() => {
                router.push(routes.login.path);
                Local.logout();
            })
            .catch(Rest.simpleCatch);
        return defaultProfile;
    }
    @MutationAction({ mutate: ['profile'] })
    async initAuth () {
        try {
            const profileId = await Local.getProfileIdPromise();
            const token = await Local.getTokenPromise();

            if (!profileId || !token) throw new Error('not authorized');

            Rest.setToken(token);

            const response = await Rest.GET(urlProfile);

            if (response.status >= 400) {
                throw new Error(response.statusText);
            }
            const { data: { id, username, fname, lname, department_id, department_name, permissions, email, report_templates } } = await response.json();

            return {
                profile: { id, username, fname, lname, department_id, department_name, permissions, email, report_templates }
            };
        } catch (error) {
            await Local.logout();
            router.push({ name: routes.login.name }).catch(() => console.log(error));
        }

        return {
            profile: defaultProfile
        };
    }
    @Action({ commit: 'setAuthForm' })
    handleSetAuthForm (password: String) {
        return password;

    }
    @Action({ rawError: true })
    async handleLogin ({ login, password, rememberMe }) {
        return await Rest.POST(urlLogin, {
            username: login,
            password
        })
            .then(response => {
                return new Promise((res, rej) => {
                    if (response.status >= 400) {
                        response.json()
                            .then(({ errors }) => {
                                rej(errors);
                            });
                    } else {
                        response.json()
                            .then(responseJson => {
                                const { data : { id, username, fname, lname, token, department_id, department_name, permissions, email, report_templates } } = responseJson;

                                Rest.setToken(token);
                                if (rememberMe) {
                                    Local.setToken(token);
                                    Local.setProfileId(id);
                                }
                                res(responseJson.data);
                                this.handleSetLogin({ id, username, fname, lname, department_id, department_name, permissions, email, report_templates });
                            });
                    }
                });
            })
            .finally(() => {
                this.handleSetAuthForm('');
            });
    }
    @Action({ rawError: true })
    async handleForgotPassword ({ email }) {
        return  await Rest.POST(urlForgotPassword, { email })
            .then(response => {
                if (response.status >= 400) {
                    return new Promise((res, rej) => {
                        response.json()
                            .then(({ message }) => {
                                rej(formatErrorMessage(message));
                            });
                    });
                }
                return response.json();
            })
            .then(({ message }) => {
                return message;
            });

    }
    @Action({ rawError: true })
    async handlePasswordReset ({ email, token, password, password_confirmation }) {
        return await Rest.POST(urlResetPassword, {
            email,
            token,
            password,
            password_confirmation
        })
            .then(response => {
                return new Promise((res, rej) => {
                    if (response.status >= 400) {
                        response.json()
                            .then(({ errors }) => {
                                rej(formatErrorMessage(errors));
                            });
                    } else {
                        response.json()
                            .then(({ message }) => {
                                this.handleSetAuthForm(message);
                                res(message);
                            });
                    }
                });

            });
    }
}
export const moduleName = 'Profile';
export const mutationSetProfile = `${moduleName}/setProfile`;
export const ProfileModule = getModule(Profile);
