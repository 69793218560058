export default {
    data () {
        return {
            isOpen : false,
        };
    },
    methods : {
        toggleOpen () {
            this.isOpen = !this.isOpen;
        },
        show () {
            this.isOpen = true;
        },
        hide () {
            this.isOpen = false;
        }
    }
};
