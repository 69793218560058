import Vue from 'vue';
import { FinanceContentItemBodyInterfaces } from './interfaces/finance-content-item-body-interfaces';
export default Vue.extend({
    props: {
        noPadding: {
            type: Boolean
        },
        addClass: {
            type: String
        }
    },
    data (): FinanceContentItemBodyInterfaces {
        return {
            className: 'b-finance-content-item-body'
        };
    },
    computed: {
        isNoPadding (): Boolean {
            return this.noPadding;
        }
    }

});
