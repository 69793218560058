import Vue from 'vue';

export default Vue.extend({
    props: {
        cell: {
            type: Object,
            required: true
        },
        row: {
            type: Array,
            required: true
        },
        width: {
            type: String
        },
        index: {
            type: [String, Number],
            required: false
        },
        phone: {
            type: Boolean,
        },
    }
});
