import { ITableHeaderColumn } from '~/components/tables/interfaces/table-header-column.interfaces';
import { ETableFilterInputType, ETableFilterType } from '~/components/tables/enums/table-filter-type.enum';
import { tableHeaderParser } from '~/components/tables/helpers/table-header-parser';

export const affiliatesReportsTableHeader: ITableHeaderColumn[] = tableHeaderParser([
    {
        id: 2,
        name: 'affiliate_name_reports',
        value: 'Affiliate Name',
        cellType: 'plain-text-tooltip',
        cellData: 'full_name',
        filterType: ETableFilterType.input,
        inputType: ETableFilterInputType.text,
        cellClass: 'up-first-latter',
    },
    {
        id: 1,
        name: 'campaigns_reports',
        value: 'Campaigns',
        cellType: 'plain-text',
        cellData: 'affiliate_campaigns_count',
        filterType: ETableFilterType.component,
        filterComponent: 'multi-range-filter',
        textAlign: 'right',
    },
    {
        id: 9,
        name: 'bonus_plan',
        value: 'Bonus Plan',
        cellType: 'plain-text',
        cellData: 'bonus_plan_type',
        filterType: ETableFilterType.select,
    },
    {
        id: 3,
        name: 'new_leads',
        value: 'Leads',
        cellType: 'plain-text',
        cellData: 'leads_count',
        textAlign: 'right',
        filterType: ETableFilterType.component,
        filterComponent: 'multi-range-filter',

    },
    {
        id: 6,
        name: 'ftd',
        value: ' FTD',
        cellType: 'plain-text',
        cellData: 'ftd_count',
        textAlign: 'right',
        filterType: ETableFilterType.component,
        filterComponent: 'multi-range-filter',
    },
    {
        id: 7,
        name: 'ftd_ratio',
        value: 'FTD Ratio',
        cellType: 'plain-sum-percentage',
        cellData: 'ftd_ratio',
        textAlign: 'right',
        filterType: ETableFilterType.component,
        filterComponent: 'multi-range-filter',
    },
    {
        id: 5,
        name: 'ftd_amount',
        value: 'FTD Amount',
        cellType: 'plain-text',
        cellData: 'ftd_amount',
        textAlign: 'right',
        filterType: ETableFilterType.component,
        filterComponent: 'multi-range-filter',
    },
    {
        id: 4,
        name: 'deposit_amount',
        value: 'Deposits',
        cellType: 'plain-text',
        cellData: 'deposit_amount',
        textAlign: 'right',
        filterType: ETableFilterType.component,
        filterComponent: 'multi-range-filter',
    },
    {
        id: 8,
        name: 'profit_amount',
        value: 'Profit',
        cellType: 'plain-sum',
        cellData: 'profit_amount',
        textAlign: 'right',
        filterType: ETableFilterType.component,
        filterComponent: 'multi-range-filter',
    },
    {
        id: 10,
        name: 'bonus_plan_min_deposit',
        value: 'Min. Deposit',
        cellType: 'plain-text',
        cellData: 'bonus_plan_min_deposit',
        textAlign: 'right',
        filterType: ETableFilterType.component,
        filterComponent: 'multi-range-filter',

    },
    {
        id: 11,
        name: 'average_commission',
        value: 'Average Comm.',
        cellType: 'plain-text',
        cellData: 'average_commission',
        filterType: ETableFilterType.component,
        filterComponent: 'multi-range-filter',
        textAlign: 'right',
    },
    {
        id: 12,
        name: 'commission',
        value: 'Commission',
        cellType: 'plain-text',
        cellData: 'commission',
        textAlign: 'right',
        filterType: ETableFilterType.component,
        filterComponent: 'multi-range-filter',
    },
    {
        id: 13,
        name: 'roi_amount',
        value: 'ROI',
        cellType: 'plain-sum-percentage',
        cellData: 'roi_amount',
        textAlign: 'right',
        filterType: ETableFilterType.component,
        filterComponent: 'multi-range-filter',
        cellWidth: '140px',
    },
]);
