import Vue from 'vue';
import EmailsNavbar from '~/components/emails/emails-navbar/emails-navbar.vue';
import EmailSendMessage from '~/components/emails/email-send-message/email-send-message.vue';

export default Vue.extend({
    components: {
        EmailsNavbar,
        EmailSendMessage
    }
});
