import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '~/store/index';

// MOCK DATA
import { EVENTS_INIT } from '~/mock-data/mock-calendar';

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: 'Calendar',
})

class Calendar extends VuexModule {
    calendarEvents: Array<object> = [];
    calendarNotifications: Array<object> = [];
    currentView: string = 'dayGridMonth'

    get getCalendarEvents (): Array<object> {
        return this.calendarEvents;
    }

    get getCalendarNotifications (): Array<object> {
        return this.calendarNotifications;
    }

    get getCurrentViewCalendar (): string {
        return this.currentView;
    }

    @Mutation
    mutationAddNotification (payload: Object) {
        this.calendarNotifications = [...this.calendarNotifications, { ...payload }];
    }

    @Mutation
    mutationRemoveNotification (index: number) {
        const notifications = [...this.calendarNotifications];
        notifications.splice(index, 1);
        this.calendarNotifications = [...notifications];
    }

    @Mutation
    setCalendarEvents (payload: Array<object>) {
        this.calendarEvents = payload;
    }

    @Mutation
    mutationAddEvent (payload: Object) {
        this.calendarEvents.push(payload);
    }

    @Mutation
    setCurrentView (payload: string) {
        this.currentView = payload;
    }

    @Mutation
    mutationDeleteEvent (id: Number) {
        const calendarEventsPart = this.calendarEvents.filter((event: Object) => {
            return event['id'] !== id;
        });
        this.calendarEvents = calendarEventsPart;
    }


    @Action({ commit: 'setCalendarEvents' })
    loadCalendarEvents () {
        return EVENTS_INIT;
    }

    @Action ({ commit: 'mutationAddEvent' })
    addEvent (payload: Object) {
        return payload;
    }

    @Action ({ commit: 'mutationDeleteEvent' })
    deleteEvent (id: Number) {
        return id;
    }

    @Action ({ commit: 'mutationAddNotification' })
    addNotification (notification: Object) {
        return notification;
    }

    @Action ({ commit: 'mutationRemoveNotification' })
    removeNotification (notificationIndex: number) {
        return notificationIndex;
    }

    @Action ({ commit: 'setCurrentView' })
    changeCurrentView (currentView: string) {
        return currentView;
    }
}
export const CalendarModule = getModule(Calendar);
