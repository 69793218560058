// Imports
import Vue from 'vue';

import AppMultiSelect from '~/components/parts/app-multiselect/app-multiselect.vue';
import AppInput from '~/components/parts/app-input/app-input.vue';

import configsMixin from '~/components/mixins/configs-mixin';
import { mapActions } from 'vuex';
import { actionHandleUpdate } from '~/store/modules/client-kyc/client-kyc';
import clientKycMixin from '~/components/mixins/client-kyc-mixin/client-kyc-mixin';
import { ModalsModule } from '~/store/modules/modals';

import { EModalType } from '~/helpers/modal-services/enums/modal-type.enum';
import { EModalProps } from '~/helpers/modal-services/enums/modal-props.enum';
import {
    AS_COLUMN_PROFESSIONAL_LEVEL,
    COLUMN_VERIFY_STATUS_REJECT_REASON
} from '~/store/modules/_columnNames';
import { AppKycEnums } from './enums/app-kyc.enums';
import clientTypeMixin from '~/components/mixins/client-type-mixin';
import { IDefaultConfig } from '@/js/store/modules/configs/interfaces/configs-interface';
import { modalType } from '@/js/helpers/modal-services/enums/modal-settings';
import { EProfessionalLevels } from '@/js/settings/enums/professional-levels.enum';
import {
    isPermissionEditComplianceDocs,
    isPermissionEditClientsGroup,
    isPermissionFinance,
    isPermissionApprovedDepositsWithdrawal
} from '~/helpers/user-permission';

// Exports
export default Vue.extend({
    components: {
        AppMultiSelect,
        AppInput
    },
    mixins: [
        configsMixin,
        clientKycMixin,
        clientTypeMixin,
    ],
    data () {
        return {
            approved_deposit_amount_value: 0,
            clientCategorisationData: null,
        };
    },
    computed: {
        isPermissionEditComplianceDocs,
        isPermissionEditClientsGroup,
        isPermissionFinance,
        isPermissionApprovedDepositsWithdrawal,
        clientCategorisation: {
            get (): IDefaultConfig {
                return this.clientCategorisationData || this.professional_level;
            },
            set (value: IDefaultConfig): void {
                this.clientCategorisationData = value;
            }
        },
    },
    watch: {
        approved_deposit_amount: {
            immediate: true,
            handler (value: string | number) {
                this.approved_deposit_amount_value = value;
            },
        }
    },
    methods: {
        ...mapActions({
            handleUpdateClientKyc : actionHandleUpdate,
        }),
        handleSelect (selectItem: IDefaultConfig, type: string): void {
            if (
                type === this.AS_COLUMN_VERIFY_STATUS
                    && (selectItem.id === AppKycEnums.rejectedId
                    || selectItem.id === AppKycEnums.closedId
                    || selectItem.id === AppKycEnums.rejectFifteenDays)
            ) {
                ModalsModule.openModal({
                    modalType: EModalType.confirmUpdateLeads,
                    props: {
                        title: 'Rejection reason',
                        body: 'Type reason rejection:',
                        isHeader: true,
                        isFooter: true,
                        isReason: true,
                        reason: '',
                        ok: () => {
                            this.handleUpdate(selectItem, type);
                        },
                    },
                });
            } else {
                if (
                    type === AS_COLUMN_PROFESSIONAL_LEVEL &&
                    selectItem.id === EProfessionalLevels.retail
                ) {
                    this.preConfirmClientCategorisation(selectItem);
                } else {
                    this.handleUpdateClientKyc({ column: type, value: selectItem });
                }
            }
        },
        handleSendApprovedDeposit (event: string, type: string) {
            this.handleUpdateClientKyc({
                column: type,
                value: Number(event.replaceAll(',', '')),
            });
        },
        handleUpdate (payload: IDefaultConfig, type: string): void {
            const reason = ModalsModule.getModalPropByKey(EModalType.confirmUpdateLeads, EModalProps.reason);
            this.handleUpdateClientKyc([
                { column: type, value: payload },
                { column: COLUMN_VERIFY_STATUS_REJECT_REASON, value: reason }
            ]);
        },
        preConfirmClientCategorisation (selectItem: IDefaultConfig): void {
            const { offsetLeft, offsetHeight } = this.$refs.clientCategorisation.$el;

            ModalsModule.openModal({
                modalType: modalType.actionModal,
                props: {
                    body: {
                        text: 'Have you checked if there is enough Free Margin to support client’s positions? This action may change the client’s accounts leverage!',
                    },
                    isHeader: true,
                    isFooter: false,
                    confirm: () => {
                        this.handleUpdateClientKyc({
                            column: AS_COLUMN_PROFESSIONAL_LEVEL,
                            value: selectItem
                        });
                    },
                    close: () => {
                        this.clientCategorisation = null;
                    },
                },
                modalSettings: {
                    props: {
                        minWidth: ModalsModule.modalWidth.sm,
                    },
                    positionModal: {
                        clientX: offsetLeft,
                        clientY: offsetHeight,
                        modalWidth: ModalsModule.modalWidth.sm,
                    }
                },
            });
        },
    },
});
