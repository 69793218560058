import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '~/store/index';
import Rest from '~/helpers/Rest';
import { urlPaymentRequisites } from '~/settings/ajax-urls';
import router from '~/router';
import { IPaymentRequisiteRequest, IPaymentRequisiteResponse, IPaymentRequisiteRequestUpdate } from './payment-requisites-interfaces';
import { paymentRequisitesCleanData } from './helpers/payment-requisites-clean-data';
import { NotificationPrefabricated } from '~/helpers/modal-notification-services/modal-notification-services';
import { handlerRestResponse } from '~/helpers/handler-rest-response';
import { IConfigValue } from '~/settings/interfaces/config-default-interfaces';
import {
    paymentRequisitesSourceAvailable,
    paymentRequisitesSubTypeAvailable
} from './helpers/payment-requisites-sub-type-available';
import { leadsBankAccountsTableHeader } from '~/components/tables/_headers/payment-methods/bank-accounts';
import { leadsCreditCardsTableHeader } from '~/components/tables/_headers/payment-methods/credit-cards';
import { leadsEWalletTableHeaders } from '~/components/tables/_headers/payment-methods/e-wallets';
import { leadsCryptoTableHeaders } from '~/components/tables/_headers/payment-methods/crypto';
import { ITableHeaderColumn } from '~/components/tables/interfaces/table-header-column.interfaces';

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: 'PaymentRequisites',
})
class PaymentRequisites extends VuexModule {
    paymentRequisites: IPaymentRequisiteResponse[] = [];
    subTypeAvailable: IConfigValue[] = [];
    sourceAvailable: IConfigValue[] = [];
    loaded: boolean = false;

    get getPaymentRequisites (): IPaymentRequisiteResponse[] {
        return this.paymentRequisites;
    }

    get getBankAccountsHeader (): ITableHeaderColumn[] {
        return leadsBankAccountsTableHeader;
    }

    get getCreditCardsTableHeaders (): ITableHeaderColumn[] {
        return leadsCreditCardsTableHeader;
    }

    get getEWalletTableHeaders (): ITableHeaderColumn[] {
        return leadsEWalletTableHeaders;
    }

    get getCryptoHeaders (): ITableHeaderColumn[] {
        return leadsCryptoTableHeaders;
    }

    get getProvidersFromRequisites (): Array<number> {
        const providerIds = this.paymentRequisites.map(item => item.method_id);
        return [...new Set(providerIds)];
    }

    get getPaymentRequisitesSubTypeAvailable (): IConfigValue[] {
        return this.subTypeAvailable;
    }

    get getPaymentRequisitesSourceAvailable (): IConfigValue[] {
        return this.sourceAvailable;
    }

    get getPaymentRequisitesIsLoaded (): boolean {
        return this.loaded;
    }

    @Mutation
    setPaymentRequisites (payload: IPaymentRequisiteResponse[]) {
        this.paymentRequisites = payload;
    }

    @Mutation
    isAddPaymentRequisite (payload: IPaymentRequisiteResponse) {
        this.paymentRequisites.push(payload);
    }

    @Mutation
    setSubTypeAvailable (payload: IConfigValue[]) {
        this.subTypeAvailable = payload;
    }

    @Mutation
    setSourceAvailable (payload: IConfigValue[]) {
        this.sourceAvailable = payload;
    }

    @Mutation
    setLoadedState (payload: boolean) {
        this.loaded = payload;
    }

    @Action({ commit: 'setPaymentRequisites' })
    async loadPaymentRequisites (): Promise<IPaymentRequisiteResponse[]> {
        this.setLoadedState(false);
        const response = await Rest.GET(urlPaymentRequisites(+router.currentRoute.params.id))
            .then((response) => response.json())
            .then((responseJSON) => {
                const sourceAvailable = paymentRequisitesSourceAvailable(responseJSON.data);
                this.setSourceAvailable(sourceAvailable);

                const subTypeAvailable = paymentRequisitesSubTypeAvailable(responseJSON.data);
                this.setSubTypeAvailable(subTypeAvailable);

                return responseJSON.data;
            })
            .catch(Rest.simpleCatch)
            .finally(() => this.setLoadedState(true));
        return response;
    }

    @Action({ commit: 'isAddPaymentRequisite' })
    async addPaymentRequisite (payload: Partial<IPaymentRequisiteRequest>): Promise<IPaymentRequisiteResponse> {
        const response = await Rest.POST(urlPaymentRequisites(+router.currentRoute.params.id), payload)
            .then(response => {
                return handlerRestResponse(response, NotificationPrefabricated.AddPaymentRequisitesSuccess);
            })
            .catch(error => {
                NotificationPrefabricated.Error(error);
            });
        return response;
    }
    @Action
    async editPaymentRequisite (payload: Partial<IPaymentRequisiteRequestUpdate>): Promise<IPaymentRequisiteResponse> {
        const cleanPayload = await paymentRequisitesCleanData(payload);

        return Rest.PUT(
            urlPaymentRequisites(+router.currentRoute.params.id, cleanPayload.id), cleanPayload
        )
            .then(response => {
                return handlerRestResponse(response, NotificationPrefabricated.editPaymentRequisitesSuccess);
            })
            .catch(Rest.simpleCatch);
    }

    @Action
    async removePaymentRequisite (payload: number): Promise<void> {
        await Rest.DELETE(urlPaymentRequisites(+router.currentRoute.params.id, payload))
            .catch(Rest.simpleCatch);
    }
}
export const PaymentRequisitesModule = getModule(PaymentRequisites);
