import Vue from 'vue';
import rootTable from '~/components/tables/root-table/root-table.vue';
import { NotificationsActivityLogModule } from '~/store/modules/notifications/notifications-activity-log';
import { ITableHeaderColumn } from '~/components/tables/interfaces/table-header-column.interfaces';
import { ITableCell } from '~/components/tables/interfaces/table-cell-interfaces';
import { ETableType } from '~/components/tables/enums/table-type.enum';
import { setQuaryPerPage } from '~/components/tables/helpers/setQuaryPerPage';

export default Vue.extend({
    components: {
        rootTable
    },
    data () {
        return {
            tableType: ETableType.notificationsActivity,
        };
    },
    computed: {
        notificationActivityHeader (): ITableHeaderColumn[] {
            return NotificationsActivityLogModule.notificationsHeaders;
        },
        notificationActivityRows (): ITableCell[] {
            return NotificationsActivityLogModule.notificationsRows;
        },
    },
    created (): void {
        this.loadActivityLogTable();
    },
    destroyed () {
        NotificationsActivityLogModule.reset();
    },
    methods: {
        async loadActivityLogTable (): Promise<void> {
            setQuaryPerPage(this.tableType);
            await NotificationsActivityLogModule.load(this.$route.query);
        }
    }
});
