<template>
    <div class="edit-blocker">
        <a
            href="#"
            class="edit-blocker__link"
            @click.prevent="$emit('onOk', $event)"
        >
            <material-icon
                symbol="done"
                size="xs-plus"
                color="green"
                title="Confirm"
                delay
            />
        </a>
        <a
            href="#"
            class="edit-blocker__link"
            @click.prevent="$emit('onCancel')"
        >
            <material-icon
                symbol="close"
                size="xs-plus"
                color="red"
                title="Cancel"
                delay
            />
        </a>
    </div>
</template>
<script>
export default {

};
</script>
<style lang="scss">
    .edit-blocker {
        width: 50px;
        height: 30px;
        align-items: center;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-around;
        line-height: 1;

        &__link {
            width: 15px;
            height: 15px;

            img {
                width: 15px;
                height: 15px;
            }
        }
    }
</style>
