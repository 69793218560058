import Vue from 'vue';
import { EConfigType } from '~/store/modules/configs/enums/config-type.enum';
import rowItemPropsMixin from '@/js/components/tables/mixins/table-cells-mixins/row-item-props-mixin';

export default Vue.extend({
    mixins: [
        rowItemPropsMixin,
    ],
    computed: {
        isValue (): string {
            return typeof this.cell.value === EConfigType.string ||
            typeof this.cell.value === EConfigType.number ?
                this.cell.value :
                'N/A';
        }
    }
});
