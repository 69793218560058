// Imports
import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';

import store from '~/store';
import { BonusPlanModule } from '~/store/modules/bonus-plan/bonus-plan';
import { IBonusPlan } from '~/store/modules/bonus-plan/interfaces/bonus-plan-interfaces';
import { EBonusPlanType } from '~/settings/enums/bonus-plan-type.enum';
@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: 'affiliatesBonusPlan',
})

class AffiliatesBonusPlans extends VuexModule {
    activeBonusPlan: number = 0;
    activeCategory: number = EBonusPlanType.cpa;

    get getActiveCategory (): number {
        return this.activeCategory;
    }

    get getActiveBonusPlan (): number {
        return this.activeBonusPlan;
    }

    get getActiveBonusPlanObj (): IBonusPlan {
        return this.getBonusPlanItems.find(item => item.id === this.activeBonusPlan);
    }

    get getBonusPlanItems (): IBonusPlan[] {
        return BonusPlanModule.getSortedBonusPlanList
            .filter(bonusPlan => (
                bonusPlan.bonus_type.id === this.getActiveCategory
            ));
    }

    @Mutation
    handleActiveCategories (id: number) {
        this.activeCategory = id;
    }

    @Mutation
    handleActiveBonusPlan (id: number) {
        this.activeBonusPlan = id;
    }

    @Action({ commit: 'handleActiveCategories' })
    async setActiveCategories (id: number) {
        return id;
    }

    @Action({ commit: 'handleActiveBonusPlan' })
    async setActiveBonusPlan (id: number) {
        return id;
    }

    @Action({ commit: 'handleActiveBonusPlan' })
    async setActiveFirstBonusPlan () {
        return this.getBonusPlanItems[0].id;
    }

    @Action
    async deleteBonusPlan (id: number): Promise<void> {
        const activeBonusPlan = this.getBonusPlanItems
            .filter(bonusPlan => bonusPlan.id !== id)[0].id || 0;
        this.handleActiveBonusPlan(activeBonusPlan);
        await BonusPlanModule.deleteBonusPlan(id);
        await BonusPlanModule.loadBonusPlan()
            .then(() => {
                this.setActiveFirstBonusPlan();
            });
    }
}

export const AffiliatesBonusPlansModule = getModule(AffiliatesBonusPlans);
