// Imports
import Vue from 'vue';
import modalLayout from '~/components/modals/root-modal-layout/root-modal-layout.vue';
import modalsMixin from '~/components/mixins/modals';

import { AccountingManagersModules } from '~/store/modules/accounting/accounting-managers';

// Exports
export default Vue.extend({
    components: {
        modalLayout,
    },
    mixins: [modalsMixin],
    data () {
        return {
            statusOptions: [1, 2, 3],
            status: null,
            bonusPlanOptions: [4, 5, 6],
            bonusPlan: null,
            fullName: null,
        };
    },
    methods: {
        handleAddManager () {
            this.$emit('closeModal');
            AccountingManagersModules.actionAddManager({
                fullName: this.fullName,
                status: this.status,
                bonusPlan: this.bonusPlan
            });
        }
    }
});
