import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '~/store';
import { DocumentsTypesInterface } from './interfaces';
import { UploadedDocumentsTypeIdsEnum } from '~/components/uploaded-documents/uploaded-documents.enum';

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: 'DocumentsTypes',
})

class DocumentsTypes extends VuexModule {
    entities: Array<DocumentsTypesInterface> = [];
    entitiesAll: DocumentsTypesInterface[] = [];
    selectedDocumentType: number = 4;
    selectedStatusType: number = 0;

    get getterDocumentRequests (): Array<any> {
        return Object.values(this.entities).filter(item => item.id > UploadedDocumentsTypeIdsEnum.PaymentVerificationDocuments);
    }

    get getterDocumentsTypeSelected (): number {
        return this.selectedDocumentType;
    }

    get getterDocumentsStatusSelected (): number {
        return this.selectedStatusType;
    }

    @Mutation
    mutationChangeDocumentStatus (payload: number) {
        this.selectedStatusType = payload;
    }

    @Mutation
    mutationChangeDocumentsType (payload: number) {
        this.selectedDocumentType = payload;
    }

    @Action({ commit: 'mutationChangeDocumentStatus' })
    actionChangeDocumentsStatus (statusId) {
        return statusId;
    }

    @Action({ commit: 'mutationChangeDocumentsType' })
    actionChangeDocumentsType (typeId: number): number {
        return typeId;
    }
}
export const DocumentsTypesModule = getModule(DocumentsTypes);
