import Vue from 'vue';
import modalLayout from '~/components/modals/root-modal-layout/root-modal-layout.vue';
import modalsMixin from '~/components/mixins/modals';
import vue2Dropzone from 'vue2-dropzone';
import Rest from '~/helpers/Rest';
import { urlLegalDocuments } from '~/settings/ajax-urls';
import { LegalDocumentsModule } from '~/store/modules/documents/legal-documents';

export default Vue.extend({
    components: {
        modalLayout,
        'vueDropzone': vue2Dropzone,
    },
    mixins: [modalsMixin],
    data () {
        return {
            version: '',
            file: null,
            dropzoneOptions: {
                acceptedFiles: 'image/jpeg,image/png,image/gif,image/jpg,application/pdf',
                autoProcessQueue: false,
                url: 'https://',
                thumbnailWidth: 150,
                maxFilesize: 15,
                maxFiles: 1,
            }
        };
    },

    methods: {
        addFile (file) {
            this.file = file;
        },

        uploadDocument () {
            const formData = new FormData();
            const uploadedFile = this.file;
            formData.append('type_id', this.modalData.type_id);
            formData.append('version', this.version);
            formData.append('file', uploadedFile);
            this.upload(formData);
        },

        upload (data) {
            Rest.FILE(urlLegalDocuments, data)
                .then(() => {
                    this.closeModal();
                    LegalDocumentsModule.actionLoadLegalDocuments();
                })
                .catch(Rest.simpleCatch);
        },
        closeModal () {
            this.$emit('closeModal');
        },
    }
});
