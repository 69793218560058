import Vue from 'vue';
export default Vue.extend({
    props: {
        template: {
            type: Object,
            default: null,
            required: true
        },
        index: {
            type: Number,
            default: null,
            required: true
        }
    }
});
