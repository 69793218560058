import Vue from 'vue';
import { ENotificationsButtonActions } from '~/components/parts/notification-message/enums/notiffications-button-actions.enum';
import { UsersModule } from '~/store/modules/users/users';
import { PaymentRequisitesModule } from '~/store/modules/payment-requisites/payment-requisites';
import { affiliatesCampaignsModule } from '~/store/modules/affiliates/affiliates-campaigns';
import { EBooleanOptions } from '~/settings/enums/boolean-options.enum';
import { ModalNotificationModule } from '~/store/modules/modal-notification/modal-notification';
import {
    EModalNotificationIcons,
    EModalNotificationStylesCloseButton,
    ModalNotificationTypesEnum
} from '@/js/helpers/modal-notification-services/enums/modal-notification.enum';

export default Vue.extend({
    methods: {
        hasActionsButtons (length: number): boolean {
            return !!length;
        },
        styleCloseButton (type: string): string {
            switch (type) {
            case ModalNotificationTypesEnum.Success:
                return EModalNotificationStylesCloseButton.creamGreen;

            case ModalNotificationTypesEnum.Error:
                return EModalNotificationStylesCloseButton.creamRed;

            case ModalNotificationTypesEnum.Pending:
                return EModalNotificationStylesCloseButton.creamOrange;

            default:
                return '';
            }
        },
        symbolStatus (type: string): string {
            switch (type) {
            case ModalNotificationTypesEnum.Success:
                return EModalNotificationIcons.check;

            case ModalNotificationTypesEnum.Error:
                return EModalNotificationIcons.error;

            case ModalNotificationTypesEnum.Pending:
                return EModalNotificationIcons.pending;

            default:
                return '';
            }
        },
        isApprove (name: string): boolean {
            return name === 'Yes';
        },
        // TO REMOVE after moved to action-modal.ts
        async buttonClick (action: string, props: any, param: any) {
            switch (action) {
            case ENotificationsButtonActions.deleteUser:
                await UsersModule.deleteUser({
                    id: param.id,
                    assignUserId:  param.assignUserId,
                });
                await UsersModule.loadUsers(this.$route.query);
                props.close();
                break;
            case ENotificationsButtonActions.deleteAffiliatesCampaigns:
                await  affiliatesCampaignsModule.editCampaignBulk({
                    campaign_ids: param.id,
                    is_disabled: EBooleanOptions.yes,
                });
                await affiliatesCampaignsModule.fetchCampaignsList(this.$route.query);
                props.close();
                break;
            case ENotificationsButtonActions.deletePaymentRequisites:
                await PaymentRequisitesModule.removePaymentRequisite(param);
                await PaymentRequisitesModule.loadPaymentRequisites();
                props.close();
                break;
            default:
                props.close();
                break;
            }
            ModalNotificationModule.setActiveNotificationState(false);
        },
        closeNotification (props: any,) {
            props.close();
            ModalNotificationModule.setActiveNotificationState(false);
        }
    }
});
