import Vue from 'vue';
import modalsMixin from '~/components/mixins/modals';
import modalLayout from '~/components/modals/root-modal-layout/root-modal-layout.vue';
import AppInput from '~/components/parts/app-input/app-input.vue';
import AppMultiSelect from '~/components/parts/app-multiselect/app-multiselect.vue';
import fieldModelMixin from '~/components/mixins/modelMixins/fieldModelMixin';
import appPhone from '~/components/parts/app-phone/app-phone.vue';
import configsMixin from '~/components/mixins/configs-mixin';
import DatePicker from 'vue2-datepicker';
import { ELegalSubject } from '~/settings/enums/legal-subject.enum';
import { BonusPlanModule } from '~/store/modules/bonus-plan/bonus-plan';
import { IBonusPlan } from '~/store/modules/bonus-plan/interfaces/bonus-plan-interfaces';
import { IAffiliateCreate } from '~/store/modules/affiliates/interfaces/affiliates-interfaces';
import { AffiliatesPageModule } from '~/store/modules/affiliates/affiliates-page';
import { parseDate, createDateObj } from '~/helpers/common';
import { mapActions } from 'vuex';
import { names as affiliatesConfig } from '~/store/modules/configs/affiliates';
import { IDefaultConfig } from '~/store/modules/configs/interfaces/configs-interface';

export default Vue.extend({
    components: {
        modalLayout,
        AppInput,
        AppMultiSelect,
        appPhone,
        DatePicker,
    },
    mixins: [
        modalsMixin,
        fieldModelMixin,
        configsMixin,
    ],
    data () {
        return {
            type: null,
            full_name: '',
            dob: null,
            email: '',
            phone_mobile_country: null,
            phone_mobile: '',
            country: null,
            language: null,
            bonus_plan: null,
            bonus_name: null,
            status: null,
            website: '',
            representative_name: '',
            isDateError: false,
        };
    },
    computed: {
        isBusinessType (): boolean {
            return this.type?.id === ELegalSubject.business;
        },
        getBonusPlanList (): IBonusPlan[] {
            return BonusPlanModule.getBonusPlanList;
        },
        isAffiliateNameLabel (): string {
            return this.isBusinessType ? 'Company Name' : 'Affiliate Name';
        },
        isRepresentativeNameValidation (): string {
            return this.isBusinessType ? 'required' : '';
        },
    },
    async created () {
        if (this.modalData.id) {
            await BonusPlanModule.loadBonusPlan();
            this.getCurrentAffiliate();
        }
    },
    methods: {
        // TODO
        ...mapActions({
            handleFetchAffiliates : affiliatesConfig.actions.handleFetch,
        }),
        getCurrentAffiliate (): void {
            const affiliate = AffiliatesPageModule.getAffiliates
                .find(item => item.id === this.modalData.id);
            const bonusType = BonusPlanModule.getBonusPlanList
                .find(bonusPlan => bonusPlan.id === affiliate.bonus.id);
            this.full_name = affiliate.full_name;
            this.email = affiliate.email;
            this.country = affiliate.country;
            this.language = affiliate.language;
            this.website = affiliate.website;
            this.phone_mobile_country = affiliate.phone_mobile_country;
            this.phone_mobile = affiliate.phone_mobile;
            this.bonus_name = affiliate.bonus;
            this.bonus_plan = bonusType.bonus_type;
            this.status = affiliate.status;
            this.type = affiliate.type;
            this.dob = createDateObj(affiliate.dob, 'DD/MM/YYYY').toDate();
            this.representative_name = this.isBusinessType ? affiliate.representative_name : affiliate.full_name;
        },
        async handlerSubmitForm (): Promise<void> {
            const isValid = await this.$refs.observer.validate();
            this.isDateError = !this.dob ? true : false;
            if (isValid && !this.isDateError) {
                const payload = {
                    full_name: this.full_name,
                    email: this.email,
                    country_id: this.country.id,
                    language_a2: this.language.A2,
                    website: this.website,
                    phone_mobile_country_id: this.phone_mobile_country.id,
                    phone_mobile: Number(this.phone_mobile),
                    bonus_id: this.bonus_name.id,
                    status_id: this.status.id,
                    type_id: this.type.id,
                    representative_name: this.representative_name,
                    dob: parseDate(this.dob, 'YYYY-MM-DD').format('YYYY-MM-DD'),
                };
                this.onSubmitForm(payload);

            }
        },
        changePhoneMobile (value: string) {
            this.phone_mobile = value;
        },
        async onSubmitForm (payload: IAffiliateCreate): Promise<void> {
            await AffiliatesPageModule.updateAffiliateDetails({
                id: this.modalData.id,
                payload,
            })
                .then(response => {
                    if (response) {
                        this.closeModal();
                        AffiliatesPageModule.loadAffiliates(this.$route.query);
                        // TODO
                        this.handleFetchAffiliates();
                    }
                });
        },
        datePickerRemoveError (date: string) {
            if (date) {
                this.isDateError = false;
            }
        },
        selectBonusType (event: IDefaultConfig): void {
            this.bonus_plan = event;
        },
        selectBonusPlan (event: IBonusPlan): void {
            this.bonus_plan = event.bonus_type;
        },
        setRepresentativeName (payload: string): void {
            if (!this.isBusinessType) {
                this.representative_name = payload;
            }
        },
    }
});
