import { getStdDefaultState, getStdNames, getStdGetters, getStdMutations, getStdActions } from '../_stdModuleUtils';
import { urlUsersConfig } from '~/settings/ajax-urls';
import { formatResponse } from '~/store/modules/configs/helper/format-response';

export const moduleName = 'usersList';
export const names = getStdNames(moduleName);

export default {
    state: {
        ...getStdDefaultState(),
    },
    getters: {
        ...getStdGetters({ names }),
    },
    mutations: {
        ...getStdMutations({ names }),
    },
    actions: {
        ...getStdActions({
            names,
            ajaxUrl: urlUsersConfig({}),
            formatResponse
        }),
    },
};

