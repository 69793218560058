import Vue from 'vue';
import modalLayout from '~/components/modals/root-modal-layout/root-modal-layout.vue';
import modalsMixin from '~/components/mixins/modals';
import vue2Dropzone from 'vue2-dropzone';

export default Vue.extend({
    components: {
        modalLayout,
        'vueDropzone': vue2Dropzone,
    },
    mixins: [modalsMixin],
    data () {
        return {
            version: '',
            file: null,
            dropzoneOptions: {
                autoProcessQueue: false,
                url: 'https://',
                thumbnailWidth: 150,
                maxFiles: 1,
                previewTemplate: `
                <div class="dz-preview dz-file-preview">
                    <div class="dz-image">
                        <div data-dz-thumbnail-bg></div>
                    </div>
                    <div class="dz-details">
                        <div class="dz-size"><span data-dz-size></span></div>
                        <div class="dz-filename"><span data-dz-name></span></div>
                    </div>
                    <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
                    <div class="dz-error-message"><span data-dz-errormessage></span></div>
                    <div class="dz-success-mark"><i class="fa fa-check"></i></div>
                    <div class="dz-error-mark"><i class="fa fa-close"></i></div>
                     <a class="dz-remove" data-dz-remove><i class="material-symbols-rounded">close</i></a>
                </div>`,
            }
        };
    },

    methods: {
        addFile (file: File) {
            this.file = file;
        },

        removeFile (): void {
            this.file = null;
        },

        uploadDocument () {
            const formData = new FormData();
            const uploadedFile = this.file;
            formData.append('file', uploadedFile);
            this.modalProps.confirm(formData);
            this.closeModal();
        },
    }
});
