// Import
import Vue from 'vue';
import { IDefaultConfigValue } from '~/store/modules/configs/interfaces/configs-interface';
import appDropdownBtn from '~/components/parts/app-dropdown-btn/app-dropdown-btn.vue';
import campaignTokenCopyMixin from '~/components/mixins/campaign-token-copy-mixin';
import rowItemPropsMixin from '@/js/components/tables/mixins/table-cells-mixins/row-item-props-mixin';

export default Vue.extend({
    components: {
        appDropdownBtn,
    },
    mixins: [
        campaignTokenCopyMixin,
        rowItemPropsMixin,
    ],
    computed: {
        isTokenCopyOptions (): IDefaultConfigValue[] {
            return this.geTokenCopyOptions(this.cell.value);
        },
    },
});
