export enum EPermissions {
    viewUsers = 1,
    editUsers = 2,
    addUsers = 3,
    deleteUsers = 4,
    editUserInformation = 5,
    editPassword = 6,
    editAccessLevel = 7,
    editManager = 8,
    editUserAccess = 9,
    editComplianceDocs = 10,
    viewClient = 12,
    editClients = 13,
    notes = 16,
    createNotes = 17,
    multipleEditClients = 18,
    editClientsInformation = 20,
    editUsersClients = 21,
    editClientEmailPhone = 22,
    editClientsGroup = 23,
    exportToExcel = 24,
    importFromExcel = 25,
    enableEmails = 27,
    viewAll = 28,
    campaigns = 29,
    complianceReadOnly = 30,
    compliancePayments = 31,
    withdrawOrders = 32,
    liveChat = 35,
    accounting = 36,
    reportByTeam = 37,
    commissionDatabase = 38,
    bonusPlan = 39,
    teamAndBrokers = 40,
    reports = 41,
    affiliate = 42,
    emails = 43,
    calendar = 44,
    notificationsKyc  = 45,
    notificationsFunding  = 46,
    notificationsRegister  = 48,
    finance = 49,
    approvedDepositsWithdrawal = 50,
    editLeverage = 53,
    delete = 54,
    trading = 55,
    activityLog = 56,
    settings = 57,
    settingsGroup = 58,
    settingsDocuments = 59,
    settingsSymbols = 60,
}

export enum SubPermission {
    editPassword = 6,
    editAccessLevel = 7,
    editManager = 8,
    editUsersClients = 21,
    editClientEmailPhone = 22,
    editClientsGroup = 23,
    reportByTeam = 37,
    commissionDatabase = 38,
    bonusPlan = 39,
    teamAndBrokers = 40,
    settingsGroup = 58,
    settingsDocuments = 59,
    settingsSymbols = 60,
}

export enum EPermissionsGroupName {
    users = 'users',
    clients = 'clients',
    menu = 'menu',
}

// TODO
export enum EPermissionsOrder {
    viewAll = 1,
    campaigns = 2,
    complianceReadOnly = 3,
    editComplianceDocs = 4,
    compliancePayments = 5,
    delete = 6,
    trading = 7,
    default,
}
