import Vue from 'vue';
import { EIconsSettingsAffiliateStatus, EIconsSettingsColor } from '~/settings/enums/status-icons-settings.enum';
import rowItemPropsMixin from '~/components/tables/mixins/table-cells-mixins/row-item-props-mixin';
import { EAffiliatesStatuses } from '~/settings/enums/affiliates-statuses.enum';

export default Vue.extend({
    mixins: [
        rowItemPropsMixin,
    ],
    computed: {
        statusId (): number {
            return this.cell.value?.id || this.cell.statusId;
        },
        statusName (): string {
            return EAffiliatesStatuses[this.statusId];
        },
        isIcon (): string {
            return this.statusId ? EIconsSettingsAffiliateStatus[this.statusId] : '';
        },
        isIconColor (): string {
            return this.isIcon ? EIconsSettingsColor[this.isIcon] : '';
        }
    }
});
