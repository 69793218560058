import Vue from 'vue';
// @ts-ignore
import modalLayout from '~/components/modals/root-modal-layout/root-modal-layout';

import modalsMixin from '~/components/mixins/modals';
// @ts-ignore
import sendMailEditor from '~/components/parts/sendMailEditor';

export default Vue.extend({
    components: {
        modalLayout,
        sendMailEditor
    },
    mixins: [modalsMixin],
});
