import { render, staticRenderFns } from "./affiliates-bonus-plan.html?vue&type=template&id=162cfbeb&lang=html&"
import script from "./affiliates-bonus-plan.ts?vue&type=script&lang=ts&"
export * from "./affiliates-bonus-plan.ts?vue&type=script&lang=ts&"
import style0 from "./affiliates-bonus-plan.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports