import Vue from 'vue';
import sendMailEditor from '~/components/parts/sendMailEditor.vue';
import appInput from '~/components/parts/app-input/app-input.vue';
import appMultiSelect from '~/components/parts/app-multiselect/app-multiselect.vue';
import { IMarketingTemplatesDataForSendEmail } from '~/store/modules/marketing/marketing-interfaces';

export default Vue.extend({
    components: {
        sendMailEditor,
        appInput,
        appMultiSelect
    },
    props: {
        clientId: {
            type: Number
        },
        emailType: {
            type: String,
        },
        isDragging: {
            type: Boolean,
        },
        isFilteredUserList: {
            type: Boolean
        }
    },
    methods: {
        sendEmail (
            keysFromMarketingTemplatesForSendEmail: IMarketingTemplatesDataForSendEmail
        ): void {
            this.$emit('sendEmail', keysFromMarketingTemplatesForSendEmail);
        },
    },
});
