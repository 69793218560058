import Vue from 'vue';
import subNavbar from '~/components/subNavbar/subNavbar.vue';
import subNavbarLink from '~/components/subNavbar/subNavbarLink.vue';
import subNavbarSeparator from '~/components/subNavbar/subNavbarSeparator.vue';
import NotificationTable from './notifications-table/notifications-table.vue';
import NotificationsActivityLog from './notifications-activity-log/notifications-activity-log.vue';
import Search from '~/components/parts/search/search.vue';
import { NotificationsMessagesModule } from '~/store/modules/notifications/notifications';
import { NotificationsAffiliatesModule } from '~/store/modules/notifications/notifications-affiliates';
import {
    NotificationsEnum,
    NotificationsKeysEnum,
    NumbersNotificationEnum,
} from './enums/notifications.enum';
import { INotificationsQuery, NotificationsInterfaces } from '~/components/notifications/interfaces/notifications-interfaces';
import router from '~/router';
import {
    isPermissionNotificationsRegister,
    isPermissionNotificationsKyc,
    isPermissionNotificationsFunding
} from '~/helpers/user-permission';
import { ERouterNameFromPermission } from '~/settings/enums/router-name.enum';
import { NotificationsActivityLogModule } from '~/store/modules/notifications/notifications-activity-log';
import { ETableType } from '~/components/tables/enums/table-type.enum';

import { setQuaryPerPage } from '~/components/tables/helpers/setQuaryPerPage';
import { handlerRoutesQuery } from '~/helpers/handler-routes-query';
import { ETableFilterKeyPagination } from '~/components/tables/enums/table-filter-key.enum';

export default Vue.extend({
    components: {
        'search': Search,
        'sub-navbar': subNavbar,
        'sub-navbar-link': subNavbarLink,
        'sub-navbar-separator': subNavbarSeparator,
        'notification-table': NotificationTable,
        'notification-activity-log': NotificationsActivityLog,
    },
    data (): Object {
        return {
            activeTab: null,
            notificationEnum: NotificationsEnum,
            activeButtonClass: 'active',
        };
    },
    computed: {
        isPermissionNotificationsRegister,
        isPermissionNotificationsKyc,
        isPermissionNotificationsFunding,
        isNotifications (): Number {
            return NotificationsMessagesModule.getterNotificationsUnReadCount;
        },
        notificationsCounters (): Number {
            return NotificationsMessagesModule.getAllNotificationsCount;
        },
        notificationsKycCounter (): Number {
            return NotificationsMessagesModule.getKYCNotificationsCount;
        },
        notificationsFundingCounter (): Number {
            return NotificationsMessagesModule.getFundingNotificationsCount;
        },
        notificationsRegistrationCounter (): Number {
            return NotificationsMessagesModule.getRegistrationNotificationsCount;
        },
        notificationsAffiliatesCounter (): number {
            return NotificationsMessagesModule.getAffiliatesNotificationsCount;
        },
        isNotificationsRegistrationCounter (): boolean {
            return this.notificationsRegistrationCounter > NumbersNotificationEnum.Zero;
        },
        isNotificationsFundingCounter (): boolean {
            return this.notificationsFundingCounter > NumbersNotificationEnum.Zero;
        },
        isNotificationsKycCounter (): boolean {
            return this.notificationsKycCounter > NumbersNotificationEnum.Zero;
        },
        isNotificationsCounters (): boolean {
            return this.notificationsCounters > NumbersNotificationEnum.Zero;
        },
        isNotificationsAffiliatesCounter (): boolean {
            return this.notificationsAffiliatesCounter > NumbersNotificationEnum.Zero;
        },
        searchValue (): string {
            const query = this.$route.query;
            return query.search;
        },
        isActivityLog (): boolean {
            return this.activeTab === NotificationsEnum.ACTIVITY_LOG;
        },
        isAffiliates (): boolean {
            return this.activeTab === NotificationsEnum.TYPE_AFFILIATES;
        },
        tableType (): string {
            if (this.$route.name === ERouterNameFromPermission.notificationsActivityLog) {
                return ETableType.notificationsActivity;
            }

            return this.activeTab === NotificationsEnum.TYPE_AFFILIATES ?
                ETableType.notificationsAffiliates :
                ETableType.notificationsClients;
        }
    },
    created (): void {
        this.loadNotifications();
    },
    mounted () {
        document.querySelector('#theHeaderNotifications')
            .addEventListener('click', this.checkForHandleTabs);
    },
    destroyed () {
        document.querySelector('#theHeaderNotifications')
            .removeEventListener('click', this.checkForHandleTabs);
    },
    methods: {
        checkForHandleTabs () {
            if (!this.$route.query[NotificationsKeysEnum.TypeId]) {
                this.handleTabs(NotificationsEnum.TYPE_ALL);
            }
        },
        setActiveTab () {
            if (this.$route.name === ERouterNameFromPermission.notificationsActivityLog) {
                this.activeTab = NotificationsEnum.ACTIVITY_LOG;
            } else {
                this.activeTab = this.$route.query.type_id || NotificationsEnum.TYPE_ALL;
            }
        },
        loadNotifications (): void {
            this.setActiveTab();
            setQuaryPerPage(this.tableType);
            const query = this.$route.query;
            NotificationsMessagesModule.handleNotificationQuery(query);
            this.loadTable(query);
        },
        loadTable (query: INotificationsQuery): void {
            if (this.$route.name !== ERouterNameFromPermission.notificationsActivityLog) {
                if (query.type_id === NotificationsEnum.TYPE_AFFILIATES) {
                    NotificationsAffiliatesModule.load(query);
                } else {
                    NotificationsMessagesModule.setNotificationsList(query);
                }
            }
        },
        isActive (type: string): boolean {
            return this.activeTab === type;
        },
        handleNotificationsQuery (
            key: keyof NotificationsInterfaces,
            value: string,
            removePageQuery: boolean = false,
        ) {
            const isQuery = removePageQuery ?
                { ...this.deleteQueryPage(router.currentRoute.query) } :
                { ...router.currentRoute.query };

            NotificationsMessagesModule.handleNotificationsQuerySimple({ key, value });

            if (value) {
                isQuery[key] = value;
            } else {
                delete isQuery[key];
            }

            return  router.push({
                query: { ...isQuery },
            })
                .catch (() => console.log());
        },
        deleteQueryPage (query) {
            const clearQuery = { ...query };

            if (query.page) {
                delete clearQuery.page;
            }

            return clearQuery;
        },
        async handleTabs (typeId: string) {
            await this.handleNotificationsQuery(NotificationsKeysEnum.TypeId, typeId, true);

            if (this.$route.name !== ERouterNameFromPermission.notificationsClients) {
                handlerRoutesQuery(ETableFilterKeyPagination.perPage, null);
                setQuaryPerPage(ERouterNameFromPermission.notificationsClients);

                this.$router.push({
                    name: ERouterNameFromPermission.notificationsClients,
                    query: { ...this.$route.query },
                });
            }

            if (this.activeTab !== typeId) {
                this.loadTable(this.$route.query);
                this.activeTab = typeId;
            }
        },
        handleActiveLog (tab: string): void {
            if (this.$route.name !== ERouterNameFromPermission.notificationsActivityLog) {
                this.$router.push({
                    name: ERouterNameFromPermission.notificationsActivityLog,
                    query: this.$route.query[NotificationsKeysEnum.Search] ?
                        { [NotificationsKeysEnum.Search]: this.$route.query[NotificationsKeysEnum.Search] } :
                        {},
                });

                this.activeTab = tab;
            }
        },
        handleSearch (event: string): void {
            this.handleNotificationsQuery(NotificationsKeysEnum.Search, event);
        },
        clickButtonSearch (): void {
            const query = this.$route.query;

            switch (this.activeTab) {
            case NotificationsEnum.ACTIVITY_LOG:
                NotificationsActivityLogModule.load(query);
                break;

            case NotificationsEnum.TYPE_AFFILIATES:
                NotificationsAffiliatesModule.load(query);
                break;
            
            default:
                NotificationsMessagesModule.setNotificationsList(query);
                break;
            }
        }
    }
});
