import { ITableHeaderColumn } from '~/components/tables/interfaces/table-header-column.interfaces';
import { tableHeaderParser } from '~/components/tables/helpers/table-header-parser';

export const affiliatesMarketingTableHeader: ITableHeaderColumn[] = tableHeaderParser([
    {
        id: 3,
        name: 'campaignName',
        value: 'Campaign Name',
        cellType: 'plain-text',
        cellData: 'campaignName'
    },
    {
        id: 4,
        name: 'affiliateName',
        value: 'Affiliate Name',
        cellType: 'plain-text',
        cellData: 'affiliateName'
    },
    {
        id: 5,
        name: 'bonusPlan',
        value: 'Bonus Plan',
        cellType: 'plain-text',
        cellData: 'bonusPlan'
    },
    {
        id: 6,
        name: 'campaignType',
        value: 'Campaign Type',
        cellType: 'plain-text',
        cellData: 'campaignType'
    },
    {
        id: 7,
        name: 'description',
        value: 'Description',
        cellType: 'plain-text',
        cellData: 'description'
    },
    {
        id: 8,
        name: 'size',
        value: 'Size',
        cellType: 'plain-text',
        cellData: 'size'
    },
    {
        id: 9,
        name: 'screenshot',
        value: 'Screenshot',
        cellType: 'item-link',
        cellData: 'screenshot'
    },
    {
        id: 10,
        name: 'token',
        value: 'Token',
        cellType: 'plain-text',
        cellData: 'token'
    },
    {
        id: 11,
        name: 'userName',
        value: 'User Name',
        cellType: 'plain-text',
        cellData: 'userName'
    },
    {
        id: 12,
        name: 'impressions',
        value: 'Impressions',
        cellType: 'plain-text',
        cellData: 'impressions',
        textAlign: 'right',
    },
    {
        id: 13,
        name: 'clicks',
        value: 'Clicks',
        cellType: 'plain-text',
        cellData: 'clicks',
        textAlign: 'right',
    },
    {
        id: 14,
        name: 'bannersCtr',
        value: 'Banners CTR',
        cellType: 'plain-text',
        cellData: 'bannersCtr',
        textAlign: 'right',
    },
    {
        id: 15,
        name: 'landingPage',
        value: 'Landing Page',
        cellType: 'plain-text',
        cellData: 'landingPage'
    },
    {
        id: 16,
        name: 'lpImpressions',
        value: 'LP Impressions',
        cellType: 'plain-text',
        cellData: 'lpImpressions',
        textAlign: 'right',
    },
    {
        id: 17,
        name: 'leads',
        value: 'Leads',
        cellType: 'plain-text',
        cellData: 'leads',
        textAlign: 'right',
    },
    {
        id: 18,
        name: 'bannersCtl',
        value: 'Banners CTL',
        cellType: 'plain-text',
        cellData: 'bannersCtl',
        textAlign: 'right',
    },
    {
        id: 19,
        name: 'conversions',
        value: 'Conversions',
        cellType: 'plain-text',
        cellData: 'conversions',
        textAlign: 'right',
    },
    {
        id: 20,
        name: 'conversionsRate',
        value: 'Conversion Rate',
        cellType: 'plain-text',
        cellData: 'conversionsRate',
        textAlign: 'right',
    },
    {
        id: 21,
        name: 'avgFtd',
        value: 'Avg. FTD',
        cellType: 'plain-text',
        cellData: 'avgFtd',
        textAlign: 'right',
    },
    {
        id: 22,
        name: 'totalDeposits',
        value: 'Total Deposits',
        cellType: 'plain-text',
        cellData: 'totalDeposits',
        textAlign: 'right',
    }
]);
