import {
    IDashboardPayments,
    IDashboardClients,
    IDashboardWidgetsItems,
    IDashboardSummaryStatisticFormattedObject,
    IDashboardSummaryPlatformFormatted,
    IDashboardSummaryPlatform,
} from '~/store/modules/dashboard/interfaces';
import {
    EDashboardSummaryStatisticItemName,
    EDashboardPaymentsItemsKey,
    EDashboardPaymentsItemsKeyNoDecimal,
    EDashboardSummaryStatisticItemRedirect,
} from '../enums/dashboard-payments-items.enum';
import { formatPrice } from '~/settings/format-price';

const isAddDecimals = (keys: object, item: string): boolean => {
    // eslint-disable-next-line no-prototype-builtins
    return !keys.hasOwnProperty(item);
};

export const dashboardPaymentsFormatted = (data: IDashboardPayments): IDashboardSummaryStatisticFormattedObject => {
    const formatted = {};
    for (const item in data) {
        formatted[item] = {
            name: EDashboardSummaryStatisticItemName[item],
            percentage: data[item].percentage,
            value: formatPrice(
                data[item].value,
                isAddDecimals(EDashboardPaymentsItemsKeyNoDecimal, item)
            ),
            sign: setSign(item),
            redirect: EDashboardSummaryStatisticItemRedirect[item],
        };
    }

    return formatted;
};

export const dashboardClientsFormatted = (data: IDashboardClients): {
    summaryBlocks: IDashboardSummaryStatisticFormattedObject,
    widgets: IDashboardWidgetsItems,
} => {
    const summaryBlocks = {};
    const widgets = {};
    for (const item in data) {
        if (!Array.isArray(data[item])) {
            summaryBlocks[item] = {
                name: EDashboardSummaryStatisticItemName[item],
                percentage: data[item].percentage,
                value: formatPrice(data[item].value, false),
                redirect: EDashboardSummaryStatisticItemRedirect[item],
            };
        } else {
            widgets[item] = data[item];
        }
    }
    return {
        summaryBlocks: summaryBlocks,
        widgets: widgets,
    };
};

export const dashboardPlatformFormatted = (data: IDashboardSummaryPlatform): IDashboardSummaryPlatformFormatted => {
    const formatted = {};

    for (const item in data) {
        formatted[item] = formatPrice(String(data[item]), false);
    }

    return formatted;
};

const setSign = (item: string): string => {
    switch (item) {
    case EDashboardPaymentsItemsKey.deposit_amount:
    case EDashboardPaymentsItemsKey.ftd_amount:
    case EDashboardPaymentsItemsKey.net_deposit_amount:
    case EDashboardPaymentsItemsKey.withdraw_amount:
        return '€';
    }
};
