import {
    TradingPaginationInterface,
    DEFAULT_PER_PAGE,
    DataForOrdersInterface,
    AjaxUrlForOrdersInterface,
    TradingSumInfoInterface
} from './kyc-trading-interfaces';
import { TradingTabsNameEnums, KycTradingEnums } from './kyc-trading.enums';

import {
    urlOrdersHistory,
    urlOpenOrders,
    urlPendingOrders,
    urlSumValues
} from '~/settings/ajax-urls';
import Rest from '~/helpers/Rest';
import { ETableFilterKeyPagination } from '~/components/tables/enums/table-filter-key.enum';

export const getAjaxUrlForOrders = ({ query = null, leadId, type }: AjaxUrlForOrdersInterface): Promise<DataForOrdersInterface> => {
    let url;
    let historyQuery;
    let anotherQuery;

    if (query) {
        for (const key in query) {
            if (key === 'accounts' && typeof query[key] === 'string') {
                query[key] = query[key].split(',');
            }
        }
    }

    if (type !== TradingTabsNameEnums.OrderHistory) {
        anotherQuery = {
            [KycTradingEnums.Accounts]: query[KycTradingEnums.Accounts],
            [ETableFilterKeyPagination.perPage]: query[ETableFilterKeyPagination.perPage],
        };
    } else {
        historyQuery = query;
        delete historyQuery.type;
    }

    switch (type) {
    case TradingTabsNameEnums.OrderHistory: {
        url = urlOrdersHistory(historyQuery, +leadId);
        break;
    }
    case TradingTabsNameEnums.OpenOrders: {
        url = urlOpenOrders(anotherQuery, +leadId);
        break;
    }
    case TradingTabsNameEnums.PendingOrders: {
        url = urlPendingOrders(anotherQuery, +leadId);
        break;
    }
    }

    return Rest.GET(url)
        .then(Rest.middleThen)
        .catch(Rest.simpleCatch);
};

export const getAjaxSumValue = ({ query = null, leadId }: AjaxUrlForOrdersInterface): Promise<TradingSumInfoInterface> => {
    if (query) {
        for (const key in query) {
            if (key === 'accounts' && typeof query[key] === 'string') {
                query[key] = query[key].split(',');
            }
        }
    }
    const sumValueQuery = {
        [KycTradingEnums.Accounts]: query[KycTradingEnums.Accounts]
    };

    return Rest.GET(urlSumValues(sumValueQuery, +leadId)).then(Rest.middleThen)
        .then(response => {
            if (!Object.values(response.data).length) throw new Error(`empty ${name} response`);
            return response.data;
        })
        .catch(Rest.simpleCatch);
};

export const getDefaultPagination = (): TradingPaginationInterface => {
    return {
        current_page: null,
        from: null,
        last_page: null,
        per_page: DEFAULT_PER_PAGE,
        to: null,
        total: null,
    };
};
