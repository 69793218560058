var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{staticClass:"description-cell"},[(_vm.cell.value.comment)?_c('p',[_c('span',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.auto",value:({
                disabled: _vm.isNotLongText,
                title: _vm.cell.value.comment,
            }),expression:"{\n                disabled: isNotLongText,\n                title: cell.value.comment,\n            }",modifiers:{"hover":true,"auto":true}}],staticClass:"description-cell__text"},[_vm._v("\n            "+_vm._s(_vm.cell.value.comment)+",\n        ")]),_vm._v(" "),_c('span',{staticClass:"description-cell__link",on:{"click":function($event){return _vm.handleRedirect(
                _vm.cell.value.client.id,
                _vm.cell.value.id,
                _vm.cell.value.redirect
            )}}},[_vm._v("\n            View Details\n        ")])]):_c('p',[_vm._v("-")])])}
var staticRenderFns = []

export { render, staticRenderFns }