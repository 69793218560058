import Vue from 'vue';
import { ETableFilterKeyLeads } from '~/components/tables/enums/table-filter-key.enum';
import { IOption } from '~/components/tables/_header-filters/leads-id-filter/leads-id-filter-interfaces';
import { ELeadIdFilterOptionsId } from '~/components/tables/_header-filters/leads-id-filter/leads-id-filter-enums';

export default Vue.extend({
    data () {
        return {
            className: 'b-leads-id-filters',
            isActive: false,
            isShowList: false,
            selectedValue: null,
            searchQuery: '',
            options: [
                {
                    id: ELeadIdFilterOptionsId.all,
                    name: 'all',
                    color: 'blue',
                    icon: 'task_alt',
                },
                {
                    id: ELeadIdFilterOptionsId.online,
                    name: 'online',
                    color: 'green',
                    icon: 'circle',
                },
                {
                    id: ELeadIdFilterOptionsId.offline,
                    name: 'offline',
                    color: 'red',
                    icon: 'circle',
                },
            ],
        };
    },
    computed: {
        isSelectedValueAll (): boolean {
            return this.selectedValue?.id === ELeadIdFilterOptionsId.all;
        },
        isVisibleSelectedValue (): boolean {
            return !this.isActive &&
                this.selectedValue &&
                !this.isSelectedValueAll;
        },
        isShowDefaultIcon (): boolean {
            return this.isSelectedValueAll ||
                !this.searchQuery &&
                this.isSelectedValueAll;
        },
    },
    mounted (): void {
        this.setSelectedValue(this.options[0]);
        this.handlerRouterQuery();
    },
    methods: {
        changeIsActive (is: boolean): void {
            this.isActive = is;
        },
        changeIsShowList (is: boolean): void {
            this.isShowList = is;
        },
        handlerShowList (): void {
            this.changeIsShowList(true);
            this.$root.$el.addEventListener('click', this.clickOnBody);
        },
        handlerHideList (): void {
            this.changeIsActive(false);
            this.changeIsShowList(false);

            this.$root.$el.removeEventListener('click', this.clickOnBody);
        },
        clickOnBody (event: any): void {
            const target = event.target;
            if (target.classList.contains === this.className || target.closest(`.${this.className}`)) {
                return;
            } else {
                this.handlerHideList();
            }
        },
        handleClick (): void {
            if (!this.isActive) {
                this.changeIsActive(true);
                this.handlerShowList();
            } else {
                this.handlerHideList();
            }
        },
        resetActivity (): void {
            this.$emit('clearSimpleQuery', ETableFilterKeyLeads.is_on_webtrader);
        },
        setSelectedValue (option: IOption): void {
            this.selectedValue = option;
        },
        resetSearchId (): void {
            this.$emit('clearSimpleQuery', ETableFilterKeyLeads.id);
        },
        resetSearch () {
            this.resetSearchId();
            this.searchQuery = null;
        },
        handlerActivity (option: IOption): void {
            if (option.id === ELeadIdFilterOptionsId.all && this.selectedValue) {
                this.resetActivity();
                this.$emit('clickButtonSearch');
            } else {
                this.$emit('select', [ETableFilterKeyLeads.is_on_webtrader, option]);
            }

            this.setSelectedValue(option);
        },
        handlerSelectMethod (option: IOption): void {
            this.changeIsActive(false);
            this.changeIsShowList(false);
            this.resetSearch();
            this.handlerActivity(option);
            this.$refs.searchTable.clickOutside();
        },
        setDefaultSelectedValue (): void {
            this.selectedValue = this.options[0];
        },
        setSearchQuery (value: string | number): void {
            this.searchQuery = value;
        },
        handlerSearch (leadId: number): void {
            if (leadId && !this.isSelectedValueAll) {
                this.setDefaultSelectedValue();
                this.resetActivity();
                this.handlerHideList();
            } else if (leadId && this.isSelectedValueAll) {
                this.handlerHideList();
            } else if (leadId === null) {
                this.changeIsActive(false);
                this.changeIsShowList(false);
            } else if (!leadId) {
                this.handlerShowList();
            }

            this.$emit('input', [ETableFilterKeyLeads.id, leadId]);
            this.setSearchQuery(leadId);
        },
        clickSearch (): void {
            this.resetActivity();
            this.$nextTick(() => {
                this.$emit('clickButtonSearch');
            });
        },
        searchCurrentOption (id: number): IOption {
            return this.options.find((item: IOption) => item.id === id);
        },
        handlerRouterQuery (): void {
            const query = this.$router.currentRoute.query;
            let option = null;

            if (query[ETableFilterKeyLeads.id]) {
                const id = query[ETableFilterKeyLeads.id];

                this.handlerSearch(id);
                this.setSearchQuery(id);
            }

            if (query[ETableFilterKeyLeads.is_on_webtrader]) {
                option = this.searchCurrentOption(
                    Number(query[ETableFilterKeyLeads.is_on_webtrader])
                );

                this.handlerActivity(option);
            }
        },
        handlerResetBase (): void {  // use in root-table.ts -> methods -> handlerResetFilter
            this.resetActivity();
            this.resetSearchId();
            this.setDefaultSelectedValue();
            this.setSearchQuery('');
            this.handlerHideList();
        },
    }
});
