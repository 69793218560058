export enum ETableFilterType {
    searchLive = 'search-live',
    input = 'input',
    select = 'select',
    data = 'data',
    component = 'component',
}

export enum ETableFilterInputType {
    text = 'text',
    number = 'number',
}
