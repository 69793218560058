<template>
    <div
        class="kyc-tab-item"
        :class="{'kyc-tab-item__red': isPending, 'kyc-tab-item__active': isHistoryOpen}"
    >
        <loader-small-relative v-if="isLoading" />
        <div class="kyc-tab-info">
            <div class="kyc-tab-info__label mb-2">
                {{ label }}
            </div>
            <div class="row-line row-line__center">
                <click2call
                    :class-name="`kyc-phone-icon-width`"
                    :lead-id="leadId"
                    :is-first="isFirst"
                />
                <div
                    v-if="!isOpen"
                    class="kyc-tab-info__value"
                    @dblclick="$emit('edit')"
                >
                    {{ concatValue }}
                </div>
                <div
                    v-if="isOpen"
                    class="kyc-tab-info__row row-phone"
                >
                    <ValidationObserver ref="observer">
                        <app-phone
                            v-model="selected"
                            label="phone_code"
                            track-by="id"
                            type="phone"
                            single-label-type="phone"
                            placeholder="Select"
                            :on-clear="clear"
                            :input="value"
                            :size="'100'"
                            @changeInput="changeValue"
                        />
                    </ValidationObserver>
                </div>
            </div>
        </div>
        <div
            v-if="!isOpen && phone"
            class="kyc-tab-phone_schedule"
        >
            <b-button-group>
                <b-button
                    variant="success"
                    :style="{'white-space': 'nowrap'}"
                    @click="clickCall"
                >
                    Schedule call
                </b-button>
                <img
                    src="/img/clients/details/call_right.svg"
                    alt=""
                >
            </b-button-group>
        </div>
        <div
            v-if="!disabled && !isPending"
            class="kyc-tab-icons"
        >
            <template v-if="!isEditMode">
                <kyc-tab-item-edit-button @edit="edit" />

                <div
                    class="history-parent"
                >
                    <kyc-tab-item-history-button @toggleHistory="history" />

                    <app-history-kyc
                        v-if="isHistoryOpen"
                        v-click-outside="closeHistory"
                    />
                </div>
            </template>
            <template v-if="isEditMode">
                <kyc-tab-item-apply-button @apply="apply" />
                <kyc-tab-item-cancel-button @cancel="cancel" />
            </template>
        </div>

        <div
            v-if="isPending"
            class="kyc-tab-icons"
        >
            <kyc-tab-item-apply-button @apply="clientEditApply(fieldValue)" />
            <kyc-tab-item-cancel-button @cancel="clientEditCancel(fieldValue)" />

            <div class="history-parent">
                <kyc-tab-item-history-button @toggleHistory="history" />

                <app-history-kyc
                    v-if="isHistoryOpen"
                    v-click-outside="closeHistory"
                />
            </div>
        </div>
    </div>
</template>

<script>
import loaderSmallRelative from '../../parts/loaderSmallRelative';
import toggleMixin from '../../mixins/toggleMixin';
import fieldModelMixin from '../../mixins/modelMixins/fieldModelMixin';
import clientKycActionsMixin from '../../mixins/clientKycActionsMixin';
import loadingMixin from '../../mixins/loadingMixin';
import appPhone from '~/components/parts/app-phone/app-phone.vue';
import click2call from '~/components/parts/click2call/click2call';
import toggleHistoryMixin from '~/components/mixins/toggle-history-mixin';
import appHistoryKyc from '~/components/parts/app-history-kyc/app-history-kyc';
import { ClientKYCFinancesModule } from '~/store/modules/client-kyc-finances/client-kyc-finances';
import kycTabItemActionButtonsMixin from '@/js/components/KYC/KycTabItem/KycTabItemActionButtons/kyc-tab-item-action-buttons-mixin';
import { getStringFromFieldValueData, getDataFromFieldValue } from '~/components/KYC/kyc-helpers';
import { EFieldValueDataKeys } from '~/components/KYC/kyc-enums';

export default {
    components: {
        loaderSmallRelative,
        appPhone,
        click2call,
        appHistoryKyc
    },
    mixins : [
        toggleMixin,
        fieldModelMixin,
        clientKycActionsMixin,
        loadingMixin,
        toggleHistoryMixin,
        kycTabItemActionButtonsMixin,
    ],
    props: {
        label : {
            type : String,
            required: true,
        },
        initial : {
            type : [String, Number, Object],
            required: false,
        },
        phoneCountryColumn : {
            type: String,
            required: true
        },
        phoneColumn : {
            type: String,
            required: true
        },
        options : {
            type: Array,
            required: false
        },
        className: {
            type : String,
            required: false,
        },
        phone: {
            type: Boolean,
            required: false,
        },
        leadId: {
            type: Number,
            required: false
        },
        isFirst: {
            type: Boolean,
            default: true,
            required: false
        }
    },
    data () {
        return {
            NOTHING : 'N/A',
        };
    },
    computed : {
        concatValue () {
            if (!this.selected) {
                return `${this.selected ? this.selected : '-'} ${this.value}`;
            } else {
                return getStringFromFieldValueData(this.initial, { visibleCustom: true });
            }
        },
        isEditable () {
            return this.initial?.field?.is_editable;
        },
        fieldValue () {
            return this.initial?.id;
        },
        reason () {
            return ClientKYCFinancesModule.getPendingWithdrawalReason;
        },
        isEditMode () {
            return this.isOpen && this.isEditable;
        }
    },
    methods : {
        getInitialValue () {
            return getStringFromFieldValueData(this.initial, {
                key: EFieldValueDataKeys.custom
            });
        },
        getInitialSelected () {
            return getDataFromFieldValue(this.initial)
                ? {
                    phone_code: this.initial.data[0].display,
                    id: this.initial.data[0].value,
                }
                : null;
        },
        clear () {
            this.clearSelected();
        },
        edit () {
            this.toggleOpen();
        },
        async apply () {
            const isValid = await this.$refs.observer.validate();

            if (isValid) {
                this.startLoading();

                this.updateClientKyc([
                    { column : this.phoneCountryColumn, value : this.selected },
                    { column : this.phoneColumn, value : this.value }
                ])
                    .then(() => {
                        this.endLoading();
                        this.toggleOpen();
                    });
            }
        },
        cancel () {
            this.restoreValue();
            this.restoreSelected();
            this.toggleOpen();
        },
        remove () {
            this.clearSelected();
        },
    }
};
</script>
