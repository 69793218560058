import { EPermissions, EPermissionsOrder } from '~/settings/enums/permissions.enum';
import { IDefaultConfig, IPermissionsGrouped } from '../interfaces/configs-interface';

export const handlerPermissionsGrouped = (data: IDefaultConfig[]): IPermissionsGrouped => {
    const permissionsGrouped: IPermissionsGrouped = {
        users: [],
        clients: [],
        menu: [],
    };
    data.forEach(permission => {
        if (
            permission.id < EPermissions.viewClient &&
            permission.id !== EPermissions.editComplianceDocs
        ) {
            permissionsGrouped.users.push(permission);
        }
        if (permission.id >= EPermissions.viewClient && permission.id < EPermissions.viewAll) {
            permissionsGrouped.clients.push(permission);
        }
        if (
            permission.id >= EPermissions.viewAll ||
            permission.id === EPermissions.editComplianceDocs
        ) {
            const permissionName = EPermissions[permission.id];
            permissionsGrouped.menu.push({
                ...permission,
                // TODO
                order: EPermissionsOrder[permissionName] || EPermissionsOrder.default,
            });
        }
    });

    // TODO
    permissionsGrouped.menu.sort((a, b) => a.order - b.order);
    return permissionsGrouped;
};
