import Vue from 'vue';
import { UserProfilePreview } from './interfaces/user-profile-preview';
import leadDetailsMixin from '~/components/mixins/leadDetailsMixin';
import { urlClientDetails } from '~/settings/ajax-urls';
import { mapActions, mapGetters } from 'vuex';
import { names as leadDetailsNames } from '~/store/modules/leadDetails';
export default Vue.extend({
    mixins: [leadDetailsMixin],
    props: {
        position: {
            type: String
        },
        accounts: {
            type: Boolean
        },
        name: {
            type: Boolean
        }
    },
    data (): UserProfilePreview {
        return {
            className: 'b-user-profile-preview'
        };
    },
    computed: {
        ...mapGetters({
            isLeadDetails : leadDetailsNames.getters.entity,
        }),
        isFullName (): String {
            return this.isLeadDetails['full_name'];
        },
        isDemoAccounts (): Number {
            return this.isLeadDetails['demo_accounts'].length;
        },
        isLiveAccounts (): Number {
            return this.isLeadDetails['live_accounts'].length;
        }
    },
    created () {
        this.handleFetchDetails({
            getAjaxUrl : () => urlClientDetails(this.$route.params.id)
        });
    },
    methods: {
        ...mapActions({
            handleFetchDetails: leadDetailsNames.actions.handleFetch
        }),
    }
});
