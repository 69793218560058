export enum ELegalSubject {
    individual = 1,
    business = 2,
}

export enum ELegalSubjectString {
    individual = 'individual',
    business = 'business',
}

export enum ELegalSubjectIcons {
    individual = 'person',
    business = 'business_center',
    unknown = 'help_outline',
}
