import { isEqual } from 'lodash';

export class StorageService {

    public static hasItem (name: string): boolean {
        return this.getItem(name) !== null;
    }
    public static getItem (name: string): any {
        return JSON.parse(localStorage.getItem(name) as string);
    }

    public static setItem (name: string, item: string | boolean | number | object): void {
        if (item !== null) {
            localStorage.setItem(name, JSON.stringify(item));
        }
    }

    public static updateArrayItem (name: string, item: string | boolean | number | any): void {
        if (!this.hasItem(name)) {
            this.setItem(name, [item]);
        } else {
            if (Array.isArray(item)) {
                this.setItem(name, [ ...this.getItem(name), ...item ]);
            } else {
                const lsItems = this.getItem(name).filter((lsItem: object) => !isEqual(lsItem, item));
                this.setItem(name, [ ...lsItems, item ]);
            }
        }
    }

    public static updateObjectItem (name: string, item: object): void {
        if (!this.hasItem(name)) {
            this.setItem(name, item);
        } else {
            this.setItem(name, { ...this.getItem(name), ...item });
        }
    }

    public static removeItem (name: string): void {
        localStorage.removeItem(name);
    }

    public static clearAll (): void {
        localStorage.clear();
    }
}
