<template>
    <div class="details_contacts__row">
        <div class="details_category-img mr-3 ml-0">
            <material-icon
                symbol="alternate_email"
                color="grey"
                size="sm"
            />
        </div>
        <div class="d-flex details_contacts__label details_contacts__label--email pr-0">
            <span class="details_category">Email</span>
            <span
                v-b-popover.hover.top
                class="details_value details_value--no-margin text-right"
                :title="leadDetails.email"
            >
                {{ leadDetails.email }}
            </span>
        </div>
    </div>
</template>

<script>
import leadDetailsMixin from '../../mixins/leadDetailsMixin';

export default {
    mixins : [
        leadDetailsMixin,
    ],

};
</script>
