import { ETableFilterKeyAffiliatesReports } from '../enums/table-filter-key.enum';
import { ITableFilterQuery } from '../interfaces/table-filter-query.interfaces';
import { parseDate } from '~/helpers/common';

export const prepareQueryWithDate = (query: ITableFilterQuery): any => {
    const startDate = '2015-01-01';

    const dates = {
        [ETableFilterKeyAffiliatesReports.startDate]:
            query[ETableFilterKeyAffiliatesReports.startDate] || startDate,
        [ETableFilterKeyAffiliatesReports.endDate]:
            query[ETableFilterKeyAffiliatesReports.endDate] ||
                parseDate(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD'),
    };

    return {
        ...dates,
        ...query,
    };
};
