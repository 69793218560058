import { getModule, Module, VuexModule, Action, Mutation } from 'vuex-module-decorators';
import store from '~/store';
import Rest from '~/helpers/Rest';
import {
    urlAffiliatesDocumentsList,
    urlAffiliatesDocumentsFile,
    urlAffiliatesDocumentsDelete,
} from '~/settings/ajax-urls';
import {
    IAffiliatesDocumentsPayload,
    IAffiliatesDocument,
    IAffiliatesDocumentFilePayload,
    IAffiliatesDocumentFileAddSrc,
    IAffiliatesDocumentCreatePayload,
    IAffiliatesDocumentDeletePayload,
    IAffiliatesDocumentUpdatePayload,
} from '~/store/modules/affiliates/interfaces/affiliates-documents-interfaces';
import { handlerRestResponse } from '~/helpers/handler-rest-response';

import Vue from 'vue';
import { NotificationPrefabricated } from '~/helpers/modal-notification-services/modal-notification-services';

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: 'affiliatesDocuments',
})

class affiliatesDocuments extends VuexModule {

    documentList: IAffiliatesDocument[] = [];

    get getAffiliateDocumentList (): IAffiliatesDocument[] {
        return this.documentList;
    }

    @Mutation
    setAffiliatesDocumentSrc (payload: IAffiliatesDocumentFileAddSrc) {
        const index = this.documentList
            .findIndex(item => item.id === payload.documentId);
        Vue.prototype.$set(this.documentList[index], 'url', payload.src);
    }

    @Mutation
    setAffiliatesDocumentDetails (payload: IAffiliatesDocument) {
        const index = this.documentList
            .findIndex(item => item.id === payload.id);
        for (const item in payload) {
            Vue.prototype.$set(this.documentList[index], item, payload[item]);
        }
    }

    @Mutation
    deleteAffiliatesDocument (payload: number) {
        const index = this.documentList
            .findIndex(item => item.id === payload);
        Vue.prototype.$delete(this.documentList, index);
    }

    @Mutation
    setAffiliatesDocumentsList (payload: IAffiliatesDocument[]) {
        this.documentList = payload;
    }

    @Mutation
    setSimpleAffiliatesDocument (payload: IAffiliatesDocument[]) {
        this.documentList.push(...payload);
    }

    @Action
    async affiliatesDocumentsList (payload: IAffiliatesDocumentsPayload): Promise<void> {
        const { data } = await Rest.GET(urlAffiliatesDocumentsList(payload.affiliate, payload))
            .then(Rest.middleThen)
            .catch(error => {
                Rest.simpleCatch(error);
            });
        this.setAffiliatesDocumentsList(data);
    }

    @Action
    async affiliatesDocumentsFile (payload: IAffiliatesDocumentFilePayload): Promise<void> {
        const documentId = payload.documentId;
        await Rest.GET(urlAffiliatesDocumentsFile(payload.affiliate, documentId))
            .then(res => res.blob())
            .then(blob => {
                const src = URL.createObjectURL(blob);
                this.setAffiliatesDocumentSrc({
                    documentId,
                    src,
                });
            })
            .catch(error => {
                Rest.simpleCatch(error);
            });
    }

    @Action
    async affiliatesDocumentCreate (payload: IAffiliatesDocumentCreatePayload): Promise<void> {
        const { data } = await Rest.FILE(urlAffiliatesDocumentsList(payload.affiliate), payload.documents)
            .then(response => {
                return handlerRestResponse(response, NotificationPrefabricated.uploadedDocumentsSuccess);
            })
            .catch(error => {
                Rest.simpleCatch(error);
            });
        this.setSimpleAffiliatesDocument(data);
    }

    @Action
    async affiliatesDocumentDelete (payload: IAffiliatesDocumentDeletePayload): Promise<void> {
        await Rest.DELETE(urlAffiliatesDocumentsDelete(payload.affiliate, payload.document))
            .then(response => {
                return handlerRestResponse(response, NotificationPrefabricated.deletedDocumentsSuccess);
            })
            .catch(error => {
                Rest.simpleCatch(error);
            });
        this.deleteAffiliatesDocument(payload.document);
    }

    @Action
    async affiliatesDocumentUpdate (payload: IAffiliatesDocumentUpdatePayload): Promise<void> {
        const { data } = await Rest.PUT(urlAffiliatesDocumentsDelete(payload.affiliate, payload.document_id), payload.document)
            .then(response => {
                return handlerRestResponse(response, NotificationPrefabricated.updateDocumentsSuccess);
            })
            .catch(error => {
                Rest.simpleCatch(error);
            });
        this.setAffiliatesDocumentDetails(data);
    }

}

export const affiliatesDocumentsModule = getModule(affiliatesDocuments);
