import {
    COLUMN_AFFILIATE,
    COLUMN_CAMPAIGN,
    COLUMN_COMPLICATEDNESS_ID,
    COLUMN_DEMO_ACCOUNTS_COUNT, COLUMN_DEPOSITED,
    COLUMN_EMAIL, COLUMN_EQUITY,
    COLUMN_FIRST_LIVE_DEPOSIT_AMOUNT,
    COLUMN_FIRST_LIVE_DEPOSIT_DATE, COLUMN_FREE_MARGIN,
    COLUMN_ID, COLUMN_LANGUAGE_A2, COLUMN_LAST_LIVE_DEPOSIT_AMOUNT,
    COLUMN_LAST_LIVE_DEPOSIT_DATE, COLUMN_LAST_LIVE_WITHDRAWAL_AMOUNT, COLUMN_LAST_LIVE_WITHDRAWAL_DATE,
    COLUMN_LEAD_STATUS_ID, COLUMN_CUSTOMER_SUPPORT_USER_FULL_NAME,
    COLUMN_LEGAL_SUBJECT,
    COLUMN_LIVE_ACCOUNTS_COUNT,
    COLUMN_NAME, COLUMN_OPEN_ORDERS, COLUMN_PENDING_WITHDRAWALS,
    COLUMN_PHONE,
    COLUMN_PROFESSIONAL_LEVEL_ID,
    COLUMN_REGISTERED_AT,
    COLUMN_RESIDENCE_COUNTRY_ID, COLUMN_RETENTION_USER_FULL_NAME, COLUMN_SALES_USER_FULL_NAME,
    COLUMN_TIMEZONE_ID, COLUMN_TOTAL_LIVE_DEPOSITS,
    COLUMN_VERIFY_STATUS_ID
} from '../../store/modules/_columnNames';
import { leadsFiltersColumnsId } from '../../store/modules/_columnsId';

export const exportOptions =  {
    export: {
        name: 'Export',
        value: 'export',
        modal: null

    },
    edit: {
        name: 'Edit',
        value: 'edit',
        modal: {
            modalType: 'editLeadModal',
            title: 'Edit Multiple Leads'
        }
    },
    import: {
        name: 'Import Data',
        value: 'import',
        isAlwaysActive: true,
        modal: {
            modalType: 'importLeadModal',
            title: 'Import Data from Excel'
        }

    }
};

export const filtersOptions = [
    {
        numberId: leadsFiltersColumnsId[COLUMN_ID],
        id: COLUMN_ID,
        name: 'ID',
        value: 'id'
    },
    {
        numberId: leadsFiltersColumnsId[COLUMN_RESIDENCE_COUNTRY_ID],
        id: COLUMN_RESIDENCE_COUNTRY_ID,
        name: 'Country',
        value: 'country'
    },
    {
        numberId: leadsFiltersColumnsId[COLUMN_TIMEZONE_ID],
        id: COLUMN_TIMEZONE_ID,
        name: 'Local Time',
        value: 'local_time'
    },
    {
        numberId: leadsFiltersColumnsId[COLUMN_NAME],
        id: COLUMN_NAME,
        name: 'Name',
        value: 'name'
    },
    {
        numberId: leadsFiltersColumnsId[COLUMN_REGISTERED_AT],
        id: COLUMN_REGISTERED_AT,
        name: 'Reg. Date',
        value: 'reg_date'
    },
    {
        numberId: leadsFiltersColumnsId[COLUMN_PHONE],
        id: COLUMN_PHONE,
        name: 'Phone',
        value: 'phone'
    },
    {
        numberId: leadsFiltersColumnsId[COLUMN_EMAIL],
        id: COLUMN_EMAIL,
        name: 'Email',
        value: 'email'
    },
    {
        numberId: leadsFiltersColumnsId[COLUMN_LEGAL_SUBJECT],
        id: COLUMN_LEGAL_SUBJECT,
        name: 'Client Type',
        value: 'client_type'
    },
    {
        numberId: leadsFiltersColumnsId[COLUMN_VERIFY_STATUS_ID],
        id: COLUMN_VERIFY_STATUS_ID,
        name: 'Client Status',
        value: 'client_status'
    },
    {
        numberId: leadsFiltersColumnsId[COLUMN_PROFESSIONAL_LEVEL_ID],
        id: COLUMN_PROFESSIONAL_LEVEL_ID,
        name: 'Client Categorization',
        value: 'client_categorisation'
    },
    {
        numberId: leadsFiltersColumnsId[COLUMN_LEAD_STATUS_ID],
        id: COLUMN_LEAD_STATUS_ID,
        name: 'Lead Status',
        value: 'lead_status'
    },
    {
        numberId: leadsFiltersColumnsId[COLUMN_COMPLICATEDNESS_ID],
        id: COLUMN_COMPLICATEDNESS_ID,
        name: 'Group',
        value: 'group'
    },
    {
        numberId: leadsFiltersColumnsId[COLUMN_LIVE_ACCOUNTS_COUNT],
        id: COLUMN_LIVE_ACCOUNTS_COUNT,
        name: 'Live Acc.',
        value: 'live_acc'
    },
    {
        numberId: leadsFiltersColumnsId[COLUMN_DEMO_ACCOUNTS_COUNT],
        id: COLUMN_DEMO_ACCOUNTS_COUNT,
        name: 'Demo Acc.',
        value: 'demo_acc'
    },
    {
        numberId: leadsFiltersColumnsId[COLUMN_FIRST_LIVE_DEPOSIT_DATE],
        id: COLUMN_FIRST_LIVE_DEPOSIT_DATE,
        name: 'FTD Date',
        value: 'ftd_date'
    },
    {
        numberId: leadsFiltersColumnsId[COLUMN_FIRST_LIVE_DEPOSIT_AMOUNT],
        id: COLUMN_FIRST_LIVE_DEPOSIT_AMOUNT,
        name: 'FTD Amount',
        value: 'ftd_amount'
    },
    {
        numberId: leadsFiltersColumnsId[COLUMN_LAST_LIVE_DEPOSIT_DATE],
        id: COLUMN_LAST_LIVE_DEPOSIT_DATE,
        name: 'LTD Date',
        value: 'ltd_date'
    },
    {
        numberId: leadsFiltersColumnsId[COLUMN_LAST_LIVE_DEPOSIT_AMOUNT],
        id: COLUMN_LAST_LIVE_DEPOSIT_AMOUNT,
        name: 'LTD Amount',
        value: 'ltd_amount'
    },
    {
        numberId: leadsFiltersColumnsId[COLUMN_TOTAL_LIVE_DEPOSITS],
        id: COLUMN_TOTAL_LIVE_DEPOSITS,
        name: 'Total Deposits',
        value: 'total_deposits'
    },
    {
        numberId: leadsFiltersColumnsId[COLUMN_LAST_LIVE_WITHDRAWAL_DATE],
        id: COLUMN_LAST_LIVE_WITHDRAWAL_DATE,
        name: 'LTW Date',
        value: 'ltw_date'
    },
    {
        numberId: leadsFiltersColumnsId[COLUMN_LAST_LIVE_WITHDRAWAL_AMOUNT],
        id: COLUMN_LAST_LIVE_WITHDRAWAL_AMOUNT,
        name: 'LTW Amount',
        value: 'ltw_amount'
    },
    {
        numberId: leadsFiltersColumnsId[COLUMN_PENDING_WITHDRAWALS],
        id: COLUMN_PENDING_WITHDRAWALS,
        name: 'Pending Withdrawals',
        value: 'pending_withdrawals'
    },
    {
        numberId: leadsFiltersColumnsId[COLUMN_EQUITY],
        id: COLUMN_EQUITY,
        name: 'Equity',
        value: 'equity'
    },
    {
        numberId: leadsFiltersColumnsId[COLUMN_LANGUAGE_A2],
        id: COLUMN_LANGUAGE_A2,
        name: 'Language',
        value: 'language'
    },
    {
        numberId: leadsFiltersColumnsId[COLUMN_CAMPAIGN],
        id: COLUMN_CAMPAIGN,
        name: 'Campaign',
        value: 'campaign'
    },
    {
        numberId: leadsFiltersColumnsId[COLUMN_AFFILIATE],
        id: COLUMN_AFFILIATE,
        name: 'Affiliate',
        value: 'affiliate'
    },
    {
        numberId: leadsFiltersColumnsId[COLUMN_OPEN_ORDERS],
        id: COLUMN_OPEN_ORDERS,
        name: 'Open Orders',
        value: 'open_orders'
    },
    {
        numberId: leadsFiltersColumnsId[COLUMN_FREE_MARGIN],
        id: COLUMN_FREE_MARGIN,
        name: 'F. Margin',
        value: 'f_margin'
    },
    {
        numberId: leadsFiltersColumnsId[COLUMN_CUSTOMER_SUPPORT_USER_FULL_NAME],
        id: COLUMN_CUSTOMER_SUPPORT_USER_FULL_NAME,
        name: 'CS Rep.',
        value: 'cs_rep'
    },
    {
        numberId: leadsFiltersColumnsId[COLUMN_SALES_USER_FULL_NAME],
        id: COLUMN_SALES_USER_FULL_NAME,
        name: 'Sales Rep.',
        value: 'sales_rep'
    },
    {
        numberId: leadsFiltersColumnsId[COLUMN_RETENTION_USER_FULL_NAME],
        id: COLUMN_RETENTION_USER_FULL_NAME,
        name: 'Retention Rep.',
        value: 'retention_rep'
    },
    {
        numberId: leadsFiltersColumnsId[COLUMN_DEPOSITED],
        id: COLUMN_DEPOSITED,
        name: 'Deposited',
        value: 'depositedS'
    }
];

export const failedAccsOptions = [
    {
        name: 'Rejected',
        value: 'rejected'
    },
    {
        name: 'Rejected in Last 15 Days',
        value: 'rejected_15'
    },
    {
        name: 'Terminated',
        value: 'terminated'
    },
    {
        name: 'Closed',
        value: 'closed'
    },
    {
        name: 'Failed Questionnaire',
        value: 'failed_questionnaire'
    }
];
