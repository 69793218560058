export enum ESocialIconsDev {
    'social-icon-i' = 63,
    'social-icon-f' = 64,
    'social-icon-in' = 65,
    'social-icon-g' = 66,
    'social-icon-t'= 67,
}

export enum ESocialIconsProd {
    'social-icon-i' = 1890,
    'social-icon-f' = 1891,
    'social-icon-in' = 1892,
    'social-icon-g' = 1893,
    'social-icon-t'= 1894,
}
