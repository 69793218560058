import { render, staticRenderFns } from "./dasboard-trading-volume.html?vue&type=template&id=405d4847&lang=html&"
import script from "./dasboard-trading-volume.ts?vue&type=script&lang=ts&"
export * from "./dasboard-trading-volume.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports