import ItemNavbar from '../ItemNavbar.vue';
import rootTable from '~/components/tables/root-table/root-table.vue';
import LeverageItem from './leverage-item/leverage-item.vue';
import leadDetailsMixin from '~/components/mixins/leadDetailsMixin';
import tradingMixin from '~/components/mixins/trading/tradingMixin';
import { LeadAccountsModule } from '~/store/modules/lead-accounts/lead-accounts';
import { ILeadAccount } from '~/store/modules/lead-accounts/lead-accounts-interfaces';

export default {
    components: {
        ItemNavbar,
        rootTable,
        LeverageItem,
    },
    mixins: [
        tradingMixin,
        leadDetailsMixin,
    ],
    computed: {
        accounts (): ILeadAccount[]  {
            return LeadAccountsModule.getAccounts;
        },
        sortAccounts (): ILeadAccount[] {
            return this.accounts.sort((
                a: ILeadAccount,
                b: ILeadAccount
            ) => a.profit_method_type_id - b.profit_method_type_id);
        },
        isClientHaveOneAccount (): boolean {
            return this.accounts.length === 1;
        }
    },
    mounted (): void {
        LeadAccountsModule.handleSetAccounts(this.$route.params.id)
            .then(() => {
                if (this.isClientHaveOneAccount) {
                    this.filterAccounts([this.accounts[0]]);
                } else {
                    this.loadTrading();
                }
            });
    },
};
