import localForage from 'localforage';
import { appName } from './constants';
import querystring from 'query-string';
import moment from 'moment';
import lodash from 'lodash';


localForage.config({
    driver : localForage.LOCALSTORAGE,
    name : appName,
    storeName : appName
});

// todo dev only
window.__localForage = localForage;
window.__querystring = querystring;
window.moment = moment;
window.lodash = lodash;
