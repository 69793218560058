import Vue from 'vue';
import { EConfigType } from '~/store/modules/configs/enums/config-type.enum';
import rowItemPropsMixin from '@/js/components/tables/mixins/table-cells-mixins/row-item-props-mixin';

export default Vue.extend({
    mixins: [
        rowItemPropsMixin,
    ],
    computed: {
        isPhoneNumber (): string {
            return typeof this.cell.value === EConfigType.string ?
                this.isPhoneNumberString() :
                this.isPhoneNumberObj();
        },
    },
    methods: {
        isPhoneNumberString (): string {
            return this.cell.value || '';
        },
        isPhoneNumberObj (): string {
            const { phone_mobile_country, phone_mobile } = this.cell.value;
            return phone_mobile_country ?
                `(+${phone_mobile_country.phone}) ${phone_mobile}` :
                phone_mobile || '';
        },
    }
});
