import { ITableHeaderColumn } from '~/components/tables/interfaces/table-header-column.interfaces';

export const reportsTableHeader: ITableHeaderColumn[] = [
    {
        id: 1,
        name: 'account_id',
        value: 'Account Id',
        cellData: 'id',
        cellType: 'plain-text',
        textAlign: 'center',
    },
    {
        id: 2,
        name: 'name',
        value: 'Name',
        cellData: 'client',
        cellType: 'key-from-object',
        cellDataKey: 'name',
    },
    {
        id: 3,
        name: 'email',
        value: 'Email',
        cellData: 'client',
        cellType: 'key-from-object',
        cellDataKey: 'email',
        textAlign: 'left',
        cellClass: 'text-lowercase',
    },
    {
        id: 4,
        name: 'account',
        value: 'Trading Account',
        cellType: 'plain-text',
        cellData: 'account',
        textAlign: 'right',
    },
    {
        id: 5,
        name: 'client_type',
        value: 'Client Categorization',
        cellType: 'key-from-object',
        cellDataKey: 'name',
        cellData: 'professional_level',
    },
    {
        id: 6,
        name: 'balance',
        value: 'Balance',
        cellType: 'plain-sum',
        cellData: 'balance',
        textAlign: 'right',
    },
    {
        id: 7,
        name: 'equity',
        value: 'Equity',
        cellType: 'plain-sum',
        cellData: 'equity',
        textAlign: 'right',
    },
    {
        id: 8,
        name: 'crm_id',
        value: 'CRM ID',
        cellType: 'key-from-object',
        cellDataKey: 'id',
        cellData: 'client',
        cellWidth: '100px',
    },
    {
        id: 9,
        name: 'currency',
        value: 'Currency',
        cellType: 'key-from-object',
        cellDataKey: 'name',
        cellData: 'currency',
        cellWidth: '100px',
    },
];
