import Vue from 'vue';
import modalLayout from '~/components/modals/root-modal-layout/root-modal-layout.vue';
import modalsMixin from '~/components/mixins/modals';
import configsMixin from '~/components/mixins/configs-mixin';
import currencyHelper from '~/components/mixins/currency-helper';
import cpaForm from '~/components/_forms/bonus-plan-forms/cpa-form/cpa-form.vue';
import cplForm from '~/components/_forms/bonus-plan-forms/cpl-form/cpl-form.vue';
import rebateForm from '~/components/_forms/bonus-plan-forms/rebate-form/rebate-form.vue';
import hybridForm from '~/components/_forms/bonus-plan-forms/hybrid-form/hybrid-form.vue';
import revShareForm from '~/components/_forms/bonus-plan-forms/rev-share-form/rev-share-form.vue';
import { AffiliatesBonusPlanEnum } from '~/components/affiliates/affiliates-bonus-plan/enums/affiliates-bonus-plan.enum';
import { bonusPlanFormatted } from '~/store/modules/bonus-plan/helpers/bonus-plan-formatted';
import { bonusPlanTypeValidation } from '~/components/affiliates/affiliates-bonus-plan/helpers/bonus-plan-type-validation';
import { IBonusPlan } from '~/store/modules/bonus-plan/interfaces/bonus-plan-interfaces';
import { AffiliatesBonusPlansModule } from '@/js/store/modules/affiliates/affiliates-bonus-plans';
import { TypesBonusPlanFormsEnums } from '@/js/components/_forms/bonus-plan-forms/types-bonus-plan-forms.enum';

export default Vue.extend({
    components: {
        modalLayout,
        cpaForm,
        cplForm,
        rebateForm,
        hybridForm,
        revShareForm,
    },
    mixins: [
        modalsMixin,
        configsMixin,
        currencyHelper,
    ],
    provide () {
        return {
            bonusPlanListNames: this.bonusPlanListNames,
        };
    },
    data () {
        return {
            editBonusPlanForm: AffiliatesBonusPlanEnum,
            bonus_type: null,
        };
    },
    computed: {
        isActiveBonusPlanId (): number {
            return this.bonus_type?.id;
        },
        bonusPlanListNames (): string[] {
            return AffiliatesBonusPlansModule.getBonusPlanItems
                .map((item) => item.name);
        },
        getTypeForm () {
            return TypesBonusPlanFormsEnums.create;
        }
    },
    created (): void {
        this.setActiveBonusType();
    },
    methods: {
        setActiveBonusType () {
            this.bonus_type = this.getConfigBonusTypes
                .find(type => type.id === this.$attrs['modal-props'].body.activeCategory);
        },
        async createBonusPlan () {
            const payload = {
                ...this.$refs.editBonusPlanForm.$data.editBonusPlan,
                revenues: this.$refs.editBonusPlanForm.$refs.editBonusPlanRevenue?.$data.itemsToEdit,
                countries: this.$refs.editBonusPlanForm.$refs.editBonusPlanCountries?.$data.bonusPlanCountriesToEdit,
                other_country_value: this.$refs.editBonusPlanForm.$refs.editBonusPlanCountries?.$data.otherCountryValue,
                bonus_type: this.bonus_type,
            };

            const isValid = await this.$refs.observer.validate();
            const typeValid = await bonusPlanTypeValidation(payload);

            if (isValid && typeValid) {
                this.onSubmitUpdateBonusPlan(payload);
            }
        },
        closeModal () {
            this.$emit('closeModal');
        },
        async onSubmitUpdateBonusPlan (payload: IBonusPlan): Promise<void> {
            const payloadFormatted = bonusPlanFormatted(payload);
            this.$attrs['modal-props'].ok(payloadFormatted);
        }
    }
});
