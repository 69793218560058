import { Module, VuexModule, getModule, Action, Mutation } from 'vuex-module-decorators';
import store from '~/store/index';
import Rest from '~/helpers/Rest';
import { urlAffiliatesReports, urlCampaignsReports } from '~/settings/ajax-urls';
import { affiliatesReportsTableHeader } from '~/components/tables/_headers/affiliates-reports';
import { affiliatesReportsCampaignTableHeader } from '~/components/tables/_headers/affiliates-reports-campaign';
import { tableParser } from '~/helpers/table-parser';
import { ITableCell } from '~/components/tables/interfaces/table-cell-interfaces';
import { ITableHeaderColumn } from '~/components/tables/interfaces/table-header-column.interfaces';
import { ITableFilterQuery } from '~/components/tables/interfaces/table-filter-query.interfaces';
import { handlerQueriesToFetch } from '~/helpers/handlers-queries-to-fetch/handler-queries-to-fetch';
import { ETableType } from '~/components/tables/enums/table-type.enum';
import { IPagination } from '~/settings/interfaces/pagination-interfaces';
import { NotificationPrefabricated } from '@/js/helpers/modal-notification-services/modal-notification-services';
import { showNothingWasFound } from '@/js/components/tables/helpers/showNothingWasFound';

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: 'affiliatesReports',
})
class AffiliatesReports extends VuexModule {

    affiliatesReports = [];
    affiliatesReportsCampaigns = [];
    loaded: boolean = false;
    affiliatesReportsPagination: IPagination = {
        current_page: null,
        from: null,
        last_page: null,
        per_page: null,
        to: null,
        total: null,
    };
    campaignsReportsPagination: IPagination = {
        current_page: null,
        from: null,
        last_page: null,
        per_page: null,
        to: null,
        total: null,
    };

    get getAffiliatesReports () {
        return this.affiliatesReports;
    }
    get getAffiliatesReportsCampaigns () {
        return this.affiliatesReportsCampaigns;
    }

    get getReportsHeader (): ITableHeaderColumn[] {
        return affiliatesReportsTableHeader;
    }
    get getReportsRows (): ITableCell[] {
        return tableParser(affiliatesReportsTableHeader, this.getAffiliatesReports);
    }
    get getReportsHeaderCampaign (): ITableHeaderColumn[] {
        return affiliatesReportsCampaignTableHeader;
    }
    get getReportsRowsCampaign (): ITableCell[] {
        return tableParser(affiliatesReportsCampaignTableHeader, this.getAffiliatesReportsCampaigns);
    }

    get getAffiliatesReportIsLoaded (): boolean {
        return this.loaded;
    }

    get getAffiliatesReportsPagination (): IPagination {
        return this.affiliatesReportsPagination;
    }

    get getCampaignsReportsPagination (): IPagination {
        return this.campaignsReportsPagination;
    }

    @Mutation
    setAffiliatesReports (payload) {
        this.affiliatesReports = payload;
    }
    @Mutation
    setAffiliatesReportsCampaigns (payload) {
        this.affiliatesReportsCampaigns = payload;
    }

    @Mutation
    setLoadedState (payload: boolean) {
        this.loaded = payload;
    }

    @Mutation
    setAffiliatesReportsPagination (payload: IPagination) {
        this.affiliatesReportsPagination = payload;
    }

    @Mutation
    setCampaignsReportsPagination (payload: IPagination) {
        this.campaignsReportsPagination = payload;
    }

    @Action
    async loadAffiliatesReports (payload: ITableFilterQuery): Promise<void> {
        this.setLoadedState(false);
        payload = handlerQueriesToFetch(payload, ETableType.affiliatesReports);

        const { data, meta } = await Rest.GET(urlAffiliatesReports(payload))
            .then(Rest.middleThen)
            .catch(error => {
                Rest.simpleCatch(error);
            })
            .finally(() => {
                this.setLoadedState(true);
                NotificationPrefabricated.cleanAllNotify();
            });
        this.setAffiliatesReports(data);
        this.setAffiliatesReportsPagination(meta);
        showNothingWasFound(data.length);
    }

    @Action
    async loadCampaignsReports (payload: ITableFilterQuery): Promise<void> {
        this.setLoadedState(false);
        payload = handlerQueriesToFetch(payload, ETableType.affiliatesReports);

        const { data, meta } = await Rest.GET(urlCampaignsReports(payload))
            .then(Rest.middleThen)
            .catch(error => {
                Rest.simpleCatch(error);
            })
            .finally(() => {
                this.setLoadedState(true);
                NotificationPrefabricated.cleanAllNotify();
            });

        this.setCampaignsReportsPagination(meta);
        this.setAffiliatesReportsCampaigns(data);
    }

    @Action
    async loadCampaignsReportsById (payload: ITableFilterQuery): Promise<void> {
        const { data } = await Rest.GET(urlCampaignsReports(payload))
            .then(Rest.middleThen)
            .catch(error => {
                Rest.simpleCatch(error);
            });
        this.setAffiliatesReportsCampaigns(data);
        return data;
    }
}
export const AffiliatesReportsModule = getModule(AffiliatesReports);
