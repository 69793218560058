import { render, staticRenderFns } from "./accounting-commission-database.html?vue&type=template&id=0c360a76&lang=html&"
import script from "./accounting-commission-database.ts?vue&type=script&lang=ts&"
export * from "./accounting-commission-database.ts?vue&type=script&lang=ts&"
import style0 from "./accounting-commission-database.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports