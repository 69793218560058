import Vue from 'vue';

export default Vue.extend({
    props: {
        className: {
            type: String,
            required: false,
            default: '',
        },
        text : {
            type: String,
            required : true,
        },
    },
});
