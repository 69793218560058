import Vue from 'vue';
import EmailsNavbar from '~/components/emails/emails-navbar/emails-navbar.vue';
import EmailsTemplateItem from '~/components/emails/emails-template-item/emails-template-item.vue';
import { EmailsTemplatesModule } from '~/store/modules/emails/emails-templates';
import { ModalsModule } from '~/store/modules/modals';

export default Vue.extend({
    components: {
        EmailsNavbar,
        EmailsTemplateItem,
    },
    computed: {
        emailTemplates () {
            return EmailsTemplatesModule.getterEmailTemplates;
        }
    },
    created (): void {
        EmailsTemplatesModule.loadEmailTemplates();
    },
    methods: {
        addTemplate () {
            ModalsModule.openModal({
                modalType: 'emailTemplates',
                props: {
                    title: '',
                    body: {
                        templateName: ''
                    },
                    isHeader: false,
                    isFooter: false
                },
                modalSettings: {
                    props: {
                        minWidth: ModalsModule.modalWidth.lg,
                    },
                },
                addClass: 'modal-emails'
            });
        }
    }
});
