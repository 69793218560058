import Vue from 'vue';
import subNavbar from '~/components/subNavbar/subNavbar.vue';
import subNavbarLink from '~/components/subNavbar/subNavbarLink.vue';
import subNavbarSeparator from '~/components/subNavbar/subNavbarSeparator.vue';
import { EmailsInboxModule } from '~/store/modules/emails/emails-inbox';
import { isPermissionLiveChat } from '~/helpers/user-permission';

export default Vue.extend({
    components: {
        subNavbar,
        subNavbarLink,
        subNavbarSeparator,
    },
    computed: {
        isPermissionLiveChat,
        countEmailsUnRead (): Number {
            return EmailsInboxModule.getterEmailsListUnReadCount;
        }
    }
});
