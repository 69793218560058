import { ITableHeaderColumn } from '../interfaces/table-header-column.interfaces';
import { defaultTableHeader } from '~/components/tables/_headers/_default';

export const tableHeaderParser = (
    header: ITableHeaderColumn[], amount: number = defaultTableHeader.length
): ITableHeaderColumn[] => {
    if (header.length >= defaultTableHeader.length) return header;
    const res = [...defaultTableHeader].slice(0, amount);

    header.forEach((item, index) => {
        res[index] = item;
    });
    return res;
};
