import Vue from 'vue';
import templateItem from '~/components/template-item/template-item.vue';
import { LeadsFilterTemplatesModule } from '~/store/modules/leads-filter-templates/leads-filter-templates';
import { ModalsModule } from '~/store/modules/modals';
export default Vue.extend({
    components: {
        templateItem
    },
    props: {
        templates: {
            type: Array,
            default: null,
            required: true
        }
    },
    methods: {
        async useTemplate (index: number) {
            await LeadsFilterTemplatesModule.useTemplate(this.templates[index]);
            LeadsFilterTemplatesModule.changeVisibleTemplateList(false);
        },
        setTemplateToEdit (index: number) {
            LeadsFilterTemplatesModule.setTemplateToEdit(this.templates[index]);
            ModalsModule.openModal({
                modalType: 'leadsFiltersTemplateEdit',
                props: {
                    title: 'Edit Template',
                    body: {
                    },
                    isHeader: true,
                    isFooter: true
                },
                modalSettings: {
                    props: {
                        minWidth: ModalsModule.modalWidth.xs,
                    },
                },
            });
        },
        deleteTemplate (index: number) {
            LeadsFilterTemplatesModule.isDeleteTemplate(this.templates[index]);
            ModalsModule.openModal({
                modalType: 'leadsFiltersTemplateDelete',
                props: {
                    title: 'Are you sure?',
                    body: {
                        data: 'Are you sure you want to delete this template?'
                    },
                    isHeader: true,
                    isFooter: true
                },
                modalSettings: {
                    props: {
                        minWidth: ModalsModule.modalWidth.xs,
                    },
                },
            });
        },
    }
});
