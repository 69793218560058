// Imports
import Vue from 'vue';
import DatePicker from 'vue2-datepicker';
import { AffiliatesMarketingModule } from '~/store/modules/affiliates/affiliates-marketing';

import subNavbar from '~/components/subNavbar/subNavbar.vue';
import subNavbarLink from '~/components/subNavbar/subNavbarLink.vue';
import subNavbarSeparator from '~/components/subNavbar/subNavbarSeparator.vue';
import navbarAffiliates from '~/components/affiliates/affiliates-nav/affiliates-nav.vue';
// @ts-ignore
import rootTable from '~/components/tables/root-table/root-table';
import { ETableType } from '~/components/tables/enums/table-type.enum';
import appMoreOptions from '~/components/parts/app-more-options/app-more-options.vue';

// Exports
export default Vue.extend({
    components: {
        'sub-navbar': subNavbar,
        'sub-navbar-link': subNavbarLink,
        'sub-navbar-separator': subNavbarSeparator,
        navbarAffiliates,
        rootTable,
        DatePicker,
        appMoreOptions,
    },
    data () {
        return {
            className: '',
            activeTab: 'By Employee',
            selectedDate: '',
            options: [
                {
                    type: 'affilaite',
                    value: 'All Affiliates'
                },
                {
                    type: 'affilaite',
                    value: 'All Affiliates'
                }
            ]
        };
    },
    computed: {
        marketingTableHeader (): Array<object> {
            return AffiliatesMarketingModule.getterAffiliatesMarketingHeader;
        },
        marketingTableRows (): Array<object> {
            return AffiliatesMarketingModule.getterAffiliatesMarketingRows;
        },
        tableType (): string {
            return ETableType.affiliatesStatistic;
        },
    },
});
