import Vue from 'vue';
import Chart from 'chart.js';
import rowItemPropsMixin from '@/js/components/tables/mixins/table-cells-mixins/row-item-props-mixin';

export default Vue.extend({
    mixins: [
        rowItemPropsMixin,
    ],
    data () {
        return {
            chart: {
                type: 'pie',
                data: {
                    datasets: [{
                        data: [],
                        backgroundColor: ['#6dbf5a', '#d6d6e6'],
                        borderColor: '#ffffff',
                        borderAlign: 'inner',
                        borderWidth: 1
                    }],
                },
                options: {
                    segmentShowStroke: true,
                    maintainAspectRatio: false,
                    legend: {
                        display: false,
                    },
                    scales: {
                        display: false,
                        xAxes: [
                            {
                                display: false,
                            },
                        ],
                        yAxes: [
                            {
                                display: false,
                            },
                        ],
                    },
                },
            },
        };
    },
    computed: {
        swiper () {
            return this.$refs.mySwiper.$swiper;
        }
    },
    mounted () {
        this.cell.value.forEach((element, id) => {
            const canvas = <HTMLCanvasElement> document.getElementById(`calls-destinations${this.$attrs.index}${id}`);
            const ctx = canvas.getContext('2d');
            this.chart.data.datasets[0].data = [element.percents, 100 - element.percents];
            this.chart.link = new Chart(ctx, this.chart);
        });
    }
});
