<template>
    <ValidationObserver ref="observerPhoneCode">
        <form>
            <div class="details_contacts__row client-detail-row">
                <click2call
                    class="ml-0"
                    :lead-id="leadId"
                    :is-first="isFirst"
                    size="great-lg-super"
                    :icon="icon"
                    icon-color="gray"
                />
                <div class="d-flex details_contacts__label">
                    <div
                        v-if="!isOpen"
                        class="details_category"
                    >
                        {{ label }}
                    </div>
                    <div
                        v-if="isOpen"
                        class="details_field"
                    >
                        <app-phone
                            v-model="selected"
                            placeholder="Select"
                            :on-clear="clear"
                            :input="handleReplace(value)"
                            :size="'100'"
                            :type="'phone'"
                            @changeInput="changeValue"
                        />
                    </div>
                    <span
                        v-else-if="loading"
                        class="details_value"
                    >Loading...</span>
                    <span
                        v-else
                        class="details_value details_value--no-margin text-right"
                    >{{ phoneFull || 'N/A' }}</span>
                </div>

                <div
                    :class="[
                        `client-detail-row__buttons
                        d-flex flex-row
                        align-items-center
                        phone-column`,
                        isOpen ? 'client-detail-row__buttons--active' : ''
                    ]"
                >
                    <edit-btn
                        v-if="!isOpen && isPermissionEditClientEmailPhone"
                        :class="[{ 'mr-3': isFirst && addButton }]"
                        @onEdit="toggleOpen"
                    />
                    <add-btn
                        v-if="!isOpen && isPermissionEditClientEmailPhone && isFirst && addButton"
                        @onAdd="$emit('add')"
                    />
                    <edit-mode-btns
                        v-if="isOpen"
                        @onOk="confirm"
                        @onCancel="restore"
                    />
                </div>
            </div>
        </form>
    </ValidationObserver>
</template>
<script>
import appPhone from '~/components/parts/app-phone/app-phone.vue';

import toggle from '../../mixins/toggleMixin';
import EditBtn from './parts/editBtn';
import AddBtn from './parts/addBtn';
import EditModeBtns from './parts/editModeBtns';
import click2call from '~/components/parts/click2call/click2call';
import fieldModelMixin from '../../mixins/modelMixins/fieldModelMixin';
import { isPermissionEditClientEmailPhone } from '~/helpers/user-permission';

export default {
    components : {
        EditBtn,
        AddBtn,
        EditModeBtns,
        appPhone,
        click2call
    },
    mixins : [
        toggle,
        fieldModelMixin,
    ],
    props : {
        label : {
            type : String,
            required: true,
            default : 'Mobile',
        },
        icon: {
            type: String,
        },
        phoneCountry : {
            type : Object,
            required: false,
            default: function () {
                return {
                    id: 'default',
                    name: 'Select'
                };
            }
        },
        phoneBody : {
            type : [String, Number, Object],
            default: '',
            required: false
        },
        phoneFull : {
            type : [String],
            required: false,
            default: ''
        },
        loading : {
            type : Boolean,
            required: true,
        },
        addButton : {
            type : Boolean,
            required: false,
            default: false
        },
        leadId: {
            type: Number,
            required: false
        },
        isFirst: {
            type: Boolean,
            default: true,
            required: false
        }
    },
    computed: {
        isPermissionEditClientEmailPhone,
    },
    created () {
        this.checkPhoneCountry();
    },
    methods : {
        handleReplace (value) {
            if (!value) return;
            return value.replace('XX', '');
        },
        checkPhoneCountry () {
            if (this.phoneCountry?.id === 'default') {
                this.clear();
            }
        },
        async confirm () {
            const isValid = await this.$refs.observerPhoneCode.validate();
            const result = this.selected ? this.selected.id : this.selected;

            if (isValid) {
                this.$emit('confirm', result, this.value);
                this.hide();
            }
        },
        clear () {
            this.clearSelected();
        },
        restore () {
            this.restoreSelected();
            this.restoreValue();
            this.hide();
        },
        getInitialValue () {
            return this.phoneBody;
        },
        getInitialSelected () {
            return this.phoneCountry;
        }
    },
};
</script>
