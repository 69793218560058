import Vue from 'vue';
import { EmailsInboxModule } from '~/store/modules/emails/emails-inbox';

import EmailsNavbar from '~/components/emails/emails-navbar/emails-navbar.vue';
import EmailsInboxColumn from '~/components/emails/email-inbox-column/email-inbox-column.vue';

export default Vue.extend({
    components: {
        EmailsNavbar,
        EmailsInboxColumn
    },
    computed: {
        emailsInboxUnReadList (): Array<any> {
            return EmailsInboxModule.getterEmailsListUnReadList;
        },
        emailsInboxReadList (): Array<any> {
            return EmailsInboxModule.getterEmailsListReadList;
        }
    }
});
