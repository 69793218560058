import Vue from 'vue';
import rowItemPropsMixin from '@/js/components/tables/mixins/table-cells-mixins/row-item-props-mixin';

export default Vue.extend({
    mixins: [
        rowItemPropsMixin,
    ],
    computed: {
        getKey (): string {
            return this.cell?.cellDataKey || '';
        },
        getUrl (): string {
            return this.getKey ? this.cell.value?.[this.getKey] : this.cell.value;
        }
    },
});
