export default {
    data (): object {
        return {
            switchers: ['Percentage', 'Fixed'],
        };
    },
    computed: {
        currentSwitcher (): string {
            return this.switchers[this.editBonusPlan.is_fixed];
        }
    }
};
