export enum DocumentTypesEnum {
    AuthorisationDeposit = 'Authorisation of Deposit'
}

export enum DocumentTypesIdsEnum {
    ProofOfAddress = 5,
    AuthorisationDepositId = 8,
    AuthorisationDepositTransfer = 9,
    AuthorisationDepositEWallets = 10
}

