import { ITableHeaderColumn } from '~/components/tables/interfaces/table-header-column.interfaces';
import { ETableFilterInputType, ETableFilterType } from '~/components/tables/enums/table-filter-type.enum';
import { tableHeaderParser } from '~/components/tables/helpers/table-header-parser';

export const symbolsTableHeader: ITableHeaderColumn[] = tableHeaderParser([
    {
        name: 'icon',
        value: 'Logo',
        id: 2,
        cellType: 'image-url',
        cellDataKey: 'url',
        cellData: 'display_symbol',
        filterType: ETableFilterType.select,
    },
    {
        name: 'countries',
        value: 'Country',
        id: 3,
        cellType: 'country-find-by',
        cellDataKey: 'A3',
        cellData: 'country',
        filterType: ETableFilterType.select,
        filterMultiple: true,
    },
    {
        name: 'trading_types',
        value: 'Trading Type',
        id: 4,
        cellType: 'plain-text',
        cellData: 'trading_type',
        filterType: ETableFilterType.select,
        filterMultiple: true,
    },
    {
        name: 'exchanges',
        value: 'Exchange',
        id: 5,
        cellType: 'plain-text',
        cellData: 'exchange',
        filterType: ETableFilterType.select,
        filterMultiple: true,
    },
    {
        name: 'mt5_symbol',
        value: 'Trading Symbol Name MT5',
        id: 6,
        cellType: 'plain-text',
        cellData: 'symbol_mt5',
        filterType: ETableFilterType.input,
    },
    {
        name: 'display_symbol_name',
        value: 'Trading Symbol Name Webtrader/Website',
        id: 7,
        cellType: 'key-from-object',
        cellDataKey: 'name',
        cellData: 'display_symbol',
        filterType: ETableFilterType.input,
    },
    {
        name: 'description_generic',
        value: 'Description Generic (Graphs & Search)',
        id: 9,
        cellType: 'plain-text',
        cellData: 'description_generic',
        filterType: ETableFilterType.input,
    },
    {
        name: 'description_detail',
        value: 'Description (For Trade box only)',
        id: 10,
        cellType: 'plain-text',
        cellData: 'description_detail',
        filterType: ETableFilterType.input,
    },
    {
        name: 'currencies_base',
        value: 'Base Currency',
        id: 11,
        cellType: 'plain-text',
        cellData: 'currency_base',
        filterType: ETableFilterType.select,
        filterMultiple: true,
    },
    {
        name: 'currencies_profit',
        value: 'Quote Currency',
        id: 12,
        cellType: 'plain-text',
        cellData: 'currency_quote',
        filterType: ETableFilterType.select,
    },
    {
        name: 'sectors',
        value: 'Sector',
        id: 13,
        cellType: 'plain-text',
        cellData: 'sector',
        filterType: ETableFilterType.select,
        filterMultiple: true,
    },
    {
        name: 'industries',
        value: 'Industry',
        id: 13,
        cellType: 'plain-text',
        cellData: 'industry',
        filterType: ETableFilterType.select,
        filterMultiple: true,
    },
    {
        name: 'products',
        value: 'Product',
        id: 14,
        cellType: 'plain-text',
        cellData: 'product',
        filterType: ETableFilterType.select,
        filterMultiple: true,
    },
    {
        name: 'contract_size',
        value: 'Contract Size',
        id: 14,
        cellType: 'plain-sum',
        cellData: 'contract_size',
        filterType: ETableFilterType.input,
        inputType: ETableFilterInputType.number,
        textAlign: 'right',
    },
    {
        name: 'spread_novice',
        value: 'Avg. Spread for Novice Acc.Type',
        id: 15,
        cellType: 'plain-text',
        cellData: 'spread_novice',
        filterType: ETableFilterType.input,
        inputType: ETableFilterInputType.number,
        textAlign: 'right',
    },
    {
        name: 'spread_experience',
        value: 'Avg. Spread for Experience Acc. Type',
        id: 16,
        cellType: 'plain-text',
        cellData: 'spread_experience',
        filterType: ETableFilterType.input,
        inputType: ETableFilterInputType.number,
        textAlign: 'right',
    },
    {
        name: 'min_trade_size',
        value: 'Min. Trade size',
        id: 17,
        cellType: 'plain-sum',
        cellData: 'min_trade_size',
        filterType: ETableFilterType.input,
        inputType: ETableFilterInputType.number,
        textAlign: 'right',
    },
    {
        name: 'max_trade_size',
        value: 'Max. Trade Size',
        id: 18,
        cellType: 'plain-sum',
        cellData: 'max_trade_size',
        filterType: ETableFilterType.input,
        inputType: ETableFilterInputType.number,
        textAlign: 'right',
    },
    {
        name: 'time_expiration',
        value: 'Expiring Date',
        id: 19,
        cellType: 'plain-text',
        cellData: 'time_expiration',
        filterType: ETableFilterType.data,
        textAlign: 'center',
    },
    {
        name: 'trading_hours_gmt2',
        value: 'Trading Hours (GMT+2)',
        id: 20,
        cellType: 'plain-text',
        cellData: 'trading_hours_gmt2',
        filterType: ETableFilterType.input,
        textAlign: 'center',
    },
    {
        name: 'dividend',
        value: 'Dividend',
        id: 21,
        cellType: 'plain-text',
        cellData: 'dividend',
        filterType: ETableFilterType.input,
    },
    {
        name: 'dividend_date',
        value: 'Dividend Date',
        id: 22,
        cellType: 'plain-text',
        cellData: 'dividend_date',
        filterType: ETableFilterType.data,
    },
    {
        name: 'ex_div_date',
        value: 'Expiring Dividend Date',
        id: 23,
        cellType: 'plain-text',
        cellData: 'ex_div_date',
        filterType: ETableFilterType.data,
        textAlign: 'center',
    },
    {
        name: 'is_allowed_on_website',
        value: 'Allowed on website',
        id: 24,
        cellType: 'boolean-into-text',
        cellData: 'is_allowed_on_website',
        filterType: ETableFilterType.select,
    },
]);
