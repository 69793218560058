<template>
    <div class="lds-default">
        <div /><div /><div /><div /><div /><div /><div /><div /><div /><div /><div /><div />
    </div>
</template>

<script>
export default {
    name: 'TheLoader'
};
</script>

<style scoped>

</style>
