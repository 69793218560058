<template>
    <svg
        id="background_finacnial_kyc"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32.41 37.12"
    >
        <path
            :style="`fill:#2362b7`"
            d="M27.25 5v4.17h-5.76V5a16.21 16.21 0 1 0 5.76 0z"
            transform="translate(-8.16)"
        />
        <path
            :style="`fill:#2362b7`"
            d="M14.46 0h3.33v7.92h-3.33z"
        />
        <path
            :style="`fill:#fff`"
            d="M26.59 26.77c-.45-.07-.46-1.29-.46-1.29a6.13 6.13 0 0 0 1.58-3c.77 0 1.24-1.84.47-2.49 0-.68 1-5.34-3.81-5.34S20.52 19.3 20.56 20c-.77.65-.3 2.49.47 2.49a6.13 6.13 0 0 0 1.58 3s0 1.22-.46 1.29c-1.42.22-6.37 2.32-6.59 4.68l17.76.46c0-2.68-5.31-4.92-6.73-5.14zm0 0"
            transform="translate(-8.16)"
        />
        <path
            :style="`fill:#fff`"
            d="M33.32 31.61c.33 1.36-4 2.52-8.94 2.52s-8.82-.74-9-2.22 4-2.82 9-2.82 8.62 1.05 8.94 2.52z"
            transform="translate(-8.16)"
        />
    </svg>
</template>

<script>
export default {

};
</script>
