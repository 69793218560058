// Imports
import Vue from 'vue';
import { mapGetters } from 'vuex';
import pendingDocumentsTable from './pending-documents-table/pending-documents-table.vue';
import { names as clientKycNames } from '~/store/modules/client-kyc/client-kyc';
import { DocumentRequestsModule } from '~/store/modules/documents/document-requests';
import { PendingDocumentsInterfaces } from './interfaces/pending-documents-interfaces';
import theLoader from '~/components/theLoader.vue';
import { NotificationPrefabricated } from '~/helpers/modal-notification-services/modal-notification-services';
import { ComplianceUploadedDocumentsModule } from '~/store/modules/documents/uploadedDocuments';
import { DocumentsRequestInterfaces, DocumentsRequestTypeInterface } from '~/store/modules/documents/interfaces/documents-request-interfaces';
import configsMixin from '~/components/mixins/configs-mixin';

// Exports
export default Vue.extend({
    components: {
        pendingDocumentsTable,
        theLoader
    },
    mixins: [
        configsMixin,
    ],
    data (): PendingDocumentsInterfaces {
        return {
            options: [],
            docCategorySelected: null,
            docSelected: null,
            text: '',
            isDisabled: true,
            disabledField: true,
            submitStatus: ''
        };
    },
    computed: {
        ...mapGetters({
            load: clientKycNames.getters.load,
            clientKyc: clientKycNames.getters.entity,
        }),
        categories (): Array<DocumentsRequestTypeInterface> {
            return this.getConfigClientDocumentTypes
                .filter(item => !item.parent_id);
        },
        entities (): Array<DocumentsRequestInterfaces>|Array<object> {
            return DocumentRequestsModule.getterDocumentRequests;
        },
        isLoading () {
            return this.load.isLoaded;
        },
        possibleDocumentTypes () {
            if (!this.docCategorySelected) return [];
            return this.getConfigClientDocumentTypes
                .filter(item => item.parent_id === this.docCategorySelected.id);
        },
    },
    methods: {
        removeDocumentRequest ({ id, client_id }) {
            DocumentRequestsModule.actionHandlerRemovedDocumentRequest({ id, client_id });
        },
        onCategorySelect (option) {
            this.docCategorySelected = option;
            this.docSelected = null;
            this.text = null;
            this.isDisabled = false;
        },
        onDocSelect (option) {
            this.docSelected = option;
            this.disabledField = false;
            this.text = option ? option.comment : null;
        },
        removeTableItem (id) {
            this.removeDocumentRequest({ id: id, client_id: this.clientKyc.id });
            NotificationPrefabricated.PendingDocumentsRemoveSuccess();
        },
        handleOnSubmit () {
            DocumentRequestsModule.actionHandleCreatedDocumentsRequest({
                client_id: this.clientKyc.id,
                comment: this.text,
                type_id: this.docSelected.id
            });

            this.docSelected = null;
            this.text = null;
            this.docCategorySelected = [];
            this.isDisabled = true;
            this.disabledField = true;

            ComplianceUploadedDocumentsModule.receiveUploadedDocuments({ leadId: this.clientKyc.id });
        }
    }
});
