import Vue from 'vue';
import affiliateDetailsMixin from '~/components/mixins/affiliate-details-mixin';
import rowItemPropsMixin from '@/js/components/tables/mixins/table-cells-mixins/row-item-props-mixin';

export default Vue.extend({
    mixins: [
        affiliateDetailsMixin,
        rowItemPropsMixin,
    ],
    computed: {
        isValue () {
            return this.cell.value ?
                this.cell.value.name || this.cell.value :
                '-' ;
        },
    },
    methods: {
        handleClick (): void {
            if (this.cell.value?.id) {
                this.goToAffiliateDetails(this.cell.value.id);
            }
        },
    },
});
