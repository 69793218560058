import Vue from 'vue';
import generateUniqueIdMixin from '~/components/mixins/generate-unique-id-mixin';

export default Vue.extend({
    mixins: [
        generateUniqueIdMixin,
    ],
    props: {
        isLabel: {
            type: String
        },
        isButton: {
            type: Boolean
        },
        clearButton: {
            type: Boolean
        },
        textarea: {
            type: Boolean
        },
        noMargin: {
            type: Boolean
        },
        noBorder: {
            type: Boolean
        },
        noRounding: {
            type: Boolean
        },
        addClass: {
            type: String
        },
        validation: {
            type: String
        },
        size: {
            type: String
        },
        inputValue: {
            type: String,
        },
        textError: {
            type: Boolean,
            default: false,
        },
        card: {
            type: Boolean
        },
        // TO REMOVE icon after change on material-icon
        icon: {
            type: String,
        },
        materialIcon: {
            type: String,
        },
        materialIconColor: {
            type: String,
        },
        mode: {
            type: String,
            default: 'passive',
        },
        placeholder: {
            type: String
        },
    },
    data () {
        return {
            className: 'b-input',
            middleValue: '',
            isTooltip: {
                title: '',
                disabled: true,
            },
        };
    },
    computed: {
        getMaterialIconColor (): string {
            return this.materialIconColor ? this.materialIconColor : '';
        },
        allBindings () {
            // Need to proxify both props and attrs, for example for showLabels
            return { ...this.$props, ...this.$attrs };
        },
        isExistLabel (): Boolean {
            return !!this.isLabel;
        },
        isTextArea (): Boolean {
            return this.textarea;
        },
        isCheckbox (): Boolean {
            return this.allBindings.type === 'checkbox';
        },
        isNoMargin (): Boolean {
            return this.noMargin;
        },
        isHidden (): Boolean {
            return this.allBindings.type === 'hidden';
        },
        checkboxIcon (): string {
            return this.allBindings.checked
                ? 'select_check_box'
                : 'check_box_outline_blank';
        },
        checkboxColor (): string {
            return this.allBindings.checked ? 'blue' : 'grey';
        },
        checkboxTitle (): string {
            return `Change on ${this.allBindings.checked ? 'uncheck' : 'check'}`;
        },
        isDisabled (): boolean {
            return this.allBindings.disabled || this.allBindings.readonly;
        },
        isValue (): boolean {
            return !!this.allBindings.value;
        },
        visibleClearButton (): string {
            return this.clearButton && this.isValue;
        }
    },
    methods: {
        handleChangeEvent (event) {
            const val = event.target.checked;
            this.middleValue = val;
            this.$emit('change', val);
        },
        handleInputEvent (event) {
            const val = event.target.value;
            this.middleValue = val;
            this.$emit('input', val);
        },
        handleClickButton () {
            this.$emit('clickButton', this.middleValue);
        },
        handleKeyPress (event) {
            this.$emit('keyPress', event.target.value);
        },
        handleClickClearButton () {
            this.$emit('clear', this.middleValue);
        },
        /**
         * Remove input error
         * */
        removeInputError () {
            this.$refs.validationProvider.reset();
        },

        setTooltipState (disabled: boolean, title: string = ''): void {
            this.$set(this.isTooltip, 'disabled', disabled);
            this.$set(this.isTooltip, 'title', title);

            setTimeout(() => {
                if (disabled) {
                    this.$root.$emit('bv::hide::popover', this.generatedUniqueId);
                } else {
                    this.$root.$emit('bv::show::popover', this.generatedUniqueId);
                }
            });
        },

        checkInputError () {
            const errors = this.$refs.validationProvider.errors;

            if (this.textError && errors.length > 0) {
                this.setTooltipState(false, errors[0]);
            } else {
                this.setTooltipState(true);
            }
        },
        clearInput () {
            this.handleClickClearButton();
            this.$emit('input', '');
        },
    }
});
