var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{ref:"validationProvider",class:[(_vm.className + "-wrap"), _vm.isHidden ? (_vm.className + "-wrap--hidden") : '' ],attrs:{"rules":_vm.validation,"mode":_vm.mode,"tabindex":"0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('label',{directives:[{name:"b-popover",rawName:"v-b-popover.top",value:(_vm.isTooltip),expression:"isTooltip",modifiers:{"top":true}}],class:[
            _vm.className,
            _vm.isTextArea ? (_vm.className + "--textarea"): '',
            _vm.isCheckbox ? (_vm.className + "--checkbox"): '',
            _vm.isNoMargin ? (_vm.className + "--no-margin"): '',
            (_vm.isValue && !_vm.isDisabled) ? (_vm.className + "--is-value"): '',
            _vm.noBorder ? (_vm.className + "--no-border"): '',
            _vm.noRounding ? (_vm.className + "--no-rounding"): '',
            _vm.addClass ? (_vm.className + "--" + _vm.addClass) : '',
            _vm.icon ? (_vm.className + "--icon") : '',
            _vm.materialIcon ? (_vm.className + "--icon") : '',
            _vm.size ? (_vm.className + "--" + _vm.size) : '',
            classes
        ],attrs:{"id":_vm.generatedUniqueId}},[(_vm.isCheckbox)?[_c('material-icon',{class:[(_vm.className + "__checkbox-checkmark")],attrs:{"symbol":_vm.checkboxIcon,"color":_vm.checkboxColor,"title":_vm.checkboxTitle,"delay":""}})]:_vm._e(),_vm._v(" "),(_vm.isExistLabel)?_c('span',{class:[(_vm.className + "__label")]},[_vm._v("\n            "+_vm._s(_vm.isLabel)+"\n        ")]):_vm._e(),_vm._v(" "),(_vm.isButton)?[_c('div',{class:[(_vm.className + "__input-row")]},[_c('input',_vm._b({class:[(_vm.className + "__text-field")],on:{"input":function($event){return _vm.handleInputEvent($event)},"focus":_vm.removeInputError,"blur":_vm.checkInputError}},'input',_vm.allBindings,false)),_vm._v(" "),_c('button',{attrs:{"сlass":[(_vm.className + "__btn-submit")],"type":"submit"},on:{"click":_vm.handleClickButton}},[_c('img',{attrs:{"src":"/img/icon/tick-white.svg","alt":""}})])])]:[_c('div',{class:[(_vm.className + "__text-field-wrap")]},[(_vm.isTextArea)?[_c('textarea',_vm._b({class:[(_vm.className + "__text-field")],on:{"input":function($event){return _vm.handleInputEvent($event)},"focus":_vm.removeInputError,"blur":_vm.checkInputError}},'textarea',_vm.allBindings,false))]:_vm._e(),_vm._v(" "),(_vm.isCheckbox)?[_c('input',_vm._b({class:[(_vm.className + "__text-field")],on:{"change":function($event){return _vm.handleChangeEvent($event)},"focus":_vm.removeInputError,"blur":_vm.checkInputError}},'input',_vm.allBindings,false))]:[(!_vm.card)?_c('input',_vm._b({class:[
                            (_vm.className + "__text-field") ],on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleKeyPress($event)},"input":function($event){return _vm.handleInputEvent($event)},"focus":_vm.removeInputError,"blur":_vm.checkInputError}},'input',_vm.allBindings,false)):_c('input',_vm._b({class:[
                            (_vm.className + "__text-field") ],attrs:{"placeholder":_vm.placeholder},domProps:{"value":_vm.inputValue},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleKeyPress($event)},"input":function($event){return _vm.handleInputEvent($event)},"focus":_vm.removeInputError,"blur":_vm.checkInputError}},'input',_vm.allBindings,false))],_vm._v(" "),(_vm.materialIcon || _vm.icon || _vm.visibleClearButton)?_c('span',{class:[
                        (_vm.className + "__text-field-icon"),
                        _vm.visibleClearButton ? (_vm.className + "__text-field-icon--clear") : '' ]},[(_vm.icon && !_vm.visibleClearButton)?_c('app-icon',{attrs:{"symbol":_vm.icon,"color":"inherit","customSize":"sm"}}):(_vm.materialIcon && !_vm.visibleClearButton)?_c('material-icon',{attrs:{"symbol":_vm.materialIcon,"size":"sm","color":_vm.getMaterialIconColor}}):_c('material-icon',{attrs:{"symbol":"close","size":"sm","color":"red"},nativeOn:{"click":function($event){return _vm.clearInput.apply(null, arguments)}}})],1):_vm._e()],2)]],2)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }