import Vue from 'vue';
export default Vue.extend({
    props: {
        option: {
            type: Object,
            required: true,
            default: function () {
                return {};
            }
        },
        className: {
            type: String,
        },
    },
    methods: {
        getStatusImg (type: string): string {
            switch (type) {
            case 'Nov.' :
                return '/img/clients/icon-select-novice.svg';
            case 'N/A' :
                return '/img/clients/icon-select-na.svg';
            case 'Exp.' :
                return '/img/clients/icon-select-experienced.svg';
            case 'Crypto' :
                return '/img/clients/icon-select-crypto.svg';
            }
        },
    }
});
