export enum modalType {
    accessSettingsModal = 'accessSettingsModal',
    addEditUserModal = 'addEditUserModal',
    massEditUserModal = 'massEditUserModal',
    deleteUserModal = 'deleteUserModal',
    addNewAffiliatesModal = 'addNewAffiliatesModal',
    affiliatesEditModal = 'affiliatesEditModal',
    affiliatesCampaignsAdd = 'affiliatesCampaignsAdd',
    affiliatesCampaignsEdit = 'affiliatesCampaignsEdit',
    affiliatesMassEditModal = 'affiliatesMassEditModal',
    createBonusPlan = 'createBonusPlan',
    actionModal = 'actionModal',
    reportCreating = 'reportCreating',
    addLead = 'addLead',
    editLeadModal = 'editLeadModal',
    leadsFiltersTemplateSave = 'leadsFiltersTemplateSave',
    importLeadModal = 'importLeadModal',
    createAdditionalAccountModal = 'createAdditionalAccountModal',
    confirmUpdateLeads = 'confirmUpdateLeads',
    cancelUpdateLeadField = 'cancelUpdateLeadField',
    emailTemplates = 'emailTemplates',
    addNewManager = 'addNewManager',
    addNewEmployees = 'addNewEmployees',
    leadsFiltersTemplateEdit = 'leadsFiltersTemplateEdit',
    affiliateDocumentModal = 'affiliateDocumentModal',
    lightBox = 'lightBox',
    docModal = 'docModal',
    uploadTermDocuments = 'uploadTermDocuments',
    leadsFinanceCreateTransaction = 'leadsFinanceCreateTransaction',
    leadsPaymentsModal = 'leadsPaymentsModal',
    uploadDocumentModal = 'uploadDocumentModal',
    symbolModal = 'symbolModal',
}

export enum modalWidth {
    xxs = 275,
    xs = 320,
    sm = 500,
    md = 640,
    lg = 900,
    xl = 1200,
}

export enum modalHeight {
    lg = 500,
}
