import Vue from 'vue';
import { EConfigType } from '~/store/modules/configs/enums/config-type.enum';
import { EStringMaxLength } from './plain-text.enum';
import rowItemPropsMixin from '@/js/components/tables/mixins/table-cells-mixins/row-item-props-mixin';

export default Vue.extend({
    mixins: [
        rowItemPropsMixin,
    ],
    computed: {
        isValue (): string {
            return this.cell.value &&
                (
                    typeof this.cell.value === EConfigType.string ||
                    typeof this.cell.value === EConfigType.number
                )
                ? this.cell.value : 'N/A';
        },
        isNotLongText (): boolean {
            return this.isValue.length < EStringMaxLength.length;
        },
    },
});
