import Vue from 'vue';
import toggleMixin from '~/components/mixins/toggleMixin';
import kycTabItemApplyButton from '@/js/components/KYC/KycTabItem/KycTabItemActionButtons/kyc-tab-item-apply-button/kyc-tab-item-apply-button.vue';
import kycTabItemCancelButton from '@/js/components/KYC/KycTabItem/KycTabItemActionButtons/kyc-tab-item-cancel-button/kyc-tab-item-cancel-button.vue';
import clientKycActionsMixin from '~/components/mixins/clientKycActionsMixin';
import toggleHistoryMixin from '~/components/mixins/toggle-history-mixin';
import kycTabItemHistoryButton from '@/js/components/KYC/KycTabItem/KycTabItemActionButtons/kyc-tab-item-history-button/kyc-tab-item-history-button.vue';
import appHistoryKyc from '~/components/parts/app-history-kyc/app-history-kyc.vue';
import { getStringFromFieldValueData } from '~/components/KYC/kyc-helpers';
import { IDateTime } from '~/store/modules/configs/interfaces/configs-interface';

export default Vue.extend({
    components: {
        kycTabItemApplyButton,
        kycTabItemCancelButton,
        kycTabItemHistoryButton,
        appHistoryKyc,
    },
    mixins : [
        toggleMixin,
        clientKycActionsMixin,
        toggleHistoryMixin,
    ],
    props: {
        initial : {
            type: Object,
            required : true,
        },
    },
    computed: {
        date (): IDateTime {
            return this.initial.status_at || this.initial.created_at;
        },
        value (): string {
            return getStringFromFieldValueData(this.initial, {
                visibleCustom: true,
                isPipe: true,
            });
        },
        outputValue (): string {
            return (this.value && this.date) &&
                `${this.value} | Date: ${this.date.date} | Time: ${this.date.time}` || '-';
        },
    },
});
