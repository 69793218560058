import { IDataOfFieldValues, IDataOfFieldValuesWithName, IFieldValuesWithFieldConfigs } from '~/settings/interfaces/kyc-interfaces';
import { ArgsOfGetStringFromFieldValueData } from '~/components/KYC/kyc-interfaces';
import { EFieldValueDataKeys } from '~/components/KYC/kyc-enums';

export const addNameKeyWithValueOfDisplay = (data: IDataOfFieldValues[]): IDataOfFieldValuesWithName[] => {
    return data.map((tin: IDataOfFieldValues) => {
        return {
            ...tin,
            name: tin.display
        };
    });
};

// send key from EFieldValueDataKeys ('~/components/KYC/kyc-enums')
export const getStringFromFieldValueData = (
    fieldValue: IFieldValuesWithFieldConfigs,
    settings?: ArgsOfGetStringFromFieldValueData
): string => {
    if (fieldValue?.data?.[0]) {
        const field = {
            visibleCustom: false,
            key: EFieldValueDataKeys.display,
            customBrackets: false,
            defaultReturn: false,
            isPipe: false,
            ...settings,
        };

        if (fieldValue.data[0].custom && field.visibleCustom) {
            return `${fieldValue.data[0].display} ${field.isPipe ? '|' : ''} ${field.customBrackets ? `(${fieldValue.data[0].custom})` : fieldValue.data[0].custom}`;
        }

        if (fieldValue.data[0][field.key]) {
            return fieldValue.data[0][field.key];
        }
    }

    if (settings?.defaultReturn) {
        return 'N/A';
    }

    return '';
};

export const getDataFromFieldValue = (fieldValue: IFieldValuesWithFieldConfigs,): IDataOfFieldValues => {
    return fieldValue?.data?.[0];
};
