import { Module, VuexModule, MutationAction, Action, getModule } from 'vuex-module-decorators';
import router from '~/router';
import store from '~/store/index';
import Rest from '~/helpers/Rest';
import { urlAccounts, urlAccountsCreate } from '~/settings/ajax-urls';
import { ILeadAccount, ICreateAccountPayload } from '~/store/modules/lead-accounts/lead-accounts-interfaces';

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: 'LeadAccounts',
})

class LeadAccounts extends VuexModule {
    accounts: ILeadAccount[] = [];
    loading: boolean = false;
    loaded: boolean = false;

    get getAccounts (): ILeadAccount[] {
        return this.accounts;
    }

    @MutationAction ({ mutate: ['accounts'] })
    async handleSetAccounts (leadId: number) {
        const result = await Rest.GET(urlAccounts(leadId));
        const { data = [] } = await result.json();

        return {
            accounts: data
        };
    }

    @Action
    async createAccount (payload: ICreateAccountPayload): Promise<void> {
        await Rest.POST(urlAccountsCreate(+router.currentRoute.params.id), payload);
    }
}

export const LeadAccountsModule = getModule(LeadAccounts);
