export enum ModalNotificationTypesEnum {
    Success = 'success',
    Error = 'error',
    Pending = 'pending',
}

export enum EModalNotificationButtonTypes {
    Success = 'outline-green',
    Error = 'outline-danger',
}

export enum EModalNotificationIcons {
    check = 'check_circle',
    error = 'error_circle_rounded',
    pending = 'pending',
}

export enum EModalNotificationStylesCloseButton {
    creamGreen = 'cream-green-reverse',
    creamRed = 'cream-red-reverse',
    creamOrange = 'cream-orange-reverse',
}
