import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '~/store/index';
import { tableParser } from '~/helpers/table-parser';
import Rest from '~/helpers/Rest';
import {
    urlPayments,
    urlPaymentsPendingApprove,
    urlPaymentsPendingCancel
} from '~/settings/ajax-urls';
import { clientKycFinancesInterfaces } from '~/store/modules/client-kyc-finances/client-kyc-finances-interfaces';
import { ITableHeaderColumn } from '~/components/tables/interfaces/table-header-column.interfaces';
import { ITableCell } from '~/components/tables/interfaces/table-cell-interfaces';
import {
    IWithdrawalsQuery,
} from '~/store/modules/withdrawals/interfaces/withdrawals-query.interfaces';
import { IWithdrawalsOperationPayload } from '~/store/modules/withdrawals/interfaces/withdrawals-operation-payload.interfaces';
import {
    withdrawalsHistoryHeader,
    withdrawalsPendingHeader,
} from '~/components/tables/_headers/withdrawals';
import { NotificationPrefabricated } from '~/helpers/modal-notification-services/modal-notification-services';
import { IPagination } from '~/settings/interfaces/pagination-interfaces';
import { showNothingWasFound } from '@/js/components/tables/helpers/showNothingWasFound';
import { ETableFilterKeyWithdrawals } from '~/components/tables/enums/table-filter-key.enum';
import { PaymentStatusEnum } from '~/settings/enums/payment-status-enum';
import { handlerQueriesToFetch } from '~/helpers/handlers-queries-to-fetch/handler-queries-to-fetch';
import { ETableType } from '~/components/tables/enums/table-type.enum';
import { handlerRestResponse } from '~/helpers/handler-rest-response';
import { EIdKey } from '~/settings/enums/id-key-for-table-parser-enum';

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: 'Withdrawals',
})
class Withdrawals extends VuexModule {

    withdrawalsHistoryTable: clientKycFinancesInterfaces[] = [];
    withdrawalsPagination: IPagination = {
        current_page: null,
        from: null,
        last_page: null,
        per_page: null,
        to: null,
        total: null,
    };
    loaded: boolean = false;

    get getWithdrawalsHistoryTableHeader (): ITableHeaderColumn[] {
        return withdrawalsHistoryHeader;
    }
    get getWithdrawalsPendingTableHeader (): ITableHeaderColumn[] {
        return withdrawalsPendingHeader;
    }

    get getWithdrawalsHistoryTable (): ITableCell[] {
        return tableParser(withdrawalsHistoryHeader, this.withdrawalsHistoryTable, EIdKey.clientId);
    }

    get getWithdrawalsPendingTable (): ITableCell[] {
        return tableParser(withdrawalsPendingHeader, this.withdrawalsHistoryTable, EIdKey.clientId);
    }

    get getWithdrawalsPagination (): IPagination {
        return this.withdrawalsPagination;
    }

    get getWithdrawalsIsLoaded (): boolean {
        return this.loaded;
    }

    @Mutation
    setHistoryTable (payload: clientKycFinancesInterfaces[]) {
        this.withdrawalsHistoryTable = payload;
    }

    @Mutation
    setWithdrawalsPagination (payload: IPagination) {
        this.withdrawalsPagination = payload;
    }

    @Mutation
    setLoadedState (payload: boolean) {
        this.loaded = payload;
    }

    @Action({ commit: 'setHistoryTable' })
    async loadWithdrawalsHistoryTable (query: IWithdrawalsQuery = null): Promise<clientKycFinancesInterfaces[]> {
        this.setLoadedState(false);
        query = handlerQueriesToFetch(query, ETableType.withdrawalsHistory);
        const { data, meta } =  await Rest.GET(urlPayments(query))
            .then(Rest.middleThen)
            .catch(Rest.simpleCatch)
            .finally(() => {
                this.setLoadedState(true);
                NotificationPrefabricated.cleanAllNotify();
            });
        this.setWithdrawalsPagination(meta);
        showNothingWasFound(data.length);
        return data;
    }

    @Action
    async loadWithdrawalsPendingTable (query: IWithdrawalsQuery = null): Promise<void> {
        this.loadWithdrawalsHistoryTable({
            [ETableFilterKeyWithdrawals.status_ids]: `${String(PaymentStatusEnum.pending)}, ${String(PaymentStatusEnum.preApproved)}`,
            ...query,
        });
    }

    @Action
    async handlerWithdrawalApprove (payload: IWithdrawalsOperationPayload): Promise<any> {
        return await Rest.POST(urlPaymentsPendingApprove(payload.accountId), { payment_id: payload.paymentId })
            .then(response => handlerRestResponse(response, NotificationPrefabricated.withdrawalsApprove))
            .catch(Rest.simpleCatch);
    }

    @Action
    async handlerWithdrawalReject (payload: IWithdrawalsOperationPayload): Promise<any> {
        return await Rest.POST(urlPaymentsPendingCancel(payload.accountId), {
            payment_id: payload.paymentId,
            reason: payload.reason,
        })
            .then(response => handlerRestResponse(response, NotificationPrefabricated.withdrawalsReject))
            .catch(Rest.simpleCatch);
    }

}
export const withdrawalsModule = getModule(Withdrawals);
