import Vue from 'vue';
import { EIconsSettingsAffiliateStatus, EIconsSettingsColor } from '~/settings/enums/status-icons-settings.enum';
export default Vue.extend({
    props: {
        option: {
            type: Object,
            required: true,
            default: function () {
                return {};
            }
        },
        className: {
            type: String,
        },
    },
    computed: {
        isIcon (): string {
            return this.option.id
                ? EIconsSettingsAffiliateStatus[this.option.id]
                : '';
        },
        isIconColor (): string {
            return this.isIcon ? EIconsSettingsColor[this.isIcon] : '';
        }
    }
});
