import Vue from 'vue';
import rowItemPropsMixin from '@/js/components/tables/mixins/table-cells-mixins/row-item-props-mixin';

export default Vue.extend({
    mixins: [
        rowItemPropsMixin,
    ],
    computed: {
        isLeadId (): number {
            return this.cell.value.id;
        },
        isActive (): boolean {
            return this.cell.value.is_on_webtrader;
        },
        colorIcon (): string {
            return this.isActive ? 'green' : 'red';
        }
    },
});
