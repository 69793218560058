import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '~/store';
import Rest from '~/helpers/Rest';
import { urlLegalDocument, urlLegalDocuments, urlLegalDocumentStatusUpdate } from '~/settings/ajax-urls';
import {
    IDocumentsData,
    IDocumentsHistory,
    IDocumentTypes,
    ISingleDocument,
    TDocumentTypeNames
} from '~/store/modules/documents/interfaces';
import { handlerRestResponseBlob } from '~/helpers/handler-rest-response';

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: 'legalDocuments',
})


class LegalDocuments extends VuexModule {
    documents: IDocumentsHistory = {} as IDocumentsHistory;
    documentTypes: IDocumentTypes[] = [];

    get getDocuments (): IDocumentsHistory {
        return this.documents;
    }

    get getDocumentTypes (): IDocumentTypes[] {
        return this.documentTypes;
    }

    get getDocumentOfType (): (name: TDocumentTypeNames)=>ISingleDocument[] {
        const { documents } = this;
        return function (typeName: TDocumentTypeNames): ISingleDocument[] {
            return documents[typeName];
        };
    }

    @Mutation
    mutationChangeDocuments (payload:  IDocumentsData) {
        const { type_mapping, ...rest } = payload;
        this.documents = rest;
        this.documentTypes = type_mapping;
    }

    @Action({ commit: 'mutationChangeDocuments' })
    actionLoadLegalDocuments (): Promise<{ data: IDocumentsData } | undefined> {
        return Rest.GET(urlLegalDocuments)
            .then(Rest.middleThen)
            .then((responseJSON) => responseJSON.data)
            .catch(Rest.simpleCatch);
    }

    @Action
    actionSettingStatusOfDocument (payload: {id: number, status_id: number}) {
        const { id, status_id } = payload;
        return Rest.PUT(urlLegalDocumentStatusUpdate(id), { status_id })
            .then((response) => {
                if (response.status === 200) {
                    this.actionLoadLegalDocuments();
                }
            })
            .catch(Rest.simpleCatch);
    }

    @Action
    actionLoadLegalDocument (payload: number) {
        Rest.GET(urlLegalDocument(payload))
            .then(handlerRestResponseBlob)
            .then(blob => {
                const url = URL.createObjectURL(blob);
                window.open(url, '_blank');
                URL.revokeObjectURL(url);
            });
    }

    // todo add loading functionality
}
export const LegalDocumentsModule = getModule(LegalDocuments);
