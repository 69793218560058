import { IConfig, ILeadsAmountRangeSelect, IConfigValue } from '~/settings/interfaces/config-default-interfaces';
import {
    ETableFilterKeyCampaigns,
    ETableFilterKeyUser,
    ETableFilterKeyWithdrawals,
    ETableFilterKeyLeads,
    ETableFilterKeyAffiliates,
    ETableFilterKeyAffiliatesReports,
    ETableFilterKeyPaymentHistory,
    ETableFilterKeySymbols,
} from '~/components/tables/enums/table-filter-key.enum';
import { ETableType } from '~/components/tables/enums/table-type.enum';
import { PaymentStatusEnum } from '~/settings/enums/payment-status-enum';
import { BonusPlanModule } from '~/store/modules/bonus-plan/bonus-plan';
import configsMixin from '~/components/mixins/configs-mixin';
import { ETableFilterNoCountry } from '~/components/tables/enums/table-filter-no-country.enum';
import { leadsAmountRangeSelect } from '~/settings/configs/leads-amount-range';
import { booleanOptions, booleanOptionsWithId } from '~/settings/configs/boolean-options';
import { campaignDisabledOptions } from '~/settings/configs/campaign-disabled-options';
import { LeadAccountsModule } from '~/store/modules/lead-accounts/lead-accounts';
import { PaymentRequisitesModule } from '~/store/modules/payment-requisites/payment-requisites';
import { SymbolsConfigsModule } from '~/store/modules/symbols-configs/symbols-configs';
import campaignMixin from '~/components/mixins/campaignMixin';

export default {
    mixins: [
        configsMixin,
        campaignMixin,
    ],
    methods: {
        isWithdrawalStatusSelectOption (table: string): IConfig[] {
            const options = this.getConfigPaymentStatuses;
            switch (table) {
            case ETableType.withdrawalsHistory:
                return options;
            case ETableType.withdrawalsPending:
                return options.filter((item: IConfig) =>
                    item.id === PaymentStatusEnum.pending ||
                    item.id === PaymentStatusEnum.preApproved
                );
            default:
                return options;
            }
        },
        filterOptions (key: string, table: string = ''): IConfig[] | ILeadsAmountRangeSelect[] | IConfigValue[] | string[] {
            switch (key) {
            case ETableFilterKeyWithdrawals.status_ids:
            case ETableFilterKeyPaymentHistory.status:
                // TODO - create a separate handler for each table
                if (table === ETableType.affiliates) {
                    return this.getConfigAffiliateStatuses;
                } else {
                    return this.isWithdrawalStatusSelectOption(table);
                }
            case ETableFilterKeyWithdrawals.client_status_id:
            case ETableFilterKeyLeads.verify_status_id:
            case ETableFilterKeyLeads.verify_status_crypto_id:
                return this.getConfigVerifyStatuses;
            case ETableFilterKeyPaymentHistory.operation: // type_id
                // TODO
                if (table === ETableType.affiliates) {
                    return this.getConfigAffiliateTypes;
                } else if (
                    table === ETableType.paymentHistory ||
                    table === ETableType.withdrawalsHistory ||
                    table === ETableType.withdrawalsPending
                ) {
                    return this.getConfigPaymentTypes;
                } else {
                    return [];
                }
            case ETableFilterKeyLeads.payment_type:
            case ETableFilterKeyPaymentHistory.type: // method_id
                return this.getConfigPaymentMethods;
            case ETableFilterKeyPaymentHistory.subtype:
                return PaymentRequisitesModule.getPaymentRequisitesSubTypeAvailable;
            case ETableFilterKeyPaymentHistory.source:
                return PaymentRequisitesModule.getPaymentRequisitesSourceAvailable;
            case ETableFilterKeyUser.country_id:
            case ETableFilterKeyLeads.residence_country_id:
            case ETableFilterKeyCampaigns.country_id:
            case ETableFilterKeyAffiliates.country:
            case ETableFilterKeySymbols.countries:
                // TODO
                return table === ETableType.leadsTable ?
                    [
                        {
                            id: ETableFilterNoCountry.noCountry,
                            name: 'No Country',
                        },
                        ...this.getConfigCountries
                    ] :
                    this.getConfigCountries;
            case ETableFilterKeyUser.language_a2:
            case ETableFilterKeyLeads.language_a2:
            case ETableFilterKeyCampaigns.language_a2:
            case ETableFilterKeyAffiliates.language:
                return this.getConfigLanguages;
            case ETableFilterKeyAffiliates.status:
                return this.getConfigAffiliateStatuses;
            case ETableFilterKeyUser.department:
                return this.getConfigDepartments;
            case ETableFilterKeyUser.activity:
            case ETableFilterKeyLeads.active:
                return this.getConfigLeadsFiltersConfigActivity;
            case ETableFilterKeyUser.bonus_plan:
            case ETableFilterKeyAffiliates.bonus:
                return BonusPlanModule.getSortedBonusPlanList;
            case ETableFilterKeyAffiliatesReports.bonus_plan:
                return this.getConfigBonusTypes;
            case ETableFilterKeyLeads.timezone_id:
                return this.getConfigTimezones;
            case ETableFilterKeyLeads.mode_type_ids:
                return this.getConfigAccountModeTypes;
            case ETableFilterKeyLeads.lead_status_id:
                return this.getConfigLeadStatuses;
            case ETableFilterKeyLeads.complicatedness_id:
                return this.getConfigComplicatedness;
            case ETableFilterKeyLeads.trading_type:
                return this.getConfigAccountProfitMethodTypes;
            case ETableFilterKeyLeads.mt_subject_type:
                return this.getConfigMTSubjectTypes;
            case ETableFilterKeyLeads.risk_id:
                return this.getConfigRisks;
            case ETableFilterKeyLeads.professional_level_id:
                return this.getConfigProfessionalLevels;
            case ETableFilterKeyLeads.legal_subject_id:
                return this.getConfigLegalSubjects;
            case ETableFilterKeyLeads.annual_investment:
                return this.getConfigLeadsFiltersConfigsAnnualInvestment;
            case ETableFilterKeyLeads.annual_income:
                return this.getConfigLeadsFiltersConfigsAnnualIncome;
            case ETableFilterKeyLeads.net_wealth:
                return this.getConfigLeadsFiltersConfigsNetWealth;
            case ETableFilterKeyLeads.savings_investments:
                return this.getConfigLeadsFiltersConfigsSavingsInvestments;
            case ETableFilterKeyLeads.target_market:
                return this.getConfigLeadsFiltersConfigTargetMarket;
            case ETableFilterKeyLeads.client_profitability:
                return this.getConfigLeadsFiltersConfigClientProfitability;
            case ETableFilterKeyLeads.employment_status_id:
                return this.getConfigEmploymentStatuses;
            case ETableFilterKeyLeads.is_test:
            case ETableFilterKeySymbols.is_allowed_on_website:
            case ETableFilterKeySymbols.icon:
                return booleanOptionsWithId;
            case ETableFilterKeyLeads.deposited:
            case ETableFilterKeyLeads.political_exposed:
                return this.getConfigLeadsFiltersConfigDeposited;
            case ETableFilterKeyLeads.completed_questionnaire:
            case ETableFilterKeyLeads.open_orders:
                return booleanOptions;
            case ETableFilterKeyCampaigns.is_disabled:
                return campaignDisabledOptions;
            case ETableFilterKeyLeads.total_deposit_amount:
            case ETableFilterKeyLeads.ftd_amount:
            case ETableFilterKeyLeads.ltd_amount:
            case ETableFilterKeyLeads.equity:
            case ETableFilterKeyLeads.free_margin:
                return leadsAmountRangeSelect;
            case ETableFilterKeyCampaigns.campaign_type:
                return this.getConfigAffiliatesCampaignsType;
            case ETableFilterKeyCampaigns.affiliate_name:
            case ETableFilterKeyLeads.affiliate:
                return this.getConfigAffiliates;
            case ETableFilterKeyPaymentHistory.account:
                return LeadAccountsModule.getAccounts.map(item => {
                    return {
                        ...item,
                        id: item.id,
                        name: `${item.account}`,
                    };
                });
            case ETableFilterKeyPaymentHistory.currency:
                return this.getConfigAccountCurrencies;
            case ETableFilterKeySymbols.trading_types:
                return SymbolsConfigsModule.getTradingTypes;
            case ETableFilterKeySymbols.exchanges:
                return SymbolsConfigsModule.getExchanges;
            case ETableFilterKeySymbols.sectors:
                return SymbolsConfigsModule.getSectors;
            case ETableFilterKeySymbols.industries:
                return SymbolsConfigsModule.getIndustries;
            case ETableFilterKeySymbols.products:
                return SymbolsConfigsModule.getProducts;
            case ETableFilterKeySymbols.currencies_base:
            case ETableFilterKeySymbols.currencies_profit:
                return this.getConfigCurrencies;
            case ETableFilterKeyLeads.campaign:
                return this.campaignList;
            default:
                return [];
            }
        }
    }
};
