import Vue from 'vue';
import configsMixin from '~/components/mixins/configs-mixin';
import { EAccountStatus, EAccountStatusIcons } from '~/settings/enums/account-status.enum';
import rowItemPropsMixin from '@/js/components/tables/mixins/table-cells-mixins/row-item-props-mixin';

export default Vue.extend({
    mixins: [
        configsMixin,
        rowItemPropsMixin,
    ],
    computed: {
        statusIcon (): string {
            return this.cell.value[this.cell.cellDataKey] >= EAccountStatus.closed
                ? EAccountStatusIcons[EAccountStatus[EAccountStatus.unverified]]
                : EAccountStatusIcons[EAccountStatus[this.cell.value[this.cell.cellDataKey]]];
        },
        statusName (): string {
            const status = this.getConfigVerifyStatuses.find(status => status.id === this.cell.value[this.cell.cellDataKey]);
            return status ? status.name : '-';
        },
        getColorCell (): string {
            return this.statusName.toLowerCase();
        }
    }
});
