import Vue from 'vue';
import { FinanceContentItemHeaderInterfaces } from './interfaces/finance-content-item-header-interfaces';
export default Vue.extend({
    props: {
        tabs: {
            type: Boolean
        },
        positionItem: {
            type: String,
            default: ''
        },
        addClass: {
            type: String
        },
    },
    data (): FinanceContentItemHeaderInterfaces {
        return {
            className: 'b-finance-content-item-header'
        };
    },
    computed: {
        isTabs (): Boolean {
            return this.tabs;
        },
        isItemExists (): Boolean {
            return !!this.$slots.item;
        }
    }

});
