import Rest from '~/helpers/Rest';
import { urlClick2call } from '~/settings/ajax-urls';
import { NotificationPrefabricated } from '~/helpers/modal-notification-services/modal-notification-services';

export default {
    methods: {
        clickCall (clientID: number, phone1st: boolean, primaryRoute: boolean): void {
            Rest.POST(urlClick2call, {
                clientID,
                phone1st,
                primaryRoute,
            })
                .then(Rest.middleJson)
                .then(res => {
                    if (res.status === 'false') {
                        NotificationPrefabricated.Error(res.message);
                    }
                    if (res.status === 'true') {
                        NotificationPrefabricated.Success(res.message);
                    }
                })
                .catch(Rest.simpleCatch);
        }
    }
};
