import { Action, Module, VuexModule, getModule, Mutation } from 'vuex-module-decorators';
import store from '~/store/index';
import Rest from '~/helpers/Rest';
import { urlClientsSearch } from '~/settings/ajax-urls';
import { ISearchMain } from '~/settings/interfaces/search-main-interfaces';
import { ISearchMainQuery } from '~/store/modules/search-main/interfaces/search-main-interfaces';
import { IPagination } from '~/settings/interfaces/pagination-interfaces';

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: 'SearchMain',
})

class SearchMain extends VuexModule {

    searchPagination: IPagination = {
        current_page: null,
        from: null,
        last_page: null,
        per_page: null,
        to: null,
        total: null,
    };

    get getSearchPagination (): IPagination {
        return this.searchPagination;
    }

    @Mutation
    setSearchPagination (payload: IPagination) {
        this.searchPagination = payload;
    }

    @Action
    async fetchSearchMain (payload: ISearchMainQuery): Promise<ISearchMain[]> {
        const { data, meta } = await Rest.GET(urlClientsSearch(payload))
            .then(Rest.middleThen)
            .catch(Rest.simpleCatch);
        this.setSearchPagination(meta);
        return data;
    }
}

export const SearchMainModule = getModule(SearchMain);
