import Vue from 'vue';
import { PaymentRequisitesModule } from '~/store/modules/payment-requisites/payment-requisites';
import { EPaymentMethodName, EPaymentMethodTitle } from '~/settings/enums/payment-methods.enum';
import { IPaymentRequisiteResponse } from '~/store/modules/payment-requisites/payment-requisites-interfaces';
import { IPaymentEditModal } from '~/components/leadsItem/FinanceDetails/payment-methods/interfaces/payment-edit-modal-interfaces';
import { NotificationPrefabricated } from '~/helpers/modal-notification-services/modal-notification-services';
import { ETableType } from '~/components/tables/enums/table-type.enum';
import { tableParser } from '@/js/helpers/table-parser';
import { PaymentStatusEnum } from '@/js/settings/enums/payment-status-enum';
import { ITableHeaderColumn } from '@/js/components/tables/interfaces/table-header-column.interfaces';
import modalLayout from '~/components/modals/root-modal-layout/root-modal-layout.vue';
import { ELeadsPaymentsModal } from '~/settings/enums/leads-payments-modal.enum';
import { isPermissionFinance } from '~/helpers/user-permission';

export default Vue.extend({
    components: {
        modalLayout,
    },
    props: {
        scrollTop: {
            type: Number,
        }
    },
    data () {
        return {
            clickedOutside: 0,
            ePaymentMethodName: EPaymentMethodName,
            eTableType: ETableType,
            statusCheckbox: false,
        };
    },
    computed: {
        isPermissionFinance,
        getPaymentRequisitesIsLoaded (): boolean {
            return PaymentRequisitesModule.getPaymentRequisitesIsLoaded;
        },

        // headers
        tableHeaderBankAccounts (): ITableHeaderColumn[] {
            return PaymentRequisitesModule.getBankAccountsHeader;
        },
        tableHeaderCreditCards (): ITableHeaderColumn[] {
            return PaymentRequisitesModule.getCreditCardsTableHeaders;
        },
        tableHeaderEWallet (): ITableHeaderColumn[] {
            return PaymentRequisitesModule.getEWalletTableHeaders;
        },
        tableHeaderCrypto (): ITableHeaderColumn[] {
            return PaymentRequisitesModule.getCryptoHeaders;
        },

        // rows
        tableRowsBankAccounts (): ITableHeaderColumn[] {
            return tableParser(this.tableHeaderBankAccounts, this.isPaymentRequisitesWireTransfer);
        },
        tableRowsCreditCards (): ITableHeaderColumn[] {
            return tableParser(this.tableHeaderCreditCards, this.isPaymentRequisitesCreditCard);
        },
        tableRowsEWallet (): ITableHeaderColumn[] {
            return tableParser(this.tableHeaderEWallet, this.isPaymentRequisitesEWallet);
        },
        tableRowsCrypto (): ITableHeaderColumn[] {
            return tableParser(this.tableHeaderCrypto, this.isPaymentRequisitesCrypto);
        },

        // sort on types
        isPaymentRequisitesWireTransfer (): IPaymentRequisiteResponse[] {
            return PaymentRequisitesModule.getPaymentRequisites
                .filter(paymentMethod => paymentMethod?.method_id === EPaymentMethodName.wireTransfer
                );
        },
        isPaymentRequisitesCreditCard (): IPaymentRequisiteResponse[] {
            return PaymentRequisitesModule.getPaymentRequisites
                .filter(paymentMethod => paymentMethod?.method_id === EPaymentMethodName.creditCard
                );
        },
        isPaymentRequisitesEWallet (): IPaymentRequisiteResponse[] {
            return PaymentRequisitesModule.getPaymentRequisites
                .filter(paymentMethod => paymentMethod?.method_id === EPaymentMethodName.eWallet
                );
        },
        isPaymentRequisitesCrypto (): IPaymentRequisiteResponse[] {
            return PaymentRequisitesModule.getPaymentRequisites
                .filter(paymentMethod => paymentMethod?.method_id === EPaymentMethodName.cryptoWallet
                );
        },

        // exists
        isPaymentRequisitesWireTransferExist (): Boolean {
            return this.isPaymentRequisitesWireTransfer.length !== 0;
        },
        isPaymentRequisitesCreditCardExist (): Boolean {
            return this.isPaymentRequisitesCreditCard.length !== 0;
        },
        isPaymentRequisitesEWalletExist (): Boolean {
            return this.isPaymentRequisitesEWallet.length !== 0;
        },
        isPaymentRequisitesCryptoExist (): Boolean {
            return this.isPaymentRequisitesCrypto.length !== 0;
        },

        isNoPaymentMethods (): Boolean {
            return !this.isPaymentRequisitesWireTransferExist
                && !this.isPaymentRequisitesCreditCardExist
                && !this.isPaymentRequisitesEWalletExist
                && !this.isPaymentRequisitesCryptoExist;
        },
        elementWithScroll () {
            return this.$refs.paymentMethodsTableScroll
                .closest('.modal-content__content-scroll-height');
        },
    },
    mounted () {
        this.setScrollTop();
    },
    methods: {
        setScrollTop (): void {
            this.elementWithScroll.scrollTop = this.scrollTop;
        },
        emitEditPaymentMethod (): void {
            this.$emit('editPaymentMethod', this.elementWithScroll.scrollTop);
        },
        findPaymentItem (id: number): IPaymentRequisiteResponse {
            return PaymentRequisitesModule.getPaymentRequisites
                .find((item: IPaymentRequisiteResponse)  => item.id === id);
        },
        openEditPayment (payload): void {
            this.$emit('changeModal', {
                name: ELeadsPaymentsModal.editPayment,
                payload
            });
        },
        editBankingDetailItem (id: number): void {
            this.emitEditPaymentMethod();

            const payload = {
                ...this.findPaymentItem(id),
                paymentMethodId: EPaymentMethodName.wireTransfer,
            };

            this.openEditPayment(payload);
        },
        editCrypto (id: number): void {
            this.emitEditPaymentMethod();

            const payload: IPaymentEditModal = {
                ...this.findPaymentItem(id),
                paymentMethodId: EPaymentMethodName.cryptoWallet,
            };

            this.openEditPayment(payload);
        },
        async changeStatus (payload: IPaymentRequisiteResponse) {
            const formattedPayload = {
                id: payload.id,
                status_id: payload.status_id,
            };
            await PaymentRequisitesModule.editPaymentRequisite(formattedPayload);
            await PaymentRequisitesModule.loadPaymentRequisites();
        },

        async handleRemovePayment (payload: IPaymentRequisiteResponse): Promise<void> {
            const isMethodName = EPaymentMethodName[payload.method_id];

            await NotificationPrefabricated.deletePaymentRequisites(
                payload.id, EPaymentMethodTitle[isMethodName]
            );
        },
        changeStatusCheckbox (): void {
            this.statusCheckbox = true;
        },
        changeModal (): void {
            this.emitEditPaymentMethod();

            this.$emit('changeModal', {
                name: ELeadsPaymentsModal.addPayment,
            });
        },
        approve (payload: IPaymentRequisiteResponse): void {
            payload.status_id = PaymentStatusEnum.approved;
            this.changeStatus(payload);
            NotificationPrefabricated.cryptoWalletSuccess();
        },
        reject (payload: IPaymentRequisiteResponse): void {
            payload.status_id = PaymentStatusEnum.cancelled;
            this.changeStatus(payload);
            NotificationPrefabricated.cryptoWalletDecline();
        },
    },
});
