import Vue from 'vue';
import modalsMixin from '~/components/mixins/modals';
import modalLayout from '~/components/modals/root-modal-layout/root-modal-layout.vue';
import AppInput from '~/components/parts/app-input/app-input.vue';
import AppMultiSelect from '~/components/parts/app-multiselect/app-multiselect.vue';
import fieldModelMixin from '~/components/mixins/modelMixins/fieldModelMixin';
import appPhone from '~/components/parts/app-phone/app-phone.vue';
import configsMixin from '~/components/mixins/configs-mixin';
import { ELegalSubject } from '~/settings/enums/legal-subject.enum';
import { BonusPlanModule } from '~/store/modules/bonus-plan/bonus-plan';
import { IBonusPlan } from '~/store/modules/bonus-plan/interfaces/bonus-plan-interfaces';
import { IAffiliateCreate } from '~/store/modules/affiliates/interfaces/affiliates-interfaces';
import { AffiliatesPageModule } from '~/store/modules/affiliates/affiliates-page';
import { parseDate } from '~/helpers/common';
import { mapActions } from 'vuex';
import { names as affiliatesConfig } from '~/store/modules/configs/affiliates';
import { ICountries, IDefaultConfig } from '~/store/modules/configs/interfaces/configs-interface';
import datePickerMixin from '~/components/mixins/date-picker-mixin';
import { ECountriesId } from '~/settings/enums/countries.enum';
import { ELanguages } from '~/settings/enums/languages.enum';

export default Vue.extend({
    components: {
        modalLayout,
        AppInput,
        AppMultiSelect,
        appPhone,
    },
    mixins: [
        modalsMixin,
        fieldModelMixin,
        configsMixin,
        datePickerMixin,
    ],
    data () {
        return {
            type: null,
            full_name: '',
            dob: null,
            email: '',
            phone_mobile_country: null,
            phone_mobile: '',
            country: null,
            language: null,
            bonus_plan: null,
            bonus_name: null,
            status: null,
            website: '',
            representative_name: '',
        };
    },
    computed: {
        isBusinessType (): boolean {
            return this.type?.id === ELegalSubject.business;
        },
        getBonusPlanList (): IBonusPlan[] {
            return BonusPlanModule.getBonusPlanList;
        },
        isAffiliateNameLabel (): string {
            return this.isBusinessType ? 'Company Name' : 'Full Name';
        },
        isRepresentativeNameValidation (): string {
            return this.isBusinessType ? 'required' : '';
        },
    },
    created () {
        this.preSelectValue();
    },
    methods: {
        // TODO
        ...mapActions({
            handleFetchAffiliates : affiliatesConfig.actions.handleFetch,
        }),
        async handlerSubmitForm (): Promise<void> {
            const isValid = await this.$refs.observer.validate();
            this.isDateError = !this.dob ? true : false;
            if (isValid && !this.isDateError) {
                let payload = {
                    full_name: this.full_name,
                    email: this.email,
                    country_id: this.country.id,
                    language_a2: this.language.A2,
                    website: this.website,
                    phone_mobile_country_id: this.phone_mobile_country.id,
                    phone_mobile: Number(this.phone_mobile),
                    bonus_id: this.bonus_name.id,
                    status_id: this.status.id,
                    type_id: this.type.id,
                    dob: parseDate(this.dob, 'YYYY-MM-DD').format('YYYY-MM-DD'),
                };
                if (this.isBusinessType) {
                    payload = {
                        ...payload,
                        representative_name: this.representative_name,
                    } as IAffiliateCreate;
                }
                this.onSubmitForm(payload);

            }
        },
        changePhoneMobile (value: string) {
            this.phone_mobile = value;
        },
        async onSubmitForm (payload: IAffiliateCreate): Promise<void> {
            await AffiliatesPageModule.createAffiliate(payload)
                .then(response => {
                    if (response) {
                        this.closeModal();
                        AffiliatesPageModule.loadAffiliates(this.$route.query);
                        // TODO
                        this.handleFetchAffiliates();
                    }
                });
        },
        setRepresentativeName (payload: string): void {
            if (!this.isBusinessType) {
                this.representative_name = payload;
            }
        },
        setPhoneMobileCountry (event: ICountries): void {
            this.phone_mobile_country = event;
        },
        selectBonusType (event: IDefaultConfig): void {
            this.bonus_plan = event;
        },
        selectBonusPlan (event: IBonusPlan): void {
            this.bonus_plan = event.bonus_type;
        },
        preSelectValue (): void {
            this.country = this.getConfigCountries.find(country => country.id === ECountriesId.cyprus);
            this.phone_mobile_country = this.country;
            this.language = this.getConfigLanguages.find(language => language.A2 === ELanguages.english);
        },
    },
});
