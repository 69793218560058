import appInput from '~/components/parts/app-input/app-input.vue';
import appMultiSelect from '~/components/parts/app-multiselect/app-multiselect.vue';
import configsMixin from '~/components/mixins/configs-mixin';
import { COLUMN_CITIZENSHIP_IDS, COLUMN_TINS } from '~/store/modules/_columnNames';
import { ICountries } from '~/store/modules/configs/interfaces/configs-interface';

export default {
    components : {
        appInput,
        appMultiSelect
    },
    mixins : [configsMixin],
    props : {
        index: {
            type: Number,
            required: true
        },
        item: {
            type: [Number, Array, Object],
            required : false,
            default: {},
        },
        isOpen: {
            type : Boolean,
            required: true,
        },
        canAdd: {
            type : Boolean,
            required : false,
        },
        typeList: {
            type: String,
            required: true,
        }
    },
    computed : {
        concatValue (): string {
            if (this.item) {
                return this.getBooleanByTypeList
                    ? `${this.item.display} ${this.item.custom}`
                    : this.item.display;
            }

            return 'N/A';
        },
        getBooleanByTypeList (): boolean {
            return this.typeList === COLUMN_TINS;
        },
        isPossibleRemoveFirstItem (): boolean {
            return this.getBooleanByTypeList || this.index !== 0;
        },
        custom (): string {
            return this.item?.custom || '';
        },
        isPositionPlusButton (): boolean {
            return this.typeList === COLUMN_CITIZENSHIP_IDS;
        },
    },
    methods : {
        onSelect (selected: ICountries): void {
            this.$emit('update', this.index, {
                name: selected.name,
                value: selected.id,
            });
        },
        onInput (value: string): void {
            this.$emit('update', this.index, value);
        }
    }
};
