import Vue from 'vue';
import { ModalsModule } from '~/store/modules/modals';
import rowItemPropsMixin from '@/js/components/tables/mixins/table-cells-mixins/row-item-props-mixin';

export default Vue.extend({
    mixins: [
        rowItemPropsMixin,
    ],
    methods: {
        showEditAffiliateModal (): void {
            return;
        },
        removeAffiliate (): void {
            ModalsModule.openModal({
                modalType: 'deleteUserModal',
                props: {
                    title: 'Remove Affiliate',
                    body: {
                        data: 'Are you sure you want to delete this Affiliates?'
                    },
                    isFooter: true,
                    isHeader: true
                },
                addClass: ''
            });
        }
    }
});
