export enum ESymbolsPayloadKey {
    file = 'file',
    type = 'type',
    subtype = 'subtype',
    trading_type = 'trading_type',
    exchange = 'exchange',
    symbol_mt5 = 'symbol_mt5',
    display_symbol_name = 'display_symbol_name',
    description_generic = 'description_generic',
    description_detail = 'description_detail',
    sector = 'sector',
    industry = 'industry',
    product = 'product',
    isin = 'isin',
    spread_novice = 'spread_novice',
    spread_experience = 'spread_experience',
    dividend = 'dividend',
    dividend_date = 'dividend_date',
    ex_div_date = 'ex_div_date',
    trading_hours_gmt2 = 'trading_hours_gmt2',
    is_allowed_on_website = 'is_allowed_on_website',
}
