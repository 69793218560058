import { Module, VuexModule, getModule, Action, Mutation } from 'vuex-module-decorators';
import store from '~/store/index';
import { reportsTableHeader } from '~/components/tables/_headers/report';
import Rest from '~/helpers/Rest';
import { urlReports, urlReportsExports } from '~/settings/ajax-urls';
import { ITableHeaderColumn } from '~/components/tables/interfaces/table-header-column.interfaces';
import { IReports, IReportExportPayload, IReportCreatePayload } from './reports-interfaces';
import { IPagination } from '~/settings/interfaces/pagination-interfaces';
import { ITableFilterQuery } from '~/components/tables/interfaces/table-filter-query.interfaces';
import { EReportsExportFile } from './enums/reports.enums';
import { NotificationPrefabricated } from '~/helpers/modal-notification-services/modal-notification-services';
import { handlerRestResponseBlob, handlerRestResponse } from '~/helpers/handler-rest-response';
import { ProfileModule } from '~/store/modules/profile/profile';
import { parseDate } from '~/helpers/common';

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: 'reports',
})
class Reports extends VuexModule {
    reports: IReports[] = [];
    pagination: IPagination = {
        current_page: null,
        from: null,
        last_page: null,
        per_page: null,
        to: null,
        total: null,
    };
    loaded: boolean = false;

    get getReportsHeader (): ITableHeaderColumn[] {
        return reportsTableHeader;
    }

    get getReports (): IReports[] {
        return this.reports;
    }

    get getPagination (): IPagination {
        return this.pagination;
    }

    get getReportsIsLoaded (): boolean {
        return this.loaded;
    }

    @Mutation
    setReports (payload: IReports[]) {
        this.reports = payload;
    }

    @Mutation
    setPagination (payload: IPagination) {
        this.pagination = payload;
    }

    @Mutation
    setLoadedState (payload: boolean) {
        this.loaded = payload;
    }

    @Action
    async loadReports (payload: ITableFilterQuery = null): Promise<void> {
        this.setLoadedState(false);
        const { data, meta } = await Rest.GET(urlReports(payload))
            .then(Rest.middleThen)
            .catch(error => {
                Rest.simpleCatch(error);
            })
            .finally(() => {
                NotificationPrefabricated.cleanAllNotify();
            });
        const result = data.map((item) => ({
            ...item,
            professional_level: item.client?.professional_level,
        }));
        this.setReports(result);
        this.setPagination(meta);
        this.setLoadedState(true);
    }

    @Action
    async createReports (payload: IReportCreatePayload): Promise<void> {
        const { data } = await Rest.POST(urlReports(payload))
            .then(response => {
                return handlerRestResponse(response, NotificationPrefabricated.createdReportSuccess);
            })
            .catch(error => {
                Rest.simpleCatch(error);
            });
        ProfileModule.setReportTemplate(data);
    }

    @Action
    async exportReports (payload: IReportExportPayload): Promise<void> {
        const currentReport = ProfileModule.getReportTemplates.find(item => item.id === payload.template_id);
        const currentTime = parseDate(new Date(), 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
        const fileName = `${currentReport.name}_on_${payload.day}_exported_on_${currentTime}.${ EReportsExportFile.type}`
            .replace(/\s/g, '_');
        if (!payload.ids || payload.ids.length > EReportsExportFile.largeNumberColumns) {
            NotificationPrefabricated.reportExportProcess();
        }
        await Rest.GET(urlReportsExports(payload))
            .then(response => {
                // TODO
                const reader = response.body.getReader();
                return new ReadableStream({
                    start (controller) {
                        return pump();
                        function pump () {
                            return reader.read().then(({ done, value }) => {
                                if (done) {
                                    controller.close();
                                    return;
                                }
                                // Enqueue the next data chunk into our target stream
                                controller.enqueue(value);
                                return pump();
                            });
                        }
                    }
                });
            })
            // Create a new response out of the stream
            .then((stream) => new Response(stream))
            .then((response) => {
                return handlerRestResponseBlob(response, NotificationPrefabricated.reportExportSuccess);
            })
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                a.remove();
            })
            .catch(error => {
                Rest.simpleCatch(error);
            });
    }
}

export const ReportsModule = getModule(Reports);

