// Imports
import Vue from 'vue';
import { DashboardModule } from '~/store/modules/dashboard/dashboard';
import {
    IDashboardSummaryStatisticFormatted,
    IDashboardSummaryStatisticItem
} from '~/store/modules/dashboard/interfaces';
import { EDashboardClientsItemsKey } from '~/store/modules/dashboard/enums/dashboard-payments-items.enum';
import { ESocialIconsDev, ESocialIconsProd } from './social-icons.enum';
import { isEnvModeTypeDevelopment } from '~/helpers/is-env-mode-type';
import dashboardSummaryMixin from '~/components/mixins/dashboard-summary-mixin';
import { commaToValue } from '~/helpers/format';
// Exports
export default Vue.extend({
    mixins: [
        dashboardSummaryMixin,
    ],
    computed: {
        getDashboardCampaign (): IDashboardSummaryStatisticFormatted[] {
            return this.socialDataFormatted(
                DashboardModule.getDashboardClientsWidgets[EDashboardClientsItemsKey.client_count_by_social_media],
            );
        },
    },
    methods: {
        socialDataFormatted (payload: IDashboardSummaryStatisticItem[]):IDashboardSummaryStatisticFormatted [] {
            const icons = isEnvModeTypeDevelopment() ?
                ESocialIconsDev :
                ESocialIconsProd;
            return payload?.map(item => ({
                ...item,
                name: '',
                sign: icons[item.id],
                value: commaToValue(String(item.value)),
            }));
        },
    }
});
