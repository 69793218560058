export enum EBonusPlanType {
    default = 0,
    cpa = 1,
    cpl = 2,
    cryptoRebate = 3,
    hybrid = 4,
    revShare = 5
}

export enum EBonusPlanUrl {
    cpa = 1,
    cpl = 2,
    'crypto-rebate' = 3,
    hybrid = 4,
    'rev-share' = 5
}
