import { ComplianceUploadedDocumentsModule } from '~/store/modules/documents/uploadedDocuments';
import { DocumentsTypesModule } from '~/store/modules/documents/documents-types';
import { UploadedDocumentsTypeIdsEnum } from '~/components/uploaded-documents/uploaded-documents.enum';
import { automaticEmailsModule } from '~/store/modules/emails/automatic-emails';
import { ModalsModule } from '~/store/modules/modals';
import { getTimeStampFromDate } from '~/helpers/common';
import { EUploadedDocumentsStatuses } from '~/settings/enums/uploaded-documents-statuses.enum';
import { IClientDocument } from '~/store/modules/documents/interfaces/upload-documents-interfaces';
import { AutomaticEmailsInterfaces } from '~/store/modules/emails/emails-interfaces';
import { IAffiliatesDocument } from '~/store/modules/affiliates/interfaces/affiliates-documents-interfaces';

export default {
    computed: {
        isAutoEmailCategoryActive (): boolean {
            return DocumentsTypesModule.getterDocumentsTypeSelected === UploadedDocumentsTypeIdsEnum.AutoEmails;
        },
        clientDocuments (): IClientDocument[] {
            return ComplianceUploadedDocumentsModule.getUploadedDocuments;
        },
        sortedClientDocuments (): IClientDocument[] {
            return ComplianceUploadedDocumentsModule.getUploadedDocuments
                .sort((x, y) => {
                    const xDatetimeStamp = getTimeStampFromDate(
                        x.uploaded_at.date,
                        x.uploaded_at.time
                    );

                    const yDatetimeStamp = getTimeStampFromDate(
                        y.uploaded_at.date,
                        y.uploaded_at.time
                    );

                    return ((x.status_id === EUploadedDocumentsStatuses.pending
                        && y.status_id !== EUploadedDocumentsStatuses.pending) && -1)
                        || ((x.status_id !== EUploadedDocumentsStatuses.pending
                        && y.status_id === EUploadedDocumentsStatuses.pending) && 1)
                        || yDatetimeStamp - xDatetimeStamp;
                });
        },
        emailsDocuments (): AutomaticEmailsInterfaces[] {
            return automaticEmailsModule.getAutomaticEmailsList;
        },
        uploadedDocuments (): AutomaticEmailsInterfaces[] | IClientDocument[] {
            return this.isAutoEmailCategoryActive ?
                this.emailsDocuments :
                this.sortedClientDocuments;
        },

    },
    created (): void {
        this.loadDocuments();
    },
    methods: {
        loadDocuments (): void {
            const leadId = this.$route.params.id;
            if (leadId) {
                ComplianceUploadedDocumentsModule.receiveUploadedDocuments({ leadId: leadId });
                automaticEmailsModule.setAutomaticEmailsList(this.$route.params.id);
            }
        },
        createClientDocument (document: FormData): void {
            ComplianceUploadedDocumentsModule.uploadDocument(document);
        },
        selectDocument (document: IAffiliatesDocument): void {
            if (this.isAutoEmailCategoryActive) {
                this.showEmailModal(document);
            } else {
                this.showClientDocumentModal(document);
            }
        },
        getClientDocumentFile (documentId: number): void {
            const document = this.uploadedDocuments
                .find(item => item.id === documentId);
            if (!document?.url) {
                const clientId = this.$route.params.id;
                ComplianceUploadedDocumentsModule.clientDocumentsFile({
                    clientId,
                    documentId,
                });
            }
        },
        getEmailDocumentFile (documentId: number): void {
            const document = this.emailsDocuments
                .find(item => item.id === documentId);
            if (!document?.url) {
                const clientId = this.$route.params.id;
                automaticEmailsModule.automaticEmailDocumentsFile({
                    clientId,
                    documentId,
                });
            }
        },
        changeType (event: number): void {
            DocumentsTypesModule.actionChangeDocumentsType(event);
        },
        showClientDocumentModal (document: IAffiliatesDocument): void {
            ModalsModule.openModal({
                modalType: 'docModal',
                props: {
                    title: '',
                    body: document,
                    isFooter: false,
                    isHeader: false
                },
                modalSettings: {
                    props: {
                        minWidth: ModalsModule.modalWidth.xl,
                    },
                },
                addClass: 'docModal'
            });
        },
        showEmailModal (document: IAffiliatesDocument): void {
            ModalsModule.openModal({
                modalType: 'lightBox',
                props: {
                    title: '',
                    body: document,
                    isFooter: false,
                    isHeader: false,
                    isScroll: true,
                },
                modalSettings: {
                    props: {
                        minWidth: ModalsModule.modalWidth.lg,
                    },
                },
                addClass: 'light-box'
            });
        }
    },
};
