export const AccountingEmployeesItems = [
    {
        id: 2,
        fullName: 'Sergey Martyshko',
        department: 'Developers',
        role: 'Front-end',
        bonusPlan: 'Client Support',
        checked: false,
        status: {
            id: 1,
            name: 'Unverified',
        },
    },
    {
        id: 3,
        fullName: 'Boris Britva',
        department: 'Developers',
        role: 'Back-end',
        bonusPlan: 'Client Support',
        checked: false,
        status: {
            id: 3,
            name: 'Verified',
        },
    },
    {
        id: 5,
        fullName: 'Alex Forgot',
        department: 'Client + Reletionship Agent',
        role: 'Manager',
        bonusPlan: 'Client + Reletionship Agent',
        checked: false,
        status: {
            id: 2,
            name: 'Pending',
        },
    }
];
