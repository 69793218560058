import { Action, Module, VuexModule, getModule } from 'vuex-module-decorators';
import store from '~/store/index';
import Rest from '~/helpers/Rest';
import { urlSearchLive } from '~/settings/ajax-urls';
import { IDefaultConfigValue } from '~/store/modules/configs/interfaces/configs-interface';
import { ISearchLivePayload } from '~/store/modules/search-live/interfaces/search-live-interface';

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: 'SearchLive',
})

class SearchLive extends VuexModule {

    @Action
    async searchLive (payload: ISearchLivePayload): Promise<IDefaultConfigValue[]> {
        const { data } = await Rest.GET(urlSearchLive(payload.type,  {
            ... payload.query,
            column: payload.column,
        }))
            .then(Rest.middleThen)
            .catch(Rest.simpleCatch);
        return data;
    }
}

export const SearchLiveModule = getModule(SearchLive);
