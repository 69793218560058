import Vue from 'vue';
import { CryptoWalletInterfaces } from './interfaces/crypto-wallet-interfaces';
import addPaymentTab from '~/components/mixins/add-payment-tab';
import formsHelper from '~/components/mixins/forms-helper';
import currencyHelper from '~/components/mixins/currency-helper';
import financeEditModalForms from '~/components/mixins/finance-edit-modal-forms';
import paymentMethodFormsMixin from '../payment-method-forms-mixin';

export default Vue.extend({
    mixins: [
        addPaymentTab,
        formsHelper,
        currencyHelper,
        financeEditModalForms,
        paymentMethodFormsMixin,
    ],
    props: {
        payload: {
            type: Object,
        }
    },
    data (): CryptoWalletInterfaces {
        return {
            method_id: null,
            crypto_wallet: '',
            currency: null,
            owner_name: '',
            id: null,
            currency_id: null,
        };
    },
    mounted () {
        if (this.payload) {
            this.setIsCurrency();
        }
    },
    methods: {
        setIsCurrency (): void {
            this.isCurrency = this.currency;
        }
    }
});
