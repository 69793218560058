import Vue from 'vue';
import { ModalsModule } from '~/store/modules/modals';
import { EmailsTemplatesModule } from '~/store/modules/emails/emails-templates';


export default Vue.extend({
    props: {
        template: {
            type: Object,
            required: true
        }
    },
    methods: {
        editTemplate () {
            ModalsModule.openModal({
                modalType: 'emailTemplates',
                props: {
                    title: '',
                    body: {
                        templateName: this.template.title
                    },
                    isHeader: false,
                    isFooter: false
                },
                modalSettings: {
                    props: {
                        minWidth: ModalsModule.modalWidth.lg,
                    },
                },
                addClass: 'modal-emails'
            });
        },
        deleteTemplate (event: MouseEvent) {
            ModalsModule.openModal({
                modalType: ModalsModule.modalType.actionModal,
                props: {
                    body: {
                        text: 'Are you sure you want to delete the selected template?',
                    },
                    isHeader: true,
                    isFooter: false,
                    confirm: () => {
                        EmailsTemplatesModule.deleteEmailTemplates(this.template.id);
                    },
                },
                modalSettings: {
                    props: {
                        minWidth: ModalsModule.modalWidth.xs,
                    },
                    positionModal: {
                        clientX: event.clientX,
                        clientY: event.clientY,
                        modalWidth: ModalsModule.modalWidth.xs,
                    }
                },
            });
        }
    }
});
