import { IConfigs } from '~/store/modules/configs/interfaces/configs-interface';
import { handlerConfigItemDate } from './handler-config-item-data';

export const handlerConfigDate = (payload: IConfigs): IConfigs => {
    const AllConfigs = {} as IConfigs;

    for (const config in payload) {
        AllConfigs[config] = handlerConfigItemDate(config, payload[config]);
    }

    return AllConfigs;
};
