import { render, staticRenderFns } from "./text-status.html?vue&type=template&id=37260a21&lang=html&"
import script from "./text-status.ts?vue&type=script&lang=ts&"
export * from "./text-status.ts?vue&type=script&lang=ts&"
import style0 from "./text-status.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports