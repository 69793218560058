// Imports
import Vue from 'vue';
import { AppAccountStatusSelectInterfaces } from './interfaces/app-account-status-select-interfaces';
// Exports
export default Vue.extend({
    data (): AppAccountStatusSelectInterfaces {
        return {
            className: 'b-account-status-select',
            selectedOption: [],
            options: [
                {
                    name: 'Verified',
                    value: 'verified',
                    image: '/img/clients/verified.svg'
                },
                {
                    name: 'Pending',
                    value: 'pending',
                    image: '/img/clients/pending.svg'
                },
                {
                    name: 'Terminated',
                    value: 'terminated',
                    image: '/img/clients/terminated.svg'
                }
            ]

        };
    },
    methods: {
        select (event: Object) {
            this.$emit('select', event);
        }
    }
});
