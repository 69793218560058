import {
    names,
    getterFundingMethods,
} from '~/store/modules/client-kyc/client-kyc';
import {
    COLUMN_ID,
    COLUMN_FNAME,
    COLUMN_LNAME,
    COLUMN_MNAME,
    COLUMN_EMAIL,
    COLUMN_PHONE_MOBILE_COUNTRY,
    COLUMN_CITIZENSHIP_IDS,
    COLUMN_CITIZENSHIP_EQUAL_RESIDENCE,
    COLUMN_RESIDENCE_COUNTRY_ID,
    COLUMN_PHONE_MOBILE,
    COLUMN_PHONE_SECOND,
    COLUMN_RESIDENCE_PLACE,
    COLUMN_RESIDENCE_ADDRESS,
    COLUMN_RESIDENCE_ZIP,
    COLUMN_TIN_ABSENCE_REASON_VALUE,
    COLUMN_USA_TIN,
    COLUMN_POLITICAL_EXPOSED,
    COLUMN_IDENTITY_NUMBER,
    COLUMN_DRIVING_LICENSE,
    COLUMN_PASSPORT,
    COLUMN_COMPANY_NAME,
    COLUMN_EMPLOYMENT_VALUE,
    COLUMN_INDUSTRY_VALUE,
    COLUMN_FUND_TYPE_IS_BANK_TRANSFER,
    COLUMN_FUND_TYPE_BANK_NAME,
    COLUMN_FUND_TYPE_BANK_LOCATION,
    COLUMN_FUND_TYPE_IS_CARD,
    COLUMN_FUND_TYPE_IS_E_WALLETS,
    COLUMN_ANSWERS,
    COLUMN_AQM_ON_BEHALF_OF,
    COLUMN_AQM_ON_BEHALF_OF_CAPACITY,
    COLUMN_CHECKBOX_CONSENTS,
    COLUMN_AWARENESS_MESSAGES,
    COLUMN_HEARD_FROM_ID,
    COLUMN_PRO_REQUEST_TYPE_ID,
    AS_COLUMN_TITLE,
    AS_COLUMN_LEGAL_SUBJECT,
    AS_COLUMN_LEGAL_SUBJECT_ID,
    AS_COLUMN_BIRTH_COUNTRY_ID,
    AS_COLUMN_PHONE_MOBILE_COUNTRY,
    AS_COLUMN_PHONE_SECOND_COUNTRY,
    AS_COLUMN_PHONE_SECOND_COUNTRY_ID,
    AS_COLUMN_TIN_ABSENCE_REASON,
    COLUMN_EMPLOYMENT_STATUS_ID,
    AS_COLUMN_INDUSTRY_ID,
    AS_COLUMN_FUND_SOURCE_ID,
    AS_COLUMN_VERIFY_STATUS,
    AS_COLUMN_HEARD_FROM_ID,
    AS_COLUMN_RISK,
    AS_COLUMN_LEAD_STATUS,
    AS_COLUMN_COMPLICATEDNESS,
    AS_COLUMN_PROFESSIONAL_LEVEL,
    getBooleanOptions,
    COLUMN_IS_FATCA,
    AS_COLUMN_APPROVED_DEPOSIT_AMOUNT,
    COLUMN_VERIFY_STATUS_CRYPTO,
    COLUMN_VERIFY_STATUS_CRYPTO_ID,
    COLUMN_INCORPORATION_COUNTRY_ID,
    COLUMN_BUSINESS_ADDRESS,
    COLUMN_BUSINESS_PHONE,
    COLUMN_BUSINESS_PHONE_COUNTRY_ID,
    COLUMN_IS_TIN_AVAILABLE,
    COLUMN_IS_TIN_AVAILABLE_STRING,
    COLUMN_ASSESSMENT_QUESTIONS,
    COLUMN_INVESTMENT_QUESTIONS,
    COLUMN_FINANCIAL_QUESTIONS,
    COLUMN_COMPLETE_QUESTIONNAIRE_AT,
    COLUMN_VERIFIED_STATUS_BY_USER,
    COLUMN_CAMPAIGN_NAME,
} from '~/store/modules/_columnNames';
import { mapGetters } from 'vuex';
import fieldTypesMixin from '../modelMixins/fieldTypesMixin';
import { ClientKycEnum } from '~/settings/enums/client-kyc.enum';
import configsMixin from '~/components/mixins/configs-mixin';
import questionsAnswersMixin from '../questionsAnswersMixin';
import { EKycFieldsValuesId } from '~/settings/enums/kyc-fields_values-id.enums';
import { IAwarenessMessage, IBooleanOption, ICheckboxConsents, IDataOfFieldValuesWithName, IKycField, ITermsAndCondition, IVerifiedStatusByUser } from '~/settings/interfaces/kyc-interfaces';
import {
    IGetGuide,
    IShouldDisplayParamsCaseEmploymentStatusId,
} from '~/components/mixins/client-kyc-mixin/interfaces/get-guide-interfaces';
import { IDateTime, IDefaultConfig, IDefaultConfigIsDescribable } from '~/store/modules/configs/interfaces/configs-interface';

export default {
    mixins : [fieldTypesMixin, configsMixin, questionsAnswersMixin],
    data (): any {
        return {
            NOTHING : '-',
            politicalExposedOptions : getBooleanOptions(),
            onBehalfOfOptions : getBooleanOptions(),
            fundTypeIsBankTransferOptions: getBooleanOptions(),
            fundTypeIsEwalletsOptions: getBooleanOptions(),
            fundTypeIsCardOptions: getBooleanOptions(),
            isCitizenshipSameAsResidence: getBooleanOptions(),
            COLUMN_ID,
            COLUMN_FNAME,
            COLUMN_MNAME,
            COLUMN_LNAME,
            COLUMN_COMPANY_NAME,
            COLUMN_INCORPORATION_COUNTRY_ID,
            COLUMN_BUSINESS_ADDRESS,
            COLUMN_BUSINESS_PHONE,
            COLUMN_BUSINESS_PHONE_COUNTRY_ID,
            COLUMN_IDENTITY_NUMBER,
            COLUMN_DRIVING_LICENSE,
            COLUMN_PASSPORT,

            COLUMN_CITIZENSHIP_IDS,
            COLUMN_RESIDENCE_PLACE,
            COLUMN_RESIDENCE_ADDRESS,
            COLUMN_RESIDENCE_ZIP,
            COLUMN_CITIZENSHIP_EQUAL_RESIDENCE,
            COLUMN_HEARD_FROM_ID,
            COLUMN_RESIDENCE_COUNTRY_ID,

            COLUMN_PHONE_MOBILE,
            COLUMN_PHONE_SECOND,

            COLUMN_TIN_ABSENCE_REASON_VALUE,
            COLUMN_USA_TIN,

            COLUMN_EMPLOYMENT_VALUE,
            COLUMN_INDUSTRY_VALUE,
            COLUMN_PHONE_MOBILE_COUNTRY,

            COLUMN_POLITICAL_EXPOSED,
            COLUMN_FUND_TYPE_IS_BANK_TRANSFER,
            COLUMN_FUND_TYPE_BANK_NAME,
            COLUMN_FUND_TYPE_BANK_LOCATION,
            COLUMN_FUND_TYPE_IS_CARD,
            COLUMN_FUND_TYPE_IS_E_WALLETS,

            COLUMN_AQM_ON_BEHALF_OF,
            COLUMN_AQM_ON_BEHALF_OF_CAPACITY,

            COLUMN_ANSWERS,
            COLUMN_CHECKBOX_CONSENTS,

            COLUMN_PRO_REQUEST_TYPE_ID,
            COLUMN_VERIFY_STATUS_CRYPTO,
            COLUMN_VERIFY_STATUS_CRYPTO_ID,

            AS_COLUMN_TITLE,
            AS_COLUMN_LEGAL_SUBJECT,
            AS_COLUMN_LEGAL_SUBJECT_ID,
            AS_COLUMN_BIRTH_COUNTRY_ID,
            AS_COLUMN_PHONE_MOBILE_COUNTRY,
            AS_COLUMN_PHONE_SECOND_COUNTRY,
            AS_COLUMN_PHONE_SECOND_COUNTRY_ID,
            AS_COLUMN_TIN_ABSENCE_REASON,
            COLUMN_EMPLOYMENT_STATUS_ID,
            AS_COLUMN_INDUSTRY_ID,
            AS_COLUMN_FUND_SOURCE_ID,
            AS_COLUMN_VERIFY_STATUS,
            AS_COLUMN_HEARD_FROM_ID,
            AS_COLUMN_RISK,
            AS_COLUMN_LEAD_STATUS,
            AS_COLUMN_COMPLICATEDNESS,
            AS_COLUMN_PROFESSIONAL_LEVEL,
            AS_COLUMN_APPROVED_DEPOSIT_AMOUNT,
        };
    },
    computed : {
        ...mapGetters({
            clientKyc: names.getters.entity,
            clientFundingMethods: getterFundingMethods,
        }),

        leadId (): number {
            return this.clientKyc[COLUMN_ID];
        },
        title (): IKycField {
            return this.clientKyc[EKycFieldsValuesId.title_id];
        },
        fname (): IKycField {
            return this.clientKyc[EKycFieldsValuesId.fname];
        },
        mname (): IKycField {
            return this.clientKyc[EKycFieldsValuesId.mname];
        },
        lname (): IKycField {
            return this.clientKyc[EKycFieldsValuesId.lname];
        },
        email (): string {
            return this.clientKyc[COLUMN_EMAIL];
        },
        phone_mobile (): IKycField {
            return this.clientKyc[EKycFieldsValuesId.phone_mobile];
        },
        phone_second (): IKycField {
            return this.clientKyc[EKycFieldsValuesId.phone_second];
        },
        birth_date (): IKycField {
            return this.clientKyc[EKycFieldsValuesId.birth_date];
        },
        birth_date_formatted (): string | null {
            return this.clientKyc.birth_date_formatted;
        },
        birth_country_id (): IKycField {
            return this.clientKyc[EKycFieldsValuesId.birth_country_id];
        },
        residence_country_id (): IKycField {
            return this.clientKyc[EKycFieldsValuesId.residence_country_id];
        },
        residence_place (): IKycField {
            return this.clientKyc[EKycFieldsValuesId.residence_place];
        },
        residence_address (): IKycField {
            return this.clientKyc[EKycFieldsValuesId.residence_address];
        },
        residence_zip (): IKycField {
            return this.clientKyc[EKycFieldsValuesId.residence_zip];
        },
        heard_from_id (): IKycField {
            return this.clientKyc[EKycFieldsValuesId.heard_from_id];
        },
        citizenship_equal_residence (): IBooleanOption {
            return this.clientKyc[COLUMN_CITIZENSHIP_EQUAL_RESIDENCE];
        },
        citizenship_country_ids (): IKycField | object {
            return this.clientKyc[EKycFieldsValuesId.citizenship_country_ids];
        },
        political_exposed (): IKycField {
            return this.clientKyc[EKycFieldsValuesId.political_exposed];
        },
        identity_number (): IKycField { // identification_document, ID
            return this.clientKyc[EKycFieldsValuesId.identity_number];
        },
        driving_license (): IKycField {
            return this.clientKyc[EKycFieldsValuesId.driving_license];
        },
        passport (): IKycField {
            return this.clientKyc[EKycFieldsValuesId.passport];
        },
        tins (): IKycField {
            return this.clientKyc[EKycFieldsValuesId.tins];
        },
        is_tin_available (): boolean {
            return this.clientKyc[COLUMN_IS_TIN_AVAILABLE];
        },
        is_tin_available_string (): IBooleanOption {
            return this.clientKyc[COLUMN_IS_TIN_AVAILABLE_STRING];
        },
        tin_absence_reason_id (): IKycField {
            return this.clientKyc[EKycFieldsValuesId.tin_absence_reason_id];
        },
        is_fatca (): IBooleanOption {
            return this.clientKyc[COLUMN_IS_FATCA];
        },
        usa_tin (): null | string {
            return this.clientKyc[COLUMN_USA_TIN];
        },
        assessmentQuestions (): IKycField[] {
            return this.clientKyc[COLUMN_ASSESSMENT_QUESTIONS];
        },
        isLoadAssessmentQuestions (): boolean {
            return !this.assessmentQuestions.some((item: IKycField) => !item);
        },
        investmentQuestions (): IKycField[] {
            return this.clientKyc[COLUMN_INVESTMENT_QUESTIONS];
        },
        isLoadInvestmentQuestions (): boolean {
            return !this.investmentQuestions.some((item: IKycField) => !item);
        },
        employment_status_id (): IKycField {
            return this.clientKyc[EKycFieldsValuesId.employment_status_id];
        },
        industry_id (): IKycField {
            return this.clientKyc[EKycFieldsValuesId.industry_id];
        },
        industry_value (): undefined {
            return this.clientKyc[COLUMN_INDUSTRY_VALUE];
        },
        fund_source_id (): IKycField {
            return this.clientKyc[EKycFieldsValuesId.fund_source_id];
        },
        financialQuestions (): IKycField[] {
            return this.clientKyc[COLUMN_FINANCIAL_QUESTIONS];
        },
        isLoadfinancialQuestions (): boolean {
            return !this.financialQuestions.some((item: IKycField) => !item);
        },
        fund_type_bank_name (): IKycField {
            return this.clientKyc[EKycFieldsValuesId.fund_type_bank_name];
        },
        fund_type_bank_location (): IKycField {
            return this.clientKyc[EKycFieldsValuesId.fund_type_bank_location];
        },
        fund_type_is_bank_transfer (): IKycField {
            return this.clientKyc[EKycFieldsValuesId.fund_type_is_bank_transfer];
        },
        fund_type_is_card (): IKycField {
            return this.clientKyc[EKycFieldsValuesId.fund_type_is_card];
        },
        fund_type_is_e_wallets (): IKycField {
            return this.clientKyc[EKycFieldsValuesId.fund_type_is_e_wallets];
        },
        target_market_classification_id (): IKycField {
            return this.clientKyc[EKycFieldsValuesId.target_market_classification_id];
        },
        complete_questionnaire_at (): IDateTime {
            return this.clientKyc[COLUMN_COMPLETE_QUESTIONNAIRE_AT];
        },
        verified_status_by_user (): IVerifiedStatusByUser {
            return this.clientKyc[COLUMN_VERIFIED_STATUS_BY_USER];
        },
        ip_address (): IKycField {
            return this.clientKyc[EKycFieldsValuesId.ip_address];
        },
        terms_and_condition (): ITermsAndCondition {
            return this.clientKyc['terms_and_condition'];
        },
        awareness_messages (): IAwarenessMessage[] | [] {
            const awarenessMessages = this.clientKyc[COLUMN_AWARENESS_MESSAGES]
                ? this.clientKyc[COLUMN_AWARENESS_MESSAGES]
                : [];

            return awarenessMessages.filter((item: IAwarenessMessage) => (item.id === ClientKycEnum.SpainWarningScore));
        },
        awareness_messages_account_holder_declaration (): IAwarenessMessage[] | [] {
            const awarenessMessages = this.clientKyc[COLUMN_AWARENESS_MESSAGES]
                ? this.clientKyc[COLUMN_AWARENESS_MESSAGES]
                : [];

            return awarenessMessages;
        },
        checkbox_consents (): ICheckboxConsents[] {
            return this.clientKyc[COLUMN_CHECKBOX_CONSENTS];
        },

        // Used in app-kyc-filter.vue
        verify_status (): IDefaultConfig {
            return this.clientKyc[AS_COLUMN_VERIFY_STATUS];
        },
        verify_status_crypto (): IDefaultConfig | null {
            return this.clientKyc[COLUMN_VERIFY_STATUS_CRYPTO];
        },
        legal_subject_id (): IKycField {
            return this.clientKyc[EKycFieldsValuesId.legal_subject_id];
        },
        professional_level (): IDefaultConfig {
            return this.clientKyc[AS_COLUMN_PROFESSIONAL_LEVEL];
        },
        risk (): IDefaultConfig { // Risk Categorisation
            return this.clientKyc[AS_COLUMN_RISK];
        },
        complicatedness (): IDefaultConfig { // Status
            return this.clientKyc[AS_COLUMN_COMPLICATEDNESS];
        },
        aqm_on_behalf_of (): IKycField {
            return this.clientKyc[EKycFieldsValuesId.aqm_on_behalf_of];
        },
        aqm_on_behalf_of_capacity (): IKycField {
            return this.clientKyc[EKycFieldsValuesId.aqm_on_behalf_of_capacity];
        },
        go_pro_answers (): IKycField {
            return this.clientKyc[EKycFieldsValuesId.go_pro_answers];
        },
        approved_deposit_amount (): number | string {
            return this.clientKyc[AS_COLUMN_APPROVED_DEPOSIT_AMOUNT];
        },
        campaign_name (): string {
            return this.clientKyc[COLUMN_CAMPAIGN_NAME];
        },

        // business
        companyName (): IKycField {
            return this.clientKyc[EKycFieldsValuesId.company_name];
        },
        incorporationCountryId (): IKycField {
            return this.clientKyc[EKycFieldsValuesId.incorporation_country_id];
        },
        businessAddress (): IKycField {
            return this.clientKyc[EKycFieldsValuesId.business_address];
        },
        businessPhone (): IKycField {
            return this.clientKyc[EKycFieldsValuesId.business_phone];
        },
    },

    methods : {
        getGuide (column: string): IGetGuide[] {
            switch (column) {
            case this.AS_COLUMN_TITLE : {
                return [
                    {
                        type : this.FIELD_TYPE_SELECT,
                        initial : this.title,
                        options: this.getConfigTitles,
                        column: this.AS_COLUMN_TITLE,
                    }
                ];
            }
            case this.COLUMN_FNAME : {
                return [
                    {
                        type : this.FIELD_TYPE_INPUT,
                        initial : this.fname,
                        column
                    }
                ];
            }
            case this.COLUMN_MNAME : {
                return [
                    {
                        type : this.FIELD_TYPE_INPUT,
                        initial: this.mname,
                        column,
                    }
                ];
            }
            case this.COLUMN_LNAME : {
                return [
                    {
                        type : this.FIELD_TYPE_INPUT,
                        initial: this.lname,
                        column,
                    }
                ];
            }
            case this.COLUMN_PHONE_MOBILE : {
                return [
                    {
                        type : this.FIELD_TYPE_SELECT,
                        initial : this.phone_mobile_country,
                        options : this.getConfigCountries,
                        column : this.AS_COLUMN_PHONE_MOBILE_COUNTRY
                    },
                    {
                        type : this.FIELD_TYPE_INPUT,
                        initial : this.phone_mobile,
                        column : this.COLUMN_PHONE_MOBILE
                    }
                ];
            }
            case this.COLUMN_PHONE_SECOND : {
                return [
                    {
                        type : this.FIELD_TYPE_SELECT,
                        initial : this.phone_second_country,
                        options : this.getConfigCountries,
                        column : this.AS_COLUMN_PHONE_SECOND_COUNTRY
                    },
                    {
                        type : this.FIELD_TYPE_INPUT,
                        initial : this.phone_second,
                        column : this.COLUMN_PHONE_SECOND
                    }
                ];
            }
            case this.AS_COLUMN_BIRTH_COUNTRY_ID : {
                return [
                    {
                        type : this.FIELD_TYPE_SELECT,
                        initial: this.birth_country_id,
                        column,
                        options: this.getConfigCountries
                    }
                ];
            }
            case this.COLUMN_RESIDENCE_COUNTRY_ID : {
                return [
                    {
                        type : this.FIELD_TYPE_SELECT,
                        initial: this.residence_country_id,
                        column,
                        options: this.getConfigCountries,
                    }
                ];
            }
            case this.COLUMN_RESIDENCE_PLACE : {
                return [
                    {
                        type : this.FIELD_TYPE_INPUT,
                        initial: this.residence_place,
                        column,
                    }
                ];
            }
            case this.COLUMN_RESIDENCE_ADDRESS : {
                return [
                    {
                        type : this.FIELD_TYPE_INPUT,
                        initial: this.residence_address,
                        column,
                    }
                ];
            }
            case this.COLUMN_RESIDENCE_ZIP : {
                return [
                    {
                        type : this.FIELD_TYPE_INPUT,
                        initial: this.residence_zip,
                        column,
                    }
                ];
            }
            case this.COLUMN_POLITICAL_EXPOSED : {
                return [
                    {
                        type: this.FIELD_TYPE_RADIO,
                        initial: this.political_exposed,
                        column,
                        options : this.politicalExposedOptions
                    }
                ];
            }
            case this.COLUMN_IDENTITY_NUMBER : { // ID
                return [
                    {
                        type: this.FIELD_TYPE_INPUT,
                        initial: this.identity_number,
                        column,
                    }
                ];
            }
            case this.COLUMN_DRIVING_LICENSE : {
                return [
                    {
                        type: this.FIELD_TYPE_INPUT,
                        initial: this.driving_license,
                        column,
                    }
                ];
            }
            case this.COLUMN_PASSPORT : {
                return [
                    {
                        type: this.FIELD_TYPE_INPUT,
                        initial: this.passport,
                        column,
                    }
                ];
            }
            case this.AS_COLUMN_TIN_ABSENCE_REASON : {
                return [
                    {
                        type : this.FIELD_TYPE_SELECT,
                        initial: this.tin_absence_reason_id,
                        column: this.AS_COLUMN_TIN_ABSENCE_REASON,
                        options: this.getConfigTinAbsenceReasons,
                    },
                    {
                        type : this.FIELD_TYPE_INPUT,
                        column: this.COLUMN_TIN_ABSENCE_REASON_VALUE,
                        initial: '',
                        shouldDisplay: (params) => {
                            const { currentOption } = params; // todo think of more abstract param in KycTabItemInputSelect
                            return currentOption?.describable;
                        }
                    }
                ];
            }
            case this.AS_COLUMN_VERIFY_STATUS : {
                return [
                    {
                        type : this.FIELD_TYPE_SELECT,
                        initial: this.verify_status,
                        column,
                        options: this.getConfigVerifyStatuses,
                    }
                ];
            }
            case this.AS_COLUMN_LEGAL_SUBJECT_ID : {
                return [
                    {
                        type : this.FIELD_TYPE_SELECT,
                        initial: this.legal_subject_id,
                        column,
                        options: this.getConfigLegalSubjects,
                    }
                ];
            }
            case this.AS_COLUMN_RISK : {
                return [
                    {
                        type : this.FIELD_TYPE_SELECT,
                        initial: this.risk,
                        column,
                        options: this.getConfigRisks,
                    }
                ];
            }
            case this.AS_COLUMN_COMPLICATEDNESS : {
                return [
                    {
                        type : this.FIELD_TYPE_SELECT,
                        initial: this.clientComplicatedness,
                        column,
                        options: this.getConfigComplicatedness,
                    }
                ];
            }
            case this.AS_COLUMN_PROFESSIONAL_LEVEL : {
                return [
                    {
                        type : this.FIELD_TYPE_SELECT,
                        initial: this.professional_level,
                        column,
                        options: this.getConfigProfessionalLevels,
                    }
                ];
            }
            case this.COLUMN_EMPLOYMENT_STATUS_ID : {
                return [
                    {
                        type : this.FIELD_TYPE_SELECT,
                        initial: this.employment_status_id,
                        column: this.COLUMN_EMPLOYMENT_STATUS_ID,
                        options: this.getConfigEmploymentStatuses,
                    },
                    {
                        type : this.FIELD_TYPE_INPUT,
                        initial: this.employment_status_id,
                        column: this.COLUMN_EMPLOYMENT_VALUE,
                        shouldDisplay: (params: IShouldDisplayParamsCaseEmploymentStatusId): boolean => {
                            const { currentOption } = params; // todo think of more abstract param in KycTabItemInputSelect

                            if ('describable' in currentOption) {
                                return currentOption?.describable;
                            }

                            return this.getConfigEmploymentStatuses
                                .find((status: IDefaultConfigIsDescribable) => {
                                    return status.id === currentOption.value;
                                })?.describable;
                        },
                        placeholder: () => {
                            return 'Name of your employer or business';
                        }
                    }
                ];
            }
            case this.AS_COLUMN_INDUSTRY_ID : {
                return [
                    {
                        type : this.FIELD_TYPE_SELECT,
                        initial: this.industry_id,
                        column: this.AS_COLUMN_INDUSTRY_ID,
                        options: this.getConfigIndustries,
                    },
                    {
                        type : this.FIELD_TYPE_INPUT,
                        initial: this.industry_value,
                        column: this.COLUMN_INDUSTRY_VALUE,
                        shouldDisplay: (params) => {
                            const { currentOption } = params; // todo think of more abstract param in KycTabItemInputSelect

                            return currentOption?.describable;
                        },
                        placeholder : (params) => {
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            const { currentOption } = params;
                            return 'Industry Name';
                        }
                    }
                ];
            }
            case this.AS_COLUMN_FUND_SOURCE_ID : {
                return [
                    {
                        type : this.FIELD_TYPE_SELECT,
                        initial: this.fund_source_id,
                        column: this.AS_COLUMN_FUND_SOURCE_ID,
                        options: this.getConfigFundSources,
                    },
                ];
            }
            case this.COLUMN_FUND_TYPE_IS_BANK_TRANSFER: {
                return [
                    {
                        type : this.FIELD_TYPE_RADIO,
                        initial: this.fund_type_is_bank_transfer,
                        column,
                        options: this.fundTypeIsBankTransferOptions,
                    }
                ];
            }
            case this.COLUMN_FUND_TYPE_BANK_NAME : {
                return [
                    {
                        type : this.FIELD_TYPE_INPUT,
                        initial: this.fund_type_bank_name,
                        column,
                    }
                ];
            }
            case this.COLUMN_FUND_TYPE_BANK_LOCATION : {
                return [
                    {
                        type : this.FIELD_TYPE_INPUT,
                        initial : this.fund_type_bank_location,
                        column,
                    }
                ];
            }
            case this.COLUMN_FUND_TYPE_IS_E_WALLETS: {
                return [
                    {
                        type : this.FIELD_TYPE_RADIO,
                        initial: this.fund_type_is_e_wallets,
                        column,
                        options: this.fundTypeIsEwalletsOptions,
                    }
                ];
            }
            case this.COLUMN_FUND_TYPE_IS_CARD: {
                return [
                    {
                        type : this.FIELD_TYPE_RADIO,
                        initial: this.fund_type_is_card,
                        column,
                        options: this.fundTypeIsCardOptions,
                    }
                ];
            }
            case this.COLUMN_AQM_ON_BEHALF_OF : {
                return [
                    {
                        type : this.FIELD_TYPE_RADIO,
                        initial: this.aqm_on_behalf_of,
                        column,
                        options: this.onBehalfOfOptions,
                    }
                ];
            }
            case this.COLUMN_AQM_ON_BEHALF_OF_CAPACITY : {
                return [
                    {
                        type : this.FIELD_TYPE_INPUT,
                        initial: this.aqm_on_behalf_of_capacity,
                        column,
                    }
                ];
            }

            case this.COLUMN_CITIZENSHIP_EQUAL_RESIDENCE : {
                return [
                    {
                        type : this.FIELD_TYPE_RADIO,
                        initial: this.citizenship_equal_residence,
                        column,
                        options: this.isCitizenshipSameAsResidence,
                    }
                ];
            }

            case this.AS_COLUMN_HEARD_FROM_ID : {
                return [
                    {
                        type : this.FIELD_TYPE_SELECT,
                        initial: this.heard_from_id,
                        column,
                        options: this.getConfigHeardFrom,
                    }
                ];
            }

            // business
            case this.COLUMN_COMPANY_NAME : {
                return [
                    {
                        type : this.FIELD_TYPE_INPUT,
                        initial: this.companyName,
                        column,
                    }
                ];
            }

            case this.COLUMN_INCORPORATION_COUNTRY_ID : {
                return [
                    {
                        type : this.FIELD_TYPE_SELECT,
                        initial: this.incorporationCountryId,
                        column,
                        options: this.getConfigCountries,
                    }
                ];
            }
            case this.COLUMN_BUSINESS_ADDRESS : {
                return [
                    {
                        type : this.FIELD_TYPE_INPUT,
                        initial: this.businessAddress,
                        column,
                    }
                ];
            }

            case this.COLUMN_BUSINESS_PHONE: {
                return [
                    {
                        type : this.FIELD_TYPE_INPUT,
                        initial : this.businessPhone,
                        column : this.COLUMN_BUSINESS_PHONE,
                    }
                ];
            }

            case this.COLUMN_BUSINESS_PHONE_COUNTRY_ID : {
                return [
                    {
                        type : this.FIELD_TYPE_SELECT,
                        initial: this.businessPhoneCountry,
                        column : this.COLUMN_BUSINESS_PHONE_COUNTRY_ID,
                        options: this.getConfigCountries,
                    },
                ];
            }

            default : {
                throw new Error('wrong column');
            }
            }
        }
    },
};
