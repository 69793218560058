import { Module, VuexModule, getModule, Mutation, Action }  from 'vuex-module-decorators';
import store from '~/store/index';
import Rest from '~/helpers/Rest';
import { notificationActivityLog } from '~/components/tables/_headers/notification-activity-log';
import { tableParser } from '~/helpers/table-parser';
import { ITableHeaderColumn } from '~/components/tables/interfaces/table-header-column.interfaces';
import { INotificationActivityLog } from './notifications-interfaces';
import { ITableCell } from '~/components/tables/interfaces/table-cell-interfaces';
import { urlNotificationActivityLog } from '~/settings/ajax-urls';
import { IPagination } from '~/settings/interfaces/pagination-interfaces';


@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: 'NotificationsActivityLog',
})

class NotificationsActivityLog extends VuexModule {
    notifications: INotificationActivityLog[] = [];
    notificationsPagination: IPagination = {
        current_page: null,
        from: null,
        last_page: null,
        per_page: null,
        to: null,
        total: null,
    };

    get notificationsHeaders (): ITableHeaderColumn[] {
        return notificationActivityLog;
    }

    get notificationsRows (): ITableCell[] {
        return tableParser(notificationActivityLog, this.notifications);
    }

    get getNotificationsPagination (): IPagination {
        return this.notificationsPagination;
    }

    @Mutation
    set (payload: INotificationActivityLog[]) {
        this.notifications = payload;
    }

    @Mutation
    reset () {
        this.notifications = [];
    }

    @Mutation
    setNotificationsPagination (payload: IPagination) {
        this.notificationsPagination = payload;
    }

    @Action ({ commit: 'set' })
    async load (query: object): Promise<INotificationActivityLog[]> {
        const { data, meta } = await Rest.GET(urlNotificationActivityLog(query))
            .then(Rest.middleThen)
            .catch(Rest.simpleCatch);

        this.setNotificationsPagination(meta);

        return data;
    }
}

export const NotificationsActivityLogModule = getModule(NotificationsActivityLog);
