export enum EConfigsMethods {
    getAnswers = 'Configs/getAnswers',
    getCheckboxConsents = 'Configs/getCheckboxConsents',
    getComplicatedness = 'Configs/getComplicatedness',
    getCountries = 'Configs/getCountries',
    getIndustries = 'Configs/getIndustries',
    getLeadStatuses = 'Configs/getLeadStatuses',
    getLegalSubjects = 'Configs/getLegalSubjects',
    getProLevelLeverageRequestStatuses = 'Configs/getProLevelLeverageRequestStatuses',
    getProfessionalLevelRequestTypes = 'Configs/getProfessionalLevelRequestTypes',
    getProfessionalLevels = 'Configs/getProfessionalLevels',
    getRisks = 'Configs/getRisks',
    getTinAbsenceReasons = 'Configs/getTinAbsenceReasons',
    getTitles = 'Configs/getTitles',
    getVerifyStatuses = 'Configs/getVerifyStatuses',
    getFields = 'Configs/getFields',
}
