import Vue from 'vue';

import { CalendarModule } from '~/store/modules/calendar/calendar';

export default Vue.extend({
    computed: {
        notifications () {
            return CalendarModule.getCalendarNotifications;
        }
    },
    methods: {
        getNotificationPosition (notificationIndex) {
            return `${notificationIndex * 47 + 26}px`;
        },
        closeNotification (notificationIndex) {
            return CalendarModule.removeNotification(notificationIndex);
        }
    }
});
