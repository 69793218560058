import { ILeadsConfigsAnswers } from '~/store/modules/configs/interfaces/configs-interface';
import configsMixin from '~/components/mixins/configs-mixin';
import { EKycFieldsValuesId } from '~/settings/enums/kyc-fields_values-id.enums';

export default {
    mixins: [
        configsMixin,
    ],
    methods : {
        answersById (questionId: number, name: string): ILeadsConfigsAnswers {
            if (
                (questionId >= EKycFieldsValuesId.question1 &&
                questionId <= EKycFieldsValuesId.question22) ||
                questionId === EKycFieldsValuesId.question23
            ) {
                // TODO when back send id from server
                const id = Number(name.slice('question'.length));

                return this.getConfigAnswers.filter(
                    (item: ILeadsConfigsAnswers) => item.question_id === id
                );
            }
        }
    }
};
