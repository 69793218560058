import {
    COLUMN_LANGUAGE_A2,
    COLUMN_LEAD_STATUS_ID,
    COLUMN_RESIDENCE_COUNTRY_ID,
} from '~/store/modules/_columnNames.js';
import { ETableFilterNoCountry } from '~/components/tables/enums/table-filter-no-country.enum';
import { ETableFilterKeyLeads } from '~/components/tables/enums/table-filter-key.enum';
import { convertStringToArray } from '~/helpers/convert-string-to-array';

export const handlerLeads = (inputs: object): object => {
    for (const input in inputs) {
        switch (input) {
        case COLUMN_RESIDENCE_COUNTRY_ID:
            if (inputs[input]) {
                inputs[input] = convertStringToArray(inputs[input]);
                inputs[input] = isNoCountry(inputs[input]);
            }
            break;
        case COLUMN_LEAD_STATUS_ID:
        case COLUMN_LANGUAGE_A2:
        case ETableFilterKeyLeads.verify_status_id:
        case ETableFilterKeyLeads.trading_type:
        case ETableFilterKeyLeads.mode_type_ids:
        case ETableFilterKeyLeads.mt_subject_type:
        case ETableFilterKeyLeads.campaign:
            if (inputs[input]) inputs[input] = convertStringToArray(inputs[input]);
            break;
        default:
            break;
        }
    }
    return inputs;
};

const isNoCountry = (payload: string[]): string[] => {
    return payload.map(item => {
        if (Number(item) === ETableFilterNoCountry.noCountry) {
            return '';
        } else {
            return item;
        }
    });
};
