import { IBonusPlan } from '~/store/modules/bonus-plan/interfaces/bonus-plan-interfaces';
import { EBonusPlanType } from '~/settings/enums/bonus-plan-type.enum';

export const bonusPlanTypeValidation = (payload: IBonusPlan): boolean => {
    switch (payload.bonus_type.id) {
    case EBonusPlanType.cpa:
    case EBonusPlanType.cpl:
        return payload.countries?.length > 0;
    case EBonusPlanType.cryptoRebate:
    case EBonusPlanType.revShare:
        return payload.revenues?.length > 0;
    case EBonusPlanType.hybrid:
        return payload.countries?.length > 0 && payload.revenues?.length > 0;
    }
};
