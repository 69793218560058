import Vue from 'vue';
import currencyHelper from '~/components/mixins/currency-helper';
import { EAccountCurrencies } from '~/settings/enums/account-currencies.enum';
import rowItemPropsMixin from '@/js/components/tables/mixins/table-cells-mixins/row-item-props-mixin';

export default Vue.extend({
    mixins: [
        currencyHelper,
        rowItemPropsMixin,
    ],
    computed: {
        isCurrentCurrency (): string {
            const currency = this.isCurrencies
                .find(currency => currency.id === (this.cell.value || EAccountCurrencies.USD));

            return currency?.name || '';
        }
    }
});
