<template>
    <div
        :class="[
            'kyc-tab-subtitle',
            { 'kyc-tab-subtitle--content-title': contentTitle },
        ]"
    >
        <h3 class="kyc-tab-subheader">
            {{ subtitle }}
        </h3>
        <slot />
    </div>
</template>

<script>
export default {
    props : {
        subtitle : {
            type : String,
            required: true
        },
        contentTitle: {
            type: Boolean,
        }
    }
};
</script>
