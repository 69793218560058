import Vue from 'vue';

export default Vue.extend({
    props: {
        document: {
            type: Object,
            required: true
        }
    },
    computed: {
        title (): string {
            return this.document.title;
        }
    },
    methods: {
        handleDragStart (event: DragEvent) {
            event.dataTransfer.setData('text/plain', JSON.stringify(this.document));
            this.$emit('changeDragging', true);
        },
        handleDragEnd () {
            this.$emit('changeDragging', false);
        },
    }
});
