import { convertStringToArray } from '../convert-string-to-array';
import { ETableFilterKeyAffiliates } from '~/components/tables/enums/table-filter-key.enum';

export const handlerAffiliates = (inputs: object): object => {
    for (const input in inputs) {
        switch (input) {
        case ETableFilterKeyAffiliates.language:
        case ETableFilterKeyAffiliates.country:
        case ETableFilterKeyAffiliates.bonus:
            if (inputs[input]) inputs[input] = convertStringToArray(inputs[input]);
            break;
        default:
            break;
        }
    }
    return inputs;
};
