import Vue from 'vue';
import { PaymentStatusEnum } from '~/settings/enums/payment-status-enum';
import { EIconsSettingsPaymentHistoryStatus, EIconsSettingsColor } from '~/settings/enums/status-icons-settings.enum';
import rowItemPropsMixin from '@/js/components/tables/mixins/table-cells-mixins/row-item-props-mixin';

export default Vue.extend({
    mixins: [
        rowItemPropsMixin,
    ],
    computed: {
        statusName (): string {
            return PaymentStatusEnum[this.cell.value];
        },
        isStatus (): string {
            return this.cell.value >= PaymentStatusEnum.cancelledByClient ? PaymentStatusEnum.cancelled : this.cell.value;
        },
        isIcon (): string {
            return this.cell.value ?
                EIconsSettingsPaymentHistoryStatus[this.cell.value] :
                '';
        },
        isIconColor (): string {
            return this.isIcon ? EIconsSettingsColor[this.isIcon] : '';
        }
    }
});
