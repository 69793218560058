import Vue from 'vue';
export default Vue.extend({
    props: {
        option: {
            type: Object,
            required: true,
            default: function () {
                return {};
            }
        },
        className: {
            type: String,
        },
    },
    methods: {
        // TODO
        handlerStatusIcon (event): void {
            event.target.src = '/img/clients/finance-statuses/3.svg';
        },
    }
});
