import Vue from 'vue';
import { ModalsModule } from '~/store/modules/modals';

export default Vue.extend({
    methods: {
        showModal () {
            ModalsModule.openModal({
                modalType: 'connectModal',
                props: {
                    title: 'Title',
                    body: {},
                    isFooter: false,
                    isHeader: true
                },
                addClass: 'connectModal'
            });
        }
    }
});
