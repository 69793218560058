import Vue from 'vue';
import { EConfigType } from '~/store/modules/configs/enums/config-type.enum';
import {
    regExprLink,
    regExprLinkNotStartProtocol,
    regExprLinkWww
} from '~/helpers/regular-expressions';
import { EStringMaxLength } from '../plain-text/plain-text.enum';
import rowItemPropsMixin from '@/js/components/tables/mixins/table-cells-mixins/row-item-props-mixin';

export default Vue.extend({
    mixins: [
        rowItemPropsMixin,
    ],
    computed: {
        isValue (): string {
            return typeof this.cell.value === EConfigType.string
                || typeof this.cell.value === EConfigType.number
                ? this.cell.value : 'N/A';
        },
        isNotLongText (): boolean {
            return this.isValue.length < EStringMaxLength.length;
        },
    },
    methods: {
        isValueLink (): boolean {
            const stringIsValue = typeof this.isValue !== EConfigType.string
                ? String(this.isValue)
                : this.isValue;

            return regExprLink.test(stringIsValue)
                || regExprLinkWww.test(stringIsValue)
                || regExprLinkNotStartProtocol.test(stringIsValue);
        },
    }
});
