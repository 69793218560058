import { defaultProfileEnum } from './profile.enums';
export const defaultProfile = {
    id: defaultProfileEnum.id,
    username: defaultProfileEnum.username,
    fname: defaultProfileEnum.fname,
    lname: defaultProfileEnum.lname,
    email: defaultProfileEnum.email,
    department_id: defaultProfileEnum.department_id,
    department_name: defaultProfileEnum.department_name,
    permissions: [
        {
            id: 0,
            name: ''
        },
    ],
};
