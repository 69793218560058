import { EAccountCurrencies } from '~/settings/enums/account-currencies.enum';
import { ConfigsModule } from '~/store/modules/configs/configs-module';

export default {
    data () {
        return {
            currencyDefault: {
                name: 'Currency',
                id: null
            },
            currency: null,
        };
    },

    computed: {
        isCurrencies () {
            return ConfigsModule.getCurrencies;
        },
        accountCurrencies () {
            return ConfigsModule.getAccountCurrencies;
        },
        bonusPlanCurrencies () {
            return ConfigsModule.getAccountCurrencies
                .filter(item => item.id <= EAccountCurrencies.USD);
        },
        isCurrenciesFiat () {
            return this.isCurrencies.filter(currency => EAccountCurrencies[currency.id]);
        },
        isCurrenciesCrypto () {
            return this.isCurrencies.filter(currency => !EAccountCurrencies[currency.id]);
        },

        isCurrency: {
            get () {
                return this.currency;
            },
            set (value) {
                const isValue = value || this.currencyDefault;
                this.currency = isValue;
            }
        },
    },
    methods: {
        /**
         * @Param: number
         * @return: String
         * */
        getCurrencyName (id) {
            const isValue = this.isCurrencies.find(currency => currency.id === id);
            return isValue ? isValue.name : '';
        },

        setCurrency (currencyId) {
            this.isCurrency = this.isCurrencies
                .find(currency => currency.id === currencyId);
        }
    },
};
