import Vue from 'vue';
import { LeadsFilterTemplatesModule } from '~/store/modules/leads-filter-templates/leads-filter-templates';
// @ts-ignore
import modalLayout from '~/components/modals/root-modal-layout/root-modal-layout';
import modalsMixin from '~/components/mixins/modals';
export default Vue.extend({
    components: {
        modalLayout
    },
    mixins: [modalsMixin],
    methods: {
        deleteTemplate () {
            this.closeModal();
            LeadsFilterTemplatesModule.deleteTemplate();
        },
        cancelDelete () {
            this.closeModal();
            LeadsFilterTemplatesModule.cancelDeleteTemplate();
        }
    }
});
