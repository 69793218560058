export const affiliatesCampaignsType = [
    {
        name:  'Link',
        value:  'Link'
    },
    {
        name:  'Banner',
        value:  'Banner'
    },
    {
        name:  'Logo',
        value:  'Logo'
    },
    {
        name:  'Button',
        value:  'Button'
    },
    {
        name:  'Widget',
        value:  'Widget'
    },
    {
        name:  'Video',
        value:  'Video'
    },
    {
        name:  'Landing Page',
        value:  'Landing Page'
    },
];
