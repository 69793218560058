// @ts-ignore
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import DatePicker from 'vue2-datepicker';
import Vue from 'vue';
import { UploadedDocumentsUploaderInterfaceInterface } from '~/components/uploaded-documents/uploaded-documents-uploader/interfaces/uploaded-documents-uploader-interface';
import { ComplianceUploadedDocumentsModule } from '~/store/modules/documents/uploadedDocuments';
import { UploadedDocumentsUploaderEnum } from './enums/uploaded-documents-uploader.enum';
import { DocumentsTypesModule } from '~/store/modules/documents/documents-types';

export default Vue.extend({
    components: {
        'vueDropzone': vue2Dropzone,
        'date-picker': DatePicker
    },
    props: {
        isExpiredDate: {
            type: Boolean,
            default: false,
        }
    },
    data (): UploadedDocumentsUploaderInterfaceInterface {
        return {
            selectedDate: '',
            files: [],
            dropzoneOptions: {
                acceptedFiles: 'image/jpeg,image/png,image/gif,image/jpg,application/pdf,video/mp4,audio/mpeg,',
                autoProcessQueue: false,
                url: 'https://',
                thumbnailWidth: 150,
                maxFilesize: 15,
                addRemoveLinks: true,
                uploadMultiple: true
            }
        };
    },
    computed: {
        isEnabled (): boolean {
            if (this.isExpiredDate) {
                return this.files.length && this.selectedDate;
            }
            return this.files.length;
        },
        docTypeSelected (): number {
            return DocumentsTypesModule.getterDocumentsTypeSelected;
        },
        isEmailAble (): Boolean {
            return ComplianceUploadedDocumentsModule.getIsEmailAble;
        }
    },
    methods: {
        addFiles (files: Array<Object>): void {
            this.files = [
                ...this.files,
                ...files
            ];
        },
        removeFile (file): void {
            this.files = this.files.filter(item => {
                return file.upload.uuid !== item.upload.uuid;
            });
        },
        closeUploader (): Boolean {
            return ComplianceUploadedDocumentsModule.setUploaderStatus(false);
        },
        uploadDocument (): void {
            const formData = new FormData();
            this.files.forEach(item => {
                formData.append(UploadedDocumentsUploaderEnum.document, item);
            });
            if (this.isExpiredDate) {
                formData.append(UploadedDocumentsUploaderEnum.expireAt, this.selectedDate.toLocaleString());
            }

            this.$emit('upload', formData);
        }
    }
});
