import Vue from 'vue';
import { IWorldMapData } from '~/settings/interfaces/world-map-interfaces';

export default Vue.extend({
    props: {
        data: {
            type: Array,
        },
    },
    data () {
        return {
            wordMap: null,
            activeCountries: [],
        };
    },

    watch: {
        data: {
            immediate: true,
            deep: true,
            handler (value: IWorldMapData[]) {
                this.cleanStyle();
                this.handlerData(value);
            },
        },
    },
    methods: {
        cleanStyle (): void {
            this.activeCountries.forEach(country => {
                const element = this.$el?.querySelector(`#${country}`);
                element.style = '';
            });
            this.activeCountries = [];
        },
        handlerData (value: IWorldMapData[]) {
            if (value) {
                value.forEach(country => {
                    const element = this.$el?.querySelector(`#${country.A2.toLowerCase()}`);
                    if (element) {
                        this.activeCountries.push(country.A2.toLowerCase());
                        element.style = `fill: ${country.color}`;
                    }
                });
            }
        },
    },
});
