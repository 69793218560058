// Imports
import { Module, VuexModule, Mutation, Action, getModule, MutationAction } from 'vuex-module-decorators';
import store from '~/store/index';
import Rest from '~/helpers/Rest';
import {
    urlNotification,
    urlNotificationOpen,
    urlNotificationUpdate,
} from '~/settings/ajax-urls';
import { notificationCounter } from '~/settings/ajax-urls';
import { handlerRestResponse } from '~/helpers/handler-rest-response';
import { NotificationsInterfaces } from '~/components/notifications/interfaces/notifications-interfaces';
import { NotificationsDataInterfaces, notificationsTotalInterface } from './notifications-interfaces';
import { NotificationPrefabricated } from '~/helpers/modal-notification-services/modal-notification-services';
import { NotificationsEnum, EStatusNotification } from '~/components/notifications/enums/notifications.enum';
import { handlerPendingNotifications } from '~/helpers/handler-pending-notification';
import { intervalRequestsService } from '~/helpers/interval-requests-service/interval-requests-service';
import { IPagination } from '~/settings/interfaces/pagination-interfaces';
import {
    isPermissionAdmin,
} from '~/helpers/user-permission';

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: 'NotificationsMessages',
})

class NotificationsMessages extends VuexModule {
    notifications: Array<NotificationsDataInterfaces> = [];
    notificationsQuery: NotificationsInterfaces = {
        type_id: null
    };
    notificationCounts: Array<notificationsTotalInterface> = [];
    notificationsPagination: IPagination = {
        current_page: null,
        from: null,
        last_page: null,
        per_page: null,
        to: null,
        total: null,
    };

    get getAllNotification (): notificationsTotalInterface[] {
        return this.notificationCounts;
    }

    get getAllNotificationsCount (): number {
        let count = 0;
        this.notificationCounts.forEach(item => {
            count += item.total;
        });
        return count;
    }

    get getKYCNotificationsCount (): number {
        const item = this.notificationCounts.find(item => `${item.type_id}` === NotificationsEnum.TYPE_KYC);
        return item ? item.total : 0;
    }

    get getFundingNotificationsCount (): number {
        const item = this.notificationCounts.find(item => `${item.type_id}` === NotificationsEnum.TYPE_FUNDING);
        return item ? item.total : 0;
    }

    get getRegistrationNotificationsCount (): number {
        const item = this.notificationCounts.find(item => `${item.type_id}` === NotificationsEnum.TYPE_REGISTER);
        return item ? item.total : 0;
    }

    get getAffiliatesNotificationsCount (): number {
        const item = this.notificationCounts.find(item => `${item.type_id}` === NotificationsEnum.TYPE_AFFILIATES);
        return item ? item.total : 0;
    }

    get getNotificationsQuery (): NotificationsInterfaces {
        return this.notificationsQuery;
    }

    get getterNotificationsReadList (): Array<NotificationsDataInterfaces> {
        return Object.values(this.notifications).filter(item => item.status.id === EStatusNotification.Read);
    }

    get getterNotificationsUnReadList (): Array<NotificationsDataInterfaces> {
        return Object.values(this.notifications).filter(item => item.status.id === EStatusNotification.Pending || item.status.id === EStatusNotification.New);
    }

    get getterNotificationsUnReadCount (): Number {
        const notifications = Object.values(this.notifications).filter(item => !item.status);
        return notifications.length;
    }

    get getNotificationsPagination (): IPagination {
        return this.notificationsPagination;
    }

    @Mutation
    mutationNotificationsQuerySimple (payload) {
        this.notificationsQuery[payload.key] = payload.value;
    }

    @Mutation
    mutationNotifications (payload: Array<NotificationsDataInterfaces>) {
        this.notifications = payload;
    }

    @Mutation
    mutationNotificationsCounters (payload: Array<notificationsTotalInterface>) {
        this.notificationCounts = payload;
    }

    @Action ({ commit: 'mutationNotificationsQuerySimple' })
    handleNotificationsQuerySimple (payload) {
        return payload;
    }

    @MutationAction ({ mutate: ['notificationsQuery'] })
    async handleNotificationQuery (payload: NotificationsInterfaces) {
        return {
            notificationsQuery: {
                ...NotificationsMessagesModule.getNotificationsQuery,
                ...payload
            }
        };
    }

    @Mutation
    setNotificationsPagination (payload: IPagination) {
        this.notificationsPagination = payload;
    }

    @Action ({ commit: 'mutationNotificationsCounters' })
    async setNotificationsTotal (): Promise<notificationsTotalInterface[]> {
        const response = await Rest.GET(notificationCounter);
        const { data } = await Rest.middleThen(response).catch(Rest.simpleCatch);
        return data;
    }

    @Action
    async firstLoadNotificationsPendingCount (): Promise<void> {
        await this.setNotificationsTotal()
            .then((res) => {
                if (!isPermissionAdmin()) {
                    handlerPendingNotifications(res);
                }
                intervalRequestsService.notifications();
            });
    }

    @Action
    async clearInterval (): Promise<void> {
        intervalRequestsService.clearInterval();
    }

    @Action ({ commit: 'mutationNotifications' })
    async setNotificationsList (query: object): Promise<NotificationsDataInterfaces> {
        const { data, meta } = await Rest.GET(urlNotification({ ...query }))
            .then(Rest.middleThen)
            .catch(Rest.simpleCatch);

        this.setNotificationsPagination(meta);

        return data;
    }

    @Action
    async handleNotificationUpdate ({ notificationId, status, query }): Promise<void> {
        await Rest.PUT(urlNotificationUpdate(notificationId, { status }))
            .then(response => {
                return handlerRestResponse(response, NotificationPrefabricated.NotificationUpdateSuccess);
            })
            .catch(Rest.simpleCatch);

        await NotificationsMessagesModule.setNotificationsList(query);
        await NotificationsMessagesModule.setNotificationsTotal();
    }

    @Action
    async handleNotificationOpen (notificationId: number): Promise<void> {
        await Rest.PUT(urlNotificationOpen(notificationId))
            .then(response => {
                return handlerRestResponse(response, NotificationPrefabricated.NotificationOpenSuccess);
            })
            .catch(Rest.simpleCatch);
        await NotificationsMessagesModule.setNotificationsTotal();
    }
}

export const NotificationsMessagesModule = getModule(NotificationsMessages);
