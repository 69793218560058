import { IConfigValue } from '~/settings/interfaces/config-default-interfaces';
import { IPaymentRequisiteResponse } from '../payment-requisites-interfaces';

export const paymentRequisitesSourceAvailable = (reqisites: IPaymentRequisiteResponse[]):IConfigValue[] => {
    return reqisites?.reduce((result, current) :IConfigValue[] => {
        const value = current.iban || current.card_mask || current.crypto_wallet;
        const hasSameValue = result.some(item => item.value == value);
        if (value && !hasSameValue) {
            result.push({
                id: current.id,
                name: value,
                value: value,
            });
        }
        return result;
    }, []);
};

export const paymentRequisitesSubTypeAvailable = (
    reqisites: IPaymentRequisiteResponse[]
):IConfigValue[] => {
    return reqisites?.reduce((result, current) :IConfigValue[] => {
        const subTypeValue = current.subtype;
        const hasSameValue = result.some(item => item.value == subTypeValue);
        if (subTypeValue && !hasSameValue) {
            result.push({
                id: current.id,
                name: subTypeValue,
                value: subTypeValue,
            });
        }
        return result;
    }, []);
};
