export default {
    data () {
        return {
            isLoading : false
        };
    },
    methods : {
        startLoading () {
            this.isLoading = true;
        },
        endLoading () {
            this.isLoading = false;
        },
    }
};
