export enum EPaymentMethodName {
    creditCard = 1,
    wireTransfer = 2,
    eWallet = 3,
    cryptoWallet = 4,
}

export enum EPaymentMethod {
    skrill = 1,
    neteller = 10,
    wireTransfer = 20,
    creditCard = 14,
    cryptoWallet = 12,
}

export enum EPaymentMethodTitle {
    wireTransfer = 'Banking Details',
    cryptoWallet = 'Crypto Wallet Details',
}

export enum EPaymentKeyMethodName {
    card_mask = 1,
    iban = 2,
    crypto_wallet = 4,
}
