import Vue from 'vue';
import { PropType } from 'vue';
import { KycTradingEnums, TradingSumInfoEnums } from '~/store/modules/kyc-trading/kyc-trading.enums';
import toggleMixin from '~/components/mixins/toggleMixin';
import appMultiSelect from '~/components/parts/app-multiselect/app-multiselect.vue';
import leadDetailsMixin from '~/components/mixins/leadDetailsMixin';
import { LeverageItemEnum } from './enum/leverage-item.enum';
import { KycTradingModule } from '~/store/modules/kyc-trading/kyc-trading';
import { NotificationPrefabricated } from '~/helpers/modal-notification-services/modal-notification-services';
import configsMixin from '~/components/mixins/configs-mixin';
import { EDepartments } from '~/settings/enums/departments.enum';
import { ModalsModule } from '~/store/modules/modals';
import {
    ILeverageItem,
    ILeverageModified,
} from '~/components/leadsItem/leads-item-trading/leverage-item/interface/leverage-item-interface';
import { ProfileModule } from '~/store/modules/profile/profile';
import { isPermissionChangeLeverage, isPermissionAdmin } from '~/helpers/user-permission';
import { ILeveragesFormatted } from '~/store/modules/configs/interfaces/configs-interface';

export default Vue.extend({
    components: {
        appMultiSelect
    },
    mixins: [
        toggleMixin,
        leadDetailsMixin,
        configsMixin
    ],
    props: {
        title: {
            type: String,
            required: true
        },
        leverageItem: {
            type: Object as PropType<ILeverageItem>,
            required: true
        },
        lengthSelectedAccounts: {
            type: Number,
        },
        tradingCurrencySign: {
            type: String
        },
        disabled: {
            type: Boolean
        }
    },
    data () {
        return {
            isOpen: false,
            currentLeverageData: null,
        };
    },
    computed: {
        isPermissionChangeLeverage,
        isPermissionAdmin,
        currentLeverage: {
            get (): ILeverageModified {
                return this.getModifyCurrentLeverage(
                    this.currentLeverageData || this.leverageItem
                );
            },
            set (value: ILeveragesFormatted): void {
                const modifyCurrentLeverage = this.getModifyCurrentLeverage(value);
                this.currentLeverageData = modifyCurrentLeverage;
                this.preChangeLeverage(modifyCurrentLeverage);
            }
        },
        isLeverage (): boolean {
            return this.title === TradingSumInfoEnums.leverage;
        },
        isEdit (): boolean {
            return this.isLeverage
                && this.isPermissionChangeLeverage
                && this.currentLeverage.id
                && ProfileModule.getProfile.department_id === EDepartments.Brokerage
                || this.isPermissionAdmin;
        },
        accounts (): Array<string|number>|string {
            return KycTradingModule.getTradingQuery[KycTradingEnums.Accounts];
        },
        accountsIds (): Array<number> {
            return Array.isArray(this.accounts)
                ? this.accounts.map(id => +id)
                : [+this.accounts];
        },
        getOptionsLeverages (): ILeveragesFormatted[] {
            return this.leadDetails?.professional_level?.id === LeverageItemEnum.Retail
                ? this.getConfigLeveragesFormatted.filter(
                    (leverage: ILeveragesFormatted) => leverage.professional_level_id === LeverageItemEnum.Retail
                )
                : this.getConfigLeveragesFormatted;
        },
        isReadOnly (): boolean {
            return !this.isEdit || this.disabled;
        }
    },
    methods: {
        async handleSelectLeverage (event: ILeveragesFormatted): Promise<void> {
            const query = this.$route.query;

            const updateData = {
                account_ids: this.accountsIds,
                leverage_value: event.value
            };

            if (this.accounts.length > 0) {
                await KycTradingModule.handleLeverageUpdate(updateData);
                await KycTradingModule.setTradingSumInfo(query);
                await NotificationPrefabricated.LeverageChangeSuccess();
            } else {
                await NotificationPrefabricated.LeverageChangeError();
            }
        },
        preChangeLeverage (value: ILeverageModified): void {
            const {
                top,
                left,
            } = this.$refs.leverageSelect.$el.getBoundingClientRect();

            ModalsModule.openModal({
                modalType: 'actionModal',
                props: {
                    body: {
                        text: `Are you sure you want to change leverage for ${this.lengthSelectedAccounts} account${this.lengthSelectedAccounts > 1 ? 's' : ''}?`,
                    },
                    isHeader: true,
                    isFooter: false,
                    confirm: () => {
                        this.handleSelectLeverage(value);
                    },
                    close: () => {

                        this.resetCurrentLeverageData();
                    }
                },
                modalSettings: {
                    props: {
                        minWidth: ModalsModule.modalWidth.xxs,
                    },
                    positionModal: {
                        clientX: left,
                        clientY: top,
                        modalWidth: ModalsModule.modalWidth.xxs,
                    }
                },
            });
        },
        getModifyCurrentLeverage (leverageItem: ILeverageItem): ILeverageModified {
            return {
                ...leverageItem,
                title: this.title,
                sign: this.tradingCurrencySign,
            };
        },
        resetCurrentLeverageData (): void {
            this.currentLeverageData = null;
        }
    }
});
