import { EPermissions } from '~/settings/enums/permissions.enum';

export enum ERouterNameFromPermission {
    calendar = 'calendar',
    users = 'users',
    leads = 'leads',
    leadsItem = 'leadsItem',
    leadsItemsDetails = 'leadsItemsDetails',
    leadsItemsKyc = 'leadsItemsKyc',
    leadsItemsTrading = 'leadsItemsTrading',
    leadsItemsFinance = 'leadsItemsFinance',
    leadsItemsMarketing = 'leadsItemsMarketing',
    leadsItemsActivity = 'leadsItemsActivity',
    leadsItemsFiltersTemplates = 'leadsItemsFiltersTemplates',
    compliance = 'compliance',
    withdrawals = 'withdrawals',
    accounting = 'accounting',
    accountingReports = 'accountingReports',
    commissionDatabase = 'commissionDatabase',
    bonusPlan = 'bonusPlan',
    managers = 'managers',
    employees = 'employees',
    reports = 'reports',
    affiliates = 'affiliates',
    affiliatesReports = 'affiliatesReports',
    affiliatesCompliance = 'affiliatesCompliance',
    affiliatesPage = 'affiliatesPage',
    affiliatesBonusPlan = 'affiliatesBonusPlan',
    affiliatesCampaigns = 'affiliatesCampaigns',
    emails = 'emails',
    emailsMass = 'emailsMass',
    emailsIndividual = 'emailsIndividual',
    emailsInbox = 'emailsInbox',
    emailsChat = 'emailsChat',
    emailsTemplates = 'emailsTemplates',
    notifications = 'notifications',
    notificationsClients = 'notificationsClients',
    notificationsActivityLog = 'notificationsActivityLog',
    settings = 'settings',
    settingsGroup = 'settingsGroup',
    documentsComponent = 'documentsComponent',
    settingsSymbols = 'settingsSymbols',
    unauthorizedAccess = 'unauthorizedAccess',
}

export enum ERouterNameByPermissionId {
    calendar = EPermissions.calendar,
    users = EPermissions.viewUsers,
    leads = EPermissions.viewClient,
    affiliatesCampaigns = EPermissions.campaigns,
    compliance = EPermissions.complianceReadOnly,
    leadsItemsFinance = EPermissions.finance,
    withdrawals = EPermissions.withdrawOrders,
    accounting = EPermissions.accounting,
    accountingReports = EPermissions.reportByTeam,
    commissionDatabase = EPermissions.commissionDatabase,
    bonusPlan = EPermissions.bonusPlan,
    reports = EPermissions.reports,
    affiliates = EPermissions.affiliate,
    emails = EPermissions.emails,
    notifications = EPermissions.notificationsRegister,
}
