var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{ref:"validationProvider",staticClass:"b-input-wrap",attrs:{"rules":_vm.validation,"mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
return [_c('div',{class:[
            'b-input',
            _vm.isNoMargin ? 'b-input--no-margin': '',
            _vm.noBorder ? 'b-input--no-border': '',
            _vm.noRounding ? 'b-input--no-rounding': '',
            _vm.addClass ? ("b-input--" + _vm.addClass) : '',
            _vm.size ? ("b-input--" + _vm.size) : '',
            classes
        ],on:{"click":function($event){return _vm.handleClick($event)}}},[_c('input',{attrs:{"type":"hidden","name":_vm.name,"autocomplete":"off"},domProps:{"value":_vm.isValueString}}),_vm._v(" "),(_vm.isExistLabel)?_c('span',{staticClass:"b-input__label"},[_vm._v("\n            "+_vm._s(_vm.isLabel)+"\n        ")]):_vm._e(),_vm._v(" "),_c('multiselect',_vm._g(_vm._b({ref:"appMultiSelect",class:[
                _vm.className,
                _vm.isCleanButton ? (_vm.className + "--is-clean-button") : '',
                _vm.readonly ? (_vm.className + "--readonly") : '',
                _vm.placeholderCenter ? (_vm.className + "--placeholder-center") : '',
                _vm.isOpened ? (_vm.className + "--opened") : '',
                _vm.isExistValue ? (_vm.className + "--selected") : '' ],attrs:{"disabled":_vm.readonly,"close-on-select":_vm.closeOnSelect,"options":_vm.isOptions,"hide-selected":_vm.selectedList && _vm.isOptionsLongList && _vm.isMultiple,"searchable":_vm.allBindings.searchable || _vm.isOptionsLongList},on:{"open":_vm.removeInputError,"close":_vm.handleClose},scopedSlots:_vm._u([{key:"singleLabel",fn:function(ref){
            var option = ref.option;
return [_c(_vm.isMultiselectSingleLabelType,{tag:"component",attrs:{"option":option,"className":_vm.className,"isTrackBy":_vm.isTrackBy,"label":_vm.label,"placeholder":_vm.allBindings.placeholder}})]}},{key:"beforeList",fn:function(scope){return [(_vm.isGroups)?_c('groups',{attrs:{"groups":_vm.groups,"selectedGroup":_vm.selectedGroup},on:{"selectGroup":function($event){return _vm.selectGroup($event)}}}):_vm._e(),_vm._v(" "),(_vm.noneOption)?_c('none-option',{on:{"close":function($event){return _vm.handleClose(scope)},"selectNone":_vm.selectNone}}):_vm._e(),_vm._v(" "),(_vm.allReset && !_vm.allResetDisabled)?_c('all-option',{on:{"close":function($event){return _vm.handleClose(scope)},"selectAll":_vm.selectAll}}):_vm._e(),_vm._v(" "),(_vm.selectedList && _vm.isOptionsLongList && _vm.isValueObj && _vm.isMultiple)?_c('div',{class:[(_vm.className + "__selected-value-before-list")]},[(_vm.isMultiple)?_vm._l((_vm.isValueObj),function(option,index){return _c('div',{key:index,staticClass:"multiselect__option multiselect__option--selected",on:{"click":function($event){return _vm.removeSelectedOption(option)}}},[_c((_vm.multiselectType + "-component"),{key:index,tag:"component",attrs:{"option":option,"className":_vm.className,"isTrackBy":_vm.isTrackBy,"label":_vm.label}})],1)}):_c('div',{staticClass:"multiselect__option multiselect__option--selected",on:{"click":function($event){return _vm.removeSelectedOption(_vm.isValueObj)}}},[_vm._v("\n                        "+_vm._s(_vm.isValueObjLabel)+"\n                    ")])],2):_vm._e()]}},{key:"option",fn:function(ref){
            var option = ref.option;
return [_c((_vm.multiselectType + "-component"),{tag:"component",attrs:{"option":option,"className":_vm.className,"isTrackBy":_vm.isTrackBy,"label":_vm.label}})]}}],null,true)},'multiselect',_vm.allBindings,false),_vm.$listeners),[_c('template',{slot:"selection"},[(_vm.isExistValue && _vm.isMultiple)?_c('span',{ref:"selection",staticClass:"multiselect__single"},[(_vm.isValueLength > 1)?[_vm._v("\n                        "+_vm._s(_vm.isValueLength)+" options\n                    ")]:[_c(_vm.isMultiselectSingleLabelType,{tag:"component",attrs:{"option":_vm.isMultipleSingleLabel,"className":_vm.className,"isTrackBy":_vm.isTrackBy,"label":_vm.label}})]],2):_vm._e()]),_vm._v(" "),_vm._v(" "),_c('template',{slot:"placeholder"},[_c('div',{class:(_vm.className + "__placeholder")},[_vm._v("\n                    "+_vm._s(_vm.allBindings.placeholder || 'Select')+"\n                ")])]),_vm._v(" "),_vm._v(" "),_c('template',{slot:"afterList"},[(_vm.isLoadMoreButton)?_c('element-option',{staticClass:"d-flex align-center justify-content-center",on:{"click":_vm.loadMore}},[_vm._v("\n                    Load More\n                ")]):_vm._e()],1),_vm._v(" "),_vm._v(" "),_c('template',{slot:"clear"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isCleanButton),expression:"isCleanButton"}],class:(_vm.className + "__clear " + _vm.classResetButton),on:{"click":_vm.handleClean}},[_c('material-icon',{attrs:{"symbol":"close","color":"red","size":"xs","title":"Clear","delay":""}})],1)]),_vm._v(" "),_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v("Oops! No elements found. Consider changing the search query.")])],2)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }