import Vue from 'vue';
import currencyHelper from '~/components/mixins/currency-helper';
import rowItemPropsMixin from '@/js/components/tables/mixins/table-cells-mixins/row-item-props-mixin';

export default Vue.extend({
    mixins: [
        currencyHelper,
        rowItemPropsMixin,
    ],
    computed: {
        isAmount (): string {
            return this.cell.value.account;
        },
        isCurrentCurrency (): string {
            return this.cell.value.currency?.name;
        },
    }
});
