<template>
    <div class="copyrights">
        &#xa9; AAATrade, All Rights reserved
    </div>
</template>

<script>
export default {
    name: 'AppCopyrights',
};
</script>

<style lang="scss">
    .copyrights {
          display: flex;
          align-items: center;
          height: $footer-height;
          padding-right: 1.5rem;
          padding-left: 1.5rem;
          font-family: 'ArialMT', sans-serif;
          font-size: 1.4rem;
          line-height: 1.8rem;
          margin-left: auto;
    }
</style>