import { Action, getModule, Module, Mutation, MutationAction, VuexModule } from 'vuex-module-decorators';
import store from '~/store/index';
import Rest from '~/helpers/Rest';
import { urlLeverageUpdate } from '~/settings/ajax-urls';
import { leadsPositionsTradingTableHeader, leadsPendingTradingTableHeader, leadsHistoryTradingTableHeader } from '~/components/tables/_headers/leads-trading';
// @ts-ignore
import { tableParser } from '~/helpers/table-parser';
import {
    FilterTradingInterfaces,
    TradingPaginationInterface,
    TradingSumInfoInterface,
    TradingQueryPayload,
    handleTradingQueryInterface
} from './kyc-trading-interfaces';
import { TradingTabsNameEnums, KycTradingEnums } from './kyc-trading.enums';
import {
    getAjaxUrlForOrders,
    getDefaultPagination,
    getAjaxSumValue
} from './kyc-trading-helper';
import router from '~/router';
import { ITableHeaderColumn } from '~/components/tables/interfaces/table-header-column.interfaces';
import { ITableCell } from '~/components/tables/interfaces/table-cell-interfaces';
import moment from 'moment';
import { NotificationPrefabricated } from '@/js/helpers/modal-notification-services/modal-notification-services';

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: 'kycTrading',
})
class KycTrading extends VuexModule {
    currentTradingTab: string = TradingTabsNameEnums.OpenOrders;
    tradingInfo: Array<object> = [];
    currentTable: Array<object> = [];
    pagination: TradingPaginationInterface = {
        current_page: null,
        from: null,
        last_page: null,
        per_page: null,
        to: null,
        total: null,
    };
    tradingQuery: FilterTradingInterfaces = {
        accounts: [],
        start_date: null,
        end_date: null,
        page: null,
        per_page: null
    };
    loaded: boolean = false;

    get getTradingQuery (): FilterTradingInterfaces {
        return this.tradingQuery;
    }

    get getStartDay (): string {
        const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
        return this.tradingQuery.start_date || startOfMonth;
    }

    get getEndDay (): string {
        const endOfMonth   = moment().endOf('month').format('YYYY-MM-DD');
        return this.tradingQuery.end_date || endOfMonth;
    }

    get getTradingPagination (): TradingPaginationInterface {
        return this.pagination;
    }

    get getTradingInfo (): Array<object> {
        return this.tradingInfo;
    }

    get getCurrentTradingTab (): string {
        return this.currentTradingTab;
    }

    get getPositionHeader (): ITableHeaderColumn[] {
        return leadsPositionsTradingTableHeader;
    }

    get getPendingHeader (): ITableHeaderColumn[] {
        return leadsPendingTradingTableHeader;
    }

    get getHistoryHeader (): ITableHeaderColumn[] {
        return leadsHistoryTradingTableHeader;
    }

    get getTradingPendingTable (): ITableCell[] {
        return tableParser(this.getPendingHeader, this.currentTable);
    }

    get getTradingPositionTable (): ITableCell[] {
        return tableParser(this.getPositionHeader, this.currentTable);
    }

    get getHistoryTradingTable (): ITableCell[] {
        return tableParser(this.getHistoryHeader, this.currentTable);
    }

    get getAccounts (): Array<string|object|number> {
        const accounts: Array<number|string>|string = this.tradingQuery[KycTradingEnums.Accounts];
        return typeof accounts === 'string' ? accounts.split(',') : accounts;
    }

    get getTradingIsLoaded (): boolean {
        return this.loaded;
    }

    @Mutation
    SET_TRADING_SUM_INFO (payload: Array<TradingSumInfoInterface>) {
        this.tradingInfo = payload;
    }

    @Mutation
    mutationTradingQuerySimple (payload) {
        this.tradingQuery[payload.key] = payload.value;
    }

    @Mutation
    setLoadedState (payload: boolean) {
        this.loaded = payload;
    }

    @Mutation
    setCurrentTable (payload) {
        this.currentTable = payload;
    }

    @Mutation
    setCurrentTradingTab (payload: string) {
        this.currentTradingTab = payload;
    }

    @Mutation
    setPagination (payload: TradingPaginationInterface) {
        this.pagination = payload;
    }

    @MutationAction({ mutate: ['tradingQuery'] })
    async handleTradingQuery (payload: FilterTradingInterfaces): Promise<handleTradingQueryInterface> {
        return {
            tradingQuery: {
                ...KycTradingModule.getTradingQuery,
                ...payload
            }
        };
    }

    @Action ({ commit: 'mutationTradingQuerySimple' })
    handleTradingQuerySimple (payload: TradingQueryPayload): TradingQueryPayload {
        return payload;
    }

    @Action
    async handleLeverageUpdate (updatedData): Promise<void> {
        return await Rest.PUT(urlLeverageUpdate(+router.currentRoute.params.id), updatedData)
            .then(Rest.middleNoContent)
            .catch(error => {
                Rest.simpleCatch(error);
            });
    }

    @Action ({ commit: 'SET_TRADING_SUM_INFO' })
    async setTradingSumInfo (query = {}): Promise<TradingSumInfoInterface> {
        return await getAjaxSumValue({
            query,
            leadId: router.currentRoute.params.id
        });
    }

    @Action
    async loadOrders ({ query = {}, type }) {
        this.setLoadedState(false);
        const leadId = router.currentRoute.params.id;

        const {
            data = [],
            meta = getDefaultPagination()
        } = await getAjaxUrlForOrders({ query, leadId, type });

        NotificationPrefabricated.cleanAllNotify();
        this.setCurrentTable(data);
        this.setPagination(meta);
        this.setCurrentTradingTab(type);
        this.setLoadedState(true);
    }
}
export const KycTradingModule = getModule(KycTrading);
