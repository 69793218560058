import Vue from 'vue';
import { regHasFirstPlus } from '~/helpers/regular-expressions';

export default Vue.extend({
    props: {
        option: {
            type: Object,
            required: true,
            default: function () {
                return {};
            }
        },
        className: {
            type: String,
        },
    },
    computed: {
        isPhoneCodeFormat (): string {
            const phone = this.option.phone_code || this.option.phone;
            return phone && regHasFirstPlus.test(phone) ?
                phone :
                `+${phone}`;
        },
    }
});
