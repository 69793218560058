export enum defaultProfileEnum {
    id = 0,
    email = '',
    username = '',
    fname = '',
    lname = '',
    department_id  = 0,
    department_name  =''
}

export enum defaultAuthForm {
    passwordResetSuccess = ''
}
