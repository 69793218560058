import Vue from 'vue';
import modalLayout from '~/components/modals/root-modal-layout/root-modal-layout.vue';

import modalsMixin from '~/components/mixins/modals';
import docNew from '~/components/modals/doc-modal/doc-modal-new/doc-modal-new.vue';
import docExist from '~/components/modals/doc-modal/doc-modal-existing/doc-modal-existing.vue';
import { ModalsModule } from '~/store/modules/modals';
import { affiliatesDocumentsModule } from '~/store/modules/affiliates/affiliates-documents';
import AppDocumentViewer from '~/components/parts/app-document-viewer/app-document-viewer.vue';
import { EAffiliatesDocumentStatuses } from '~/settings/enums/affiliates-document-statuses.enum';
import configsMixin from '~/components/mixins/configs-mixin';
import { EAffiliatesCompliance } from '~/settings/enums/affiliates-compliance.enum';
import {
    IAffiliatesDocumentUpdate,
    IAffiliatesDocumentUpdatePayload,
} from '~/store/modules/affiliates/interfaces/affiliates-documents-interfaces';
import { DocumentsTypesInterface } from '~/store/modules/documents/interfaces';
import { DocumentTypesSort } from '~/store/modules/documents/document-type-helper';

export default Vue.extend({
    components: {
        modalLayout,
        docNew,
        docExist,
        AppDocumentViewer,
    },
    mixins: [
        modalsMixin,
        configsMixin,
    ],
    data (): Object {
        return {
            tempIsReview: null,
        };
    },
    computed: {
        isReview: {
            get (): boolean {
                if (this.tempIsReview !== null) {
                    return this.tempIsReview;
                }
                const status = this.modalData.status_id;
                return status === EAffiliatesDocumentStatuses.pending;
            },
            set (isReview: boolean) {
                this.tempIsReview = isReview;
            }
        },
        statusId () {
            return this.modalData.status_id;
        },
        modalType () {
            return this.$attrs['modal-type'];
        },
        documentType () {
            const documentObject = this.getConfigAffiliateDocumentTypes
                .find(item => item.id === this.modalData.type_id);
            return documentObject ? documentObject.name : 'Other';
        },
        getDocumentTypes (): DocumentsTypesInterface[] {
            return DocumentTypesSort(this.getConfigAffiliateDocumentTypes);
        }
    },
    methods: {
        closeModal () {
            ModalsModule.closeModal(this.modalType);
        },
        async deleteDocument () {
            const query = this.$route.query;
            await affiliatesDocumentsModule.affiliatesDocumentDelete({
                document: this.modalData.id,
                affiliate: query[EAffiliatesCompliance.affiliate_id],
            })
                .then(() => {
                    this.closeModal();
                });
        },
        changeIsReview (isReview: boolean): void {
            this.isReview = isReview;
        },
        loadAffiliatesDocuments () {
            const query = this.$route.query;
            affiliatesDocumentsModule.affiliatesDocumentsList({
                affiliate: query[EAffiliatesCompliance.affiliate_id],
            });
        },
        async approveDocument (event: IAffiliatesDocumentUpdate) {
            const query = this.$route.query;
            const payload = {
                document_id: this.modalData.id,
                affiliate: query[EAffiliatesCompliance.affiliate_id],
                document: {
                    ...event,
                    status_id: EAffiliatesDocumentStatuses.approved,
                },
            };
            this.updateDocument(payload);
        },
        async rejectDocument (event: IAffiliatesDocumentUpdate) {
            const query = this.$route.query;
            const payload = {
                document_id: this.modalData.id,
                affiliate: query[EAffiliatesCompliance.affiliate_id],
                document: {
                    ...event,
                    status_id: EAffiliatesDocumentStatuses.rejected,
                },
            };
            this.updateDocument(payload);
        },
        async changeDocument (event: IAffiliatesDocumentUpdate) {
            const query = this.$route.query;
            const payload = {
                document_id: this.modalData.id,
                affiliate: query[EAffiliatesCompliance.affiliate_id],
                document: {
                    ...event,
                },
            };
            this.updateDocument(payload);
        },
        async updateDocument (payload: IAffiliatesDocumentUpdatePayload) {
            await affiliatesDocumentsModule.affiliatesDocumentUpdate(payload)
                .then(() => {
                    this.closeModal();
                });
        }
    }
});
