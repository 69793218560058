import { IPaymentRequisiteResponse } from '~/store/modules/payment-requisites/payment-requisites-interfaces';

export const paymentRequisitesCleanData = (payload: Partial<IPaymentRequisiteResponse>): IPaymentRequisiteResponse => {
    const result = {} as IPaymentRequisiteResponse;
    for (const property in payload) {
        if (payload[property] !== null) {
            result[property] = payload[property];
        }
    }
    return result;
};
