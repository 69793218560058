import { extend } from 'vee-validate';

const hasNumeric = (value): boolean => {
    return /\d/.test(value);
};

const hasCharacter = (value) => {
    return /[`!@#$%^&*()_+\-={};':"\\|,.<>?~]/.test(value);
};

const hasUpperLetter = (value) => {
    return /[A-Z]/g.test(value);
};

export const passwordValidate = extend('password-validate', value => {
    if (!hasNumeric(value)) {
        return 'At least one digit required.';
    }

    if (!hasCharacter(value)) {
        return 'You need add one symbol.';
    }

    if (!hasUpperLetter(value))  {
        return 'You need add Upper letter.';
    }

    return true;
});
