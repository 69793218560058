import { EDepartments } from '~/settings/enums/departments.enum';
export const departmentNotes = [
    EDepartments.compliance,
    EDepartments.sales,
    EDepartments.customerSupport,
];

export const departmentSales = [
    EDepartments.sales,
    EDepartments.salesManagement
];

export const departmentCustomerSupport = [
    EDepartments.customerSupport,
    EDepartments.customerCareManagement
];
