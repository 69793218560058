export const affiliatesType = [
    {
        id: 1,
        value: 'affiliate',
        name: 'By Affiliate',
    },
    {
        id: 2,
        value: 'campaign',
        name: 'By Campaign',
    },
];

export enum EAffiliateReportsTableType {
    'affiliates-reports' = 1,
    'affiliates-reports-campaign' = 2,
}

export enum EReportsType {
    affiliate = 'affiliate',
    campaign = 'campaign'
}
