import numeral from 'numeral';


export function commaToValue (val = '') {
    // 1000 => 1,000
    return numeral(val).format('0,0');
}

export function formattedAmount (val = '') {
    // 1000 => 1,000.00
    return numeral(val).format('0,0.00');
}

export function roundToTenth (val = '') {
    // 12.12312321 => 12.12
    return numeral(val).format('0[.]00');
}

export function cutThousandToK (val = '') {
    // 1000 => 1k
    return numeral(val).format('0a');
}

export function mathAbs (val = 0) {
    return Math.abs(val);
}


export const formatErrorMessage = (message, defaultMsg = 'Something went wrong') => {
    let formatted = _formatErrorMessage(message);
    if (!formatted) formatted = defaultMsg;
    return formatted;
};
const _formatErrorMessage = message => {
    switch (true) {
    case typeof message === 'string' : {
        return message;
    }
    case Array.isArray(message) : {
        return message.join('\n ');
    }
    case ((typeof message === 'object') && !!Object.values(message).length) : {
        let formatted = '';
        for (let key in message) {
            formatted += `${_formatErrorMessage(message[key])} `;
        }
        return formatted;
    }
    default : {
        return '';
    }
    }
};

export const formatOffset = (offset) => {
    let hours = Math.abs(Math.floor(offset / 60));
    let minutes = Math.abs(offset % 60);

    hours = ('0' + hours).slice(-2);
    minutes = ('0' + minutes).slice(-2);

    return `(GTM: ${offset < 0 ? '-' : ''}${hours}:${minutes})`;
};
