import Vue from 'vue';
import CryptoWallet from '~/components/_forms/payment-method-forms/crypto-wallet/crypto-wallet.vue';
import CreditCard from '~/components/_forms/payment-method-forms/credit-card/credit-card.vue';
import EWallet from '~/components/_forms/payment-method-forms/e-wallet/e-wallet.vue';
import WireTransfer from '~/components/_forms/payment-method-forms/wire-transfer/wire-transfer.vue';
import FinanceContentItemHeader from '~/components/finance-content-item-header/finance-content-item-header.vue';
import FinanceContentItemBody from '~/components/finance-content-item-body/finance-content-item-body.vue';
import addPaymentTab from '~/components/mixins/add-payment-tab';
import { PaymentRequisitesModule } from '~/store/modules/payment-requisites/payment-requisites';
import { EPaymentMethodName } from '~/settings/enums/payment-methods.enum';
import moment from 'moment';
import formsHelper from '~/components/mixins/forms-helper';
import { PaymentMethodsModule } from '@/js/store/modules/configs/payment-methods/payment-methods';
import { ELeadsPaymentsModal } from '~/settings/enums/leads-payments-modal.enum';
import modalLayout from '~/components/modals/root-modal-layout/root-modal-layout.vue';

export default Vue.extend({
    components: {
        modalLayout,
        FinanceContentItemHeader,
        FinanceContentItemBody,
        CryptoWallet,
        CreditCard,
        EWallet,
        WireTransfer,
    },
    mixins: [
        addPaymentTab,
        formsHelper,
    ],
    methods: {
        handleCloseForm (): void {
            this.resetModal();

            if (PaymentMethodsModule.getPaymentMethodSelected?.id !== null) {
                PaymentMethodsModule.mutationPaymentProviderMethodSelected(null);
            }

        },
        generatePayload () {
            const currentForm = this.$refs.addPaymentForm;

            switch (this.isMethodsSelected.id) {
            case EPaymentMethodName.wireTransfer:
                return {
                    'method_id': currentForm.isMethodsSelected.id,
                    'bank_name': currentForm.bank_name,
                    'bank_address': currentForm.bank_address,
                    'swift': currentForm.swift,
                    'owner_name': currentForm.owner_name,
                    'iban': currentForm.iban,
                };
            case EPaymentMethodName.creditCard:
                return {
                    'method_id': currentForm.isMethodsSelected.id,
                    'card_mask': currentForm.card_mask,
                    'expire_at': moment(currentForm.expire_at).format('YYYY-MM'),
                    'owner_name': currentForm.owner_name || 'client name',
                };
            case EPaymentMethodName.cryptoWallet:
                return {
                    'method_id': currentForm.isMethodsSelected.id,
                    'currency_id': currentForm.currency.id,
                    'crypto_wallet': currentForm.crypto_wallet,
                };
            case EPaymentMethodName.eWallet:
                return {
                    'method_id': currentForm.isMethodsSelected.id,
                    'provider_id': currentForm.provider.id,
                    'email': currentForm.email,
                };
            }
        },
        async onSubmitForm (): Promise<void> {
            const isValid = this.isMethodsSelectedValue && await this.$refs.observer.validate();

            if (isValid) {
                const payload = this.generatePayload();

                await PaymentRequisitesModule.addPaymentRequisite(payload)
                    .then(res => {
                        if (res) {
                            this.handleCloseForm();
                            PaymentRequisitesModule.loadPaymentRequisites();
                        }
                    });

                this.resetModal();
            }
        },
        resetModal () {
            this.$emit('changeModal', {
                name: ELeadsPaymentsModal.paymentMethods,
            });
        },
    }

});
