// Imports
import Vue from 'vue';
import rowItemPropsMixin from '@/js/components/tables/mixins/table-cells-mixins/row-item-props-mixin';

// Exports
export default Vue.extend({
    mixins: [
        rowItemPropsMixin,
    ],
    computed: {
        isValue (): string {
            return this.cell.value?.date && this.cell.value?.time
                ? `${this.cell.value.date} | ${ this.cell.value.time }`
                : '-';
        }
    }
});
