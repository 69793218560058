// Imports
import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import { tableParser } from '~/helpers/table-parser';
import store from '~/store';
import { accountingEmployeesTableHeader } from '~/components/tables/_headers/accounting-employees';

// MOCK
import { AccountingEmployeesItems } from '~/mock-data/mock-accounting-employees';
@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: 'accountingEmployees',
})

class AccountingEmployees extends VuexModule {
    AccountingEmployeesItems: Array<object> = AccountingEmployeesItems;
    currentTable: Array<object> = [];

    get getterHeader (): Array<object> {
        return accountingEmployeesTableHeader;
    }

    get getterTable (): Array<object> {
        return this.currentTable;
    }

    get getterEmployeeList (): Array<Object> {
        return this.AccountingEmployeesItems;
    }

    @Mutation
    setAccountingEmployeesTable (table: Array<Object>) {
        this.currentTable = table;
    }

    @Mutation
    mutationAddEmployee (employeesTableItem) {
        this.AccountingEmployeesItems = [
            ...this.AccountingEmployeesItems,
            {
                ...employeesTableItem
            }
        ];
    }

    @Action ({ commit: 'setAccountingEmployeesTable' })
    actionSetEmployeesTable () {
        const parsedRows = tableParser(this.getterHeader, this.getterEmployeeList);
        return parsedRows;
    }

    @Action
    actionAddEmployees ({ fullName, bonusPlan, status }) {
        // TODO add request to server
        const id = `${Math.random()}`;
        this.mutationAddEmployee({
            id,
            fullName: fullName,
            department: 'Developers',
            role: 'Front-end',
            bonusPlan: bonusPlan,
            status: status
        });
        this.actionSetEmployeesTable();
    }
}

export const AccountingEmployeesModule = getModule(AccountingEmployees);
