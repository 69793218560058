import Vue from 'vue';
// @ts-ignore
import modalLayout from '~/components/modals/root-modal-layout/root-modal-layout';

import modalsMixin from '~/components/mixins/modals';

export default Vue.extend({
    components: {
        modalLayout
    },
    mixins: [modalsMixin],

});
