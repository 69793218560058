import Vue from 'vue';
import settingsNavbar from '~/components/settings/settings-navbar/settings-navbar.vue';
import settingsDocuments from '~/components/settings/settings-documents/documentsComponent.vue';

export default Vue.extend({
    components: {
        settingsNavbar,
        settingsDocuments
    }
});
