import { ITableHeaderColumn } from '~/components/tables/interfaces/table-header-column.interfaces';
import {
    ETableFilterInputType,
    ETableFilterType,
} from '~/components/tables/enums/table-filter-type.enum';
import { tableHeaderParser } from '~/components/tables/helpers/table-header-parser';

export const affiliatesTableHeader: ITableHeaderColumn[] = tableHeaderParser([
    {
        id: 1,
        name: 'full_name',
        value: 'Name',
        cellType: 'plain-text',
        cellData: 'full_name',
        filterType: ETableFilterType.input,
        inputType: ETableFilterInputType.text,
    },
    {
        id: 2,
        name: 'bonus',
        value: 'Bonus Plan',
        cellData: 'bonus',
        cellType: 'key-from-object',
        cellDataKey: 'name',
        filterType: ETableFilterType.select,
        filterMultiple: true,
    },
    {
        id: 3,
        name: 'affiliate_type',
        value: 'Type',
        cellData: 'type',
        cellType: 'key-from-object',
        cellDataKey: 'name',
        filterType: ETableFilterType.select,
    },
    {
        id: 4,
        name: 'phone',
        value: 'Phone Number',
        cellType: 'phone-number',
        cellData: ['phone_mobile', 'phone_mobile_country'],
        phone: true,
        filterType: ETableFilterType.input,
        inputType: ETableFilterInputType.number,
    },
    {
        id: 6,
        name: 'country',
        value: 'Country',
        cellType: 'flag-country',
        cellData: 'country',
        filterType: ETableFilterType.select,
        filterMultiple: true,
    },
    {
        id: 7,
        name: 'language',
        value: 'Language',
        cellType: 'key-from-object',
        cellDataKey: 'name',
        cellData: 'language',
        filterType: ETableFilterType.select,
        filterMultiple: true,
    },
    {
        id: 8,
        name: 'status',
        value: 'Status',
        cellType: 'affiliates-status',
        cellData: 'status',
        cellDataKey: 'id',
        filterType: ETableFilterType.select,
    },
    {
        id: 10,
        name: 'website',
        value: 'Verified Website(s)',
        cellType: 'website-list',
        cellData: 'website',
        filterType: ETableFilterType.input,
        inputType: ETableFilterInputType.text,
    },
    {
        id: 11,
        name: 'rep_name',
        value: 'Rep Name',
        cellType: 'plain-text',
        cellData: 'representative_name',
        filterType: ETableFilterType.input,
        inputType: ETableFilterInputType.text,
    },
    {
        id: 12,
        name: 'dob',
        value: 'Date of Birth',
        cellType: 'plain-text',
        cellData: 'dob',
        filterType: ETableFilterType.input,
        inputType: ETableFilterInputType.text,
        textAlign: 'center',
    },
    {
        id: 13,
        name: 'email',
        value: 'Email',
        cellType: 'plain-text',
        cellData: 'email',
        filterType: ETableFilterType.input,
        inputType: ETableFilterInputType.text,
        cellClass: 'text-lowercase',
    },
    {
        id: 14,
        name: 'created_at',
        value: 'Created On',
        cellType: 'date-time-cell',
        cellData: 'created_at',
        textAlign: 'center',
        filterType: ETableFilterType.data,
    },
    {
        id: 15,
        name: 'updated_at',
        value: 'Last Updated',
        cellType: 'date-time-cell',
        cellData: 'updated_at',
        textAlign: 'center',
        filterType: ETableFilterType.data,
    },
]);
