import Vue from 'vue';
import modalsMixin from '~/components/mixins/modals';
import paymentMethods from '~/components/leadsItem/FinanceDetails/payment-methods/payment-methods.vue';
import addPayment from '~/components/leadsItem/FinanceDetails/add-payment/add-payment.vue';
import editPayment from '~/components/leadsItem/FinanceDetails/edit-payment/edit-payment.vue';
import { ELeadsPaymentsModal } from '~/settings/enums/leads-payments-modal.enum';
import { ILeadsPaymentsModalEventPayload } from './interfaces';

export default Vue.extend({
    components: {
        addPayment,
        editPayment,
        paymentMethods,
    },
    mixins: [
        modalsMixin,
    ],
    data () {
        return {
            activeModal: ELeadsPaymentsModal.paymentMethods,
            payload: null,
            scrollTopOfPaymentMethodsModal: 0,
        };
    },
    methods: {
        async changeModal (event: ILeadsPaymentsModalEventPayload) {
            if (event.payload) this.payload = await Object.assign({}, this.payload, event.payload);
            this.activeModal = event.name;
        },
        setScrollTopOfPaymentMethodsModal (scrollTop: number): void {
            this.scrollTopOfPaymentMethodsModal = scrollTop;
        },
    },
});
