<template>
    <kyc-tab-item
        v-bind="$attrs"
        :display="display"
        :disabled="disabled"
        :is-open="isOpen"
        :is-loading="isLoading"
        :editable="isEditable"
        :client-editable="isPending"
        :field-id="fieldId"
        v-on="$listeners"
        @edit="edit"
        @apply="apply"
        @client-edit-apply="clientEditApply(fieldValue)"
        @client-edit-cancel="clientEditCancel(fieldValue)"
        @cancel="cancel"
    >
        <template
            v-if="isOpen"
            #input-select
        >
            <div
                class="kyc-tab-info__row"
                :class="{ 'row-phone': phone }"
            >
                <template
                    v-for="(guideItem, index) in guide"
                >
                    <div
                        v-if="guideItem.type === FIELD_TYPE_SELECT && shouldDisplay(guideItem)"
                        :key="index"
                        class="kyc-tab-info__box"
                        :class="className || 'full-width'"
                    >
                        <app-multi-select
                            v-model="selected"
                            :options="guideItem.options"
                            searchable
                            :close-on-select="true"
                            track-by="id"
                            label="name"
                            @remove="remove"
                        >
                            <template
                                slot="singleLabel"
                                slot-scope="{ option }"
                            >
                                <div
                                    :title="typeof option.getDescription === 'function' ? option.getDescription() : option"
                                >
                                    <span>{{ option }}</span>
                                    <img
                                        v-if="option.image"
                                        class="std-multiselect-icon"
                                        :src="option.image"
                                        alt=""
                                    >
                                </div>
                            </template>
                            <template
                                slot="option"
                                slot-scope="{option}"
                            >
                                <div
                                    :title="typeof option.getDescription === 'function' ? option.getDescription() : option"
                                >
                                    <span>{{ option }}</span>
                                    <img
                                        v-if="option.image"
                                        :src="option.image"
                                        alt=""
                                    >
                                </div>
                            </template>
                        </app-multi-select>
                    </div>
                    <div
                        v-if="guideItem.type === FIELD_TYPE_INPUT && shouldDisplay(guideItem)"
                        :key="index"
                        class="kyc-tab-info__box"
                        :class="className || 'full-width'"
                    >
                        <app-input
                            ref="input"
                            v-model="value"
                            type="text"
                            :placeholder="placeholder(guideItem)"
                            :title="placeholder(guideItem)"
                            :disabled="disabled"
                        />
                    </div>
                    <div
                        v-if="guideItem.type === FIELD_TYPE_RADIO && shouldDisplay(guideItem)"
                        :key="index"
                        class="kyc-tab-info__box"
                        :class="className || 'full-width'"
                    >
                        <label
                            v-for="(option, optionIndex) in guideItem.options"
                            :key="optionIndex"
                            class="kyc-tab-info__radio-label mb-2"
                        >
                            <input
                                type="radio"
                                name="exposed"
                                :value="option.name"
                                :checked="selected.name === option.name"
                                @change="updateSelected(option)"
                            >
                            {{ option }}
                        </label>
                    </div>
                </template>
            </div>
        </template>
    </kyc-tab-item>
</template>

<script>
import KycTabItem from './KycTabItem';
import fieldModelMixin from '../../mixins/modelMixins/fieldModelMixin';
import toggleMixin from '../../mixins/toggleMixin';
import loadingMixin from '../../mixins/loadingMixin';
import clientKycActionsMixin from '../../mixins/clientKycActionsMixin';
import { getEmptyArrayWarn } from '../../../helpers/validators';
import { FIELD_TYPE_RADIO, FIELD_TYPE_SELECT } from '../../mixins/modelMixins/fieldTypesMixin';
import appInput from '~/components/parts/app-input/app-input.vue';
import appMultiSelect from '~/components/parts/app-multiselect/app-multiselect.vue';
import { ClientKYCFinancesModule } from '~/store/modules/client-kyc-finances/client-kyc-finances';
import { getDataFromFieldValue } from '~/components/KYC/kyc-helpers';

import { mapActions } from 'vuex';
import { names as clientKycNames } from '~/store/modules/client-kyc/client-kyc';

export default {
    components: {
        KycTabItem,
        appInput,
        appMultiSelect
    },
    mixins: [
        fieldModelMixin,
        toggleMixin,
        loadingMixin,
        clientKycActionsMixin,
    ],
    props: {
        guide: {
            type: Array,
            required: true,
            validator: (v = []) => {
                getEmptyArrayWarn('guide')(v);

                for (let i = 0; i < v.length; i++) {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    let { initial, type, column, options } = v[i];

                    if (!column) {
                        console.warn('Guide item: \'column\' is required');
                        return false;
                    }
                    if (!type) {
                        console.warn('Guide item: \'type\' is required');
                        return false;
                    }
                    if ([FIELD_TYPE_SELECT, FIELD_TYPE_RADIO].includes(type) && !Array.isArray(options)) {
                        console.warn('Guide item: \'options\' is required when \'type\' is ' + [FIELD_TYPE_SELECT, FIELD_TYPE_RADIO].join(', '));
                        return false;
                    }
                }

                return true;
            }
        },
        className: {
            type : String,
            required: false,
        },
        phone: {
            type: Boolean,
            required: false,
        },
        initial: {
            type: [String, Number, Object, Boolean],
            required: false,
        }
    },
    computed : {
        display () {
            let display = '';

            this.guide.forEach((item) => {
                let value = this.getValueByType(item.type);

                if (value && !display) {
                    display += value.name
                        || (typeof value === 'string' && value)
                        || '';
                }

                if (display && value?.custom) {
                    display += ` ${value.custom}`;
                }
            });

            return display && this.initial ? display : null;
        },
        isEditable () {
            return this.initial?.field?.is_editable;
        },
        fieldValue () {
            return this.initial?.id;
        },
        reason () {
            return ClientKYCFinancesModule.getPendingWithdrawalReason;
        },
    },
    methods: {
        ...mapActions({
            handleFetchClientKyc : clientKycNames.actions.handleFetch,
        }),
        shouldDisplay (guideItem) {
            if (
                guideItem.type === this.FIELD_TYPE_INPUT &&
                typeof guideItem.shouldDisplay === 'function'
            )
                return guideItem.shouldDisplay({ currentOption : this.selected });

            return true;
        },
        placeholder (guideItem) {
            if (guideItem.type === this.FIELD_TYPE_INPUT && typeof guideItem.placeholder === 'string') {
                return guideItem.placeholder;
            }
            if (guideItem.type === this.FIELD_TYPE_INPUT && typeof guideItem.placeholder === 'function') {
                return guideItem.placeholder({ currentOption : this.selected });
            }
            return '';
        },
        getSelectGuide () {
            return this.guide.find(({ type }) => type === this.FIELD_TYPE_SELECT || type === this.FIELD_TYPE_RADIO);
        },
        getInitialGuide () {
            return this.guide.find(({ type }) => type === this.FIELD_TYPE_INPUT);
        },
        getInitialSelected () {
            const guideItem = this.getSelectGuide();

            let data = getDataFromFieldValue(guideItem.initial) || null;

            if (data) {
                data.name = data.display;
            }

            const guideItemInitial = data || guideItem.initial || null;

            return guideItem ? guideItemInitial : null;
        },
        getInitialValue () {
            const guideItem = this.getInitialGuide();

            const guideItemName = getDataFromFieldValue(guideItem.initial)
                ? guideItem.initial.data[0].custom
                    || guideItem.initial.data[0].display
                    || guideItem.initial
                : null;

            return guideItem ? guideItemName : null;
        },
        getValueByType (type) {
            if (type === this.FIELD_TYPE_SELECT) return this.selected;
            if (type === this.FIELD_TYPE_RADIO) return this.selected; // todo temp

            return this.value;
        },
        edit () {
            if (!this.isEditable) return;
            this.toggleOpen();
        },
        apply () {
            this.startLoading();
            let payload = [];

            this.guide.forEach(({ column, type }) => {
                payload = [...payload, { column, value: this.getValueByType(type) }];
            });

            this.updateClientKyc([...payload])
                .then(() => {
                    this.restoreValue();
                    this.restoreSelected();
                    this.endLoading();
                    this.toggleOpen();
                });
        },
        cancel () {
            this.restoreValue();
            this.restoreSelected();
            this.toggleOpen();
        },
        remove () {
            this.clearSelected();
        },
        updateSelected (option) {
            this.selected = option;
        }
    },
};
</script>
