import Vue from 'vue';
import modalLayout from '~/components/modals/root-modal-layout/root-modal-layout.vue';
import modalsMixin from '~/components/mixins/modals';
import configsMixin from '~/components/mixins/configs-mixin';
import { IConfig } from '~/settings/interfaces/config-default-interfaces';
import {
    SubPermission,
    EPermissions,
    EPermissionsGroupName,
} from '~/settings/enums/permissions.enum';
import { EAccessSettingsModalTypes } from '~/settings/enums/access-settings-modal-types.enum';

export default Vue.extend({
    components: {
        modalLayout
    },
    mixins: [
        modalsMixin,
        configsMixin,
    ],
    data () {
        return {
            EnumPermissionsParent: EPermissions,
            EnumSubPermissions: SubPermission,
            permissions: {},
            groupName: '',
            groupEmail: '',
        };
    },
    computed: {
        getPermissionsList (): IConfig[] {
            return this.getConfigPermissions;
        },
        getPermissionsUser (): IConfig[] {
            return this.getConfigPermissionsGrouped.users;
        },
        getPermissionsClients (): IConfig[] {
            return this.getConfigPermissionsGrouped.clients;
        },
        getPermissionsMenu (): IConfig[] {
            return this.getConfigPermissionsGrouped.menu;
        },
        isGroupInfo (): boolean {
            return this.modalData.type === EAccessSettingsModalTypes.departmentGroup ||
                this.modalData.type === EAccessSettingsModalTypes.createDepartment;
        },
        isTypeValidation (): string {
            return EAccessSettingsModalTypes.createDepartment ? 'required' : '';
        }
    },
    async created (): Promise<void> {
        await this.handlerPermissionsData();
    },
    methods: {
        isSubPermission (id: number): number {
            return this.EnumSubPermissions[id];
        },
        getGroupItems (groupName: string): IConfig[] {
            switch (groupName) {
            case EPermissionsGroupName.users:
                return this.getPermissionsUser;
            case EPermissionsGroupName.clients:
                return this.getPermissionsClients;
            case EPermissionsGroupName.menu:
                return this.getPermissionsMenu;
            }
        },
        handlerSetEventPermissions (event: boolean, items: IConfig[]) {
            items.forEach(permission => {
                this.permissions[permission.id] = event;
            });
        },
        handlerSetEventToGroup (event: boolean, groupName: string) {
            const items = this.getGroupItems(groupName);
            this.handlerSetEventPermissions(event, items);
        },
        handlerClick (event: boolean, id: number) {
            switch (id) {
            case EPermissions.viewAll:
                this.handlerSetEventToGroup(event, EPermissionsGroupName.menu);
                break;
            case EPermissions.editUserInformation:
                this.permissions[EPermissions.editPassword] = event;
                this.permissions[EPermissions.editAccessLevel] = event;
                this.permissions[EPermissions.editManager] = event;
                break;
            case EPermissions.editPassword:
            case EPermissions.editAccessLevel:
            case EPermissions.editManager:
                if (event) {
                    this.permissions[EPermissions.editUserInformation] = event;
                }
                break;
            case EPermissions.editClientsInformation:
                this.permissions[EPermissions.editUsersClients] = event;
                this.permissions[EPermissions.editClientEmailPhone] = event;
                this.permissions[EPermissions.editClientsGroup] = event;
                break;
            case EPermissions.editUsersClients:
            case EPermissions.editClientEmailPhone:
            case EPermissions.editClientsGroup:
                if (event) {
                    this.permissions[EPermissions.editClientsInformation] = event;
                }
                break;
            case EPermissions.accounting:
                this.permissions[EPermissions.reportByTeam] = event;
                this.permissions[EPermissions.commissionDatabase] = event;
                this.permissions[EPermissions.bonusPlan] = event;
                this.permissions[EPermissions.teamAndBrokers] = event;
                break;
            case EPermissions.reportByTeam:
            case EPermissions.commissionDatabase:
            case EPermissions.bonusPlan:
            case EPermissions.teamAndBrokers:
                if (event) {
                    this.permissions[EPermissions.accounting] = event;
                }
                break;
            case EPermissions.trading:
            case EPermissions.complianceReadOnly:
                if (event) {
                    this.permissions[EPermissions.trading] = event;
                    this.permissions[EPermissions.complianceReadOnly] = event;
                } else {
                    this.permissions[EPermissions.trading] = event;
                }
                break;
            case EPermissions.settings:
                this.permissions[EPermissions.settingsDocuments] = event;
                this.permissions[EPermissions.settingsGroup] = event;
                this.permissions[EPermissions.settingsSymbols] = event;
                break;
            case EPermissions.settingsDocuments:
            case EPermissions.settingsGroup:
            case EPermissions.settingsSymbols:
                if (event) {
                    this.permissions[EPermissions.settings] = event;
                }
                break;
            }
        },
        async handlerPermissionsData () {
            const permission = this.modalData.permissions || [];
            this.groupName = this.modalData.name || '';
            this.groupEmail = this.modalData.email || '';
            this.getPermissionsList.forEach(permission => {
                this.$set(this.permissions, permission.id, false);
            });
            if (permission.length > 0) {
                this.handlerSetEventPermissions(true, permission);
            }
        },
        handlerChangePermissionState (event: boolean, id: number) {
            this.permissions[id] = event;
            this.handlerClick(event, id);
        },
        isSelectedPermissions (): number[] {
            const permissions = [];
            for (const permission in this.permissions) {
                if (this.permissions[permission]) {
                    permissions.push(permission);
                }
            }
            return permissions;
        },
        async saveSettings () {
            const isValid = await this.$refs.observer.validate();
            if (isValid) {
                const permissions = this.isSelectedPermissions();
                const payload = {
                    name: this.groupName || '',
                    email: this.groupEmail || '',
                    permissions: permissions,
                };
                this.$attrs['modal-props'].ok(payload);
                this.closeModal();
            }
        },
        closeModal () {
            this.$emit('closeModal');
        }
    }
});
