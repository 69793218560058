<template>
    <div
        id="wrapper"
        class="wrapper"
        :class="{ 'modal-notification-background' : isActiveNotification }"
    >
        <div class="inner-page__container">
            <div
                class="inner-page__menu"
                :class="{'inner-page__menu--hidden': !isShowMenu}"
            >
                <navigation-menu />
            </div>
            <div
                class="inner-page__content"
                :class="{'inner-page__content--hidden': !isShowMenu}"
            >
                <the-header />

                <div class="inner-page__subheader">
                    <div class="container-lg">
                        <slot name="subheader" />
                    </div>
                </div>

                <div
                    v-if="isNotCustomScroll"
                    :class="[
                        'content',
                        contentClass,
                    ]"
                >
                    <div class="container-lg">
                        <slot />
                    </div>
                </div>

                <div
                    v-else
                    v-bar
                    class="inner-page__main"
                >
                    <div>
                        <div
                            :class="[
                                'content',
                                contentClass,
                            ]"
                        >
                            <div class="container-lg">
                                <slot />
                            </div>
                        </div>
                    </div>
                </div>

                <the-footer
                    v-if="!isUsedMockPagination"
                    :table-type="tableType"
                />
            </div>
        </div>
    </div>
</template>

<script>
import TheHeader from './theHeader';
import NavigationMenu from '~/components/parts/navigation-menu/navigation-menu.vue';
import { AppModule } from '~/store/modules/app/app';
import { ModalNotificationModule } from '~/store/modules/modal-notification/modal-notification';
import { ERouterNameFromPermission } from '~/settings/enums/router-name.enum';
import TheFooter from '~/components/TheFooter/TheFooter.vue';

export default {
    components: {
        'the-header': TheHeader,
        'navigation-menu': NavigationMenu,
        TheFooter,
    },
    props: {
        contentClass: {
            type: String,
        },
        tableType: {
            type: String,
        },
    },
    computed: {
        isShowMenu () {
            return AppModule.getIsHideMenu;
        },
        isActiveNotification () {
            return ModalNotificationModule.getActiveNotificationState;
        },
        isUsedMockPagination () {
            return this.$route.name === ERouterNameFromPermission.commissionDatabase
                || this.$route.name === ERouterNameFromPermission.bonusPlan
                || this.$route.name === ERouterNameFromPermission.managers
                || this.$route.name === ERouterNameFromPermission.employees;
        },
        isNotCustomScroll () {
            return this.$route.name === ERouterNameFromPermission.leads
                || this.$route.name === ERouterNameFromPermission.withdrawals
                || this.$route.name === ERouterNameFromPermission.reports
                || this.$route.name === ERouterNameFromPermission.users
                || this.$route.name === ERouterNameFromPermission.affiliatesPage
                || this.$route.name === ERouterNameFromPermission.affiliatesCampaigns
                || this.$route.name === ERouterNameFromPermission.affiliatesReports
                || this.$route.name === ERouterNameFromPermission.affiliatesReports
                || this.$route.name === ERouterNameFromPermission.leadsItemsFinance
                || this.$route.name === ERouterNameFromPermission.leadsItemsActivity;
        },
    },
};
</script>
