<template>
    <svg
        id="shiled"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 27.54 33.04"
    >
        <path
            d="M37.24 8.31L25.3 4.82a1.09 1.09 0 0 0-.62 0l-12 3.49a2 2 0 0 0-1.5 2v17a5.9 5.9 0 0 0 3.22 5.27l7.82 4.6h.06a5.92 5.92 0 0 0 2.67.63 6 6 0 0 0 2.68-.63l7.85-4.61a5.88 5.88 0 0 0 3.22-5.27v-17a2 2 0 0 0-1.46-1.99z"
            transform="translate(-11.19 -4.78)"
            fill="#2362b7"
        />
        <path
            :style="`fill:#fff`"
            d="M25 12.33a8.3 8.3 0 1 0 8.3 8.3 8.3 8.3 0 0 0-8.3-8.3zM25 27a6.36 6.36 0 1 1 6.36-6.35A6.37 6.37 0 0 1 25 27z"
            transform="translate(-11.19 -4.78)"
        />
        <path
            :style="`fill:#fff`"
            d="M27.14 18.09l-3.26 3L22.81 20a1 1 0 0 0-1.37 0 1 1 0 0 0 0 1.37l1.72 1.73H23.91l3.95-3.66a1 1 0 1 0-1.32-1.42z"
            transform="translate(-11.19 -4.78)"
        />
    </svg>
</template>

<script>
export default {

};
</script>
