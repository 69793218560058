import Vue from 'vue';
import { Module, VuexModule, Mutation, Action, getModule, MutationAction } from 'vuex-module-decorators';
import Rest from '~/helpers/Rest';
import store from '~/store/index';
import {
    urlComplianceDocuments,
    urlComplianceDocumentsDelete,
    urlComplianceDocumentsUpdate,
    urlComplianceDocumentsFile,
} from '~/settings/ajax-urls';
import router from '~/router';
import { NotificationPrefabricated } from '~/helpers/modal-notification-services/modal-notification-services';
import { UploadedDocumentsEnum } from './enums/uploaded-documents.enum';
import {
    UploadDocumentsInterfaces,
    handleUploadDocumentsQueryInterface,
    UploadDocumentsQueryPayload,
    IClientDocumentFilePayload,
    IClientDocumentFileAddSrc,
    IClientDocument,
} from './interfaces/upload-documents-interfaces';

// @ts-ignore
@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: 'ComplianceUploadedDocuments',
})
class ComplianceUploadedDocuments extends VuexModule {
    uploadedDocuments: IClientDocument[] = [];
    isUploaderOpen: Boolean = false;
    uploadedDocumentsQuery: UploadDocumentsInterfaces = {
        status_id: null,
        type_id: null
    };
    is_emailable: Boolean = true;

    get getUploadedDocuments (): IClientDocument[] {
        return this.uploadedDocuments;
    }

    get getUploaderStatus (): Boolean {
        return this.isUploaderOpen;
    }

    get getUploadedDocumentsQuery (): UploadDocumentsInterfaces {
        return this.uploadedDocumentsQuery;
    }

    get getIsEmailAble (): Boolean {
        return  this.is_emailable;
    }

    @Mutation
    mutationUploadedDocumentsQuerySimple (payload) {
        this.uploadedDocumentsQuery[payload.key] = payload.value;
    }

    @Mutation
    setClientDocumentSrc (payload: IClientDocumentFileAddSrc) {
        const index = this.uploadedDocuments
            .findIndex(item => item.id === payload.documentId);
        Vue.prototype.$set(this.uploadedDocuments[index], 'url', payload.src);
    }

    @Mutation
    setClientDocumentDetails (payload: IClientDocument) {
        const index = this.uploadedDocuments
            .findIndex(item => item.id === payload.id);
        for (const item in payload) {
            Vue.prototype.$set(this.uploadedDocuments[index], item, payload[item]);
        }
    }

    @Mutation
    deleteClientDocument (payload: number) {
        const index = this.uploadedDocuments
            .findIndex(item => item.id === payload);
        Vue.prototype.$delete(this.uploadedDocuments, index);
    }

    @Mutation
    SET_UPLOADED_DOCUMENTS (uploadedDocuments: IClientDocument[]) {
        this.uploadedDocuments = uploadedDocuments;
    }
    @Mutation
    SET_UPLOADER_STATUS (uploaderStatus: Boolean) {
        this.isUploaderOpen = uploaderStatus;
    }

    @Mutation
    SET_UPLOADER_EMAILABLE (uploadedEmailAble: Boolean) {
        this.is_emailable = uploadedEmailAble;
    }

    @Mutation
    setSimpleClientDocument (payload: IClientDocument[]) {
        this.uploadedDocuments.push(...payload);
    }

    @MutationAction({ mutate: ['uploadedDocumentsQuery'] })
    async handleUploadedDocumentsQuery (payload: UploadDocumentsInterfaces): Promise<handleUploadDocumentsQueryInterface> {
        return {
            uploadedDocumentsQuery: {
                ...ComplianceUploadedDocumentsModule.getUploadedDocumentsQuery,
                ...payload
            }
        };
    }

    @Action ({ commit: 'mutationUploadedDocumentsQuerySimple' })
    handleUploadedDocumentsQuerySimple (payload: UploadDocumentsQueryPayload): UploadDocumentsQueryPayload {
        return payload;
    }

    @Action ({ commit: 'SET_UPLOADED_DOCUMENTS' })
    async receiveUploadedDocuments ({ leadId, query = {} }) {
        return await Rest.GET(urlComplianceDocuments(leadId, query))
            .then((response: any) => response.json())
            .then((responseJSON: any) => {
                return responseJSON.data;
            })
            .catch(Rest.simpleCatch);
    }
    @Action
    async uploadDocument (uploadFileData: FormData) {
        const { data } = await Rest.FILE(urlComplianceDocuments(+router.currentRoute.params.id), uploadFileData)
            .then((result) => {
                NotificationPrefabricated.uploadedDocumentsSuccess();
                this.setUploaderStatus(false);
                return result.json();
            })
            .catch(Rest.simpleCatch);
        this.setSimpleClientDocument(data);
    }

    @Action
    deleteDocument (documentId: number) {
        Rest.DELETE(urlComplianceDocumentsDelete(+router.currentRoute.params.id, documentId))
            .then(() => {
                this.deleteClientDocument(documentId);
            })
            .catch(Rest.simpleCatch);
    }

    @Action ({ commit: 'SET_UPLOADER_STATUS' })
    setUploaderStatus (status: Boolean) {
        return status;
    }

    @Action ({ commit: 'SET_UPLOADER_EMAILABLE' })
    setUploaderEmailAble (isEmailAble: boolean) {
        return isEmailAble;
    }

    @Action
    async updateDocument (updatedData) {
        const { data } = await Rest.PUT(urlComplianceDocumentsUpdate(+router.currentRoute.params.id, updatedData.id),
            {
                [UploadedDocumentsEnum.TypeId]: updatedData.type_id,
                [UploadedDocumentsEnum.ExpireAt]: updatedData.expire_at,
                [UploadedDocumentsEnum.StatusId]: updatedData.status_id,
                [UploadedDocumentsEnum.StatusDescription]: updatedData.status_description,
                [UploadedDocumentsEnum.IsEmailable]: updatedData.is_emailable,
            })
            .then(Rest.middleJson)
            .catch(Rest.simpleCatch);
        this.setClientDocumentDetails(data);
    }

    @Action
    async clientDocumentsFile (payload: IClientDocumentFilePayload): Promise<void> {
        const documentId = payload.documentId;
        await Rest.GET(urlComplianceDocumentsFile(payload.clientId, documentId))
            .then(res => res.blob())
            .then(blob => {
                const src = URL.createObjectURL(blob);
                this.setClientDocumentSrc({
                    documentId,
                    src,
                });
            })
            .catch(error => {
                Rest.simpleCatch(error);
            });
    }
}
export const ComplianceUploadedDocumentsModule = getModule(ComplianceUploadedDocuments);
