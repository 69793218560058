import {
    IPaymentRequisiteRequestUpdate,
    IPaymentRequisiteResponse
} from '~/store/modules/payment-requisites/payment-requisites-interfaces';
import { EPaymentMethodName } from '~/settings/enums/payment-methods.enum';

export const handleFormattedPayloadWireTransfer = (payload: IPaymentRequisiteResponse): IPaymentRequisiteRequestUpdate => {
    return {
        id: payload.id,
        bank_name: payload.bank_name,
        bank_address: payload.bank_address,
        swift: payload.swift,
        iban: payload.iban,
        owner_name: payload.owner_name,
    };
};

export const handleFormattedPayloadCryptoWallet = (payload: IPaymentRequisiteResponse): IPaymentRequisiteRequestUpdate => {
    return {
        id: payload.id,
        currency_id: payload.currency_id,
        owner_name: payload.owner_name,
        crypto_wallet: payload.crypto_wallet,
    };
};


export const handleFormattedPayload = (payload: IPaymentRequisiteResponse): IPaymentRequisiteRequestUpdate => {
    switch (payload.method_id) {
    case EPaymentMethodName.wireTransfer:
        return handleFormattedPayloadWireTransfer(payload);
    case EPaymentMethodName.cryptoWallet:
        return handleFormattedPayloadCryptoWallet(payload);
    default:
        return payload;
    }
};
