// Imports
import Vue from 'vue';
import {
    IDashboardSummaryStatisticFormatted,
} from '~/store/modules/dashboard/interfaces';
import dashboardSummaryMixin from '~/components/mixins/dashboard-summary-mixin';
import chartsMixin from '~/components/mixins/charts-mixins';

// Exports
export default Vue.extend({
    mixins: [
        dashboardSummaryMixin,
        chartsMixin,
    ],
    props: {
        name: {
            type: String,
        },
        title: {
            type: String,
        },
        chartData: {
            type: Array,
            default: () => [],
        },
    },
    watch: {
        chartData: {
            deep: true,
            handler (payload: IDashboardSummaryStatisticFormatted[]) {
                this.updateChart(
                    payload,
                    'value',
                    'name',
                );
            },
        },
    },
    mounted () {
        this.initChart(
            this.name,
            this.chartType.doughnut,
            '',
        );
    },
});
