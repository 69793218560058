import Vue from 'vue';
import { sliceHoursMinutes } from '~/helpers/sliceHoursMinutes';

export default Vue.extend({
    props: {
        labelSince: {
            type: String,
            required: true
        },
        initial : {
            type : [String, Number, Object, Array],
            required: false,
        }
    },
    computed: {
        userApproved (): string {
            return this.initial?.user?.name || '-';
        },
        dateApproved (): string {
            return this.initial?.date?.date || '-';
        },
        timeApproved (): string {
            return this.initial?.date
                ? sliceHoursMinutes(this.initial.date.time)
                : '-';
        },
        isDateApproved (): boolean {
            return this.initial?.date;
        }
    }
});
