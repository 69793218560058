import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '~/store/index';
import { ProfileModule } from '~/store/modules/profile/profile';

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: 'App',
})

class App extends VuexModule {
    isInited: Boolean = false;
    isLoading: Boolean = false;
    isShowMenu: Boolean = true;
    isAfterLoadingContent: boolean = false;

    get getIsHideMenu (): Boolean {
        return this.isShowMenu;
    }

    get getIsInited (): Boolean {
        return this.isInited;
    }

    get getIsLoading (): Boolean {
        return this.isLoading;
    }

    get getIsAfterLoadingContent (): boolean {
        return this.isAfterLoadingContent;
    }

    @Mutation
    setShowMenu (payload: Boolean) {
        this.isShowMenu = payload;
    }

    @Mutation
    setInited (payload: Boolean) {
        this.isInited = payload;
    }

    @Mutation
    setLoading (payload: Boolean) {
        this.isLoading = payload;
    }

    @Mutation
    setAfterLoadingContent (payload: boolean) {
        this.isAfterLoadingContent = payload;
    }
    @Action
    async handleInit (payload: Boolean) {
        await ProfileModule.initAuth()
            .catch((error) => { console.log(error); })
            .finally(() => {
                this.setInited(payload);
                this.handleSetLoading(false);
            });
    }

    @Action ({ commit: 'setShowMenu' })
    handleShowMenu (payload) {
        return payload;
    }

    @Action ({ commit: 'setLoading' })
    handleSetLoading (payload: Boolean) {
        return payload;
    }

    @Action ({ commit: 'setAfterLoadingContent' })
    handleSetAfterLoadingContent (payload: boolean) {
        return payload;
    }
}
export const AppModule = getModule(App);
