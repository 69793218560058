import { ITableHeaderColumn } from '~/components/tables/interfaces/table-header-column.interfaces';
import { ETableFilterInputType, ETableFilterType } from '~/components/tables/enums/table-filter-type.enum';
import { leadsFiltersColumnsId } from '~/store/modules/_columnsId';
import {
    COLUMN_ACTIVITY,
    COLUMN_AFFILIATE, COLUMN_ANNUAL_INCOME, COLUMN_ANNUAL_INVESTMENT,
    COLUMN_CAMPAIGN,
    COLUMN_CLIENT_PROFITABILITY,
    COLUMN_COMPLETED_QUESTIONNAIRE,
    COLUMN_COMPLICATEDNESS_ID,
    COLUMN_DEMO_ACCOUNTS_COUNT,
    COLUMN_DEPOSITED,
    COLUMN_EMAIL,
    COLUMN_EMPLOYMENT_STATUS_ID,
    COLUMN_EQUITY,
    COLUMN_FIRST_LIVE_DEPOSIT_AMOUNT,
    COLUMN_FIRST_LIVE_DEPOSIT_DATE,
    COLUMN_FREE_MARGIN,
    COLUMN_ID,
    COLUMN_LANGUAGE_A2,
    COLUMN_LAST_DEPOSIT_AMOUNT,
    COLUMN_LAST_LIVE_DEPOSIT_DATE,
    COLUMN_LAST_LIVE_WITHDRAWAL_DATE,
    COLUMN_CUSTOMER_SUPPORT_USER_FULL_NAME,
    COLUMN_LEGAL_SUBJECT,
    COLUMN_LEGAL_SUBJECT_ID,
    COLUMN_LIVE_ACCOUNTS_COUNT,
    COLUMN_MODE_TYPE_ID,
    COLUMN_NAME,
    COLUMN_OPEN_ORDERS, COLUMN_PAYMENT_TYPE,
    COLUMN_PENDING_WITHDRAWALS, COLUMN_PEPS,
    COLUMN_PHONE, COLUMN_PROFESSIONAL_LEVEL_ID,
    COLUMN_REGISTERED_AT,
    COLUMN_RESIDENCE_COUNTRY_ID,
    COLUMN_RETENTION_USER_FULL_NAME,
    COLUMN_RISK_ID,
    COLUMN_SALES_USER_FULL_NAME, COLUMN_SAVINGS_INVESTMENTS,
    COLUMN_TARGET_MARKET,
    COLUMN_TIMEZONE_ID,
    COLUMN_TOTAL_LIVE_DEPOSITS,
    COLUMN_TRADING_TYPE,
    COLUMN_VERIFY_STATUS_CRYPTO_ID,
    COLUMN_VERIFY_STATUS_ID,
    COLUMN_SECOND_PHONE_FULL,
    COLUMN_MT_SUBJECT_TYPE,
    COLUMN_TEST,
    COLUMN_NET_WEALTH,
} from '~/store/modules/_columnNames';

export const leadsTableHeader: ITableHeaderColumn[] = [
    {
        id: leadsFiltersColumnsId[COLUMN_ID],
        name: COLUMN_ID,
        value: 'ID',
        filterType: ETableFilterType.component,
        filterComponent: 'leads-id-filter',
        cellType: 'leads-link',
        cellData: ['id', 'is_on_webtrader'],
    },
    {
        id: leadsFiltersColumnsId[COLUMN_NAME],
        name: COLUMN_NAME,
        value: 'Name',
        filterType: ETableFilterType.input,
        inputType: ETableFilterInputType.text,
        cellType: 'lead-name',
        cellData: ['name', 'legal_subject'],
    },
    {
        id: leadsFiltersColumnsId[COLUMN_CAMPAIGN],
        name: COLUMN_CAMPAIGN,
        value: 'Campaign',
        filterType: ETableFilterType.select,
        cellType: 'plain-text',
        cellData: 'campaign',
        filterMultiple: true,
    },
    {
        id: leadsFiltersColumnsId[COLUMN_RESIDENCE_COUNTRY_ID],
        name: COLUMN_RESIDENCE_COUNTRY_ID,
        value: 'Country',
        filterType: ETableFilterType.select,
        filterMultiple: true,
        cellType: 'flag-country',
        cellData: 'residence_country',
        textAlign: 'center'
    },
    {
        id: leadsFiltersColumnsId[COLUMN_LANGUAGE_A2],
        name: COLUMN_LANGUAGE_A2,
        value: 'Language',
        filterType: ETableFilterType.select,
        filterMultiple: true,
        cellType: 'plain-text',
        cellData: 'language_a2',
        textAlign: 'center'
    },
    {
        id: leadsFiltersColumnsId[COLUMN_TIMEZONE_ID],
        name: COLUMN_TIMEZONE_ID,
        value: 'Local Time',
        filterType: ETableFilterType.select,
        cellType: 'timezone',
        cellData: ['geo_tz_id'],
        textAlign: 'center'
    },
    {
        id: leadsFiltersColumnsId[COLUMN_COMPLICATEDNESS_ID],
        name: COLUMN_COMPLICATEDNESS_ID,
        value: 'Status',
        filterType: ETableFilterType.select,
        cellType: 'key-from-object',
        cellDataKey: 'name',
        cellData: 'complicatedness',
    },
    {
        id: leadsFiltersColumnsId[COLUMN_REGISTERED_AT],
        name: COLUMN_REGISTERED_AT,
        value: 'Reg. Date & Time',
        filterType: ETableFilterType.data,
        cellType: 'date-time-cell',
        cellData: 'registered_at',
        textAlign: 'center',
    },
    {
        id: leadsFiltersColumnsId[COLUMN_VERIFY_STATUS_ID],
        name: COLUMN_VERIFY_STATUS_ID,
        value: 'Verification (Trader)',
        filterType: ETableFilterType.select,
        filterMultiple: true,
        cellType: 'account-status',
        cellData: 'verify_status',
    },
    {
        id: leadsFiltersColumnsId[COLUMN_VERIFY_STATUS_CRYPTO_ID],
        name: COLUMN_VERIFY_STATUS_CRYPTO_ID,
        value: 'Verification (Crypto)',
        filterType: ETableFilterType.select,
        filterDisabled: true,
        cellType: 'account-status',
        cellData: 'verify_status_crypto',
    },
    {
        id: leadsFiltersColumnsId[COLUMN_DEPOSITED],
        name: COLUMN_DEPOSITED,
        value: 'Deposited',
        filterType: ETableFilterType.select,
        cellType: 'plain-text',
        cellData: 'deposited',
        cellClass: 'text-capitalize',
    },
    {
        id: leadsFiltersColumnsId[COLUMN_SALES_USER_FULL_NAME],
        name: COLUMN_SALES_USER_FULL_NAME,
        value: 'Sales Rep.',
        filterType: ETableFilterType.input,
        inputType: ETableFilterInputType.text,
        cellType: 'plain-text',
        cellData: 'sales_user_full_name'
    },
    {
        id: leadsFiltersColumnsId[COLUMN_PHONE],
        name: COLUMN_PHONE,
        value: 'Mobile Phone',
        filterType: ETableFilterType.input,
        inputType: ETableFilterInputType.number,
        cellType: 'phone-call',
        cellData: 'phone_mobile_full',
        phone: true,
    },
    {
        id: leadsFiltersColumnsId[COLUMN_SECOND_PHONE_FULL],
        name: COLUMN_SECOND_PHONE_FULL,
        value: 'Home Number',
        filterType: ETableFilterType.input,
        inputType: ETableFilterInputType.number,
        cellType: 'phone-call',
        cellData: 'phone_second_full',
        phone: false,
    },
    {
        id: leadsFiltersColumnsId[COLUMN_MODE_TYPE_ID],
        name: COLUMN_MODE_TYPE_ID,
        value: 'Acc. Types',
        filterType: ETableFilterType.select,
        cellType: 'plain-text-concat',
        cellData: 'modeTypes',
        filterMultiple: true,
        params: {
            concat: ','
        },
    },
    {
        id: leadsFiltersColumnsId[COLUMN_EMAIL],
        name: COLUMN_EMAIL,
        value: 'Email',
        filterType: ETableFilterType.input,
        inputType: ETableFilterInputType.text,
        cellType: 'plain-text',
        cellData: 'email',
        cellClass: 'text-lowercase',
    },
    {
        id: leadsFiltersColumnsId[COLUMN_TRADING_TYPE],
        name: COLUMN_TRADING_TYPE,
        value: 'Trading Type',
        filterType: ETableFilterType.select,
        filterMultiple: true,
        cellType: 'plain-text-concat',
        cellDataKey: 'name',
        cellData: 'tradingTypes',
        params: {
            concat: ','
        },
    },
    {
        id: leadsFiltersColumnsId[COLUMN_MT_SUBJECT_TYPE],
        name: COLUMN_MT_SUBJECT_TYPE,
        value: 'Platform',
        filterType: ETableFilterType.select,
        filterMultiple: true,
        cellType: 'plain-text-concat',
        cellData: 'mt_subject_types',
        params: {
            concat: ','
        },
    },
    {
        id: leadsFiltersColumnsId[COLUMN_LIVE_ACCOUNTS_COUNT],
        name: COLUMN_LIVE_ACCOUNTS_COUNT,
        value: 'Live Acc.',
        filterType: ETableFilterType.input,
        inputType: ETableFilterInputType.text,
        cellType: 'plain-text',
        cellData: 'live_accounts_count',
        textAlign: 'right',
    },
    {
        id: leadsFiltersColumnsId[COLUMN_DEMO_ACCOUNTS_COUNT],
        name: COLUMN_DEMO_ACCOUNTS_COUNT,
        value: 'Demo Acc.',
        filterType: ETableFilterType.input,
        cellType: 'plain-text',
        cellData: 'demo_accounts_count',
        textAlign: 'right',
    },
    {
        id: leadsFiltersColumnsId[COLUMN_FIRST_LIVE_DEPOSIT_DATE],
        name: COLUMN_FIRST_LIVE_DEPOSIT_DATE,
        value: 'FTD Date',
        filterType: ETableFilterType.data,
        cellType: 'date-time-cell',
        cellData: 'ftd_at',
        textAlign: 'center',
    },
    {
        id: leadsFiltersColumnsId[COLUMN_FIRST_LIVE_DEPOSIT_AMOUNT],
        name: COLUMN_FIRST_LIVE_DEPOSIT_AMOUNT,
        value: 'FTD Amount',
        filterType: ETableFilterType.select,
        cellType: 'plain-text',
        cellData: 'ftd_amount',
        textAlign: 'right',
    },
    {
        id: leadsFiltersColumnsId[COLUMN_LAST_LIVE_DEPOSIT_DATE],
        name: COLUMN_LAST_LIVE_DEPOSIT_DATE,
        value: 'LTD Date',
        filterType: ETableFilterType.data,
        cellType: 'date-time-cell',
        cellData: 'ltd_at',
        textAlign: 'center',
    },
    {
        id: leadsFiltersColumnsId[COLUMN_LAST_DEPOSIT_AMOUNT],
        name: COLUMN_LAST_DEPOSIT_AMOUNT,
        value: 'LTD Amount',
        filterType: ETableFilterType.select,
        cellType: 'plain-text',
        cellData: 'ltd_amount',
        textAlign: 'right',
    },
    {
        id: leadsFiltersColumnsId[COLUMN_TOTAL_LIVE_DEPOSITS],
        name: COLUMN_TOTAL_LIVE_DEPOSITS,
        value: 'Total Deposits',
        filterType: ETableFilterType.select,
        cellType: 'plain-text',
        cellData: 'total_deposit_amount',
        textAlign: 'right',
    },
    {
        id: leadsFiltersColumnsId[COLUMN_LAST_LIVE_WITHDRAWAL_DATE],
        name: COLUMN_LAST_LIVE_WITHDRAWAL_DATE,
        value: 'LTW Date',
        filterType: ETableFilterType.data,
        cellType: 'date-time-cell',
        cellData: 'ltw_at',
        textAlign: 'center',
    },
    {
        id: leadsFiltersColumnsId[COLUMN_PENDING_WITHDRAWALS],
        name: COLUMN_PENDING_WITHDRAWALS,
        value: 'Pending Withdrawals',
        filterType: ETableFilterType.input,
        inputType: ETableFilterInputType.number,
        cellType: 'plain-text',
        cellData: 'pending_withdrawals',
        textAlign: 'right',
    },
    {
        id: leadsFiltersColumnsId[COLUMN_EQUITY],
        name: COLUMN_EQUITY,
        value: 'Equity',
        filterType: ETableFilterType.select,
        cellType: 'plain-text',
        cellData: 'equity',
        textAlign: 'right',
    },
    {
        id: leadsFiltersColumnsId[COLUMN_OPEN_ORDERS],
        name: COLUMN_OPEN_ORDERS,
        value: 'Open Orders',
        filterType: ETableFilterType.select,
        cellType: 'plain-text',
        cellData: 'open_orders'
    },
    {
        id: leadsFiltersColumnsId[COLUMN_FREE_MARGIN],
        name: COLUMN_FREE_MARGIN,
        value: 'F. Margin',
        filterType: ETableFilterType.select,
        cellType: 'plain-text',
        cellData: 'free_margin',
        textAlign: 'right',
    },
    {
        id: leadsFiltersColumnsId[COLUMN_CUSTOMER_SUPPORT_USER_FULL_NAME],
        name: COLUMN_CUSTOMER_SUPPORT_USER_FULL_NAME,
        value: 'CS Rep.',
        filterType: ETableFilterType.input,
        inputType: ETableFilterInputType.text,
        cellType: 'plain-text',
        cellData: 'customer_support_user_full_name'
    },
    {
        id: leadsFiltersColumnsId[COLUMN_RETENTION_USER_FULL_NAME],
        name: COLUMN_RETENTION_USER_FULL_NAME,
        value: 'Retention Rep.',
        filterType: ETableFilterType.input,
        inputType: ETableFilterInputType.text,
        cellType: 'plain-text',
        cellData: 'retention_user_full_name'
    },
    {
        id: leadsFiltersColumnsId[COLUMN_TARGET_MARKET],
        name: COLUMN_TARGET_MARKET,
        value: 'Target Market',
        filterType: ETableFilterType.select,
        cellType: 'plain-text',
        cellData: 'target_market'
    },
    {
        id: leadsFiltersColumnsId[COLUMN_COMPLETED_QUESTIONNAIRE],
        name: COLUMN_COMPLETED_QUESTIONNAIRE,
        value: 'Suitability Assessment',
        filterType: ETableFilterType.select,
        cellType: 'plain-text',
        cellData: 'completed_questionnaire'
    },
    {
        id: leadsFiltersColumnsId[COLUMN_RISK_ID],
        name: COLUMN_RISK_ID,
        value: 'Risk class',
        filterType: ETableFilterType.select,
        cellType: 'key-from-object',
        cellDataKey: 'name',
        cellData: 'risks'
    },
    {
        id: leadsFiltersColumnsId[COLUMN_PROFESSIONAL_LEVEL_ID],
        name: COLUMN_PROFESSIONAL_LEVEL_ID,
        value: 'Client Categorization',
        filterType: ETableFilterType.select,
        cellType: 'key-from-object',
        cellDataKey: 'name',
        cellData: 'professional_level'
    },
    {
        id: leadsFiltersColumnsId[COLUMN_LEGAL_SUBJECT],
        name: COLUMN_LEGAL_SUBJECT_ID,
        value: 'Client Type',
        filterType: ETableFilterType.select,
        cellType: 'key-from-object',
        cellDataKey: 'name',
        cellData: 'legal_subject'
    },
    {
        id: leadsFiltersColumnsId[COLUMN_PAYMENT_TYPE],
        name: COLUMN_PAYMENT_TYPE,
        value: 'Payment Type',
        filterType: ETableFilterType.select,
        cellType: 'plain-text',
        cellData: 'payment-methods'
    },
    {
        id: leadsFiltersColumnsId[COLUMN_AFFILIATE],
        name: COLUMN_AFFILIATE,
        value: 'Affiliate',
        filterType: ETableFilterType.select,
        cellType: 'key-from-object',
        cellDataKey: 'name',
        cellData: 'affiliate'
    },
    {
        id: leadsFiltersColumnsId[COLUMN_ACTIVITY],
        name: COLUMN_ACTIVITY,
        value: 'Activity',
        filterType: ETableFilterType.select,
        cellType: 'plain-text',
        cellData: 'active'
    },
    {
        id: leadsFiltersColumnsId[COLUMN_CLIENT_PROFITABILITY],
        name: COLUMN_CLIENT_PROFITABILITY,
        value: 'Client Profitability',
        filterType: ETableFilterType.select,
        cellType: 'plain-text',
        cellData: 'active'
    },
    {
        id: leadsFiltersColumnsId[COLUMN_PEPS],
        name: COLUMN_PEPS,
        value: 'PEPs',
        filterType: ETableFilterType.select,
        cellType: 'boolean-into-text',
        cellData: 'political_exposed'
    },
    {
        id: leadsFiltersColumnsId[COLUMN_ANNUAL_INVESTMENT],
        name: COLUMN_ANNUAL_INVESTMENT,
        value: 'Annual Investment',
        filterType: ETableFilterType.select,
        cellType: 'plain-text',
        cellData: 'annual_investment',
        textAlign: 'right',
    },
    {
        id: leadsFiltersColumnsId[COLUMN_ANNUAL_INCOME],
        name: COLUMN_ANNUAL_INCOME,
        value: 'Annual Income',
        filterType: ETableFilterType.select,
        cellType: 'plain-text',
        cellData: 'annual_income',
        textAlign: 'right',
    },
    {
        id: leadsFiltersColumnsId[COLUMN_NET_WEALTH],
        name: COLUMN_NET_WEALTH,
        value: 'Net Wealth',
        filterType: ETableFilterType.select,
        cellType: 'plain-text',
        cellData: 'net_wealth',
        textAlign: 'right',
    },
    {
        id: leadsFiltersColumnsId[COLUMN_SAVINGS_INVESTMENTS],
        name: COLUMN_SAVINGS_INVESTMENTS,
        value: 'Savings / Investments',
        filterType: ETableFilterType.select,
        cellType: 'plain-text',
        cellData: 'savings_investments',
        textAlign: 'right',
    },
    {
        id: leadsFiltersColumnsId[COLUMN_EMPLOYMENT_STATUS_ID],
        name: COLUMN_EMPLOYMENT_STATUS_ID,
        value: 'Employment',
        filterType: ETableFilterType.select,
        cellType: 'key-from-object',
        cellDataKey: 'name',
        cellData: 'employment_status',
    },
    {
        id: leadsFiltersColumnsId[COLUMN_TEST],
        name: COLUMN_TEST,
        value: 'Test',
        filterType: ETableFilterType.select,
        cellType: 'boolean-into-text',
        cellData: 'is_test',
    }
];
