import { IEmitSwitcher } from '~/components/parts/app-switcher/app-switcher-interfaces';

export default {
    data (): object {
        return {
            editBonusPlan: {
                name: null,
                bonus_type: null,
                currency: null,
                countries: null,
                revenues: [],
                min_volume: null,
                min_deposit: null,
                is_fixed: 0,
                is_used: false,
                other_country_value: 0,
            },
        };
    },
    watch: {
        bonusPlan: {
            deep: true,
            immediate: true,
            handler (): void {
                this.setBonusPlan();
            }
        },
    },
    methods: {
        setBonusPlan (): void {
            if (this.bonusPlan?.id) {
                this.editBonusPlan = Object.assign({}, this.editBonusPlan, this.bonusPlan);
            }
        },
        changedBonusPlanIsFixed (switcher: IEmitSwitcher): void {
            if (this.isEdit) {
                this.$set(this.editBonusPlan, 'is_fixed', switcher.index);
            }
        },
    },
};
