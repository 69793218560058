import Vue from 'vue';

export default Vue.extend({
    methods: {
        handleClick () {

            this.$emit('close');
            this.$emit('click');
        }
    }
});
