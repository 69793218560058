import Vue from 'vue';
import { IAmountCell } from './amount-cell.interfaces';
import currencyHelper from '~/components/mixins/currency-helper';
import { EAccountCurrencies } from '~/settings/enums/account-currencies.enum';
import rowItemPropsMixin from '@/js/components/tables/mixins/table-cells-mixins/row-item-props-mixin';

export default Vue.extend({
    mixins: [
        currencyHelper,
        rowItemPropsMixin,
    ],
    props: {
        cell: {
            type: Object as () => IAmountCell,
            required: true
        }
    },
    computed: {
        isAmount (): string {
            return this.cell.value.amount;
        },
        isCurrentCurrency (): string {
            return this.isCurrencies
                .find(currency => currency.id === (this.cell.value.currency_id || EAccountCurrencies.USD)).name;
        }
    }
});
