import Vue from 'vue';
import modalLayout from '~/components/modals/root-modal-layout/root-modal-layout.vue';
import modalsMixin from '~/components/mixins/modals';
import configsMixin from '~/components/mixins/configs-mixin';
import { ReportsModule } from '~/store/modules/reports/reports';

export default Vue.extend({
    components: {
        modalLayout,
    },
    mixins: [
        modalsMixin,
        configsMixin,
    ],
    data () {
        return {
            reportName: '',
            reportColumns: null,
        };
    },
    methods: {
        async saveReport () {
            const isValid = await this.$refs.observer.validate();
            if (isValid) {
                await ReportsModule.createReports({
                    name: this.reportName,
                    column_ids: this.reportColumns.map(column => column.id),
                });
                this.closeModal();
            }
        },
    }
});
