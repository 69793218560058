export enum FinanceMixinEnums {
    LeadId = 'lead_id',
    StatusId = 'status_id',
    TypeId = 'type_id',
    AccountsIds = 'account_ids',
    Page = 'page',
    PerPage = 'per_page'
}

export enum EventNameEnums {
    Add = 'add',
    Remove = 'remove'
}

export enum PaginationEnums {
    Page = 'page',
    PerPage = 'per_page',
    Total = 'total'
}

export enum DefaultNumbersEnums {
    DEFAULT_PER_PAGE = 1
}
