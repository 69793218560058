export enum SendMailIndividualEnums {
    ClientId = 'id',
    FirstName = 'fname',
    MiddleName = 'mname',
    LastName = 'lname',
    Email = 'email',
    PhoneMobile = 'phone_mobile',
    LanguagesA2 = 'languages_a2',
    Deposit = 'is_deposited',
    ResidenceCountryIds = 'residence_country_ids',
    LeadStatusIds = 'lead_status_ids',
    CampaignIds = 'campaign_ids',
    ComplicatednessIds = 'complicatedness_ids',
    MtSubjectTypeIds = 'mt_subject_type_ids',
    UserIds = 'user_ids',
    ExceptIds = 'except_ids'
}
