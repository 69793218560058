import Vue from 'vue';

export default Vue.extend({
    props: {
        title: {
            type: String,
            required: false,
            default: 'Apply',
        },
        approveType: {
            type: Boolean,
        }
    },
    computed: {
        getTitle (): string {
            return this.approveType ? 'Approve' : this.title;
        },
    }
});
