import { Module, VuexModule, getModule, MutationAction } from 'vuex-module-decorators';
import store from '~/store';
import Rest from '~/helpers/Rest';
import {
    urlSymbolsConfigs,
} from '~/settings/ajax-urls';
import {
    ISymbolsConfigs,
} from '~/store/modules/symbols-configs/symbols-configs-interfaces';

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: 'symbolsConfigs',
})

class Symbols extends VuexModule {

    exchanges: string[] = [];
    industries: string[] = [];
    products: string[] = [];
    sectors: string[] = [];
    subtypes: string[] = [];
    trading_types: string[] = [];
    types: string[] = [];

    get getExchanges (): string[] {
        return this.exchanges;
    }

    get getIndustries (): string[] {
        return this.industries;
    }

    get getProducts (): string[] {
        return this.products;
    }

    get getSectors (): string[] {
        return this.sectors;
    }

    get getSubtypes (): string[] {
        return this.subtypes;
    }

    get getTradingTypes (): string[] {
        return this.trading_types;
    }

    get getTypes (): string[] {
        return this.types;
    }

    @MutationAction({ mutate: [
        'exchanges',
        'industries',
        'products',
        'sectors',
        'subtypes',
        'trading_types',
        'types'
    ], })
    async fetch (): Promise<ISymbolsConfigs> {
        const { data } = await Rest.GET(urlSymbolsConfigs)
            .then(Rest.middleThen)
            .catch(Rest.simpleCatch);
        return {
            ...data,
        };
    }

    @MutationAction({ mutate: [
        'exchanges',
        'industries',
        'products',
        'sectors',
        'subtypes',
        'trading_types',
        'types'
    ], })
    async reset (): Promise<ISymbolsConfigs> {
        return {
            exchanges: [],
            industries: [],
            products: [],
            sectors: [],
            subtypes: [],
            trading_types: [],
            types: [],
        };
    }

}

export const SymbolsConfigsModule = getModule(Symbols);
