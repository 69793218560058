// Import
import Vue from 'vue';
import rowItemPropsMixin from '@/js/components/tables/mixins/table-cells-mixins/row-item-props-mixin';

export default Vue.extend({
    mixins: [
        rowItemPropsMixin,
    ],
    methods: {
        openLink () {
            console.log('id', this.cell.value.id);
            console.log('text', this.cell.value.text);
            return;
        }
    }
});
