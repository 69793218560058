<template>
    <the-wrapper>
        <template #subheader>
            <div class="single-lead">
                <div class="details">
                    <item-navbar>
                        <template #return-navigation />
                        <template #kyc-controls>
                            <app-more-options v-if="isPermissionEditComplianceDocs" />
                        </template>
                    </item-navbar>
                </div>
            </div>
        </template>
        <div class="single-lead">
            <div class="details">
                <div class="kyc_content">
                    <app-kyc-filter />
                    <div class="row leads-row-line">
                        <div class="col-6 column-half">
                            <kyc-tabs />
                            <div
                                v-if="isPermissionNotes"
                                class="comment-block__wrap"
                            >
                                <h3 class="comment-block__title">
                                    Communication & Notes
                                </h3>
                                <notes-about-client />
                            </div>
                        </div>
                        <div class="col-6 column-half kyc_content-upload">
                            <uploaded-documents
                                :start-document-type="startDocumentType"
                                :class="{ 'email-documents-list' : isAutoEmailCategoryActive }"
                                type="clientDocuments"
                                :document-types="getClientDocumentTypes"
                                :statuses="getConfigClientDocumentStatuses"
                                :documents="uploadedDocuments"
                                :documents-check-pending="clientDocuments"
                                :disabled-statuses="isAutoEmailCategoryActive"
                                :disabled-filtered="isAutoEmailCategoryActive"
                                @upload="createClientDocument($event)"
                                @selectDocument="selectDocument($event)"
                                @changeType="changeType($event)"
                            >
                                <template
                                    slot="singleDocument"
                                    slot-scope="{ document }"
                                >
                                    <div
                                        v-if="isAutoEmailCategoryActive"
                                        class="email-documents"
                                    >
                                        <div class="email-documents__photo">
                                            <app-auth-img
                                                :id="document.id"
                                                file-type="email"
                                                class="uploaded-document__image-small"
                                                :file="document.mime_type"
                                                :file-url="document.url"
                                                @created="getEmailDocumentFile(document.id)"
                                            />
                                            <span class="email-documents__icon">
                                                <img
                                                    src="/img/icon/status-email.svg"
                                                    alt=""
                                                >
                                            </span>
                                        </div>
                                        <span class="email-documents__date">Sent at: {{ document.sent_at.date }} | {{ document.sent_at.time }}</span>
                                    </div>
                                    <app-auth-img
                                        v-else
                                        :id="document.id"
                                        file-type="thumb"
                                        class="uploaded-document__image-small"
                                        :file="document.mime_type"
                                        :file-url="document.url"
                                        @created="getClientDocumentFile(document.id)"
                                    />
                                </template>
                            </uploaded-documents>
                            <lead-pending-documents />
                        </div>
                    </div>
                    <div class="row leads-row-line">
                        <div class="col-6 column-half">
                            <div
                                v-if="isPermissionEnableEmails"
                                class="wrapper-editor"
                            >
                                <send-mail-editor
                                    :client-id="leadId"
                                    :is-dragging="isDragging"
                                    @sendEmail="sendEmail"
                                />
                            </div>
                        </div>
                        <div class="col-6 column-half">
                            <attachments-tabs @changeDragging="changeDragging" />
                        </div>
                    </div>
                    <kyc-details-otp-table :title="'OTP'" />
                </div>
            </div>
        </div>
    </the-wrapper>
</template>

<script>
import ItemNavbar from './ItemNavbar';
// @ts-ignore
import uploadedDocuments from '~/components/uploaded-documents/uploaded-documents.vue';
import LeadPendingDocuments from '../complianceDocuments/pending-documents/pending-documents.vue';
import KycTabs from '~/components/KYC/KycTabs';
import NotesAboutClient from '~/components/notes-about-client/notes-about-client';
import sendMailEditor from '~/components/parts/sendMailEditor';
import AppKycFilter from '~/components/parts/app-kyc-filter/app-kyc-filter.vue';
import KycDetailsOtpTable from '~/components/parts/kyc-details-otp-table/kyc-details-otp-table.vue';
import AttachmentsTabs from '~/components/attachments/attachments.vue';
import appMoreOptions from '~/components/parts/app-more-options/app-more-options.vue';
import { DocumentTypesSort } from '~/store/modules/documents/document-type-helper';
import configsMixin from '~/components/mixins/configs-mixin';
import uploadedDocumentsMixin from '~/components/mixins/documents/uploadedDocumentsMixin';
import AppAuthImg from '~/components/parts/app-auth-img/app-auth-img.vue';
import { UploadedDocumentsTypeIdsEnum } from '~/components/uploaded-documents/uploaded-documents.enum';
import {
    isPermissionEditComplianceDocs,
    isPermissionNotes,
    isPermissionEnableEmails
} from '~/helpers/user-permission';
import { SendMailModule } from '~/store/modules/send-mail/send-mail';

export default {
    components: {
        ItemNavbar,
        uploadedDocuments,
        LeadPendingDocuments,
        KycTabs,
        NotesAboutClient,
        sendMailEditor,
        AppKycFilter,
        KycDetailsOtpTable,
        AttachmentsTabs,
        appMoreOptions,
        AppAuthImg,
    },
    mixins: [
        configsMixin,
        uploadedDocumentsMixin,
    ],
    data () {
        return {
            skipQuest: false,
            isUpload: false,
            startDocumentType: UploadedDocumentsTypeIdsEnum.YourSelfie,
            isDragging: false,
        };
    },
    computed: {
        isPermissionEditComplianceDocs,
        isPermissionNotes,
        isPermissionEnableEmails,
        leadId () {
            return  +this.$router.currentRoute.params.id;
        },
        getClientDocumentTypes () {
            return DocumentTypesSort(this.getConfigClientDocumentTypes);

        },
    },
    methods: {
        changeDragging (event) {
            this.isDragging = event;
        },
        sendEmail (payload) {
            SendMailModule.clientSendEmail({
                template: 'clients_email', // TODO must be removed
                leadId: this.$route.params.id,
                ...payload,
            });
        }
    }
};
</script>
<style lang="scss">
    .leads-row-line {
        margin: 0 0 21px !important;
        .column-half {
            padding: 0;
        }
    }
</style>
