import { generateModalProps } from '~/helpers/modal-services/generate-modal-props';
import { IModal } from '~/helpers/modal-services/interfaces/interfaces';
import { modalWidth, modalHeight, modalType } from '~/helpers/modal-services/enums/modal-settings';
import { showModal, hideModal } from '~/helpers/modal-services/modal-services';

import {
    Module,
    VuexModule,
    Mutation,
    Action,
    getModule
} from 'vuex-module-decorators';
import store from '~/store/index';

// @ts-ignore
@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: 'modalsNew',
})
class Modals extends VuexModule {
    modals: Object = {}
    get getModals (): Array<any> {
        return Object.values({ ...this.modals });
    }
    get getModalPropByKey (): (name: string, key: string) => string {
        return (name: string, key: string) => this.modals[name]['props'][key];
    }

    get modalWidth () {
        return modalWidth;
    }

    get modalHeight () {
        return modalHeight;
    }

    get modalType () {
        return modalType;
    }

    @Mutation
    OPEN_MODAL (modal: IModal) {
        this.modals = {
            ...this.modals,
            [modal.modalType] : {
                ...modal
            }
        };
    }
    @Mutation
    CLOSE_MODAL (modalType: string) {
        hideModal(modalType);
        const copy = { ...this.modals };
        delete copy[modalType];
        this.modals = { ...copy };
    }
    @Action
    openModal (modal: IModal) {
        const newProps = new generateModalProps();
        const currentProps = newProps.getModalProps(modal);

        this.OPEN_MODAL({
            ...modal,
            modalSettings: {
                ...modal.modalSettings,
                props: {
                    ...currentProps,
                }
            }
        });
        setTimeout(() => {
            showModal(modal.modalType);
        }, 0);
    }
    @Action ({ commit: 'CLOSE_MODAL' })
    closeModal (modalType: string) {
        return modalType;
    }
}
export const ModalsModule = getModule(Modals);
