export interface ISingleDocument {
    id: number,
    type_id: number,
    name: string,
    url: string,
    status_id: 1,
    version: string,
    created_at: string, // "2021-07-08T10:33:23.000000Z",
    updated_at: string, // "2021-07-08T10:33:23.000000Z"
}

export interface DocumentsTypesInterface {
    id: number,
    name: string,
    comment: string|null,
    parent_id: number|null
}

export enum EDocumentStatusId{
    Pending= 1,
    Active = 2,
    Archived = 3,
    Rejected = 4
}
export enum EDocumentTypeNames {
    termsAndConditions= 'terms_and_conditions',
    termsOfUse = 'terms_of_use',
    privacyPolicy = 'privacy_policy'
}


export type TDocumentTypeNames =
    EDocumentTypeNames.termsAndConditions |
    EDocumentTypeNames.termsOfUse |
    EDocumentTypeNames.privacyPolicy

export interface IDocumentsHistory {
    documentTypeNames: ISingleDocument[],
}

export interface IDocumentsData {
    documentTypeNames: ISingleDocument[],
    type_mapping: IDocumentTypes[]
}

export interface IDocumentTypes {
    type_id: number,
    name: string,
    mapping_name: TDocumentTypeNames
}
