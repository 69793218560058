export const leadsAmountRange = [
    {
        from: '0',
        to: '10000',
    },
    {
        from: '10001',
        to: '50000',
    },
    {
        from: '50001',
        to: '100000',
    },
    {
        from: '100000',
    },
];

export const leadsAmountRangeSelect = [
    {
        id: 1,
        name: `${leadsAmountRange[0].from} - ${leadsAmountRange[0].to}`,
        ...leadsAmountRange[0],
    },
    {
        id: 2,
        name: `${leadsAmountRange[1].from} - ${leadsAmountRange[1].to}`,
        ...leadsAmountRange[1],
    },
    {
        id: 3,
        name: `${leadsAmountRange[2].from} - ${leadsAmountRange[2].to}`,
        ...leadsAmountRange[2],
    },
    {
        id: 4,
        name: `${leadsAmountRange[3].from}+`,
        ...leadsAmountRange[3],
    },
];
