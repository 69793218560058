import { ProfileModule } from '~/store/modules/profile/profile';
import AuthWrapper from '~/components/auth/auth-wrapper/auth-wrapper.vue';
import Rest from '~/helpers/Rest';
import Cookies from 'js-cookie';

import { AuthLoginInterfaces, ErrorsTextInterface } from './interfaces/auth-login-interfaces';
import { ExpiresDaysEnums, AuthClassesEnums, AuthTypesEnums } from './interfaces/auth-login.enums';

export default {
    components : {
        AuthWrapper,
    },
    data (): AuthLoginInterfaces {
        return {
            login: '',
            password: '',
            rememberMe: true,
            isLoading : false,
            errorLogin: '',
            errorPassword: '',
            isErrorLogin: false,
            isErrorPassword: false,
            authClassesEnums: AuthClassesEnums,
            authTypesEnums: AuthTypesEnums
        };
    },
    computed: {
        errorPasswordText (): string {
            return this.errorPassword.toString();
        },
        errorLoginText (): string {
            return this.errorLogin.toString();
        }
    },
    methods: {
        trimEmail (): void {
            this.login = this.login.trim();
        },
        submit (event: Event): void {
            if (!this.login && !this.password) {
                this.isErrorLogin = true;
                this.isErrorPassword = true;
                return;
            }

            this.setIsLoadingTrue();
            this.clearError();
            ProfileModule.handleLogin({ login : this.login, password : this.password, rememberMe : this.rememberMe })
                .then(Rest.middleThen)
                .then(() => {
                    this.clearError();
                    this.setAuthorizationCookie('', this.login);
                    this.removeInputError(event);
                })
                .catch((errors: ErrorsTextInterface) => {
                    if (errors.username) {
                        this.errorLogin = Array.isArray(errors.username)
                            ? errors.username.join(', ')
                            : errors.username;
                    }

                    if (errors.password) {
                        this.errorPassword = Array.isArray(errors.password)
                            ? errors.password.join(', ')
                            : errors.password;
                    }

                    Rest.simpleCatch(errors);
                })
                .finally(() => {
                    this.setIsLoadingFalse();
                });
        },
        setIsLoadingTrue (): void {
            this.isLoading = true;
        },
        setIsLoadingFalse (): void {
            this.isLoading = false;
        },
        absoluteLabel (event: Event, force: boolean): void {
            const input = <HTMLInputElement>event.target;
            if (force) {
                input.closest(`.${AuthClassesEnums.parentClass}`).classList.add(`${AuthClassesEnums.activeClass}`);
            } else if (input.value) {
                input.closest(`.${AuthClassesEnums.parentClass}`).classList.add(`${AuthClassesEnums.activeClass}`);
            } else if (input.value == '') {
                input.closest(`.${AuthClassesEnums.parentClass}`).classList.remove(`${AuthClassesEnums.activeClass}`);
            }
        },
        clearError (type: string|null, typeError: string|null): void {
            if (type === null) {
                this.errorLogin = '';
                this.errorPassword = '';
            } else {
                this[type] = '';
            }

            if (typeError) {
                this[typeError] = false;
            }
        },
        removeInputError (event: Event): void {
            const input = <HTMLInputElement>event.target;
            if (input.closest(AuthClassesEnums.errorClass))
                input.closest(AuthClassesEnums.errorClass).classList.remove(AuthClassesEnums.errorClass);
            else if (input.classList.contains(AuthClassesEnums.errorClass))
                input.classList.remove(AuthClassesEnums.errorClass);
        },
        onInput (event: Event, type: string, typeError: string): void {
            this.absoluteLabel(event, true);
            this.clearError(
                type === AuthTypesEnums.typeLogin ? AuthTypesEnums.typeErrorLoginText : AuthTypesEnums.typeErrorPasswordText,
                typeError === AuthTypesEnums.typeErrorLogin ? AuthTypesEnums.typeErrorLogin : AuthTypesEnums.typeErrorPassword
            );
        },
        onFocus (event: Event): void {
            this.absoluteLabel(event, true);
            this.trimEmail();
        },
        onBlur (event: Event): void {
            this.absoluteLabel(event, false);
            this.trimEmail();
        },
        setAuthorizationCookie (name: string, value: string): void {
            name = `${name.trim()}#authorizationCookie`;
            value = value.trim();
            if (value)
                this.setCookie(name, value);
        },
        setCookie (name: string, value: string, expiresDays?: number): AuthLoginInterfaces {
            return Cookies.set(name, value, { expires: expiresDays || ExpiresDaysEnums.expiresDaysDefault });
        },
        checkAuthorizationCookie (name: string): string {
            const matches = document.cookie.match(new RegExp(
                '(?:^|; )' + name.replace('/([.$?*|{}()[]/+^])/g', '//$1') + '=([^;]*)'
            ));
            return matches ? decodeURIComponent(matches[1]) : '';
        },
        checkCookie (): AuthLoginInterfaces {
            return this.login = this.checkAuthorizationCookie('#authorizationCookie');
        },
    },
    created (): void {
        this.checkCookie();
    },
};
