import { render, staticRenderFns } from "./pending-documents-table-item.html?vue&type=template&id=62c4c49b&lang=html&"
import script from "./pending-documents-table-item.ts?vue&type=script&lang=ts&"
export * from "./pending-documents-table-item.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports