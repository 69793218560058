import { notificationsTotalInterface } from '~/store/modules/notifications/notifications-interfaces';
import { ENotificationsRegistrationsSoundType } from './enums/notifications-registrations-sound-services.enum';
import { NotificationsSoundServices } from './notifications-sound-services';

class NotificationsRegistrationSoundServices extends NotificationsSoundServices {

    private soundNewRegistration: string = `${this.soundPath}/new_registration.mp3`;

    public handlerNotification (payload: notificationsTotalInterface) {
        if (payload?.additional[ENotificationsRegistrationsSoundType.has_new_registration]) {
            this.soundConstructor(this.soundNewRegistration);
        }
    }
}

export const notificationsRegistrationSoundServices = new NotificationsRegistrationSoundServices();
