export const emailsInboxListMock = [
    {
        id: 4,
        date: '17/04/2018',
        time: '14:35:21',
        lead_id: 2882,
        full_name: 'Vaughan Eastes',
        title: 'Subject: Request to recount the votes',
        description: 'Upload new document (POI) for account 10109',
        read: false,
        thread: [
            {
                id: 55,
                date: '17/04/2018',
                time: '14:35:21',
                full_name: 'Angus Young',
                description: 'Sit amet consectetur adipiscing elit ut aliquam. Malesuada bibendum arcu vitae elementum curabitur. Ultricies integer quis auctor elit sed vulputate mi sit amet. Ut sem viverra aliquet eget sit amet tellus. Sit amet est placerat in egestas erat imperdiet. Massa vitae tortor condimentum lacinia quis vel eros'
            },
            {
                id: 5667,
                date: '17/04/2018',
                time: '14:35:21',
                full_name: 'Jimmy Hendrix',
                title: 'Can’t access the account. Need assistance.',
                description: 'Sit amet consectetur adipiscing elit ut aliquam. Malesuada bibendum arcu vitae elementum curabitur. Ultricies integer quis auctor elit sed vulputate mi sit amet. Ut sem viverra aliquet eget sit amet tellus. Sit amet est placerat in egestas erat imperdiet. Massa vitae tortor condimentum lacinia quis vel eros'
            }
        ]
    },
    {
        id: 5,
        date: '17/04/2018',
        time: '12:35:21',
        lead_id: 2345,
        full_name: 'Glynn Henry',
        title: 'Request to become professional trader',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam mollis efficitur massa, eu faucibus diam viverra in. Donec nec vehicula nunc. Quisque finibus neque quis metus rutrum finibus. Fusce eros massa, maximus id facilisis...',
        read: false
    },
    {
        id: 6,
        date: '17/04/2018',
        time: '12:35:21',
        lead_id: 2345,
        full_name: 'Anton Chekhov',
        title: 'Failed withdrawal',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam mollis efficitur massa, eu faucibus diam viverra in. Donec nec vehicula nunc. Quisque finibus neque quis metus rutrum finibus. Fusce eros massa, maximus id facilisis...',
        read: false
    },
    {
        id: 7,
        date: '17/04/2018',
        time: '12:35:21',
        lead_id: 2345,
        full_name: 'Robert Plant',
        title: 'Password change failed',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam mollis efficitur massa, eu faucibus diam viverra in. Donec nec vehicula nunc. Quisque finibus neque quis metus rutrum finibus. Fusce eros massa, maximus id facilisis...',
        read: true
    }
];
