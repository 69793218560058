import { Action, Module, VuexModule, getModule, Mutation } from 'vuex-module-decorators';
import store from '~/store/index';
import { IRootTablePayload } from './interfaces/root-table-interfaces';

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: 'RootTable',
})
class RootTable extends VuexModule {
    selectedRows: number[] = [];

    get getSelectedRows (): number[] {
        return this.selectedRows;
    }

    get isSelectedRow () {
        return (rowId: number): boolean => this.selectedRows.includes(rowId);
    }

    @Mutation
    mutationSelectedRows (payload: number) {
        this.selectedRows.push(payload);
    }

    @Mutation
    mutationSelectedRowsAll (payload: number[]) {
        this.selectedRows = payload;
    }

    @Action
    setSelectedRow (payload: number) {
        this.mutationSelectedRows(payload);
    }

    @Action
    deleteSelectedRow (payload: number) {
        const selectedRows = this.getSelectedRows.filter(item => item !== payload);
        this.mutationSelectedRowsAll(selectedRows);
    }

    @Action
    handlerSelectedRow (payload: IRootTablePayload) {
        const isSelectRow = this.isSelectedRow(payload.rowId);
        if (isSelectRow) {
            RootTableModule.deleteSelectedRow(payload.rowId);
        } else {
            if (payload.multiple) {
                RootTableModule.setSelectedRow(payload.rowId);
            } else {
                this.mutationSelectedRowsAll([payload.rowId]);
            }
        }
    }

    @Action
    handlerSelectedMultipleRows (payload: number[]) {
        this.mutationSelectedRowsAll(payload);
    }

    @Action
    resetAll () {
        this.mutationSelectedRowsAll([]);
    }
}

export const RootTableModule = getModule(RootTable);
