var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
        'switcher',
        ("switcher--" + _vm.customClass) ]},[_vm._l((_vm.switchers),function(switcher,index){
    var _obj;
return [_c('span',{class:[
              'switcher__item',
              ( _obj = {
                  'switcher__item--active': _vm.activeSwitcher(switcher)
              }, _obj[("switcher__item--fw-" + _vm.fontWeight)] = _vm.fontWeight, _obj[("switcher__item--active--color-" + _vm.activeColor)] = _vm.activeColor && _vm.activeSwitcher(switcher), _obj['switcher__item--actionable'] =  _vm.actionable, _obj )
          ],on:{"click":function($event){return _vm.changeSwitcher({
              item: switcher,
              index: index,
          })}}},[_vm._v("\n          "+_vm._s(switcher)+"\n      ")]),_vm._v(" "),(index !== _vm.switchers.length - 1)?_c('span',{staticClass:"switcher__separator"},[_vm._v("|")]):_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }