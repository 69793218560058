// Import
import Vue from 'vue';
import { ModalsModule } from '~/store/modules/modals';
import rowItemPropsMixin from '@/js/components/tables/mixins/table-cells-mixins/row-item-props-mixin';

// Exports
export default Vue.extend({
    mixins: [
        rowItemPropsMixin,
    ],
    data () {
        return {
        };
    },
    methods: {
        removeManagerUser () {
            ModalsModule.openModal({
                modalType: 'deleteUserModal',
                props: {
                    title: 'Remove Manager',
                    body: {
                        data: 'Are you sure you want to delete this employee?'
                    }
                },
            });
        },
        showEditManagerModal (modalProps) {
            ModalsModule.openModal({
                modalType: 'addNewManager',
                props: {
                    ...modalProps,
                    title: 'Edit Manager',
                    body: {
                        subtitle: 'Edit Manager'
                    }
                },
                modalSettings: {
                    props: {
                        minWidth: ModalsModule.modalWidth.lg,
                    },
                },
            });
        }
    }
});
