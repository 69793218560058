import Vue from 'vue';
import settingsNavbar from '~/components/settings/settings-navbar/settings-navbar.vue';
import { ESymbolModalType } from '~/settings/enums/symbol-modal-type.enum';
import { ITableHeaderColumn } from '~/components/tables/interfaces/table-header-column.interfaces';
import { symbolsTableHeader } from '~/components/tables/_headers/symbols';
import { ITableCell } from '~/components/tables/interfaces/table-cell-interfaces';
import { tableParser } from '~/helpers/table-parser';
import { SymbolsModule } from '~/store/modules/symbols/symbols';
import { ETableType } from '~/components/tables/enums/table-type.enum';
import { SymbolsConfigsModule } from '~/store/modules/symbols-configs/symbols-configs';
import { RootTableModule } from '~/store/modules/root-table/root-table';
import { ModalsModule } from '~/store/modules/modals';
import { ESettingsSymbolModalEvents } from './settings-symbol-interfaces';

export default Vue.extend({
    components: {
        settingsNavbar,
    },
    data () {
        return {
            currentModal: null,
            eSymbolModalType: ESymbolModalType,
        };
    },
    computed: {
        getHeader (): ITableHeaderColumn[] {
            return symbolsTableHeader;
        },
        getSymbols (): ITableCell[] {
            return tableParser(symbolsTableHeader, SymbolsModule.getSymbols);
        },
        getIsLoaded (): boolean {
            return SymbolsModule.getIsLoaded;
        },
        tableType (): string {
            return ETableType.symbols;
        },
        activeModal (): string {
            return this.currentModal === ESymbolModalType.create ?
                ModalsModule.modalType.symbolModal :
                '';
        }
    },
    async created (): Promise<void> {
        await SymbolsModule.fetch(this.$route.query);
        await SymbolsConfigsModule.fetch();
    },

    destroyed (): void {
        SymbolsModule.reset();
        SymbolsConfigsModule.reset();
    },

    methods: {
        getModalTitle (type: string): string {
            switch (type) {
            case ESymbolModalType.create:
                return 'Add new symbol';
            case ESymbolModalType.edit:
                return 'Edit symbol';
            case ESymbolModalType.duplicate:
                return 'Duplicate symbol';
            case ESymbolModalType.massEdit:
                return 'Mass Edit symbols';
            }
        },
        getModalEvents (
            type: string,
            symbolId: number = null
        ): ESettingsSymbolModalEvents {
            switch (type) {
            case ESymbolModalType.duplicate:
            case ESymbolModalType.edit:
                return {
                    id: symbolId,
                };
            case ESymbolModalType.massEdit:
                return {
                    ids: RootTableModule.getSelectedRows,
                };
            }
        },
        symbolModal (
            type: string,
            symbolId: number = null
        ) {
            const title = this.getModalTitle(type);
            const events = this.getModalEvents(type, symbolId);
            this.currentModal = type;

            ModalsModule.openModal({
                modalType: ModalsModule.modalType.symbolModal,
                props: {
                    title: title,
                    body: {
                        type: type,
                        ...events
                    },
                    isHeader: true,
                    isFooter: false
                },
                modalSettings: {
                    props: {
                        minWidth: ModalsModule.modalWidth.xl,
                    },
                },
            });
        },
    }
});
