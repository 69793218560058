import { render, staticRenderFns } from "./accounting-commission-database-table-item.html?vue&type=template&id=6b48400f&lang=html&"
import script from "./accounting-commission-database-table-item.ts?vue&type=script&lang=ts&"
export * from "./accounting-commission-database-table-item.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports