// Import
import Vue from 'vue';
import rowItemPropsMixin from '@/js/components/tables/mixins/table-cells-mixins/row-item-props-mixin';

export default Vue.extend({
    mixins: [
        rowItemPropsMixin,
    ],
    methods: {
        handleCopy () {
            try {
                navigator.clipboard.writeText(this.cell.value).then(r => r);
            } catch (error) {
                console.log(error);
                throw error;
            }
        }
    }
});
