<template>
    <div class="details_navbar d-flex flex-row align-items-center">
        <slot />
    </div>
</template>

<script>
export default {

};
</script>
