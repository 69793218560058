import Vue from 'vue';
import subNavbar from '~/components/subNavbar/subNavbar.vue';
import subNavbarLink from '~/components/subNavbar/subNavbarLink.vue';
import subNavbarSeparator from '~/components/subNavbar/subNavbarSeparator.vue';
import {
    isPermissionDepartmentCompliance,
    isPermissionDepartmentManagement,
    isPermissionCampaigns,
    isPermissionAffiliate,
} from '~/helpers/user-permission';

export default Vue.extend({
    components: {
        subNavbar,
        subNavbarLink,
        subNavbarSeparator,
    },
    computed: {
        isPermissionDepartmentCompliance,
        isPermissionDepartmentManagement,
        isPermissionCampaigns,
        isPermissionAffiliate,
        isDisabled (): boolean {
            return !this.isPermissionDepartmentCompliance &&
                !this.isPermissionDepartmentManagement;
        }
    },
});
