export enum ETableFilterKeyPagination {
    page = 'page',
    perPage = 'per_page',
}

export enum ETableFilterKeyDate {
    startDate = 'start_date',
    endDate = 'end_date',
    created_at = 'created_at',
    updated_at = 'updated_at',
    created_at_from = 'created_at_from',
    created_at_to = 'created_at_to',
    updated_at_from = 'updated_at_from',
    updated_at_to = 'updated_at_to',
}

export enum ETableFilterKeyAmountRange {
    from = 'from',
    to = 'to',
    id = 'id',
}

export enum ETableFilterKeyCampaigns {
    campaign_name = 'name',
    numberCell = 'id',
    affiliate_name = 'affiliate_id',
    campaign_type = 'type',
    campaign_size = 'campaign_size',
    user = 'user_full_name',
    screenshot = 'screenshot',
    token = 'token',
    country_id = 'country_id',
    language_a2 = 'language_a2',
    is_disabled = 'is_disabled',
    created_at = 'created_at',
    updated_at = 'updated_at',
    created_at_from = 'created_at_from',
    created_at_to = 'created_at_to',
    updated_at_from = 'updated_at_from',
    updated_at_to = 'updated_at_to',
    affiliate_id = 'affiliate_id',
    source = 'source',
}

export enum ETableFilterKeyUser {
    fname = 'fname',
    lname = 'lname',
    username = 'username',
    email = 'email',
    country_id = 'country_id',
    language_a2 = 'language_a2',
    department = 'department_id',
    activity = 'is_active',
    bonus_plan = 'bonus_plan_id',
    page = 'page',
    perPage = 'per_page',
}

export enum ETableFilterKeyWithdrawals {
    id = 'id',
    account = 'account',
    client_name = 'client_name',
    client_status_id = 'client_status_id',
    type = 'method_id',
    source = 'card_source',
    comment = 'comment',
    created_at = 'created_at',
    updated_at = 'updated_at',
    created_at_from = 'created_at_from',
    created_at_to = 'created_at_to',
    updated_at_from = 'updated_at_from',
    updated_at_to = 'updated_at_to',
    status_ids = 'status_ids',
    startDate = 'start_date',
    endDate = 'end_date',
    page = 'page',
    perPage = 'per_page',
    operation = 'type_id',
}

export enum ETableFilterKeyLeads {
    checkbox = 'checkbox',
    number = 'number',
    id = 'id',
    email = 'email',
    residence_country_id = 'residence_country_id',
    registration_date_from = 'registration_date_from',
    registration_date_to = 'registration_date_to',
    language_a2 = 'language_a2',
    timezone_id = 'timezone_id',
    name = 'name',
    verify_status_id = 'verify_status_ids',
    verify_status_crypto_id = 'verify_status_crypto_id',
    deposited = 'is_deposited',
    registered_at = 'registered_at',
    phone_mobile = 'phone_mobile',
    phone_second_full = 'phone_second_full',
    mode_type_ids = 'mode_type_ids',
    lead_status_id = 'lead_status_id',
    complicatedness_id = 'complicatedness_id',
    trading_type = 'trading_type_ids',
    mt_subject_type = 'mt_subject_type_ids',
    live_accounts_count = 'live_accounts_count',
    demo_accounts_count = 'demo_accounts_count',
    ftd_at = 'ftd_at',
    ftd_amount = 'ftd_amount',
    ltd_at = 'ltd_at',
    ltd_amount = 'ltd_amount',
    total_deposit_amount = 'total_deposit_amount',
    ltw_at = 'ltw_at',
    equity = 'equity',
    campaign = 'campaign_ids',
    open_orders = 'has_open_orders',
    free_margin = 'free_margin',
    customer_support_user_full_name = 'customer_support_user_full_name',
    sales_user_full_name = 'sales_user_full_name',
    retention_user_full_name = 'retention_user_full_name',
    target_market = 'target_market',
    completed_questionnaire = 'is_completed_questionnaire',
    risk_id = 'risk_id',
    professional_level_id = 'professional_level_id',
    legal_subject_id = 'legal_subject_id',
    payment_type = 'payment_type',
    active = 'active',
    client_profitability = 'client_profitability',
    political_exposed = 'political_exposed',
    annual_investment = 'annual_investment',
    annual_income = 'annual_income',
    net_wealth = 'net_wealth',
    savings_investments = 'savings_investments',
    employment_status_id = 'employment_status_id',
    is_on_webtrader = 'is_on_webtrader',
    ftd_date_from = 'ftd_date_from',
    ftd_date_to = 'ftd_date_to',
    ltd_date_from = 'ltd_date_from',
    ltd_date_to = 'ltd_date_to',
    ltw_date_from = 'ltw_date_from',
    ltw_date_to = 'ltw_date_to',
    total_deposit_amount_from = 'total_deposit_amount_from',
    total_deposit_amount_to = 'total_deposit_amount_to',
    ftd_amount_from = 'ftd_amount_from',
    ftd_amount_to = 'ftd_amount_to',
    ltd_amount_from = 'ltd_amount_from',
    ltd_amount_to = 'ltd_amount_to',
    equity_amount_from = 'equity_amount_from',
    equity_amount_to = 'equity_amount_to',
    free_margin_amount_from = 'free_margin_amount_from',
    free_margin_amount_to = 'free_margin_amount_to',
    affiliate = 'affiliate',
    is_test = 'is_test',
}

export enum ETableFilterKeyAffiliatesReports {
    bonus_plan = 'bonus_id',
    affiliate_name = 'affiliate_full_name',
    affiliate_name_reports = 'name',
    campaigns_reports = 'affiliate_campaigns_count',
    startDate = 'start_date',
    endDate = 'finish_date',
    new_leads = 'leads_count',
    ftd = 'ftd_count',
    ftd_ratio = 'ftd_ratio',
    ftd_amount = 'ftd_amount',
    deposit_amount = 'deposit_amount',
    profit_amount = 'profit_amount',
    average_commission = 'average_commission',
    commission = 'commission',
    roi_amount = 'roi_amount',
    bonus_plan_min_deposit = 'bonus_plan_min_deposit',
    type = 'type',
    is_campaigns_reports = 'campaigns_reports',
}

export enum ETableFilterKeyAffiliates {
    full_name = 'full_name',
    bonus = 'bonus_ids',
    affiliate_type = 'type_id',
    phone = 'phone',
    country = 'country_ids',
    language = 'languages_a2',
    dob = 'dob',
    website = 'website',
    status = 'status_id',
    activity = 'is_active',
    rep_name = 'rep_name',
    email = 'email',
    created_at = 'created_at',
    updated_at = 'updated_at',
    created_at_from = 'created_at_from',
    created_at_to = 'created_at_to',
    updated_at_from = 'updated_at_from',
    updated_at_to = 'updated_at_to',
}

export enum ETableFilterKeyPaymentHistory {
    account = 'account_ids[]',
    operation = 'type_id',
    status = 'status_ids',
    id = 'transaction_id',
    type = 'method_id',
    subtype = 'sub_type',
    source = 'source',
    amount = 'amount',
    currency = 'currency_id',
    platform_comment = 'platform_comment',
    client_comment = 'client_comment',
    ip = 'ip_address',
    created_at = 'created_at',
    updated_at = 'updated_at',
    created_at_from = 'created_at_from',
    created_at_to = 'created_at_to',
    updated_at_from = 'updated_at_from',
    updated_at_to = 'updated_at_to',
}

export enum ETableFilterKeReports {
    template_id = 'template_id',
    day = 'day',
}

export enum ETableFilterKeySymbols {
    icon = 'has_logo',
    countries = 'countries',
    trading_types = 'trading_types',
    exchanges = 'exchanges',
    mt5_symbol = 'mt5_symbol',
    display_symbol_name = 'display_symbol_name',
    description_generic = 'description_generic',
    description_detail = 'description_detail',
    currencies_base = 'currencies_base',
    currencies_profit = 'currencies_profit',
    sectors = 'sectors',
    industries = 'industries',
    products = 'products',
    contract_size = 'contract_size',
    spread_novice = 'spread_novice',
    spread_experience = 'spread_experience',
    min_trade_size = 'min_trade_size',
    max_trade_size = 'max_trade_size',
    trading_hours_gmt2 = 'trading_hours_gmt2',
    time_expiration = 'time_expiration',
    time_expiration_from = 'time_expiration_from',
    time_expiration_to = 'time_expiration_to',
    is_allowed_on_website = 'is_allowed_on_website',
    dividend = 'dividend',
    dividend_date = 'dividend_date',
    dividend_date_from = 'dividend_date_from',
    dividend_date_to = 'dividend_date_to',
    ex_div_date = 'ex_div_date',
    ex_div_date_from = 'ex_div_date_from',
    ex_div_date_to = 'ex_div_date_to',
}
