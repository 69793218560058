import Vue from 'vue';
// @ts-ignore
import modalLayout from '~/components/modals/root-modal-layout/root-modal-layout';

import modalsMixin from '~/components/mixins/modals';
import configsMixin from '~/components/mixins/configs-mixin';
import { MultiEditLeadsModule } from '~/store/modules/multi-edit-leads/multi-edit-leads';
import { ModalsModule } from '~/store/modules/modals';
import AppMultiSelect from '~/components/parts/app-multiselect/app-multiselect.vue';
import { EditLeadInterfaces } from './interfaces/edit-lead-interfaces';
import { IDepartment } from '~/store/modules/users-department/users-department-interfaces';
import { UsersDepartmentModule } from '~/store/modules/users-department/users-department';
import { NotificationPrefabricated } from '~/helpers/modal-notification-services/modal-notification-services';
import { removeEmptyFields } from '~/helpers/remove-empty-fields';
import { RootTableModule } from '~/store/modules/root-table/root-table';
import { mapActions } from 'vuex';
import { actionHandleFetchleads } from '~/store/modules/leads';

export default Vue.extend({
    components: {
        modalLayout,
        AppMultiSelect
    },
    mixins: [
        modalsMixin,
        configsMixin,
    ],
    data (): EditLeadInterfaces {
        return {
            newLeadStatus: null,
            language: null,
            accType: null,
            group: null,
            clientStatus: null,
            salesRep: null,
            retentionRep: null,
            csRep: null,
        };
    },
    computed : {
        getUsersDepartmentCsRep (): IDepartment[] {
            return UsersDepartmentModule.getUsersDepartmentCsRep;
        },

        getUsersDepartmentRetentionRep (): IDepartment[] {
            return UsersDepartmentModule.getUsersDepartmentRetentionRep;
        },

        getUsersDepartmentSalesRep (): IDepartment[] {
            return UsersDepartmentModule.getUsersDepartmentSalesRep;
        },
    },
    methods: {
        ...mapActions({
            fetchLeads: actionHandleFetchleads,
        }),
        sendEditLeadsItem (usersEdit) {
            MultiEditLeadsModule.actionEditLead(usersEdit)
                .then(() => {
                    this.$emit('closeModal');
                    this.fetchLeads(this.$route.query);
                    // todo open success modal
                    NotificationPrefabricated.LeadFilterTemplatesEditSuccess();
                })
                .catch(() => {
                    // todo open failed modal with reasons
                    console.log('show errors on modal or show separate \'Error-modal\'');
                });
        },
        handleSubmit () {
            const usersEdit = removeEmptyFields({
                lead_status_id: this.newLeadStatus?.id,
                language_a2: this.language?.A2,
                verify_status_id: this.clientStatus?.id,
                complicatedness_id: this.group?.id,
                sales_rep: this.salesRep?.id,
                retention_rep: this.retentionRep?.id,
                cs_rep: this.csRep?.id,
                client_ids : RootTableModule.getSelectedRows,
            });

            ModalsModule.openModal({
                modalType: 'confirmUpdateLeads',
                props: {
                    title: 'Are you sure?',
                    body: 'Are you sure you want to update multiple leads?',
                    isHeader: true,
                    isFooter: true,
                    ok : () => {
                        this.sendEditLeadsItem(usersEdit);
                    },
                },
                modalSettings: {
                    props: {
                        minWidth: ModalsModule.modalWidth.xs,
                    },
                },
            });
        },
    },
});
