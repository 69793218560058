// Imports
import Vue from 'vue';
import { LegalDocumentsModule } from '~/store/modules/documents/legal-documents';
import { ModalsModule } from '~/store/modules/modals';
import { EDocumentStatusId, ISingleDocument } from '~/store/modules/documents/interfaces';
import configsMixin from '~/components/mixins/configs-mixin';
import { IDefaultConfig } from '~/store/modules/configs/interfaces/configs-interface';

export default Vue.extend({
    mixins: [
        configsMixin,
    ],
    props: {
        label: {
            type: String,
            required: true
        },
        id: {
            type: Number,
            required: true
        },
        typeId:{
            type: Number,
            required: true
        },
        mappingName: {
            type: String,
            required: true
        }
    },
    data () {
        return {
            isExpanded: false,
        };
    },
    computed: {
        getDocuments (): ISingleDocument[] {
            return LegalDocumentsModule.getDocumentOfType(this.mappingName);
        },
        isExpandable (): boolean {
            return this.getArchivedDocuments.length > 0;
        },
        getActiveOrPendingDocument (): ISingleDocument {
            return this.getDocuments?.find(d => d.status.id === EDocumentStatusId.Active || d.status.id === EDocumentStatusId.Pending);
        },
        getActiveOrPendingDocumentName () {
            return this.getActiveOrPendingDocument?.name || '-';
        },
        getActiveOrPendingDocumentVersion () {
            return this.getActiveOrPendingDocument?.version || '-';
        },
        getActiveOrPendingDocumentStatusId () {
            return this.getActiveOrPendingDocument?.status?.id;
        },
        getActiveOrPendingDocumentDate (): string {
            return this.getActiveOrPendingDocument?.created_at.date || '-';
        },
        getArchivedDocuments () : ISingleDocument {
            return this.getDocuments.filter(d => d.status.id === EDocumentStatusId.Archived);
        },
        isPendingMainDocument () : boolean {
            return this.getActiveOrPendingDocument?.status?.id === EDocumentStatusId.Pending;
        },
    },
    methods: {
        toDateString (d: string) {
            const date = new Date(d);
            return date.toDateString();
        },
        uploadNew () {
            ModalsModule.openModal({
                modalType: 'uploadTermDocuments',
                props: {
                    body: {
                        title: this.label,
                        description: 'uploading document',
                        type_id: this.typeId,
                        button: {
                            text: 'upload'
                        }
                    },
                    isHeader: true,
                    isFooter: true
                },
                addClass: 'successfully-modal'
            });
        },
        viewFile (): void {
            LegalDocumentsModule.actionLoadLegalDocument(this.getActiveOrPendingDocument.id);
        },
        onApprove () {
            this.onSetStatus(this.getActiveOrPendingDocument.id,  EDocumentStatusId.Active);
        },
        onReject () {
            this.onSetStatus(this.getActiveOrPendingDocument.id, EDocumentStatusId.Rejected);
        },
        onSetStatus (documentId: number, statusId: number) {
            LegalDocumentsModule.actionSettingStatusOfDocument({ id: documentId, status_id: statusId });
        },
        getStatusName (id: number): string {
            return this.getConfigLegalDocumentsStatuses
                .find((document: IDefaultConfig) => document.id === id)?.name || '-';
        }
    }
});
