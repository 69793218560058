import { ITableHeaderColumn } from '~/components/tables/interfaces/table-header-column.interfaces';
import { ETableFilterInputType, ETableFilterType } from '~/components/tables/enums/table-filter-type.enum';
import { tableHeaderParser } from '~/components/tables/helpers/table-header-parser';

export const affiliatesCampaignsTableHeader: ITableHeaderColumn[] = tableHeaderParser([
    {
        id: 4,
        name: 'campaign_name',
        value: 'Name',
        cellType: 'plain-text-left',
        cellData: 'name',
        filterType: ETableFilterType.input,
        inputType: ETableFilterInputType.text,
    },
    {
        id: 5,
        name: 'campaign_type',
        value: 'Type',
        cellType: 'plain-text',
        cellData: 'type',
        filterType: ETableFilterType.select,
    },
    {
        id: 13,
        name: 'source',
        value: 'Source',
        cellType: 'url-definition',
        cellData: 'source',
        filterType: ETableFilterType.input,
    },
    {
        id: 14,
        name: 'is_disabled',
        value: 'Status',
        cellType: 'campaign-disabled',
        cellData: 'is_disabled',
        filterType: ETableFilterType.select,
    },
    {
        id: 3,
        name: 'affiliate_name',
        value: 'Affiliate',
        cellType: 'affiliate',
        cellDataKey: 'name',
        cellData: 'affiliate',
        filterType: ETableFilterType.select,
    },
    {
        id: 6,
        name: 'user',
        value: 'User',
        cellType: 'key-from-object',
        cellData: 'user',
        cellDataKey: 'name',
        filterType: ETableFilterType.input,
        inputType: ETableFilterInputType.text,
    },
    {
        id: 10,
        name: 'token',
        value: 'Token',
        cellType: 'campaign-token',
        cellData: 'token',
        filterType: ETableFilterType.input,
        inputType: ETableFilterInputType.text,
    },
    {
        id: 12,
        name: 'country_id',
        value: 'Country',
        cellType: 'flag-country',
        cellData: 'country',
        filterType: ETableFilterType.select,
        inputType: ETableFilterInputType.text,
    },
    {
        id: 11,
        name: 'language_a2',
        value: 'Language',
        cellType: 'key-from-object',
        cellDataKey: 'name',
        cellData: 'language',
        filterType: ETableFilterType.select,
    },
    {
        id: 7,
        name: 'comment',
        value: 'Comment',
        cellType: 'plain-text-left',
        cellData: 'comment',
        filterType: ETableFilterType.input,
        inputType: ETableFilterInputType.text,
        cellClass: 'text-lowercase up-first-latter',
    },
    {
        id: 8,
        name: 'created_at',
        value: 'Created On',
        cellType: 'date-time-cell',
        cellData: 'created_at',
        textAlign: 'center',
        filterType: ETableFilterType.data,
    },
    {
        id: 9,
        name: 'updated_at',
        value: 'Last Updated',
        cellType: 'date-time-cell',
        cellData: 'updated_at',
        textAlign: 'center',
        filterType: ETableFilterType.data,
    },
]);
