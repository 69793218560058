interface IUnknownPropertiesObject {
    [key: string]: any;
}

export const removeEmptyFields = (object: IUnknownPropertiesObject):  IUnknownPropertiesObject => {
    const newObject = {};

    for (const field in object) {
        if (
            object[field] !== null &&
            object[field] !== '' &&
            object[field] !== undefined
        ) {
            newObject[field] = object[field];
        }
    }

    return newObject;
};
