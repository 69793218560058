import Vue from 'vue';
import modalsMixin from '~/components/mixins/modals';
import modalLayout from '~/components/modals/root-modal-layout/root-modal-layout.vue';
import configsMixin from '~/components/mixins/configs-mixin';
import { BonusPlanModule } from '~/store/modules/bonus-plan/bonus-plan';
import { IBonusPlan } from '~/store/modules/bonus-plan/interfaces/bonus-plan-interfaces';
import { IAffiliateMassEdit } from '~/store/modules/affiliates/interfaces/affiliates-interfaces';
import { AffiliatesPageModule } from '~/store/modules/affiliates/affiliates-page';
import { IDefaultConfig } from '~/store/modules/configs/interfaces/configs-interface';
import { RootTableModule } from '~/store/modules/root-table/root-table';

export default Vue.extend({
    components: {
        modalLayout,
    },
    mixins: [
        modalsMixin,
        configsMixin,
    ],
    data () {
        return {
            type: null,
            country: null,
            language: null,
            bonus_plan: null,
            bonus_name: null,
            status: null,
        };
    },
    computed: {
        getBonusPlanList (): IBonusPlan[] {
            return BonusPlanModule.getBonusPlanList;
        },
    },
    methods: {
        async handlerSubmitForm (): Promise<void> {
            const isValid = Object.values(this.$data).some(item => !!item);
            if (isValid) {
                const affiliate_ids = RootTableModule.getSelectedRows;
                const payload = {
                    affiliate_ids: affiliate_ids,
                    country_id: this.country?.id,
                    language_a2: this.language?.A2,
                    bonus_id: this.bonus_name?.id,
                    status_id: this.status?.id,
                    type_id: this.type?.id,
                };

                const res = Object.keys(payload).reduce((result, current) => {
                    if (payload[current]) result[current] = payload[current];
                    return result;
                }, {});
                this.onSubmitForm(res);

            }
        },
        async onSubmitForm (payload: IAffiliateMassEdit): Promise<void> {
            await AffiliatesPageModule.massEditAffiliateDetails(payload)
                .then(response => {
                    if (response) {
                        this.closeModal();
                        AffiliatesPageModule.loadAffiliates(this.$route.query);
                    }
                });
        },
        selectBonusType (event: IDefaultConfig): void {
            this.bonus_plan = event;
        },
        selectBonusPlan (event: IBonusPlan): void {
            this.bonus_plan = event.bonus_type;
        },
    }
});
