import Vue from 'vue';
import subNavbar from '~/components/subNavbar/subNavbar.vue';
import subNavbarLink from '~/components/subNavbar/subNavbarLink.vue';
import subNavbarSeparator from '~/components/subNavbar/subNavbarSeparator.vue';
import appButton from '~/components/parts/app-button/app-button.vue';
import { ModalsModule } from '~/store/modules/modals';
import {
    isPermissionReportByTeam,
    isPermissionCommissionDatabase,
    isPermissionBonusPlan
} from '~/helpers/user-permission';

export default Vue.extend({
    components: {
        'sub-navbar': subNavbar,
        'sub-navbar-link': subNavbarLink,
        'sub-navbar-separator': subNavbarSeparator,
        'app-button': appButton,
    },
    data () {
        return {
            options: [1, 2, 3],
            selectedDate: ''
        };
    },
    computed: {
        isPermissionReportByTeam,
        isPermissionCommissionDatabase,
        isPermissionBonusPlan,
        isAccounting (): boolean {
            return this.$route.name == 'accounting-reports';
        },
        isManagers (): boolean {
            return this.$route.name == 'managers';
        },
        isBonus (): boolean {
            return this.$route.name == 'bonus-plan';
        },
        isEmployees () {
            return this.$route.name == 'employees';
        },
    },
    methods: {
        showAddNewManagersModal (): void {
            ModalsModule.openModal({
                modalType: 'addNewManager',
                props: {
                    title: 'Add New Manager',
                    body: {
                        subtitle: 'Add managers'
                    },
                    isHeader: true,
                    isFooter: true
                },
            });
        },

        showAddNewEmployeesModal (): void {
            ModalsModule.openModal({
                modalType: 'addNewEmployees',
                props: {
                    title: 'Add New Employees',
                    body: {
                        subtitle: 'Add employees'
                    },
                    isHeader: true,
                    isFooter: true
                },
            });
        },

        calendarSelect (event: object): void {
            this.selectedDate = event['fullDate'];
        }
    }
});
