import Vue from 'vue';
// @ts-ignore
import deleteLeadModal from '~/components/modals/delete-lead/delete-lead';
// @ts-ignore
import docModal from '~/components/modals/doc-modal/doc-modal';
// @ts-ignore
import editLeadModal from '~/components/modals/edit-lead/edit-lead';
// @ts-ignore
import sendMailLead from '~/components/modals/send-mail-lead/send-mail-lead';
// @ts-ignore
import importLeadModal from '~/components/modals/import-lead/import-lead';
// @ts-ignore
import leadsFiltersTemplateSave from '~/components/modals/leadsFiltersTemplate/leads-filters-template-save/leads-filters-template-save';
// @ts-ignore
import leadsFiltersTemplateDelete from '~/components/modals/leadsFiltersTemplate/leads-filters-template-delete/leads-filters-template-delete';
// @ts-ignore
import leadsFiltersTemplateEdit from '~/components/modals/leadsFiltersTemplate/leads-filters-template-edit/leads-filters-template-edit';
// @ts-ignore
import informationModal from '~/components/modals/information-modal/information-modal.vue';
// @ts-ignore
import actionModal from '@/js/components/modals/action-modal/action-modal';
// @ts-ignore
import addEditUserModal from '~/components/modals/add-edit-user-modal/add-edit-user-modal';
import massEditUserModal from '~/components/modals/mass-edit-user-modal/mass-edit-user-modal.vue';
// @ts-ignore
import accessSettingsModal from '~/components/modals/access-settings-modal/access-settings-modal';
// @ts-ignore
import addNewManager from '~/components/modals/add-new-user/add-new-user.vue';
// @ts-ignore
import deleteUserModal from '~/components/modals/delete-user-modal/delete-user-modal.vue';
// @ts-ignore
import addNewEmployees from '~/components/modals/add-new-employee/add-new-employee.vue';
// @ts-ignore
import createAdditionalAccountModal from '~/components/modals/create-additional-account/create-additional-account.vue';
// @ts-ignore
import confirmUpdateLeads from '~/components/modals/confirm-update-leads/confirm-update-leads.vue';
// @ts-ignore
import affiliatesCampaignsEdit from '~/components/modals/affiliates-campaigns/affiliates-campaigns-edit/affiliates-campaigns-edit';
// @ts-ignore
import affiliatesCampaignsAdd from '~/components/modals/affiliates-campaigns/affiliates-campaigns-add/affiliates-campaigns-add';
// @ts-ignore
import uploadTermDocuments from '~/components/modals/upload-terms-document/upload-terms-document';
import emailTemplates from '~/components/modals/emails-templates/emails-templates.vue';
import addLead from '~/components/modals/add-lead/add-lead.vue';
import lightBox from '~/components/modals/light-box/light-box.vue';
import addNewAffiliatesModal from '~/components/modals/add-new-affiliates-modal/add-new-affiliates-modal.vue';
import affiliatesEditModal from '~/components/modals/affiliates-edit-modal/affiliates-edit-modal.vue';
import affiliatesMassEditModal from '~/components/modals/affiliates-mass-edit-modal/affiliates-mass-edit-modal.vue';
import cancelUpdateLeadField from '~/components/modals/cancel-update-lead-field/cancel-update-lead-field.vue';
import createBonusPlan from '~/components/modals/create-bonus-plan/create-bonus-plan.vue';
import reportCreating from '~/components/modals/report-creating/report-creating.vue';
import affiliateDocumentModal from '~/components/modals/affiliates-document-modal/affiliates-document-modal.vue';
import changeClientTypeModal from '~/components/modals/change-client-type-modal/change-client-type-modal.vue';
import leadsFinanceCreateTransaction from '~/components/modals/leads-finance-create-transaction/leads-finance-create-transaction.vue';
import leadsPaymentsModal from '~/components/modals/leads-payments-modal/leads-payments-modal.vue';
import uploadDocumentModal from '~/components/modals/upload-document-modal/upload-document-modal.vue';
import connectModal from '~/components/modals/connect-modal/connect-modal.vue';
import symbolModal from '~/components/modals/symbol/symbol.vue';

import { ModalsModule } from '~/store/modules/modals';

// Exports
export default Vue.extend({
    components: {
        deleteLeadModal,
        docModal,
        editLeadModal,
        sendMailLead,
        importLeadModal,
        leadsFiltersTemplateSave,
        leadsFiltersTemplateDelete,
        leadsFiltersTemplateEdit,
        informationModal,
        actionModal,
        addEditUserModal,
        accessSettingsModal,
        deleteUserModal,
        addNewManager,
        createAdditionalAccountModal,
        addNewEmployees,
        uploadTermDocuments,
        affiliatesCampaignsEdit,
        affiliatesCampaignsAdd,
        confirmUpdateLeads,
        emailTemplates,
        massEditUserModal,
        addLead,
        lightBox,
        addNewAffiliatesModal,
        affiliatesEditModal,
        affiliatesMassEditModal,
        cancelUpdateLeadField,
        createBonusPlan,
        reportCreating,
        affiliateDocumentModal,
        changeClientTypeModal,
        leadsFinanceCreateTransaction,
        leadsPaymentsModal,
        uploadDocumentModal,
        connectModal,
        symbolModal,
    },
    computed: {
        vuexModals () {
            return ModalsModule.getModals;
        },
        modals () {
            return this.vuexModals
                .map(modal => {
                    return { ...modal, component: modal.modalType };
                });
        },
    },
    methods: {
        closeModal (modalType, closeFunction) {
            if (closeFunction) {
                closeFunction();
            }

            ModalsModule.closeModal(modalType);
        },
        isAddClass (modal) {
            return modal?.addClass || '';
        },
        getCloseFunction (props) {
            return props?.close;
        },
    }
});
