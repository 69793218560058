import { ITableHeaderColumn } from '~/components/tables/interfaces/table-header-column.interfaces';
import { tableHeaderParser } from '../../helpers/table-header-parser';

export const leadsEWalletTableHeaders: ITableHeaderColumn[] = tableHeaderParser(
    [
        {
            id: 1,
            name: 'wallet',
            value: 'Wallet',
            cellType: 'plain-text',
            cellData: 'subtype',
        },
        {
            id: 2,
            name: 'email',
            value: 'Email',
            cellType: 'plain-text',
            cellData: 'email',
            textAlign: 'left',
            cellClass: 'text-lowercase',
        },
        {
            id: 3,
            name: 'owner',
            value: 'e-Wallet Owner',
            cellType: 'plain-text',
            cellData: 'owner_name',
            textAlign: 'left',
        }
    ],
    6
);
