import { names } from '~/store/modules/configs/usersList';
import { mapGetters } from 'vuex';


export default {
    computed : {
        ...mapGetters({
            userList: names.getters.entities,
        })
    }
};
