import Vue from 'vue';
import { DashboardModule } from '~/store/modules/dashboard/dashboard';
import { ProfileModule } from '~/store/modules/profile/profile';
import dashboardSummaryItem from '~/components/dashboard/dashboard-summary-item/dashboard-summary-item.vue';
import dashboardRegCountry from '~/components/dashboard/dashboard-reg-country/dashboard-reg-country.vue';
import dashboardSocials from '~/components/dashboard/dashboard-socials/dashboard-socials.vue';
import dashboardDoughnutChart from '~/components/dashboard/dashboard-doughnut-chart/dashboard-doughnut-chart.vue';
import dashboardUpcomingEvents from '~/components/dashboard/dashboard-upcoming-events/dashboard-upcoming-events.vue';
import dashboardRegistrations from '~/components/dashboard/dashboard-registrations/dashboard-registrations.vue';
import dashboardTradingVolume from '~/components/dashboard/dasboard-trading-volume/dasboard-trading-volume.vue';
import {
    IDashboardSummaryStatisticFormattedObject,
    IDashboardSummaryStatisticFormatted,
    IDashboardSummaryStatisticItem,
    IDashboardSummaryPlatformFormatted,
} from '~/store/modules/dashboard/interfaces';
import { ICalendarSelectDate } from '~/components/calendar-select/interfaces/calendar-select-interfaces';
import { ECalendarOptions, ECalendarOptionsDays } from '~/components/calendar-select/enums/calendar-options.enum';
import { EDashboardCalendar } from './enums/dashboard-calendar.enums';
import {
    EDashboardClientsItemsKey,
    EDashboardPlatformItemsKey,
    EDashboardSummaryStatisticItemName,
} from '~/store/modules/dashboard/enums/dashboard-payments-items.enum';
import configsMixin from '~/components/mixins/configs-mixin';
import { IDefaultConfig } from '~/store/modules/configs/interfaces/configs-interface';
import campaignMixin from '~/components/mixins/campaignMixin';
import { ECalendarTypesOptions } from '~/components/calendar-select/enums/calendar-types-options.enum';
import moment from 'moment';
import { IDashboardDate } from './interfaces';

export default Vue.extend({
    components: {
        dashboardSummaryItem,
        dashboardRegCountry,
        dashboardSocials,
        dashboardDoughnutChart,
        dashboardUpcomingEvents,
        dashboardRegistrations,
        dashboardTradingVolume,
    },
    mixins: [
        configsMixin,
        campaignMixin,
    ],
    data () {
        return {
            calendarComponent: null,
            selectedDate: null,
            selectedCalendarType: ECalendarOptionsDays.today,
            selectedCalendarOptions: {
                name: '',
                value: ECalendarOptions.today,
            },
            calendarTypesOptionsData: ECalendarTypesOptions,
        };
    },
    computed: {
        toolTipDate (): string {
            return this.selectedCalendarOptions.value === ECalendarOptions.custom ?
                this.getCalendarPeriodLabel :
                this.selectedCalendarOptions.name;
        },
        getCalendarPeriodLabel (): string {
            const dateFrom = moment(this.selectedDate[EDashboardCalendar.startDate])
                .format('DD.MM.YYYY');
            const dateTo = moment(this.selectedDate[EDashboardCalendar.endDate])
                .format('DD.MM.YYYY');

            return `${dateFrom} - ${dateTo}`;
        },
        getProfileName (): string {
            return ProfileModule.getProfile.fname;
        },
        getDashboardPayments (): IDashboardSummaryStatisticFormattedObject {
            return DashboardModule.getDashboardPayments;
        },
        getDashboardClients (): IDashboardSummaryStatisticFormattedObject {
            return DashboardModule.getDashboardClients;
        },
        getDashboardPlatform (): IDashboardSummaryPlatformFormatted {
            return DashboardModule.getDashboardPlatform;
        },
        getDashboardSummaryStatistics (): IDashboardSummaryStatisticFormattedObject {
            return {
                ...this.getDashboardPayments,
                ...this.getDashboardClients,
            };
        },
        getDashboardPlatformInactiveClients (): IDashboardSummaryStatisticFormatted {
            return {
                id: null,
                name: EDashboardSummaryStatisticItemName.client_inactive_count,
                value: this.getDashboardPlatform[EDashboardPlatformItemsKey.client_inactive_count],
                percentage: null,
            };
        },
        getDashboardLeadsStatus (): IDashboardSummaryStatisticFormatted[] {
            return this.doughnutChartDataFormatted(
                DashboardModule.getDashboardClientsWidgets[EDashboardClientsItemsKey.client_count_by_lead_status],
                this.getConfigComplicatedness,
            );
        },

        getDashboardCampaign (): IDashboardSummaryStatisticFormatted[] {
            return this.doughnutChartDataFormatted(
                DashboardModule.getDashboardClientsWidgets[EDashboardClientsItemsKey.client_count_by_campaign],
                this.campaignList,
            );
        },
    },
    mounted () {
        this.initCalendarComponent();
        this.$nextTick(() => this.setDefaultCalendarSelect());
    },
    methods: {
        doughnutChartDataFormatted (
            payload: IDashboardSummaryStatisticItem[],
            config: IDefaultConfig[]
        ): IDashboardSummaryStatisticFormatted [] {
            return payload?.map(item => ({
                ...item,
                name: config.length && config
                    .find(configItem => configItem.id === item.id)?.name || '',
            }));
        },
        initCalendarComponent (): void {
            this.calendarComponent = this.$refs.calendarSelect;
        },
        setDefaultCalendarSelect (): void {
            const option = this.calendarComponent?.findCalendarOption(this.selectedCalendarType);

            this.calendarComponent?.selectOption(option);
        },
        calendarSelect (date: ICalendarSelectDate) {
            this.selectedDate = {
                [EDashboardCalendar.startDate]: date.formattedDate.startDate,
                [EDashboardCalendar.endDate]: date.formattedDate.endDate,
            };
            this.selectedCalendarType = date.calendar_type;
            this.selectedCalendarOptions = date.selected_option;
            this.loadDashboardStatistic();
        },
        getDateForVolume (): IDashboardDate {
            if (this.selectedCalendarType === ECalendarOptions.today) {
                const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
                return {
                    [EDashboardCalendar.startDate]: this.calendarComponent.setFormatDate(yesterday),
                    [EDashboardCalendar.endDate]: this.selectedDate[EDashboardCalendar.startDate],
                };
            }
            return this.selectedDate;
        },
        loadDashboardStatistic () {
            const dateVolume = this.getDateForVolume();

            DashboardModule.loadDashboardPayments(this.selectedDate);
            DashboardModule.loadDashboardClients(this.selectedDate);
            DashboardModule.loadDashboardPlatform(this.selectedDate);
            DashboardModule.loadDashboardVolume(dateVolume);
            DashboardModule.loadDashboardEvents(this.selectedDate);
        },
    }
});
