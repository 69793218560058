import { render, staticRenderFns } from "./accounting-bonus-plan-table.html?vue&type=template&id=4549054b&scoped=true&lang=html&"
import script from "./accounting-bonus-plan-table.ts?vue&type=script&lang=ts&"
export * from "./accounting-bonus-plan-table.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4549054b",
  null
  
)

export default component.exports