import Vue from 'vue';

export default Vue.extend({
    props: {
        option: {
            type: Object
        }
    },
    computed: {
        sign (): string {
            return this.option.sign;
        },
        title (): string {
            return this.option.title;
        },
        value (): string {
            return this.option.formatted;
        },
    },
});