import Vue from 'vue';
import EmailsFilter from '../emails-filter/emails-filter.vue';
import EmailsEditor from '../emails-editor/emails-editor.vue';
import Attachments from '~/components/attachments/attachments.vue';
import { EEmailType } from '~/components/emails/email-type.enum';
import { MarketingSendEmailModule } from '~/store/modules/marketing/marketing-send-email';
import  appSwitcher from '~/components/parts/app-switcher/app-switcher.vue';
import { MarketingTemplatesModule } from '~/store/modules/marketing/marketing-templates';
import { IMarketingListsDataForSendEmail, IMarketingTemplatesDataForSendEmail } from '~/store/modules/marketing/marketing-interfaces';
import { IEmailTemplatesPostRequest } from '~/store/modules/emails/emails-interfaces';
import { SendMailModule } from '~/store/modules/send-mail/send-mail';
import { removeEmptyFields } from '~/helpers/remove-empty-fields';
import { IEmitSwitcher } from '~/components/parts/app-switcher/app-switcher-interfaces';

export default Vue.extend({
    components: {
        EmailsFilter,
        EmailsEditor,
        Attachments,
        appSwitcher,
    },
    data () {
        return {
            eEmailType: EEmailType,
            currentEmailType: EEmailType.MailGun,
            marketingDataForSendEmail: null,
            isFilteredUserList: false, // for send mass email email editor component
            isDragging: false,
        };
    },
    methods: {
        handleTypeSendingMessages (switcher: IEmitSwitcher): void {
            if (this.currentEmailType !== switcher.item) {
                this.currentEmailType = switcher.item;

                if (
                    this.currentEmailType === EEmailType.MailChimp &&
                    !MarketingTemplatesModule.marketingTemplates.length
                ) {
                    MarketingTemplatesModule.loadMarketingTemplates();
                }
            }
        },
        saveDataForSendEmail (
            keysFromMarketingListsForSendEmail: IMarketingListsDataForSendEmail
        ): void {
            this.marketingDataForSendEmail = keysFromMarketingListsForSendEmail;
        },
        sendMailGun (payload: IEmailTemplatesPostRequest): void {
            const fields = this.$refs.emailsFilter.baseFields;
            const formattedFields = removeEmptyFields(fields);

            SendMailModule.massiveSendEmails({
                ...payload,
                ...formattedFields,
            });
        },
        sendMailChimp (keysFromMarketingTemplatesForSendEmail: IMarketingTemplatesDataForSendEmail): void {
            this.marketingDataForSendEmail = {
                ...this.marketingDataForSendEmail,
                ...keysFromMarketingTemplatesForSendEmail,
            };

            MarketingSendEmailModule.sendEmail(this.marketingDataForSendEmail);
        },
        sendEmail (payload: IMarketingTemplatesDataForSendEmail | IEmailTemplatesPostRequest): void {
            switch (this.currentEmailType) {
            case EEmailType.MailGun:
                this.sendMailGun(payload as IEmailTemplatesPostRequest);
                break;
            case EEmailType.MailChimp:
                this.sendMailChimp(payload as IMarketingTemplatesDataForSendEmail);
                break;
            }
        },
        filterList (value: boolean): void {
            this.isFilteredUserList = value;
        },
        changeDragging (event: boolean): void {
            this.isDragging = event;
        }
    }
});
