import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '~/store/index';
import Rest from '~/helpers/Rest';
import { urlUsersManagement, urlDepartmentsGroup } from '~/settings/ajax-urls';
import { IDepartments, IUsersManager, IDepartmentsPayload } from '~/store/modules/departments/interfaces/departments-interfaces';
import { IUserManagementPayload } from '~/store/modules/configs/interfaces/user-management.interface';

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: 'Departments',
})

class Departments extends VuexModule {
    departments: IDepartments[] = [];
    usersManagement: IUsersManager[] = [];

    get getDepartments (): IDepartments[] {
        return this.departments;
    }

    get getUsersManagement (): IUsersManager[] {
        return this.usersManagement;
    }

    @Mutation
    setDepartments (payload: IDepartments[]) {
        this.departments = payload;
    }

    @Mutation
    setUsersManagement (payload: IUsersManager[]) {
        this.usersManagement = payload;
    }

    @Action ({ commit: 'setUsersManagement' })
    async handleChangeDepartment (query: IUserManagementPayload): Promise<IUsersManager> {
        const { data } = await Rest.GET(urlUsersManagement(query))
            .then(Rest.middleThen)
            .catch(Rest.simpleCatch);

        return data;
    }

    @Action
    async loadDepartments (): Promise<void> {
        const { data } = await Rest.GET(urlDepartmentsGroup())
            .then(Rest.middleThen)
            .catch(Rest.simpleCatch);
        this.setDepartments(data);
    }

    @Action
    async loadDepartmentDetails (id: number): Promise<IDepartments> {
        const { data } = await Rest.GET(urlDepartmentsGroup(id))
            .then(Rest.middleThen)
            .catch(Rest.simpleCatch);
        return data;
    }

    @Action
    async updateDepartment (payload: IDepartmentsPayload): Promise<IDepartments> {
        const { data } = await Rest.PUT(urlDepartmentsGroup(payload.id), {
            name: payload.name,
            email: payload.email,
            permissions: payload.permissions,
        })
            .then(Rest.middleThen)
            .catch(Rest.simpleCatch);
        return data;
    }

    @Action
    async createDepartment (payload: IDepartmentsPayload): Promise<IDepartments> {
        const { data } = await Rest.POST(urlDepartmentsGroup(), {
            name: payload.name,
            email: payload.email,
            permissions: payload.permissions,
        })
            .then(Rest.middleThen)
            .catch(Rest.simpleCatch);
        return data;
    }
}

export const DepartmentsModule = getModule(Departments);
