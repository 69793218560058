import { NotificationsMessagesModule } from '~/store/modules/notifications/notifications';
import { EIntervalRequestsService } from './interval-requests-service.enum';
import { handlerPendingNotifications } from '~/helpers/handler-pending-notification';
import { isPermissionAdmin } from '~/helpers/user-permission';
class IntervalRequestsService {

    private clientInfoIntervalId!: NodeJS.Timeout;

    public notifications () {
        this.clientInfoIntervalId = setInterval(async () => {
            if (document.visibilityState === EIntervalRequestsService.visibilityState) {
                const data = await NotificationsMessagesModule.setNotificationsTotal();
                if (!isPermissionAdmin()) {
                    return handlerPendingNotifications(data);
                }
            }
        }, EIntervalRequestsService.interval);
    }

    public clearInterval () {
        clearInterval(this.clientInfoIntervalId);
    }
}

export const intervalRequestsService = new IntervalRequestsService();
