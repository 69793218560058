<template>
    <router-view />
</template>

<script>
import leadDetailsMixin from '~/components/mixins/leadDetailsMixin';
import { ERouterNameFromPermission } from '~/settings/enums/router-name.enum';

export default {
    mixins: [
        leadDetailsMixin,
    ],
    created () {
        if (this.$route.name !== ERouterNameFromPermission.leadsItemsDetails) {
            this.getLeadDetails();
        }
    },
};
</script>
