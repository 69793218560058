import { ITableHeaderColumn } from '~/components/tables/interfaces/table-header-column.interfaces';
import { tableHeaderParser } from '~/components/tables/helpers/table-header-parser';

export const leadsPositionsTradingTableHeader: ITableHeaderColumn[] = tableHeaderParser([
    {
        id: 1,
        name: 'account',
        value: 'Acc. No.',
        cellType: 'plain-text',
        cellData: 'account',
        textAlign: 'right',
    },
    {
        id: 2,
        name: 'id',
        value: 'ID #',
        cellType: 'plain-text',
        cellData: 'id',
        textAlign: 'center',
    },
    {
        id: 3,
        name: 'symbol',
        value: 'Symbol',
        cellType: 'symbol-cell',
        cellData: ['symbol'],
    },
    {
        id: 4,
        name: 'date',
        value: 'O.Time',
        cellType: 'date-time-cell',
        cellData: 'time',
        textAlign: 'center',
    },
    {
        id: 5,
        name: 'type',
        value: 'Type',
        cellType: 'plain-text',
        cellData: 'type'
    },
    {
        id: 6,
        name: 'size',
        value: 'Size',
        cellType: 'plain-text',
        cellData: 'size',
        textAlign: 'right',
    },
    {
        id: 7,
        name: 'price_open',
        value: 'O. Price',
        cellType: 'plain-text',
        cellData: 'price_open',
        textAlign: 'right',
    },
    {
        id: 8,
        name: 'stop_loss',
        value: 'Stop Loss',
        cellType: 'plain-text',
        cellData: 'stop_loss',
        textAlign: 'right',
    },
    {
        id: 9,
        name: 'take_profit',
        value: 'Take Profit',
        cellType: 'plain-text',
        cellData: 'take_profit',
        textAlign: 'right',
    },
    {
        id: 10,
        name: 'swap',
        value: 'Swap',
        cellType: 'plain-text',
        cellData: 'swap',
        cellWidth: '105px',
        textAlign: 'right',
    },
    {
        id: 11,
        name: 'price_current',
        value: 'Price',
        cellType: 'plain-text',
        cellData: 'price_current',
        textAlign: 'right',
    },
    {
        id: 12,
        name: 'change_percentage',
        value: 'Change',
        cellType: 'trading-profit',
        cellData: 'change_percentage',
        textAlign: 'right',
    },
    {
        id: 13,
        name: 'profit',
        value: 'Profit',
        cellType: 'trading-profit',
        cellData: ['profit', 'currency'],
        textAlign: 'right',
    },
    {
        id: 14,
        name: 'comment',
        value: 'Comment',
        cellType: 'comment-read',
        cellData: 'comment',
        cellWidth: '300px'
    }
]);

export const leadsPendingTradingTableHeader: ITableHeaderColumn[] = tableHeaderParser([
    {
        id: 1,
        name: 'account',
        value: 'Acc. No.',
        cellType: 'plain-text',
        cellData: 'account',
        textAlign: 'right',
    },
    {
        id: 2,
        name: 'order',
        value: 'ID #',
        cellType: 'plain-text',
        cellData: 'id',
        textAlign: 'right',
    },
    {
        id: 3,
        name: 'symbol',
        value: 'Symbol',
        cellType: 'symbol-cell',
        cellData: ['symbol'],
    },
    {
        id: 4,
        name: 'date',
        value: 'O.Time',
        cellType: 'date-time-cell',
        cellData: 'time',
        textAlign: 'right',
    },
    {
        id: 5,
        name: 'type',
        value: 'Type',
        cellType: 'plain-text',
        cellData: 'type'
    },
    {
        id: 6,
        name: 'size',
        value: 'Size',
        cellType: 'plain-text',
        cellData: 'size',
        textAlign: 'right',
    },
    {
        id: 7,
        name: 'order_price',
        value: 'O. Price',
        cellType: 'plain-text',
        cellData: 'order_price',
        textAlign: 'right',
    },
    {
        id: 8,
        name: 'stop_loss',
        value: 'Stop Loss',
        cellType: 'plain-text',
        cellData: 'stop_loss',
        textAlign: 'right',
    },
    {
        id: 9,
        name: 'take_profit',
        value: 'Take Profit',
        cellType: 'plain-text',
        cellData: 'take_profit',
        textAlign: 'right',
    },

    {
        id: 10,
        name: 'comment',
        value: 'Comment',
        cellType: 'comment-read',
        cellData: 'comment',
        cellWidth: '500px'
    }
]);

export const leadsHistoryTradingTableHeader: ITableHeaderColumn[] = tableHeaderParser([
    {
        id: 1,
        name: 'account',
        value: 'Acc. No.',
        cellType: 'plain-text',
        cellData: 'account',
        textAlign: 'right',
    },
    {
        id: 2,
        name: 'id',
        value: 'ID #',
        cellType: 'plain-text',
        cellData: 'id',
        textAlign: 'right',
    },
    {
        id: 3,
        name: 'symbol',
        value: 'Symbol',
        cellType: 'symbol-cell',
        cellData: ['symbol'],
    },
    {
        id: 4,
        name: 'date',
        value: 'O.Time',
        cellType: 'date-time-cell',
        cellData: 'open_time',
        textAlign: 'right',
    },
    {
        id: 5,
        name: 'date',
        value: 'C.Time',
        cellType: 'date-time-cell',
        cellData: 'time',
        textAlign: 'right',
    },
    {
        id: 6,
        name: 'type',
        value: 'Type',
        cellType: 'plain-text',
        cellData: 'type'
    },
    {
        id: 7,
        name: 'size',
        value: 'Size',
        cellType: 'plain-text',
        cellData: 'size',
        textAlign: 'right',
    },
    {
        id: 8,
        name: 'price_open',
        value: 'O. Price',
        cellType: 'plain-text',
        cellData: 'price_open',
        textAlign: 'right',
    },
    {
        id: 9,
        name: 'price_open',
        value: 'C. Price',
        cellType: 'plain-text',
        cellData: 'price_closed',
        textAlign: 'right',
    },
    {
        id: 10,
        name: 'stop_loss',
        value: 'Stop Loss',
        cellType: 'plain-text',
        cellData: 'stop_loss',
        cellWidth: '100px',
        textAlign: 'right',
    },
    {
        id: 11,
        name: 'take_profit',
        value: 'Take Profit',
        cellType: 'plain-text',
        cellData: 'take_profit',
        textAlign: 'right',
    },
    {
        id: 12,
        name: 'swap',
        value: 'Swap',
        cellType: 'plain-text',
        cellData: 'swap',
        textAlign: 'right',
    },
    {
        id: 13,
        name: 'price_current',
        value: 'Price',
        cellDataKey: 'price_current',
        cellType: 'currency-price',
        cellData: ['currency', 'price_current'],
        textAlign: 'right',
    },
    {
        id: 14,
        name: 'change_percentage',
        value: 'Change',
        cellType: 'trading-profit',
        cellData: 'change_percentage',
        cellWidth: '100px',
        textAlign: 'right',
    },
    {
        id: 15,
        name: 'profit',
        value: 'Profit',
        cellType: 'trading-profit',
        cellData: ['profit', 'currency'],
        textAlign: 'right',
    },
    {
        id: 16,
        name: 'comment',
        value: 'Comment',
        cellType: 'comment-read',
        cellData: 'comment',
        cellWidth: '300px'
    }
]);

