import Vue from 'vue';
import LeadsFiltersTemplates from '~/components/leads/leads-filters-templates/leads-filters-templates.vue';
import { LeadsFilterTemplatesModule } from '~/store/modules/leads-filter-templates/leads-filter-templates';
import theHeader from '~/components/theHeader.vue';

export default Vue.extend({
    components: {
        LeadsFiltersTemplates,
        theHeader,
    },
    computed: {
        leadsFiltersTemplates () {
            return LeadsFilterTemplatesModule.getTemplates;
        },
    },
    mounted () {
        this.loadTemplates();
    },
    methods: {
        loadTemplates () {
            LeadsFilterTemplatesModule.loadTemplates();
        },
    }

});
