import { INotes, INotesGroup } from '../notes-interfaces';

export const handlerGroupByDepartment = (payload: INotes[]): any => {
    const groupByDepartment = payload.reduce((group, item): INotesGroup => {
        const departmentId = item.department.id;
        const departmentName = item.department.name;
        if (group[departmentId]) {
            group[departmentId] = {
                user_department_id: departmentId,
                user_department_name: departmentName,
                notes : [
                    item,
                    ...group[departmentId].notes,
                ]
            };
        } else {
            group[departmentId] = {
                user_department_id: departmentId,
                user_department_name: departmentName,
                notes : [item]
            };
        }
        return group;
    }, {});
    return groupByDepartment;
};
