import Vue from 'vue';
import { PropType } from 'vue';
import { AppButtonInterfaces } from './interfaces/app-button-interfaces';
import { ModalsModule } from '~/store/modules/modals';
import { ETypeButton } from '~/components/parts/app-button/app-button.enums';

export default Vue.extend({
    props: {
        type: {
            type: String as PropType<ETypeButton>,
            required: false,
            default: ETypeButton.button,
            validator (typeButton: ETypeButton): boolean {
                return Object.values(ETypeButton).includes(typeButton);
            }
        },
        src: {
            type: String
        },
        to: {
            type: Object,
        },
        click: {
            type: Function
        },
        disabled: {
            type: Boolean,
            default: false
        },
        modify: {
            type: String
        },
        icon: {
            type: String
        },
        iconActive: {
            type: String
        },
        size: {
            type: String
        },
        styleButton: {
            type: String
        },
        active: {
            type: Boolean
        },
        isActiveModal: {
            type: String,
        }
    },
    data (): AppButtonInterfaces {
        return {
            className: 'b-button'
        };
    },
    computed: {
        isButtonTypeRoute (): Boolean {
            return this.type === 'route';
        },
        isButtonTypeLink (): Boolean {
            return this.type === 'link';
        },
        isIcon (): Boolean {
            return !!this.icon;
        },
        isIconActive (): Boolean {
            return !!this.iconActive;
        },
        hasActiveModal (): boolean {
            return this.isActiveModal && this.checkActiveModal();
        },
    },
    methods: {
        checkActiveModal (): boolean {
            return ModalsModule.getModals.some(item => item.modalType === this.isActiveModal);
        },
    },
});
