import Vue from 'vue';
import { HeaderItemInterfaces } from './interfaces/header-item-interfaces';
export default Vue.extend({
    props: {
        addClass: {
            type: String
        },
        addActiveClass: {
            type: String
        }
    },
    data (): HeaderItemInterfaces {
        return {
            className: 'b-finance-content-header-item'
        };
    }
});
