var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{attrs:{"width":_vm.cell.cellWidth}},[(_vm.isValueLink())?_c('span',{staticClass:"b-plain-text"},[_c('a',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.auto",value:({
                  disabled: _vm.isNotLongText,
                  title: _vm.isValue,
                  customClass: 'popover--dashboard-style',
              }),expression:"{\n                  disabled: isNotLongText,\n                  title: isValue,\n                  customClass: 'popover--dashboard-style',\n              }",modifiers:{"hover":true,"auto":true}}],staticClass:"b-plain-text__link",attrs:{"href":_vm.isValue,"target":"_blank"}},[_vm._v("\n              "+_vm._s(_vm.isValue)+"\n          ")])]):_c('span',{staticClass:"b-plain-text"},[_c('span',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.auto",value:({
                  disabled: _vm.isNotLongText,
                  title: _vm.isValue,
                  customClass: 'popover--dashboard-style',
              }),expression:"{\n                  disabled: isNotLongText,\n                  title: isValue,\n                  customClass: 'popover--dashboard-style',\n              }",modifiers:{"hover":true,"auto":true}}]},[_vm._v("\n              "+_vm._s(_vm.isValue)+"\n          ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }