import { ITableHeaderColumn } from '~/components/tables/interfaces/table-header-column.interfaces';

export const leadsBankAccountsTableHeader: ITableHeaderColumn[] = [
    {
        id: 1,
        name: 'name',
        value: 'Bank Name',
        cellType: 'plain-text',
        cellData: 'bank_name',
    },
    {
        id: 2,
        name: 'address',
        value: 'Bank Address',
        cellType: 'plain-text',
        cellData: 'bank_address',
        cellClass: 'up-first-latter',
    },
    {
        id: 3,
        name: 'swift',
        value: 'Swift Code',
        cellType: 'plain-text',
        cellData: 'swift',
    },
    {
        id: 4,
        name: 'iban',
        value: 'Iban / Account No.',
        cellType: 'plain-text',
        cellData: 'iban',
    },
    {
        id: 5,
        name: 'owner',
        value: 'Account Owner',
        cellType: 'plain-text',
        cellData: 'owner_name',
    },
];
