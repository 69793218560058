import Vue from 'vue';
import appInput from '~/components/parts/app-input/app-input.vue';
import appMultiSelect from '~/components/parts/app-multiselect/app-multiselect.vue';
import configsMixin from '~/components/mixins/configs-mixin';
import { AppPhoneInterfaces } from './interfaces/app-phone-interfaces';

export default Vue.extend({
    components: {
        appInput,
        appMultiSelect
    },
    mixins: [
        configsMixin,
    ],
    props: {
        size: {
            type: String
        },
        input: {
            type: [String, Number, Object]
        },
        type: {
            type: String
        },
        singleLabelType: {
            type: String
        },
        edit: {
            type: Boolean
        },
        required: {
            type: Boolean,
            default: true,
        }
    },
    data (): AppPhoneInterfaces {
        return {
            query: null
        };
    },
    computed: {
        allBindings () {
            // Need to proxify both props and attrs, for example for showLabels
            return { ...this.$props, ...this.$attrs };
        },
        isReadonly (): boolean {
            return this.allBindings.readonly;
        },
        isNoBorder (): boolean {
            return this.allBindings.noBorder;
        },
        isAddClass (): string {
            return this.allBindings.addClass;
        },
        isConcatValue (): string {
            return this.$attrs.value && this.input ?
                `(+${this.$attrs.value.phone}) ${this.input}` : '';
        },
        isRequired (): string {
            return this.required ? 'required' : '';
        }
    },
    methods: {
        search (query) {
            this.query = query;
        }
    }
});
