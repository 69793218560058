import { ModalsModule } from '~/store/modules/modals';
import { NotificationPrefabricated } from '~/helpers/modal-notification-services/modal-notification-services';
import leadDetailsMixin from '~/components/mixins/leadDetailsMixin';
import { ELegalSubject } from '~/settings/enums/legal-subject.enum';
import toggleMixin from '~/components/mixins/toggleMixin';
import loadingMixin from '~/components/mixins/loadingMixin';
import { IDefaultConfig } from '~/store/modules/configs/interfaces/configs-interface';
import { AS_COLUMN_LEGAL_SUBJECT_ID } from '~/store/modules/_columnNames';
import { mapActions } from 'vuex';
import { actionLoadKyc } from '~/store/modules/client-kyc/client-kyc';

interface ECoordinates {
    clientX: number,
    clientY: number,
}

export default {
    mixins: [
        leadDetailsMixin,
        toggleMixin,
        loadingMixin,
    ],
    data (): object {
        return {
            clientTypeTitle: 'Client Type',
            selectedClientType: null,
        };
    },
    computed: {
        clientType: {
            get (): IDefaultConfig {
                return this.selectedClientType || this.leadDetails.legal_subject;
            },
            set (value: IDefaultConfig): void {
                this.selectedClientType = value;
            },
        },
        individualActiveClientType (): boolean {
            return this.clientType.id === ELegalSubject.individual;
        },
        inBusinessActiveClientType ():boolean {
            return this.clientType.id === ELegalSubject.business;
        },
    },
    methods: {
        ...mapActions({
            actionLoadKyc,
        }),
        preConfirmOnSelect (): void {
            const { offsetLeft, offsetHeight } = this.$refs.clientType.$el;

            const coordinates = {
                clientX: offsetLeft,
                clientY: offsetHeight,
            };

            this.$nextTick(() => {
                this.preConfirm(coordinates);
            });
        },
        preConfirm (event: MouseEvent | ECoordinates): void {
            if (
                this.leadDetails.individual_full_name
                && this.individualActiveClientType
                || (this.leadDetails.company_name
                && this.inBusinessActiveClientType)
            ) {
                this.confirm();
            } else {
                ModalsModule.openModal({
                    modalType: 'changeClientTypeModal',
                    props: {
                        title: `Input client ${this.individualActiveClientType
                            ? 'Full Name'
                            : 'Company Name' }`,
                        isHeader: true,
                        isFooter: false,
                        confirm: (name: string) => {
                            this.confirm(name);
                        },
                        close: () => {
                            this.cancel();
                        }
                    },
                    modalSettings: {
                        props: {
                            minWidth: ModalsModule.modalWidth.sm,
                        },
                        positionModal: {
                            clientX: event.clientX,
                            clientY: event.clientY,
                            modalWidth: ModalsModule.modalWidth.sm,
                        }
                    },
                });
            }
        },
        confirm (name: string): void {
            this.startLoading();
            this.hide();

            const data = {
                [AS_COLUMN_LEGAL_SUBJECT_ID]: this.clientType.id,
            };

            if (name && this.individualActiveClientType) {
                data['full_name'] = name;  // To Do use enums
            }

            if (name && this.inBusinessActiveClientType) {
                data['company_name'] = name;
            }

            this.updateLeadDetails(data)
                .then(() => {
                    NotificationPrefabricated.leadManagerEditSuccess(this.clientTypeTitle);
                    this.getLeadDetails(); // TODO will be removed when Eugen updated response - company_name, full_name
                    this.actionLoadKyc(this.$route.params.id);
                })
                .catch(error => {
                    NotificationPrefabricated.Error(error);
                })
                .finally(() => {
                    this.endLoading();
                });
        },
        cancel (): void {
            this.clientType = this.leadDetails.legal_subject;
        }
    },
};
