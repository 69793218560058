import Vue from 'vue';
import { IEmitSwitcher } from '~/components/parts/app-switcher/app-switcher-interfaces';

export default Vue.extend({
    name: 'AppSwitcher',
    props: {
        switchers: {
            type: Array,
            required: true,
        },
        currentSwitcher: {
            type: String,
            required: true,
        },
        customClass: {
            type: String,
        },
        fontWeight: {
            type: String,
        },
        activeColor: {
            type: String,
        },
        actionable: {
            type: Boolean,
            default: true,
        },
    },
    methods: {
        changeSwitcher (switcher: IEmitSwitcher) {
            this.$emit('changeSwitcher', switcher);
        },
        activeSwitcher (switcher: string): boolean {
            return this.currentSwitcher === switcher;
        }
    }
});
