import { actionHandleUpdate, names } from '~/store/modules/leadDetails';
import { mapGetters, mapActions } from 'vuex';
import { EAccountStatus } from '~/settings/enums/account-status.enum';
import { EIconAccountStatus, EIconAccountStatusColor } from '~/settings/enums/status-icons-settings.enum';
import { urlClientDetails } from '~/settings/ajax-urls';

export default {
    computed : {
        ...mapGetters({
            leadDetails : names.getters.entity,
        }),
        isClientStatusUnverified () {
            return this.leadDetails?.verify_status?.id === EAccountStatus.unverified
                && (!this.leadDetails?.verify_status_crypto
                || this.leadDetails?.verify_status_crypto?.id === EAccountStatus.unverified);
        },

        isLiveAccountCounts () {
            return this.isClientStatusUnverified
                ? '0'
                : this.leadDetails.live_accounts.length;
        },
    },
    methods : {
        ...mapActions({
            updateLeadDetails: actionHandleUpdate,
            handleFetchDetails: names.actions.handleFetch
        }),

        hasClientDate (date) {
            return date ? 'Yes' : 'No';
        },

        hasClientDateClass (date) {
            return date ? 'details_user-date-table-item--has-date' : 'details_user-date-table-item--empty-date';
        },

        getDate (date) {
            return date ? `${date.date} ${date.time}` : 'N/A';
        },
        getLeadDetails () {
            this.handleFetchDetails({
                getAjaxUrl : () => urlClientDetails(this.$route.params.id)
            });
        },

        getStatusEnumKey (id) {
            return EAccountStatus[id];
        },

        getAccountStatusIcon (id) {
            switch (id) {
            case EAccountStatus.unverified :
            case EAccountStatus.pending :
            case EAccountStatus.verified :
                return EIconAccountStatus[id];
            default :
                return EIconAccountStatus[EAccountStatus.closed];
            }
        },
        getAccountStatusIconColor (id) {
            return EIconAccountStatusColor[id] || EIconAccountStatusColor[EAccountStatus.closed];
        },
    }
};
