import { ITableHeaderColumn } from '~/components/tables/interfaces/table-header-column.interfaces';
import { ETableFilterInputType, ETableFilterType } from '~/components/tables/enums/table-filter-type.enum';
import { tableHeaderParser } from '~/components/tables/helpers/table-header-parser';

export const leadsPaymentHistoryTableHeader: ITableHeaderColumn[] = tableHeaderParser([
    {
        id: 1,
        name: 'options',
        value: '',
        cellType: 'withdrawal-options',
        cellData: ['id', 'amount', 'currency', 'status_id', 'operation'],
        textAlign: 'left',
    },
    {
        id: 2,
        name: 'id',
        value: 'ID',
        cellType: 'plain-text',
        cellData: 'id',
        filterType: ETableFilterType.input,
        inputType: ETableFilterInputType.number,
    },
    {
        id: 14,
        name: 'status',
        value: 'Status',
        cellType: 'payment-status',
        cellData: 'status_id',
        filterType: ETableFilterType.select,
        textAlign: 'left',
    },
    {
        id: 3,
        name: 'created_at',
        value: 'Creation Time',
        cellType: 'date-time-cell',
        cellData: 'created_at',
        textAlign: 'center',
        filterType: ETableFilterType.data,
        inputType: ETableFilterInputType.text,
    },
    {
        id: 4,
        name: 'updated_at',
        value: 'Last Updated',
        cellType: 'date-time-cell',
        cellData: 'updated_at',
        textAlign: 'center',
        filterType: ETableFilterType.data,
    },
    {
        id: 5,
        name: 'account',
        value: 'Trading Account',
        cellType: 'account-text',
        cellData: 'account',
        textAlign: 'left',
        cellDataKey: 'id',
        filterType: ETableFilterType.select,
    },
    {
        id: 13,
        name: 'operation',
        value: 'Operation',
        cellType: 'plain-text',
        cellData: 'operation',
        filterType: ETableFilterType.select,
        textAlign: 'left',
    },
    {
        id: 6,
        name: 'type',
        value: 'Method',
        cellType: 'plain-text',
        cellData: 'type',
        textAlign: 'left',
        filterType: ETableFilterType.select,
    },
    {
        id: 7,
        name: 'subtype',
        value: 'SubType',
        cellType: 'plain-text',
        cellData: 'subtype',
        textAlign: 'left',
        filterType: ETableFilterType.select,
    },
    {
        id: 8,
        name: 'source',
        value: 'Source',
        cellType: 'plain-text',
        cellData: 'source',
        textAlign: 'left',
        filterType: ETableFilterType.select,
    },
    {
        id: 9,
        name: 'amount',
        value: 'Amount',
        cellType: 'plain-text',
        cellData: 'amount',
        textAlign: 'right',
        filterType: ETableFilterType.input,
        inputType: ETableFilterInputType.number,
    },
    {
        id: 10,
        name: 'currency',
        value: 'Currency',
        cellType: 'key-from-object',
        cellDataKey: 'name',
        cellData: 'currency',
        textAlign: 'center',
        filterType: ETableFilterType.select,
    },
    {
        id: 11,
        name: 'platform_comment',
        value: 'Platform Comment',
        cellType: 'plain-text',
        cellData: 'platform_comment',
        textAlign: 'left',
        filterType: ETableFilterType.input,
        inputType: ETableFilterInputType.text,
        cellClass: 'text-lowercase up-first-latter',
    },
    {
        id: 12,
        name: 'client_comment',
        value: 'Client Comment',
        cellType: 'plain-text',
        cellData: 'client_comment',
        textAlign: 'left',
        filterType: ETableFilterType.input,
        inputType: ETableFilterInputType.text,
        cellClass: 'text-lowercase up-first-latter',
    },
    {
        id: 15,
        name: 'ip',
        value: 'IP Address',
        cellType: 'ip-address',
        cellData: 'ip',
        textAlign: 'left',
        filterType: ETableFilterType.input,
        inputType: ETableFilterInputType.text,
    },
]);
