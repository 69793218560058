export enum LeadDetailsEnums {
    MainLine,
    SecondLine
}

export enum LeadManagerEnum {
    Sales = 2,
    Retention = 2,
    CustomerSupport = 3,
}
