var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.auto",value:({
        delay: { show: _vm.getDelayShow, hide: 0 },
        customClass: _vm.customClassPopover,
    }),expression:"{\n        delay: { show: getDelayShow, hide: 0 },\n        customClass: customClassPopover,\n    }",modifiers:{"hover":true,"auto":true}}],class:[
        ("" + _vm.className),
        ("" + _vm.defaultClass),
        (_vm.className + "--color--" + _vm.color),
        (_vm.className + "--size--" + _vm.size),
        _vm.cursor ? (_vm.className + "--" + _vm.cursor) : '',
        _vm.addClass ? (_vm.className + "--" + _vm.addClass) : '',
        !_vm.getIsAfterLoadingContent ? (_vm.className + "--hidden") : '' ],attrs:{"title":_vm.title},on:{"click":function($event){return _vm.$emit('clicked', $event)}}},[_vm._v("\n    "+_vm._s(_vm.symbol)+"\n")])}
var staticRenderFns = []

export { render, staticRenderFns }