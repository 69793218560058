import Vue from 'vue';
import navbarAffiliates from '~/components/affiliates/affiliates-nav/affiliates-nav.vue';
import rootTable from '~/components/tables/root-table/root-table.vue';
import { affiliatesCampaignsModule } from '~/store/modules/affiliates/affiliates-campaigns';
import { ModalsModule } from '~/store/modules/modals';
import { ETableType } from '~/components/tables/enums/table-type.enum';
import { NotificationPrefabricated } from '~/helpers/modal-notification-services/modal-notification-services';
import { ENotificationsButtonActions } from '~/components/parts/notification-message/enums/notiffications-button-actions.enum';
import { RootTableModule } from '~/store/modules/root-table/root-table';
import { setQuaryPerPage } from '~/components/tables/helpers/setQuaryPerPage';

export default Vue.extend({
    components: {
        navbarAffiliates,
        rootTable,
    },
    computed: {
        getCampaignsIsLoaded (): boolean {
            return affiliatesCampaignsModule.getCampaignsIsLoaded;
        },
        tableHeader () {
            return affiliatesCampaignsModule.getterHeader;
        },
        tableRows () {
            return affiliatesCampaignsModule.getterRows;
        },
        isThereSelectedRow () : boolean {
            return RootTableModule.getSelectedRows.length > 0;
        },
        tableType (): string {
            return ETableType.campaigns;
        },
    },
    mounted () {
        setQuaryPerPage(this.tableType);
        affiliatesCampaignsModule.fetchCampaignsList(this.$route.query);
    },
    methods: {
        deleteMultipleCampaigns () {
            const ids = RootTableModule.getSelectedRows;
            const text = ids.length > 1 ?
                'campaigns' :
                'campaign';
            NotificationPrefabricated.confirmAction({
                title: 'Are you sure?',
                text: `Are you sure you want to disable the selected ${text}?`,
                confirmData: {
                    id: ids,
                    actionId: ENotificationsButtonActions.deleteAffiliatesCampaigns
                }
            });
        },
        editMultipleCampaigns () {
            ModalsModule.openModal({
                modalType: 'affiliatesCampaignsEdit',
                props: {
                    title: 'Mass Edit Campaigns',
                    body: {},
                    isFooter: false,
                    isHeader: true
                },
                modalSettings: {
                    props: {
                        minWidth: ModalsModule.modalWidth.md,
                    },
                },
            });
        },
        addCampaign () {
            ModalsModule.openModal({
                modalType: 'affiliatesCampaignsAdd',
                props: {
                    title: 'Add Campaign',
                    body: {},
                    isFooter: false,
                    isHeader: true
                },
                modalSettings: {
                    props: {
                        minWidth: ModalsModule.modalWidth.lg,
                    },
                },
            });
        },
        editItem (id: number) {
            ModalsModule.openModal({
                modalType: 'affiliatesCampaignsEdit',
                props: {
                    title: 'Edit Campaign',
                    body: {
                        single: true,
                        id,
                    },
                    isFooter: false,
                    isHeader: true
                },
                modalSettings: {
                    props: {
                        minWidth: ModalsModule.modalWidth.lg,
                    },
                },
            });
        },
    }
});
