import Vue from 'vue';
import toggleHistoryMixin from '~/components/mixins/toggle-history-mixin';
import { KycClientModule } from '~/store/modules/kyc-client/kyc-client';
import { HistoryInterfaces } from '~/store/modules/kyc-client/interfaces/history-interfaces';
import kycTabItemHistoryButton from '@/js/components/KYC/KycTabItem/KycTabItemActionButtons/kyc-tab-item-history-button/kyc-tab-item-history-button.vue';
import { getStringFromFieldValueData } from '~/components/KYC/kyc-helpers';

export default Vue.extend({
    components: {
        kycTabItemHistoryButton,
    },
    mixins: [
        toggleHistoryMixin
    ],
    props: {
        label : {
            type : String,
            required: true,
        },
        urlName: {
            type : String,
            required: true,
        },
        initial: {
            type : [String, Number, Object, Array],
            required: false,
        },
        ipAddress: {
            type: Object,
        }
    },
    computed: {
        historyTermsAndCondition (): HistoryInterfaces[] {
            return KycClientModule.getHistoryTermsAndConditions;
        },
        isTerms (): boolean {
            return this.initial;
        },
        documentUrl (): string {
            return this.initial?.id || '#';
        },
        dateApprove () {
            return this.initial?.updated_at?.date;
        },
        timeApprove () {
            return this.initial?.updated_at?.time;
        },
        displayIpAddress (): string {
            return getStringFromFieldValueData(this.ipAddress);
        }
    },
    methods: {
        async history () {
            this.toggleHistoryOpen();
            if (this.isHistoryOpen) {
                await KycClientModule.setHistoryTermsAndCondition();
            } else {
                await KycClientModule.handleClearHistoryField([]);
            }
        }
    }
});
