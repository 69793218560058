// Import
import { Module, VuexModule, Action, getModule } from 'vuex-module-decorators';
import { ILeadsUpdateResponse } from './interfaces/edit-lead-interfaces';
import store from '~/store/index';
import { urlLeadsUpdate } from '~/settings/ajax-urls';
import Rest from '~/helpers/Rest';
// Export
@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: 'MultiEditLeads',
})

class MultiEditLeads extends VuexModule {

    @Action
    async actionEditLead (updatedData: ILeadsUpdateResponse): Promise<void> {
        await Rest.PUT(urlLeadsUpdate, updatedData);
    }
}

export const MultiEditLeadsModule = getModule(MultiEditLeads);
