import { EDocumentType } from '~/settings/enums/document-type.enum';
import { EComplianceDocumentFileType } from '~/settings/enums/compliance-document-file-type.enum';
import { IComplianceDocumentFileType } from '~/settings/interfaces/compliance-document-file-type-interfaces';

export default {
    props: {
        id: {
            type: Number,
            required : true
        },
        leadId: {
            type: Number,
            required: false
        },
        fileType: {
            type: String,
            required: true,
            validator : (value: IComplianceDocumentFileType): boolean => {
                return [
                    EComplianceDocumentFileType.thumb,
                    EComplianceDocumentFileType.file,
                    EComplianceDocumentFileType.email
                ].includes(value);
            }
        },
        className: {
            type: String
        },
        file: {
            type: String,
        },
        fileUrl: {
            type: String,
        },
    },
    data (): object { // TODO revert
        return {

            authSrc: null

        };
    },
    computed: {
        isVideo (): boolean {
            return this.file === EDocumentType.videoMp4;
        },
        isJpeg (): boolean {
            return this.file === EDocumentType.jpeg || this.file === EDocumentType.png;
        },
        isPdf (): boolean {
            return this.file === EDocumentType.pdf;
        },
        isDocX (): boolean {
            return this.file === EDocumentType.docX;
        },
        isThumb (): boolean {
            return this.fileType === EComplianceDocumentFileType.thumb;
        },
        isMpeg (): boolean {
            return this.file === EDocumentType.mp3;
        },
        isGif (): boolean {
            return this.file === EDocumentType.gif;
        },
        isEmail (): boolean {
            return this.fileType === EComplianceDocumentFileType.email;
        },
        isFileUrl (): string { // TODO revert
            return this.fileUrl || this.authSrc;
        }
    },
    created (): void {
        this.handlerCreatedComponent();
    },
    methods: {
        handlerCreatedComponent (): void {
            this.$emit('created');
        },
        getTypeIcon (): string {
            switch (true) {
            case this.isVideo:
                return '/img/clients/kyc/format/video-format.svg';
            case this.isPdf:
                return '/img/clients/kyc/format/pdf-format.svg';
            case this.isDocX:
                return '/img/clients/kyc/format/doc-format.svg';
            case this.isMpeg:
                return '/img/clients/kyc/format/audio-format.svg';
            case this.isGif:
                return '/img/clients/kyc/format/gif-format.svg';
            case this.isJpeg:
                return '/img/clients/kyc/format/jpg-format.svg';
            }
        },
    },
};
