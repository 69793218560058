export enum QuestionnaireEnumId {
    AcceptPrivacyPolicyTermsOfUse = 10,
    AcceptRisks = 11
}

export enum ENamesAwarenessMessages {
    W8BEN = 'W-8 BEN',
}

export enum EFieldValueDataKeys {
    custom = 'custom',
    display = 'display',
    value = 'value',
}
