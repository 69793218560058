import Vue from 'vue';
import rowItemPropsMixin from '~/components/tables/mixins/table-cells-mixins/row-item-props-mixin';
import { getIconByLegalSubjectId } from '~/helpers/getIconByLegalSubjectId';


export default Vue.extend({
    mixins: [
        rowItemPropsMixin,
    ],
    computed: {
        iconByLegalSubjectId (): string {
            return getIconByLegalSubjectId(this.cell.value?.legal_subject?.id);
        },
    }
});
