import Vue from 'vue';
import rowItemPropsMixin from '@/js/components/tables/mixins/table-cells-mixins/row-item-props-mixin';

export default Vue.extend({
    mixins: [
        rowItemPropsMixin,
    ],
    computed: {
        isValue (): string {
            return this.cell.value &&
            this.cell.cellDataKey &&
            this.cell.value[this.cell.cellDataKey]
                ?
                this.cell.value[this.cell.cellDataKey] : '-';
        }
    }
});
