import Vue from 'vue';
import {
    CalendarSelectInterfaces,
    ICalendarSelectDate,
    IFormattedDate,
    IDefaultDate,
} from './interfaces/calendar-select-interfaces';
import DatePicker from 'vue2-datepicker';
import { parseDate, changeStringFormatDate, daysToMilliseconds } from '~/helpers/common';
import { ECalendarEvents } from '~/components/calendar-select/enums/calendar-events.enum';
import { ECalendarValueType } from '~/components/calendar-select/enums/calendar-value-type.enum';
import { ECalendarOffsetMonths } from '~/components/calendar-select/enums/calendar-offset-months.enum';
import {
    ECalendarOptions,
    ECalendarOptionsDays,
    ECalendarOptionsAmountDays
} from '~/components/calendar-select/enums/calendar-options.enum';
import { IDefaultConfigValue } from '~/store/modules/configs/interfaces/configs-interface';
import generateUniqueIdMixin from '~/components/mixins/generate-unique-id-mixin';
import { IConfigValue } from '~/settings/interfaces/config-default-interfaces';

export default Vue.extend({
    components: {
        DatePicker
    },
    mixins: [generateUniqueIdMixin],
    props: {
        addClass: {
            type: String
        },
        formatDate: {
            type: String,
            default: 'YYYY-MM-DD',
        },
        value: {
            type: [Object, String],
        },
        placeholder: {
            type: String,
            default: 'Select',
        },
        placeholderCenter: {
            type: Boolean,
            default: false,
        },
        labelName: {
            type: Boolean,
            default: true,
        },
        selectedType: {
            type: [Number, String],
        },
        icon: {
            type: Boolean,
            default: false,
        },
        typeOptions: {
            type: String,
            required: false,
        },
        isShowCloseButton: {
            type: Boolean,
            default: false,
        }
    },
    data (): CalendarSelectInterfaces {
        return {
            className: 'b-calendar-select',
            selectedValue: 'Select',
            selectedOption: {
                name: 'Select',
                value: ECalendarOptions.select,
            },
            formatDateSelectedValue: 'YY.MM.DD',
            customDateFirst: null,
            customDateLast: null,
            visibleCustomDate: false,
            visibleContent: false,
            isOpenCalendarFrom: false,
            isOpenCalendarTo: false,
            isClickedAcceptCustomDate: false,
            oldSelectedOption: ECalendarOptions.select,
            isResetButtonClicked: false,
            cleanDate: {
                default: {
                    startDate: '',
                    endDate: '',
                },
                formattedDate: {
                    startDate: '',
                    endDate: '',
                },
                calendar_type: null,
            },
            calendarOptionsTypes: {
                calendarOptions: [
                    {
                        name: 'Today',
                        value: ECalendarOptions.today,
                    },
                    {
                        name: 'This Week',
                        value: ECalendarOptions.week,
                    },
                    {
                        name: 'This Month',
                        value: ECalendarOptions.month,
                    },
                    {
                        name: 'Last 3 Months',
                        value: ECalendarOptions.lastMonth_3,
                    },
                    {
                        name: 'Last 6 Months',
                        value: ECalendarOptions.lastMonth_6,
                    },
                    {
                        name: 'YTD',
                        value: ECalendarOptions.ytd,
                    },
                    {
                        name: 'Custom',
                        value: ECalendarOptions.custom,
                    },
                ],
                calendarOptionsDays: [
                    {
                        name: 'Today',
                        value: ECalendarOptionsDays.today,
                    },
                    {
                        name: 'Last 7 Days',
                        value: ECalendarOptionsDays.lastDays_7,
                    },
                    {
                        name: 'Last 30 Days',
                        value: ECalendarOptionsDays.lastDays_30,
                    },
                    {
                        name: 'Last 90 Days',
                        value: ECalendarOptionsDays.lastDays_90,
                    },
                    {
                        name: 'Last 120 Day',
                        value: ECalendarOptionsDays.lastDays_120,
                    },
                    {
                        name: 'YTD',
                        value: ECalendarOptionsDays.ytd,
                    },
                    {
                        name: 'Custom',
                        value: ECalendarOptionsDays.custom,
                    },
                ],
            },
        };
    },
    computed: {
        getOptions (): IDefaultConfigValue[] {
            return this.typeOptions
                ? this.calendarOptionsTypes[this.typeOptions]
                : this.calendarOptionsTypes.calendarOptions;
        },
        isVisibleCustomDate (): boolean {
            return this.visibleCustomDate;
        },
        isVisibleContent (): boolean {
            return this.visibleContent;
        },
        isSelectedOptionValueNotSelectAndCustomType (): boolean {
            return this.selectedOption?.value !== ECalendarOptions.select &&
                this.selectedOption?.value !== ECalendarOptions.custom;
        },
        isSelectedOption (): boolean {
            return this.isSelectedOptionValueNotSelectAndCustomType ||
                this.isClickedAcceptCustomDate;
        },
        isSingleText (): string {
            return this.labelName &&
                this.isSelectedOptionValueNotSelectAndCustomType ?
                this.selectedOption.name : this.selectedValue;
        },
        getSelectedOption (): IDefaultConfigValue {
            return this.selectedOption;
        },
        showCloseButton (): boolean {
            return !this.isShowCloseButton && this.isSelectedOption;
        }
    },
    watch: {
        value: {
            deep: true,
            immediate: true,
            handler (value: string | IFormattedDate | IDefaultDate): void {
                this.handlerValue(value);
            }
        },
        selectedType: {
            immediate: true,
            handler (value: number | string): void {
                if (value) this.handlerSelectedType(value);
            }
        }
    },
    methods: {
        selectOption (type: IDefaultConfigValue) {
            this.selectedOption = type;
            switch (type.value) {
            case ECalendarOptions.today:
                this.selectToday(type.value);
                break;
            case ECalendarOptions.week:
                this.selectWeek(type.value);
                break;
            case ECalendarOptions.month:
                this.selectMonth(ECalendarOffsetMonths.default, type.value);
                break;
            case ECalendarOptions.lastMonth_3:
                this.selectMonth(ECalendarOffsetMonths.threeMonth, type.value);
                break;
            case ECalendarOptions.lastMonth_6:
                this.selectMonth(ECalendarOffsetMonths.sixMonth, type.value);
                break;
            case ECalendarOptions.ytd:
                this.selectYear(type.value);
                break;
            case ECalendarOptions.custom:
                this.showCustomDate();
                break;
            case ECalendarOptionsDays.lastDays_7:
                this.selectDays(type.value, ECalendarOptionsAmountDays.lastDays_7);
                break;
            case ECalendarOptionsDays.lastDays_30:
                this.selectDays(type.value, ECalendarOptionsAmountDays.lastDays_30);
                break;
            case ECalendarOptionsDays.lastDays_90:
                this.selectDays(type.value, ECalendarOptionsAmountDays.lastDays_90);
                break;
            case ECalendarOptionsDays.lastDays_120:
                this.selectDays(type.value, ECalendarOptionsAmountDays.lastDays_120);
                break;
            }
        },
        clickOnBody (event: any): void {
            const target = event.target;
            if (!(target.classList.contains === this.generatedUniqueId ||
                target.closest(`.${this.generatedUniqueId}`)) ||
                !(target.classList.contains === this.className ||
                target.closest(`.${this.className}`))
            ) {
                this.hideContent();

                if (this.oldSelectedOption !== ECalendarOptions.select
                    && this.isResetButtonClicked
                ) {
                    this.resetValueAfterClickBody();
                    this.oldSelectedOption = ECalendarOptions.select;
                    this.isResetButtonClicked = false;
                }
            }
        },
        handleClick () {
            this.showContent();

            setTimeout(() => {
                this.$root.$el.addEventListener('click', this.clickOnBody);
            }, 100);
        },
        showContent () {
            if (this.visibleContent) {
                this.hideContent();
            } else {
                this.visibleContent = true;
            }

            if (this.isResetButtonClicked && !this.visibleContent) {
                this.resetValueAfterClickBody();
                this.isResetButtonClicked = false;
            }
        },
        hideContent () {
            this.visibleContent = false;
            this.hideCustomDate();
            setTimeout(() => {
                this.$root.$el.removeEventListener('click', this.clickOnBody);
            }, 100);
        },
        showCustomDate () {
            this.visibleCustomDate = true;
        },
        hideCustomDate () {
            this.visibleCustomDate = false;
        },
        setSelectedValueDefault () {
            this.selectedValue = this.placeholder;
        },
        setSelectedOptionDefault () {
            this.selectedOption = {
                name: 'Select',
                value: ECalendarOptions.select,
            };
        },
        handleDefaultEvent (payload: IDefaultDate, type?: number) {
            this.setSelectedValue(payload);
            const dates = this.setMoreFormatsDate(payload, type);
            this.$emit(ECalendarEvents.select, dates);
            this.hideContent();
        },
        disabledFromFirstDate (date: Date): boolean {
            return this.customDateLast ? date >= this.customDateLast : false;
        },
        disabledFromLastDate (date: Date): boolean {
            return date <= this.customDateFirst;
        },
        setFormatDate (date: Date, format: string = ''): string {
            const _format = format || this.formatDate;
            return parseDate(date, _format).format(_format);
        },
        setMoreFormatsDate (date: IDefaultDate, type?: number): ICalendarSelectDate {
            const defaultDate: IDefaultDate = date;
            const formattedDate: IFormattedDate = {
                startDate: '',
            };
            Object.keys(date).forEach(key => {
                formattedDate[key] = this.setFormatDate(date[key]);
            });
            return {
                default: defaultDate,
                formattedDate: formattedDate,
                calendar_type: type,
                selected_option: this.selectedOption,
            };
        },
        setSelectedValue (date: IDefaultDate): void {
            this.selectedValue = date.endDate ?
                `${this.setFormatDate(date.startDate, this.formatDateSelectedValue)} ~ ${this.setFormatDate(date.endDate, this.formatDateSelectedValue)}` :
                this.setFormatDate(date.startDate, this.formatDateSelectedValue) || this.setSelectedValueDefault();
            this.isClickedAcceptCustomDate = true;
        },
        resetValue () {
            this.oldSelectedOption = this.selectedOption.value;
            this.setSelectedValueDefault();
            this.setSelectedOptionDefault();
            this.hideContent();
            this.isClickedAcceptCustomDate = false;
            this.isResetButtonClicked = true;
        },
        resetValueWithHandleClick (): void {
            this.resetValue();
            this.handleClick();
        },
        resetValueAfterClickBody () {
            this.resetValue();
            this.$emit(ECalendarEvents.closeAfterReset, this.cleanDate);
        },
        openCalendarForFrom () {
            this.$refs.datePickerFrom.focus();
        },
        openCalendarForTo () {
            this.$refs.datePickerTo.focus();
        },
        changeValueisOpenCalendarFrom (booleanValue: boolean) {
            this.isOpenCalendarFrom = booleanValue;
        },
        changeValueisOpenCalendarTo (booleanValue: boolean) {
            this.isOpenCalendarTo = booleanValue;
        },
        closeCalendarFrom () {
            this.changeValueisOpenCalendarFrom(false);
        },
        closeCalendarTo () {
            this.changeValueisOpenCalendarTo(false);
        },
        openCalendarHandlerFrom () {
            if (this.isOpenCalendarFrom) {
                this.changeValueisOpenCalendarFrom(false);
                this.$refs.datePickerFrom.closePopup();
            } else {
                this.changeValueisOpenCalendarFrom(true);
                this.$refs.datePickerFrom.openPopup();
            }
        },
        openCalendarHandlerTo () {
            if (this.isOpenCalendarTo) {
                this.changeValueisOpenCalendarTo(false);
                this.$refs.datePickerTo.closePopup();
            } else {
                this.changeValueisOpenCalendarTo(true);
                this.$refs.datePickerTo.openPopup();
            }
        },
        selectToday (type: number) {
            const today = new Date();
            const tomorrow = new Date(new Date().setDate(today.getDate() + 1));
            const currentDay: IDefaultDate = {
                startDate: today,
                endDate: tomorrow,
            };
            this.handleDefaultEvent(currentDay, type);
        },
        selectWeek (type: number) {
            const currentDay = new Date();
            const first = currentDay.getDate() - currentDay.getDay();
            const last = first + 6;

            const firstDay = new Date(currentDay.setDate(first));
            const lastDay = new Date(currentDay.setDate(last));
            const date: IDefaultDate = {
                startDate: firstDay,
                endDate: lastDay,
            };
            this.handleDefaultEvent(date, type);
        },
        selectMonth (months: number, type: number) {
            const currentDay = new Date(),
                y = currentDay.getFullYear(),
                m = currentDay.getMonth(),
                mFirstData = months !== 0 ? (m + 1 - months) : m;

            const firstDay = new Date(y, mFirstData, 1);
            const lastDay = new Date(y, m + 1, 0);
            const date: IDefaultDate = {
                startDate: firstDay,
                endDate: lastDay,
            };
            this.handleDefaultEvent(date, type);
        },
        selectYear (type: number) {
            const currentDay = new Date();
            const firstDay = new Date(new Date().getFullYear(), 0, 1);
            const date: IDefaultDate = {
                startDate: firstDay,
                endDate: currentDay,
            };
            this.handleDefaultEvent(date, type);
        },
        setCustomDate () {
            if (this.customDateFirst && this.customDateLast) {
                this.handleDefaultEvent({
                    startDate: this.customDateFirst,
                    endDate: this.customDateLast,
                },
                ECalendarOptions.custom,);
            }
        },
        selectDays (type: number, amountDays: number): void {
            const currentDay = Date.now();
            const startDate = currentDay - daysToMilliseconds(amountDays);

            const date: IDefaultDate = {
                startDate: new Date(startDate),
                endDate: new Date(currentDay),
            };

            this.handleDefaultEvent(date, type);
        },
        handlerValue (value: string | IDefaultDate | IFormattedDate) {
            switch (typeof value) {
            case ECalendarValueType.string:
                this.selectedValue = value;
                break;
            case ECalendarValueType.object:
                this.handlerValueObj(value);
                break;
            }
        },
        handlerValueObj (value: IDefaultDate | IFormattedDate): void {
            switch (typeof (value.startDate)) {
            case ECalendarValueType.string:
                if (value.startDate) {
                    this.selectedValue = value.endDate ?
                        `${changeStringFormatDate(value.startDate as string)} ~ ${changeStringFormatDate(value.endDate as string)}` :
                        changeStringFormatDate(value.startDate as string);
                } else {
                    this.setSelectedValueDefault();
                }
                break;
            case ECalendarValueType.object:
                this.setSelectedValue(value);
                break;
            }
        },
        handlerSelectedType (type: number | string): void {
            this.selectedOption = this.findCalendarOption(type);
        },
        findCalendarOption (type: number | string): IConfigValue {
            return this.getOptions
                .find((option: IConfigValue) => option.value === Number(type));
        }
    }
});
