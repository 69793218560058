// Imports
import Vue from 'vue';
import AppButton from '~/components/parts/app-button/app-button.vue';
import AppInput from '~/components/parts/app-input/app-input.vue';
import AppMultiSelect from '~/components/parts/app-multiselect/app-multiselect.vue';
import currencyHelper from '~/components/mixins/currency-helper';
import bonusPlanTable from '~/components/affiliates/affiliates-bonus-plan/bonus-plan-table/bonus-plan-table.vue';
import bonusPlanEditFormMixin from '~/components/mixins/bonus-plan-edit-form-mixin';
import bonusPlanForms from '~/components/_forms/bonus-plan-forms/bonus-plan-forms-mixin';

// Exports
export default Vue.extend({
    components: {
        AppButton,
        AppInput,
        AppMultiSelect,
        bonusPlanTable
    },
    mixins: [
        bonusPlanEditFormMixin,
        currencyHelper,
        bonusPlanForms,
    ],
    inject: ['bonusPlanListNames'],
    props: {
        bonusPlan: {
            type: Object,
        },
        isEdit: {
            type: Boolean,
        },
    }
});
