import { ProfileModule } from '~/store/modules/profile/profile';
import { names as usersManagement } from '../modules/configs/usersManagement';
import { names as campaign } from '../modules/configs/campaign';
import { names as usersList } from '~/store/modules/configs/usersList';
import { names as affiliates } from '~/store/modules/configs/affiliates';
import {
    isPermissionNotificationsFunding,
    isPermissionNotificationsKyc,
    isPermissionNotificationsRegister
} from '~/helpers/user-permission';
// TODO
const GET_CONFIGS = 'Configs/loadConfigs';
const GET_PROFILE_AUTH = 'Profile/initAuth';
const SET_PROFILE = 'Profile/setProfile';
const PENDING_COUNT = 'NotificationsMessages/firstLoadNotificationsPendingCount';
const CLEAR_INTERVAL = 'NotificationsMessages/clearInterval';

const auth = store => {
    store.subscribe(({ type }) => {
        if ([GET_PROFILE_AUTH, SET_PROFILE].includes(type) && !!ProfileModule.getProfile.id) { // authorized
            store.dispatch(GET_CONFIGS);
            store.dispatch(usersManagement.actions.handleFetch);
            store.dispatch(campaign.actions.handleFetch);
            store.dispatch(usersList.actions.handleFetch);
            store.dispatch(affiliates.actions.handleFetch);
            if (
                isPermissionNotificationsRegister() ||
                isPermissionNotificationsKyc() ||
                isPermissionNotificationsFunding()
            ) {
                store.dispatch(PENDING_COUNT);
            }
        }

        if ([GET_PROFILE_AUTH, SET_PROFILE].includes(type) && !ProfileModule.getProfile.id) { // logging out
            store.dispatch(usersManagement.actions.clearModule);
            store.dispatch(campaign.actions.clearModule);
            store.dispatch(usersList.actions.clearModule);
            store.dispatch(affiliates.actions.clearModule);
            store.dispatch(CLEAR_INTERVAL);
        }
    });
};

export default auth;

