import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '~/store/index';

// MOCK DATA
import { paymentTypes } from '~/mock-data/payment-methods';
import { IPaymentMethods } from '~/store/modules/configs/payment-methods/payment-methods-interfaces';

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: 'PaymentMethods',
})
class PaymentMethods extends VuexModule {
    paymentTypeMethods: Array<object> = [];
    paymentProviderMethods: IPaymentMethods[] = [];
    methodSelectedDefault: IPaymentMethods = {
        name: 'Select method',
        id: null,
    };
    methodSelected: IPaymentMethods | null = null;

    get getPaymentTypes (): Array<any> {
        return this.paymentTypeMethods;
    }

    get getPaymentMethodSelected (): IPaymentMethods | null {
        return this.methodSelected;
    }

    get getPaymentMethodSelectedDefault (): IPaymentMethods {
        return this.methodSelectedDefault;
    }

    @Mutation
    setPaymentTypeMethods (payload: Array<object>) {
        this.paymentTypeMethods = payload;
    }

    @Mutation
    setPaymentProviderMethods (payload: IPaymentMethods[]) {
        this.paymentProviderMethods = payload;
    }

    @Mutation
    mutationPaymentProviderMethodSelected (payload: IPaymentMethods) {
        this.methodSelected = payload;
    }

    @Action({ commit: 'setPaymentTypeMethods' })
    loadPaymentTypeMethods () {
        return paymentTypes;
    }

    @Action ({ commit: 'mutationPaymentProviderMethodSelected' })
    setPaymentProviderMethodSelected (payload: IPaymentMethods) {
        return payload;
    }

}
export const PaymentMethodsModule = getModule(PaymentMethods);
