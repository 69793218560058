import { SymbolsConfigsModule } from '~/store/modules/symbols-configs/symbols-configs';

export default {
    computed: {
        getSymbolConfigTradingTypes (): string[] {
            return SymbolsConfigsModule.getTradingTypes;
        },
        getSymbolConfigExchange (): string[] {
            return SymbolsConfigsModule.getExchanges;
        },
        getSymbolConfigSectors (): string[] {
            return SymbolsConfigsModule.getSectors;
        },
        getSymbolConfigIndustries (): string[] {
            return SymbolsConfigsModule.getIndustries;
        },
        getSymbolConfigProducts (): string[] {
            return SymbolsConfigsModule.getProducts;
        },
        getSymbolConfigTypes (): string[] {
            return SymbolsConfigsModule.getTypes;
        },
        getSymbolConfigSubTypes (): string[] {
            return SymbolsConfigsModule.getSubtypes;
        },
    }
};
