export enum ETableType {
    leadsTable = 'leadsTable',
    leadActivityLog = 'leadActivityLog',
    usersTable = 'usersTable',
    withdrawalsHistory = 'withdrawalsHistory',
    withdrawalsPending = 'withdrawalsPending',
    campaigns = 'campaigns',
    paymentHistory = 'paymentHistory',
    paymentWithdrawal = 'paymentWithdrawal',
    affiliates = 'affiliates',
    affiliatesReports = 'affiliates-reports',
    affiliatesReportsCampaign = 'affiliates-reports-campaign',
    affiliatesStatistic = 'affiliates-marketing',
    OpenOrders = 'openOrders',
    PendingOrders = 'pendingOrders',
    OrderHistory = 'orderHistory',
    reports = 'reports',
    bankingDetails = 'bankingDetails',
    creditCards = 'creditCards',
    eWallet = 'eWallet',
    crypto = 'crypto',
    notificationsClients = 'notificationsClients',
    notificationsActivity = 'notificationsActivity',
    notificationsAffiliates = 'notificationsAffiliates',
    symbols = 'symbols',
}
