import Vue from 'vue';
import affiliates from '../icons/affiliates.vue';
import calendar from '../icons/calendar.vue';
import check from '../icons/check.vue';
import close from '../icons/close.vue';
import dollarSign from '../icons/dollar-sign.vue';
import email from '../icons/email.vue';
import home from '../icons/home.vue';
import percent from '../icons/percent.vue';
import reports from '../icons/reports.vue';
import sliders from '../icons/sliders.vue';
import usersTwo from '../icons/users-two.vue';
import users from '../icons/users.vue';

export default Vue.extend({
    components: {
        affiliates, // only for navigate menu
        calendar,   // only for navigate menu
        check,
        close,
        dollarSign, // only for navigate menu
        email,      // only for navigate menu
        home,       // only for navigate menu
        percent,    // only for navigate menu
        reports,    // only for navigate menu
        sliders,    // only for navigate menu
        usersTwo,   // only for navigate menu
        users,      // only for navigate menu
    },
    props: {
        symbol: {
            type: String,
        },
        color: {
            type: String,
        },
        customSize: {
            type: String,
        },
        size: {
            type: String,
            default: '0 0 24 24',
        },
        title: {
            type: String,
        }
    },
    data () {
        return {
            className: 'b-icon',
        };
    },
    computed: {
        iconHeight (): string {
            return this.size.split(' ')[3];
        },
        iconWidth (): string {
            return this.size.split(' ')[2];
        }
    }
});
