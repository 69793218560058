import { extend } from 'vee-validate';

/**
 * Email Open Account
 **/

const checkEmailOpenAccount = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,14}$/i;
export const emailValidation = extend('email-validation', {
    validate (value) {
        return checkEmailOpenAccount.test(value);
    },
    message: 'Add a valid email format!',
});
