// Imports
import Vue from 'vue';
import configsMixin from '~/components/mixins/configs-mixin';
import { EBonusPlanRevenueType } from '../enums/bonus-plan-revenue-type.enum';

// Exports
export default Vue.extend({
    mixins: [
        configsMixin,
    ],
    props: {
        items: {
            type: Array,
            default: () => {
                return [];
            },
        },
        isEdit: {
            type: Boolean,
        },
        isFixed: {
            type: Number,
        },
        currencyType: {
            type: Object,
        },
        preSetOne: {
            type: Boolean,
            default: false,
        }
    },
    data () {
        return {
            itemsToEdit: [],
        };
    },
    computed: {
        isFixedTitle (): string {
            return EBonusPlanRevenueType[Number(this.isFixed)];
        },
        isCurrencyType (): string {
            return this.currencyType?.sign;
        },
        isRevenueColumnSign (): string {
            return !this.isFixed ? '%' : this.isCurrencyType;
        },

    },
    watch: {
        items: {
            immediate: true,
            deep: true,
            handler () {
                this.setObj();
            }
        },
        isEdit (payload: boolean) {
            if (!payload) this.clearCountriesList();
        }
    },
    created (): void {
        this.handlePreSetOne();
    },
    methods: {
        handlePreSetOne (): void {
            if (this.preSetOne) {
                this.newItem();
            }
        },
        clearCountriesList () {
            this.itemsToEdit = this.items
                .map(item => {
                    return item.value ? Object.assign({}, item) : false;
                });
        },
        setObj () {
            this.itemsToEdit = this.items ?
                this.items.map(item => Object.assign({}, item)) :
                [];
            this.itemsToEdit.sort((a, b) => a.value - b.value);
        },
        newItem (): void {
            this.itemsToEdit = [
                {
                    from: null,
                    to: null,
                    value: null,
                },
                ...this.itemsToEdit,
            ];
        },
        removeItem (index: number): void {
            this.$delete(this.itemsToEdit, index);
        }
    },
});
