import Vue from 'vue';
import { CalendarInterfaces } from './interfaces/calendar-interfaces';
import subNavbar from '~/components/subNavbar/subNavbar.vue';
import subNavbarLink from '~/components/subNavbar/subNavbarLink.vue';
import subNavbarSeparator from '~/components/subNavbar/subNavbarSeparator.vue';
import calendarEvents from '~/components/calendar-events/calendar-events.vue';
import { CalendarModule } from '~/store/modules/calendar/calendar';
import calendarNotifications from '~/components/calendar-notifications/calendar-notifications.vue';

export default Vue.extend({
    components: {
        'sub-navbar': subNavbar,
        'sub-navbar-link': subNavbarLink,
        'sub-navbar-separator': subNavbarSeparator,
        calendarEvents,
        calendarNotifications,
    },
    data (): CalendarInterfaces {
        return {
            className: 'b-calendar-page',
            isFullForm: false,
        };
    },
    methods: {
        addNotification (): void {
            CalendarModule.addNotification({
                text: 'testNotification'
            });
        },
        selectToday () {
            CalendarModule.setCurrentView('timeGridDay');
            this.changeHeight(1090);
        },
        selectWeek () {
            CalendarModule.setCurrentView('timeGridWeek');
            this.changeHeight(1090);
        },
        selectMonth () {
            CalendarModule.setCurrentView('dayGridMonth');
            this.changeHeight(958);
        },
        changeHeight (height) {
            const el = <HTMLElement>document.querySelector('.fc-view-harness-active');
            el.style.height = `${height}px`;
        },
        showFullForm () {
            this.isFullForm = true;
        }
    }

});
