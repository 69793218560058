import Vue from 'vue';
import PinchScrollZoom from '@coddicat/vue-pinch-scroll-zoom';
import documentViewerMixin from '~/components/mixins/document-viewer-mixin';

export default Vue.extend({
    components: {
        PinchScrollZoom,
    },
    mixins: [
        documentViewerMixin,
    ],
    data () {
        return {
            scale: 1,
            rotate: 0,
        };
    },
    methods: {
        rotateImageRight (): void {
            this.rotate += 90;
        },
        rotateImageLeft (): void {
            this.rotate -= 90;
        },
        resetRotate (): void {
            this.rotate = 0;
        },
        resetScaling () {
            this.resetRotate();
            (this.$refs.zoomer as PinchScrollZoom).setData({
                scale: 1,
                originX: 0,
                originY: 0,
                translateX: 0,
                translateY: 0
            });
        }
    }
});
