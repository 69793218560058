// Imports
import Vue from 'vue';
import AccountingBonusPlanTable from './accounting-bonus-plan-table/accounting-bonus-plan-table.vue';
import Pagination from '~/components/parts/pagination/pagination.vue';

// Exports
export default Vue.extend({
    components: {
        'accounting-bonus-plan-table': AccountingBonusPlanTable,
        'pagination': Pagination
    },
    data () {
        return {
        };
    }
});
