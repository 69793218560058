export const FIELD_TYPE_INPUT = 'input';
export const FIELD_TYPE_SELECT = 'select';
export const FIELD_TYPE_RADIO = 'radio';

export const FIELD_TYPES = {
    FIELD_TYPE_INPUT,
    FIELD_TYPE_SELECT,
    FIELD_TYPE_RADIO
};
export const FIELD_TYPE_DEFAULT = FIELD_TYPE_INPUT;

export default {
    props: {
        disabled: {
            type: Boolean,
            default () {
                return false;
            }
        }

    },
    data () {
        return {
            ...FIELD_TYPES,
        };
    }
};
