import { mapActions, mapGetters } from 'vuex';
import { urlClientKyc } from '~/settings/ajax-urls';
import { actionLoadKyc, names as clientKycNames } from '~/store/modules/client-kyc/client-kyc';
import { handleFetchLeadDetails, names as leadDetailsNames } from '~/store/modules/leadDetails';
import { ISearchMain, ISearchMainHistory } from '~/settings/interfaces/search-main-interfaces';
import { ERouterNameFromPermission } from '~/settings/enums/router-name.enum';
import { KycTradingModule } from '~/store/modules/kyc-trading/kyc-trading';
import { LeadAccountsModule } from '~/store/modules/lead-accounts/lead-accounts';
import { ClientKYCFinancesModule } from '~/store/modules/client-kyc-finances/client-kyc-finances';
import { PaymentRequisitesModule } from '~/store/modules/payment-requisites/payment-requisites';
import { DocumentRequestsModule } from '~/store/modules/documents/document-requests';
import { ComplianceUploadedDocumentsModule } from '~/store/modules/documents/uploadedDocuments';
import { NotesModule } from '~/store/modules/notes/notes';
import { ESearchMainType } from '~/settings/enums/search-main.enum';
import { SearchMainModule } from '~/store/modules/search-main/search-main';
import { ISearchMainQuery } from '~/store/modules/search-main/interfaces/search-main-interfaces';

export default {
    data (): object {
        return {
            searchIndex: 0,
            searchOptions: [],
        };
    },
    computed: {
        ...mapGetters({
            leadDetails : leadDetailsNames.getters.entity,
        }),
    },
    methods: {
        ...mapActions({
            handleFetchClientKyc : clientKycNames.actions.handleFetch,
            handleFetchLeadDetails: handleFetchLeadDetails,
            loadKyc: actionLoadKyc,
        }),
        resetSearchIndex (): void {
            this.$set(this.$data, 'searchIndex', 0);
        },
        setSearchIndex (index: number): void {
            this.$set(this.$data, 'searchIndex', index);
        },
        setSearchOptions (payload: ISearchMain[]): void {
            this.searchOptions = [
                ...this.searchOptions,
                ...payload,
            ];
        },
        resetSearchOptions (payload: ISearchMain[] = []): void {
            this.searchOptions = payload;
        },
        async fetchSearch (payload: ISearchMainQuery, reset: boolean = false): Promise<void> {
            const data = await SearchMainModule.fetchSearchMain(payload);
            if (reset) {
                this.resetSearchOptions(data);
            } else {
                this.setSearchOptions(data);
            }
        },
        asyncFind (payload: ISearchMainQuery): void {
            this.setSearchIndex(payload.search.length);

            if (payload.search) {
                setTimeout(() => {
                    if (payload.search.length === this.searchIndex) {
                        this.fetchSearch(payload, true);
                    }
                }, 500);
            } else {
                this.resetSearchOptions();
            }
        },

        selectType (payload: ISearchMainQuery): void {
            this.fetchSearch(payload, true);
        },

        async selectInput (selected: ISearchMain): Promise<void> {
            if (!selected) return;

            if (selected.model.type === ESearchMainType.clients) {
                if (+this.$route.params.id !== selected.model.id) {
                    const searchMainComponent = this.$refs.searchMain;
                    const redirectTo = this.redirectToRoute();
                    await this.$router.replace({ name: redirectTo, params: { id: selected.model.id } });
                    await this.handlerLoadMethods(redirectTo)
                        .then(result => {
                            const leadDetails = result[ERouterNameFromPermission.leadsItemsDetails];
                            searchMainComponent.setHistoryItem({
                                id: selected.model.id,
                                name: leadDetails.full_name,
                                email: leadDetails.email,
                                clientType: selected.model.legal_subject_id,
                            });
                        });
                }
            }
        },

        selectItemHistory (selected: ISearchMainHistory): void {
            if (+this.$route.params.id !== selected.id) {
                const redirectTo = this.redirectToRoute();
                this.$router.replace({ name: redirectTo, params: { id: selected.id } });
                this.handlerLoadMethods(redirectTo);
            }
        },

        async handlerNextPage (payload: ISearchMainQuery): Promise<void> {
            const {
                last_page,
                current_page,
            } = SearchMainModule.getSearchPagination;
            if (current_page < last_page) {
                this.$refs.searchMain.disableScroll();
                await this.fetchSearch({
                    ...payload,
                    page: current_page + 1,
                })
                    .then(() => {
                        this.$refs.searchMain.enableScroll();
                    });
            }
        },
        closeSearch (): void {
            this.resetSearchOptions();
            this.resetSearchIndex();
        },
        redirectToRoute (): string {
            switch (this.$router.currentRoute.name) {
            case ERouterNameFromPermission.leadsItemsDetails:
            case ERouterNameFromPermission.leadsItemsKyc:
            case ERouterNameFromPermission.leadsItemsTrading:
            case ERouterNameFromPermission.leadsItemsFinance:
                return this.$router.currentRoute.name;
            default:
                return ERouterNameFromPermission.leadsItemsDetails;
            }
        },
        handleFetchDocumentsRequests (id: number): void {
            DocumentRequestsModule.actionFetchDocumentsRequests(id);
        },
        handlerFetchClientKyc (id: number): void {
            this.handleFetchClientKyc({ getAjaxUrl : () => urlClientKyc(id) });
        },
        handleFetchComplianceDocuments (id: number): void {
            ComplianceUploadedDocumentsModule.receiveUploadedDocuments({ leadId: id });
        },
        handleFetchNotes (id: number): void {
            NotesModule.loadNotes(id);
        },
        async handlerLoadMethods (name: string): Promise<object> {  // TODO
            const leadId = this.$route.params.id;
            const result = {};
            switch (name) {
            case ERouterNameFromPermission.leadsItemsDetails:
                result[ERouterNameFromPermission.leadsItemsDetails] = await this.handlerLoadDetails(leadId);
                break;
            case ERouterNameFromPermission.leadsItemsKyc:
                await this.handlerLoadKyc(leadId);
                result[ERouterNameFromPermission.leadsItemsDetails] = await this.handlerLoadDetails(leadId);
                break;
            case ERouterNameFromPermission.leadsItemsTrading:
                await this.handlerLoadTrading(leadId);
                result[ERouterNameFromPermission.leadsItemsDetails] = await this.handlerLoadDetails(leadId);
                break;
            case ERouterNameFromPermission.leadsItemsFinance:
                await this.handlerLoadFinance(leadId);
                result[ERouterNameFromPermission.leadsItemsDetails] = await this.handlerLoadDetails(leadId);
                break;
            }
            return result;
        },
        async handlerLoadDetails (leadId: number): Promise<any> {
            const data = await this.handleFetchLeadDetails(leadId);
            this.handleFetchNotes(leadId);
            return data;
        },
        handlerLoadKyc (leadId: number): void {
            this.handlerFetchClientKyc(leadId);
            this.handleFetchComplianceDocuments(leadId);
            this.handleFetchDocumentsRequests(leadId);
            this.loadKyc(leadId);
        },
        handlerLoadTrading (leadId: number): void {
            const query = this.$route.query;
            KycTradingModule.loadOrders({ query, type: KycTradingModule.getCurrentTradingTab });
            KycTradingModule.setTradingSumInfo();
            LeadAccountsModule.handleSetAccounts(leadId);
        },
        handlerLoadFinance (leadId: number): void {
            LeadAccountsModule.handleSetAccounts(leadId);
            ClientKYCFinancesModule.loadPaymentsHistory();
            PaymentRequisitesModule.loadPaymentRequisites();
        }
    }
};
