<template>
    <div class="select-template">
        <div
            v-if="isDragging"
            class="drop-zone"
            @drop.prevent="drop"
            @dragover.prevent
        >
            <material-icon
                symbol="upload_file"
                size="attachment-email"
                cursor="pointer"
            />

            <span class="drop-zone__description">
                Drag your document into this box to upload
            </span>
        </div>

        <template v-if="!isOpenTemplates">
            <div class="d-flex flex-row editor_template">
                <button
                    class="template-btn d-flex flex-row align-items-center flex-nowrap"
                    @click="toggleStateOpenTemplates"
                >
                    <material-icon
                        symbol="edit"
                        color="blue"
                        size="sm"
                    />
                    <span>Select Template</span>
                </button>
                <input
                    v-model="emailSubject"
                    type="text"
                    placeholder="Subject..."
                    class="template-input"
                >
            </div>
            <Editor
                v-model="content"
                :api-key="apiKey"
                :init="settings"
            />

            <div
                v-if="sendDocuments.length"
                class="drop-zone-documents"
            >
                <span
                    v-for="document in sendDocuments"
                    :key="document.id"
                    class="drop-zone-documents__item"
                >
                    <span>{{ document.title }}</span>
  
                    <material-icon
                        class="drop-zone-documents__icon"
                        symbol="close"
                        size="xs"
                        color="white"
                        title="Remove file"
                        cursor="pointer"
                        delay
                        @clicked="removeDocument(document.id)"
                    />
                </span>
            </div>

            <div class="d-flex flex-row editor_template_controls">
                <div
                    :class="{'editor_template_controls-item_active': isTemplateActive}"
                    class="editor_template_controls-item d-flex flex-row"
                    @click="discardEmail"
                >
                    <span class="editor_template_controls-item-text">
                        Discard Email
                    </span>

                    <material-icon
                        symbol="delete"
                        :color="isTemplateActive ? 'blue' : 'grey'"
                        size="great-lg"
                        class="ml-2"
                        add-class="wght-light"
                    />
                </div>

                <div
                    :class="{'editor_template_controls-item_active': isCanSave}"
                    class="editor_template_controls-item d-flex flex-row"
                    @click="saveChanges"
                >
                    <span class="editor_template_controls-item-text">
                        Save Template
                    </span>

                    <material-icon
                        symbol="save"
                        :color="isCanSave ? 'blue' : 'grey'"
                        size="great-lg"
                        class="ml-2"
                        add-class="wght-light"
                    />
                </div>

                <div
                    :class="{'editor_template_controls-item_active': isCanSaveAs}"
                    class="editor_template_controls-item d-flex flex-row"
                    @click="saveAsTemplate()"
                >
                    <span class="editor_template_controls-item-text">
                        Save as Template
                    </span>

                    <material-icon
                        symbol="save"
                        :color="isCanSaveAs ? 'blue' : 'grey'"
                        size="great-lg"
                        class="ml-2"
                        add-class="wght-light"
                    />
                </div>

                <div
                    :class="{'editor_template_controls-item_active': isActiveSendButton}"
                    class="editor_template_controls-item d-flex flex-row ml-auto"
                    @click="sendEmail"
                >
                    <span class="editor_template_controls-item-text">
                        Send
                    </span>

                    <material-icon
                        symbol="send"
                        :color="isActiveSendButton ? 'blue' : 'grey'"
                        size="great-lg"
                        class="ml-2"
                        add-class="wght-light"
                    />
                </div>
            </div>
        </template>

        <template v-else>
            <div class="d-flex flex-column editor_template-select">
                <div
                    class="p-5 d-flex flex-row editor_template-header align-items-baseline"
                    @click="toggleStateOpenTemplates"
                >
                    <b-img
                        src="/img/clients/arrow_back.svg"
                        height="12px"
                    />
                    <p class="mb-0 ml-3">
                        Select a template:
                    </p>
                </div>
                <div class="editor_template-content">
                    <div class="horizontal-scrollbar">
                        <div class=" d-flex flex-row no wrap">
                            <div
                                v-for="(item, idx) in templates"
                                :key="idx"
                                class="editor_template-item"
                            >
                                <div class="editor_template-item_image">
                                    <img
                                        src="/img/clients/Illustrations.svg"
                                        width="47px"
                                    >
                                </div>
                                <div class="editor_template-item_controls">
                                    <span>{{ item.subject }}</span>
                                    <button
                                        class="btn-custom btn-custom-success"
                                        @click="useTemplate(item)"
                                    >
                                        Use this
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue';
import { EmailsTemplatesModule } from '~/store/modules/emails/emails-templates';
import { EEmailType } from '~/components/emails/email-type.enum';
import { MarketingTemplatesModule } from '~/store/modules/marketing/marketing-templates';
import { MarketingListsModule } from '~/store/modules/marketing/marketing-lists';
import materialIcon from './material-icon/material-icon';
import { ERouterNameFromPermission } from '~/settings/enums/router-name.enum';

export default {
    components: {
        Editor,
        materialIcon
    },
    props: {
        emailType: {
            type: String,
        },
        isDragging: {
            type: Boolean,
        },
        isFilteredUserList: {
            type: Boolean,
        }
    },
    data () {
        return {
            apiKey: process.env.API_TINY_KEY,
            settings: {
                plugins: 'lists link image table code help wordcount',
            },
            currentTemplate: null,
            isOpenTemplates: false,
            emailSubject: '',
            content: '',
            sendDocuments: [],
        };
    },
    computed: {
        isTemplateActive () {
            return !!this.content && !!this.emailSubject;
        },
        isMailChimp () {
            return this.emailType === EEmailType.MailChimp;
        },
        templates () {
            return this.isMailChimp ?
                MarketingTemplatesModule.getMarketingTemplates :
                EmailsTemplatesModule.getterEmailTemplates;
        },
        isEmailsPage () {
            return this.$route.name === ERouterNameFromPermission.emailsMass;
        },
        isActiveSendButton () {
            const isActiveOnEmailsPageWithoutType = this.isEmailsPage &&
                this.isFilteredUserList &&
                this.isTemplateActive;

            const isActiveOtherPages = !this.isEmailsPage &&
                this.isTemplateActive;

            return (isActiveOnEmailsPageWithoutType &&
                (!this.isMailChimp ||
                this.isMailChimp &&
                MarketingListsModule.getMarketingLists)) ||
                isActiveOtherPages;
        },
        checkCurrentTemplateTextForEqual () {
            const sameTemplate = this.currentTemplate && this.templates
                .find(template => template.id === this.currentTemplate.id);

            return sameTemplate &&
                this.currentTemplate &&
                sameTemplate.subject === this.emailSubject &&
                sameTemplate.content === this.content;
        },
        isCanSave () {
            return this.currentTemplate &&
                this.isTemplateActive &&
                !this.checkCurrentTemplateTextForEqual;
        },
        isCanSaveAs () {
            return (this.currentTemplate &&
                this.isTemplateActive &&
                !this.checkCurrentTemplateTextForEqual) ||
                (!this.currentTemplate &&
                this.isTemplateActive);
        },
    },
    watch: {
        emailType () {
            this.resetCurrentTemplate();
        }
    },
    mounted () {
        EmailsTemplatesModule.loadEmailTemplates();
    },
    methods: {
        addSendDocument (document) {
            const thereIsSameDocument = this.sendDocuments
                .some(item => item.id === document.id);

            if (!thereIsSameDocument) {
                this.sendDocuments.push(document);
            }
        },
        removeDocument (id) {
            this.sendDocuments = this.sendDocuments
                .filter(document => document.id !== id);
        },
        resetSendDocuments () {
            this.sendDocuments = [];
        },
        payloadForTemplateRequests () {
            return {
                subject: this.emailSubject,
                content: this.content
            };
        },
        resetCurrentTemplate () {
            this.currentTemplate = null;
        },
        discardEmail () {
            this.emailSubject = '';
            this.content = '';
            this.resetCurrentTemplate();
        },
        saveChanges () {
            const payload = {
                ...this.payloadForTemplateRequests(),
                templateId: this.currentTemplate.id,
            };

            return this.isMailChimp ?
                MarketingTemplatesModule.changeMarketingTemplate(payload) :
                EmailsTemplatesModule.changeEmailTemplate(payload);
        },
        async saveAsTemplateBase () {
            const payload = this.payloadForTemplateRequests();
            const newTemplate = this.isMailChimp ?
                await MarketingTemplatesModule.createMarketingTemplate(payload) :
                await EmailsTemplatesModule.createEmailTemplate(payload);

            return newTemplate;
        },
        async saveAsTemplate () {
            this.currentTemplate = await this.saveAsTemplateBase();
        },
        useTemplate (emailTemplate) {
            this.currentTemplate = emailTemplate;
            this.content = emailTemplate.content;
            this.emailSubject = emailTemplate.subject;
            this.toggleStateOpenTemplates();
        },
        async generatePayloadMailChimp () {
            const template = this.currentTemplate || await this.saveAsTemplateBase();
            return {
                title: template.subject,
                template_id: template.id,
            };
        },
        async sendEmail () {
            if (!this.isActiveSendButton) return;

            const payload = this.isMailChimp ?
                await this.generatePayloadMailChimp() :
                await this.payloadForTemplateRequests();

            if (this.sendDocuments.length) {
                payload['attachments[]'] = this.sendDocuments
                    .map(document => document.id);
            }

            this.$emit('sendEmail', payload);

            this.resetCurrentTemplate();
            this.discardEmail();
            this.resetSendDocuments();
        },
        toggleStateOpenTemplates () {
            this.isOpenTemplates = !this.isOpenTemplates;
        },
        setDefaultIsOpenTemplates () {
            this.isOpenTemplates = false;
        },
        drop (event) {
            const document = JSON.parse(event.dataTransfer.getData('text/plain'));
            this.addSendDocument(document);
        },
    },
};
</script>
<style lang="scss" scoped>
    .select-template {
        border: 1px solid #878f94;
        border-radius: 5px;
        position: relative;
        overflow: hidden;

        .drop-zone {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 2;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: rgba($color: #e9edf2, $alpha: 0.5) ;
            backdrop-filter: blur(5px);


            &__description {
                margin-top: 15px;
                font-size: 20px;
            }
        }

        .drop-zone-documents {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            row-gap: 8px;
            padding: 8px 1.7rem;
            border-bottom: 1px solid #ccc;
            background-color: $white;

            &__item {
                display: flex;
                align-items: center;
                color: $white;
                padding: 4px 8px;
                border-radius: 4px;
                background-color: $blue;

                &:not(:last-child) {
                    margin-right: 15px;
                }
            }

            &__icon {
                margin-left: 6px;
            }
        }

        .column {
            display: flex;
        }

        &__btn {
            border-right: 1px solid #878f94;
            padding: 2.2rem;
            color: $info_text;
            font-size: 14px;
            font-weight: 700;
            font-style: normal;
            letter-spacing: normal;
            line-height: 17px;
            cursor: pointer;
            &--border-none {
                border: 0;
            }

            img {
                margin: 0 0 0 5px;
            }
        }
    }

    .success-text {
        margin: 20px 0 0;
        color: green;
        font-size: 14px;
    }

    .error-text {
        margin: 20px 0 0;
        color: red;
        font-size: 14px;
    }
</style>
