// Imports
import Vue from 'vue';
import financeContentItemHeader from '~/components/finance-content-item-header/finance-content-item-header.vue';
import headerItem from '~/components/finance-content-item-header/header-item/header-item.vue';
import configsMixin from '~/components/mixins/configs-mixin';
import { AffiliatesBonusPlansModule } from '~/store/modules/affiliates/affiliates-bonus-plans';
import { IBonusPlan } from '~/store/modules/bonus-plan/interfaces/bonus-plan-interfaces';
import { IConfig } from '~/settings/interfaces/config-default-interfaces';
// Exports
export default Vue.extend({
    components: {
        headerItem,
        financeContentItemHeader
    },
    mixins: [
        configsMixin,
    ],
    computed: {
        getCategories (): IConfig[] {
            return this.getConfigBonusTypes;
        },
        isActiveTab (): number {
            return AffiliatesBonusPlansModule.getActiveCategory;
        },
        bonusPlans (): IBonusPlan[] {
            return AffiliatesBonusPlansModule.getBonusPlanItems;
        },
    },
    methods: {
        setActiveTab (id: number) {
            AffiliatesBonusPlansModule.setActiveCategories(id)
                .then(() => {
                    setTimeout(() => {
                        if (this.bonusPlans.length > 0) {
                            AffiliatesBonusPlansModule.setActiveBonusPlan(this.bonusPlans[0].id);
                        }
                    }, 0);
                });
        },
    },

});
