export const moduleName = 'notifications';

export const getterNotifications = `${moduleName}/getNotifications`;

export const mutationAddNotification = `${moduleName}/addNotification`;
export const mutationRemoveNotification = `${moduleName}/removeNotification`;

export const actionAddNotification = `${moduleName}/setAddNotification`;
export const actionRemoveNotification = `${moduleName}/setRemoveNotification`;
/* notification: {text: '', type: ''}
    types: success, danger, info */
export default {
    state: {
        notifications: [],
    },
    getters: {
        [getterNotifications]: state => {
            return state.notifications;
        }
    },
    mutations: {
        [mutationAddNotification]: (state, payload) => {
            state.notifications = [...state.notifications, { ...payload }];
        },
        [mutationRemoveNotification]: (state, index) => {
            const notifications = [...state.notifications];
            notifications.splice(index, 1);
            state.notifications = [...notifications];
        },
    },
    actions: {
        [actionAddNotification]: ({ commit }, payload) => {
            commit(mutationAddNotification, payload);
        },
        [actionRemoveNotification]: ({ commit }, index) => {
            commit(mutationRemoveNotification, index);
        },
    },
};
