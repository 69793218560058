import Vue from 'vue';
import { NotificationsMessagesModule } from '~/store/modules/notifications/notifications';
import routes from '~/settings/routes';
import { AppModule } from '~/store/modules/app/app';
import { ERouterNameFromPermission } from '~/settings/enums/router-name.enum';
import {
    isPermissionCalendar,
    isPermissionViewClient,
    isPermissionAccounting,
    isPermissionEditWithdrawOrders,
    isPermissionReports,
    isPermissionViewUsers,
    isPermissionAffiliate,
    isPermissionCampaigns,
    isPermissionEmails,
    isPermissionAdmin,
    isPermissionDepartmentCompliance,
    isPermissionDepartmentManagement,
    isPermissionSettingsGroup,
    isPermissionSettingsDocuments,
    isPermissionSettingsSymbols,
} from '~/helpers/user-permission';

export default Vue.extend({
    data (): Object {
        return {
            time: '',
            date: '',
            notifications: routes.notifications.path,
            users : routes.users.path,
            leads : routes.leads.path,
            leadsFiltersTemplates : routes.leadsFiltersTemplates.path,
            accounting : routes.accounting.path,
            settings : routes.settings.path,
            settingsDocuments : routes.settings.subRoutes.documents.path,
            settingsSymbols : routes.settings.subRoutes.symbols.path,
            withdrawals : routes.withdrawals.path,
            reports: routes.reports.path,
            calendar : routes.calendar.path,
            dashboard: routes.dashboard.path,
            emails: routes.emails.path,
            affiliates: routes.affiliatesItem.path,
            affiliatesCampaigns: routes.affiliatesItem.subRoutes.campaigns.path,
        };
    },
    computed: {
        isPermissionCalendar,
        isPermissionViewClient,
        isPermissionAccounting,
        isPermissionEditWithdrawOrders,
        isPermissionReports,
        isPermissionViewUsers,
        isPermissionAffiliate,
        isPermissionCampaigns,
        isPermissionEmails,
        isPermissionAdmin,
        isPermissionDepartmentCompliance,
        isPermissionDepartmentManagement,
        isPermissionSettingsGroup,
        isPermissionSettingsDocuments,
        isPermissionSettingsSymbols,
        isNotifications (): Number {
            return NotificationsMessagesModule.getterNotificationsUnReadCount;
        },
        isShowMenu (): Boolean {
            return AppModule.getIsHideMenu;
        },
        isActiveRoute (): boolean {
            return this.$route.name === ERouterNameFromPermission.leads ||
                this.$route.name === ERouterNameFromPermission.leadsItemsFiltersTemplates;
        },

        isSettingsDisabled (): boolean {
            return this.isPermissionSettingsGroup ||
                this.isPermissionSettingsDocuments ||
                this.isPermissionSettingsSymbols;
        },

        settingsRoute (): string {
            if (this.isPermissionSettingsGroup) return this.settings;
            if (this.isPermissionSettingsDocuments) return this.settingsDocuments;
            if (this.isPermissionSettingsSymbols) return this.settingsSymbols;
            return this.settings;
        }
    },
    methods: {
        hideMenu (): void {
            if (!this.isShowMenu) {
                AppModule.handleShowMenu(true);
            } else {
                AppModule.handleShowMenu(false);
            }
        },
        goToLeads () {
            this.$router.push(this.leads);
        },
        openNewTab () {
            window.open(window.location.href, '_blank');
        }
    }
});
