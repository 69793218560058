// Imports
import Vue from 'vue';
import { DepartmentsModule } from '~/store/modules/departments/departments';
import { IDepartments } from '~/store/modules/departments/interfaces/departments-interfaces';
import settingsGroupItem from '~/components/settings/settings-group-item/settings-group-item.vue';
// Exports
export default Vue.extend({
    components: {
        settingsGroupItem,
    },
    computed: {
        getDepartments (): IDepartments[] {
            return DepartmentsModule.getDepartments;
        }
    },
    created (): void {
        this.loadDepartments();
    },
    methods: {
        loadDepartments (): void {
            DepartmentsModule.loadDepartments();
        },
    },
});
