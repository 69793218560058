// Imports
import { Module, VuexModule, getModule, Action, Mutation } from 'vuex-module-decorators';
import { tableParser } from '~/helpers/table-parser';
import { affiliatesTableHeader } from '~/components/tables/_headers/affiliates';
import store from '~/store';
import Rest from '~/helpers/Rest';
import { 
    urlAffiliates,
    urlAffiliatesDetailsUpdate,
    urlAffiliatesMassiveUpdate,
    urlAffiliatesSearch,
    urlAffiliatesDetails,
} from '~/settings/ajax-urls';
import { ITableHeaderColumn } from '~/components/tables/interfaces/table-header-column.interfaces';
import { ITableCell } from '~/components/tables/interfaces/table-cell-interfaces';
import {
    IAffiliates,
    IAffiliateCreate,
    IAffiliatesSearchResponse,
    IAffiliateMassEdit,
} from '~/store/modules/affiliates/interfaces/affiliates-interfaces';
import { handlerRestResponse } from '~/helpers/handler-rest-response';
import { handlerQueriesToFetch } from '~/helpers/handlers-queries-to-fetch/handler-queries-to-fetch';
import { NotificationPrefabricated } from '~/helpers/modal-notification-services/modal-notification-services';
import { ITableFilterQuery } from '~/components/tables/interfaces/table-filter-query.interfaces';
import { ETableType } from '~/components/tables/enums/table-type.enum';
import { IPagination } from '~/settings/interfaces/pagination-interfaces';
import { showNothingWasFound } from '@/js/components/tables/helpers/showNothingWasFound';

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: 'affiliatesTable',
})

class AffiliatesPage extends VuexModule {
    affiliates: IAffiliates[] = [];
    loaded: boolean = false;
    affiliatesPagination: IPagination = {
        current_page: null,
        from: null,
        last_page: null,
        per_page: null,
        to: null,
        total: null,
    };

    get getAffiliates (): IAffiliates[] {
        return this.affiliates;
    }

    get getterHeader (): ITableHeaderColumn[] {
        return affiliatesTableHeader;
    }

    get getterAffiliatesRows (): ITableCell[] {
        return tableParser(affiliatesTableHeader, this.affiliates);
    }

    get getAffiliatesIsLoaded (): boolean {
        return this.loaded;
    }

    get getAffiliatesPagination (): IPagination {
        return this.affiliatesPagination;
    }

    @Mutation
    setLoadedState (payload: boolean) {
        this.loaded = payload;
    }

    @Mutation
    setAffiliates (payload: IAffiliates[]) {
        this.affiliates = payload;
    }

    @Mutation
    setAffiliatesPagination (payload: IPagination) {
        this.affiliatesPagination = payload;
    }

    @Action({ commit: 'setAffiliates' })
    async loadAffiliates (query: ITableFilterQuery = null): Promise<IAffiliates> {
        this.setLoadedState(false);
        query = handlerQueriesToFetch(query, ETableType.affiliates);
        const { data, meta } = await Rest.GET(urlAffiliates(query))
            .then(Rest.middleThen)
            .catch(Rest.simpleCatch)
            .finally(() => {
                this.setLoadedState(true);
                NotificationPrefabricated.cleanAllNotify();
            });

        this.setAffiliatesPagination(meta);
        showNothingWasFound(data.length);
        return data;
    }

    @Action
    async createAffiliate (payload: IAffiliateCreate): Promise<IAffiliates> {
        this.setLoadedState(false);
        return await Rest.POST(urlAffiliates(), payload)
            .then(response => {
                return handlerRestResponse(response, NotificationPrefabricated.createdAffiliateSuccess);
            })
            .catch(Rest.simpleCatch)
            .finally(() => this.setLoadedState(true));
    }

    @Action
    async updateAffiliateDetails ({ id, payload }): Promise<IAffiliates> {
        this.setLoadedState(false);
        const { data } = await Rest.PUT(urlAffiliatesDetailsUpdate(id), payload)
            .then(response => {
                return handlerRestResponse(response, NotificationPrefabricated.updatedAffiliateSuccess);
            })
            .catch(Rest.simpleCatch)
            .finally(() => this.setLoadedState(true));
        return data;
    }

    @Action
    async massEditAffiliateDetails (payload: IAffiliateMassEdit): Promise<IAffiliates> {
        this.setLoadedState(false);
        const { data } = await Rest.PUT(urlAffiliatesMassiveUpdate, payload)
            .then(response => {
                return handlerRestResponse(response, NotificationPrefabricated.massUpdatedAffiliateSuccess);
            })
            .catch(Rest.simpleCatch)
            .finally(() => this.setLoadedState(true));
        return data;
    }

    @Action
    async searchAffiliates (query: string): Promise<IAffiliatesSearchResponse> {
        const { data } = await Rest.GET(urlAffiliatesSearch({
            search: query,
        }))
            .then(Rest.middleThen)
            .catch(Rest.simpleCatch);
        return data;
    }

    @Action
    async loadAffiliateDetails (id: number): Promise<IAffiliates> {
        const { data } = await Rest.GET(urlAffiliatesDetails(id))
            .then(Rest.middleThen)
            .catch(Rest.simpleCatch);
        return data;
    }
}

export const AffiliatesPageModule = getModule(AffiliatesPage);
