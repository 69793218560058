import Vue from 'vue';
import modalLayout from '~/components/modals/root-modal-layout/root-modal-layout.vue';
import modalsMixin from '~/components/mixins/modals';
import { affiliatesCampaignsModule } from '~/store/modules/affiliates/affiliates-campaigns';
import { IEditBulkBody, IEditSingleBody } from '~/components/modals/affiliates-campaigns/interfaces';
import configsMixin from '~/components/mixins/configs-mixin';
import { RootTableModule } from '~/store/modules/root-table/root-table';

export default Vue.extend({

    components: {
        modalLayout,
    },
    mixins: [
        modalsMixin,
        configsMixin,
    ],
    data () {
        return {
            campaignName  : '',
            campaignType  : '',
            campaignLanguage: '',
            campaignCountry: '',
            affiliate: '',
            user: '',
            campaignComment: '',
            campaignStatus: '',
            source: '',
        };
    },
    computed:{
        getUsersList () {
            return affiliatesCampaignsModule.getUsers;
        },
    },
    created () {
        if (this.modalData.id) {
            const specificRow = affiliatesCampaignsModule.getCampaignsList.find(c => c.id === this.modalData.id);

            this.campaignName = specificRow.name;
            this.campaignType = this.getConfigAffiliatesCampaignsType.find(item => item.value === specificRow.type);
            this.campaignLanguage = specificRow.language;
            this.affiliate = specificRow.affiliate;
            this.user = specificRow.user;
            this.campaignComment = specificRow.comment;
            this.campaignCountry = specificRow.country;
            this.campaignStatus = this.getConfigCampaignDisabledOptions.find(item => item.value === specificRow.is_disabled);
            this.source = specificRow.source;
        }

    },
    methods: {
        prepareForm (isSingle: boolean): IEditSingleBody | IEditBulkBody {
            const {
                campaignName,
                campaignType,
                campaignLanguage,
                campaignCountry,
                affiliate,
                user,
                campaignComment,
                campaignStatus,
                source,
            } = this;
            const campaign_ids = RootTableModule.getSelectedRows;

            const body = {
                language_a2: campaignLanguage?.A2,
                country_id: campaignCountry?.id,
                affiliate_id: affiliate?.id,
                user_id: user?.id,
                type: campaignType?.value,
                is_disabled: campaignStatus?.value,
                source: source || 'N/A',
            };

            if (isSingle || campaign_ids.length === 1) {
                return {
                    ...body,
                    name: campaignName,
                    comment: campaignComment,
                };
            } else {
                return {
                    ...body,
                    campaign_ids,
                };
            }

        },
        submitEdit () {
            const isSingle = this.modalData.id;
            const formData = this.prepareForm(isSingle);
            const campaign_ids = RootTableModule.getSelectedRows;

            let promise;
            const id = this.modalData.id;
            if (id || campaign_ids.length === 1) {
                const campaignId = id ? id : campaign_ids[0];
                promise = affiliatesCampaignsModule.editCampaign({ id: campaignId, body: formData });
            } else {
                promise = affiliatesCampaignsModule.editCampaignBulk(formData);
            }

            promise.then(res => {
                if (res) {
                    affiliatesCampaignsModule.fetchCampaignsList(this.$route.query);
                    this.closeModal();
                }
            });
        }
    }

});
