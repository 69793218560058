<template>
    <material-icon
        class="cursor-pointer"
        symbol="edit"
        color="black"
        size="sm"
        @click.native="$emit('onEdit')"
    />
</template>
<script>
export default {};
</script>
