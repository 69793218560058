import {
    IPaymentRequisiteResponse,
} from '~/store/modules/payment-requisites/payment-requisites-interfaces';
import { PaymentRequisitesModule } from '~/store/modules/payment-requisites/payment-requisites';
import { ICurrency } from '~/settings/interfaces/currencies-interfaces';
import { EPaymentMethodName } from '~/settings/enums/payment-methods.enum';
import { handleFormattedPayload } from '~/helpers/handle-payment-requisites-formatted-payload';

export default {
    props: {
        paymentRequisites: {
            type: [Object],
        },
    },
    data (): IPaymentRequisiteResponse {
        return {
            id: null,
            client_id: null,
            currency_id: null,
            method_id: null,
            provider_id: null,
            status_id: null,
            status_description: '',
            crypto_wallet: '',
            iban: '',
            bank_name: '',
            bank_address: '',
            swift: '',
            owner_name: '',
            card_mask: '',
            call_confirmed: true,
        };
    },
    computed: {
        paymentMethod (): string {
            return EPaymentMethodName[this.method_id];
        },
    },
    methods: {
        handlePaymentRequisite (payload: IPaymentRequisiteResponse): void {
            for (const property in payload) {
                this[property] = payload[property];
            }
        },
        async onSubmitPaymentRequisiteForm (): Promise<IPaymentRequisiteResponse> {
            const payload = handleFormattedPayload(this.$refs.paymentRequisiteForm.$data);
            const result = await PaymentRequisitesModule.editPaymentRequisite(payload);

            if (result) {
                await PaymentRequisitesModule.loadPaymentRequisites();
            }
            return result;
        },
        handleSelectCurrency (currency: ICurrency): void {
            this.currency_id = currency.id;
        }
    },
    mounted (): void {
        this.handlePaymentRequisite(this.paymentRequisites);
    },
};
