import {
    COLUMN_EMAIL,
    COLUMN_ID,
    COLUMN_NAME,
    COLUMN_PHONE,
    COLUMN_REGISTERED_AT,
    COLUMN_RESIDENCE_COUNTRY_ID,
    COLUMN_TIMEZONE_ID,
    COLUMN_LEGAL_SUBJECT,
    COLUMN_VERIFY_STATUS_ID,
    COLUMN_VERIFY_STATUS_CRYPTO_ID,
    COLUMN_PROFESSIONAL_LEVEL_ID,
    COLUMN_LEAD_STATUS_ID,
    COLUMN_COMPLICATEDNESS_ID,
    COLUMN_LIVE_ACCOUNTS_COUNT,
    COLUMN_DEMO_ACCOUNTS_COUNT,
    COLUMN_FIRST_LIVE_DEPOSIT_DATE,
    COLUMN_FIRST_LIVE_DEPOSIT_AMOUNT,
    COLUMN_LAST_LIVE_DEPOSIT_DATE,
    COLUMN_LAST_LIVE_DEPOSIT_AMOUNT,
    COLUMN_TOTAL_LIVE_DEPOSITS,
    COLUMN_LAST_LIVE_WITHDRAWAL_DATE,
    COLUMN_LAST_LIVE_WITHDRAWAL_AMOUNT,
    COLUMN_PENDING_WITHDRAWALS,
    COLUMN_EQUITY,
    COLUMN_LANGUAGE_A2,
    COLUMN_CAMPAIGN,
    COLUMN_AFFILIATE,
    COLUMN_OPEN_ORDERS,
    COLUMN_FREE_MARGIN,
    COLUMN_CUSTOMER_SUPPORT_USER_FULL_NAME,
    COLUMN_SALES_USER_FULL_NAME,
    COLUMN_RETENTION_USER_FULL_NAME,
    COLUMN_DEPOSITED,
    COLUMN_COMPLETED_QUESTIONNAIRE,
    COLUMN_RISK_ID,
    COLUMN_SECOND_PHONE_FULL,
    COLUMN_MT_SUBJECT_TYPE,
    COLUMN_NET_WEALTH,
} from './_columnNames';
export const leadsFiltersColumnsId = {
    [COLUMN_ID] : 2,
    [COLUMN_RESIDENCE_COUNTRY_ID] : 3,
    [COLUMN_TIMEZONE_ID] : 4,
    [COLUMN_NAME] : 5,
    [COLUMN_REGISTERED_AT] : 6,
    [COLUMN_PHONE] : 7,
    [COLUMN_EMAIL] : 8,
    [COLUMN_LEGAL_SUBJECT] : 9,
    [COLUMN_VERIFY_STATUS_ID] : 10,
    [COLUMN_PROFESSIONAL_LEVEL_ID] : 11,
    [COLUMN_LEAD_STATUS_ID] : 12,
    [COLUMN_COMPLICATEDNESS_ID] : 13,
    [COLUMN_LIVE_ACCOUNTS_COUNT] : 14,
    [COLUMN_DEMO_ACCOUNTS_COUNT] : 15,
    [COLUMN_FIRST_LIVE_DEPOSIT_DATE] : 16,
    [COLUMN_FIRST_LIVE_DEPOSIT_AMOUNT] : 17,
    [COLUMN_LAST_LIVE_DEPOSIT_DATE] : 18,
    [COLUMN_LAST_LIVE_DEPOSIT_AMOUNT] : 19,
    [COLUMN_TOTAL_LIVE_DEPOSITS] : 20,
    [COLUMN_LAST_LIVE_WITHDRAWAL_DATE] : 21,
    [COLUMN_LAST_LIVE_WITHDRAWAL_AMOUNT] : 22,
    [COLUMN_PENDING_WITHDRAWALS] : 23,
    [COLUMN_EQUITY] : 24,
    [COLUMN_LANGUAGE_A2] : 25,
    [COLUMN_CAMPAIGN] : 26,
    [COLUMN_AFFILIATE] : 27,
    [COLUMN_OPEN_ORDERS] : 28,
    [COLUMN_FREE_MARGIN] : 29,
    [COLUMN_CUSTOMER_SUPPORT_USER_FULL_NAME] : 30,
    [COLUMN_SALES_USER_FULL_NAME] : 31,
    [COLUMN_RETENTION_USER_FULL_NAME] : 32,
    [COLUMN_DEPOSITED] : 33,
    [COLUMN_VERIFY_STATUS_CRYPTO_ID] : 34,
    [COLUMN_COMPLETED_QUESTIONNAIRE] : 35,
    [COLUMN_RISK_ID] : 36,
    [COLUMN_SECOND_PHONE_FULL] : 37,
    [COLUMN_MT_SUBJECT_TYPE] : 38,
    [COLUMN_NET_WEALTH]: 39,
};
