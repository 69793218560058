import { ITableHeaderColumn } from '~/components/tables/interfaces/table-header-column.interfaces';
import { tableHeaderParser } from '~/components/tables/helpers/table-header-parser';

export const accountingEmployeesTableHeader: ITableHeaderColumn[] = tableHeaderParser([
    {
        id: 2,
        name: 'fullName',
        value: 'Full Name',
        cellType: 'plain-text',
        cellData: 'fullName'
    },
    {
        id: 3,
        name: 'department',
        value: 'Department',
        cellType: 'plain-text',
        cellData: 'department'
    },
    {
        id: 4,
        name: 'role',
        value: 'Role',
        cellType: 'plain-text',
        cellData: 'role'
    },
    {
        id: 5,
        name: 'bonusPlan',
        value: 'Bonus Plan',
        cellType: 'plain-text',
        cellData: 'bonusPlan'
    },
    {
        id: 6,
        name: 'account-status',
        value: 'Status',
        cellType: 'account-status',
        cellData: 'status'
    },
    {
        id: 7,
        name: 'options',
        value: 'Options',
        cellType: 'options',
        cellData: 'options'
    }
]);
