import Vue from 'vue';
export default Vue.extend({
    props: {
        option: {
            type: Object,
        },
        className: {
            type: String,
        },
    },
});
