// Imports
import Vue from 'vue';
import Pagination from '~/components/parts/pagination/pagination.vue';

import { AccountingEmployeesModule } from '~/store/modules/accounting/accounting-employees';

// Exports
export default Vue.extend({
    components: {
        'pagination': Pagination
    },
    data () {
        return {
            options: [
                {
                    name: 'option',
                    value: 'option'
                },
                {
                    name: 'option',
                    value: 'option'
                },
                {
                    name: 'option',
                    value: 'option'
                }
            ],
            nameSelected: '',
        };
    },
    computed: {
        getAccountingEmployeesTableHeader (): Array<object> {
            return AccountingEmployeesModule.getterHeader;
        },
        getAccountingEmployeesTable (): Array<object> {
            return AccountingEmployeesModule.getterTable;
        }
    },
    created (): void {
        this.setTable();
    },
    methods: {
        setTable (): void {
            AccountingEmployeesModule.actionSetEmployeesTable();
        }
    }

});
