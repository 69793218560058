<template>
    <div
        class="kyc-tab-item"
        :class="{
            'kyc-tab-item__red': clientEditable,
            'kyc-tab-item__active': isHistoryOpen
        }"
    >
        <loader-small-relative v-if="isLoading" />
        <div
            class="kyc-tab-info"
            :class="{ 'kyc-tab-info--editable': editable }"
        >
            <div class="kyc-tab-info__label mb-2">
                {{ label }}
            </div>
            <div
                v-if="!isOpen"
                class="kyc-tab-info__value"
                @dblclick="$emit('edit')"
            >
                <template v-if="Array.isArray(display) && display.length > 0">
                    <div class="d-flex flex-column">
                        <b-row
                            v-for="(item, ind) in display"
                            :key="ind"
                        >
                            {{ item }}
                        </b-row>
                    </div>
                </template>
                <template v-else-if="!display || Array.isArray(display) && display.length === 0">
                    {{ NOTHING }}
                </template>
                <template v-else>
                    {{ display }}
                </template>
            </div>
            <slot name="input-select" />
        </div>
        <div
            v-if="isNotClientEditableButtons"
            class="kyc-tab-icons"
        >
            <template v-if="!isOpen && editable">
                <kyc-tab-item-edit-button
                    @edit="handle('edit')"
                />
    
                <div class="history-parent">
                    <kyc-tab-item-history-button
                        @toggleHistory="history"
                    />

                    <app-history-kyc
                        v-if="isHistoryOpen"
                        v-click-outside="closeHistory"
                    />
                </div>
            </template>

            <template v-if="isOpen && editable">
                <kyc-tab-item-apply-button @apply="handle('apply')" />
                <kyc-tab-item-cancel-button @cancel="handle('cancel')" />
            </template>
        </div>
        <div
            v-if="clientEditable"
            class="kyc-tab-icons"
        >
            <kyc-tab-item-apply-button @apply="handle('client-edit-apply')" />
            <kyc-tab-item-cancel-button @cancel="handle('client-edit-cancel')" />

            <div
                class="history-parent"
            >
                <kyc-tab-item-history-button
                    @toggleHistory="history"
                />

                <app-history-kyc
                    v-if="!isOpen && isHistoryOpen"
                    v-click-outside="closeHistory"
                />
            </div>
        </div>
    </div>
</template>

<script>
import loaderSmallRelative from '../../parts/loaderSmallRelative';
import toggleHistoryMixin from '~/components/mixins/toggle-history-mixin';
import appHistoryKyc from '~/components/parts/app-history-kyc/app-history-kyc';
import kycTabItemActionButtonsMixin from '@/js/components/KYC/KycTabItem/KycTabItemActionButtons/kyc-tab-item-action-buttons-mixin';

export default {
    components: {
        loaderSmallRelative,
        appHistoryKyc,
    },
    mixins: [
        toggleHistoryMixin,
        kycTabItemActionButtonsMixin,
    ],
    props: {
        label : {
            type : String,
            required: true,
        },
        className: {
            type : String,
            required: false,
        },
        phone: {
            type: Boolean,
            required: false,
        },
        display : {
            type : [String, Number, Object, Array],
            required: false,
        },
        isOpen : {
            type : Boolean,
            required: false,
            default: false,
        },
        clientEditable: {
            type: Boolean,
            required: false,
            default: false
        },
        editable: {
            type: Boolean,
            required: false,
            default: false
        },
        hasHistory: {
            type: Boolean,
            required: false,
            default: false
        },
        isLoading : {
            type : Boolean,
            required: false,
        },
        disabled: {
            type: Boolean,
            default () {
                return false;
            }
        },
        // use in another mixin
        fieldId: {
            type: Number,
        },
    },
    data () {
        return {
            NOTHING : 'N/A',
        };
    },
    computed: {
        isNotClientEditableButtons () {
            return !this.clientEditable && this.editable || this.hasHistory;
        }
    },
    methods: {
        handle: function (type) {
            if (!this.disabled) {
                this.$emit(type);
            }
        },
    }
};
</script>
