// Imports
import Vue from 'vue';
import EmailsNavbar from '~/components/emails/emails-navbar/emails-navbar.vue';
import emailSendIndividual from '~/components/emails/email-send-individual/email-send-individual.vue';

export default Vue.extend({
    components: {
        EmailsNavbar,
        emailSendIndividual
    }
});
