import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import { generateFiltersToSave } from './leads-filter-templates-helpers';
import router from '~/router';
import routes from '~/settings/routes';
import store from '~/store/index';
import Rest from '~/helpers/Rest';
import { urlLeadsFiltersTemplates, urlLeadsFiltersTemplatesUpdate } from '~/settings/ajax-urls';
import {
    actionHandleSelectedColumns,
    actionHandleFetchleads,
    actionHandleCleanSelectedColumns,
    getterColumnsInFilter,
} from '../leads';
import {
    ILeadsFiltersTemplate,
    ILeadsFilterItems,
    ILeadsFilterColumnsName,
} from '~/store/modules/leads-filter-templates/interfaces/leads-filter-templates-interfaces';
import { ITableHeaderColumn } from '~/components/tables/interfaces/table-header-column.interfaces';
import { NotificationPrefabricated } from '~/helpers/modal-notification-services/modal-notification-services';
import { handlerRoutesQuery } from '~/helpers/handler-routes-query';

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: 'LeadsFilterTemplates',
})
class LeadsFilterTemplates extends VuexModule {
    templates: ILeadsFiltersTemplate[] = [];
    isVisibleTemplatesList: boolean = false;
    toDeleteTemplate: ILeadsFiltersTemplate = null;
    templateToEdit: ILeadsFiltersTemplate = {
        id: null,
        name: '',
        user_id: null,
        created_at: '',
        updated_at: '',
        leads_filters: [],
    };
    activeTemplate: ILeadsFiltersTemplate = {
        id: null,
        name: '',
        user_id: null,
        created_at: '',
        updated_at: '',
        leads_filters: [],
    };
    filtersToModal: ITableHeaderColumn[] = [];

    get getTemplates (): ILeadsFiltersTemplate[] {
        return this.templates;
    }
    get getVisibleTemplateList (): boolean {
        return this.isVisibleTemplatesList;
    }
    get getToDeleteTemplate (): ILeadsFiltersTemplate {
        return this.toDeleteTemplate;
    }
    get getTemplateToEdit (): ILeadsFiltersTemplate {
        return this.templateToEdit;
    }
    get getTemplateToEditId (): number {
        return this.templateToEdit.id;
    }
    get getFiltersToModal (): ITableHeaderColumn[] {
        return this.filtersToModal;
    }
    @Mutation
    SET_TEMPLATES (templates: ILeadsFiltersTemplate[]) {
        this.templates = templates;
    }
    @Mutation
    SET_VISIBLE_TEMPLATE_LIST (value: boolean) {
        this.isVisibleTemplatesList = value;
    }
    @Mutation
    SET_TO_DELETE_TEMPLATE (template: ILeadsFiltersTemplate) {
        this.toDeleteTemplate = template;
    }
    @Mutation
    SET_TEMPLATE_TO_EDIT (template: ILeadsFiltersTemplate) {
        this.templateToEdit = template;
    }
    @Mutation
    SET_USE_TEMPLATE_LIST (template: ILeadsFiltersTemplate) {
        this.activeTemplate = template;
    }
    @Mutation
    SET_FILTERS_TO_MODAL (filters:ITableHeaderColumn[]) {
        this.filtersToModal = filters;
    }
    @Action
    async saveTemplate (templateName: string): Promise<void> {
        const isExistQuery: ILeadsFilterItems[] = generateFiltersToSave();
        await Rest.POST(urlLeadsFiltersTemplates, {
            name: templateName,
            leads_filters: isExistQuery
        })
            .then(Rest.middleJson)
            .then(response => {
                const errors = response.errors;
                if (errors) {
                    const error = Object.values(errors)[0] as string;
                    NotificationPrefabricated.Error(error);
                }
                if (response.data?.id) {
                    this.loadTemplates();
                    NotificationPrefabricated.LeadFilterTemplatesSavedSuccess();
                }
            })
            .catch(Rest.simpleCatch);
    }
    @Action
    async toEditTemplate (templateName: string): Promise<void> {
        const { user_id, id } = this.getTemplateToEdit;
        const isExistQuery: ILeadsFilterItems[] = generateFiltersToSave();

        await Rest.PUT(urlLeadsFiltersTemplatesUpdate(user_id, id), {
            name: templateName,
            leads_filters: isExistQuery
        })
            .then(Rest.middleJson)
            .then(response => {
                const errors = response.errors;
                if (errors) {
                    const error = Object.values(errors)[0] as string;
                    NotificationPrefabricated.Error(error);
                }
                if (response.data?.id) {
                    this.loadTemplates();
                    NotificationPrefabricated.LeadFilterTemplatesEditSuccess();
                }
            })
            .catch(Rest.simpleCatch);
        await this.cancelEditTemplate();
    }
    @Action
    async deleteTemplate (): Promise<void> {
        const { user_id, id } = this.getToDeleteTemplate;
        await Rest.DELETE(urlLeadsFiltersTemplatesUpdate(user_id, id))
            .then(() => {
                this.loadTemplates();
            })
            .catch(Rest.simpleCatch);
        await this.cancelDeleteTemplate();
        await this.cancelEditTemplate();
    }
    @Action({ commit: 'SET_TEMPLATES' })
    async loadTemplates (): Promise<ILeadsFiltersTemplate[]> {
        const { data } = await Rest.GET(urlLeadsFiltersTemplates)
            .then(Rest.middleThen)
            .catch(Rest.simpleCatch);
        return data;
    }
    @Action ({ commit: 'SET_TO_DELETE_TEMPLATE' })
    isDeleteTemplate (templateId: ILeadsFiltersTemplate) {
        return templateId;
    }
    @Action ({ commit: 'SET_TEMPLATE_TO_EDIT' })
    setTemplateToEdit (template: ILeadsFiltersTemplate) {
        return template;
    }
    @Action({ commit: 'SET_TO_DELETE_TEMPLATE' })
    cancelDeleteTemplate () {
        return null;
    }
    @Action({ commit: 'SET_TEMPLATE_TO_EDIT' })
    cancelEditTemplate () {
        return null;
    }
    @Action({ commit: 'SET_VISIBLE_TEMPLATE_LIST' })
    changeVisibleTemplateList (payload: boolean) {
        return payload;
    }
    @Action({ commit: 'SET_USE_TEMPLATE_LIST' })
    async useTemplate (template: ILeadsFiltersTemplate) {
        const filters = template.leads_filters;
        const columnsToArr: ILeadsFilterColumnsName[] = [];
        const columnsId: ITableHeaderColumn[] = this.context.rootGetters[getterColumnsInFilter];

        await this.context.dispatch(actionHandleCleanSelectedColumns, {}, { root: true });

        filters.forEach((filterItem) => {
            const currentId = columnsId.find(item => item.id === filterItem.id);
            if (currentId) {
                router.push(routes.leads.path);
                handlerRoutesQuery(currentId.name.toString().toLowerCase(), filterItem.wheres);
                columnsToArr.push({
                    name: `${currentId.name.toString().toLowerCase()}`
                });
            }
        });

        await this.context.dispatch(actionHandleSelectedColumns, columnsToArr, { root: true });
        const isQuery = {
            ...router.currentRoute.query
        };
        await this.context.dispatch(actionHandleFetchleads, isQuery, { root: true });
        return template;
    }
    @Action({ commit: 'SET_FILTERS_TO_MODAL' })
    setFiltersToModal (filters: ITableHeaderColumn[]): ITableHeaderColumn[] {
        return filters;
    }
    @Action({ commit: 'SET_FILTERS_TO_MODAL' })
    cleanFiltersToModal () {
        this.context.dispatch(actionHandleCleanSelectedColumns, {}, { root: true });
        return [];
    }

}
export const LeadsFilterTemplatesModule = getModule(LeadsFilterTemplates);
