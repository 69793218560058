import {
    IBonusPlan,
    IBonusPlanUpdate,
} from '~/store/modules/bonus-plan/interfaces/bonus-plan-interfaces';
import { bonusPlanFormattedCountry } from './bonus-plan-formatted-country';

export const bonusPlanFormattedCpl = (payload: IBonusPlan): IBonusPlanUpdate => {
    const {
        id,
        name,
        other_country_value,
    } = payload;
    return {
        id: id || null,
        name,
        other_country_value,
        countries: bonusPlanFormattedCountry(payload.countries),
        bonus_type_id: payload.bonus_type.id,
        currency_id: payload.currency.id,
    };
};
