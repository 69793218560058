import queryString from 'query-string';
import { ERequestUrlPrefix } from '~/settings/enums/request-url-prefix.enum';

export const createRequestUrl = (
    url: string,
    query: Object = null
): string => {
    const baseUrl = `${ERequestUrlPrefix.default}/${url}`;
    const queryParams = query ?
        queryString.stringify(query, { arrayFormat: 'bracket' }) : '';
    return queryParams ? `${baseUrl}?${queryParams}` : baseUrl;
};
