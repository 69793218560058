import { ITableFilterQuery } from '../interfaces/table-filter-query.interfaces';
import { exceptionsWithAllTables } from './exceptions-keys-filters';

export const isVisibleActionButtons = (tableType: string, query: ITableFilterQuery): boolean => {
    const exceptionsForThisTableType = exceptionsWithAllTables(tableType);

    if (exceptionsForThisTableType) {
        query = Object.keys(query).reduce((current, item) => {
            if (!exceptionsForThisTableType.includes(item)) {
                current[item] = query[item];
            }

            return current;
        }, {});
    }

    return Object.values(query).filter(item => !!item).length > 0;
};
