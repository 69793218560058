import Vue from 'vue';
import rowItemPropsMixin from '@/js/components/tables/mixins/table-cells-mixins/row-item-props-mixin';
import { ECommentMaxLength } from '~/components/tables/table-cells/description-cell/description-cell.enum';

export default Vue.extend({
    mixins: [
        rowItemPropsMixin,
    ],
    computed: {
        isNotLongText (): boolean {
            return this.cell.value.comment.length < ECommentMaxLength.Length;
        },
    },
    methods: {
        handleRedirect (leadId: number, notificationId: number, redirect: string): void {
            this.$router.push(`/leads/${leadId}/${redirect}`);
        }
    }
});
