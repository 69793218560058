import { TypesBonusPlanFormsEnums } from './types-bonus-plan-forms.enum';

export default {
    props: {
        typeForm: {
            type: String,
        }
    },
    computed: {
        typeValidation (): string {
            switch (this.typeForm) {
            case TypesBonusPlanFormsEnums.create:
                return `required|isNameUsed:${this.bonusPlanListNames}`;
        
            default:
                return 'required';
            }
        }
    }
};
