import Vue from 'vue';
import TheWrapper from '../theWrapper.vue';
import rootTable from '~/components/tables/root-table/root-table.vue';
import { UsersModule } from '~/store/modules/users/users';
import { ITableHeaderColumn } from '~/components/tables/interfaces/table-header-column.interfaces';
import { ITableCell } from '~/components/tables/interfaces/table-cell-interfaces';
import { ETableType } from '~/components/tables/enums/table-type.enum';
import { BonusPlanModule } from '~/store/modules/bonus-plan/bonus-plan';
import { ModalsModule } from '~/store/modules/modals';
import { EUserTitlesModals } from '~/settings/enums/user-titles-modals.enum';
import { RootTableModule } from '~/store/modules/root-table/root-table';
import appMoreOptions from '~/components/parts/app-more-options/app-more-options.vue';
import { isPermissionEditUserInformation, isPermissionAddUsers } from '~/helpers/user-permission';
import { setQuaryPerPage } from '~/components/tables/helpers/setQuaryPerPage';
import { handlerLoadTable } from '~/components/tables/helpers/handler-load-table';

export default Vue.extend({
    components: {
        TheWrapper,
        rootTable,
        appMoreOptions,
    },
    computed: {
        isPermissionEditUserInformation,
        isPermissionAddUsers,
        tableType (): string {
            return ETableType.usersTable;
        },
        getUsersIsLoaded (): boolean {
            return UsersModule.getUsersIsLoaded;
        },
        usersTableHeader (): ITableHeaderColumn[] {
            return UsersModule.getUsersHeader;
        },
        usersTableRows (): ITableCell[] {
            return UsersModule.getUsersRows;
        },
        usersCheckedIndexes (): number[] {
            return RootTableModule.getSelectedRows;
        },
        istUsersCheckedIndexes (): boolean {
            return this.usersCheckedIndexes.length > 0;
        }
    },
    created (): void {
        setQuaryPerPage(this.tableType);
        handlerLoadTable(this.tableType);
        this.loadBonusPlan();
    },
    methods: {
        loadBonusPlan (): void {
            BonusPlanModule.loadBonusPlan();
        },
        addUser (): void {
            ModalsModule.openModal({
                modalType: 'addEditUserModal',
                props: {
                    title: EUserTitlesModals.add,
                    body: {
                        button: {
                            text: 'Add'
                        }
                    },
                    isHeader: true,
                    isFooter: true
                },
                modalSettings: {
                    props: {
                        minWidth: ModalsModule.modalWidth.lg,
                    },
                },
            });
        },
        massEditUser (): void {
            if (this.istUsersCheckedIndexes) {
                ModalsModule.openModal({
                    modalType: 'massEditUserModal',
                    props: {
                        title: 'Edit Multiple Users',
                        body: {
                            usersIds: this.usersCheckedIndexes,
                            button: {
                                text: 'Update'
                            }
                        },
                        isHeader: true,
                        isFooter: true
                    },
                    modalSettings: {
                        props: {
                            minWidth: ModalsModule.modalWidth.lg,
                        },
                    },
                });
            }
        }
    }
});
