import { Module, VuexModule, getModule, Action, Mutation } from 'vuex-module-decorators';
import store from '~/store/index';
import Rest from '~/helpers/Rest';
import { IMarketingCreateList, IMarketingList } from '~/store/modules/marketing/marketing-interfaces';
import { urlMarketingList } from '~/settings/ajax-urls';

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: 'MarketingLists',
})

class MarketingLists extends VuexModule {
    marketingLists = null;

    get getMarketingLists (): IMarketingList {
        return this.marketingLists;
    }

    @Mutation
    setMarketingLists (payload: IMarketingList): void {
        this.marketingLists = payload;
    }

    @Action({ commit: 'setMarketingLists' })
    async createMarketingList (payload: IMarketingCreateList):  Promise<IMarketingList> {
        const { data } = await Rest.POST(urlMarketingList, payload)
            .then(Rest.middleThen)
            .catch(Rest.simpleCatch);

        return data;
    }
}

export const MarketingListsModule = getModule(MarketingLists);