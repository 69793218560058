import { Module, VuexModule, getModule, Action, Mutation } from 'vuex-module-decorators';
import router from '~/router';
import store from '~/store/index';
import Rest from '~/helpers/Rest';
import { urlClientActivityLog } from '~/settings/ajax-urls';
import { leadsActivityLogTableHeader } from '~/components/tables/_headers/leads-activity-log';
import { tableParser } from '~/helpers/table-parser';
import { ITableCell } from '~/components/tables/interfaces/table-cell-interfaces';
import { ITableHeaderColumn } from '~/components/tables/interfaces/table-header-column.interfaces';
import { ILeadsActivityLog } from '~/store/modules/leads-activity-log/leads-activity-log.interfaces';
import { IPagination } from '~/settings/interfaces/pagination-interfaces';
import { ITableFilterQuery } from '~/components/tables/interfaces/table-filter-query.interfaces';

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: 'leadsActivityLog',
})
class LeadsActivityLog extends VuexModule {

    activityLogs: ILeadsActivityLog[] = [];
    loaded: boolean = false;
    activityLogsPagination: IPagination = {
        current_page: null,
        from: null,
        last_page: null,
        per_page: null,
        to: null,
        total: null,
    };

    get getLeadsActivityLogHeader (): ITableHeaderColumn[] {
        return leadsActivityLogTableHeader;
    }
    get getActivityLogRows (): ITableCell[] {
        return tableParser(leadsActivityLogTableHeader, this.activityLogs);
    }

    get getActivityLogIsLoaded (): boolean {
        return this.loaded;
    }

    get getActivityLogPagination (): IPagination {
        return this.activityLogsPagination;
    }

    @Mutation
    setLeadsActivityLogs (payload: ILeadsActivityLog[]) {
        this.activityLogs = payload;
    }

    @Mutation
    setLoadedState (payload: boolean) {
        this.loaded = payload;
    }

    @Mutation
    setLeadsActivityLogsPagination (payload: IPagination) {
        this.activityLogsPagination = payload;
    }

    @Action
    async loadLeadsActivityLogs (payload: ITableFilterQuery = null): Promise<void> {
        const leadId = +router.currentRoute.params.id;
        this.setLoadedState(false);
        const { data, meta } = await Rest.GET(urlClientActivityLog(leadId, payload))
            .then(Rest.middleThen)
            .catch(error => {
                Rest.simpleCatch(error);
            })
            .finally(() => this.setLoadedState(true));
        this.setLeadsActivityLogs(data);
        this.setLeadsActivityLogsPagination(meta);
    }

    @Action resetLeadsActivityLog () {
        this.setLeadsActivityLogs([]);
    }
}
export const LeadsActivityLogsModule = getModule(LeadsActivityLog);
