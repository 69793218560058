export const WithdrawalDepositTabHelpers = [
    {
        component: 'withdrawal',
        name: 'Add Withdrawal',
        img: '/img/clients/finance/add_withdrawal.svg'
    },
    {
        component: 'deposit',
        name: 'Add Deposit',
        img: '/img/clients/finance/add_deposit.svg'
    }
];
