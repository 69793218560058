import { IPaymentMethods } from '@/js/store/modules/configs/interfaces/configs-interface';
import Vue from 'vue';
import configsMixin from '../../mixins/configs-mixin';

export default Vue.extend({
    mixins: [
        configsMixin,
    ],
    computed: {
        getPaymentMethod (): IPaymentMethods | null {
            return this.getConfigPaymentMethods.find(
                (payment: IPaymentMethods) => payment.id === this.method_id
            );
        },
    },
    created () {
        this.setValuesFromPayload();
    },
    methods: {
        setValuesFromPayload (): void {
            if (this.payload) {
                for (const key in this.payload) {
                    this[key] = this.payload[key];
                }

                this.isMethodsSelected = this.getPaymentMethod;
            }
        },
    }
});
