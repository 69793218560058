import { DocumentsTypesInterface } from './interfaces';
import { DocumentTypesEnum, DocumentTypesIdsEnum } from './enums/document-types.enum';
import { UploadedDocumentsTypeIdsEnum } from '~/components/uploaded-documents/uploaded-documents.enum';

const documentTypeAutoEmails =  {
    id: UploadedDocumentsTypeIdsEnum.AutoEmails,
    name: 'Auto Emails'
};
export const DocumentTypesSort = (documents: Array<DocumentsTypesInterface>): Array<DocumentsTypesInterface>  => {
    const docs = documents.reduce(
        (acc, item: DocumentsTypesInterface) => {
            if (
                item.id === DocumentTypesIdsEnum.AuthorisationDepositTransfer ||
                item.id === DocumentTypesIdsEnum.AuthorisationDepositEWallets ||
                item.id <= UploadedDocumentsTypeIdsEnum.PaymentVerificationDocuments
            ) {
                return acc;
            }

            if (item.id === DocumentTypesIdsEnum.AuthorisationDepositId) {
                item = {
                    ...item,
                    name: DocumentTypesEnum.AuthorisationDeposit
                };
            }

            acc = [
                ...acc,
                item
            ];

            return acc;
        },
        []
    );
    return [...docs, documentTypeAutoEmails];
};
