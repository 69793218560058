export enum ExpiresDaysEnums {
    expiresDaysDefault = 3
}

export enum AuthClassesEnums {
    parentClass = 'js-text-field',
    activeClass = 'active',
    errorClass = 'has-danger',
}

export enum AuthTypesEnums {
    typeLogin = 'login',
    typePassword = 'password',
    typeErrorLoginText = 'errorLogin',
    typeErrorPasswordText = 'errorPassword',
    typeErrorLogin = 'isErrorLogin',
    typeErrorPassword = 'isErrorPassword'
}
