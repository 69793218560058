import { mapActions } from 'vuex';
import { actionHandleUpdate, actionLoadKyc } from '~/store/modules/client-kyc/client-kyc';
import { KycClientModule } from '~/store/modules/kyc-client/kyc-client';
import router from '~/router';
import { NotificationPrefabricated } from '~/helpers/modal-notification-services/modal-notification-services';
import { ELeadsKycFieldStatus } from '~/settings/enums/leads-kyc-field-status.enum';
import { ModalsModule } from '~/store/modules/modals';

export default {
    data () {
        return {
            eLeadsKycFieldStatus: ELeadsKycFieldStatus,
        };
    },
    computed: {
        hasHistory () {
            return this.initial?.field?.has_history;
        },
        title () {
            return this.initial?.field?.title;
        },
        status () {
            // this.initial takes from component props
            return this.initial?.status;
        },
        isPending () {
            return this.status?.id === ELeadsKycFieldStatus.pending;
        },
        fieldId () {
            // this.initial takes from component props
            return this.initial?.field?.id;
        },
        initialId () {
            return this.initial?.id;
        },
    },
    methods : {
        ...mapActions({
            handleUpdateClientKyc : actionHandleUpdate,
            actionLoadKyc,
        }),
        updateClientKyc (payload) {
            return this.handleUpdateClientKyc(payload);
        },
        async cancelClient (field, reason) {
            const status = this.isPending;

            await KycClientModule.handleUpdateField({
                field: field,
                status: ELeadsKycFieldStatus.rejected,
                reason: reason
            });

            await this.actionLoadKyc(router.currentRoute.params.id);

            if (status) {
                await NotificationPrefabricated.KycFieldCancelEdit();
            }
        },
        async applyClient (field, statusId) {
            const status = this.isPending;

            await KycClientModule.handleUpdateField({
                field: field,
                status: statusId || ELeadsKycFieldStatus.approved,
                reason: ''
            });

            await this.actionLoadKyc(router.currentRoute.params.id);

            if (status) {
                await NotificationPrefabricated.KycFieldSuccessEdit();
            }
        },
        clientEditApply (field, statusId) {
            ModalsModule.openModal({
                modalType: 'confirmUpdateLeads',
                props: {
                    title: 'Are you sure?',
                    body: 'Are you sure you want to approve the request?',
                    isHeader: true,
                    isFooter: true,
                    isReason: false,
                    ok: async () => {
                        this.applyClient(field, statusId);
                        this.hide();
                    },
                },
                modalSettings: {
                    props: {
                        minWidth: ModalsModule.modalWidth.xs,
                    },
                },
            });
        },
        clientEditCancel (field) {
            ModalsModule.openModal({
                modalType: 'cancelUpdateLeadField',
                props: {
                    title: 'Please provide a reason:',
                    body: 'Reason',
                    isHeader: true,
                    isFooter: true,
                    isReason: true,
                    ok: async (reason) => {
                        this.cancelClient(field, reason);
                        this.hide();
                    },
                },
                modalSettings: {
                    props: {
                        minWidth: ModalsModule.modalWidth.md,
                    },
                },
                addClass: 'middle-width'
            });
        },
    }
};
