import { render, staticRenderFns } from "./calls-time.html?vue&type=template&id=f6b2d290&lang=html&"
import script from "./calls-time.ts?vue&type=script&lang=ts&"
export * from "./calls-time.ts?vue&type=script&lang=ts&"
import style0 from "./calls-time.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports