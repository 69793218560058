<template>
    <div>
        <div
            class="kyc-tab-item"
            :class="[
                isHistoryOpen ? 'kyc-tab-item__active': '',
                isPending ? 'kyc-tab-item__red': '',
                isReviewedStatus ? 'kyc-tab-item__yellow': '',
            ]"
        >
            <loader-small-relative v-if="isLoading" />
            <div class="kyc-tab-info">
                <div class="kyc-tab-info__label mb-2">
                    <span v-html="displayQuestion" />
                </div>
                <div
                    v-if="!isOpen"
                    class="kyc-tab-info__value"
                >
                    {{ displayAnswer }}
                </div>
                <div class="kyc-tab-info__row">
                    <div
                        class="kyc-tab-info__box"
                        :class="className || 'full-width'"
                    >
                        <app-multi-select
                            v-if="isOpen"
                            v-model="selected"
                            :options="optionsById"
                            label="name"
                            track-by="id"
                            searchable
                            :close-on-select="true"
                            @remove="remove"
                        />
                        <app-input
                            v-if="isOpen && selectedDescribable"
                            ref="input"
                            v-model="value"
                            type="text"
                            class="kyc-tab-info__input"
                        />
                    </div>
                </div>
            </div>
            <div
                v-if="!disabled && !isPending"
                class="kyc-tab-icons"
            >
                <kyc-tab-item-edit-button
                    v-if="!isOpen && !isReviewedStatus"
                    @edit="toggleOpen"
                />

                <div
                    v-if="!isOpen"
                    class="history-parent"
                >
                    <kyc-tab-item-history-button
                        @toggleHistory="history"
                    />

                    <app-history-kyc
                        v-if="isHistoryOpen"
                        v-click-outside="closeHistory"
                    />
                </div>

                <template v-if="isOpen">
                    <kyc-tab-item-apply-button @apply="apply" />
                    <kyc-tab-item-cancel-button @cancel="cancel" />
                </template>
            </div>
            <div
                v-if="isPending"
                class="kyc-tab-icons"
            >
                <kyc-tab-item-apply-button @apply="clientEditApply(initialId)" />
                <kyc-tab-item-cancel-button @cancel="clientEditCancel(initialId)" />

                <div class="history-parent">
                    <kyc-tab-item-history-button
                        v-if="!isOpen"
                        @toggleHistory="history"
                    />
                    <app-history-kyc
                        v-if="!isOpen && isHistoryOpen"
                        v-click-outside="closeHistory"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import loaderSmallRelative from '../../parts/loaderSmallRelative';
import toggleMixin from '../../mixins/toggleMixin';
import fieldModelMixin from '../../mixins/modelMixins/fieldModelMixin';
import clientKycActionsMixin from '../../mixins/clientKycActionsMixin';
import loadingMixin from '../../mixins/loadingMixin';
import questionsAnswersMixin from '~/components/mixins/questionsAnswersMixin';
import clientKycMixin from '~/components/mixins/client-kyc-mixin/client-kyc-mixin';
import appMultiSelect from '~/components/parts/app-multiselect/app-multiselect.vue';
import appInput from '~/components/parts/app-input/app-input.vue';
import toggleHistoryMixin from '~/components/mixins/toggle-history-mixin';
import appHistoryKyc from '~/components/parts/app-history-kyc/app-history-kyc';
import kycTabItemActionButtonsMixin from '@/js/components/KYC/KycTabItem/KycTabItemActionButtons/kyc-tab-item-action-buttons-mixin';
import { getStringFromFieldValueData, getDataFromFieldValue } from '~/components/KYC/kyc-helpers';
import { ELeadsKycFieldStatus } from '~/settings/enums/leads-kyc-field-status.enum';

export default {
    components: {
        loaderSmallRelative,
        appMultiSelect,
        appInput,
        appHistoryKyc
    },
    mixins : [
        toggleMixin,
        fieldModelMixin,
        clientKycActionsMixin,
        loadingMixin,
        questionsAnswersMixin,
        clientKycMixin,
        toggleHistoryMixin,
        kycTabItemActionButtonsMixin,
    ],
    props: {
        initial : {
            type: [String, Number, Object, Array],
            required : true,
        },
        className: {
            type : String,
            required: false,
        },
    },
    data () {
        return {
            NOTHING : 'N/A',
        };
    },
    computed : {
        displayQuestion () {
            return this.initial.field.title;
        },
        displayAnswer () {
            return getStringFromFieldValueData(this.initial, {
                visibleCustom: true,
                customBrackets: true,
            }) || getStringFromFieldValueData(this.initial)
                || this.NOTHING;
        },
        optionsById () {
            return this.answersById(
                this.initial.field.id,
                this.initial.field.name
            );
        },
        initialId () {
            return this.initial.id;
        },
        selectedDescribable () {
            return this.selected?.describable;
        },
        isReviewedStatus () {
            return this.status?.id === ELeadsKycFieldStatus.reviewed;
        },
    },
    methods : {
        // use fieldModelMixin
        getInitialValue () {
            return this.initial?.data?.[0].custom || '';
        },
        getInitialSelected () {
            return getDataFromFieldValue(this.initial)
                && this.optionsById.find(
                    option => option.name === this.initial.data[0].display
                ) || {};

        },
        apply () {
            this.startLoading();
            const answer = {
                question_id : this.initial.id || this.selected.question_id,
                answer_id : this.selected?.id,
                custom : this.selected?.describable ? this.value : null
            };
            this.updateClientKyc({ column : this.COLUMN_ANSWERS, value : [answer] })
                .then(() => {
                    this.endLoading();
                    this.toggleOpen();
                });
        },
        cancel () {
            this.restoreValue();
            this.restoreSelected();
            this.toggleOpen();
        },
        remove () {
            this.clearSelected();
        },
    },
};
</script>
