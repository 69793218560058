export const campaignDisabledOptions = [
    {
        name: 'Enabled',
        value: 0,
    },
    {
        name: 'Disabled',
        value: 1,
    },
];
