<template>
    <kyc-tab-item
        v-bind="$attrs"
        :display="dateInitial"
        :is-open="isOpen"
        :is-loading="isLoading"
        :disabled="disabled"
        :editable="isEditable"
        :client-editable="isPending"
        :field-id="fieldId"
        v-on="$listeners"
        @edit="toggleOpen"
        @apply="apply"
        @cancel="cancel"
    >
        <template
            v-if="isOpen"
            #input-select
        >
            <div class="kyc-tab-info__row">
                <div class="kyc-tab-info__box">
                    <date-picker
                        v-model="date"
                    />
                </div>
            </div>
        </template>
    </kyc-tab-item>
</template>

<script>
import KycTabItem from './KycTabItem';
import toggleMixin from '../../mixins/toggleMixin';
import fieldModelMixin from '../../mixins/modelMixins/fieldModelMixin';
import clientKycActionsMixin from '../../mixins/clientKycActionsMixin';
import loadingMixin from '../../mixins/loadingMixin';
import DatePicker from 'vue2-datepicker';
import { getStringFromFieldValueData } from '~/components/KYC/kyc-helpers';

export default {
    components: {
        KycTabItem,
        DatePicker
    },
    mixins : [
        toggleMixin,
        fieldModelMixin,
        clientKycActionsMixin,
        loadingMixin,
    ],
    props: {
        initial: {
            type: [String, Object, Date],
            required: false,
        },
        column : {
            type: String,
            required: true
        }
    },
    data () {
        return {
            currentDate: null,
        };
    },
    computed : {
        date: {
            get () {
                return this.currentDate
                    || new Date(getStringFromFieldValueData(this.initial));
            },
            set (newDate) {
                this.currentDate = newDate;
            }
        },
        dateInitial () {
            return getStringFromFieldValueData(this.initial) || null;
        },
        isEditable () {
            return this.initial ? this.initial.field.is_editable || null : true;
        },
        fieldId () {
            return this.initial?.field?.id;
        }
    },
    methods: {
        apply () {
            this.startLoading();
            this.updateClientKyc({ column : this.column, value : this.date })
                .then(() => {
                    this.restoreValue();
                    this.endLoading();
                    this.toggleOpen();
                });
        },
        cancel () {
            this.restoreValue();
            this.toggleOpen();
        },
    },
};
</script>
