import Vue from 'vue';
import { IDefaultConfig } from '~/store/modules/configs/interfaces/configs-interface';

export default Vue.extend({
    props: {
        groups: {
            type: Array,
            default: () => { return []; },
        },
        selectedGroup: {
            type: Object,
        },
        size: {
            type: String,
        },
        icons: {
            type: Object,
        },
        iconKey: {
            type: String,
        },
        trackBy: {
            type: String,
            default: 'id',
        },
    },
    methods: {
        selectGroup (group: IDefaultConfig) {
            this.$emit('selectGroup', group);
        },
        isActiveGroup (id: number | string): boolean {
            return this.selectedGroup?.[this.trackBy] === id;
        }
    }
});
