import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '~/store/index';
import { leadsPaymentHistoryTableHeader } from '~/components/tables/_headers/leads-payment-history';
import {
    FilterInterface,
    ICreateDepositPaymentResponse,
    ICreateDepositPaymentResponseError,
    PaymentsPendingCancelInterface,
    IPaymentHistoryQueryPayload,
    ITotalWithdrawals,
    ITotalCurrencyInterface,
    IClientPayment,
} from './client-kyc-finances-interfaces';
import { tableParser } from '~/helpers/table-parser';
import Rest from '~/helpers/Rest';
import {
    urlPaymentsHistory,
    urlPaymentsPendingCancel,
    urlPaymentsPendingApprove,
    urlPaymentsStore,
} from '~/settings/ajax-urls';
import router from '~/router';
import { IPaymentRequisiteResponse } from '~/store/modules/payment-requisites/payment-requisites-interfaces';
import { formatErrorMessage } from '~/helpers/format';
import { PaymentHistoryPaginationInterface } from '~/components/mixins/finance/finance-mixin.interfaces';
import { PaginationEnum } from '~/settings/enums/pagination.enum';
import { NotificationPrefabricated } from '~/helpers/modal-notification-services/modal-notification-services';
import { ITableFilterQuery } from '~/components/tables/interfaces/table-filter-query.interfaces';
import { showNothingWasFound } from '@/js/components/tables/helpers/showNothingWasFound';
import { handlerQueriesToFetch } from '~/helpers/handlers-queries-to-fetch/handler-queries-to-fetch';
import { ETableType } from '~/components/tables/enums/table-type.enum';
import { handlerRestResponse } from '~/helpers/handler-rest-response';

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: 'clientKYCFinances',
})
class clientKycFinances extends VuexModule {
    paymentHistoryTableRows: Array<object> = [];
    paymentHistoryFilter: FilterInterface = {
        account_ids: [],
        type_id: null,
        status_id: null,
        page: 1,
        per_page: PaginationEnum.defaultPerPage
    };
    pendingWithdrawalsFilter: FilterInterface = {
        account_ids: [],
        type_id: null,
        status_id: null
    };
    reasonForCancel: string|null = null;
    pagination: PaymentHistoryPaginationInterface = {
        current_page: null,
        last_page: null,
        per_page: PaginationEnum.defaultPerPage,
        total: null,
    };

    totalWithdrawals: ITotalWithdrawals = {
        deposits: 0,
        net_deposits: 0,
        withdrawals: 0
    };

    totalCurrency: ITotalCurrencyInterface = {
        id: 1,
        name: 'EUR',
        sign: '€',
    };
    loadedPaymentHistory: boolean = false;
    financeTabsActive: string = '';
    statusesCounter: Object = {};

    get getStatusesCounter (): Object {
        return this.statusesCounter;
    }

    get getFinanceTabsActive (): string {
        return this.financeTabsActive;
    }

    get getPaymentHeader (): Array<object> {
        return leadsPaymentHistoryTableHeader;
    }

    get getPaymentRows (): Array<object> {
        return tableParser(leadsPaymentHistoryTableHeader, this.paymentHistoryTableRows);
    }

    get getPendingWithdrawalReason (): string {
        return this.reasonForCancel;
    }

    get getTotalWithdrawals (): ITotalWithdrawals {
        return this.totalWithdrawals;
    }

    get getTotalCurrency (): ITotalCurrencyInterface {
        return this.totalCurrency;
    }

    get getHistoryPagination ():PaymentHistoryPaginationInterface {
        return this.pagination;
    }

    get getPaymentsHistoryIsLoaded (): boolean {
        return this.loadedPaymentHistory;
    }


    @Mutation
    mutationPendingWithdrawalsQuerySimple (payload: IPaymentHistoryQueryPayload) {
        this.pendingWithdrawalsFilter[payload.key] = payload.value;
    }

    @Mutation
    setFinanceTabs (payload: string) {
        this.financeTabsActive = payload;
    }

    @Mutation
    REASON_TEXT (payload: string) {
        this.reasonForCancel = payload;
    }

    @Mutation
    mutationPaymentQuerySimple (payload: IPaymentHistoryQueryPayload) {
        this.paymentHistoryFilter[payload.key] = payload.value;
    }

    @Mutation
    setLoadedStatePaymentHistory (payload: boolean) {
        this.loadedPaymentHistory = payload;
    }

    @Mutation
    setPaymentHistoryTableRows (payload) {
        this.paymentHistoryTableRows = payload;
    }

    @Mutation
    setPagination (payload) {
        this.pagination = payload;
    }

    @Mutation
    setTotalWithdrawals (payload) {
        this.totalWithdrawals = payload;
    }

    @Mutation
    setTotalCurrency (payload) {
        this.totalCurrency = payload;
    }

    @Mutation
    setStatusesCounter (payload) {
        this.statusesCounter = payload;
    }

    @Action ({ commit: 'setFinanceTabs' })
    handlerFinanceCurrentTab (payload: string) {
        return payload;
    }

    @Action ({ commit: 'REASON_TEXT' })
    setReasonText (reason: string): string {
        return reason;
    }

    @Action ({ commit: 'mutationPaymentQuerySimple' })
    handlerPaymentQuerySimple (payload: IPaymentHistoryQueryPayload) {
        return payload;
    }

    @Action
    async loadPaymentsHistory (query: ITableFilterQuery = null) {
        this.setLoadedStatePaymentHistory(false);
        const leadId = +router.currentRoute.params.id;
        query = handlerQueriesToFetch(query, ETableType.paymentHistory);

        const {
            data,
            meta,
            deposits,
            net_deposits,
            withdrawals,
            total_currency,
            statuses_counter
        } = await Rest.GET(urlPaymentsHistory(leadId, query))
            .then(Rest.middleThen)
            .catch(Rest.simpleCatch)
            .finally(() => {
                NotificationPrefabricated.cleanAllNotify();
            });

        showNothingWasFound(data.length);

        this.setPaymentHistoryTableRows(data);
        this.setPagination(meta);
        this.setTotalWithdrawals({
            deposits: deposits,
            net_deposits: net_deposits,
            withdrawals: withdrawals
        });
        this.setTotalCurrency(total_currency);
        this.setStatusesCounter(statuses_counter);
        this.setLoadedStatePaymentHistory(true);
    }

    @Action
    async PaymentsPendingCancel ({ paymentId, reason }: PaymentsPendingCancelInterface): Promise<any> {
        return await Rest.POST(
            urlPaymentsPendingCancel(+router.currentRoute.params.id),
            { payment_id: paymentId, reason: reason }
        )
            .then(response => handlerRestResponse(response, NotificationPrefabricated.paymentRejected))
            .catch(Rest.simpleCatch);
    }

    @Action
    async PaymentsPendingApprove (paymentId: number): Promise<IClientPayment> {
        return await Rest.POST(
            urlPaymentsPendingApprove(+router.currentRoute.params.id),
            { payment_id: paymentId }
        )
            .then(response => handlerRestResponse(response, NotificationPrefabricated.paymentApproved))
            .catch(Rest.simpleCatch);
    }

    @Action
    async createDepositPayment (payload: IPaymentRequisiteResponse): Promise<ICreateDepositPaymentResponse|ICreateDepositPaymentResponseError> {
        return await Rest.POST(
            urlPaymentsStore(+router.currentRoute.params.id),
            payload
        )
            .then(response => handlerRestResponse(response, NotificationPrefabricated.transactionCreated))
            .catch(Rest.simpleCatch);
    }
}
export const ClientKYCFinancesModule = getModule(clientKycFinances);

