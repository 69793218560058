import { ELegalSubjectIcons, ELegalSubject } from '~/settings/enums/legal-subject.enum';

export function getIconByLegalSubjectId (id: number): string {
    switch (id) {
    case ELegalSubject.business:
        return ELegalSubjectIcons.business;

    case ELegalSubject.individual:
        return ELegalSubjectIcons.individual;

    default:
        return ELegalSubjectIcons.unknown;
    }
}
