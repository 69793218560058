import Vue from 'vue';
import toggleMixin from '~/components/mixins/toggleMixin';
import { ProfileModule } from '~/store/modules/profile/profile';

export default Vue.extend({
    mixins: [
        toggleMixin
    ],
    props: {
        name: {
            type: String
        },
        departmentName: {
            type: String
        },
        email: {
            type: String
        }
    },
    computed: {
        profile () {
            return ProfileModule.getProfile;
        },
    },
    methods : {
        logout ():void  {
            ProfileModule.handleLogout();
        },
        userDropdownOpen (): void {
            this.toggleOpen();
        }
    }
});
