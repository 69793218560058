export default {
    data (): Object {
        return {
            isPhoneOpen : false,
        };
    },
    methods : {
        togglePhoneOpen (): void {
            this.isPhoneOpen = !this.isPhoneOpen;
        },
        showPhone (): void {
            this.isPhoneOpen = true;
        },
        hidePhone (): void {
            this.isPhoneOpen = false;
        }
    }
};
