<template>
    <button
        v-if="disabled"
        type="button"
        class="
            text-nowrap
            details_nav-link
            mr-3
        "
        disabled
    >
        <span class="label">{{ label }}</span>
        <div
            v-if="notification"
            class="details_notification"
        >
            {{ notification }}
        </div>
    </button>

    <router-link
        v-else
        :to="to"
        class="details_nav-link mr-3"
    >
        {{ label }}
        <div
            v-if="notification"
            class="details_notification"
        >
            {{ notification }}
        </div>
    </router-link>
</template>

<script>
export default {
    props : {
        to : {
            type : String,
            required : true,
        },
        label : {
            type : String,
            required: true,
        },
        notification : {
            type : Number,
            required: false,
        },
        disabled : {
            type : Boolean,
            default: false
        }
    },
};
</script>
