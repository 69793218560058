import { LeadAccountsModule } from '~/store/modules/lead-accounts/lead-accounts';
import { FiltersInterface } from './finance-mixin.interfaces';
import { ILeadAccount } from '~/store/modules/lead-accounts/lead-accounts-interfaces';
import { FilterInterface } from '~/store/modules/client-kyc-finances/client-kyc-finances-interfaces';
import { HasId } from '~/settings/interfaces/common-interfaces';
import { FinanceMixinEnums, EventNameEnums } from './finance-mixin.enums';
import { PaymentHistoryQueryInterfaces } from '~/components/leadsItem/FinanceDetails/payment-history/interfaces/payment-history-interfaces';
import { ClientKYCFinancesModule } from '~/store/modules/client-kyc-finances/client-kyc-finances';
import { handlerRoutesQuery } from '~/helpers/handler-routes-query';
import loadingMixin from '~/components/mixins/loadingMixin';
import configsMixin from '~/components/mixins/configs-mixin';

export default {
    data (): FiltersInterface {
        return {
            accTypeSelected: null,
            paymentStatusSelected: null,
            withdrawalStatusSelected: null,
            operationSelected: null
        };
    },
    computed: {
        accounts (): ILeadAccount[]  {
            return LeadAccountsModule.getAccounts;
        },
    },
    mixins: [
        loadingMixin,
        configsMixin,
    ],

    methods: {
        handlerPaymentQuery (key: keyof PaymentHistoryQueryInterfaces, value: string): void {
            ClientKYCFinancesModule.handlerPaymentQuerySimple({ key, value });
            handlerRoutesQuery(key, value);
        },
        loadTablePaymentHistory (): void {
            const query = this.$route.query;
            ClientKYCFinancesModule.loadPaymentsHistory(query);
        },
        setFilter (): FilterInterface {
            throw new Error('Method \'setFilter\' not implemented.');
        },
        load (): FilterInterface {
            throw new Error('Method \'load\' not implemented.');
        },
        filter (selectedOption: HasId, type: string, operation: string): void {
            const id = operation === EventNameEnums.Remove ?
                null :
                FinanceMixinEnums.AccountsIds === type ?
                    [{ id: selectedOption.id }] :
                    selectedOption.id;

            this.setFilter({ [type]: id });
            this.load({ leadId: this.$route.params.id });
        }
    }
};
