

































































































































import ItemNavbar from './ItemNavbar.vue';
import appMoreOptions from '~/components/parts/app-more-options/app-more-options.vue';
import paymentHistory from './FinanceDetails/payment-history/payment-history.vue';
import financeHelper from '~/components/mixins/finance-helper';
import { LeadAccountsModule } from '~/store/modules/lead-accounts/lead-accounts';
import { ClientKYCFinancesModule } from '~/store/modules/client-kyc-finances/client-kyc-finances';
import { PaymentStatusEnum } from '~/settings/enums/payment-status-enum';
import { handlerRoutesQuery } from '~/helpers/handler-routes-query';
import { ETableFilterKeyPaymentHistory } from '~/components/tables/enums/table-filter-key.enum';
import { formattedAmount } from '~/helpers/format';
import { PaymentHistoryPaginationInterface } from '~/components/mixins/finance/finance-mixin.interfaces';
import { ITotalCurrencyInterface, ITotalWithdrawals } from '~/store/modules/client-kyc-finances/client-kyc-finances-interfaces';
import { ModalsModule } from '~/store/modules/modals';
import { ETableType } from '~/components/tables/enums/table-type.enum';
import { ETableFilterKeyPagination } from '~/components/tables/enums/table-filter-key.enum';

export default {
    components: {
        ItemNavbar,
        paymentHistory,
        appMoreOptions,
    },
    mixins: [
        financeHelper,
    ],
    data () {
        return {
            paymentStatuses: PaymentStatusEnum,
        };
    },
    computed: {
        isTableType (): string {
            return ETableType.paymentHistory;
        },
        selectedOption (): string {
            return ClientKYCFinancesModule.getFinanceTabsActive;
        },
        statusesCounter (): object {
            return ClientKYCFinancesModule.getStatusesCounter;
        },
        isQueryHasOnlyStatus (): boolean {
            return this.$route.query[ETableFilterKeyPaymentHistory.status]
              && Object.keys(this.$route.query).length === 1;
        },
        isQueryHasOnlyStatusAndPaginationQuery (): boolean {
            const queryKeysLength  = Object.keys(this.$route.query).length;
            const perPage = this.$route.query[ETableFilterKeyPagination.perPage];
            const page = this.$route.query[ETableFilterKeyPagination.page];
            const status = this.$route.query[ETableFilterKeyPaymentHistory.status];

            return (status && perPage && page && queryKeysLength === 3) ||
                (perPage && queryKeysLength === 2) ||
                (page && queryKeysLength === 2);
        },
        isClearQuery (): boolean {
            const queryKeysLength  = Object.keys(this.$route.query).length;
            const status = this.$route.query[ETableFilterKeyPaymentHistory.status];
            const perPage = this.$route.query[ETableFilterKeyPagination.perPage];
          
            const hasQueryNotOnlyStatusAndPerPage = status &&
                perPage &&
                queryKeysLength > 2;

            const hasQueryNotOnlyStatusAndHasNotPerPage = status &&
                !perPage &&
                queryKeysLength > 1;

            const hasQueryNotOnlyPerPageAndHasNotStatus = perPage &&
                !status &&
                queryKeysLength > 1;

            return hasQueryNotOnlyStatusAndPerPage ||
                hasQueryNotOnlyStatusAndHasNotPerPage ||
                hasQueryNotOnlyPerPageAndHasNotStatus;
        },
        isPreApprovedButtonActive (): boolean {
            return this.isQueryHasOnlyStatus ||
                this.isQueryHasOnlyStatusAndPaginationQuery &&
                Number(this.$route.query[ETableFilterKeyPaymentHistory.status]) === PaymentStatusEnum.preApproved;
        },
        isPendingButtonActive (): boolean {
            return this.isQueryHasOnlyStatus ||
                this.isQueryHasOnlyStatusAndPaginationQuery &&
                Number(this.$route.query[ETableFilterKeyPaymentHistory.status]) === PaymentStatusEnum.pending;
        },
        getTotalWithdrawals (): ITotalWithdrawals {
            return ClientKYCFinancesModule.getTotalWithdrawals;
        },
        getTotalCurrency (): ITotalCurrencyInterface {
            return ClientKYCFinancesModule.getTotalCurrency;
        },
        isPagination (): PaymentHistoryPaginationInterface {
            return ClientKYCFinancesModule.getHistoryPagination;
        },
        isPositiveNumber (): boolean {
            return parseFloat(
                formattedAmount(this.getTotalWithdrawals.net_deposits)
                    .replace(',', '.')
            ) >= 0;
        },
        leadsPaymentsModal () {
            return ModalsModule.modalType.leadsPaymentsModal;
        },
    },
    mounted () {
        LeadAccountsModule.handleSetAccounts(this.$route.params.id);
    },
    methods: {
        loadPayments () {
            const query = this.$router.currentRoute.query;
            ClientKYCFinancesModule.loadPaymentsHistory(query);
        },
        clearFilterSelect () {
            handlerRoutesQuery(ETableFilterKeyPaymentHistory.status, null);
            this.loadPayments();
        },
        openPaymentMethods (event) {
            ModalsModule.openModal({
                modalType: ModalsModule.modalType.leadsPaymentsModal,
                modalSettings: {
                    props: {
                        minWidth: ModalsModule.modalWidth.lg,
                        minHeight: ModalsModule.modalHeight.lg,
                    },
                    positionModal: {
                        clientX: event.clientX,
                        clientY: event.clientY,
                        modalWidth: ModalsModule.modalWidth.lg,
                    }
                },
            });
        },
        filterByStatus (selectedOption) {
            if (this.$refs[`appButtonVerifyStatus_${selectedOption}`].active) {
                this.clearFilterSelect();
            } else if (
                this.isClearQuery
            ) {
                handlerRoutesQuery(ETableFilterKeyPaymentHistory.status, [selectedOption]);
                handlerRoutesQuery(ETableFilterKeyPagination.page, null);
                this.$refs.paymentHistory.clearFiltersOfTable([ETableFilterKeyPaymentHistory.status]);
            } else {
                handlerRoutesQuery(ETableFilterKeyPaymentHistory.status, [selectedOption]);
                handlerRoutesQuery(ETableFilterKeyPagination.page, null);
                this.loadPayments();
            }
        },
        formattedAmount (value) {
            return formattedAmount(value);
        },
    }
};
