/* eslint-disable no-prototype-builtins */
import Vue from 'vue';
import { AppMultiSelectInterfaces } from './interfaces/app-multiselect-interfaces';
import Multiselect from 'vue-multiselect';
import MultiselectMixin from 'vue-multiselect/src/multiselectMixin';
import { COLUMN_TIMEZONE_ID } from '~/store/modules/_columnNames';
import SelectDone from './_buttons/select-done/select-done.vue';
import allOption from './_buttons/all-option/all-option.vue';
import noneOption from './_buttons/none-option/none-option.vue';
import elementOption from './_buttons/element-option/element-option.vue';
import groups from './_buttons/groups/groups.vue';
import { EMultiSelectType } from './enums/multiselect-type.enum';
import { EMultiselect } from './enums/multiselect.enum';
import defaultComponent from  './_template-option/default/default.vue';
import defaultSingleComponent from  './_template-option/default/default.vue';
import activityComponent from  './_template-option/activity/activity.vue';
import financeAccountsComponent from  './_template-option/finance-accounts/finance-accounts.vue';
import clientSearchComponent from  './_template-option/client-search/client-search.vue';
import paymentHistoryAccountsComponent from  './_template-option/payment-history-accounts/payment-history-accounts.vue';
import financeStatusComponent from  './_template-option/finance-status/finance-status.vue';
import localTimeComponent from  './_template-option/local-time/local-time.vue';
import phoneComponent from  './_template-option/phone/phone.vue';
import phoneSingleComponent from  './_template-single/phone/phone.vue';
import PlaceholderValueSingleComponent from  './_template-single/placeholder-value/placeholder-value.vue';
import affiliatesStatusComponent from  './_template-option/affiliates-status/affiliates-status.vue';
import countryComponent from  './_template-option/country/country.vue';
import AffiliateSearchComponent from  './_template-option/affiliate-search/affiliate-search.vue';
import ClientTypeComponent from './_template-option/client-type/client-type.vue';
import concatFNameLNameComponent from './_template-option/concat-fname-lname/concat-fname-lname.vue';
import { IDefaultConfig, IConfigItem } from '~/store/modules/configs/interfaces/configs-interface';
import leverageSingleComponent from  './_template-single/leverage/leverage.vue';
import { EConfigType } from '~/store/modules/configs/enums/config-type.enum';

export default Vue.extend({
    components: {
        Multiselect,
        SelectDone,
        allOption,
        noneOption,
        elementOption,
        groups,
        defaultSingleComponent,
        defaultComponent,
        activityComponent,
        financeAccountsComponent,
        clientSearchComponent,
        paymentHistoryAccountsComponent,
        financeStatusComponent,
        localTimeComponent,
        phoneComponent,
        phoneSingleComponent,
        PlaceholderValueSingleComponent,
        affiliatesStatusComponent,
        countryComponent,
        AffiliateSearchComponent,
        ClientTypeComponent,
        concatFNameLNameComponent,
        leverageSingleComponent,
    },
    props: {
        ...MultiselectMixin.props,
        isLabel: {
            type: String
        },
        noMargin: {
            type: Boolean
        },
        noBorder: {
            type: Boolean
        },
        noRounding: {
            type: Boolean
        },
        addClass: {
            type: String
        },
        name: {
            type: String
        },
        validation: {
            type: String
        },
        multiselectType: {
            type: String,
            default: 'default'
        },
        multiselectSingleType: {
            type: String,
            default: ''
        },
        size: {
            type: String
        },
        currentId : {
            type: String
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        placeholderCenter: {
            type: Boolean,
            default: false,
        },
        closeOnSelect: {
            type: Boolean,
            default: false,
        },
        allReset: {
            type: Boolean,
            default: false,
        },
        allResetDisabled: {
            type: Boolean,
            default: false,
        },
        noneOption: {
            type: Boolean,
            default: false,
        },
        selectedList: {
            type: Boolean,
            default: true,
        },
        isLoadMoreButton: {
            type: Boolean,
            default: false,
        },
        groups: {
            type: Array,
            default: () => { return []; },
        },
        groupSelected: {
            type: Object,
        },
    },
    data (): AppMultiSelectInterfaces {
        return {
            className: 'b-multiselect',
            classResetButton: 'reset-button',
            classSelectButton: 'multiselect__select',
            multiSelectTypeEnum: EMultiSelectType,
            selectedGroup: null,
            isOpened: false,
        };
    },
    computed: {
        isCurrentId (): string {
            return this.allBindings.currentId;
        },
        isLocalTime (): boolean {
            return this.isCurrentId === COLUMN_TIMEZONE_ID;
        },
        isMultiple (): boolean {
            return this.allBindings['multiple'];
        },
        isCleanButton (): boolean {
            return this.allBindings['clear-button'] && !!this.isExistValue;
        },
        isExistValue (): boolean {
            return this.allBindings.value && this.allBindings.value.length !== 0;
        },
        isValueLength (): number {
            return this.isMultiple ? this.allBindings.value.length : 0;
        },
        allBindings () {
            // Need to proxify both props and attrs, for example for showLabels
            const result = { ...this.$props, ...this.$attrs };

            this.checkValueByLabel(result);

            return result;
        },
        isExistLabel (): boolean {
            return !!this.isLabel;
        },
        isNoMargin (): boolean {
            return this.noMargin;
        },
        isTrackBy (): string {
            return this.allBindings['trackBy'] || '';
        },
        isValueString (): string {
            return this.allBindings.value && this.allBindings.value.length !== 0
                ? this.allBindings.value[this.isTrackBy]
                    || this.allBindings.value
                : '';
        },
        isValueObjLabel (): string {
            return this.allBindings.value && this.allBindings.value.length !== 0
                ? this.allBindings.value[this.allBindings.label]
                    || this.allBindings.value
                : '';
        },
        isSelectionLabelMultiple (): string {
            return this.allBindings.value && this.allBindings.value.length !== 0
                ? this.allBindings.value[0][this.allBindings.label]
                    || this.allBindings.value
                : '';
        },
        isValueObj (): object {
            return this.allBindings.value;
        },
        isGroups (): boolean {
            return this.groups?.length > 0;
        },
        isOptions (): IConfigItem[] {
            // TODO FOR Bonus plan only / need to be expanded
            return this.selectedGroup
                ? this.allBindings.options.filter(
                    option => option.bonus_type.id === this.selectedGroup.id
                )
                : this.allBindings.options;
        },
        isMultipleSingleLabel (): object {
            return Array.isArray(this.isValueObj)
                ? this.isValueObj[0]
                : this.isValueObj;
        },
        isMultiselectSingleLabelType (): string {
            return this.multiselectSingleType
                ? `${this.multiselectSingleType}-single-component`
                : `${this.multiselectType}-component`;
        },
        isOptionsLongList (): boolean {
            return this.isOptions.length > EMultiselect.longList;
        },
    },
    watch: {
        groupSelected: {
            immediate: true,
            handler (payload) {
                this.selectedGroup = payload;
            }
        },
    },
    methods: {
        resetSelectedGroup () {
            this.selectedGroup = null;
            this.$emit('selectGroup', null);
        },
        selectGroup (group: IDefaultConfig) {
            const _group = group.id === this.selectedGroup?.id ? null : group;
            this.selectedGroup = _group;
            this.$emit('selectGroup', _group);
        },
        handleClean () {
            this.$emit('onClear');
        },
        handleClose () {
            this.setOpenedState(false);
            const el = this.$refs.appMultiSelect.$el;
            if (el) {
                el.blur();
            }
        },
        handleDone () {
            this.$emit('click');
            this.handleClose();
        },
        selectAll () {
            const params = this.allReset  ? null : this.allBindings.options;
            this.$emit('select', params);
            this.$emit('input', params);
            if (this.closeOnSelect) {
                this.handleClose();
            }
        },
        selectNone () {
            this.$emit('select', null);
            this.$emit('input', null);
            if (!this.isMultiple && this.closeOnSelect) {
                this.handleClose();
            }
        },
        setOpenedState (payload: boolean) {
            this.isOpened = payload;
        },
        /**
         * Remove input error
         * */
        removeInputError () {
            this.$refs.validationProvider.reset();
        },
        handleClick (event) {
            const target = event.target;

            if (
                target.classList.contains === this.classResetButton ||
                target.closest(`.${this.classResetButton}`) ||
                (this.isOpened && target.classList.contains === this.classSelectButton)
            ) {

                this.$refs.appMultiSelect.toggle();
            } else {
                this.setOpenedState(true);
            }
        },
        removeSelectedOption (option) {
            this.$refs.appMultiSelect.removeElement(option);
        },
        loadMore (): void {
            this.$emit('loadMore');
        },
        checkValueByLabel (allBindings) {
            const value = allBindings.value;
            const label = allBindings.label;

            if (
                typeof value === EConfigType.object &&
                value !== null &&
                !Array.isArray(value) &&
                label
            ) {
                if (!value[label] || !value.hasOwnProperty(label)) {
                    allBindings.value = null;
                }
            }
        },
    }
});
