import Vue from 'vue';

export default Vue.extend({
    props: {
        addClass: {
            type: String,
        },
        isHeader: {
            type: Boolean,
            required: true
        },
        isFooter: {
            type: Boolean,
            required: true
        },
        isScroll: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        closeModal (): void {
            this.$emit('closeModal');
        }
    }
});
