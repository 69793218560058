import Vue from 'vue';
import { CalendarAddEventQuickInterfaces } from './interfaces/calendar-add-event-quick-interfaces';
import DatePicker from 'vue2-datepicker';
import { parseDate } from '~/helpers/common';

export default Vue.extend({
    components: {
        DatePicker
    },
    props: {
        position: {
            type: Object
        },
        side: {
            type: String
        },
        startDate: {
            type: Date
        }
    },
    data (): CalendarAddEventQuickInterfaces {
        return {
            className: 'b-calendar-add-event-quick',
            eventTitle: '',
            customDateFirst: this.startDate,
            customDateLast: this.startDate,
            timeList: ['08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30'],
            timeFrom: '08:30',
            timeTo: '18:30',
            isDateFirstError: false,
            isDateLastError: false
        };
    },
    methods: {
        resetError () {
            this.isDateFirstError = false;
            this.isDateLastError = false;
        },
        validationDate () {
            this.resetError();

            if (!this.customDateFirst) {
                this.isDateFirstError = true;
            }
            if (!this.customDateLast) {
                this.isDateLastError = true;
            }

        },
        setFormatDate (date: string): string {
            return parseDate(date, 'YYYY-MM-DD').format('YYYY-MM-DD');
        },
        disabledFromFirstDate (date: any): Boolean {
            return this.customDateLast ? date >= this.customDateLast : false;
        },
        disabledFromLastDate (date: any): Boolean {
            return date <= this.customDateFirst;
        },

        closeModal () {
            this.$emit('close');
        },
        addEvent () {
            this.validationDate();

            if (this.isDateFirstError || this.isDateLastError) {
                return false;
            }

            const start = this.setFormatDate(this.customDateFirst);
            const end = this.setFormatDate(this.customDateLast);
            const form = {
                title: this.eventTitle,
                start: `${start} ${this.timeFrom}`,
                end: `${end} ${this.timeTo}`,
                description: '',
                all_day: 0
            };
            this.$emit('addEvent', form);
            this.closeModal();
        },
        showFullForm () {
            this.closeModal();
            this.$emit('showFullForm');
        }
    }
});
