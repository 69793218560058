// Imports
import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import { tableParser } from '~/helpers/table-parser';
import { accountingManagersTableHeader } from '~/components/tables/_headers/accounting-managers';
import store from '~/store/index';

// MOCK
import { AccountingEmployeesItems } from '~/mock-data/mock-accounting-employees';

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: 'accountingManagers',
})

class AccountingManagers extends VuexModule {
    AccountingManagersItems: Array<object> = AccountingEmployeesItems;
    currentTable: Array<object> = [];

    get getterHeader (): Array<object> {
        return accountingManagersTableHeader;
    }

    get getterManagersList (): Array<object> {
        return  this.AccountingManagersItems;
    }

    get getterTable (): Array<object> {
        return this.currentTable;
    }

    @Mutation
    setAccountingManagersTable (table: Array<Object>) {
        this.currentTable = table;
    }

    @Mutation
    mutationAddManager (managerTableItem) {
        this.AccountingManagersItems = [
            ...this.AccountingManagersItems,
            {
                ...managerTableItem
            }
        ];
    }

    @Mutation
    mutationRemoveManager (id) {
        this.AccountingManagersItems = this.AccountingManagersItems.filter((item: Object) => {
            return item[id] !== id;
        });
    }

    @Action ({ commit: 'setAccountingManagersTable' })
    actionSetManagersTable () {
        const parsedRows = tableParser(this.getterHeader, this.getterManagersList);
        return parsedRows;
    }

    @Action
    actionAddManager ({ fullName, bonusPlan, status }) {
        // TODO add request to server
        const id = `${Math.random()}`;
        this.mutationAddManager({
            id,
            fullName: fullName,
            department: 'Developers',
            role: 'Front-end',
            bonusPlan: bonusPlan,
            status: status
        });
        this.actionSetManagersTable();
    }

    @Action
    removeManagerItem (id) {
        this.mutationRemoveManager(id);
        this.actionSetManagersTable();
    }
}

export const AccountingManagersModules = getModule(AccountingManagers);
