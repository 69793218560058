import Vue from 'vue';
import toggleMixin from '~/components/mixins/toggleMixin';
import EditBtn from '~/components/leadsItem/LeadDetails/parts/editBtn.vue';
import EditModeBtns from '~/components/leadsItem/LeadDetails/parts/editModeBtns.vue';
import appMultiSelect from '~/components/parts/app-multiselect/app-multiselect.vue';
import loadingMixin from '~/components/mixins/loadingMixin';
import fieldModelMixin from '~/components/mixins/modelMixins/fieldModelMixin';
import { KycClientModule } from '~/store/modules/kyc-client/kyc-client';
import { NotificationPrefabricated } from '~/helpers/modal-notification-services/modal-notification-services';
import leadDetailsMixin from '~/components/mixins/leadDetailsMixin';
import { IDefaultConfig, IDefaultConfigWithFullName } from '~/store/modules/configs/interfaces/configs-interface';
import { isUserPermissionEditUsersClients } from '~/helpers/user-permission';

export default Vue.extend({
    components: {
        EditBtn,
        EditModeBtns,
        appMultiSelect
    },
    mixins: [
        toggleMixin,
        leadDetailsMixin,
        loadingMixin,
        fieldModelMixin,
    ],
    props: {
        title: {
            required: true,
            type: String
        },
        leadName: {
            type: String
        },
        IdManager: {
            type: Number
        },
        user: {
            type: Object
        }
    },
    data (): Object {
        return {
            selectedLeadManager: null
        };
    },
    computed: {
        isUserPermissionEditUsersClients,
        users () {
            return KycClientModule.getUsersList;
        },
        leadManager: {
            get (): IDefaultConfigWithFullName {
                return this.selectedLeadManager || (this.user && {
                    ...this.user,
                    name: this.user.full_name,
                });
            },
            set (value: IDefaultConfig): void {
                this.selectedLeadManager = value;
            },
        },
    },
    methods: {
        clear () {
            this.clearSelected();
        },
        restore () {
            this.restoreSelected();
            this.hide();
        },
        restoreSelected (): void {
            this.selectedLeadManager = null;
        },
        confirm () {
            this.startLoading();
            this.hide();
            this.updateLeadDetails({
                [this.leadName]: this.leadManager.id
            })
                .catch(error => {
                    NotificationPrefabricated.Error(error);
                })
                .finally(() => {
                    this.endLoading();
                    NotificationPrefabricated.leadManagerEditSuccess(this.title);
                });
        },
        async handleUsers (id: number) {
            this.toggleOpen();
            await KycClientModule.handleSetUsersList({ department_id: id });
        }
    }
});
