import { render, staticRenderFns } from "./calendar-notifications.html?vue&type=template&id=609d9e26&lang=html&"
import script from "./calendar-notifications.ts?vue&type=script&lang=ts&"
export * from "./calendar-notifications.ts?vue&type=script&lang=ts&"
import style0 from "./calendar-notifications.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports