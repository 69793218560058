import Vue from 'vue';
import { ModalNotificationTypesEnum, EModalNotificationButtonTypes } from './enums/modal-notification.enum';
import { ENotificationsButtonActions } from '~/components/parts/notification-message/enums/notiffications-button-actions.enum';
import {
    IConfirmActionPayload,
    INotificationOptions
} from '~/helpers/modal-notification-services/interfaces/modal-notification-service-interface';
import { IUsersDeletePayload } from '~/store/modules/users/users-interface';
const SET_ACTIVE_NOTIFICATION_STATE = 'ModalNotification/setActiveNotificationState';
import store from '~/store';

export class NotificationService extends Vue {
    settings = {
        duration: 3000,
        group: 'notification',
    };

    private notificationId: number = 1;

    public notify (
        text: string,
        type: ModalNotificationTypesEnum,
        options?: INotificationOptions,
    ): Promise<number> {
        return new Promise((resolve) => {
            // TODO
            const duration = options?.duration || this.settings.duration;
            const callback = options?.callback || null;
            const buttons = options?.buttons?.map((button) => ({
                name: button.name,
                action: button.id,
                param: button.param,
                position: button.position,
                type: button.type || EModalNotificationButtonTypes.Success,
            })) || [];
            const classes = options?.classes || '';

            this.$notify({
                group: this.settings.group,
                text,
                type,
                duration: duration,
                data: {
                    callback: callback,
                    notificationId: this.notificationId,
                    buttons: buttons,
                    classes: classes,
                },
            });
            resolve(this.notificationId++);

            if (buttons.length > 0) {
                store.dispatch(SET_ACTIVE_NOTIFICATION_STATE, true);
            }
        });
    }

    public cleanAll (): void {
        this.$notify({
            group: this.settings.group,
            clean: true
        });
    }
}

export class NotificationPrefabricated {
    public static cleanAllNotify = (): void =>
        notificationService.cleanAll();

    public static Error = (message: string): Object =>
        notificationService.notify(message, ModalNotificationTypesEnum.Error);

    public static Success = (message: string): Object =>
        notificationService.notify(message, ModalNotificationTypesEnum.Success);

    public static uploadedDocumentsSuccess = (): Object =>
        notificationService.notify('The document was uploaded successfully.', ModalNotificationTypesEnum.Success);

    public static approvedDocumentsSuccess = (): Object =>
        notificationService.notify('The document was approved successfully.', ModalNotificationTypesEnum.Success);

    public static rejectedDocumentsSuccess = (): Object =>
        notificationService.notify('The document was rejected successfully.', ModalNotificationTypesEnum.Success);

    public static deletedDocumentsSuccess = (): Object =>
        notificationService.notify('The document successfully deleted.', ModalNotificationTypesEnum.Success);

    public static updateDocumentsSuccess = (): Object =>
        notificationService.notify('The document updated.', ModalNotificationTypesEnum.Success);

    public static PendingDocumentsSuccess = (): Object =>
        notificationService.notify('The document request added.', ModalNotificationTypesEnum.Success);

    public static PendingDocumentsRemoveSuccess = (): Object =>
        notificationService.notify('The document request removed.', ModalNotificationTypesEnum.Success);

    public static documentTypeChanged = (): Object =>
        notificationService.notify('The document type was changed.', ModalNotificationTypesEnum.Success);

    public static KycTinsEditSuccess = (): Object =>
        notificationService.notify('The Tins edited.', ModalNotificationTypesEnum.Success);

    public static KycCitizenshipEditSuccess = (): Object =>
        notificationService.notify('The Citizenship edited.', ModalNotificationTypesEnum.Success);

    public static KycInputEditSuccess = (): Object =>
        notificationService.notify('The field edited.', ModalNotificationTypesEnum.Success);

    public static KycDateEditSuccess = (): Object =>
        notificationService.notify('The date edited.', ModalNotificationTypesEnum.Success);

    public static KycTabRequestItemSuccess = (): Object =>
        notificationService.notify('The request item edited.', ModalNotificationTypesEnum.Success);

    public static KycPhoneSuccess = (): Object =>
        notificationService.notify('The phone edited.', ModalNotificationTypesEnum.Success);

    public static KycLanguageSuccess = (): Object =>
        notificationService.notify('The language selected.', ModalNotificationTypesEnum.Success);

    public static KycCountrySuccess = (): Object =>
        notificationService.notify('The country selected.', ModalNotificationTypesEnum.Success);

    public static AddPaymentRequisitesSuccess = (): Object =>
        notificationService.notify('The Payment added.', ModalNotificationTypesEnum.Success);

    public static editPaymentRequisitesSuccess = (): Object =>
        notificationService.notify('The Payment Requisite updated.', ModalNotificationTypesEnum.Success);

    public static paymentApproved = (): Object =>
        notificationService.notify('The Payment approved.', ModalNotificationTypesEnum.Success);

    public static paymentRejected = (): Object =>
        notificationService.notify('The Payment rejected.', ModalNotificationTypesEnum.Success);

    public static transactionCreated = (): Object =>
        notificationService.notify('The transaction created.', ModalNotificationTypesEnum.Success);

    public static createNewUserSuccess = (): Object =>
        notificationService.notify('The user added.', ModalNotificationTypesEnum.Success);

    public static NotificationOpenSuccess = (): Object =>
        notificationService.notify('The Notification opened.', ModalNotificationTypesEnum.Success);

    public static NotificationUpdateSuccess = (): Object =>
        notificationService.notify('The Notification updated.', ModalNotificationTypesEnum.Success);

    public static updateUserSuccess = (): Object =>
        notificationService.notify('The user updated.', ModalNotificationTypesEnum.Success);

    public static LeadFilterTemplatesSavedSuccess = (): Object =>
        notificationService.notify('Your report template has been successfully saved.', ModalNotificationTypesEnum.Success);

    public static LeadFilterTemplatesEditSuccess = (): Object =>
        notificationService.notify('Leads have been successfully updated.', ModalNotificationTypesEnum.Success);

    public static nothingWasFound = (): Object =>
        notificationService.notify('Nothing was found!', ModalNotificationTypesEnum.Error);

    public static pleaseWait = (): Object =>
        notificationService.notify(
            'Please wait!',
            ModalNotificationTypesEnum.Pending,
            {
                duration: -1,
            },
        );

    public static deleteUser = (payload: IUsersDeletePayload): Object =>
        notificationService.notify(
            'Are you sure you want to delete this user?',
            ModalNotificationTypesEnum.Pending,
            {
                duration: -1,
                buttons: [
                    { name: 'No', id: ENotificationsButtonActions.close, type: EModalNotificationButtonTypes.Error, param: false, position: false },
                    { name: 'Yes', id: ENotificationsButtonActions.deleteUser, type: EModalNotificationButtonTypes.Success, param: payload, position: false },
                ]
            },
        );

    public static deletePaymentRequisites = (id: number, type: string): Object =>
        notificationService.notify(
            `Are you sure you want to Delete the ${type}`,
            ModalNotificationTypesEnum.Pending,
            {
                duration: -1,
                buttons: [
                    { name: 'No', id: ENotificationsButtonActions.close, type: EModalNotificationButtonTypes.Error, param: false, position: false },
                    { name: 'Yes', id: ENotificationsButtonActions.deletePaymentRequisites, param: id, position: false },
                ]
            }
        );

    public static confirmAction = (payload: IConfirmActionPayload) : Object => notificationService.notify(
        payload.text,
        ModalNotificationTypesEnum.Pending,
        {
            duration: -1,
            buttons: [
                { name: 'No', id: ENotificationsButtonActions.close, type: EModalNotificationButtonTypes.Error, param: false, position: false },
                { name: 'Yes', id: payload.confirmData.actionId, param: payload.confirmData, position: false },
            ]
        },
    );

    public static withdrawalsApprove = (): Object =>
        notificationService.notify('The withdrawal approved.', ModalNotificationTypesEnum.Success);

    public static withdrawalsReject = (): Object =>
        notificationService.notify('The withdrawal rejected.', ModalNotificationTypesEnum.Pending);

    public static leadManagerEditSuccess = (title: string): Object =>
        notificationService.notify(`The ${title} has been successfully updated.`, ModalNotificationTypesEnum.Success);

    public static LeadCreatedSuccess = (): Object =>
        notificationService.notify('The Lead has been successfully created.', ModalNotificationTypesEnum.Success);

    public static LeverageChangeSuccess = (): Object =>
        notificationService.notify('The leverage was changed for selected accounts.', ModalNotificationTypesEnum.Success);

    public static LeverageChangeError = (): Object =>
        notificationService.notify('The selected account_ids is invalid.', ModalNotificationTypesEnum.Error);

    public static MassiveEmailsSendSuccess = (): Object =>
        notificationService.notify('Emails set to queue!', ModalNotificationTypesEnum.Success);

    public static emailsSendSuccess = (): Object =>
        notificationService.notify('Your E-mail has been sent.', ModalNotificationTypesEnum.Success);

    public static KycFieldSuccessEdit = (): Object =>
        notificationService.notify('The request have been solved.', ModalNotificationTypesEnum.Success);

    public static KycFieldCancelEdit = (): Object =>
        notificationService.notify('The change was declined.', ModalNotificationTypesEnum.Pending);

    public static callConfirmedSuccess = (): Object =>
        notificationService.notify('1K call was performed successfully.', ModalNotificationTypesEnum.Success);

    public static cryptoWalletDecline = (): Object =>
        notificationService.notify('Crypto Wallet Address has been Declined.', ModalNotificationTypesEnum.Pending);

    public static cryptoWalletSuccess = (): Object =>
        notificationService.notify('Crypto Wallet Address has been Verified.', ModalNotificationTypesEnum.Success);

    public static updateBonusPlanSuccess = (): Object =>
        notificationService.notify('The Bonus Plan updated.', ModalNotificationTypesEnum.Success);

    public static createdBonusPlanSuccess = (): Object =>
        notificationService.notify('The Bonus Plan created.', ModalNotificationTypesEnum.Success);

    public static createdAffiliateSuccess = (): Object =>
        notificationService.notify('The Affiliate created.', ModalNotificationTypesEnum.Success);

    public static updatedAffiliateSuccess = (): Object =>
        notificationService.notify('The Affiliate updated.', ModalNotificationTypesEnum.Success);

    public static massUpdatedAffiliateSuccess = (): Object =>
        notificationService.notify('The Affiliates updated.', ModalNotificationTypesEnum.Success);

    public static createdAffiliateCampaignSuccess = (): Object =>
        notificationService.notify('The Campaign created.', ModalNotificationTypesEnum.Success);

    public static createdReportSuccess = (): Object =>
        notificationService.notify('The Report created.', ModalNotificationTypesEnum.Success);

    public static reportExportSuccess = (): Object =>
        notificationService.notify(
            'Your data was exported successfully!',
            ModalNotificationTypesEnum.Success,
        );

    public static reportExportProcess = (): Object =>
        notificationService.notify(
            'Your request is being generated. Please wait!',
            ModalNotificationTypesEnum.Pending,
        );

    public static ImportSymbolsProcess = (): Object =>
        notificationService.notify(
            'Import Symbols. Please wait!',
            ModalNotificationTypesEnum.Pending,
            {
                duration: -1,
            }
        );

    public static ImportSymbolsSuccess = (): Object =>
        notificationService.notify(
            'Symbols imported!',
            ModalNotificationTypesEnum.Success,
        );

    public static createSymbolsSuccess = (): Object =>
        notificationService.notify(
            'The Symbol created.',
            ModalNotificationTypesEnum.Success,
        );

    public static updateSymbolsSuccess = (): Object =>
        notificationService.notify(
            'The Symbol updated.',
            ModalNotificationTypesEnum.Success,
        );

    public static deletedEmailTemplateSuccess = (): Object =>
        notificationService.notify('The email template successfully deleted.', ModalNotificationTypesEnum.Success);
}

export const notificationService = new NotificationService();
