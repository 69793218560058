import { ProfileModule } from '~/store/modules/profile/profile';
import { IConfig } from '~/settings/interfaces/config-default-interfaces';
import { EPermissions } from '~/settings/enums/permissions.enum';
import { EUsersId } from '~/settings/enums/users-id.enum';
import { EDepartments } from '~/settings/enums/departments.enum';

export const isUserPermissionDepartment = (id: number): boolean => {
    return ProfileModule.getProfile.department_id === id;
};

export const isPermissionAdmin = (): boolean => {
    return ProfileModule.getProfile.id === EUsersId.admin;
};

export const isPermissionDepartmentCompliance = (): boolean => {
    return ProfileModule.getProfile.department_id === EDepartments.compliance;
};

export const isPermissionDepartmentManagement = (): boolean => {
    return ProfileModule.getProfile.department_id === EDepartments.management;
};

export const isUserPermission = (id: number): boolean => {
    return !!ProfileModule.getUserPermission
        .find((permission: IConfig) => permission.id === id);
};

export const isPermissionViewUsers = (): boolean => {
    return isUserPermission(EPermissions.viewUsers);
};

export const isPermissionEditUsers = (): boolean => {
    return isUserPermission(EPermissions.editUsers);
};

export const isPermissionAddUsers = (): boolean => {
    return isUserPermission(EPermissions.addUsers);
};

export const isPermissionDeleteUsers = (): boolean => {
    return isUserPermission(EPermissions.deleteUsers);
};

export const isPermissionEditUserInformation = (): boolean => {
    return isUserPermission(EPermissions.editUserInformation);
};

export const isPermissionEditPassword = (): boolean => {
    return isUserPermission(EPermissions.editPassword);
};

export const isPermissionEditUserAccess = (): boolean => {
    return isUserPermission(EPermissions.editUserAccess);
};

export const isPermissionViewClient = (): boolean => {
    return isUserPermission(EPermissions.viewClient);
};

export const isPermissionNotes = (): boolean => {
    return isUserPermission(EPermissions.notes);
};

export const isPermissionCreateNotes = (): boolean => {
    return isUserPermission(EPermissions.createNotes);
};

export const isPermissionMultipleEditClients = (): boolean => {
    return isUserPermission(EPermissions.multipleEditClients);
};

export const isPermissionEditClientsInformation = (): boolean => {
    return isUserPermission(EPermissions.editClientsInformation);
};

export const isUserPermissionEditUsersClients = (): boolean => {
    return isUserPermission(EPermissions.editUsersClients);
};

export const isPermissionEditClientEmailPhone = (): boolean => {
    return isUserPermission(EPermissions.editClientEmailPhone);
};

export const isPermissionExportToExcel = (): boolean => {
    return isUserPermission(EPermissions.exportToExcel);
};

export const isPermissionImportFromExcel = (): boolean => {
    return isUserPermission(EPermissions.importFromExcel);
};

export const isPermissionEnableEmails = (): boolean => {
    return isUserPermission(EPermissions.enableEmails);
};

export const isPermissionEditClientsGroup = (): boolean => {
    return isUserPermission(EPermissions.editClientsGroup);
};

export const isPermissionEditComplianceDocs = (): boolean => {
    return isUserPermission(EPermissions.editComplianceDocs);
};

export const isPermissionDeleteComplianceDocs = (): boolean => {
    return isUserPermission(EPermissions.delete);
};

export const isPermissionCampaigns = (): boolean => {
    return isUserPermission(EPermissions.campaigns);
};

export const isPermissionEditComplianceReadOnly = (): boolean => {
    return isUserPermission(EPermissions.complianceReadOnly);
};

export const isPermissionEditCompliancePayments = (): boolean => {
    return isUserPermission(EPermissions.compliancePayments);
};

export const isPermissionEditWithdrawOrders = (): boolean => {
    return isUserPermission(EPermissions.withdrawOrders);
};

export const isPermissionLiveChat = (): boolean => {
    return isUserPermission(EPermissions.liveChat);
};

export const isPermissionAccounting = (): boolean => {
    return isUserPermission(EPermissions.accounting);
};

export const isPermissionReportByTeam = (): boolean => {
    return isUserPermission(EPermissions.reportByTeam);
};

export const isPermissionCommissionDatabase = (): boolean => {
    return isUserPermission(EPermissions.commissionDatabase);
};

export const isPermissionBonusPlan = (): boolean => {
    return isUserPermission(EPermissions.bonusPlan);
};

export const isPermissionReports = (): boolean => {
    return isUserPermission(EPermissions.reports);
};

export const isPermissionAffiliate = (): boolean => {
    return isUserPermission(EPermissions.affiliate);
};

export const isPermissionEmails = (): boolean => {
    return isUserPermission(EPermissions.emails);
};

export const isPermissionCalendar = (): boolean => {
    return isUserPermission(EPermissions.calendar);
};

export const isPermissionFinance = (): boolean => {
    return isUserPermission(EPermissions.finance);
};

export const isPermissionApprovedDepositsWithdrawal = (): boolean => {
    return isUserPermission(EPermissions.approvedDepositsWithdrawal);
};

export const isPermissionNotificationsKyc = (): boolean => {
    return isUserPermission(EPermissions.notificationsKyc);
};

export const isPermissionNotificationsFunding = (): boolean => {
    return isUserPermission(EPermissions.notificationsFunding);
};

export const isPermissionNotificationsRegister = (): boolean => {
    return isUserPermission(EPermissions.notificationsRegister);
};

export const isPermissionChangeLeverage = (): boolean => {
    return isUserPermission(EPermissions.editLeverage);
};

export const isPermissionTrading = (): boolean => {
    return isUserPermission(EPermissions.trading);
};

export const isPermissionActivityLog = (): boolean => {
    return isUserPermission(EPermissions.activityLog);
};

export const isPermissionSettings = (): boolean => {
    return isUserPermission(EPermissions.settings);
};

export const isPermissionSettingsGroup = (): boolean => {
    return isUserPermission(EPermissions.settingsGroup);
};

export const isPermissionSettingsDocuments = (): boolean => {
    return isUserPermission(EPermissions.settingsDocuments);
};

export const isPermissionSettingsSymbols = (): boolean => {
    return isUserPermission(EPermissions.settingsSymbols);
};
