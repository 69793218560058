// Import
import { Module, VuexModule, getModule, Mutation, Action } from 'vuex-module-decorators';
import store from '~/store/index';
import { arrayToMap } from '~/helpers/common';

// MOCK DATA
import { emailsInboxListMock } from '~/mock-data/mock-emails-inbox';

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: 'EmailInbox',
})

class EmailsInbox extends VuexModule {
    emailsList: Object = arrayToMap(emailsInboxListMock);

    get getterEmailsListUnReadCount (): Number {
        const emailsList = Object.values(this.emailsList).filter(item => !item.read);
        return emailsList.length;
    }

    get getterEmailsListUnReadList (): Array<any> {
        return Object.values(this.emailsList).filter(item => !item.read);
    }

    get getterEmailsListReadList (): Array<any> {
        return Object.values(this.emailsList).filter(item => item.read);
    }

    @Mutation
    mutationEmailsListReadStatus (id: Number) {
        this.emailsList[`${id}`].read = true;
    }

    @Mutation
    mutationEmailsListUnReadStatus (id: Number) {
        this.emailsList[`${id}`].read = false;
    }

    @Mutation
    setEmailsList (payload: Array<object>) {
        this.emailsList = payload;
    }

    @Action({ commit: 'setEmailsList' })
    actionSetEmailsList () {
        return this.emailsList;
    }

    @Action({ commit: 'mutationEmailsListReadStatus' })
    setEmailsListReadStatus (id: Number) {
        return id;
    }

    @Action({ commit: 'mutationEmailsListUnReadStatus' })
    setEmailsListUnReadStatus (id: Number) {
        return id;
    }
}

export const EmailsInboxModule = getModule(EmailsInbox);
