import { render, staticRenderFns } from "./affiliates-bonus-plan-tabs.html?vue&type=template&id=01d4b0e2&lang=html&"
import script from "./affiliates-bonus-plan-tabs.ts?vue&type=script&lang=ts&"
export * from "./affiliates-bonus-plan-tabs.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports