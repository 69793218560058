var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isButtonTypeRoute)?_c('router-link',{class:[
        _vm.className,
        _vm.modify ? (_vm.className + "--" + _vm.modify) : '',
        _vm.size ? (_vm.className + "--" + _vm.size) : '',
        _vm.styleButton ? (_vm.className + "--" + _vm.styleButton) : '',
        _vm.active || _vm.hasActiveModal ? 'active' : '',
        !_vm.isIconActive ? (_vm.className + "--without-active-icon") : '' ],attrs:{"to":_vm.src}},[(_vm.isIcon)?_c('div',{class:[(_vm.className + "__icons")]},[(_vm.isIcon)?_c('img',{class:[(_vm.className + "__icon")],attrs:{"src":_vm.icon,"alt":"alt"}}):_vm._e(),_vm._v(" "),(_vm.isIconActive)?_c('img',{class:[(_vm.className + "__icon"), (_vm.className + "__icon--active")],attrs:{"src":_vm.iconActive,"alt":"alt"}}):_vm._e()]):_vm._e(),_vm._v(" "),_vm._t("default")],2):(_vm.isButtonTypeLink)?_c('a',{class:[
        _vm.className,
        _vm.modify ? (_vm.className + "--" + _vm.modify) : '',
        _vm.size ? (_vm.className + "--" + _vm.size) : '',
        _vm.styleButton ? (_vm.className + "--" + _vm.styleButton) : '',
        _vm.active || _vm.hasActiveModal ? 'active' : '',
        !_vm.isIconActive ? (_vm.className + "--without-active-icon") : ''
    ],attrs:{"href":_vm.src}},[(_vm.isIcon)?_c('div',{class:[(_vm.className + "__icons")]},[(_vm.isIcon)?_c('img',{class:[(_vm.className + "__icon")],attrs:{"src":_vm.icon,"alt":"alt"}}):_vm._e(),_vm._v(" "),(_vm.isIconActive)?_c('img',{class:[(_vm.className + "__icon"), (_vm.className + "__icon--active")],attrs:{"src":_vm.iconActive,"alt":"alt"}}):_vm._e()]):_vm._e(),_vm._v(" "),_vm._t("default")],2):_c('button',_vm._g({class:[
        _vm.className,
        _vm.modify ? (_vm.className + "--" + _vm.modify) : '',
        _vm.size ? (_vm.className + "--" + _vm.size) : '',
        _vm.styleButton ? (_vm.className + "--" + _vm.styleButton) : '',
        _vm.active || _vm.hasActiveModal ? 'active' : '',
        !_vm.isIconActive || _vm.disabled ? (_vm.className + "--without-active-icon") : ''
    ],attrs:{"type":_vm.type,"disabled":_vm.disabled}},_vm.$listeners),[(_vm.isIcon)?_c('div',{class:[(_vm.className + "__icons")]},[(_vm.isIcon)?_c('img',{class:[(_vm.className + "__icon")],attrs:{"src":_vm.icon,"alt":"alt"}}):_vm._e(),_vm._v(" "),(_vm.isIconActive)?_c('img',{class:[(_vm.className + "__icon"), (_vm.className + "__icon--active")],attrs:{"src":_vm.iconActive,"alt":"alt"}}):_vm._e()]):_vm._e(),_vm._v(" "),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }