import Vue from 'vue';
import { NotificationsMessagesModule } from '~/store/modules/notifications/notifications';
import { NumbersNotificationEnum } from '~/components/notifications/enums/notifications.enum';

export default Vue.extend({
    props: {
        position: {
            type: String
        },
        count: {
            type: [Number, String]
        },
    },
    data () {
        return {
            className: 'b-notifications-count',
        };
    },
    computed: {
        isNotifications (): boolean {
            return Number(this.count) > 0;
        },
        total (): number {
            return NotificationsMessagesModule.getAllNotificationsCount;
        },
        isNotificationsCounters (): boolean {
            return this.total > NumbersNotificationEnum.Zero;
        }
    },
});
