export const formatPrice = (price: string, decimals: boolean = false, inDefault: string = '0'): string => {
    if (price) {
        const _price = (decimals) ? Number(price).toFixed(2) : price;
        const priceString = String(_price).replace(/^\d+/,
            (priceInteger) => [...priceInteger].map(
                (digit, index, digits) => (!index || (digits.length - index) % 3 ? '' : ',') + digit)
                .join(''));
        return priceString;
    }
    return inDefault;
};
