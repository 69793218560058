import { ICampaignsTokenOptions } from '~/store/modules/configs/interfaces/configs-interface';
import { copyTextToClipboard } from '~/helpers/common';

export default {
    data (): object {
        return {
            env: process.env,
            isTooltip: {
                title: '',
                disabled: true,
            },
        };
    },
    methods: {
        setTooltip (title: string): void {
            this.isTooltip = Object.assign({}, this.isTooltip, {
                title: `${title} copied!`,
                disabled: false,
            });

            this.$root.$emit('bv::show::tooltip', 'trigger-copy-token');

            setTimeout(() => {
                this.$root.$emit('bv::hide::tooltip', 'trigger-copy-token');
                this.isTooltip = Object.assign({}, this.isTooltip, {
                    title: '',
                    disabled: true,
                });
            }, 1500);
        },
        selectTokenOption (event: ICampaignsTokenOptions): void {
            copyTextToClipboard(event.value);
            this.setTooltip(event.type);

        },
        geTokenCopyOptions (value: string): ICampaignsTokenOptions[] {
            return [
                {
                    type: 'Website link',
                    name: 'Copy website affiliate link',
                    value: `${process.env.AAATRADE_URL}/?token=${value}`,
                },
                {
                    type: 'Affiliate link',
                    name: 'Copy platform affiliate link',
                    value: `${process.env.WEBTRADER_PLATFORM_URL}/dashboard?token=${value}`,
                }
            ];
        },
    }
};
