import { WithdrawalMethodsModule } from '~/store/modules/withdrawal-methods/withdrawal-methods';
import { EPaymentMethod, EPaymentMethodName } from '~/settings/enums/payment-methods.enum';
import { WithdrawalDepositTabModule } from '~/store/modules/withdrawal-deposit-tab/withdrawal-deposit-tab';
import { ClientKYCFinancesModule } from '~/store/modules/client-kyc-finances/client-kyc-finances';
import currencyHelper from '~/components/mixins/currency-helper';
import formsHelper from '~/components/mixins/forms-helper';
import configsMixin from '~/components/mixins/configs-mixin';
import { PaymentRequisitesModule } from '~/store/modules/payment-requisites/payment-requisites';

export default {
    mixins: [
        configsMixin,
        currencyHelper,
        formsHelper,
    ],
    data () {
        return {
            account: null,
            amount: 0,
            requisiteDetailsDefault: {
                bank_name: '',
                bank_address: '',
                swift: '',
                expiry_at: '',
            },
            requisiteDetails: null,
            isLoading : false,
            client_comment: null,
            eWalletSubtype: null,
        };
    },
    computed: {
        isMethodsSelected: {
            get () {
                return WithdrawalMethodsModule.getWithdrawalMethodSelected;
            },
            set (value) {
                const isValue = value;
                WithdrawalMethodsModule.setWithdrawalMethodSelected(isValue);
            }
        },
        isMethodsSelectedValue () {
            return EPaymentMethodName[this.isMethodsSelected.id];
        },

        isMethodsSelect () {
            const methodsIds = PaymentRequisitesModule.getProvidersFromRequisites;

            return this.getConfigPaymentMethods.filter(method => methodsIds.includes(method.id) && method.id !== 5);
        },
        isRequisiteDetails: {
            get () {
                return this.requisiteDetails;
            },
            set (value) {
                const isValue = value;
                this.requisiteDetails = isValue;
            }
        },
        isMethodSelectEWallet () {
            return this.isMethodsSelected
                && this.isMethodsSelected.id === EPaymentMethodName.eWallet;
        },
        configPaymentProvidersEWallet () {
            return this.getConfigPaymentProviders
                .filter(provider => provider.method_id === EPaymentMethodName.eWallet &&
                    (provider.id === EPaymentMethod.skrill || provider.id === EPaymentMethod.neteller));
        }
    },
    methods: {
        setIsLoadingTrue () {
            this.isLoading = true;
        },
        setIsLoadingFalse () {
            this.isLoading = false;
        },
        async handlerSubmitForm () {
            const isValid = await this.$refs.observer.validate();

            if (isValid || this.isLoading) {
                await this.onSubmitAddWithdrawalDepositForm();
            }
        },

        async onSubmitAddWithdrawalDepositForm () {
            const typeId = WithdrawalDepositTabModule.isActiveTab ? WithdrawalDepositTabModule.isActiveTab.id : null;

            const payload = {
                'type_id': typeId,
                'requisite_id': this.requisiteDetails.id,
                'account_id': this.account.id,
                'amount': this.amount,
                'currency_id': this.currency.id,
                'client_comment': this.client_comment,
            };

            if (this.isLoading) return;

            await this.setIsLoadingTrue();
            await ClientKYCFinancesModule.createDepositPayment(payload)
                .then((response) => {
                    if (response) {
                        this.closeModal(); // modal mixin
                        this.isMethodsSelected = WithdrawalMethodsModule.getWithdrawalMethodSelectDefault;
                        ClientKYCFinancesModule.loadPaymentsHistory(this.$route.query);
                    }
                })
                .finally(() => {
                    this.setIsLoadingFalse();
                });

        },
    }
};
