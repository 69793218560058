import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '~/store/index';
import Rest from '~/helpers/Rest';
import {
    urlBonusPlan,
    urlBonusPlanDelete,
    urlBonusPlanUpdate,
    urlBonusPlanCreate,
} from '~/settings/ajax-urls';
import { IBonusPlan, IBonusPlanUpdate } from '~/store/modules/bonus-plan/interfaces/bonus-plan-interfaces';
import { EBonusPlanUrl } from '~/settings/enums/bonus-plan-type.enum';
import { handlerRestResponse } from '~/helpers/handler-rest-response';
import { NotificationPrefabricated } from '~/helpers/modal-notification-services/modal-notification-services';

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: 'BonusPlan',
})

class BonusPlan extends VuexModule {
    bonusPlanList: IBonusPlan[] = [];
    loaded: boolean = false;

    get getBonusPlanList (): IBonusPlan[] {
        return this.bonusPlanList;
    }

    get getSortedBonusPlanList (): IBonusPlan[] {
        return this.bonusPlanList.sort((a, b) => b.is_default - a.is_default
            || Number(a.name) - Number(b.name));
    }

    get getUsersIsLoaded (): boolean {
        return this.loaded;
    }

    @Mutation
    setBonusPlanList (payload: IBonusPlan[]) {
        this.bonusPlanList = payload;
    }

    @Mutation
    setLoadedState (payload: boolean) {
        this.loaded = payload;
    }

    @Action
    async loadBonusPlan (): Promise<void> {
        this.setLoadedState(false);
        const { data } = await Rest.GET(urlBonusPlan)
            .then(Rest.middleThen)
            .catch(error => {
                Rest.simpleCatch(error);
            });
        this.setBonusPlanList(data);
        this.setLoadedState(true);
    }

    @Action
    async deleteBonusPlan (id: number): Promise<void> {
        await Rest.DELETE(urlBonusPlanDelete(id))
            .then(Rest.middleThen)
            .catch(error => {
                Rest.simpleCatch(error);
            });
    }

    @Action
    async updateBonusPlan (payload: IBonusPlanUpdate): Promise<IBonusPlanUpdate> {
        const type = EBonusPlanUrl[payload.bonus_type_id];
        return await Rest.PUT(urlBonusPlanUpdate(payload.id, type), payload)
            .then(response => {
                return handlerRestResponse(response, NotificationPrefabricated.updateBonusPlanSuccess);
            })
            .catch(Rest.simpleCatch);
    }

    @Action
    async createBonusPlan (payload: IBonusPlanUpdate): Promise<IBonusPlanUpdate> {
        const type = EBonusPlanUrl[payload.bonus_type_id];
        return await Rest.POST(urlBonusPlanCreate(type), payload)
            .then(response => {
                return handlerRestResponse(response, NotificationPrefabricated.createdBonusPlanSuccess);
            })
            .catch(Rest.simpleCatch);
    }
}

export const BonusPlanModule = getModule(BonusPlan);
