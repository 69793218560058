import Vue from 'vue';

export default Vue.extend({
    props: {
        id : {
            type: Number,
            required : true
        },
        leadId: {
            type: Number,
            required: false
        },
        fileType: {
            type: String,
            required: true,
            validator : function (value) {
                return ['thumb', 'file'].includes(value);
            }
        },
        className: {
            type: String
        },
        isText: {
            type: Boolean
        },
        fileUrl: {
            type: String,
        },
    },
    data () { // TODO revert
        return {
            authSrc: null,
        };

    },
    computed: {
        isFileUrl (): string {
            return this.fileUrl || this.authSrc;
        }
    },
});
