// https://crmv2.dev03.aaatrade.com/api/crmf/configs/all -> fields -> id
// https://crmv2.dev03.aaatrade.com/api/crmf/clients/{clientId}/kyc -> field_values -> field_id

export enum EKycFieldsValuesId {
    title_id = 1,
    fname = 2,
    mname = 3,
    lname = 4,
    company_name = 5,
    birth_date = 6,
    birth_country_id = 7,
    citizenship_country_ids = 8, // Citizenship
    residence_country_id = 9, // Country of Residence
    residence_place = 10, // City of Residence
    residence_zip = 11,
    residence_address = 12,
    phone_mobile = 14,
    phone_second = 16,
    fund_source_id = 17,
    legal_subject_id = 18,
    heard_from_id = 19, // How did you hear about us
    political_exposed = 20,
    tin_absence_reason_id = 21, // Reason for not having TIN
    employment_status_id = 22,
    industry_id = 23,
    tins = 24,
    go_pro_answers = 25, // request become a proffesional
    fund_type_is_bank_transfer = 26,
    fund_type_is_card = 27,
    fund_type_is_e_wallets = 28,
    fund_type_bank_name = 29,
    fund_type_bank_location = 30,
    aqm_on_behalf_of = 31,
    aqm_on_behalf_of_capacity = 32,
    aqm_submit_statement = 33,
    business_address = 34,
    business_phone = 35,
    first_account_request_mode_type_id = 36,
    first_account_request_currency_id = 37,
    first_account_request_profit_method_type_id = 38,
    // Assessment question
    question1 = 39,
    question2 = 40,
    question3 = 41,
    question4 = 42,
    question5 = 43,
    // Investment Knowledge question
    question6 = 44,
    question7 = 45,
    question8 = 46,
    question9 = 47,
    // Economic & Financial Profile question
    question10 = 48,
    question11 = 49,
    question12 = 50,
    question13 = 51,
    question14 = 52,
    question15 = 53,
    question23 = 60,

    question19 = 54,
    question20 = 55,
    question21 = 56,
    question22 = 57,
    ip_address = 59,
    incorporation_country_id = 62,
    target_market_classification_id = 63,
    identity_number = 65, // ID
    driving_license = 66,
    passport = 67,
}
