import Vue from 'vue';
import { SearchLiveModule } from '~/store/modules/search-live/search-live';
import { IDefaultConfigValue } from '~/store/modules/configs/interfaces/configs-interface';

export default Vue.extend({
    props: {
        addClass: {
            type: String,
        },
        type: {
            type: String,
        },
        column: {
            type: String,
        },
    },
    data () {
        return {
            selectedItem: null,
            searchResult: [],
        };
    },
    computed: {
        allBindings () {
            return { ...this.$props, ...this.$attrs };
        },
    },
    mounted (): void {
        this.$nextTick(() => {
            if (this.allBindings.value) {
                this.generateValue();
            }
        });
    },
    methods: {
        generateValue () {
            this.selectedItem = {
                id: 0,
                value: this.allBindings.value,
            };
        },
        async fetchList (currentQuery: string): Promise<IDefaultConfigValue[]> {
            if (!currentQuery) return [];
            const allQuery = this.$route.query;
            allQuery[this.column] = currentQuery;

            return await SearchLiveModule.searchLive({
                type: this.type,
                query: allQuery,
                column: this.column,
            });
        },
        async handlerSearch (event: string) {
            this.searchResult = await this.fetchList(event);
        },
        selectItem (event: IDefaultConfigValue) {
            this.$emit('selectItem', event.value);
        },
        clearSearch () {
            this.selectedItem = null;
            this.$emit('selectItem', null);
        }
    }
});
