import { ITablePerPageOptions } from '~/settings/interfaces/table-per-page-options.iterfaces';
import { PaginationEnum } from '~/settings/enums/pagination.enum';

export const tablePerPageOptionsName = (value: number): string => {
    return `View ${value} rows`;
};
export const tablePerPageDefaultOption: ITablePerPageOptions = {
    name: tablePerPageOptionsName(PaginationEnum.defaultPerPage),
    value: PaginationEnum.defaultPerPage,
};
export const tablePerPageOptions: ITablePerPageOptions[] = [
    {
        name: tablePerPageOptionsName(25),
        value: 25,
    },
    {
        name: tablePerPageOptionsName(50),
        value: 50,
    },
    {
        name: tablePerPageOptionsName(100),
        value: 100,
    },
    {
        name: tablePerPageOptionsName(150),
        value: 150,
    }
];
