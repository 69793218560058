import Vue from 'vue';
import KycTabSubtitle from '@/js/components/KYC/KycTabSubtitle.vue';
import kycTabItemActionButtonsMixin from '@/js/components/KYC/KycTabItem/KycTabItemActionButtons/kyc-tab-item-action-buttons-mixin';
import toggleMixin from '@/js/components/mixins/toggleMixin';
import toggleHistoryMixin from '@/js/components/mixins/toggle-history-mixin';
import appHistoryKyc from '@/js/components/parts/app-history-kyc/app-history-kyc.vue';
import { ELeadsKycFieldStatus } from '~/settings/enums/leads-kyc-field-status.enum';
import clientKycActionsMixin from '@/js/components/mixins/clientKycActionsMixin';
import configsMixin from '~/components/mixins/configs-mixin';
import { IDefaultConfig } from '~/store/modules/configs/interfaces/configs-interface';
import { EStatusesColors } from '@/js/settings/enums/statuses-colors.enum';

export default Vue.extend({
    components: {
        KycTabSubtitle,
        appHistoryKyc,
    },
    mixins: [
        kycTabItemActionButtonsMixin,
        toggleMixin,
        toggleHistoryMixin,
        clientKycActionsMixin,
        configsMixin,
    ],
    props: {
        initial: {
            type: Object,
        },
        isSecondColumn: {
            type: Boolean,
        },
    },
    data () {
        return {
            currentStatus: null,
        };
    },
    computed: {
        title (): string {
            return this.initial?.field?.title || '';
        },
        createdDate (): string {
            return this.initial?.created_at?.date;
        },
        changeDate (): string {
            return this.initial?.status_at?.date || '-';
        },
        getStatusesList (): IDefaultConfig[] {
            return this.getConfigFieldValueStatuses
                .filter((item : IDefaultConfig) =>
                    item.id !== ELeadsKycFieldStatus.pending &&
                    item.id !== ELeadsKycFieldStatus.reviewed
                );
        },
        getCurrentStatus (): number {
            return this.currentStatus || this.status;
        },
        getStatusId (): number {
            return this.currentStatus?.id || this.status?.id;
        },
        getColorOption (): string {
            return `option-color--${EStatusesColors[ELeadsKycFieldStatus[this.getStatusId]]}`;
        },
        getUserName (): string {
            return this.initial?.status_by_user_full_name || '-';
        },
        fieldValue (): number {
            return this.initial?.id;
        },
    },
    methods: {
        edit (): void {
            this.show();
        },
        apply (): void {
            if (this.isPending) {
                this.clientEditApply(this.fieldValue);
            } else {
                if (this.getStatusId === ELeadsKycFieldStatus.rejected) {
                    this.clientEditCancel(this.fieldValue);
                } else {
                    this.clientEditApply(this.fieldValue, this.getStatusId);
                }
            }
        },
        clear (): void {
            if (this.isPending) {
                this.clientEditCancel(this.fieldValue);
            } else {
                this.currentStatus = null;
                this.hide();
            }
        },
        select (selectedOption: IDefaultConfig): void {
            this.currentStatus = selectedOption;
        },
        toggleProTraderForm (): void {
            this.$emit('toggleProTraderForm', this.initial.data[0].custom);
        },
        setProTraderFormText (proTraderFormText: string): void {
            this.$emit('setProTraderFormText', proTraderFormText);
        },
    },
});
