import { ERouterNameFromPermission } from '~/settings/enums/router-name.enum';

const routes = {
    main: {
        path: '/',
        name: 'main',
        meta: { requiresAuth: true },
    },
    dashboard: {
        path: '/dashboard',
        name: 'dashboard',
        meta: {
            requiresAuth: true,
            title: 'Dashboard'
        },
    },
    calendar: {
        path: '/calendar',
        name: ERouterNameFromPermission.calendar,
        meta: {
            requiresAuth: true,
            title: 'Calendar'
        },
    },
    leads: {
        path: '/leads',
        name: ERouterNameFromPermission.leads,
        meta: {
            requiresAuth: true,
            title: 'Leads'
        },
    },
    leadsItem: {
        path: '/leads/:id',
        _getPath: id => `/leads/${id}`,
        _getDetailsPath: id => `/leads/${id}/details`,
        name: ERouterNameFromPermission.leadsItem,
        meta: { requiresAuth: true, title: 'Leads' },
        subRoutes: {
            details: {
                path: '/leads/:id/details',
                name: ERouterNameFromPermission.leadsItemsDetails,
                meta: { requiresAuth: true, title: 'Leads', details: true },
                _getPath: id => `/leads/${id}/details`,
                props: true,
            },
            kyc: {
                path: '/leads/:id/kyc',
                name: ERouterNameFromPermission.leadsItemsKyc,
                meta: { requiresAuth: true, title: 'Leads', details: true },
                _getPath: id => `/leads/${id}/kyc`
            },
            trading: {
                path: '/leads/:id/trading',
                name: ERouterNameFromPermission.leadsItemsTrading,
                meta: { requiresAuth: true, title: 'Leads', details: true },
                _getPath: id => `/leads/${id}/trading`
            },
            finance: {
                path: '/leads/:id/finance',
                name: ERouterNameFromPermission.leadsItemsFinance,
                meta: { requiresAuth: true, title: 'Leads', details: true },
                _getPath: id => `/leads/${id}/finance`
            },
            marketing: {
                path: '/leads/:id/marketing',
                name: ERouterNameFromPermission.leadsItemsMarketing,
                meta: { requiresAuth: true, title: 'Leads', details: true },
                _getPath: id => `/leads/${id}/marketing`
            },
            activity: {
                path: '/leads/:id/activity',
                name: ERouterNameFromPermission.leadsItemsActivity,
                meta: { requiresAuth: true, title: 'Leads', details: true },
                _getPath: id => `/leads/${id}/activity`
            },
        },
    },
    leadsFiltersTemplates: {
        path: '/filters-templates',
        name: ERouterNameFromPermission.leadsItemsFiltersTemplates,
        meta: { requiresAuth: true, title: 'Leads' },
        _getPath: () => '/filters-templates/'
    },
    affiliatesItem: {
        path: '/affiliates',
        name: ERouterNameFromPermission.affiliates,
        meta: {
            requiresAuth: true,
            title: 'Affiliates'
        },
        subRoutes: {
            affiliates: {
                path: '/affiliates/affiliates',
                name: ERouterNameFromPermission.affiliatesPage,
                meta: { requiresAuth: true, title: 'Affiliates' }
            },
            reports: {
                path: '/affiliates/reports',
                name: ERouterNameFromPermission.affiliatesReports,
                meta: { requiresAuth: true, title: 'Affiliates' }
            },
            compliance: {
                path: '/affiliates/compliance',
                name: ERouterNameFromPermission.affiliatesCompliance,
                meta: { requiresAuth: true, title: 'Affiliates' }
            },
            bonus: {
                path: '/affiliates/bonus-plan',
                name: ERouterNameFromPermission.affiliatesBonusPlan,
                meta: { requiresAuth: true, title: 'Affiliates' },
                _getPath: () => '/affiliates/bonus-plan'
            },
            campaigns: {
                path: '/affiliates/campaigns',
                name: ERouterNameFromPermission.affiliatesCampaigns,
                meta: { requiresAuth: true, title: 'Affiliates' }
            }
        }
    },
    accounting: {
        path: '/accounting',
        name: ERouterNameFromPermission.accounting,
        meta: { requiresAuth: true, title: 'Accounting' },
        subRoutes: {
            reports: {
                path: '/accounting/reports',
                name: ERouterNameFromPermission.accountingReports,
                meta: { requiresAuth: true, title: 'Accounting' }
            },
            commission: {
                path: '/accounting/commission-database',
                name: ERouterNameFromPermission.commissionDatabase,
                meta: { requiresAuth: true, title: 'Accounting' }
            },
            managers: {
                path: '/accounting/managers',
                name: 'managers',
                meta: { requiresAuth: true, title: 'Accounting' }
            },
            bonus: {
                path: '/accounting/bonus-plan',
                name: ERouterNameFromPermission.bonusPlan,
                meta: { requiresAuth: true, title: 'Accounting' }
            },
            employees: {
                path: '/accounting/employees',
                name: 'employees',
                meta: { requiresAuth: true, title: 'Accounting' }
            },
        }
    },
    emails: {
        path: '/emails',
        name: ERouterNameFromPermission.emails,
        meta: { requiresAuth: true, title: 'Emails' },
        subRoutes: {
            mass: {
                path: '/emails/mass',
                name: ERouterNameFromPermission.emailsMass,
                meta: { requiresAuth: true, title: 'Emails' }
            },
            individual: {
                path: '/emails/individual',
                name: ERouterNameFromPermission.emailsIndividual,
                meta: { requiresAuth: true, title: 'Emails' }
            },
            inbox: {
                path: '/emails/inbox',
                name: ERouterNameFromPermission.emailsInbox,
                meta: { requiresAuth: true, title: 'Emails' }
            },
            liveChat: {
                path: '/emails/chat',
                name: ERouterNameFromPermission.emailsChat,
                meta: { requiresAuth: true, title: 'Emails' }
            },
            templates: {
                path: '/emails/templates',
                name: ERouterNameFromPermission.emailsTemplates,
                meta: { requiresAuth: true, title: 'Emails' }
            },
        }
    },
    affiliatesMarketing: {
        path: '/affiliates/statistics',
        name: 'statistics',
        meta: { requiresAuth: true, title: 'Affiliates' }
    },
    withdrawals: {
        path: '/finance',
        name: ERouterNameFromPermission.withdrawals,
        meta: { requiresAuth: true, title: 'Finance' },
    },
    notifications: {
        path: '/notifications',
        name: ERouterNameFromPermission.notifications,
        meta: { requiresAuth: true },
        subRoutes: {
            clients: {
                path: '/notifications',
                name: ERouterNameFromPermission.notificationsClients,
                meta: { requiresAuth: true },
            },
            activityLog: {
                path: '/notifications/activity-log',
                name: ERouterNameFromPermission.notificationsActivityLog,
                meta: { requiresAuth: true },
            },
        }
    },
    users : {
        path : '/users',
        name : ERouterNameFromPermission.users,
        meta: { requiresAuth: true, title: 'Users' },
    },
    reports : {
        path : '/reports',
        name : ERouterNameFromPermission.reports,
        meta: { requiresAuth: true, title: 'Reports' },
    },
    settings: {
        path: '/settings',
        name: ERouterNameFromPermission.settings,
        meta: { requiresAuth: true, title: 'Settings' },
        subRoutes: {
            group: {
                path: '/settings/group',
                name: ERouterNameFromPermission.settingsGroup,
                meta: { requiresAuth: true, title: 'Settings' },
            },
            documents: {
                path: '/settings/documents',
                name: ERouterNameFromPermission.documentsComponent,
                meta: { requiresAuth: true, title: 'Settings' },
            },
            symbols: {
                path: '/settings/symbols',
                name: ERouterNameFromPermission.settingsSymbols,
                meta: { requiresAuth: true, title: 'Symbols' },
            },
        }
    },
    login: {
        path: '/login',
        name: 'login',
        meta: { requiresAuth: false },
    },
    nothingFound: {
        path: '*',
        name: 'nothingFound',
        meta: { requiresAuth: false },
    },
    unauthorizedAccess: {
        path: '/unauthorized-access',
        name: ERouterNameFromPermission.unauthorizedAccess,
        meta: { requiresAuth: true },
    },
};

export default routes;
