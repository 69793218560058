import Vue from 'vue';
import configsMixin from '~/components/mixins/configs-mixin';
import { ICountries } from '~/store/modules/configs/interfaces/configs-interface';

export default Vue.extend({
    mixins: [
        configsMixin,
    ],
    props: {
        bonusPlanCountries: {
            type: Array,
            default: () => {
                return [];
            },
        },
        isEdit: {
            type: Boolean,
        },
        currencyType: {
            type: Object,
        },
        bonusPlanCountryOther: {
            type: Number,
        },
        preSetOne: {
            type: Boolean,
            default: false,
        },
        heightListCountrySelect: {
            type: Number,
            default: 180,
        }
    },
    data () {
        return {
            bonusPlanCountriesToEdit: [],
            otherCountryValue: null,
            countriesOptions: {},
        };
    },
    computed: {
        isCurrencyType (): string {
            return this.currencyType?.sign;
        },

    },
    watch: {
        bonusPlanCountries: {
            immediate: true,
            deep: true,
            handler () {
                this.setObj();
                this.setOtherCountry();
            }
        },
        isEdit (payload: boolean) {
            if (!payload) this.clearCountriesList();
        }
    },
    created (): void {
        this.handlePreSetOne();
    },
    methods: {
        handlePreSetOne (): void {
            if (this.preSetOne) {
                this.newItem();
            }
        },
        setCountiesOptions (id: number) {
            this.countriesOptions = {};
            this.countriesOptions[id] = this.getCountries();
        },
        getCountries (): ICountries[] {
            const countries = [...this.getConfigCountries];
            this.bonusPlanCountriesToEdit.forEach(currentCountry => {
                const index = countries
                    .findIndex(item => currentCountry.country?.id === item.id);
                this.$delete(countries, index);
            });
            return countries;
        },
        clearCountriesList () {
            this.bonusPlanCountriesToEdit = this.bonusPlanCountries
                .map(item => {
                    return item.value ? Object.assign({}, item) : false;
                });
            this.countriesOptions = [];
        },
        setObj () {
            this.bonusPlanCountriesToEdit = this.bonusPlanCountries ?
                this.bonusPlanCountries.map(item => Object.assign({}, item)) :
                [];
            this.bonusPlanCountriesToEdit.sort((a, b) => a.country.id - b.country.id);
        },
        setOtherCountry () {
            this.otherCountryValue = this.bonusPlanCountryOther;
        },
        newItem (): void {
            this.bonusPlanCountriesToEdit = [
                {
                    country: null,
                    value: null,
                },
                ...this.bonusPlanCountriesToEdit,
            ];
        },
        removeItem (index: number): void {
            this.$delete(this.bonusPlanCountriesToEdit, index);
        }
    },
});
