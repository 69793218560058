// Imports
import Vue from 'vue';
import {
    tablePerPageOptions,
    tablePerPageDefaultOption,
} from '~/settings/table-per-page-options';

// Export
export default Vue.extend({
    data () {
        return {
            perPage: 3,
            currentPage: 1,
            perPageM: tablePerPageDefaultOption,
            perPagesOptions: tablePerPageOptions,
        };
    },
});
