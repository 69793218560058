import {
    IConfigItem,
    ICountries,
    ILeadsConfigs,
    ILeverages,
} from '~/store/modules/configs/interfaces/configs-interface';
import { EConfigsName } from '~/store/modules/configs/enums/configs-name.enum';
import { EConfigType } from '~/store/modules/configs/enums/config-type.enum';
import { formatHelper } from '~/components/leadsItem/leads-item-trading/format-helper';
import { TradingSumInfoEnums } from '~/store/modules/kyc-trading/kyc-trading.enums';
import { leadsFiltersConfigHelper } from './leads-filters-config-helper';

export const handlerConfigItemDate = (
    configName: string,
    payload: IConfigItem[] | ILeadsConfigs
): IConfigItem[] | ILeadsConfigs => {
    let _data = null;
    switch (configName) {
    case EConfigsName.countries:
        _data = (payload as ICountries[]).map(item => ({
            id: item.id,
            name: item.name,
            phone_code: item.phone,
            A2: typeof item.A2 === EConfigType.string ? item.A2.toUpperCase() : '',
            A3: item.A3,
            blacklisted: item.blacklisted,
            // image : typeof item.A2 === EConfigType.string ? getCountryFlag(item.A2) : '',
            getDescription () {
                return `${item.name} +${item.phone}`;
            },
            toString () {
                return `+${item.phone}`;
            },
        }));
        break;
    case EConfigsName.leverages:
        _data = (payload as ILeverages[]).map(item => {
            return {
                ...item,
                id: item.value,
                formatted: formatHelper(TradingSumInfoEnums.leverage, item.value),
            };
        });
        break;
    case EConfigsName.leadsFiltersConfig:
        _data = leadsFiltersConfigHelper(payload as ILeadsConfigs);
        break;
    default:
        _data = payload;
    }
    return _data;
};
