import Vue from 'vue';
import {
    getStringFromFieldValueData,
    getDataFromFieldValue
} from '~/components/KYC/kyc-helpers';

export default Vue.extend({
    props: {
        firstName: {
            type: Object,
        },
        middleName: {
            type: Object,
        },
        lastName: {
            type: Object,
        },
        email: {
            type: String,
        },
    },
    computed: {
        getFullName (): string {
            if (
                getDataFromFieldValue(this.firstName)
                    || getDataFromFieldValue(this.middleName)
                    || getDataFromFieldValue(this.lastName)
            ) {
                return `${getStringFromFieldValueData(this.firstName)} ${getStringFromFieldValueData(this.middleName)} ${getStringFromFieldValueData(this.lastName)}`;
            }

            return 'N/A';
        },
    },
});
