import Vue from 'vue';
import navbarAffiliates from '~/components/affiliates/affiliates-nav/affiliates-nav.vue';
import affiliatesHeader from '~/components/_tabs-headers/affiliates-header-tabs/affiliates-header-tabs.vue';
import affiliatesBonusPlanTabs from './affiliates-bonus-plan-tabs/affiliates-bonus-plan-tabs.vue';
import { IBonusPlan } from '~/store/modules/bonus-plan/interfaces/bonus-plan-interfaces';
import editBonusPlanComponent from './edit-bonus-plan/edit-bonus-plan.vue';
import { AffiliatesBonusPlansModule } from '~/store/modules/affiliates/affiliates-bonus-plans';
import { BonusPlanModule } from '~/store/modules/bonus-plan/bonus-plan';

export default Vue.extend({
    components: {
        navbarAffiliates,
        affiliatesHeader,
        affiliatesBonusPlanTabs,
        editBonusPlanComponent,
    },
    data (): Object {
        return {
            className: 'affiliates-bonus-plan-categories',
            isLoading: false,
        };
    },
    computed: {
        isActiveBonusPlan (): IBonusPlan {
            return AffiliatesBonusPlansModule.getActiveBonusPlanObj;
        },
    },
    mounted (): void {
        this.loadBonusPlan();
    },
    methods: {
        async loadBonusPlan () {
            await BonusPlanModule.loadBonusPlan()
                .then(() => {
                    AffiliatesBonusPlansModule.setActiveFirstBonusPlan();
                });
        },
    }
});
