import { ITableHeaderColumn } from '~/components/tables/interfaces/table-header-column.interfaces';

export const leadsCryptoTableHeaders: ITableHeaderColumn[] = [
    {
        id: 1,
        name: 'wallet',
        value: 'Crypto Wallet Address',
        cellType: 'plain-text',
        cellData: 'crypto_wallet',
    },
    {
        id: 2,
        name: 'currency',
        value: 'Currency',
        cellType: 'key-from-object',
        cellDataKey: 'name',
        cellData: 'currency',
        textAlign: 'center',
    },
    {
        id: 3,
        name: 'status',
        value: 'Status',
        cellType: 'payment-status',
        cellData: 'status_id',
    },
    {
        id: 4,
        name: 'call',
        value: '1k CC',
        cellType: 'confirmation-call',
        cellData: 'call_confirmed',
        params: {
            iconSymbol: 'info',
            iconTitle: '1k confirmation call'
        }
    },
    {
        id: 5,
        name: 'owner',
        value: 'Crypto Wallet Owner',
        cellType: 'plain-text',
        cellData: 'owner_name',
    },
    {
        id: 6,
        name: 'date',
        value: 'Date & Time	',
        cellType: 'date-time-cell',
        cellData: 'created_at',
        textAlign: 'center',
    },
    {
        id: 7,
        name: 'ip',
        value: 'IP Address	',
        cellType: 'ip-address',
        cellData: 'ip',
    },
];
