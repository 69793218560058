export enum ECalendarQuery {
    calendarType = 'calendar_type',
    startDate = 'start_date',
    endDate = 'finish_date',
    created_at = 'created_at',
    updated_at = 'updated_at',
    created_at_from = 'created_at_from',
    created_at_to = 'created_at_to',
    updated_at_from = 'updated_at_from',
    updated_at_to = 'updated_at_to',
}
