export enum NotificationsEnum {
    TYPE_ALL = '',
    TYPE_REGISTER = '1',
    TYPE_KYC = '2',
    TYPE_FUNDING = '3',
    ACTIVITY_LOG = 'ACTIVITY_LOG',
    TYPE_AFFILIATES = '4',
}

export enum EStatusNotification {
    New = 1,
    Pending = 2,
    Read = 3
}

export enum EClassNotification {
    Unread = 'unread',
    Read = 'read',
}

export enum EClassNotificationByStatus {
    unread = 1,
    'unread-pending' = 2,
    read = 3,
}

export enum NotificationsSubTitleEnum {
    Unread = 'Unread'
}

export enum NotificationsKeysEnum {
    TypeId = 'type_id',
    Search = 'search'
}

export enum NumbersNotificationEnum {
    Zero = 0
}

export enum ENotificationsTableHeaderType {
    default = 0,
    affiliates = 4,
}
