import { IDashboardSummaryStatisticItem } from '~/store/modules/dashboard/interfaces';
import { ESassColor } from '~/settings/enums/sass-color.enum';

export default {
    methods: {
        percentageArrow (summary: IDashboardSummaryStatisticItem): string {
            if (!summary) return '';
            if (summary.percentage > 0) {
                return 'arrow_upward';
            } else if (summary.percentage < 0) {
                return 'arrow_downward';
            }
            return '';
        },
        percentageArrowColor (summary: IDashboardSummaryStatisticItem): string {
            if (!summary) return '';
            if (summary.percentage > 0) {
                return ESassColor.green;
            } else if (summary.percentage < 0) {
                return ESassColor.red;
            }
            return '';
        },
        percentageStatus (summary: IDashboardSummaryStatisticItem): string {
            if (!summary) return '';
            if (summary.percentage > 0) {
                return 'positive';
            } else if (summary.percentage < 0) {
                return 'negative';
            }
            return '';
        },
    }
};
