import { convertStringToArray } from '../convert-string-to-array';
import { ETableFilterKeySymbols } from '~/components/tables/enums/table-filter-key.enum';

export const handlerSymbols = (inputs: object): object => {
    for (const input in inputs) {
        switch (input) {

        case ETableFilterKeySymbols.countries:
        case ETableFilterKeySymbols.trading_types:
        case ETableFilterKeySymbols.exchanges:
        case ETableFilterKeySymbols.currencies_base:
        case ETableFilterKeySymbols.sectors:
        case ETableFilterKeySymbols.industries:
        case ETableFilterKeySymbols.products:
            if (inputs[input]) inputs[input] = convertStringToArray(inputs[input]);
            break;
        default:
            break;
        }
    }
    return inputs;
};
