import Vue from 'vue';
import AttachmentsItem from './attachments-item/attachments-item.vue';
import configsMixin from '~/components/mixins/configs-mixin';

export default Vue.extend({
    components: {
        AttachmentsItem
    },
    mixins: [
        configsMixin,
    ],
    data () {
        return {
            activeTab: 0,
        };
    },
    computed: {
        filesTabs (): Array<any> {
            return this.getConfigDocumentation;
        }
    },
    watch: {
        activeTab: function () {
            setTimeout(() => {
                this.$refs.attachmentScroll.calculateSize();
                this.$refs.attachmentScroll.scrollToX(0);
            }, 0);
        }
    },
    methods: {
        changeDragging (event: boolean): void {
            this.$emit('changeDragging', event);
        }
    }
});
