import {
    IBonusPlan,
    IBonusPlanUpdate
} from '~/store/modules/bonus-plan/interfaces/bonus-plan-interfaces';
import { EBonusPlanType } from '~/settings/enums/bonus-plan-type.enum';
import { bonusPlanFormattedCpa } from './bonus-plan-formatted-cpa';
import { bonusPlanFormattedCpl } from './bonus-plan-formatted-cpl';
import { bonusPlanFormattedCryptoRebate } from './bonus-plan-formatted-cryptorebate';
import { bonusPlanFormattedHybrid } from './bonus-plan-formatted-hybrid';
import { bonusPlanFormattedRevShare } from './bonus-plan-formatted-revshare';

export const bonusPlanFormatted = (payload: IBonusPlan): IBonusPlanUpdate => {
    switch (payload.bonus_type.id) {
    case EBonusPlanType.cpa:
        return bonusPlanFormattedCpa(payload);
    case EBonusPlanType.cpl:
        return bonusPlanFormattedCpl(payload);
    case EBonusPlanType.cryptoRebate:
        return bonusPlanFormattedCryptoRebate(payload);
    case EBonusPlanType.hybrid:
        return bonusPlanFormattedHybrid(payload);
    case EBonusPlanType.revShare:
        return bonusPlanFormattedRevShare(payload);
    }
};

