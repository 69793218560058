import Chart from 'chart.js';
import {
    IDashboardSummaryStatisticType,
} from '~/store/modules/dashboard/interfaces';
import { EChartType } from '~/settings/enums/chart-type.enum';
import { chartColorScheme } from '~/settings/chart-color-scheme';
import { formatPrice } from '~/settings/format-price';
import { cutThousandToK } from '~/helpers/format';

export default {
    data (): object {
        return {
            chartType: EChartType,
            colors: chartColorScheme,
            options: {
                bar: {
                    legend: {
                        display: false,
                    },
                    maintainAspectRatio: false,
                    scales: {
                        display: false,
                        xAxes: [
                            {
                                gridLines: {
                                    // display: false,
                                    drawOnChartArea: false,
                                    zeroLineWidth: 0,
                                    zeroLineColor: 'transparent',
                                    tickMarkLength: 2,
                                    color: '#a1b3c5',
                                    offsetGridLines: false,
                                },
                                ticks: {
                                    beginAtZero: true,
                                    fontSize: 9,
                                    fontColor: '#a1b3c5',
                                    padding: 5,
                                },
                            },
                        ],
                        yAxes: [
                            {
                                gridLines: {
                                    drawOnChartArea: false,
                                    tickMarkLength: 2,
                                    color: '#a1b3c5',
                                },
                                ticks: {
                                    beginAtZero: true,
                                    padding: 5,
                                    fontSize: 8,
                                    fontColor: '#a1b3c5',
                                },
                            },
                        ],
                    },
                },
                doughnut: {
                    cutoutPercentage: 70,
                    maintainAspectRatio: false,
                    legend: {
                        display: false,
                    },
                    scales: {
                        display: false,
                        xAxes: [
                            {
                                display: false,
                            },
                        ],
                        yAxes: [
                            {
                                display: false,
                            },
                        ],
                    },
                },
                line: {
                    legend: {
                        display: false,
                    },
                    maintainAspectRatio: false,
                    tooltips: {
                        callbacks: {
                            label: (label) => {
                                return formatPrice(label.yLabel, true);
                            },
                        },
                    },
                    scales: {
                        display: false,
                        xAxes: [
                            {
                                gridLines: {
                                    drawOnChartArea: false,
                                    zeroLineWidth: 0,
                                    zeroLineColor: 'transparent',
                                    tickMarkLength: 2,
                                    color: '#a1b3c5',
                                    offsetGridLines: false,
                                },
                                ticks: {
                                    beginAtZero: true,
                                    fontSize: 9,
                                    fontColor: '#a1b3c5',
                                    padding: 5,
                                },
                            },
                        ],
                        yAxes: [
                            {
                                gridLines: {
                                    drawOnChartArea: false,
                                    tickMarkLength: 2,
                                    color: '#a1b3c5',
                                },
                                ticks: {
                                    beginAtZero: true,
                                    padding: 5,
                                    fontSize: 8,
                                    fontColor: '#a1b3c5',
                                    callback: (value) => {
                                        return cutThousandToK(value);
                                    },
                                },
                            },
                        ],
                    },
                }
            },
            datasets: {
                bar: {
                    barThickness: '5',
                    backgroundColor: '#7cd346',
                },
                doughnut: {
                    backgroundColor: chartColorScheme,
                    borderColor: chartColorScheme,
                    borderWidth: 1
                },
                line: {
                    lineTension: 0,
                    backgroundColor: '#8db5d0',
                    borderColor: 'transparent',
                    pointRadius: 0,
                    pointbackgroundcolor: 'transparent',
                    pointbordercolor: 'transparent',
                },
            },
            chart: null,
        };
    },
    methods: {
        initChart (
            name: string,
            type: string,
            label: string,
            datasetOptions?: object
        ): void {
            const _dataSets = {
                ...this.datasets[type],
                ...datasetOptions,
                label: label,
                data: []
            };
            this.chart = new Chart(
                document.getElementById(name),
                {
                    type: type,
                    options: this.options[type],
                    data: {
                        labels: [],
                        datasets: [
                            _dataSets,
                        ]
                    },
                }
            );
        },
        updateChart (
            payload: IDashboardSummaryStatisticType[],
            dataKey: string,
            labelKey: string
        ): void {
            this.chart.data.datasets[0].data = payload.map(item => item[dataKey]);
            this.chart.data.labels = payload.map(item => item[labelKey]);
            this.chart.update();
        }
    },
};
