import { ORDERING_ASC } from '~/settings/constants';
import Rest from '~/helpers/Rest';
import { urlLeads, urlLeadsExports, urlClients } from '~/settings/ajax-urls';
import router from '~/router';
import { getDefaultLoad, getStdDefaultState } from './_stdModuleUtils';
import { ELeadsExport } from '~/settings/enums/leads.enum';
import { leadsTableHeader } from '~/components/tables/_headers/leads';
import { handlerRestResponse } from '~/helpers/handler-rest-response';
import { handlerQueriesToFetch } from '~/helpers/handlers-queries-to-fetch/handler-queries-to-fetch';
import { NotificationPrefabricated } from '~/helpers/modal-notification-services/modal-notification-services';
import { handlerRoutesQuery } from '~/helpers/handler-routes-query';
import { EAccountStatus } from '~/settings/enums/account-status.enum';
import { ETableType } from '~/components/tables/enums/table-type.enum';
import { showNothingWasFound } from '@/js/components/tables/helpers/showNothingWasFound';
import { EComplicatedness } from '~/settings/enums/complicatedness.enum';
import { PaginationEnum } from '~/settings/enums/pagination.enum';
import { ELeadsFilterSelect } from '~/settings/enums/leads.enum';

/**
* Constants
* */
export const moduleName = 'leads';

const ORDER_DEFAULT = ORDERING_ASC;

export const getterLoad = `${moduleName}/load`;
export const getterEntity = `${moduleName}/entity`;
export const getterPagination = `${moduleName}/pagination`;
export const getterSelectedColumnsInFilter = `${moduleName}/selectedColumnsInFilter`;
export const getterColumnsInFilter = `${moduleName}/columnsInFilter`;
export const getterSelectedColumnsInTable = `${moduleName}/selectedColumnsInTable`;
export const getterUpdatedTableRows = `${moduleName}/updatedTableRows`;
export const getterLeadsVerifyStatusesFilters = `${moduleName}/leadsVerifyStatusesFilters`;

export const mutationSetLoad = `${moduleName}/setLoad`;
export const mutationSetEntities = `${moduleName}/setEntities`;
export const mutationSetPagination = `${moduleName}/setPagination`;
export const mutationSelectedColumns = `${moduleName}/selectedColumns`;
export const mutationAllRowsSelection = `${moduleName}/allRowsSelection`;
export const mutationSetLeadsVerifyStatusesFilters = `${moduleName}/setLeadsVerifyStatusesFilters`;

export const actionHandleFetchleads = `${moduleName}/handleFetchleads`;
export const actionClearleads = `${moduleName}/clearleads`;
export const actionHandleClear = `${moduleName}/handleClear`;
export const actionHandleSetFilters = `${moduleName}/handleSetFilters`;
export const actionHandleSelectedColumns = `${moduleName}/handleSelectedColumns`;
export const actionHandleCleanSelectedColumns = `${moduleName}/handleCleanSelectedColumns`;
export const actionExportSelectedRows = `${moduleName}/exportSelectedRows`;
export const actionHandleCreateLead = `${moduleName}/handleCreateLead`;

const defaultLoad = getDefaultLoad();

const defaultPagination = { current_page: null, total: null, per_page: null };
const defaultQuery = {
    orderBy: 'id',
    order: ORDER_DEFAULT,
    page: 1,
    per_page: PaginationEnum.defaultPerPage,
};

// TODO
const GET_SELECTED_ROWS = 'RootTable/getSelectedRows';

/**
 * Module
 * */
export default {
    state: () => {
        return {
            ...getStdDefaultState(),
            pagination: { ...defaultPagination },
            selectedColumns: [],
            leadsVerifyStatusesFilters: {},
            columns: leadsTableHeader,
            createLead: [],
        };
    },
    getters: {
        [getterLeadsVerifyStatusesFilters]: (state) => {
            return state.leadsVerifyStatusesFilters;
        },
        [getterUpdatedTableRows]: (state) => {
            return Object.values(state.entities).length ?
                state.entities : [];
        },
        [getterLoad]: ({ isLoading, isLoaded }) => ({ isLoading, isLoaded }),
        [getterEntity]: (state) => id => state.entities.find(item => item.id === id),
        [getterPagination]: (state) => state.pagination,
        [getterSelectedColumnsInFilter]: state => {
            const selectedNames = state.selectedColumns.map(column => column.name);
            return state.columns.filter(column => selectedNames.includes(column.name));
        },
        [getterSelectedColumnsInTable]: (state, rootGetters) => {
            if (!state.selectedColumns.length) return state.columns;
            return rootGetters[getterSelectedColumnsInFilter];
        },
        [getterColumnsInFilter]: (state) => {
            return state.columns;
        }
    },
    mutations: {
        [mutationSetLoad] (state, { isLoading, isLoaded, errorLoad }) {
            if (typeof isLoading !== 'undefined') state.isLoading = isLoading;
            if (typeof isLoaded !== 'undefined') state.isLoaded = isLoaded;
            if (typeof errorLoad !== 'undefined') state.errorLoad = errorLoad;
        },
        [mutationSetEntities] (state, { entities = [] }) {
            state.entities = entities;
        },
        [mutationSetPagination] (state, { current_page, total, per_page }) {
            if (typeof current_page !== 'undefined') state.pagination.current_page = current_page;
            if (typeof total !== 'undefined') state.pagination.total = total;
            if (typeof per_page !== 'undefined') state.pagination.per_page = +per_page;
        },
        [mutationSelectedColumns] (state, columns) {
            state.selectedColumns = columns;
        },
        [mutationSetLeadsVerifyStatusesFilters] (state, filters) {
            const verifiedFilters = filters.filter(filter => filter.verify_status_id <= EAccountStatus.verified);
            const unverifiedFilters = filters.filter(filter => filter.verify_status_id > EAccountStatus.verified);
            const totalClosed = {
                verify_status_id: [],
                percentage: 0,
                name: 'Total Closed',
            };

            state.leadsVerifyStatusesFilters = {
                buttons: verifiedFilters.sort((a, b) => b.verify_status_id - a.verify_status_id),
                dropdown: unverifiedFilters,
            };
            unverifiedFilters.forEach(filter => {
                totalClosed.verify_status_id.push(filter.verify_status_id);
                totalClosed.percentage += filter.percentage;
            });
            state.leadsVerifyStatusesFilters.dropdown.unshift(totalClosed);
        },
    },
    actions: {
        [actionHandleFetchleads] ({ state, commit }, query) {
            commit(
                mutationSetLoad,
                { isLoading: true, isLoaded: false, errorLoad: '' }
            );
            // TODO
            const paginationQuery = { ...defaultQuery };

            if (query) {
                if (query.columns) delete query.columns;
                if (query.per_page) paginationQuery.per_page = query.per_page;
                if (query.page) paginationQuery.page = query.page;
                query = handlerQueriesToFetch(query, ETableType.leadsTable);
            }

            Rest.GET(urlLeads({
                ...paginationQuery,
                ...query,
            }))
                .then(Rest.middleThen)
                .then(({
                    data = [],
                    meta: { current_page, per_page, total } = {},
                    client_statuses_percentage = [],
                }) => {
                    data = data.map(lead => {
                        const {
                            mode_types,
                            mt_subject_types,
                            trading_types,
                        } = lead;

                        const modeTypes =  mode_types?.map(item => item.name);
                        const mtSubjectTypes =   mt_subject_types?.map(item => item.name);
                        const tradingTypes =  trading_types?.map(item => item.name);

                        return {
                            ...lead,
                            checked: false,
                            modeTypes: modeTypes,
                            mt_subject_types: mtSubjectTypes,
                            tradingTypes: tradingTypes,
                            is_test: lead.complicatedness.id === EComplicatedness.test,
                        };
                    });

                    commit(mutationSetEntities, { entities: data });
                    commit(mutationSetPagination, { current_page, per_page, total });
                    commit(mutationSetLoad, { isLoading: false, isLoaded: true, errorLoad: '' });
                    commit(mutationSetLeadsVerifyStatusesFilters, client_statuses_percentage);
                })
                .catch(error => {
                    commit(mutationSetLoad, { isLoading: false, isLoaded: false, errorLoad: error });
                    Rest.simpleCatch(error);
                })
                .finally(() => {
                    NotificationPrefabricated.cleanAllNotify();
                    showNothingWasFound(state.entities.length);
                });
        },
        [actionClearleads] ({ commit }) {
            commit(mutationSetEntities, { entities: [] });
            commit(mutationSetLoad, defaultLoad);
        },
        [actionHandleClear] ({ dispatch, commit }) {
            dispatch(actionClearleads);
            commit(mutationSetPagination, { ...defaultPagination });
        },
        [actionHandleSetFilters] ({ commit }) {
            const { query } = router.currentRoute;
            const columns = query[ELeadsFilterSelect.key];
            if (columns) {
                const resultColumns = columns.split(',').map(name => ({ name }));
                commit(mutationSelectedColumns, resultColumns);
            }
        },
        [actionHandleSelectedColumns] ({ commit }, filters) {
            commit(mutationSelectedColumns, filters);
            const columns = filters?.map(filter => filter.name);
            handlerRoutesQuery(ELeadsFilterSelect.key, columns);
        },
        [actionHandleCleanSelectedColumns] ({ commit }) {
            commit(mutationSelectedColumns, []);
            handlerRoutesQuery(ELeadsFilterSelect.key, '');
        },
        [actionExportSelectedRows] ({ rootGetters }) {
            const checkedIds = rootGetters[GET_SELECTED_ROWS].map(el => Number.parseInt(el));
            const selectedColumns = rootGetters[getterSelectedColumnsInTable].slice(2).map(el => el.name);
            Rest.POST(urlLeadsExports, {
                ids: checkedIds,
                columns: selectedColumns
            })
                .then(response => {
                    if (response.status >= 400) {
                        throw new Error(response.statusText);
                    }
                    return response.blob();
                })
                .then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = ELeadsExport.FileName;
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                })
                .catch(error => {
                    Rest.simpleCatch(error);
                });
        },
        [actionHandleCreateLead] (context, params) {
            Rest.POST(urlClients, params)
                .then(response => {
                    return handlerRestResponse(response, NotificationPrefabricated.LeadCreatedSuccess);
                })
                .catch(Rest.simpleCatch);
        },
    }
};
