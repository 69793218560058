import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '~/store/index';
import Rest from '~/helpers/Rest';
import { urlNotes } from '~/settings/ajax-urls';
import {
    INotesSavePayload,
    INotesGroup,
} from '~/store/modules/notes/notes-interfaces';
import { handlerGroupByDepartment } from './helpers/handler-group-by-department';

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: 'Notes',
})

class Notes extends VuexModule {
    notes: INotesGroup = {};

    get getNotes (): INotesGroup {
        return this.notes;
    }

    @Mutation
    setNotes (payload: INotesGroup) {
        this.notes = payload;
    }

    @Action({ commit: 'setNotes', rawError: true })
    async loadNotes (id: number): Promise<INotesGroup> {
        const { data } = await Rest.GET(urlNotes(id))
            .then(Rest.middleThen)
            .catch(Rest.simpleCatch);
        return handlerGroupByDepartment(data);
    }
    @Action
    async saveNote (payload: INotesSavePayload): Promise<void> {
        await Rest.POST(urlNotes(payload.id), {
            description: payload.description,
            department_id: payload.department_id,
        })
            .then(Rest.middleThen)
            .catch(Rest.simpleCatch);

    }
}
export const NotesModule = getModule(Notes);
