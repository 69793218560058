import Vue from 'vue';
import EditBtn from '~/components/leadsItem/LeadDetails/parts/editBtn.vue';
import EditModeBtns from '~/components/leadsItem/LeadDetails/parts/editModeBtns.vue';
import appMultiSelect from '~/components/parts/app-multiselect/app-multiselect.vue';
import toggleMixin from '~/components/mixins/toggleMixin';
import leadDetailsMixin from '~/components/mixins/leadDetailsMixin';
import loadingMixin from '~/components/mixins/loadingMixin';
import fieldModelMixin from '~/components/mixins/modelMixins/fieldModelMixin';
import configsMixin from '~/components/mixins/configs-mixin';
import { IDefaultConfig } from '~/store/modules/configs/interfaces/configs-interface';
import { NotificationPrefabricated } from '~/helpers/modal-notification-services/modal-notification-services';
import { isUserPermissionEditUsersClients } from '~/helpers/user-permission';

export default Vue.extend({
    components: {
        EditBtn,
        EditModeBtns,
        appMultiSelect
    },
    mixins: [
        toggleMixin,
        leadDetailsMixin,
        loadingMixin,
        fieldModelMixin,
        configsMixin
    ],
    props: {
        title: {
            required: true,
            type: String
        },
        groupType: {
            type: String
        },
        user: {
            type: [String, Object]
        }
    },
    data (): Object {
        return {
            selectedGrouptype: null
        };
    },
    computed: {
        isUserPermissionEditUsersClients,
        userStatus: {
            get (): IDefaultConfig {
                return this.selectedGrouptype || this.user;
            },
            set (value: IDefaultConfig): void {
                this.selectedGrouptype = value;
            },
        },
        group_type (): IDefaultConfig[] {
            return this.getConfigComplicatedness;
        }
    },
    methods: {
        clear () {
            this.clearSelected();
        },
        restore () {
            this.restoreSelected();
            this.hide();
        },
        confirm () {
            this.startLoading();
            this.hide();
            this.updateLeadDetails({
                [this.groupType]: this.selectedGrouptype.id
            })
                .catch(error => {
                    NotificationPrefabricated.Error(error);
                })
                .finally(() => {
                    this.endLoading();
                    NotificationPrefabricated.leadManagerEditSuccess(this.title);
                });
        },
        restoreSelected (): void {
            this.selectedGrouptype = null;
        },
        handleEditLeadGroup (): void {
            this.toggleOpen();
        }
    }
});
