<template>
    <div :style="{position: 'absolute', top : '0', left: '0', right: '0', bottom: '0', 'z-index' : '1', 'background-color' : 'rgba(255, 255, 255, 0.5)'}">
        <div :style="{display : 'flex', 'justify-content' : 'center', 'align-items' : 'center', height : '100%'}">
            <div class="animation-container">
                <svg
                    class="visible-lid lid-body"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 93.27 89.67"
                ><title>Lid_White</title><g
                    id="Layer_2"
                    data-name="Layer 2"
                ><g
                    id="Layer_1-2"
                    data-name="Layer 1"
                ><path
                    fill="#fff"
                    class="cls-1"
                    d="M4.44,89.67,93.27,37.44c-13.41-18.19-36.37-25.85-57-19.6L26.1,0,5.76,12,16,29.79C.38,44.82-4.48,68.84,4.44,89.67Z"
                /></g></g></svg>
                <svg
                    class="visible-lamp lamp-body"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 300.83 194.65"
                ><title>Body_White</title><g
                    id="Layer_2"
                    data-name="Layer 2"
                ><g
                    id="Layer_1-2"
                    data-name="Layer 1"
                ><path
                    fill="#fff"
                    class="cls-1"
                    d="M24.66,67.91h0C7.45,72.09-3.22,90,.88,107.82s21.43,28.93,38.65,24.75l32.79-7.95a80.21,80.21,0,0,0,48.39,18,60.77,60.77,0,0,1-18.17,47.64l1,4.4,78.46-19-1-4.4a58.72,58.72,0,0,1-36-31.86A80.19,80.19,0,0,0,182.77,115L216.3,76.63a155.22,155.22,0,0,1,80.55-49.17l4-27.46ZM35.25,114h0a13.68,13.68,0,0,1-16.42-10.52,14.11,14.11,0,0,1,10.1-17L44.5,82.73a84.91,84.91,0,0,0,12.7,25.93Z"
                /></g></g></svg>
                <svg
                    class="invisible-lid lid-body"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 93.27 89.67"
                ><title>Lid_White</title><g
                    id="Layer_2"
                    data-name="Layer 2"
                ><g
                    id="Layer_1-2"
                    data-name="Layer 1"
                ><path
                    fill="#fff"
                    class="cls-1"
                    d="M4.44,89.67,93.27,37.44c-13.41-18.19-36.37-25.85-57-19.6L26.1,0,5.76,12,16,29.79C.38,44.82-4.48,68.84,4.44,89.67Z"
                /></g></g></svg>
                <svg
                    class="invisible-lamp lamp-body"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 300.83 194.65"
                ><title>Body_White</title><g
                    id="Layer_2"
                    data-name="Layer 2"
                ><g
                    id="Layer_1-2"
                    data-name="Layer 1"
                ><path
                    fill="#fff"
                    class="cls-1"
                    d="M24.66,67.91h0C7.45,72.09-3.22,90,.88,107.82s21.43,28.93,38.65,24.75l32.79-7.95a80.21,80.21,0,0,0,48.39,18,60.77,60.77,0,0,1-18.17,47.64l1,4.4,78.46-19-1-4.4a58.72,58.72,0,0,1-36-31.86A80.19,80.19,0,0,0,182.77,115L216.3,76.63a155.22,155.22,0,0,1,80.55-49.17l4-27.46ZM35.25,114h0a13.68,13.68,0,0,1-16.42-10.52,14.11,14.11,0,0,1,10.1-17L44.5,82.73a84.91,84.91,0,0,0,12.7,25.93Z"
                /></g></g></svg>
            </div>
        </div>
    </div>
</template>

<script>
export default {

};
</script>
