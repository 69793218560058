import Vue from 'vue';
import modalLayout from '~/components/modals/root-modal-layout/root-modal-layout.vue';

import modalsMixin from '~/components/mixins/modals';
// @ts-ignore
import docNew from './doc-modal-new/doc-modal-new.vue';
// @ts-ignore
import docExist from './doc-modal-existing/doc-modal-existing.vue';
import { ModalsModule } from '~/store/modules/modals';
import { ComplianceUploadedDocumentsModule } from '~/store/modules/documents/uploadedDocuments';
import AppDocumentViewer from '~/components/parts/app-document-viewer/app-document-viewer.vue';
import { mapGetters } from 'vuex';
import { names } from '~/store/modules/client-kyc/client-kyc';
import { names as leadDetailsModule } from '~/store/modules/leadDetails';
import { DocModalStatusEnum } from './doc-modal.enum';
import {
    IAffiliatesDocumentUpdate,
    IAffiliatesDocumentUpdatePayload
} from '~/store/modules/affiliates/interfaces/affiliates-documents-interfaces';
import { UploadedDocumentsStatusIdEnum } from '~/components/uploaded-documents/uploaded-documents.enum';
import configsMixin from '~/components/mixins/configs-mixin';
import { NotificationPrefabricated } from '@/js/helpers/modal-notification-services/modal-notification-services';
import { EAffiliatesDocumentStatuses } from '~/settings/enums/affiliates-document-statuses.enum';
import { DocumentTypesSort } from '~/store/modules/documents/document-type-helper';

export default Vue.extend({
    components: {
        modalLayout,
        docNew,
        docExist,
        AppDocumentViewer,
    },
    mixins: [
        modalsMixin,
        configsMixin
    ],
    data (): Object {
        return {
            tempIsReview: null,
        };
    },
    computed: {
        ...mapGetters({
            clientKyc: names.getters.entity,
            leadDetails : leadDetailsModule.getters.entity,
        }),
        fullName (): string {
            return this.leadDetails.full_name;
        },
        isReview: {
            get (): boolean {
                if (this.tempIsReview !== null) {
                    return this.tempIsReview;
                }

                return this.modalData.status_id === DocModalStatusEnum.Pending;
            },
            set (isReview: boolean) {
                this.tempIsReview = isReview;
            }
        },
        statusId () {
            return this.modalData.status_id;
        },
        modalType () {
            return this.$attrs['modal-type'];
        },
        documentType () {
            const documentObject = this.modalData.type_id &&
                this.getConfigClientDocumentTypes.find(item => item.id === this.modalData.type_id);
            return documentObject ? documentObject.name : 'Other';
        },
        getClientDocumentTypes () {
            return DocumentTypesSort(this.getConfigClientDocumentTypes);

        },
    },
    methods: {
        closeModal () {
            ModalsModule.closeModal(this.modalType);
        },
        deleteDocument () {
            ComplianceUploadedDocumentsModule.deleteDocument(this.modalData.id);
            this.closeModal();
        },
        changeIsReview (isReview: boolean): void {
            this.isReview = isReview;
        },
        approveDocument (event: IAffiliatesDocumentUpdate) {
            const payload = {
                ...event,
                id: this.modalData.id,
                type_id: this.modalData.type_id,
                status_id: UploadedDocumentsStatusIdEnum.Verified,
            };
            this.updateDocument(payload);

        },
        rejectDocument (event: IAffiliatesDocumentUpdate) {
            const payload = {
                ...event,
                id: this.modalData.id,
                type_id: this.modalData.type_id,
                status_id: UploadedDocumentsStatusIdEnum.Rejected,
            };
            this.updateDocument(payload);
        },
        async changeDocument (event: IAffiliatesDocumentUpdate) {
            await this.updateDocument({
                id: this.modalData.id,
                ...event,
            })
                .then(() => {
                    ComplianceUploadedDocumentsModule.receiveUploadedDocuments({ leadId: this.$route.params.id });
                    NotificationPrefabricated.documentTypeChanged();
                });
        },
        chooseNotificationByStatus (status): void {
            switch (status.status_id) {
            case EAffiliatesDocumentStatuses.approved:
                NotificationPrefabricated.approvedDocumentsSuccess();
                break;

            case EAffiliatesDocumentStatuses.rejected:
                NotificationPrefabricated.rejectedDocumentsSuccess();
                break;
            }
        },
        async updateDocument (payload: IAffiliatesDocumentUpdatePayload) {
            await ComplianceUploadedDocumentsModule.updateDocument(payload)
                .then(() => {
                    this.closeModal();
                    this.chooseNotificationByStatus(payload);
                });
        }
    }
});
