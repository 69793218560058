import Vue from 'vue';

export const showModal = (name: string, paramsOrProps?: object): void => {
    if (paramsOrProps) {
        Vue.prototype.$modal.show(name, paramsOrProps);
    } else {
        Vue.prototype.$modal.show(name);
    }
};

export const hideModal = (name: string): void => {
    Vue.prototype.$modal.hide(name);
};
