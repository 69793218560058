import Vue from 'vue';
import rowItemPropsMixin from '@/js/components/tables/mixins/table-cells-mixins/row-item-props-mixin';

export default Vue.extend({
    mixins: [
        rowItemPropsMixin,
    ],
    computed: {
        isValue (): string {
            const concat = `${this.cell.params.concat} ` || ' ';
            const value = this.cell.value.text || this.cell.value;
            return Object.values(value).join(concat);
        }
    },
});
