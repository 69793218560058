export const tableParser = (tableHeader, tableRows, idKey = 'id', tableType = null) => {
    let mappedRows = tableRows.map((row) => {
        let combinedRow = tableHeader.map((headerItem) => {
            let combinedObject = {
                cellType: headerItem.cellType,
                cellDataKey: headerItem.cellDataKey,
                name: headerItem.name || headerItem.id,
                value: {},
                ID: row[idKey],
                tableType,
                textAlign: headerItem.textAlign,
                cellWidth: headerItem.cellWidth,
                params: headerItem.params,
                cellClass: headerItem.cellClass,
            };

            if (typeof headerItem.cellData !== 'string') {
                headerItem.cellData.forEach((element) => {
                    combinedObject.value[element] = row[element];
                });
            } else {
                combinedObject.value = row[headerItem.cellData];
            }
            return combinedObject;
        });
        return combinedRow;
    });
    return mappedRows;
};
