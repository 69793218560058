import { Module, VuexModule, getModule, Action } from 'vuex-module-decorators';
import store from '~/store/index';
import Rest from '~/helpers/Rest';
import { urlClientSendEmail, urlEmailBulkSend } from '~/settings/ajax-urls';
import {
    ISendMailUser,
    IMassiveSendEmailsPayload,
} from './send-mail-interfaces';
import { NotificationPrefabricated } from '~/helpers/modal-notification-services/modal-notification-services';
import { handlerRestResponse } from '~/helpers/handler-rest-response';

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: 'SendMail',
})
class SendMail extends VuexModule {

    @Action
    async massiveSendEmails (payload: IMassiveSendEmailsPayload): Promise<void> {
        await Rest.POST(urlEmailBulkSend, payload)
            .then(response => {
                return handlerRestResponse(response, NotificationPrefabricated.MassiveEmailsSendSuccess);
            })
            .catch(Rest.simpleCatch);
    }

    @Action
    async clientSendEmail ({ leadId, ...rest }: ISendMailUser): Promise<void> {
        await Rest.POST(urlClientSendEmail(leadId), rest)
            .then(response => {
                return handlerRestResponse(response, NotificationPrefabricated.emailsSendSuccess);
            })
            .catch(Rest.simpleCatch);

    }
}

export const SendMailModule = getModule(SendMail);
