import { EActionModalTitle } from '@/js/components/modals/action-modal/enums/action-modal.enum';

export default {
    computed: {
        modalProps () {
            return this.$attrs['modal-props'];
        },
        title () {
            return this.modalProps.title || EActionModalTitle[4];
        },
        modalData () {
            return this.modalProps.body;
        },
        isHeader () {
            return this.modalProps.isHeader;
        },
        isFooter () {
            return this.modalProps.isFooter;
        },
        isReason () {
            return this.modalProps.isReason;
        },
        isScroll () {
            return this.modalProps.isScroll;
        }
    },
    methods: {
        closeModal () {
            this.$emit('closeModal');
        }
    }
};
