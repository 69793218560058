// Imports
import Vue from 'vue';
import subNavbar from '~/components/subNavbar/subNavbar.vue';
import subNavbarLink from '~/components/subNavbar/subNavbarLink.vue';
import subNavbarSeparator from '~/components/subNavbar/subNavbarSeparator.vue';
import routes from '~/settings/routes';
import { ModalsModule } from '~/store/modules/modals';
import { DepartmentsModule } from '~/store/modules/departments/departments';
import { IDepartmentsPayload } from '~/store/modules/departments/interfaces/departments-interfaces';
import { EAccessSettingsModalTypes } from '~/settings/enums/access-settings-modal-types.enum';
import { SymbolsModule } from '~/store/modules/symbols/symbols';
import { NotificationPrefabricated } from '~/helpers/modal-notification-services/modal-notification-services';
import {
    isPermissionSettingsGroup,
    isPermissionSettingsDocuments,
    isPermissionSettingsSymbols,
} from '~/helpers/user-permission';

// Exports
export default Vue.extend({
    components: {
        subNavbar,
        subNavbarLink,
        subNavbarSeparator,
    },
    computed: {
        isPermissionSettingsGroup,
        isPermissionSettingsDocuments,
        isPermissionSettingsSymbols,
        isGroup (): boolean {
            return this.$route.name == routes.settings.subRoutes.group.name &&
                this.isPermissionSettingsGroup;
        },
        isSymbols (): boolean {
            return this.$route.name == routes.settings.subRoutes.symbols.name &&
                this.isPermissionSettingsSymbols;
        },
        group (): string {
            return routes.settings.subRoutes.group.path;
        },
        documents (): string {
            return routes.settings.subRoutes.documents.path;
        },
        symbols (): string {
            return routes.settings.subRoutes.symbols.path;
        },
    },
    methods: {
        loadDepartmentGroup (): void {
            DepartmentsModule.loadDepartments();
        },
        async createDepartmentGroup (payload: IDepartmentsPayload) {
            await DepartmentsModule.createDepartment({
                permissions: payload.permissions,
                name: payload.name,
                email: payload.email,
            });
            this.loadDepartmentGroup();
        },
        createDepartment (): void {
            ModalsModule.openModal({
                modalType: 'accessSettingsModal',
                props: {
                    title: 'Add New Group',
                    body: {
                        type: EAccessSettingsModalTypes.createDepartment,
                    },
                    isHeader: true,
                    isFooter: true,
                    ok: (payload: IDepartmentsPayload) => this.createDepartmentGroup(payload)
                },
                modalSettings: {
                    props: {
                        minWidth: ModalsModule.modalWidth.lg,
                    },
                },
                addClass: 'modal-users-access'
            });
        },

        importSymbols (event: MouseEvent): void {
            ModalsModule.openModal({
                modalType: ModalsModule.modalType.uploadDocumentModal,
                modalSettings: {
                    props: {
                        minWidth: ModalsModule.modalWidth.md,
                    },
                    positionModal: {
                        clientX: event.clientX,
                        clientY: event.clientY,
                        modalWidth: ModalsModule.modalWidth.md,
                    }
                },
                props: {
                    title: 'Import Symbols',
                    confirm: (fileUploaded: File) => {
                        NotificationPrefabricated.ImportSymbolsProcess();
                        SymbolsModule.import(fileUploaded);
                    },
                },
                addClass: 'symbolsImportUploadedDocument'
            });

        }
    },
});
