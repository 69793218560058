<template>
    <div>
        <div class="documents-component">
            <single-document
                v-for="(d, idx) in documents"
                :id="idx"
                :key="d.type_id"
                :type-id="d.type_id"
                :label="d.name"
                :mapping-name="d.mapping_name"
            />
        </div>
    </div>
</template>

<script>
import SingleDocument from '~/components/settings/settings-documents/single-document/single-document';
import { LegalDocumentsModule } from '~/store/modules/documents/legal-documents';
export default {
    components: {
        'single-document': SingleDocument
    },
    props: {

    },
    data () {
        return {
            name: 'Documents',
            data: null
        };
    },
    computed: {
        documents () {
            return LegalDocumentsModule.getDocumentTypes.length > 0 ? LegalDocumentsModule.getDocumentTypes : [];
        }
    },
    created () {
        LegalDocumentsModule.actionLoadLegalDocuments();
    },
    methods: {
    }
};
</script>
