import Vue from 'vue';
import { withdrawalsModule } from '~/store/modules/withdrawals/withdrawals';
import { ModalsModule } from '~/store/modules/modals';
import { ITableHeaderColumn } from '~/components/tables/interfaces/table-header-column.interfaces';
import { ITableCell } from '~/components/tables/interfaces/table-cell-interfaces';
import { ETableType } from '~/components/tables/enums/table-type.enum';
import { EModalType } from '~/helpers/modal-services/enums/modal-type.enum';
import { EModalProps } from '~/helpers/modal-services/enums/modal-props.enum';
import currencyHelper from '~/components/mixins/currency-helper';
import { ERouterNameFromPermission } from '~/settings/enums/router-name.enum';

export default Vue.extend({
    mixins: [
        currencyHelper,
    ],
    props: {
        tableType: {
            type: String,
            required: true,
        },
    },
    computed: {
        isTableTypeHistory (): string {
            return ETableType.withdrawalsHistory;
        },
        getWithdrawalsTableHeader (): ITableHeaderColumn[] {
            return this.tableType === this.isTableTypeHistory ?
                withdrawalsModule.getWithdrawalsHistoryTableHeader :
                withdrawalsModule.getWithdrawalsPendingTableHeader;
        },
        getWithdrawalsTable (): ITableCell[] {
            return this.tableType === this.isTableTypeHistory ?
                withdrawalsModule.getWithdrawalsHistoryTable :
                withdrawalsModule.getWithdrawalsPendingTable;
        },
        getWithdrawalsIsLoaded (): boolean {
            return withdrawalsModule.getWithdrawalsIsLoaded;
        }
    },
    methods: {
        beforeWithdrawalApprove (withdrawalId: ITableCell): void {
            ModalsModule.openModal({
                modalType: EModalType.confirmUpdateLeads,
                props: {
                    title: 'Are you sure?',
                    body: `Are you sure you want to withdrawal ${withdrawalId.value.amount} ${this.getCurrencyName(withdrawalId.value.currency_id)}?`,
                    isHeader: true,
                    isFooter: true,
                    isReason: false,
                    ok: () => {
                        this.handlerWithdrawalApprove(withdrawalId);
                    },
                },
                modalSettings: {
                    props: {
                        minWidth: ModalsModule.modalWidth.xs,
                    },
                },
            });
        },
        beforeWithdrawalReject (withdrawalId: ITableCell): void {
            ModalsModule.openModal({
                modalType: EModalType.confirmUpdateLeads,
                props: {
                    title: 'Are you sure?',
                    body: 'Are you sure you want to cancel this withdrawal?',
                    isHeader: true,
                    isFooter: true,
                    isReason: true,
                    reason: '',
                    ok: () => {
                        this.handlerWithdrawalReject(withdrawalId);
                    },
                },
                modalSettings: {
                    props: {
                        minWidth: ModalsModule.modalWidth.xs,
                    },
                },
            });
        },
        async handlerWithdrawalApprove (withdrawalId: ITableCell): Promise<void> {
            const { client_id, id } = withdrawalId.value;
            await withdrawalsModule.handlerWithdrawalApprove({
                accountId: client_id,
                paymentId: id,
            })
                .then(response => {
                    if (response ?? true) withdrawalsModule.loadWithdrawalsPendingTable(this.$route.query);
                });
        },
        async handlerWithdrawalReject (withdrawalId: ITableCell): Promise<void> {
            const reason = ModalsModule.getModalPropByKey(EModalType.confirmUpdateLeads, EModalProps.reason);
            const { client_id, id } = withdrawalId.value;
            await withdrawalsModule.handlerWithdrawalReject({
                accountId: client_id,
                paymentId: id,
                reason: reason,
            })
                .then(response => {
                    if (response ?? true) withdrawalsModule.loadWithdrawalsPendingTable(this.$route.query);
                });
        },
        routeToLeadDetailsById (id: number) {
            this.$router.push({
                name: ERouterNameFromPermission.leadsItemsDetails,
                params: { id }
            });
        }
    }
});
