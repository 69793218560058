// @ts-ignore
import rootTable from '~/components/tables/root-table/root-table';
import { ClientKYCFinancesModule } from '~/store/modules/client-kyc-finances/client-kyc-finances';
import financeMixin from '~/components/mixins/finance/financeMixin';
import { PaymentHistoryPaginationInterface } from '~/components/mixins/finance/finance-mixin.interfaces';
import { ITotalWithdrawals, ITotalCurrencyInterface } from '~/store/modules/client-kyc-finances/client-kyc-finances-interfaces';
import { ETableType } from '~/components/tables/enums/table-type.enum';
import { ITableCell } from '~/components/tables/interfaces/table-cell-interfaces';
import { ModalsModule } from '~/store/modules/modals';
import currencyHelper from '~/components/mixins/currency-helper';

import { setQuaryPerPage } from '~/components/tables/helpers/setQuaryPerPage';
import { handlerLoadTable } from '~/components/tables/helpers/handler-load-table';

export default {
    components: {
        rootTable,
    },
    mixins: [
        currencyHelper,
        financeMixin,
    ],
    computed: {
        tableHeader (): Array<object> {
            return ClientKYCFinancesModule.getPaymentHeader;
        },
        tableRows (): Array<object> {
            return ClientKYCFinancesModule.getPaymentRows;
        },
        reason (): string {
            return ClientKYCFinancesModule.getPendingWithdrawalReason;
        },
        isPagination (): PaymentHistoryPaginationInterface {
            return ClientKYCFinancesModule.getHistoryPagination;
        },
        getTotalWithdrawals (): ITotalWithdrawals {
            return ClientKYCFinancesModule.getTotalWithdrawals;
        },
        getTotalCurrency (): ITotalCurrencyInterface {
            return ClientKYCFinancesModule.getTotalCurrency;
        },
        tableType (): string {
            return ETableType.paymentHistory;
        },
        getPaymentsHistoryIsLoaded (): boolean {
            return ClientKYCFinancesModule.getPaymentsHistoryIsLoaded;
        }
    },
    mounted (): void {
        this.loadPaymentsHistory();
    },
    methods: {
        clearFiltersOfTable (additionalExceptionsForReset: string[]): void {
            this.$refs.rootTable.handlerResetFilter(additionalExceptionsForReset);
        },
        async loadPaymentsHistory (): Promise<void> {
            setQuaryPerPage(this.tableType);
            await handlerLoadTable(this.tableType);
        },
        addNewItem (event: MouseEventInit): void {
            ModalsModule.openModal({
                modalType: 'leadsFinanceCreateTransaction',
                props: {
                    title: '',
                    isHeader: true,
                    isFooter: true,
                    isReason: false,
                },
                modalSettings: {
                    props: {
                        minWidth: ModalsModule.modalWidth.md,
                    },
                    positionModal: {
                        clientX: event.clientX,
                        clientY: event.clientY,
                        modalWidth: ModalsModule.modalWidth.md,
                    }
                },
            });
        },
        approve (event: ITableCell): void {
            ModalsModule.openModal({
                modalType: 'confirmUpdateLeads',
                props: {
                    title: 'Are you sure?',
                    body: `Are you sure you want to ${event.value.operation} ${event.value.amount} ${this.getCurrencyName(event.value.currency.id)}?`,
                    isHeader: true,
                    isFooter: true,
                    isReason: false,
                    ok: () => {
                        ClientKYCFinancesModule.PaymentsPendingApprove(event.ID)
                            .then(response => {
                                if (response ?? true) this.loadPaymentsHistory();
                            });
                    },
                },
                modalSettings: {
                    props: {
                        minWidth: ModalsModule.modalWidth.xs,
                    },
                },
            });
        },
        reject (event: ITableCell): void {
            ModalsModule.openModal({
                modalType: 'confirmUpdateLeads',
                props: {
                    title: 'Are you sure?',
                    body: 'Are you sure you want to cancel this?',
                    isHeader: true,
                    isFooter: true,
                    isReason: true,
                    ok: () => {
                        ClientKYCFinancesModule.PaymentsPendingCancel({ paymentId: event.ID, reason: this.reason })
                            .then(response => {
                                if (response ?? true) this.loadPaymentsHistory();
                            });
                    },
                },
                modalSettings: {
                    props: {
                        minWidth: ModalsModule.modalWidth.xs,
                    },
                },
            });
        }
    }
};
