import Vue from 'vue';
import { LeadsFiltersTemplateEditInterfaces } from './interfaces/leads-filters-template-edit-interfaces';
import { filtersOptions } from '~/components/leads/leadsFilterMultiselectOptions';
import { LeadsFilterTemplatesModule } from '~/store/modules/leads-filter-templates/leads-filter-templates';
import modalLayout from '~/components/modals/root-modal-layout/root-modal-layout.vue';
import modalsMixin from '~/components/mixins/modals';
import { ModalsModule } from '~/store/modules/modals';

export default Vue.extend({
    components: {
        modalLayout
    },
    mixins: [modalsMixin],
    data (): LeadsFiltersTemplateEditInterfaces {
        return {
            templateName: ''
        };
    },
    computed: {
        isFilters (): Array<any> {
            return filtersOptions;
        },
        filterSelected: {
            get (): Array<any> {
                return LeadsFilterTemplatesModule.getFiltersToModal;
            },
            set (value: Array<any>) {
                LeadsFilterTemplatesModule.setFiltersToModal(value);
            }
        },
        template (): Object {
            return LeadsFilterTemplatesModule.getTemplateToEdit;
        },
        templateToEdit (): Number {
            return LeadsFilterTemplatesModule.getTemplateToEditId;
        }
    },
    mounted (): void {
        this.handleShowModal();
    },
    destroyed (): void {
        this.handleHideModal();
    },
    methods: {
        deleteTemplate () {
            LeadsFilterTemplatesModule.isDeleteTemplate(this.templateToEdit);
            this.closeModal();
            ModalsModule.openModal({
                modalType: 'leadsFiltersTemplateDelete',
                props: {
                    title: 'Are you sure?',
                    body: {
                        data: 'Are you sure you want to delete this template?'
                    },
                    isHeader: true,
                    isFooter: true
                },
                modalSettings: {
                    props: {
                        minWidth: ModalsModule.modalWidth.xs,
                    },
                },
            });
        },
        save () {
            if (this.templateName) {
                LeadsFilterTemplatesModule.toEditTemplate(this.templateName);
                this.closeModal();
            }
        },
        handleShowModal () {
            this.templateName = this.template.name;
            const filters = this.isFilters.filter(option => {
                return this.template['leads_filters'].find(filter => filter.id === option.numberId);
            });
            LeadsFilterTemplatesModule.setFiltersToModal(filters);
        },
        handleHideModal () {
            LeadsFilterTemplatesModule.cancelEditTemplate();
            LeadsFilterTemplatesModule.cleanFiltersToModal();
            this.closeModal();
        }
    }
});
