// Imports
import Vue from 'vue';
import chartsMixin from '~/components/mixins/charts-mixins';
import {
    IDashboardTradingVolume,
} from '~/store/modules/dashboard/interfaces';
import { EDashboardVolumeItemsKey } from '~/store/modules/dashboard/enums/dashboard-payments-items.enum';
import { DashboardModule } from '~/store/modules/dashboard/dashboard';

// Exports
export default Vue.extend({
    mixins: [
        chartsMixin,
    ],
    data () {
        return {
            chartName: 'volumesChart',
        };
    },
    computed: {
        chartData (): IDashboardTradingVolume[] {
            return DashboardModule.getDashboardVolume[EDashboardVolumeItemsKey.trading_volume]
                .map(item => ({
                    day: item.day.split('/')[0],
                    volume: item.volume,
                }));
        },
    },
    watch: {
        chartData: {
            deep: true,
            handler (payload: IDashboardTradingVolume[]) {
                this.updateChart(
                    payload,
                    'volume',
                    'day',
                );
            },
        },
    },
    mounted () {
        this.initChart(
            this.chartName,
            this.chartType.line,
            '',
        );
    },
});
