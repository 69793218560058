import { ITableHeaderColumn } from '~/components/tables/interfaces/table-header-column.interfaces';
import { tableHeaderParser } from '~/components/tables/helpers/table-header-parser';

export const notificationActivityLog: ITableHeaderColumn[] = tableHeaderParser([
    {
        name: 'created_at',
        value: 'Time',
        id: 0,
        cellType: 'date-time-cell',
        cellData: 'created_at'
    },
    {
        name: 'user',
        value: 'User',
        id: 1,
        cellType: 'key-from-object',
        cellData: 'user',
        cellDataKey: 'full_name',
    },
    {
        name: 'id',
        value: 'Lead ID',
        id: 2,
        cellType: 'key-from-object',
        cellData: 'client',
        cellDataKey: 'id',
    },
    {
        id: 3,
        name: 'verify_status_id',
        value: 'Verification (Trader)',
        cellType: 'status-from-object',
        cellData: 'client',
        cellDataKey: 'verify_status_id'
    },
    {
        id: 4,
        name: 'verify_status_crypto_id',
        value: 'Verification (Crypto)',
        cellType: 'status-from-object',
        cellData: 'client',
        cellDataKey: 'verify_status_crypto_id',
    },
    {
        name: 'full_name',
        value: 'Full Name',
        id: 5,
        cellType: 'key-from-object',
        cellData: 'client',
        cellDataKey: 'full_name',
    },
    {
        name: 'email',
        value: 'Email',
        id: 6,
        cellType: 'key-from-object',
        cellData: 'client',
        cellDataKey: 'email',
        cellClass: 'text-lowercase',
    },
    {
        name: 'description',
        value: 'Description',
        id: 7,
        cellType: 'description-cell',
        cellData: ['id', 'client', 'comment', 'redirect']
    },
    {
        id: 8,
        name: 'ip_address',
        value: 'IP Address',
        cellType: 'ip-address',
        cellData: 'ip_address'
    },
    {
        id: 9,
        name: 'status',
        value: '',
        cellType: 'empty-cell',
        cellData: 'status'
    },
]);
