import Vue from 'vue';
import DatePicker from 'vue2-datepicker';

export default Vue.extend({
    components: {
        DatePicker
    },
    props: {
        validation: {
            type: String
        },
    },
    computed: {
        allBindings () {
            // Need to proxify both props and attrs, for example for showLabels
            return { ...this.$props, ...this.$attrs };
        },
    },
    methods: {
        /**
         * Remove input error
         * */
        removeInputError () {
            this.$refs.validationProvider.reset();
        },
    }
});
