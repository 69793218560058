<template>
    <div class="container-lg header">
        <div class="row header__row">
            <div class="header-column">
                <div
                    v-if="!isLeadsPage"
                    class="header-page-title"
                >
                    {{ pageName }}
                </div>
                <div
                    v-if="isLeadsPage"
                    class="header-lead"
                >
                    <app-button
                        class="header-lead__back"
                        type="button"
                        style-button="outline-secondary"
                        modify="button-icon"
                        @click="previousPage"
                    >
                        <material-icon
                            class="header-lead__back-icon"
                            size="lg"
                            add-class="wght-light"
                            symbol="arrow_back_ios"
                        />
                    </app-button>
                    <material-icon
                        class="mr-2"
                        size="lg"
                        symbol="fiber_manual_record"
                        add-class="fill"
                        :color="isOnWebTraderIconColor"
                    />
                    <strong>{{ lead_full_name }}</strong>
                    <material-icon
                        class="m-2"
                        :symbol="getIconByClientType"
                    />
                    <span class="lead-id">ID {{ lead_id }}</span>
                </div>
            </div>
            <div class="header__search">
                <search-main
                    ref="searchMain"
                    placeholder="Search for Lead ID, name, email, phone or trading account"
                    placeholder-short="Search Lead"
                    :options="searchOptions"
                    history
                    @search="asyncFind($event)"
                    @selectItem="selectInput($event)"
                    @selectType="selectType($event)"
                    @selectItemHistory="selectItemHistory($event)"
                    @closeSearch="closeSearch"
                    @scrollEnd="handlerNextPage($event)"
                />
            </div>
            <div class="header-user d-inline-flex justify-content-end align-items-center">
                <div class="header-user-name d-inline-flex pl-1">
                    <span>{{ time }}</span>
                </div>
                <div class="header-user-name d-inline-flex pr-0 pl-0">
                    <span>{{ date }}</span>
                </div>

                <!--Badge-->
                <div class="header-user-badge d-inline-flex">
                    <div
                        class="header-user-badge-item"
                        title="Tickets"
                    >
                        <material-icon
                            symbol="chat_bubble_outline"
                            size="lg-plus"
                            color="grey"
                            title="Tickets"
                            delay
                        />
                        <span
                            v-if="false"
                            class="badge badge-pill badge-danger"
                        >133</span>
                    </div>
                    <div
                        class="header-user-badge-item"
                        title="Emails"
                    >
                        <material-icon
                            symbol="mail_outline"
                            size="extra-lg"
                            color="grey"
                            title="Mails"
                            class="mb-1"
                            delay
                        />
                        <span
                            v-if="false"
                            class="badge badge-pill badge-danger"
                        >23</span>
                    </div>
                    <div class="header-user-badge-item">
                        <router-link
                            id="theHeaderNotifications"
                            :to="{ path: notifications }"
                            title="Notifications"
                        >
                            <material-icon
                                symbol="notifications_none"
                                size="super-lg"
                                color="grey"
                                title="Notifications"
                                delay
                            />
                            <notifications-count
                                :count="isNotifications"
                                position="bottom-right"
                            />
                        </router-link>
                    </div>

                    <div class="header-user-dropdown">
                        <header-user-dropdown
                            :name="fullName"
                            :department-name="departmentName"
                            :email="emailName"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
import moment from 'moment';
import { ProfileModule } from '~/store/modules/profile/profile';
import { NotificationsMessagesModule } from '~/store/modules/notifications/notifications';
import routes from '../settings/routes';
import headerUserDropdown from './header-user-dropdown/header-user-dropdown.vue';
import leadDetailsMixin from '~/components/mixins/leadDetailsMixin';
import searchMain from '~/components/parts/search-main/search-main.vue';
import leadsHeaderSearchMixin from '~/components/mixins/leads-header-search-mixin';
import { getIconByLegalSubjectId } from '~/helpers/getIconByLegalSubjectId';

export default {
    components: {
        headerUserDropdown,
        searchMain,
    },
    mixins: [
        leadDetailsMixin,
        leadsHeaderSearchMixin,
    ],
    data () {
        return {
            time: '',
            date: '',
            notifications: routes.notifications.path
        };
    },
    computed: {
        profile () {
            return ProfileModule.getProfile;
        },
        getIconByClientType () {
            return getIconByLegalSubjectId(
                this.leadDetails.legal_subject?.id
            );
        },
        fullName () {
            return `${this.profile.fname} ${this.profile.lname}`;
        },
        isNotifications () {
            return NotificationsMessagesModule.getterNotificationsUnReadCount;
        },
        pageName () {
            return this.$route.meta.title;
        },
        departmentName () {
            return ProfileModule.getProfile.department_name;
        },
        emailName () {
            return ProfileModule.getProfile.email;
        },
        isLeadsPage () {
            return this.$route.meta.details;
        },
        lead_full_name () {
            return this.leadDetails.full_name;
        },
        lead_id () {
            return this.leadDetails.id;
        },
        isOnWebTraderIconColor () {
            return this.leadDetails.is_on_webtrader ?
                'green' :
                'red';
        }
    },
    created () {
        setInterval(this.updateTime, 1000);
        this.updateTime();
    },
    methods: {
        updateTime () {
            const m = moment();
            this.time = m.format('HH:mm:ss ');
            this.date = m.format('DD MMMM, YYYY');
        },
        previousPage () {
            if (window.history.length > 2) {
                this.$router.back();
            } else {
                this.$router.push({ path: routes.leads.path });
            }
        },
    }
};
</script>
