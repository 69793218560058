import Vue from 'vue';
import {
    ISearchMain,
    ISearchMainHistory,
    ISearchMainAttributes,
} from '~/settings/interfaces/search-main-interfaces';
import {
    ESearchMainLocalStorage,
    ESearchMainAttributes,
    ESearchMainAttributesIcon,
} from '~/settings/enums/search-main.enum';
import { StorageService } from '~/helpers/storage-service';
import filterGroups from '~/components/parts/app-multiselect/_buttons/groups/groups.vue';
import configsMixin from '~/components/mixins/configs-mixin';
import { getIconByLegalSubjectId } from '~/helpers/getIconByLegalSubjectId';
import {
    EAccountStatus,
    EAccountStatusColor,
    EAccountStatusIcons
} from '~/settings/enums/account-status.enum';

export default Vue.extend({
    components: {
        filterGroups,
    },
    mixins: [
        configsMixin,
    ],
    props: {
        addClass: {
            type: String,
        },
        placeholder: {
            type: String,
        },
        placeholderShort: {
            type: String,
        },
        options: {
            type: Array,
        },
        history: {
            type: Boolean,
            default: false,
        }
    },
    data () {
        return {
            className: 'b-search-main',
            isOpened: false,
            textField: '',
            disabledScroll: false,
            currentHistory : [],
            isVisibleHistory: false,
            selectType: null,
            eSearchMainAttributesIcon: ESearchMainAttributesIcon,
            eAccountStatus: EAccountStatus,
            eAccountStatusIcons: EAccountStatusIcons,
            eAccountStatusColor: EAccountStatusColor,
        };
    },
    computed: {
        iconColor (): string {
            return this.isOpened ? 'blue' : 'grey';
        },
        isPlaceholder (): string {
            return this.placeholderShort && !this.isOpened ?
                this.placeholderShort :
                this.placeholder;
        },
        visibleOptions (): boolean {
            return this.options.length > 0 && this.isOpened;
        },
        visibleHistory (): boolean {
            return this.history &&
                this.isVisibleHistory &&
                this.currentHistory.length > 0;
        },
        searchTypes (): ISearchMainAttributes[] {
            return Object.keys(this.eSearchMainAttributesIcon)
                .map((current) => {
                    const indexIcon = ESearchMainAttributesIcon[current];
                    const item = this.getConfigAttributes.find(attribute => attribute.attribute === current);
                    return {
                        ...item,
                        icon: indexIcon,
                    };

                });
        },
        checkSelectTypeForEmit (): boolean {
            if (this.selectType) {
                switch (this.selectType.attribute) {
                case ESearchMainAttributes.ClientsId:
                case ESearchMainAttributes.ClientsPhoneMobileFull:
                case ESearchMainAttributes.AccountsAccount:
                    return !!parseInt(this.textField);
                
                default:
                    return true;
                }
            }

            return true;
        }
    },
    methods: {
        verificationStatusColor (statusId: number): EAccountStatusColor {
            return EAccountStatusColor[EAccountStatus[statusId]];
        },
        verificationIcon (statusId: number): EAccountStatusIcons {
            return EAccountStatusIcons[EAccountStatus[statusId]];
        },
        getIconByClientType (id: number): string {
            return getIconByLegalSubjectId(id);
        },
        setSearchState (payload: boolean) {
            this.$set(this.$data, 'isOpened', payload);
        },
        resetTextField () {
            this.$set(this.$data, 'textField', '');
        },
        resetSearchType () {
            this.$set(this.$data, 'selectType', null);
        },
        setCurrentHistory () {
            const history = StorageService.getItem(ESearchMainLocalStorage.name) ?
                StorageService.getItem(ESearchMainLocalStorage.name).reverse() : [];
            this.$set(this.$data, 'currentHistory', history);
        },
        setHistoryItem (item: ISearchMainHistory) {
            StorageService.updateArrayItem(
                ESearchMainLocalStorage.name,
                item,
            );
            const currentHistory = StorageService.getItem(ESearchMainLocalStorage.name);

            if (currentHistory.length > ESearchMainLocalStorage.maxLength) {
                currentHistory.shift();
                StorageService.setItem(
                    ESearchMainLocalStorage.name,
                    currentHistory
                );
            }
        },
        updateHistoryOrdering (item: ISearchMainHistory) {
            const newArray = StorageService.getItem(ESearchMainLocalStorage.name)
                .filter(current => (current.id !== item.id));
            StorageService.setItem(ESearchMainLocalStorage.name, [
                ...newArray,
                item,
            ]);
        },
        selectHistoryItem (item: ISearchMainHistory) {
            this.updateHistoryOrdering(item);
            this.$emit('selectItemHistory', item);
            this.closeSearch();
        },
        setVisibleHistoryState (payload: boolean) {
            this.isVisibleHistory = payload;
        },
        async handlerSearch (event: string) {
            if (this.checkSelectTypeForEmit) {
                this.$emit('search', {
                    search: event,
                    attribute: this.selectType?.attribute,
                });
            }

            setTimeout(() => {
                if (this.history && this.isVisibleHistory && event) {
                    this.setVisibleHistoryState(false);
                }
                if (this.history && !event) {
                    this.setVisibleHistoryState(true);
                }
            });
        },
        selectItem (event: ISearchMain) {
            this.$emit('selectItem', event);
            this.closeSearch();
        },
        openSearch () {
            if (!this.isOpened) {
                if (this.history) {
                    this.setCurrentHistory();
                    this.setVisibleHistoryState(true);
                }
                this.setSearchState(true);
                setTimeout(() => {
                    this.$root.$el.addEventListener('mousedown', this.clickOnBody);
                }, 100);
                this.$emit('openSearch');
            }
        },
        closeSearch () {
            this.setSearchState(false);
            this.resetSearchType();
            this.resetTextField();
            this.$emit('closeSearch');
            setTimeout(() => {
                this.$root.$el.removeEventListener('mousedown', this.clickOnBody);
            }, 100);

        },
        clickOnBody (event: any) {
            const target = event.target;
            if (target.classList.contains === this.className || target.closest(`.${this.className}`)) {
                console.log();
            } else {
                this.closeSearch();
            }
        },
        handlerScroll () {
            const searchMainScrollBar = this.$refs.searchMainScrollBar;
            const height = searchMainScrollBar.clientHeight;
            const dragger = searchMainScrollBar._vuebarState.dragger.offsetHeight + searchMainScrollBar._vuebarState.dragger.offsetTop;

            if ((dragger === height) && !this.disabledScroll) {
                this.$emit('scrollEnd', {
                    search: this.textField,
                    attribute: this.selectType && this.selectType.attribute,
                });
            }

        },
        disableScroll () {
            this.disabledScroll = true;
        },
        enableScroll () {
            this.disabledScroll = false;
        },
        selectSearchType (event: ISearchMainAttributes) {
            const type = event.attribute === this.selectType?.attribute ?
                null :
                event;
            this.selectType = type;

            if (this.checkSelectTypeForEmit) {
                this.$emit('selectType', {
                    search: this.textField,
                    attribute: type && type.attribute,
                });
            }
        }
    }
});
