import Vue from 'vue';

export default Vue.extend({
    props: {
        title: {
            type: String,
            required: false,
            default: 'Clear',
        },
        rejectType: {
            type: Boolean,
        }
    },
    computed: {
        getTitle (): string {
            return this.rejectType ? 'Reject' : this.title;
        },
    }
});
