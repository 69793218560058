import Vue from 'vue';
import Multiselect from 'vue-multiselect';
import { ModalsModule } from '~/store/modules/modals';
import BonusPlanComponent from '~/components/parts/bonus-plan-component/bonus-plan-component.vue';
import accountStatus from '~/components/tables/table-cells/account-status/account-status.vue';

export default Vue.extend({
    components: {
        'bonus-plan-component': BonusPlanComponent,
        Multiselect,
        accountStatus,
    },
    props: {
        tableBonusItem: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            status: {
                name: 'Verified',
                value: this.tableBonusItem.value == 'Verified' ? 'active' : 'inactive'
            },
            verifiedImageSrc: '/img/clients/verified.svg',
            pendingImageSrc: '/img/clients/pending.svg',
            terminatedImageSrc: '/img/clients/unverified.svg'
        };
    },
    computed: {
        statusImageSource () {
            return (this.status.name == 'Verified') ? this.verifiedImageSrc :
                (this.status.name == 'Pending') ? this.pendingImageSrc :
                    (this.status.name == 'Terminated') ? this.terminatedImageSrc : null;
        }
    },
    methods: {
        propsStatusImage (prop) {
            return  (prop == 'Verified') ? this.verifiedImageSrc :
                (prop == 'Pending') ? this.pendingImageSrc :
                    (prop == 'Terminated') ? this.terminatedImageSrc : null;
        },
        removeManagerUser (modalProps) {
            ModalsModule.openModal({
                modalType: 'removeItemModal',
                props: modalProps,
                addClass: 'removeItemModal'
            });
        },
        showEditManagerModal (modalProps) {
            ModalsModule.openModal({
                modalType: 'addNewManager',
                props: modalProps,
                addClass: 'addNewManager'
            });
        }
    }
});
