import Vue from 'vue';
import configsMixin from '~/components/mixins/configs-mixin';
import {
    EAccountStatus,
    EAccountStatusColor,
    EAccountStatusIcons,
} from '~/settings/enums/account-status.enum';
import rowItemPropsMixin from '@/js/components/tables/mixins/table-cells-mixins/row-item-props-mixin';
import { EConfigType } from '~/store/modules/configs/enums/config-type.enum';
import { IDefaultConfig } from '~/store/modules/configs/interfaces/configs-interface';

export default Vue.extend({
    mixins: [
        configsMixin,
        rowItemPropsMixin,
    ],
    data: () => ({
        className: 'account-status',
    }),
    computed: {
        status () {
            return typeof this.cell?.value === EConfigType.object
                ? this.cell.value
                : this.getStatusById;
        },
        statusId (): string {
            return this.status?.id;
        },
        statusName (): string {
            return this.status?.name || '';
        },
        icon (): string {
            return EAccountStatusIcons[EAccountStatus[this.statusId]] || '';
        },
        getColorCell (): string {
            return this.statusId
                ? `status-color-${EAccountStatusColor[EAccountStatus[this.statusId]]}`
                : '';
        },
        getStatusById (): IDefaultConfig {
            return this.getConfigVerifyStatuses.find(
                (status: IDefaultConfig) => status.id === this.cell.value
            );
        },
    }
});
