export enum UploadedDocumentsTypeIdsEnum {
    IdentificationDocuments = 1,
    PaymentVerificationDocuments = 2,
    AutoEmails = 13,
    YourSelfie = 4,
    AuthorisationDepositCards = 8,
    AuthorisationDepositWireTransfer = 9,
    AuthorisationDepositEWallets = 10,
    All = 0
}

export enum UploadedDocumentsStatusIdEnum {
    Pending = 1,
    Verified = 2,
    Rejected = 3
}
