// Import
import Vue from 'vue';
import modalsMixin from '~/components/mixins/modals';
import modalLayout from '~/components/modals/root-modal-layout/root-modal-layout.vue';
import Multiselect from 'vue-multiselect';

export default Vue.extend({
    components: {
        modalLayout,
        Multiselect,
    },
    mixins: [modalsMixin],
    data () {
        return {
            options: [1, 2, 3],
            nameSelected: '',
        };
    }
});
