import Vue from 'vue';
import AppButton from '~/components/parts/app-button/app-button.vue';
import AppInput from '~/components/parts/app-input/app-input.vue';
import AppMultiSelect from '~/components/parts/app-multiselect/app-multiselect.vue';
import bonusPlanEditFormMixin from '~/components/mixins/bonus-plan-edit-form-mixin';
import bonusPlanTableRevenue from '~/components/affiliates/affiliates-bonus-plan/bonus-plan-table-revenue/bonus-plan-table-revenue.vue';
import bonusPlanForms from '~/components/_forms/bonus-plan-forms/bonus-plan-forms-mixin';
import appSwitcher from '~/components/parts/app-switcher/app-switcher.vue';
import bonusPlanSwithcerMixin from '~/components/_forms/bonus-plan-forms/bonus-plan-switcher-mixin';

export default Vue.extend({
    components: {
        AppButton,
        AppInput,
        AppMultiSelect,
        bonusPlanTableRevenue,
        appSwitcher,
    },
    mixins: [
        bonusPlanEditFormMixin,
        bonusPlanForms,
        bonusPlanSwithcerMixin,
    ],
    inject: ['bonusPlanListNames'],
    props: {
        bonusPlan: {
            type: Object,
        },
        isEdit: {
            type: Boolean,
        },
    }
});
