import localforage from 'localforage';


const KEY_API_TOKEN = 'token';
const KEY_PROFILE_ID = 'profile-id';


class Local {
    logout = () => {
        return new Promise((resolve, reject) => {
            return Promise.all([
                this.setToken(null),
                this.setProfileId(null),
            ])
                .then(resolve)
                .catch((...args) => {
                    reject(...args);
                });
        });
    }

    setProfileId = (id) => {
        return localforage.setItem(KEY_PROFILE_ID, id);
    }

    getProfileIdPromise = () => {
        return localforage.getItem(KEY_PROFILE_ID);
    }

    setToken = (token) => {
        return localforage.setItem(KEY_API_TOKEN, token);
    }

    getTokenPromise = () => {
        return localforage.getItem(KEY_API_TOKEN);
    }
}

const local = new Local();

// todo dev only
window.__Local = local;

export default local;
