import { Module, VuexModule, getModule, Action } from 'vuex-module-decorators';
import store from '~/store';
import Rest from '~/helpers/Rest';
import {
    urlSymbolsDisplay,
} from '~/settings/ajax-urls';
import { ISymbolsDisplayResponse } from '~/store/modules/symbols-display/symbols-display-interfaces';
import { ITableFilterQuery } from '~/components/tables/interfaces/table-filter-query.interfaces';

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: 'symbolsDisplay',
})

class SymbolsDisplay extends VuexModule {

    @Action
    async fetch (query: ITableFilterQuery = null): Promise<ISymbolsDisplayResponse> {
        const { data, meta } = await Rest.GET(urlSymbolsDisplay(query))
            .then(Rest.middleThen)
            .catch(Rest.simpleCatch);
        return {
            data,
            meta,
        };
    }

}

export const SymbolsDisplayModule = getModule(SymbolsDisplay);
