import { render, staticRenderFns } from "./calls-effectiveness.html?vue&type=template&id=9f5481d6&lang=html&"
import script from "./calls-effectiveness.ts?vue&type=script&lang=ts&"
export * from "./calls-effectiveness.ts?vue&type=script&lang=ts&"
import style0 from "./calls-effectiveness.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports