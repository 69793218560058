import { ETableType } from '~/components/tables/enums/table-type.enum';
import { handlerLeads } from './handler-leads';
import { handlerAffiliates } from './handler-affiliates';
import { handlerAffiliatesReports } from './handler-affiliates-reports';
import { handlerPayments } from './handler-payments';

export const handlerQueriesToFetch  = (queries: object, table: string): object => {
    switch (table) {
    case ETableType.leadsTable:
        return handlerLeads(queries);
    case ETableType.affiliates:
        return handlerAffiliates(queries);
    case ETableType.affiliatesReports:
        return handlerAffiliatesReports(queries);
    case ETableType.withdrawalsHistory:
    case ETableType.paymentHistory:
        return handlerPayments(queries);
    default:
        return queries;
    }
};
