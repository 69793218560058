import queryString from 'query-string';
import { createRequestUrl } from '~/helpers/create-request-url';
import { ERequestUrlPrefix } from '~/settings/enums/request-url-prefix.enum';
import { ITableFilterQuery } from '~/components/tables/interfaces/table-filter-query.interfaces';
import { IReportCreatePayload, IReportExportPayload } from '~/store/modules/reports/reports-interfaces';
import { ISearchMainQuery } from '~/store/modules/search-main/interfaces/search-main-interfaces';
import { IUsersPayload } from '~/store/modules/users/users-interface';
import { IUserManagementPayload } from '~/store/modules/configs/interfaces/user-management.interface';

const configs = 'configs';

export const urlDashboardPayments = (query = null): string => createRequestUrl('dashboard/payments', query);
export const urlDashboardClients = (query = null): string => createRequestUrl('dashboard/clients', query);
export const urlDashboardPlatform = (query = null): string => createRequestUrl('dashboard/platform', query);
export const urlDashboardVolume = (query = null): string => createRequestUrl('dashboard/volume', query);
export const urlDashboardEvents = (query = null): string => createRequestUrl('dashboard/events', query);

export const urlLogin = `${ERequestUrlPrefix.default}/login`;
export const urlForgotPassword = `${ERequestUrlPrefix.default}/password/email`;
export const urlResetPassword = `${ERequestUrlPrefix.default}/password/reset`;
export const urlProfile = `${ERequestUrlPrefix.default}/profiles`;
export const urlLogout = `${ERequestUrlPrefix.default}/logout`;

export const urlLeads = (query = null): string => createRequestUrl('leads', query);
export const urlLeadsFiltersTemplates = `${ERequestUrlPrefix.default}/users/leads-filters-templates`;
export const urlLeadsFiltersTemplatesUpdate = (userId: number, templateId: number): string => `${ERequestUrlPrefix.default}/users/${userId}/leads-filters-templates/${templateId}`;
export const urlLeadsExports = `${ERequestUrlPrefix.default}/leads/export`;
export const urlLeadsUpdate = `${ERequestUrlPrefix.default}/leads/update-leads`;

export const urlUsersDepartment = `${ERequestUrlPrefix.default}/users/by-department`;
export const urlUsers = (query: ITableFilterQuery | IUsersPayload = null): string => createRequestUrl('users', query);
export const urlUsersUpdate = (userId: number): string => `${ERequestUrlPrefix.default}/users/${userId}`;
export const urlUserDetails = (userId: number): string => `${ERequestUrlPrefix.default}/users/${userId}/details`;
export const urlUsersUpdatePermissions = (userId: number): string => `${ERequestUrlPrefix.default}/users/${userId}/permissions`;
export const urlUsersMassiveUpdate = `${ERequestUrlPrefix.default}/users/massive-update`;

export const urlDocumentsRequests = (client_id: number): string => `${ERequestUrlPrefix.default}/compliance-documents/${client_id}/requests`;
export const urlDocumentsCreatedRequests = (client_id: number): string => `${ERequestUrlPrefix.default}/compliance-documents/${client_id}/requests`;
export const urlDocumentRequestDelete = (client_id: number, id: number): string => `${ERequestUrlPrefix.default}/compliance-documents/${client_id}/requests/${id}`;

export const urlLegalDocuments = `${ERequestUrlPrefix.default}/legal-documents`;
export const urlLegalDocumentStatusUpdate = (document_id: number): string => `${ERequestUrlPrefix.default}/legal-documents/${document_id}`;
export const urlLegalDocument = (document_id: number): string => `${ERequestUrlPrefix.default}/legal-documents/${document_id}/file`;

export const urlAffiliates = (query = null): string => createRequestUrl('affiliates', query);
export const urlAffiliatesSearch = (query: { search: string }): string => createRequestUrl('affiliates/search', query);
export const urlAffiliatesMassiveUpdate = `${ERequestUrlPrefix.default}/affiliates/massive-update`;
export const urlAffiliatesDetails = (id: number): string => `${ERequestUrlPrefix.default}/affiliates/${id}/details`;
export const urlAffiliatesDetailsUpdate = (id: number): string => `${ERequestUrlPrefix.default}/affiliates/${id}`;
export const urlAffiliatesDocumentsList = (affiliateId: number, query = null): string => createRequestUrl(`${affiliateId}/affiliate-documents`, query);
export const urlAffiliatesDocumentsFile = (affiliateId: number, documentId: number): string => `${ERequestUrlPrefix.default}/${affiliateId}/affiliate-documents/${documentId}/file`;
export const urlAffiliatesDocumentsDelete = (affiliateId: number, documentId: number): string => `${ERequestUrlPrefix.default}/${affiliateId}/affiliate-documents/${documentId}`;

export const urlCampaignsList = (query = null): string => createRequestUrl('campaigns', query);
export const urlCampaignsPost = `${ERequestUrlPrefix.default}/campaigns/store`;
export const urlCampaignUpdate = (campaign_id: number): string => `${ERequestUrlPrefix.default}/campaigns/${campaign_id}/update`;
export const urlCampaignDelete = (campaign_id: number): string => `${ERequestUrlPrefix.default}/campaigns/${campaign_id}/delete`;
export const urlCampaignMassiveUpdate = (ids: number[]): string => `${ERequestUrlPrefix.default}/campaigns/massive-update?campaign_ids=${queryString.stringify(ids)}`;
export const urlCampaignMassiveDelete = `${ERequestUrlPrefix.default}/campaigns/massive-delete`;
export const urlCampaignsReports = (query = null): string => createRequestUrl('campaigns/report', query);

export const urlAffiliatesConfig = `${ERequestUrlPrefix.default}/configs/affiliates`;
export const urlAffiliatesReports = (query = null): string => createRequestUrl('affiliates/report', query);

export const urlImportHeader = `${ERequestUrlPrefix.default}/leads/import-header`;
export const urlLeadsImport = `${ERequestUrlPrefix.default}/leads/import`;

export const urlSymbols = (query = null): string => createRequestUrl('symbols', query);
export const urlSymbolsUpdate = (symbol: string): string => `${ERequestUrlPrefix.symbols}/update/${symbol}`;
export const urlSymbolsMassiveUpdate = `${ERequestUrlPrefix.symbols}/massive-update`;
export const urlSymbolsDisplay = (query = null): string => createRequestUrl('display-symbols', query);
export const urlImportSymbols = `${ERequestUrlPrefix.symbols}/import`;
export const urlSymbolsConfigs = `${ERequestUrlPrefix.symbols}/configs`;

export const notificationCounter = `${ERequestUrlPrefix.default}/notifications/pending-counter`;
export const urlCampaign = `${ERequestUrlPrefix.default}/${configs}/campaign`;
export const urlConfigsAll = `${ERequestUrlPrefix.default}/${configs}/all`;
export const urlClick2call = `${ERequestUrlPrefix.default}/click2call`;

export const urlBonusPlan = `${ERequestUrlPrefix.default}/bonus-plans`;
export const urlBonusPlanUpdate = (id: number, type: string): string => `${ERequestUrlPrefix.default}/bonus-plans/${id}/update/${type}`;
export const urlBonusPlanDelete = (id: number): string => `${ERequestUrlPrefix.default}/bonus-plans/${id}/delete`;
export const urlBonusPlanCreate = (type: string): string => `${ERequestUrlPrefix.default}/bonus-plans/store/${type}`;

export const urlClients = `${ERequestUrlPrefix.default}/clients`;
export const urlClientDetails = (leadId: number): string => `${ERequestUrlPrefix.default}/clients/${leadId}/details`;
export const urlClientKyc = (leadId: number | string): string => `${ERequestUrlPrefix.default}/clients/${leadId}/kyc`;
export const urlClientActivityLog = (leadId: number, query: ITableFilterQuery): string => createRequestUrl(`clients/${leadId}/activity-log`, query);
export const urlClient = (leadId: number): string => `${ERequestUrlPrefix.default}/clients/${leadId}`;
export const urlClientsSearch = (query: ISearchMainQuery | object): string => createRequestUrl('clients/search', query);
export const urlAccounts = (leadId: number): string => `${ERequestUrlPrefix.default}/clients/${leadId}/accounts`;
export const urlAccountsCreate = (leadId: number): string => `${ERequestUrlPrefix.default}/clients/${leadId}/accounts/create`;

export const urlNotes = (leadId: number): string => `${ERequestUrlPrefix.default}/${leadId}/notes`;

export const urlLeadUpdate = (leadId: number): string => `${ERequestUrlPrefix.default}/clients/${leadId}`;

export const urlComplianceDocumentsFile = (leadId: number, documentId: number): string => `${ERequestUrlPrefix.default}/compliance-documents/${leadId}/${documentId}/file`; // TODO revert
export const urlAutomaticEmailsFile = (leadId: number, documentId: number): string => `${ERequestUrlPrefix.default}/clients/${leadId}/automatic-emails/${documentId}/file`; // TODO revert
export const urlComplianceDocuments = (leadId: number, query?: Object): string => createRequestUrl(`compliance-documents/${leadId}`, query);
export const urlComplianceDocumentsDelete = (leadId: number, documentId: number): string => `${ERequestUrlPrefix.default}/compliance-documents/${leadId}/${documentId}`;
export const urlComplianceDocumentsUpdate = (leadId: number, documentId: number): string => `${ERequestUrlPrefix.default}/compliance-documents/${leadId}/${documentId}`;

export const urlPaymentsHistory = (leadId: number, query: object): string => createRequestUrl(`clients/${leadId}/payments`, query);
export const urlPaymentsPendingCancel = (leadId: number): string => `${ERequestUrlPrefix.default}/clients/${leadId}/payments-pending-cancel`;
export const urlPaymentsPendingApprove = (leadId: number): string => `${ERequestUrlPrefix.default}/clients/${leadId}/payments-pending-approve`;
export const urlPaymentsStore = (leadId: number): string => `${ERequestUrlPrefix.default}/clients/${leadId}/payments-store`;
export const urlPaymentRequisites = (leadId: number, requisiteId = null): string =>  {
    return requisiteId ?
        `${ERequestUrlPrefix.default}/clients/${leadId}/payment-requisites/${requisiteId}` :
        `${ERequestUrlPrefix.default}/clients/${leadId}/payment-requisites`;
};
export const urlPayments = (query: object): string => createRequestUrl('payments', query);
export const urlClientSendEmail = (leadId: number): string => `${ERequestUrlPrefix.default}/clients/${leadId}/send-email`;
export const urlSumValues = (query: object, leadId: number): string => createRequestUrl(`clients/${leadId}/trading/sum-values`, query);
export const urlOpenOrders = (query: object, leadId: number): string => createRequestUrl(`clients/${leadId}/trading/open-orders`, query);
export const urlPendingOrders = (query: object, leadId: number): string => createRequestUrl(`clients/${leadId}/trading/pending-orders`, query);
export const urlOrdersHistory = (query: object, leadId: number): string => createRequestUrl(`clients/${leadId}/trading/orders-history`, query);

export const urlNotification = (query: object): string => createRequestUrl('notifications/client', query);
export const urlNotificationUpdate = (notification_id: number, query = null): string => createRequestUrl(`notifications/${notification_id}/update`, query);
export const urlNotificationActivityLog = (query: object): string => createRequestUrl('notifications/activity-log', query);
export const urlNotificationAffiliates = (query: object): string => createRequestUrl('notifications/affiliate', query);
export const urlNotificationOpen = (notification_id: number): string => `${ERequestUrlPrefix.default}/notifications/${notification_id}/open`;
export const urlDepartmentsGroup = (department = null): string => {
    const baseUrl = `${ERequestUrlPrefix.default}/departments`;
    return department ? `${baseUrl}/${department}` : baseUrl;
};
export const urlUsersManagement = (query: IUserManagementPayload | object): string => createRequestUrl(`${configs}/users-management`, query);
export const urlUsersConfig = (query = null): string => createRequestUrl(`${configs}/users`, query);
export const urlFieldValues = (leadId: number, field: number|string): string => `${ERequestUrlPrefix.default}/clients/${leadId}/field-values/${field}`;
export const urlAutomaticEmails = (leadId: number): string => `${ERequestUrlPrefix.default}/clients/${leadId}/automatic-emails`;
export const urlLeverageUpdate = (leadId: number): string => `${ERequestUrlPrefix.default}/clients/${leadId}/trading/leverage-update`;
export const urlTermsAndConditions = (leadId: number): string => `${ERequestUrlPrefix.default}/clients/${leadId}/terms-and-conditions`;
export const urlSearchLive = (type: string, query: object): string => createRequestUrl(`${type}/search-live`, query);
export const urlReports = (query: ITableFilterQuery | IReportCreatePayload): string => createRequestUrl('reports', query);
export const urlReportsExports = (query: IReportExportPayload): string => createRequestUrl('reports/export', query);

export const urlEmailsFilterClients = (query: object): string => createRequestUrl('clients/bulk-email-filter-clients', query);
export const urlEmailTemplates = `${ERequestUrlPrefix.default}/email-templates`;
export const urlEmailTemplate = (templateId: number): string => `${ERequestUrlPrefix.default}/email-templates/${templateId}`;

export const urlMarketingList = `${ERequestUrlPrefix.marketing}/lists`;
export const urlMarketingTemplates = `${ERequestUrlPrefix.marketing}/templates`;
export const marketingTemplate = (templateId: number): string => `${ERequestUrlPrefix.marketing}/templates/${templateId}`;
export const urlMarketingSendEmail = `${ERequestUrlPrefix.marketing}/send-email`;

export const urlEmailBulkSend = `${ERequestUrlPrefix.default}/clients/bulk-email-send`;
