import { isEmpty } from 'lodash';
import toggleHistoryMixin from '~/components/mixins/toggle-history-mixin';
import appHistoryKyc from '~/components/parts/app-history-kyc/app-history-kyc.vue';
import kycTabItemHistoryButton from '@/js/components/KYC/KycTabItem/KycTabItemActionButtons/kyc-tab-item-history-button/kyc-tab-item-history-button.vue';
import { getStringFromFieldValueData } from '~/components/KYC/kyc-helpers';

export default {
    props: {
        label : {
            type : String,
            required: true,
        },
        display : {
            type : [String, Number, Object, Array],
            required: false,
        },
    },
    components: {
        appHistoryKyc,
        kycTabItemHistoryButton,
    },
    mixins: [
        toggleHistoryMixin,
    ],
    computed: {
        // used in toggleHistoryMixin
        fieldId (): number|null {
            return this.display?.field?.id || null;
        },
        readOnlyValue (): string|null {
            return getStringFromFieldValueData(this.display, {
                visibleCustom: true,
            }) || '-';
        },
        isFieldId (): boolean {
            return !isEmpty(this.display);
        }
    },
};
