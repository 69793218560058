import togglePhoneMixin from '~/components/mixins/togglePhoneMixin';
import clickCallMixin from '~/components/mixins/click-call-mixins';
import { LeadDetailsEnums } from '~/components/leadsItem/LeadDetails/enums/lead-details.enums';

export default {
    data (): Object {
        return {
            typeLeadDetails: LeadDetailsEnums
        };
    },
    mixins: [
        togglePhoneMixin,
        clickCallMixin
    ],
    props: {
        className: {
            type: String,
            required: false,
        },
        leadId: {
            type: Number,
            required: false
        },
        isFirst: {
            type: Boolean,
            default: true,
            required: false
        },
        icon: {
            type: String,
            default: 'call',
        },
        iconColor: {
            type: String,
            default: 'green',
        }
    },
    computed: {
        phone1st (): boolean {
            return this.isFirst;
        },
    },
    methods: {
        async clickPhone (typeLine: number): Promise<void> {
            this.hidePhone();

            if (typeLine === LeadDetailsEnums.MainLine) {
                await this.clickCall(this.leadId, this.phone1st, true);
            } else {
                await this.clickCall(this.leadId, this.phone1st, false);
            }
        }
    }
};
