import { extend, configure, localize } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import en from 'vee-validate/dist/locale/en.json';

// eslint-disable-next-line no-unused-vars
import './vee-validate-rules/phone-number-validation';
import './vee-validate-rules/currency-multiselect-validation';
import './vee-validate-rules/password-validation';
import './vee-validate-rules/email-validation';
import './vee-validate-rules/check-repeat-name-validation';

Object.keys(rules).forEach(rule => {
    extend(rule, rules[rule]);
});

localize('en', en);

configure({
    classes: {
        valid: 'is-valid',
        invalid: 'is-invalid',
        dirty: ['is-dirty', 'is-dirty'] // multiple classes per flag!
    }
});
