import { ITableHeaderColumn } from '~/components/tables/interfaces/table-header-column.interfaces';
import { ETableFilterInputType, ETableFilterType } from '~/components/tables/enums/table-filter-type.enum';
import { tableHeaderParser } from '~/components/tables/helpers/table-header-parser';

export const usersTableHeader: ITableHeaderColumn[] = tableHeaderParser([
    {
        name: 'fname',
        value: 'First Name',
        id: 2,
        cellType: 'plain-text',
        cellData: 'fname',
        filterType: ETableFilterType.input,
        inputType: ETableFilterInputType.text,
    },
    {
        name: 'lname',
        value: 'Last Name',
        id: 3,
        cellType: 'plain-text',
        cellData: 'lname',
        filterType: ETableFilterType.input,
        inputType: ETableFilterInputType.text,
    },
    {
        name: 'username',
        value: 'Username',
        id: 4,
        cellType: 'plain-text',
        cellData: 'username',
        filterType: ETableFilterType.input,
        inputType: ETableFilterInputType.text,
    },
    {
        name: 'email',
        value: 'Email',
        id: 5,
        cellType: 'plain-text',
        cellData: 'email',
        filterType: ETableFilterType.input,
        inputType: ETableFilterInputType.text,
        cellClass: 'text-lowercase',
    },
    {
        name: 'country_id',
        value: 'Country',
        id: 6,
        cellType: 'flag-country',
        cellData: 'country',
        filterType: ETableFilterType.select,
    },
    {
        name: 'language_a2',
        value: 'Language',
        id: 7,
        cellType: 'flag-country',
        cellData: 'language_a2',
        filterType: ETableFilterType.select,
    },
    {
        name: 'department',
        value: 'Department',
        id: 9,
        cellType: 'key-from-object',
        cellData: 'department',
        cellDataKey: 'name',
        filterType: ETableFilterType.select,
    },
    {
        name: 'bonus_plan',
        value: 'Bonus Plan',
        id: 10,
        cellType: 'key-from-object',
        cellData: 'bonus_plan',
        cellDataKey: 'name',
        filterType: ETableFilterType.select,
    },
    {
        name: 'activity',
        value: 'Status',
        id: 11,
        cellType: 'activity-status',
        cellData: 'activity',
        cellDataKey: 'name',
        filterType: ETableFilterType.select,
    },
    {
        name: 'options',
        value: 'Options',
        id: 12,
        cellType: 'users-options',
        cellData: ''
    }
]);
