<template>
    <div class="readonly-block">
        <kyc-tab-item
            v-if="!isObject"
            v-bind="$attrs"
            :display="initial"
            :editable="false"
        />
        <kyc-tab-item-read-only-object
            v-else
            v-bind="$attrs"
            :display="initial"
            :editable="false"
        />
    </div>
</template>

<script>
import KycTabItem from './KycTabItem';
import KycTabItemReadOnlyObject from './kyc-tab-item-read-only-object/kyc-tab-item-read-only-object.vue';

export default {
    components: {
        KycTabItem,
        KycTabItemReadOnlyObject
    },
    props: {
        initial: {
            type: [String, Number, Object, Array],
            default: '',
            required: false,
        },
        isObject: {
            type: Boolean,
            default: false,
            required: false,
        }
    },
};
</script>
