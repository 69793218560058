import Vue from 'vue';
import appCheckbox from '~/components/parts/app-checkbox/app-checkbox.vue';
import fileUploader from './uploaded-documents-uploader/uploaded-documents-uploader.vue';
import { ComplianceUploadedDocumentsModule } from '~/store/modules/documents/uploadedDocuments';
import configsMixin from '~/components/mixins/configs-mixin';
import { EAffiliatesDocumentStatuses } from '~/settings/enums/affiliates-document-statuses.enum';
import {
    UploadedDocumentsUploaderEnum,
    DocumentTypesEnum,
} from '~/components/uploaded-documents/uploaded-documents-uploader/enums/uploaded-documents-uploader.enum';
import { DocumentTypesIdsEnum } from '~/store/modules/documents/enums/document-types.enum';
import { UploadedDocumentsTypeIdsEnum } from '~/components/uploaded-documents/uploaded-documents.enum';
import { handlerRoutesQuery } from '~/helpers/handler-routes-query';
import { UploadedDocumentsEnum } from '~/store/modules/documents/enums/uploaded-documents.enum';
import { IAffiliatesDocument } from '~/store/modules/affiliates/interfaces/affiliates-documents-interfaces';
import { IClientDocument } from '~/store/modules/documents/interfaces/upload-documents-interfaces';

export default Vue.extend({
    components: {
        fileUploader,
        appCheckbox,
    },
    mixins: [configsMixin],
    props: {
        type: {
            type: String,
        },
        documentTypes: {
            type: Array,
        },
        statuses: {
            type: Array,
        },
        documents: {
            type: Array,
        },
        documentsCheckPending: {
            type: Array,
        },
        hiddenDocuments: {
            type: Boolean,
            default: false,
        },
        disabledStatuses: {
            type: Boolean,
            default: false,
        },
        disabledFiltered: {
            type: Boolean,
            default: false,
        },
        startDocumentType: {
            type: [String, Number],
            default: DocumentTypesEnum.first,
        }
    },
    data () {
        return {
            isAll: true,
            isUpload: false,
            activeTypeId: null,
            activeStatus: 0,
        };
    },
    computed: {
        checked: {
            get () {
                return !ComplianceUploadedDocumentsModule.getIsEmailAble;
            },
        },
        documentsList (): IAffiliatesDocument[] {
            return this.disabledFiltered ? this.documents : this.documentFiltered();
        },
        isExpiredDateVisible (): boolean {
            return this.activeTypeId === DocumentTypesEnum.passportId; // TODO
        },
        isUploadedDateVisible (): boolean {
            return this.activeTypeId !== UploadedDocumentsTypeIdsEnum.AutoEmails;
        }
    },
    mounted () {
        this.handlerQuery();
    },
    methods: {
        getDateUploadDocument (document: IClientDocument): string {
            return document?.status_at && `${document.status_at.date}, ${document.status_at.time}` || '-';
        },
        documentFiltered (): IAffiliatesDocument[] { // TODO
            return this.documents.filter(item => {
                switch (this.activeTypeId) {
                case UploadedDocumentsTypeIdsEnum.AuthorisationDepositCards :
                    return this.checkDocumentStatus(item, UploadedDocumentsTypeIdsEnum.AuthorisationDepositCards) ||
                        this.checkDocumentStatus(item, UploadedDocumentsTypeIdsEnum.AuthorisationDepositWireTransfer) ||
                        this.checkDocumentStatus(item, UploadedDocumentsTypeIdsEnum.AuthorisationDepositEWallets);
                default:
                    return this.checkDocumentStatus(item, this.activeTypeId);
                }
            });
        },
        checkDocumentStatus (document, typeId): boolean { // TODO
            const statusId = this.activeStatus;
            const isType = document.type_id === typeId;
            return statusId ?
                isType && document.status_id === statusId :
                isType;
        },
        handlerQuery () {
            const query = this.$route.query;
            if (query && query[UploadedDocumentsEnum.TypeIds]) {
                const _currentId = query[UploadedDocumentsEnum.TypeIds].slice(',').length > 1 ?
                    UploadedDocumentsTypeIdsEnum.AuthorisationDepositCards :
                    query[UploadedDocumentsEnum.TypeIds];
                this.$set(this.$data, 'activeTypeId', +_currentId);
            } else {
                this.$set(this.$data, 'activeTypeId', this.startDocumentType);
            }

            if (query && query[UploadedDocumentsEnum.StatusId]) {
                this.$set(this.$data, 'activeStatus', +query[UploadedDocumentsEnum.StatusId]);
            }
        },
        handleChangeEmailable (is_checked: boolean): void {
            ComplianceUploadedDocumentsModule.setUploaderEmailAble(!is_checked); // TODO
        },
        closeUploader (): void {
            this.isUpload = false;
        },
        isActive (statusId:  number): boolean {
            const query = this.$route.query;
            this.isAll = query.status_id ? false : true;
            return statusId === this.activeStatus;
        },

        openUploader () {
            this.isUpload = true;
        },
        selectStatusType (id: number): void {
            const _id = id === UploadedDocumentsTypeIdsEnum.All ? null : String(id);
            this.$emit('changeStatus', id);
            this.$set(this.$data, 'activeStatus', id);
            handlerRoutesQuery(UploadedDocumentsEnum.StatusId, _id);
        },
        selectDocumentTypes (id: number) {
            this.$emit('changeType', id);
            const _id = this.handlerSelectDocumentTypesToArray(id);
            this.$set(this.$data, 'activeTypeId', id);
            handlerRoutesQuery(UploadedDocumentsEnum.TypeIds, _id);
        },
        lengthPendingDocuments (id: number): boolean { // TODO
            const _ids = id === DocumentTypesIdsEnum.AuthorisationDepositId ?
                [
                    DocumentTypesIdsEnum.AuthorisationDepositId,
                    DocumentTypesIdsEnum.AuthorisationDepositTransfer,
                    DocumentTypesIdsEnum.AuthorisationDepositEWallets,
                ] :
                [id];
            const documents = this.documentsCheckPending && this.documentsCheckPending.length > 0 ?
                this.documentsCheckPending :
                this.documents;

            return documents
                ?.filter(item => _ids.includes(item.type_id) && (item.status_id === EAffiliatesDocumentStatuses.pending)).length;
        },
        selectDocument (document: IAffiliatesDocument) {
            this.$emit('selectDocument', document);
        },
        uploadDocument (document) {
            document.append(UploadedDocumentsUploaderEnum.typeId, this.activeTypeId);
            this.$emit('upload', document);
            this.closeUploader();
        },
        getDocumentStatusSrc (statusId: number): string {
            switch (statusId) { // TODO
            case EAffiliatesDocumentStatuses.pending:
                return '/img/clients/kyc/pending.svg';
            case EAffiliatesDocumentStatuses.approved:
                return '/img/clients/kyc/verified.svg';
            case EAffiliatesDocumentStatuses.rejected:
                return '/img/clients/kyc/rejected.svg';
            }
        },
        handlerSelectDocumentTypesToArray (id: number): number[] { // TODO
            if (id === UploadedDocumentsTypeIdsEnum.AuthorisationDepositCards) {
                return [
                    UploadedDocumentsTypeIdsEnum.AuthorisationDepositCards,
                    UploadedDocumentsTypeIdsEnum.AuthorisationDepositWireTransfer,
                    UploadedDocumentsTypeIdsEnum.AuthorisationDepositEWallets,
                ];
            } else {
                return [id];
            }
        },
    }
});
