import TheWrapper from '../theWrapper.vue';
import leadsFilterRow from './leadsFilterRow.vue';
import { mapGetters, mapActions } from 'vuex';
import routes from '~/settings/routes';
import {
    getterLoad,
    getterUpdatedTableRows,
    actionHandleClear,
    actionHandleSetFilters,
    getterSelectedColumnsInTable,
} from '~/store/modules/leads';
import rootTable from '~/components/tables/root-table/root-table.vue';
import { UsersDepartmentModule } from '~/store/modules/users-department/users-department';
import { ETableType } from '~/components/tables/enums/table-type.enum';
import { ModalsModule } from '~/store/modules/modals';
import { ETableFilterKeyLeads, ETableFilterKeyPagination } from '~/components/tables/enums/table-filter-key.enum';
import { EBooleanOptions } from '~/settings/enums/boolean-options.enum';
import { ITableCell } from '~/components/tables/interfaces/table-cell-interfaces';
import { ITableHeaderColumn } from '~/components/tables/interfaces/table-header-column.interfaces';
import { tableHeaderParser } from '~/components/tables/helpers/table-header-parser';
import { tableParser } from '~/helpers/table-parser';
import { isPermissionMultipleEditClients } from '~/helpers/user-permission';
import { setQuaryPerPage } from '~/components/tables/helpers/setQuaryPerPage';
import { handlerLoadTable } from '~/components/tables/helpers/handler-load-table';
import { PaginationEnum } from '~/settings/enums/pagination.enum';

export default {
    components: {
        TheWrapper,
        leadsFilterRow,
        rootTable,
    },
    computed: {
        ...mapGetters({
            load: getterLoad,
        }),
        isPermissionMultipleEditClients,
        tableRows (): ITableCell[] {
            return tableParser(this.tableHeaders, this.$store.getters[getterUpdatedTableRows]);
        },
        tableHeaders (): ITableHeaderColumn[] {
            return tableHeaderParser(this.$store.getters[getterSelectedColumnsInTable]);
        },
        loading (): boolean {
            return this.load.isLoaded;
        },
        tableType (): string {
            return ETableType.leadsTable;
        },
    },
    created (): void {
        if (!Object.keys(this.$route.query).length) {
            this.$router.replace({ query: {
                [ETableFilterKeyLeads.is_test]: EBooleanOptions.no
            } });
        }

        if (!this.$route.query[ETableFilterKeyPagination.perPage]) {
            this.$router.replace({ query: {
                ...this.$route.query,
                [ETableFilterKeyPagination.perPage]: PaginationEnum.hundredRows,
            } });
        }

        this.getColumnFilterFromRouter();
        this.loadLeads();
    },
    destroyed (): void {
        this.handleClear();
    },
    mounted (): void {
        this.loadUserDepartment();
    },
    methods: {
        ...mapActions({
            handleClear: actionHandleClear,
            getColumnFilterFromRouter: actionHandleSetFilters,
        }),
        loadLeads (): void {
            setQuaryPerPage(this.tableType);
            handlerLoadTable(this.tableType);
        },
        loadUserDepartment (): void {
            UsersDepartmentModule.loadUsersDepartment();
        },
        addNewItem (): void {
            ModalsModule.openModal({
                modalType: 'addLead',
                props: {
                    title: 'New Lead',
                    body: {
                        button: {
                            text: 'Add'
                        }
                    },
                    isHeader: true,
                    isFooter: true
                },
                modalSettings: {
                    props: {
                        minWidth: ModalsModule.modalWidth.lg,
                    },
                },
            });
        },
        editMultipleLeads (): void {
            ModalsModule.openModal({
                modalType: 'editLeadModal',
                props: {
                    title: 'Edit Multiple Leads',
                    body: {},
                    isHeader: true,
                    isFooter: true
                },
                modalSettings: {
                    props: {
                        minWidth: ModalsModule.modalWidth.lg,
                    },
                },
                addClass: 'modal_filters'
            });
        },
        goToDetails (rowId: number): void {
            const route = this.$router.resolve(routes.leadsItem.subRoutes.details._getPath(rowId));
            window.open(route.href, '_blank');
        },
        resetFilter (): void {
            this.$refs.leadsFilterRow.handlerFilterByStatusDefault();
        },
    }
};
