export const LeadsKycOtpTableHeader = [
    {
        id: 1,
        name: 'id',
        value: 'ID',
        cellType: 'plain-text',
        cellData: 'id'
    },
    {
        id: 2,
        name: 'date',
        value: 'Date',
        cellType: 'plain-text',
        cellData: 'date'
    },
    {
        id: 3,
        name: 'sent',
        value: 'OTP Sent',
        cellType: 'plain-text',
        cellData: 'sent'
    },
    {
        id: 4,
        name: 'action',
        value: 'Customer’s Action',
        cellType: 'plain-text',
        cellData: 'action'
    }
];

export const LeadsKycOtpTableItems = [
    {
        id: 27723,
        date: '17.01.2020',
        sent: 'Successful',
        action: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore'
    },
    {
        id: 38828,
        date: '09.01.2019',
        sent: 'Successful',
        action: 'Eolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo'
    },
    {
        id: 38381,
        date: '17.04.2018',
        sent: 'Unsuccessful',
        action: 'Consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu #'
    },
];
