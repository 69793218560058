import Vue from 'vue';
import configsMixin from '~/components/mixins/configs-mixin';
import moment from 'moment';
import rowItemPropsMixin from '~/components/tables/mixins/table-cells-mixins/row-item-props-mixin';
import { ITimezone } from '~/store/modules/configs/interfaces/configs-interface';

export default Vue.extend({
    mixins: [
        configsMixin,
        rowItemPropsMixin,
    ],
    data () {
        return {
            format: 'HH:mm:ss',
        };
    },
    computed: {
        isGeoTz (): string {
            return this.getTimezoneOffsetFormat(this.cell.value.geo_tz_id);
        },
    },
    methods: {
        getTimezoneOffsetFormat (id: number): string {
            const _res = this.getConfigTimezones.find((timezone: ITimezone) => timezone.id === id);
            return _res ? moment().utcOffset(_res.offset).format(this.format) : 'N/A';
        }
    }
});
