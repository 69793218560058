import { KycClientModule } from '~/store/modules/kyc-client/kyc-client';

export default {
    data (): Object {
        return {
            isHistoryOpen : false,
            afterHistoryBtnClick: false,
        };
    },
    methods : {
        toggleHistoryOpen (): void {
            this.isHistoryOpen = !this.isHistoryOpen;
        },
        showHistory (): void {
            this.isHistoryOpen = true;
        },
        hideHistory (): void {
            this.isHistoryOpen = false;
        },
        closeHistory (): void {
            if (this.afterHistoryBtnClick) {
                this.afterHistoryBtnClick = false;
            } else {
                this.hideHistory();
            }
        },
        async history (): Promise<void> {
            this.toggleHistoryOpen();
            this.afterHistoryBtnClick = true;
            await KycClientModule.handleClearHistoryField([]);

            if (this.isHistoryOpen) {
                // this.fieldId taking from component
                await KycClientModule.setHistoryField(this.fieldId);
            }
        },
    }
};
