import Vue from 'vue';
import Multiselect from 'vue-multiselect';
import subNavbar from '~/components/subNavbar/subNavbar.vue';
import subNavbarLink from '~/components/subNavbar/subNavbarLink.vue';
import subNavbarSeparator from '~/components/subNavbar/subNavbarSeparator.vue';
import AccountingReportsTable from './accounting-reports-table/accounting-reports-table.vue';

export default Vue.extend({
    components: {
        'sub-navbar': subNavbar,
        'sub-navbar-link': subNavbarLink,
        'sub-navbar-separator': subNavbarSeparator,
        'accounting-reports-table': AccountingReportsTable,
        Multiselect,
    },
    data () {
        return {
            options: [1, 2, 3],
            nameSelected: '',
            selectedDate: ''
        };
    },
    methods: {
        calendarSelect (event: object): void {
            this.selectedDate = event['fullDate'];
        }
    }
});
