import Vue from 'vue';
import appMoreOptions from '~/components/parts/app-more-options/app-more-options.vue';
import { ReportsModule } from '~/store/modules/reports/reports';
import { ProfileModule } from '~/store/modules/profile/profile';
import { IConfig } from '~/settings/interfaces/config-default-interfaces';
import { ITableHeaderColumn } from '~/components/tables/interfaces/table-header-column.interfaces';
import { ITableCell } from '~/components/tables/interfaces/table-cell-interfaces';
import DatePicker from 'vue2-datepicker';
import { parseDate } from '~/helpers/common';
import { handlerRoutesQuery } from '~/helpers/handler-routes-query';
import { ETableType } from '~/components/tables/enums/table-type.enum';
import { ETableFilterKeReports } from '~/components/tables/enums/table-filter-key.enum';
import { EMoreOptions } from '~/settings/enums/more-options.enum';
import { RootTableModule } from '~/store/modules/root-table/root-table';
import { ModalsModule } from '~/store/modules/modals';
import { ITableSelectAllRows } from '~/components/tables/interfaces/table-interfaces';
import { defaultTableHeader } from '~/components/tables/_headers/_default';
import { tableHeaderParser } from '~/components/tables/helpers/table-header-parser';
import { tableParser } from '~/helpers/table-parser';
import { IReports } from '~/store/modules/reports/reports-interfaces';
import { setQuaryPerPage } from '~/components/tables/helpers/setQuaryPerPage';

export default Vue.extend({
    components: {
        appMoreOptions,
        DatePicker,
    },
    data () {
        return {
            selectedAllPages: null,
            selectedDate: null,
            selectedDateFormat: null,
            selectedTemplate: null,
            isTooltip: {
                title: 'Select date',
                disabled: true,
            },
        };
    },
    computed: {
        getReportsHeader (): ITableHeaderColumn[] {
            return ReportsModule.getReportsHeader;
        },
        getReports (): IReports[] {
            return ReportsModule.getReports;
        },
        tableHeader (): ITableHeaderColumn[] {
            return this.selectedTemplate?.id ?
                this.generateTableHeader() :
                defaultTableHeader;
        },
        tableRows (): ITableCell[] {
            return tableParser(this.tableHeader, this.getReports);
        },
        isSelectedTemplate (): number {
            return this.selectedTemplate && this.selectedTemplate.id;
        },
        reportsTemplate (): IConfig[] {
            return ProfileModule.getReportTemplates;
        },
        tableType (): string {
            return ETableType.reports;
        },
        getReportsIsLoaded (): boolean {
            return ReportsModule.getReportsIsLoaded;
        },
        getSelectedRows (): number[] {
            return RootTableModule.getSelectedRows;
        },
        isVisibleTable (): boolean {
            return this.isSelectedTemplate && this.selectedDate;
        },
        isSelectRowAll (): boolean {
            return this.tableRows.length > 0;
        }
    },
    mounted (): void {
        this.handleLoadQuery();
    },
    methods: {
        disabledDate (date: Date): boolean {
            const yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 2);
            return date >= yesterday;
        },
        resetFilters () {
            this.selectedDate = null;
            this.selectedDateFormat = null;
            handlerRoutesQuery(ETableFilterKeReports.template_id, null);
            handlerRoutesQuery(ETableFilterKeReports.day, null);
        },
        setFocusDatePicker () {
            this.$nextTick(() => {
                this.setTooltipState(false);
                this.$refs.datePicker.focus();
            });
        },
        handlerSelectTemplate (event: IConfig): void {
            handlerRoutesQuery(ETableFilterKeReports.template_id, String(event.id));
            this.setFocusDatePicker();
        },
        handlerSelectDate (event: string): void {
            this.selectedDateFormat = event && parseDate(event, 'YYYY-MM-DD').format('YYYY-MM-DD');
            handlerRoutesQuery(ETableFilterKeReports.day, this.selectedDateFormat);
            this.setTooltipState(true);

            if (event) {
                setQuaryPerPage(this.tableType);
                this.loadReports();
            }

            if (!event) this.setFocusDatePicker();
        },
        setTooltipState (disabled: boolean): void {
            this.$set(this.isTooltip, 'disabled', disabled);

            if (disabled) {
                this.$root.$emit('bv::hide::popover');
            } else {
                this.$root.$emit('bv::show::popover', 'trigger-date-picker-tooltip');
            }
        },
        handleLoadQuery () {
            const query = this.$route.query;

            if (Object.values(query).length) {
                const day = query.day;
                const template = query.template_id &&
                    this.reportsTemplate.find(item => item.id === Number(query.template_id));

                this.selectedDate = day && new Date(day);
                this.selectedDateFormat = day && parseDate(day, 'YYYY-MM-DD').format('YYYY-MM-DD');
                this.selectedTemplate = template;

                if (this.selectedTemplate && !day) {
                    this.$nextTick(() => this.setFocusDatePicker());
                }

                if (this.selectedTemplate && day) {
                    this.loadReports();
                }
            }
        },
        async loadReports (): Promise<void> {
            await ReportsModule.loadReports(this.$route.query);
        },
        handlerMoreOptionsSelect (event: IConfig): void {
            switch (event.id) {
            case EMoreOptions.export:
                this.handlerExport();
                break;
            }
        },
        handlerExport (): void {
            if (this.getSelectedRows.length === 0) {
                this.$root.$emit('bv::enable::popover', 'trigger-tooltip-table-select-all');
                this.$root.$emit('bv::show::popover', 'trigger-tooltip-table-select-all');
            } else {
                const ids = this.selectedAllPages ?
                    null :
                    this.getSelectedRows;
                ReportsModule.exportReports({
                    day: this.selectedDateFormat,
                    template_id: this.selectedTemplate.id,
                    ids: ids,
                });
            }

        },
        disableTooltip (): void {
            this.$root.$emit('bv::disable::popover', 'trigger-tooltip-table-select-all');
            this.$root.$emit('bv::hide::popover', 'trigger-tooltip-table-select-all');
        },
        selectAllRows (event: ITableSelectAllRows): void {
            this.selectedAllPages = false;
            this.disableTooltip();
            if (!event.state) {
                ModalsModule.openModal({
                    modalType: 'actionModal',
                    props: {
                        title: 'Info',
                        body: {
                            text: 'Do you want to expand selection to all pages?',
                        },
                        isHeader: true,
                        isFooter: false,
                        confirm: () => {
                            this.selectedAllPages = true;
                        },
                    },
                    modalSettings: {
                        props: {
                            minWidth: ModalsModule.modalWidth.xs,
                        },
                        positionModal: {
                            // @ts-ignore
                            clientX: event.event.clientX,
                            // @ts-ignore
                            clientY: event.event.clientY,
                            modalWidth: ModalsModule.modalWidth.xxs,
                        }
                    },
                });
            }
        },
        reportCreating (): void {
            ModalsModule.openModal({
                modalType: 'reportCreating',
                props: {
                    title: 'Create Report',
                    isHeader: true,
                    isFooter: false,
                },
            });
        },
        generateTableHeader (): ITableHeaderColumn[] {
            const header = this.selectedTemplate.columns.reduce((result, current) => {
                const header = this.getReportsHeader
                    .find(header => header.id === current.id);
                result.push({
                    ...header,
                    value: current.name,
                });
                return result;
            }, []);
            return tableHeaderParser(header);
        }
    }
});
