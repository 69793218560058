import Vue from 'vue';
import toggleMixin from '~/components/mixins/toggleMixin';

export default Vue.extend({
    mixins: [
        toggleMixin
    ],
    props: {
        options: {
            type: Array,
            default: () => [],
        },
        label: {
            type: String,
        },
        trackBy: {
            type: String,
        },
        icon: {
            type: String,
        },
        customSize: {
            type: String,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data (): Object {
        return {
            className: 'app-dropdown-btn',
            afterBtnClick: false,
        };
    },
    methods: {
        clickOnBody (event: any) {
            const target = event.target;
            if (target.classList.contains === this.className || target.closest(`.${this.className}`)) {
                console.log();
            } else {
                this.closeList();
            }
        },
        openBtnList (): void {
            this.toggleOpen();
            if (this.isOpen) {
                this.afterBtnClick = true;
            }
            setTimeout(() => {
                this.$root.$el.addEventListener('click', this.clickOnBody);
            }, 100);
        },
        closeList () {
            if (this.afterBtnClick) {
                this.afterBtnClick = false;
            } else {
                this.hide();
                setTimeout(() => {
                    this.$root.$el.removeEventListener('click', this.clickOnBody);
                }, 100);
            }
        },
        handlerClick (event: object) {
            this.$emit('select', event);
        },
    }
});
