import Vue from 'vue';
import tableFiltersMixin from '~/components/mixins/table-filters-mixin';
// @ts-ignore
import accountNumber from '~/components/tables/table-cells/account-number/account-number';
// @ts-ignore
import accountStatus from '~/components/tables/table-cells/account-status/account-status.vue';
// @ts-ignore
import activityStatus from '~/components/tables/table-cells/activity-status/activity-status';
// @ts-ignore
import affiliateProfit from '~/components/tables/table-cells/affiliate-profit/affiliate-profit';
import amount from '~/components/tables/table-cells/amount/amount.vue';
import callDuration from '~/components/tables/table-cells/call-duration/call-duration.vue';
import callRecord from '~/components/tables/table-cells/call-record/call-record.vue';
import callsActivity from '~/components/tables/table-cells/calls-activity/calls-activity.vue';
import callsAnswered from '~/components/tables/table-cells/calls-answered/calls-answered.vue';
import callsDestinations from '~/components/tables/table-cells/calls-destinations/calls-destinations.vue';
import callsDuration from '~/components/tables/table-cells/calls-duration/calls-duration.vue';
import callEffectiveness from '~/components/tables/table-cells/calls-effectiveness/calls-effectiveness.vue';
import callsTime from '~/components/tables/table-cells/calls-time/calls-time.vue';
import countryFindBy from '~/components/tables/table-cells/country-find-by/country-find-by.vue';
// @ts-ignore
import emptyCell from '~/components/tables/table-cells/empty-cell/empty-cell';
// @ts-ignore
import flagCountry from '~/components/tables/table-cells/flag-country/flag-country';
// @ts-ignore
import ipAddress from '~/components/tables/table-cells/ip-address/ip-address';
// @ts-ignore
import itemLink from '~/components/tables/table-cells/item-link/item-link.vue';
// @ts-ignore
import leadsLink from '~/components/tables/table-cells/leads-link/leads-link';
// @ts-ignore
import options from '~/components/tables/table-cells/options/options';
// @ts-ignore
import paymentStatus from '~/components/tables/table-cells/payment-status/payment-status';
import phoneNumber from '~/components/tables/table-cells/phone-number/phone-number.vue';
// @ts-ignore
import plainTextCopy from '~/components/tables/table-cells/plain-text-copy/plain-text-copy.vue';
// @ts-ignore
import plainTextLeft from '~/components/tables/table-cells/plain-text-left/plain-text-left';
// @ts-ignore
import plainText from '~/components/tables/table-cells/plain-text/plain-text';
import plainTextTooltip from '~/components/tables/table-cells/plain-text-tooltip/plain-text-tooltip.vue';
import plainNumber from '~/components/tables/table-cells/plain-number/plain-number.vue';
import plainSum from '~/components/tables/table-cells/plain-sum/plain-sum.vue';
import plainSumPercentage from '~/components/tables/table-cells/plain-sum-percentage/plain-sum-percentage.vue';
import websiteList from '~/components/tables/table-cells/website-list/website-list.vue';
// @ts-ignore
import tradingProfit from '~/components/tables/table-cells/profit/profit';
// @ts-ignore
import reportsActivity from '~/components/tables/table-cells/reports-activity/reports-activity';
// @ts-ignore
import textStatus from '~/components/tables/table-cells/text-status/text-status.vue';
// @ts-ignore
import usersOptions from '~/components/tables/table-cells/users-options/users-options';
// @ts-ignore
import withdrawalOptions from '~/components/tables/table-cells/withdrawal-options/withdrawal-options';
import phoneCall from '~/components/tables/table-cells/phone-call/phone-call.vue';
// @ts-ignore
import withdrawalStatusText from '~/components/tables/table-cells/withdrawal-status-text/withdrawal-status-text';
import booleanIntoText from '~/components/tables/table-cells/boolean-into-text/boolean-into-text.vue';
import keyFromObject from '~/components/tables/table-cells/key-from-object/key-from-object.vue';
import descriptionCell from '~/components/tables/table-cells/description-cell/description-cell.vue';
import symbolCell from '~/components/tables/table-cells/symbol-cell/symbol-cell.vue';
import commentRead from '~/components/tables/table-cells/comment-read/comment-read.vue';
import currencyPrice from '~/components/tables/table-cells/currency-price/currency-price.vue';
import leadsIdFilter from '~/components/tables/_header-filters/leads-id-filter/leads-id-filter.vue';
import multiRangeFilter from '~/components/tables/_header-filters/multi-range-filter/multi-range-filter.vue';
import optionsAffiliate from '~/components/tables/table-cells/options-affiliate/options-affiliate.vue';
import statusFromObject from '~/components/tables/table-cells/status-from-object/status-from-object.vue';
import dateTimeCell from '~/components/tables/table-cells/date-time-cell/date-time-cell.vue';
import plainTextConcat from '~/components/tables/table-cells/plain-text-concat/plain-text-concat.vue';
import affiliate from '~/components/tables/table-cells/affiliate/affiliate.vue';
import affiliatesStatus from '~/components/tables/table-cells/affiliates-status/affiliates-status.vue';
import campaignDisabled from '~/components/tables/table-cells/campaign-disabled/campaign-disabled.vue';
import withdrawalCurrency from '~/components/tables/table-cells/withdrawal-currency/withdrawal-currency.vue';
import accountText from '~/components/tables/table-cells/account-text/account-text.vue';
import timezone from '~/components/tables/table-cells/timezone/timezone.vue';
import campaignToken from '~/components/tables/table-cells/campaign-token/campaign-token.vue';
import urlDefinition from '~/components/tables/table-cells/url-definition/url-definition.vue';
import leadName from '~/components/tables/table-cells/lead-name/lead-name.vue';
import imageUrl from '~/components/tables/table-cells/image-url/image-url.vue';

import { dragscroll } from 'vue-dragscroll';
import loaderBig from '~/components/loaderBig.vue';
import { RootTableModule } from '~/store/modules/root-table/root-table';
import { ESearchLive } from '~/settings/enums/search-live.enum';
import { PaymentStatusEnum } from '~/settings/enums/payment-status-enum';
import { isVisibleActionButtons } from '~/components/tables/helpers/is-visible-action-buttons';
import { IRootTablePayload } from '~/store/modules/root-table/interfaces/root-table-interfaces';
import { ETableType } from '../enums/table-type.enum';
import confirmationCall from '~/components/tables/table-cells/confirmation-call/confirmation-call.vue';
import { PaymentRequisitesModule } from '~/store/modules/payment-requisites/payment-requisites';
import { IPaymentRequisiteResponse } from '~/store/modules/payment-requisites/payment-requisites-interfaces';
import { ITableHeaderColumn } from '../interfaces/table-header-column.interfaces';
import { EAccountStatus } from '~/settings/enums/account-status.enum';
import { EAffiliatesStatuses } from '~/settings/enums/affiliates-statuses.enum';
import { ECampaignEnabledOptions } from '~/settings/enums/campaign-enabled-options.enum';
import { EConfigType } from '~/store/modules/configs/enums/config-type.enum';
import { ETableHeadersNames } from '~/components/tables/enums/table-headers-names.enum';
import { ERouterNameFromPermission } from '~/settings/enums/router-name.enum';
import { ETitleAddButton } from '~/components/tables/root-table/root-table.enums';
import { EClassNotificationByStatus } from '~/components/notifications/enums/notifications.enum';

export default Vue.extend({
    directives: {
        dragscroll
    },
    components: {
        loaderBig,
        descriptionCell,
        plainText,
        plainTextTooltip,
        plainNumber,
        plainSum,
        plainSumPercentage,
        websiteList,
        withdrawalStatusText,
        withdrawalOptions,
        accountNumber,
        paymentStatus,
        ipAddress,
        emptyCell,
        tradingProfit,
        flagCountry,
        usersOptions,
        affiliate,
        affiliateProfit,
        options,
        accountStatus,
        activityStatus,
        reportsActivity,
        leadsLink,
        leadName,
        plainTextLeft,
        countryFindBy,
        callDuration,
        callRecord,
        callsActivity,
        phoneNumber,
        callsTime,
        callsDuration,
        callEffectiveness,
        callsAnswered,
        callsDestinations,
        textStatus,
        plainTextCopy,
        itemLink,
        amount,
        booleanIntoText,
        keyFromObject,
        phoneCall,
        symbolCell,
        commentRead,
        currencyPrice,
        leadsIdFilter,
        optionsAffiliate,
        statusFromObject,
        dateTimeCell,
        plainTextConcat,
        affiliatesStatus,
        campaignDisabled,
        withdrawalCurrency,
        accountText,
        timezone,
        campaignToken,
        multiRangeFilter,
        confirmationCall,
        urlDefinition,
        imageUrl,
    },
    mixins: [
        tableFiltersMixin,
    ],
    props: {
        tableHeader: {
            type: Array,
            required: true,
        },
        tableRows: {
            type: Array
        },
        tableType: {
            type: String,
            required: false,
        },
        tableName: {
            type: String,
            required: false,
        },
        isFilters: {
            type: Boolean,
            default: true,
        },
        dragscroll: {
            type: Boolean,
            default: false,
        },
        addClass: {
            type: String,
        },
        isLoaded: {
            type: Boolean,
            default: false,
        },
        isAddNewItem: {
            type: Boolean,
            default: false,
        },
        isEditItem: {
            type: Boolean,
            default: false,
        },
        isDuplicateItem: {
            type: Boolean,
            default: false,
        },
        isMassEditItem: {
            type: Boolean,
            default: false,
        },
        isSelectRow: {
            type: Boolean,
            default: false,
        },
        isMassSelectRow: {
            type: Boolean,
            default: false,
        },
        isNoFilters: {
            type: Boolean,
            default: false,
        },
        tableFixed: {
            type: Boolean,
            default: false,
        },
        orderBy: {
            type: String,
        },
        addRowClassDependsColumn: {
            type: String,
        },
        firstColumnSmall: {
            type: Boolean,
            default: false
        },
        hasEmptyFirstColumn: {
            type: Boolean
        },
        addNewItemModalType: {
            type: String,
        },
        showLineByGorizontalScroll: {
            type: Boolean,
            default: true,
        }
    },
    data () {
        return {
            scrollWidth: 0,
            intervalId: null,
            searchLiveEnum: ESearchLive,
            className: 'b-main-table',
            isSelectAllTooltip: {
                title: 'Select any data in order to use export!',
                disabled: true,
            },
            rowItemSlot: null,
            columnsWidth: [],
        };
    },
    computed: {
        rowLength () {
            return this.tableHeader.length;
        },
        getSelectedRows (): number[] {
            return RootTableModule.getSelectedRows;
        },
        multipleRowsSelected (): boolean {
            return this.getSelectedRows.length > 1;
        },
        isFiltered (): boolean {
            return isVisibleActionButtons(this.tableType, this.query);
        },
        isSelectedRow (): boolean {
            return this.getSelectedRows.length > 0;
        },
        isSelectAllRow (): boolean {
            return this.getSelectedRows.length === this.tableRows.length;
        },
        isSelectAllRowIcons (): string {
            return this.isSelectAllRow
                ? 'select_check_box' : 'check_box_outline_blank';
        },
        isActions (): boolean {
            return this.isAddNewItem ||
                this.isEditItem ||
                this.isSelectRow ||
                this.isMassEditItem ||
                this.isMassSelectRow ||
                this.isDuplicateItem ||
                this.isFiltered;
        },
        isTableRows () {
            const data = this.tableRows;

            return this.orderBy ?
                data.sort((a, b) => b[0][this.orderBy] - a[0][this.orderBy]) :
                data;
        },
        hasLoadTable (): boolean {
            return this.isTableRows.length > 0;
        },
        isGorizontalScroll () {
            return this.scrollWidth !== 0 && this.showLineByGorizontalScroll;
        },
        getRowItemSlot (): number {
            return this.rowItemSlot;
        },
        titleAddButton (): string {
            return ETitleAddButton[ERouterNameFromPermission[this.$route.name]];
        },
        firstColumnLarge (): boolean {
            return this.isSelectRow &&
                this.isEditItem &&
              this.isDuplicateItem;
        },
    },
    watch: {
        hasLoadTable: {
            immediate: true,
            handler (value: boolean) {
                if (value && this.columnsWidth.length === 0) {
                    this.$nextTick(() => this.handlerColumnWidth());
                }
            }
        }
    },
    mounted () {
        this.resetAllRow();
        this.getFilterKeyByCurrentTable(this.tableType);
        this.deleteRowItemSlot();
    },
    methods: {
        getHeaderIcon (cell): string {
            return cell.params?.iconSymbol;
        },
        getHeaderIconTitle (cell): string {
            return cell.params?.iconTitle;
        },
        getObjectForPaymentRow (row: ITableHeaderColumn): IPaymentRequisiteResponse {
            return PaymentRequisitesModule.getPaymentRequisites
                .find((object: IPaymentRequisiteResponse) => (
                    object.id === this.getCellWithStatus(row).ID
                ));
        },
        getCellWithStatus (row: ITableHeaderColumn[]): ITableHeaderColumn {
            return row.find(cell => cell.name === ETableHeadersNames.status);
        },
        isPendingOrPreApprovedRow (row: ITableHeaderColumn): boolean {
            if (this.tableType === ETableType.crypto) {
                const cellWithStatus = this.getCellWithStatus(row);

                return cellWithStatus.value === PaymentStatusEnum.pending
                    || cellWithStatus.value === PaymentStatusEnum.preApproved;
            }
        },
        isScrolled () {
            this.scrollWidth = document.querySelector('.root-table-horizontal-scroll').scrollLeft;
        },
        handlerColumnWidth (): void {
            this.columnsWidth = {}; // reset
            const headers = this.$refs.rootTableHeader;
            if (headers) {
                headers.forEach((header) => {
                    const attr = header.getAttribute('name');
                    this.columnsWidth[attr] = `${header.offsetWidth}px`;
                });
            }
        },
        generateFilterStyle (cellWidth: string, name: string): object {
            const width = cellWidth || this.columnsWidth[name];
            return {
                width: width,
            };
        },
        isSelectedCurrentRow (id: number): boolean {
            return this.getSelectedRows.includes(id);
        },
        isSelectedCurrentRowSymbol (id: number): string {
            return this.isSelectedCurrentRow(id) ? 'select_check_box' : 'check_box_outline_blank';
        },
        getEnumForTableType () {
            switch (this.tableType) {
            case ETableType.leadsTable:
                return EAccountStatus;

            case ETableType.affiliates:
                return EAffiliatesStatuses;

            case ETableType.campaigns:
                return ECampaignEnabledOptions;

            case ETableType.crypto:
            case ETableType.paymentHistory:
            case ETableType.withdrawalsHistory:
            case ETableType.withdrawalsPending:
                return PaymentStatusEnum;

            case ETableType.notificationsActivity:
                return EClassNotificationByStatus;

            default:
                break;
            }
        },
        isAddRowClasses (row): string {
            const cell = row
                .find(item => item.name === this.addRowClassDependsColumn);

            if (cell?.value) {
                const status = (typeof cell.value === EConfigType.number &&
                    cell.value) ||
                    (typeof cell.value === EConfigType.object &&
                    cell.value.id);
                const enumType: object = this.getEnumForTableType();

                return enumType ? `status-color ${enumType[status]}` : '';
            }

            return '';
        },
        isLastRow (idx, length): boolean {
            return ++idx == length;
        },
        approve (cell: ITableHeaderColumn): void {
            this.$emit('approve', cell);
        },
        reject (cell: ITableHeaderColumn): void {
            this.$emit('reject', cell);
        },
        addNewItem (event: Event): void {
            this.$emit('addNewItem', event);
        },
        editItem (id: number): void {
            this.$emit('editItem', id);
        },
        duplicateItem (id: number): void {
            this.$emit('duplicateItem', id);
        },
        massEdit (): void {
            this.$emit('massEdit');
        },
        dblclick (rowId: number): void {
            this.$emit('dblclick', rowId);
        },
        isSelected (rowId: number): boolean {
            return RootTableModule.isSelectedRow(rowId);
        },
        handlerSelectRow (event, rowId: number) {
            this.$emit('selectRow', rowId);
            const target = event.target;
            if (target.classList.contains === `${this.className}--no-click-row` ||
                target.closest(`.${this.className}--no-click-row`)) {
                console.log('');
            } else {
                if (this.isMassEditItem || this.isEditItem || this.isSelectRow) {
                    this.selectRow({ rowId: rowId, multiple: this.isMassEditItem || this.isSelectRow });
                }
            }
        },
        selectRow (payload: IRootTablePayload) {
            RootTableModule.handlerSelectedRow(payload);
        },
        resetAllRow (): void {
            RootTableModule.resetAll();
        },
        clickRow (row, index) {
            this.$emit('clickRow', {
                row,
                index,
            });
        },
        isActiveFilter (value): string {
            return value ? 'active' : '';
        },
        selectAllRows (event: Event) {
            this.$emit('selectAllRows', {
                event: event,
                state: this.isSelectAllRow,
            });
            if (this.tableRows.length === this.getSelectedRows.length) {
                this.resetAllRow();
            } else {
                const ids = this.tableRows.map(row => row[0].ID);
                RootTableModule.handlerSelectedMultipleRows(ids);
            }
        },
        handlerResetFilter (additionalExceptionsForReset?: string[]): void {
            this.resetAllRow();
            // TODO
            if (this.$refs.customFilterComponent) {
                for (const element of this.$refs.customFilterComponent) {
                    element.handlerResetBase();
                }
            }

            if (this.$refs.calendarSelect) {
                for (const element of this.$refs.calendarSelect) {
                    element.resetValue();
                }
            }

            this.resetFilter(this.tableType, additionalExceptionsForReset);
            this.$emit('resetFilter');
        },
        setRowItemSlot (id: number) {
            if (this.rowItemSlot === id) {
                this.deleteRowItemSlot();
            } else {
                this.rowItemSlot = id;
            }
        },
        deleteRowItemSlot (): void {
            this.rowItemSlot = null;
        },
        clickCell (cell, id, event) {
            this.$emit('clickCell', {
                cell,
                id,
                event,
            });
        },
        scrollTo (options: ScrollToOptions) {
            this.$el
                .querySelector('.root-table-horizontal-scroll')
                .scrollTo(options);
        },
        reInitScrollbar (): void {
            this.$vuebar.destroyScrollbar(this.$refs.rootTableWrapper);

            this.$nextTick(() => {
                this.$vuebar.initScrollbar(this.$refs.rootTableWrapper, {});
            });
        },
    },
});
