import { handlerLoadTable } from '~/components/tables/helpers/handler-load-table';
import { handlerRoutesQuery } from '~/helpers/handler-routes-query';
import filterOptionsMixin from '~/components/mixins/table-filter-options-mixin';
import { ETableFilterType } from '~/components/tables/enums/table-filter-type.enum';
import { ITableFilterQueryPayload } from '~/components/tables/interfaces/table-filter-query.interfaces';
import {
    ETableFilterKeyDate,
    ETableFilterKeyWithdrawals,
    ETableFilterKeyUser,
    ETableFilterKeyCampaigns,
    ETableFilterKeyLeads,
    ETableFilterKeyAmountRange,
    ETableFilterKeyAffiliatesReports,
    ETableFilterKeyAffiliates,
    ETableFilterKeyPaymentHistory,
    ETableFilterKeReports,
    ETableFilterKeySymbols,
} from '~/components/tables/enums/table-filter-key.enum';
import { EMultiSelectEvent } from '~/components/parts/app-multiselect/enums/multiselect-event.enum';
import { IConfig, ILanguages, IConfigValue, ILeadsAmountRangeSelect, ILeadsAmountRangeType } from '~/settings/interfaces/config-default-interfaces';
import { EMultiSelectType } from '~/components/parts/app-multiselect/enums/multiselect-type.enum';
import { ETableType } from '~/components/tables/enums/table-type.enum';
import {
    ICalendarSelectDate,
    IFormattedDate
} from '~/components/calendar-select/interfaces/calendar-select-interfaces';
import { Route } from 'vue-router/types';
import { IDefaultConfig } from '~/store/modules/configs/interfaces/configs-interface';
import { ETableFilterKeyPagination } from '~/components/tables/enums/table-filter-key.enum';
import { EConfigType } from '~/store/modules/configs/enums/config-type.enum';
import { exceptionsWithAllTables } from '../tables/helpers/exceptions-keys-filters';
import { EOptionLengthDisabled } from '../tables/enums/table-filter-option-length-disabled.enum';
import { ILeadAccount } from '~/store/modules/lead-accounts/lead-accounts-interfaces';
import { NotificationPrefabricated } from '@/js/helpers/modal-notification-services/modal-notification-services';

export default {
    mixins: [
        filterOptionsMixin,
    ],
    data (): any {
        return {
            currentTable: '',
            query: {},
            eTableFilterKey: {},
            multiSelectEvent: EMultiSelectEvent,
            valueCurrentInput: '',
        };
    },
    computed: {
        getFilterSelectedOption (): (key: string) => IConfig | IConfigValue | ILanguages {
            return (key: string, table: string = '') => {

                if (Array.isArray(this.query[key])) {
                    const options = [];
                    this.query[key].forEach(currentId => {
                        const isOption = this.findSelectedOption(key, currentId, table);

                        if (isOption) {
                            options.push(isOption);
                        }
                    });

                    return options;
                } else {
                    const currentId = this.query[key];

                    if (currentId) {
                        return this.findSelectedOption(key, currentId, table);
                    }

                    return null;
                }
            };
        },
    },
    watch: {
        $route: {
            deep: true,
            handler (to: Route): void {
                if (
                    Object.keys(to.query).length === 0 ||
                    Object.keys(to.query).length === 1 && to.query.per_page
                ) {
                    this.cleanQuery();
                }
                else {
                    this.updateQueryState();
                }
            }
        }
    },
    methods: {
        findSelectedOption (
            key: string,
            currentId: string | number,
            table: string = ''
        ): IConfig | IConfigValue | ILanguages {
            const isOption = this.isFilterSelectOptions(key, table);
            const _trackBy = this.isTrackBy(key);

            return _trackBy === '' ?
                isOption.find(option => option === currentId) :
                isOption.find(option => option[_trackBy] == currentId);
        },
        isTrackBy (key: string): string {
            switch (key) {
            case this.eTableFilterKey.language_a2:
            case this.eTableFilterKey.language:
                return 'A2';
            case this.eTableFilterKey.countries:
                return 'A3';
            case this.eTableFilterKey.activity:
            case this.eTableFilterKey.subtype:
            case this.eTableFilterKey.active:
            case this.eTableFilterKey.open_orders:
            case this.eTableFilterKey.completed_questionnaire:
            case this.eTableFilterKey.is_disabled:
            case this.eTableFilterKey.campaign_type:
            case this.eTableFilterKey.source:
                return 'value';
            case this.eTableFilterKey.trading_types:
            case this.eTableFilterKey.exchanges:
            case this.eTableFilterKey.sectors:
            case this.eTableFilterKey.industries:
            case this.eTableFilterKey.products:
                return '';
            case this.eTableFilterKey.currencies_base:
            case this.eTableFilterKey.currencies_profit:
                return 'name';
            default:
                return 'id';
            }
        },
        isName (key: string): string {
            switch (key) {
            case this.eTableFilterKey.timezone_id:
                return 'formatOffset';

            default:
                return 'name';
            }
        },
        isFilterInput (key: string): boolean {
            return key === ETableFilterType.input;
        },
        isFilterSearchLive (key: string): boolean {
            return key === ETableFilterType.searchLive;
        },
        isFilterSelect (key: string): boolean {
            return key === ETableFilterType.select;
        },
        isFilterData (key: string): boolean {
            return key === ETableFilterType.data;
        },
        isFilterComponent (key: string): boolean {
            return key === ETableFilterType.component;
        },
        handlerFormatQuery (key: string, value: string | string[], replace: boolean = false): string | string[] {
            const currentValue = this.query[key];
            const formattedValue = typeof value === EConfigType.object ?
                value :
                String(value).split(',');

            switch (key) {
            case this.eTableFilterKey.residence_country_id:
            case this.eTableFilterKey.language_a2:
            case this.eTableFilterKey.language:
            case this.eTableFilterKey.lead_status_id:
            case this.eTableFilterKey.country:
            case this.eTableFilterKey.bonus:
            case this.eTableFilterKey.verify_status_id:
            case this.eTableFilterKey.trading_type:
            case this.eTableFilterKey.mode_type_ids:
            case this.eTableFilterKey.mt_subject_type:
            case this.eTableFilterKey.status_id:
            case this.eTableFilterKey.status_ids:
            case this.eTableFilterKey.countries:
            case this.eTableFilterKey.trading_types:
            case this.eTableFilterKey.exchanges:
            case this.eTableFilterKey.currencies_base:
            case this.eTableFilterKey.currencies_profit:
            case this.eTableFilterKey.sectors:
            case this.eTableFilterKey.industries:
            case this.eTableFilterKey.products:
            case this.eTableFilterKey.campaign:
                if (replace) {
                    return formattedValue;
                }
                else if (currentValue?.length > 0) {
                    return Array.isArray(currentValue) ? [...currentValue, value] : [currentValue, value];
                } else {
                    return Array.isArray(value) ? [...value] : [value];
                }
            default:
                return value;
            }
        },
        updateQueryState (): void {
            const query = this.$router.currentRoute.query;
            Object.keys(query).forEach(item => {
                const formattedValue = this.handlerFormatQuery(item, query[item], true);
                this.handlerQuerySimple({ key: item, value: formattedValue });
            });
        },
        handlerQuerySimple (payload: ITableFilterQueryPayload): void {
            this.query[payload.key] = payload.value;
        },
        handlerQuery (key: string, value: string | string[]): void {
            const query = this.$router.currentRoute.query;

            if (query[ETableFilterKeyPagination.page]) {
                handlerRoutesQuery(ETableFilterKeyPagination.page, null);
            }
            this.handlerQuerySimple({ key, value });
            handlerRoutesQuery(key, value);
        },
        handlerSelectQuery (
            key: string,
            event: IConfig | ILanguages | IConfigValue,
            table: string,
            operation: EMultiSelectEvent,
            multiple: boolean = false
        ): void {
            switch (key) {
            case ETableFilterKeyLeads.total_deposit_amount:
            case ETableFilterKeyLeads.ftd_amount:
            case ETableFilterKeyLeads.ltd_amount:
            case ETableFilterKeyLeads.equity:
            case ETableFilterKeyLeads.free_margin:
                this.handlerAmountRange(key, event, operation);
                break;
            default:
                this.handlerSelectQueryDefault(key, event, table, operation, multiple);
                break;
            }

            this.search(table);
        },
        isFilterSelectOptions (key: string, table: string = ''): IConfig[] | ILeadAccount[] {
            return this.filterOptions(key, table);
        },
        isFilterDisabled (key: string, table: string = ''): boolean {
            switch (key) {
            case ETableFilterKeyPaymentHistory.account :
                return this.isFilterSelectOptions(
                    key, table
                ).length < EOptionLengthDisabled.accountPaymentHistory;

            default:
                return false;
            }
        },
        isFilterMultiSelectType (key: string): string {
            switch (key) {
            case this.eTableFilterKey.account:
                return EMultiSelectType.tradingAccount;

            case this.eTableFilterKey.legal_subject_id:
                return EMultiSelectType.clientType;

            default:
                return EMultiSelectType.default;
            }
        },
        search (table: string = ''): void {
            NotificationPrefabricated.pleaseWait();
            handlerLoadTable(table);
        },
        searchLive (key: string, value: string | string[], table: string): void {
            this.handlerQuery(key, value);
            this.valueCurrentInput = value;

            setTimeout(() => {
                if (this.valueCurrentInput === value) {
                    this.search(table);
                }
            }, 1000);
        },
        createQueryByTable (): void {
            const query = this.$route.query;
            const _query = Object.values(this.eTableFilterKey as string[])
                .reduce((res, current) => {
                    res[current] = query[current] ? this.handlerFormatQuery(current, query[current], true) : '';
                    return res;
                }, {});
            this.query = Object.assign({}, this.query, _query);
        },
        cleanQuery (): void {
            for (const item in this.query) {
                this.query[item] = '';
            }
        },
        clearSimpleQuery (key: string): void {
            this.query[key] = '';
            handlerRoutesQuery(key, '');
        },

        onClearFilter (key: string, table: string): void {
            this.clearSimpleQuery(key);
            this.search(table);
        },
        handlerDateValue (key: string): IFormattedDate | object  { // TODO object
            const dateType = this.getDateType(key);
            const startKey = dateType[ETableFilterKeyDate.startDate];
            const endKey = dateType[ETableFilterKeyDate.endDate];

            return {
                startDate: this.query[startKey],
                endDate: this.query[endKey],
            };
        },
        dateTypeFormat (start: string, end: string): IFormattedDate | object  { // TODO object
            return {
                [ETableFilterKeyDate.startDate]: start,
                [ETableFilterKeyDate.endDate]: end,
            };
        },
        getDateType (key: string): IFormattedDate | object  { // TODO object
            switch (key) {
            case ETableFilterKeyLeads.registered_at:
                return this.dateTypeFormat(ETableFilterKeyLeads.registration_date_from, ETableFilterKeyLeads.registration_date_to);
            case ETableFilterKeyLeads.ftd_at:
                return this.dateTypeFormat(ETableFilterKeyLeads.ftd_date_from, ETableFilterKeyLeads.ftd_date_to);
            case ETableFilterKeyLeads.ltd_at:
                return this.dateTypeFormat(ETableFilterKeyLeads.ltd_date_from, ETableFilterKeyLeads.ltd_date_to);
            case ETableFilterKeyLeads.ltw_at:
                return this.dateTypeFormat(ETableFilterKeyLeads.ltw_date_from, ETableFilterKeyLeads.ltw_date_to);
            case ETableFilterKeyDate.created_at:
                return this.dateTypeFormat(ETableFilterKeyDate.created_at_from, ETableFilterKeyDate.created_at_to);
            case ETableFilterKeyDate.updated_at:
                return this.dateTypeFormat(ETableFilterKeyDate.updated_at_from, ETableFilterKeyDate.updated_at_to);
            case ETableFilterKeySymbols.time_expiration:
                return this.dateTypeFormat(ETableFilterKeySymbols.time_expiration_from, ETableFilterKeySymbols.time_expiration_to);
            case ETableFilterKeySymbols.dividend_date:
                return this.dateTypeFormat(ETableFilterKeySymbols.dividend_date_from, ETableFilterKeySymbols.dividend_date_to);
            case ETableFilterKeySymbols.ex_div_date:
                return this.dateTypeFormat(ETableFilterKeySymbols.ex_div_date_from, ETableFilterKeySymbols.ex_div_date_to);
            default:
                return this.dateTypeFormat(ETableFilterKeyDate.startDate, ETableFilterKeyDate.endDate);
            }
        },
        handlerSelectDate (date: ICalendarSelectDate, table: string, key: string): void {
            const dateType = this.getDateType(key);
            Object.keys(date.formattedDate).forEach(key => {
                const _isKey = ETableFilterKeyDate[key];
                this.handlerQuery(dateType[_isKey], date.formattedDate[key]);
            });
            this.search(table);
        },
        getFilterKeyByCurrentTable (table: string = ''): void {
            switch (table) {
            case ETableType.usersTable:
                this.eTableFilterKey = ETableFilterKeyUser;
                break;
            case ETableType.withdrawalsPending:
            case ETableType.withdrawalsHistory:
                this.eTableFilterKey = ETableFilterKeyWithdrawals;
                break;
            case ETableType.campaigns:
                this.eTableFilterKey = ETableFilterKeyCampaigns;
                break;
            case ETableType.leadsTable:
                this.eTableFilterKey = ETableFilterKeyLeads;
                break;
            case ETableType.affiliatesReportsCampaign:
            case ETableType.affiliatesReports:
                this.eTableFilterKey = ETableFilterKeyAffiliatesReports;
                break;
            case ETableType.affiliates:
                this.eTableFilterKey = ETableFilterKeyAffiliates;
                break;
            case ETableType.paymentHistory:
                this.eTableFilterKey = ETableFilterKeyPaymentHistory;
                break;
            case ETableType.reports:
                this.eTableFilterKey = ETableFilterKeReports;
                break;
            case ETableType.symbols:
                this.eTableFilterKey = ETableFilterKeySymbols;
                break;
            }

            this.createQueryByTable();
            this.currentTable = table;
        },
        handlerSelectQueryDefault (
            key: string,
            event: IConfig | ILanguages | IConfigValue,
            table: string,
            operation: EMultiSelectEvent,
            multiple: boolean = false
        ): void {
            const trackBy = this.isTrackBy(key);

            const _isEventId = typeof event === EConfigType.string // TODO maybe typeof event === EConfigType.object
                ? event
                : String(event[trackBy]) || null;

            let result = null;
            if (_isEventId) {
                if (multiple) {
                    result = operation === EMultiSelectEvent.remove ?
                        this.query[key].filter(item => (item !== _isEventId)) :
                        this.handlerFormatQuery(key, _isEventId);
                } else {
                    result = operation === EMultiSelectEvent.remove ?
                        null : _isEventId;
                }
            }

            this.handlerQuery(key, result);
        },
        handlerAmountRange (key: string, event: ILeadsAmountRangeSelect, operation: EMultiSelectEvent): void {
            const type = this.getAmountRangeType(key);
            const remove = operation === EMultiSelectEvent.remove;
            Object.keys(type).forEach(item => {
                const result = remove ? null : event[item];
                this.handlerQuery(type[item], result);
            });
            const resultId = remove ?
                null :
                event[ETableFilterKeyAmountRange.id];
            this.handlerQuery(key, resultId);
        },
        getAmountRangeType (key: string): ILeadsAmountRangeType {
            switch (key) {
            case ETableFilterKeyLeads.total_deposit_amount:
                return this.amountRangeFormat(ETableFilterKeyLeads.total_deposit_amount_from, ETableFilterKeyLeads.total_deposit_amount_to);
            case ETableFilterKeyLeads.ftd_amount:
                return this.amountRangeFormat(ETableFilterKeyLeads.ftd_amount_from, ETableFilterKeyLeads.ftd_amount_to);
            case ETableFilterKeyLeads.ltd_amount:
                return this.amountRangeFormat(ETableFilterKeyLeads.ltd_amount_from, ETableFilterKeyLeads.ltd_amount_to);
            case ETableFilterKeyLeads.equity:
                return this.amountRangeFormat(ETableFilterKeyLeads.equity_amount_from, ETableFilterKeyLeads.equity_amount_to);
            case ETableFilterKeyLeads.free_margin:
                return this.amountRangeFormat(ETableFilterKeyLeads.free_margin_amount_from, ETableFilterKeyLeads.free_margin_amount_to);
            }
        },
        amountRangeFormat (from: string, to: string): ILeadsAmountRangeType  {
            return {
                [ETableFilterKeyAmountRange.from]: from,
                [ETableFilterKeyAmountRange.to]: to,
            };
        },
        isOptionsGroup (key: string): IDefaultConfig {
            switch (key) {
            case this.eTableFilterKey.bonus:
                return this.getConfigBonusTypes;
            default:
                return null;
            }
        },
        multiSelectDisabledAllResetButton (key: string, table: string): boolean {
            switch (key) {
            case this.eTableFilterKey.bonus:
                if (table === ETableType.affiliates) {
                    return true;
                }
                return false;
            }
        },
        fullClearQuery (): void {
            for (const item in this.query) {
                this.query[item] = '';
                handlerRoutesQuery(item, '');
            }
        },
        partClearQuery (exceptionsArray: string[], additionalExceptionsForReset?: string[]): void {
            const exceptionsQueryArray = Array.isArray(additionalExceptionsForReset) ?
                [
                    ...exceptionsArray,
                    ...additionalExceptionsForReset
                ] :
                exceptionsArray;

            for (const item in this.query) {
                if (!exceptionsQueryArray.includes(item)) {
                    this.query[item] = '';
                    handlerRoutesQuery(item, '');
                }
            }
        },
        resetFilter (tableType?: string, additionalExceptionsForReset?: string[]): void {
            const isTable = exceptionsWithAllTables(tableType);

            if (isTable) {
                this.partClearQuery(isTable, additionalExceptionsForReset);
            } else {
                this.fullClearQuery();
            }

            this.search(this.currentTable);
        }
    }
};
