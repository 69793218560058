import router from '~/router';
import { LeadsFilterTemplatesModule } from './leads-filter-templates';
import { ILeadsFilterItems } from '~/store/modules/leads-filter-templates/interfaces/leads-filter-templates-interfaces';
import { ITableHeaderColumn } from '~/components/tables/interfaces/table-header-column.interfaces';

export const generateFiltersToSave = (): ILeadsFilterItems[] => {
    const query = { ...router.currentRoute.query };
    const filters: ITableHeaderColumn[] = LeadsFilterTemplatesModule.getFiltersToModal;
    const isExistQuery: ILeadsFilterItems[] = [];

    filters.forEach(filter => {
        isExistQuery.push({ id: filter.id, wheres: `${query[filter.name] || ''}` });
    });
    return isExistQuery;
};
