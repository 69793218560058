import { ECalendarQuery } from '~/components/calendar-select/enums/calendar-query.enum';
import { ETableFilterKeyAffiliatesReports, ETableFilterKeyPagination } from '~/components/tables/enums/table-filter-key.enum';
import { ETableType } from '../enums/table-type.enum';
import { IExceptionsKeysFilter } from '../interfaces/exceptions-keys-filter-interface';

export const exceptionsWithAllTables = (tableType: string): string[] => {
    return exceptions[tableType] ?
        [
            ...exceptions[tableType],
            ...exceptions.allTables,
        ] :
        exceptions.allTables;
};

export const exceptions: IExceptionsKeysFilter = {
    allTables: [
        ETableFilterKeyPagination.perPage,
        ETableFilterKeyPagination.page,
    ],
    [ETableType.affiliatesReports]: [
        ETableFilterKeyAffiliatesReports.startDate,
        ETableFilterKeyAffiliatesReports.endDate,
        ETableFilterKeyAffiliatesReports.type,
        ECalendarQuery.calendarType
    ],
    [ETableType.affiliatesReportsCampaign]: [
        ETableFilterKeyAffiliatesReports.startDate,
        ETableFilterKeyAffiliatesReports.endDate,
        ETableFilterKeyAffiliatesReports.type,
        ECalendarQuery.calendarType
    ],
};
