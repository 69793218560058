// Imports
import Vue from 'vue';
import worldMap from '~/components/parts/world-map/world-map.vue';
import { IDashboardSummaryStatisticItem } from '~/store/modules/dashboard/interfaces';
import { DashboardModule } from '~/store/modules/dashboard/dashboard';
import { EDashboardClientsItemsKey } from '~/store/modules/dashboard/enums/dashboard-payments-items.enum';
import configsMixin from '~/components/mixins/configs-mixin';
import dashboardSummaryMixin from '~/components/mixins/dashboard-summary-mixin';
import { ICountries } from '~/store/modules/configs/interfaces/configs-interface';
import { IWorldMapData } from '~/settings/interfaces/world-map-interfaces';
import { ESassColor } from '~/settings/enums/sass-color.enum';

// Exports
export default Vue.extend({
    components: {
        worldMap,
    },
    mixins: [
        configsMixin,
        dashboardSummaryMixin,
    ],
    props: {
        chartData: {
            type: Array,
        },
        toolTipDate: {
            type: String,
            required: true,
        },
    },
    computed: {
        getDashboardClientsWidgets (): IDashboardSummaryStatisticItem[] {
            return DashboardModule.getDashboardClientsWidgets[EDashboardClientsItemsKey.client_count_by_country] as IDashboardSummaryStatisticItem[];
        },
        getRegistrationsSum (): number {
            return this.getDashboardClientsWidgets?.reduce((accumulator, item) => accumulator + item.value, 0);
        },
        getCountriesData (): IWorldMapData[] {
            return this.getDashboardClientsWidgets?.map(item => ({
                ...item,
                ...this.getCountryById(item.id),
                color: this.percentageArrowColor(item) || ESassColor.blue
            }));
        }
    },
    methods: {
        getCountryById (id: number): ICountries {
            return this.getConfigCountries
                .find(country => country.id === id);
        }
    }
});
