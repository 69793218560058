import { getIconByLegalSubjectId } from '@/js/helpers/getIconByLegalSubjectId';
import Vue from 'vue';

export default Vue.extend({
    props: {
        option: {
            type: Object,
            required: true,
            default: function () {
                return {};
            }
        },
        className: {
            type: String,
        },
    },
    computed: {
        getIconByClientType () {
            return getIconByLegalSubjectId(this.option.id);
        }
    }
});
