import Vue from 'vue';
import { FinanceContentItemInterfaces } from './interfaces/finance-content-item-interfaces';
export default Vue.extend({
    props: {
        addClass: {
            type: String
        }
    },
    data (): FinanceContentItemInterfaces {
        return {
            className: 'b-finance-content-item'
        };
    }
});
