export default {
    data (): Object {
        return {
            generatedUniqueId: 'unique-id-',
        };
    },
    mounted (): void {
        this.generateId();
    },
    methods: {
        generateId (): void {
            this.generatedUniqueId += `${this.className}-${Math.floor(Math.random() * 100)}`; // TODO
        },
    }
};
