import Vue from 'vue';
import AppButton from '~/components/parts/app-button/app-button.vue';
import AppInput from '~/components/parts/app-input/app-input.vue';
import AppMultiSelect from '~/components/parts/app-multiselect/app-multiselect.vue';
import { AffiliatesBonusPlanEnum } from '~/components/affiliates/affiliates-bonus-plan/enums/affiliates-bonus-plan.enum';
import { AffiliatesBonusPlansModule } from '~/store/modules/affiliates/affiliates-bonus-plans';
import { BonusPlanModule } from '~/store/modules/bonus-plan/bonus-plan';
import { IBonusPlan } from '~/store/modules/bonus-plan/interfaces/bonus-plan-interfaces';
import cpaForm from '~/components/_forms/bonus-plan-forms/cpa-form/cpa-form.vue';
import cplForm from '~/components/_forms/bonus-plan-forms/cpl-form/cpl-form.vue';
import rebateForm from '~/components/_forms/bonus-plan-forms/rebate-form/rebate-form.vue';
import hybridForm from '~/components/_forms/bonus-plan-forms/hybrid-form/hybrid-form.vue';
import revShareForm from '~/components/_forms/bonus-plan-forms/rev-share-form/rev-share-form.vue';
import { bonusPlanFormatted } from '~/store/modules/bonus-plan/helpers/bonus-plan-formatted';
import { bonusPlanTypeValidation } from '~/components/affiliates/affiliates-bonus-plan/helpers/bonus-plan-type-validation';
import { ModalsModule } from '~/store/modules/modals';

export default Vue.extend({
    components: {
        AppButton,
        AppInput,
        AppMultiSelect,
        cpaForm,
        cplForm,
        rebateForm,
        hybridForm,
        revShareForm,
    },
    provide () {
        return {
            bonusPlanListNames: this.bonusPlanListNames,
        };
    },
    props: {
        bonusPlan: {
            type: Object,
        },
    },
    data () {
        return {
            isEdit: false,
            editBonusPlan: {},
            editBonusPlanForm: AffiliatesBonusPlanEnum,
        };
    },
    computed: {
        isActiveBonusPlanId (): number {
            return this.bonusPlan?.bonus_type.id ||
                AffiliatesBonusPlansModule.getActiveCategory;
        },
        isEditBonusPlan (): boolean {
            return !this.editBonusPlan?.is_default;
        },
        isUsedBonusPlan (): boolean {
            return this.editBonusPlan?.is_used;
        },
        isTitle (): string {
            return this.isEdit ?
                'Edit bonus plan' :
                'Bonus plan details';
        },
        bonusPlanListNames (): string[] {
            return AffiliatesBonusPlansModule.getBonusPlanItems
                .map((item) => item.name);
        }
    },
    watch: {
        bonusPlan: {
            deep: true,
            immediate: true,
            handler (): void {
                this.setBonusPlan();
                this.disableEditBonusPlan();
            }
        },
    },
    methods: {
        cancelEditBonusPlan (): void {
            this.disableEditBonusPlan();
            this.setBonusPlan();
            this.$refs.observer.reset();
        },
        disableEditBonusPlan (): void {
            this.isEdit = false;
        },
        changeEditState (payload: boolean): void {
            this.isEdit = payload;
        },
        setBonusPlan (): void {
            this.editBonusPlan = Object.assign({}, this.editBonusPlan, this.bonusPlan);
        },

        async updateBonusPlan (): Promise<void> {
            const payload = {
                ...this.$refs.editBonusPlanForm.$data.editBonusPlan,
                revenues: this.$refs.editBonusPlanForm.$refs.editBonusPlanRevenue?.$data.itemsToEdit,
                countries: this.$refs.editBonusPlanForm.$refs.editBonusPlanCountries?.$data.bonusPlanCountriesToEdit,
                other_country_value: this.$refs.editBonusPlanForm.$refs.editBonusPlanCountries?.$data.otherCountryValue,
            };

            const isValid = await this.$refs.observer.validate();
            const typeValid = await bonusPlanTypeValidation(payload);

            if (isValid && typeValid) {
                await this.onSubmitUpdateBonusPlan(payload);
            }
        },

        async onSubmitUpdateBonusPlan (payload: IBonusPlan): Promise<void> {
            const payloadFormatted = bonusPlanFormatted(payload);
            await BonusPlanModule.updateBonusPlan(payloadFormatted)
                .then(result => {
                    if (result) {
                        BonusPlanModule.loadBonusPlan();
                    }
                });
        },
        deleteBonusPlan (): void {
            ModalsModule.openModal({
                modalType: 'deleteLeadModal',
                props: {
                    title: 'Are you Sure?',
                    body: {
                        data: 'Are you sure you want to delete the selected Bonus Plan?'
                    },
                    isFooter: false,
                    isHeader: true,
                    ok: async () => {
                        AffiliatesBonusPlansModule.deleteBonusPlan(this.bonusPlan.id);
                    }
                },
                modalSettings: {
                    props: {
                        minWidth: ModalsModule.modalWidth.xs,
                    },
                },
            });
        },
    }
});
