import Vue from 'vue';
import { isEmpty } from 'lodash';
import modalLayout from '~/components/modals/root-modal-layout/root-modal-layout.vue';
import modalsMixin from '~/components/mixins/modals';
import appPhone from '~/components/parts/app-phone/app-phone.vue';
import AppInput from '~/components/parts/app-input/app-input.vue';

import configsMixin from '~/components/mixins/configs-mixin';
import fieldModelMixin from '~/components/mixins/modelMixins/fieldModelMixin';
import { KycClientModule } from '~/store/modules/kyc-client/kyc-client';
import { LeadManagerEnum } from '~/components/leadsItem/LeadDetails/enums/lead-details.enums';
import { ILeadInterfaces } from '~/store/modules/kyc-client/interfaces/lead-interfaces';

import { actionHandleCreateLead, actionHandleFetchleads } from '~/store/modules/leads';
import { mapActions } from 'vuex';
import { ELegalSubject, ELegalSubjectString } from '@/js/settings/enums/legal-subject.enum';
import { ICountries } from '@/js/store/modules/configs/interfaces/configs-interface';
import { EClientTitle } from '~/settings/enums/client-title.enum';
import uaParser from '~/utils/uaParser';

export default Vue.extend({
    components: {
        modalLayout,
        AppInput,
        appPhone
    },
    mixins: [
        modalsMixin,
        configsMixin,
        fieldModelMixin,
    ],
    data (): Object {
        return {
            clientType: {
                id: ELegalSubject.individual,
                name: ELegalSubjectString.individual
            },
            businessName: '',
            countryOfIncorporation: null,
            business_phone_country: null,
            business_phone: '',
            titleField: null,
            fname: '',
            lname: '',
            email: '',
            clientCategorization: '',
            leadStatus: '',
            salesRep: '',
            language: '',
            country: '',
            phone_mobile_country: null,
            phone_mobile: null,
            phone_second_country: null,
            phone_second: '',
        };
    },
    computed: {
        users () {
            return KycClientModule.getUsersList;
        },
        isChoosenBusinessClientType (): boolean {
            return this.clientType.id === ELegalSubject.business;
        },
    },
    created (): void {
        KycClientModule.handleSetUsersList({ department_id: LeadManagerEnum.Sales });
        this.setDefaultValue();
    },
    methods: {
        ...mapActions({
            handleAddLead: actionHandleCreateLead,
            handleFetchleads: actionHandleFetchleads,
        }),
        setDefaultValue (): void {
            this.titleField = this.getConfigTitles
                .find(title => title.id === EClientTitle.Mr);
        },
        setBusinessPhoneCountry (seletedCountry: ICountries): void {
            this.business_phone_country = seletedCountry;
        },
        async handlerCreateNewClient (): Promise<void> {
            const payload = await this.handlerCreatePayload();
            await this.handleAddLead(payload);
            await this.handleFetchleads(this.$route.query);
            this.closeModal();
        },
        handlerCreatePayload (): ILeadInterfaces {
            const { browser, os } = uaParser.getResult();

            let payload = {
                legal_subject_id: this.clientType.id,
                title_id: this.titleField.id,
                fname: this.fname,
                lname: this.lname,
                email: this.email,
                residence_country_id: !isEmpty(this.country) ? this.country.id : '',
                complicatedness_id: !isEmpty(this.leadStatus) ? this.leadStatus.id : '',
                professional_level_id: !isEmpty(this.clientCategorization) ? this.clientCategorization.id : '',
                sales_user_id: !isEmpty(this.salesRep) ? this.salesRep.id : '',
                language_a2: this.language.A2,
                phone_mobile_country_id: !isEmpty(this.phone_mobile_country) ? this.phone_mobile_country.id : '',
                phone_mobile: this.phone_mobile,
                phone_second_country_id: !isEmpty(this.phone_second_country) ? this.phone_second_country.id : '',
                phone_second: this.phone_second,

                referrer_url: document.referrer,
                browser: browser.name,
                browser_version: browser.version,
                os_name: os.name,
                os_version: os.version,
            } as ILeadInterfaces;

            if (this.isChoosenBusinessClientType) {
                payload = {
                    company_name: this.businessName,
                    incorporation_country_id: this.countryOfIncorporation.id,
                    business_phone_country_id: this.business_phone_country.id,
                    business_phone: this.business_phone,
                    ...payload,
                } as ILeadInterfaces;
            }

            return payload;
        },
        async handlerSubmitForm (): Promise<void> {
            const isValid = await this.$refs.observer.validate();
            if (!isValid) return;

            this.handlerCreateNewClient();
        },
        getInitialSelected () {
            return '';
        },
        changeBusinessPhone (value: string): void {
            this.business_phone = value;
        },

        changePhoneMobile (value: string) {
            this.phone_mobile = value;
        },

        changePhoneSecond (value: string) {
            this.phone_second = value;
        }
    }
});
