export enum EAppHistoryKycIcon {
    pending = 'schedule',
    approved = 'check_circle',
    rejected = 'cancel',
    canceled = 'not_interested',
    reviewed = 'preview',
}

export enum EAppHistoryKycColorIcon {
    pending = 'yellow',
    approved = 'green',
    rejected = 'red',
    canceled = 'red',
    reviewed = 'purple',
}
