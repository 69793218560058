// Imports
import Vue from 'vue';
import Search from '~/components/parts/search/search.vue';
import AccountingCommissionDatabaseTableItem from './accounting-commission-database-table-item/accounting-commission-database-table-item.vue';

// Exports
export default Vue.extend({
    components: {
        'search': Search,
        'accounting-commission-database-table-item': AccountingCommissionDatabaseTableItem
    }
});
