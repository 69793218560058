import {
    IBonusPlan,
    IBonusPlanUpdate,
} from '~/store/modules/bonus-plan/interfaces/bonus-plan-interfaces';

export const bonusPlanFormattedRevShare = (payload: IBonusPlan): IBonusPlanUpdate => {
    const {
        id,
        name,
        revenues,
        is_fixed,
        bonus_type,
    } = payload;
    return {
        id: id || null,
        name,
        revenues,
        is_fixed,
        bonus_type_id: bonus_type.id,
    };
};
