export enum ESearchMainType {
    clients = 'clients',
}

export enum ESearchMainLocalStorage {
    name = 'searchMainHistory',
    maxLength = 5,
}

export enum ESearchMainAttributes {
    ClientsId = 'clients.id',
    ClientsFullName = 'clients.full_name',
    ClientsEmail = 'clients.email',
    ClientsPhoneMobileFull = 'clients.phone_mobile_full',
    AccountsAccount = 'accounts.account',
}

export enum ESearchMainAttributesIcon {
    'clients.id' = 'fingerprint',
    'clients.full_name' = 'badge',
    'clients.email' = 'alternate_email',
    'clients.phone_mobile_full' = 'call',
    'accounts.account' = 'account_balance',
}
