import { Module, VuexModule, getModule, MutationAction } from 'vuex-module-decorators';
import store from '~/store/index';
import { IModalNotificationMutation } from './interfaces/modal-notification-interfaces';

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: 'ModalNotification',
})

class ModalNotification extends VuexModule {

    isActiveNotification: boolean = false;

    get getActiveNotificationState (): boolean {
        return this.isActiveNotification;
    }

    @MutationAction ({ mutate: ['isActiveNotification'] })
    async setActiveNotificationState (payload: boolean): Promise<IModalNotificationMutation> {
        return {
            isActiveNotification: payload,
        };
    }
}

export const ModalNotificationModule = getModule(ModalNotification);
