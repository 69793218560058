import Vue from 'vue';
import EditBtn from '~/components/leadsItem/LeadDetails/parts/editBtn.vue';
import EditModeBtns from '~/components/leadsItem/LeadDetails/parts/editModeBtns.vue';
import appMultiSelect from '~/components/parts/app-multiselect/app-multiselect.vue';
import configsMixin from '~/components/mixins/configs-mixin';
import { getIconByLegalSubjectId } from '~/helpers/getIconByLegalSubjectId';
import clientTypeMixin from '~/components/mixins/client-type-mixin';
import { isUserPermissionEditUsersClients } from '~/helpers/user-permission';

export default Vue.extend({
    components: {
        EditBtn,
        EditModeBtns,
        appMultiSelect
    },
    mixins: [
        configsMixin,
        clientTypeMixin,
    ],
    computed: {
        isUserPermissionEditUsersClients,
        clientTypeName (): string {
            return this.leadDetails?.legal_subject?.name;
        },
        getIconByClientType (): string {
            return getIconByLegalSubjectId(this.leadDetails.legal_subject?.id);
        },
    },
    methods: {
        resetselectedClientType (): void {
            this.selectedClientType = null;
        },
        restore (): void {
            this.hide();
            this.resetselectedClientType();
        },
    }
});
