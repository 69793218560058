import { render, staticRenderFns } from "./pagination.html?vue&type=template&id=2af1c82b&lang=html&"
import script from "./pagination.ts?vue&type=script&lang=ts&"
export * from "./pagination.ts?vue&type=script&lang=ts&"
import style0 from "./pagination.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports