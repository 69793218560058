import DatePicker from 'vue2-datepicker';

export default {
    components: {
        DatePicker,
    },
    data (): object {
        return {
            isDateError: false,
        };
    },
    methods: {
        setFocusDatePickerAfterClosed (): void {
            this.$refs.datePicker.focus();
        },
        datePickerRemoveError (date: string): void {
            if (date) {
                this.isDateError = false;
            }
        },
    }
};
