import Vue from 'vue';
import { NotesModule } from '~/store/modules/notes/notes';
import { isPermissionCreateNotes } from '~/helpers/user-permission';

export default Vue.extend({
    props: {
        departmentId: {
            type: Number,
        },
        title: {
            type: String,
        },
        notes: {
            type: Array,
        },
        isButton: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            comment: '',
        };
    },
    computed: {
        isPermissionCreateNotes,
    },
    methods: {
        resetComment (): void {
            this.comment = '';
        },
        loadNotes (): void {
            const leadId = this.$router.currentRoute.params.id;
            NotesModule.loadNotes(leadId);
        },
        handleSaveComment (): void {
            if (this.comment) {
                const leadId = this.$router.currentRoute.params.id;
                NotesModule.saveNote({
                    id: leadId,
                    description: this.comment,
                    department_id: this.departmentId,
                })
                    .then(() => {
                        this.resetComment();
                        this.loadNotes();
                    });
            }
        }
    }
});
