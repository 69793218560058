import { render, staticRenderFns } from "./kyc-tab-item-target-market.html?vue&type=template&id=902d9af0&lang=html&"
import script from "./kyc-tab-item-target-market.ts?vue&type=script&lang=ts&"
export * from "./kyc-tab-item-target-market.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports