import { Action, Module, VuexModule, getModule, Mutation } from 'vuex-module-decorators';
import store from '~/store/index';
import { tableParser } from '~/helpers/table-parser';
import Rest from '~/helpers/Rest';
import {
    urlUsers,
    urlUsersUpdate,
    urlUsersMassiveUpdate,
    urlUsersUpdatePermissions,
    urlUserDetails
} from '~/settings/ajax-urls';
import { usersTableHeader } from '~/components/tables/_headers/users';
import { ITableHeaderColumn } from '~/components/tables/interfaces/table-header-column.interfaces';
import { ITableCell } from '~/components/tables/interfaces/table-cell-interfaces';
import {
    IUsers,
    IUsersPayload,
    IUsersDetails,
    IUsersPermissionsPayload,
    IUsersRequestPayload,
    IUsersDeletePayload,
    IUsersMassUpdatePayload
} from '~/store/modules/users/users-interface';
import { ITableFilterQuery } from '~/components/tables/interfaces/table-filter-query.interfaces';
import { IPagination } from '~/settings/interfaces/pagination-interfaces';
import { NotificationPrefabricated } from '~/helpers/modal-notification-services/modal-notification-services';
import { handlerRestResponse } from '~/helpers/handler-rest-response';
import { showNothingWasFound } from '@/js/components/tables/helpers/showNothingWasFound';

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: 'users',
})
class Users extends VuexModule {
    usersList: IUsers[] = [];
    userDetails: IUsersDetails = {
        id: null,
        username: '',
        fname: '',
        lname: '',
        dob: null,
        country_id: null,
        language_a2: '',
        parent_id: null,
        department_id: null,
        email: '',
        phone: '',
        ext: '',
        is_active: null,
        registered_at: null,
        permissions: null
    };
    usersPagination: IPagination = {
        current_page: null,
        from: null,
        last_page: null,
        per_page: null,
        to: null,
        total: null,
    };
    loaded: boolean = false;

    get getUsersHeader (): ITableHeaderColumn[] {
        return usersTableHeader;
    }
    get getUsersRows (): ITableCell[] {
        return tableParser(usersTableHeader, this.usersList);
    }
    get getUsersList (): IUsers[] {
        return this.usersList;
    }

    get getUserDetails (): IUsersDetails {
        return this.userDetails;
    }

    get getUsersPagination (): IPagination {
        return this.usersPagination;
    }

    get getUsersIsLoaded (): boolean {
        return this.loaded;
    }

    @Mutation
    setUsersList (payload: IUsers[]) {
        return this.usersList = payload;
    }

    @Mutation
    setUserDetails (payload: IUsersDetails) {
        return this.userDetails = payload;
    }

    @Mutation
    setUsersPagination (payload: IPagination) {
        this.usersPagination = payload;
    }

    @Mutation
    setLoadedState (payload: boolean) {
        this.loaded = payload;
    }

    @Action ({ commit: 'setUsersList' })
    async loadUsers (query: ITableFilterQuery = null): Promise<IUsers[]> {
        this.setLoadedState(false);
        const { data, meta } =  await Rest.GET(urlUsers(query))
            .then(Rest.middleThen)
            .catch(Rest.simpleCatch)
            .finally(() => {
                this.setLoadedState(true);
                NotificationPrefabricated.cleanAllNotify();
            });

        this.setUsersPagination(meta);
        showNothingWasFound(data.length);
        return  data;
    }

    @Action
    async createNewUser (payload: IUsersPayload): Promise<IUsers[]> {
        return await Rest.POST(urlUsers(), payload)
            .then(response => {
                return handlerRestResponse(response, NotificationPrefabricated.createNewUserSuccess);
            })
            .catch(Rest.simpleCatch);
    }

    @Action
    async updateUser ({ id, payload }: IUsersRequestPayload): Promise<IUsers[]> {
        return await Rest.PUT(urlUsersUpdate(id), payload)
            .then(response => {
                return handlerRestResponse(response, NotificationPrefabricated.updateUserSuccess);
            })
            .catch(Rest.simpleCatch);
    }

    @Action
    async usersMassiveUpdate (payload: IUsersMassUpdatePayload): Promise<void> {
        await Rest.PUT(urlUsersMassiveUpdate, payload)
            .then(response => {
                return handlerRestResponse(response, NotificationPrefabricated.updateUserSuccess);
            })
            .catch(Rest.simpleCatch);
    }

    @Action
    async deleteUser (payload: IUsersDeletePayload): Promise<void> {
        await Rest.DELETE(urlUsersUpdate(payload.id), {
            assign_user_id: payload.assignUserId,
        });
    }

    @Action
    async updateUserPermissions ({ id, payload }: IUsersPermissionsPayload): Promise<void> {
        await Rest.PUT(urlUsersUpdatePermissions(id), {
            permissions: payload,
        });
    }

    @Action({ commit: 'setUserDetails' })
    async loadUserDetails (id: number): Promise<IUsersDetails> {
        const { data } = await Rest.GET(urlUserDetails(id))
            .then(Rest.middleThen)
            .catch(Rest.simpleCatch);
        return data;
    }
}

export const UsersModule = getModule(Users);

