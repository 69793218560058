import Vue from 'vue';
import modalsMixin from '~/components/mixins/modals';
// @ts-ignore
import modalLayout from '~/components/modals/root-modal-layout/root-modal-layout';

export default Vue.extend({
    components: {
        modalLayout
    },
    mixins: [modalsMixin],
    methods: {
        async ok () {
            this.closeModal();
            this.$attrs['modal-props'].ok();
        }
    }
});
