export const regExprLink = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\\/]))?/;

export const regExprLinkNotStartProtocol = /^(www\.)/;

export const regExprLinkWww = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)/;

export const regHasFirstPlus = /^\+/;

export const regSeparationByTwoPoints = /^[^:]+/g;

export const regTimeRange = /(\d{2}:\d{2}-\d{2}:\d{2})/g;

export const regBreakTime = /Break((?:\d{2}:\d{2}-\d{2}:\d{2}\s*,?\s*)+)/g;
