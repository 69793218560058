<template>
    <div class="d-flex flex-column">
        <div class="details_info">
            <span class="details_category">
                Verification (Trader)
            </span>

            <span
                class="
                    details_value
                    details_value--capitalize
                    details_value--no-margin
                    details_value--icon
                "
            >
                <material-icon
                    size="md"
                    add-class="wght-light"
                    :symbol="getAccountStatusIcon(leadDetails.verify_status.id)"
                    :color="getAccountStatusIconColor(leadDetails.verify_status.id)"
                />
                <span :class="getStatusEnumKey( leadDetails.verify_status.id)">
                    {{ leadDetails.verify_status.name }}
                </span>
            </span>
        </div>
        <div class="details_info">
            <span class="details_category">
                Verification (Crypto)
            </span>

            <span
                class="
                    details_value
                    details_value--capitalize
                    details_value--no-margin
                    details_value--icon
                "
            >
                <template v-if="leadDetails.verify_status_crypto">
                    <material-icon
                        size="md"
                        add-class="wght-light"
                        :symbol="getAccountStatusIcon(leadDetails.verify_status_crypto.id)"
                        :color="getAccountStatusIconColor(leadDetails.verify_status_crypto.id)"
                    />
                    <span :class="getStatusEnumKey( leadDetails.verify_status_crypto.id)">
                        {{ leadDetails.verify_status_crypto.name }}
                    </span>
                </template>
                <template v-else> N/A </template>
            </span>
        </div>
        <div class="details_info">
            <span class="details_category">
                Campaign
            </span>

            <span
                class="
                    details_value
                    details_value--capitalize
                    details_value--no-margin
                "
            >
                {{ leadDetails.campaign_name || 'N/A' }}
            </span>
        </div>

        <div class="details_info">
            <span class="details_category">
                Source
            </span>

            <span
                class="
                    details_value
                    details_value--capitalize
                    details_value--no-margin
                "
            >
                {{ leadDetails.campaign_source || 'N/A' }}
            </span>
        </div>

        <details-client-type />

        <details-group-type
            title="Status"
            group-type="complicatedness_id"
            :user="leadDetails.complicatedness"
        />

        <div class="details_info">
            <div class="details_info__accounts-wrap">
                <div class="d-flex flex-row align-items-center">
                    <span class="details_category">Live Accounts</span>

                    <div class="accs_amount ml-2 mt-1">
                        {{ isLiveAccountCounts }}
                    </div>
                </div>

                <div
                    v-if="!isClientStatusUnverified"
                    class="details_info__accounts"
                >
                    <div
                        v-for="(account, index) in leadDetails.live_accounts"
                        :key="index"
                        class="d-flex flex-row details_info-row"
                    >
                        <span class="details_value ml-0 pl-0">
                            <material-icon
                                class="mr-2"
                                symbol="info"
                                v-b-popover.hover.top="accountGroupFormatter(account.account_group)"
                            />
                            {{ account.platform_name }}
                        </span>

                        <span class="details_value">{{ account.account }}</span>
                    </div>
                </div>

                <div
                    v-if="isClientStatusUnverified"
                    class="details_value"
                >
                    Didn't complete questionnaire
                </div>
            </div>
        </div>

        <div class="details_info">
            <div class="details_info__accounts-wrap">
                <div class="d-flex flex-row align-items-center">
                    <span class="details_category">Demo Accounts</span>

                    <div class="accs_amount ml-2 mt-1">
                        {{ leadDetails.demo_accounts.length }}
                    </div>
                </div>

                <span
                    v-if="leadDetails.demo_accounts.length === 0"
                    class="details_value"
                >N/A</span>
                <div
                    v-else
                    class="details_info__accounts"
                >
                    <div
                        v-for="(account, index) in leadDetails.demo_accounts"
                        :key="index"
                        class="d-flex flex-row details_info-row"
                    >
                        <span class="details_value ml-0 pl-0">
                            <material-icon
                                class="mr-2"
                                symbol="info"
                                v-b-popover.hover.top="account.account_group"
                            />
                            {{ account.platform_name }}
                        </span>

                        <span class="details_value">{{ account.account }}</span>
                    </div>
                </div>
            </div>
        </div>

        <users-managers
            title="Sales Rep"
            :id-manager="leadManager.Sales"
            :user="leadDetails.user_sales"
            lead-name="sales_user_id"
        />

        <users-managers
            title="Retention Rep"
            :user="leadDetails.user_retention"
            lead-name="retention_user_id"
            :id-manager="leadManager.Retention"
        />

        <users-managers
            title="Customer Care"
            :user="leadDetails.user_customer_support"
            lead-name="customer_support_user_id"
            :id-manager="leadManager.CustomerSupport"
        />

        <div class="details_info">
            <span class="details_category">
                Total Deposits
            </span>

            <span class="details_value details_value--no-margin">
                &euro; {{ leadDetails.total_deposits || 0 }}
            </span>
        </div>

        <div class="details_info">
            <span class="details_category">
                Total Withdrawals
            </span>

            <span class="details_value details_value--no-margin">
                &euro; {{ leadDetails.total_withdrawals || 0 }}
            </span>
        </div>

        <div class="details_info">
            <span class="details_category">
                Pending Withdrawals
            </span>

            <span class="details_value details_value--no-margin">
                &euro; 0
            </span>
        </div>

        <div class="details_info">
            <span class="details_category">
                Equity In All Accounts
            </span>

            <span class="details_value details_value--no-margin">
                &euro; {{ leadDetails.total_equity || 0 }}
            </span>
        </div>

        <div class="details_info">
            <span class="details_category">
                F. Margin
            </span>

            <p class="details_value details_value--no-margin">
                &euro; {{ leadDetails.total_free_margin || 0 }}
            </p>
        </div>

        <div class="details_info">
            <span class="details_category">
                Top 3 Tradable Instruments
            </span>

            <span class="details_value details_value--no-margin">
                {{
                    formatInstruments(leadDetails.top_tradable_instruments)
                        || 'N/A'
                }}
            </span>
        </div>
    </div>
</template>

<script>
import leadDetailsMixin from '../../mixins/leadDetailsMixin';
import UsersManagers from './users-managers/users-managers.vue';
import detailsGroupType from './details-group-type/details-group-type.vue';
import detailsClientType from './client-type/client-type.vue';
import { LeadManagerEnum } from './enums/lead-details.enums';

export default {
    components: {
        UsersManagers,
        detailsGroupType,
        detailsClientType,
    },
    mixins: [
        leadDetailsMixin,
    ],
    data () {
        return {
            leadManager: LeadManagerEnum
        };
    },
    methods: {
        formatInstruments (instruments = []) {
            return instruments.map(item => item.symbol_display).join(', ');
        },
        accountGroupFormatter (value = '') {
            const arr = value.split('\\');
            if (arr.length > 3) {
                arr.splice(1, 2);
            }
            return arr.join('\\');
        }
    },
};
</script>
