import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '~/store/index';

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: 'WithdrawalMethods',
})
class WithdrawalMethods extends VuexModule {

    withdrawalMethodSelectDefault: Object = {
        name: 'Method',
        value: ''
    };

    withdrawalMethodSelected: Object | null = null;

    get getWithdrawalMethodSelected (): Object | null {
        return this.withdrawalMethodSelected;
    }

    get getWithdrawalMethodSelectDefault (): Object {
        return this.withdrawalMethodSelectDefault;
    }

    @Mutation
    mutationWithdrawalMethodSelected (payload: Object) {
        this.withdrawalMethodSelected = payload;
    }

    @Action ({ commit: 'mutationWithdrawalMethodSelected' })
    setWithdrawalMethodSelected (payload: Object) {
        return payload;
    }

}
export const WithdrawalMethodsModule = getModule(WithdrawalMethods);
