import Vue from 'vue';
import modalLayout from '~/components/modals/root-modal-layout/root-modal-layout.vue';
import modalsMixin from '~/components/mixins/modals';
import configsMixin from '~/components/mixins/configs-mixin';
import leadsFinanceTransactionMixin from '~/components/mixins/leads-finance-transaction-mixin';
import { WithdrawalDepositTabModule } from '~/store/modules/withdrawal-deposit-tab/withdrawal-deposit-tab';
import { LeadAccountsModule } from '~/store/modules/lead-accounts/lead-accounts';
import { PaymentRequisitesModule } from '~/store/modules/payment-requisites/payment-requisites';
import { ILeadAccount } from '~/store/modules/lead-accounts/lead-accounts-interfaces';
import { IConfig } from '~/settings/interfaces/config-default-interfaces';
import { EPaymentMethodName } from '~/settings/enums/payment-methods.enum';

export default Vue.extend({
    components: {
        modalLayout,
    },
    mixins: [
        modalsMixin,
        configsMixin,
        leadsFinanceTransactionMixin,
    ],
    data () {
        return {
            selectedType: null,
            account: null,
        };
    },
    computed: {
        isVisibleComment (): boolean {
            return this.isMethodsSelected?.id === EPaymentMethodName.wireTransfer
                || this.isMethodsSelected?.id === EPaymentMethodName.eWallet;
        },
        hasNotValidationForDisabled (): string | null {
            return this.isMethodTypeSelected && 'required';
        },
        isAccounts (): ILeadAccount[]  {
            return LeadAccountsModule.getAccounts;
        },
        isMethodTypeSelected (): boolean {
            return this.isMethodsSelected?.id;
        },
        isSourceListType (): IConfig[] {
            return this.isMethodsSelected?.id === EPaymentMethodName.eWallet
                ? this.getSourceEWallet : this.getSourceList;
        },
        isSourcePlaceholder (): string {
            return this.isMethodsSelected
            && this.isMethodsSelected.id === EPaymentMethodName.eWallet
                ? 'Email' : 'Source';
        },
        getSourceList (): IConfig[] {
            return PaymentRequisitesModule.getPaymentRequisites
                .filter(requisite => requisite.method_id === this.isMethodTypeSelected)
                .map(item  => {
                    return {
                        id: item.id,
                        name: item.card_mask || item.iban || item.crypto_wallet
                    };
                });
        },
        getSourceEWallet (): IConfig[] {
            return PaymentRequisitesModule.getPaymentRequisites
                .filter(requisite => requisite.provider?.id === this.eWalletSubtype?.id)
                .map(item  => {
                    return {
                        id: item.id,
                        name: item.email
                    };
                });
        },
    },

    methods: {
        handlerSelectType (selected: number): void {
            WithdrawalDepositTabModule.setActiveTab(selected);
        },
        clearSource (): void {
            this.isRequisiteDetails = null;
        },
    }
});
