<template>
    <div
        class="details_contacts__row client-detail-row"
        :class="{ 'details_contacts__row--open' : isOpen }"
    >
        <div class="details_category-img mr-3 ml-0">
            <material-icon
                symbol="language"
                color="grey"
                size="sm"
            />
        </div>
        <span
            v-if="!isOpen"
            class="details_category"
        >
            Country / Language
        </span>
        <div class="d-flex details_contacts__row-items">
            <template v-if="isOpen">
                <app-multi-select
                    v-model="selected"
                    class="mr-3"
                    :options="getConfigCountries"
                    track-by="id"
                    label="name"
                    searchable
                    :allow-empty="true"
                    :close-on-select="true"
                    :show-labels="false"
                    placeholder=""
                    no-margin
                    @onClear="clear"
                />
                <app-multi-select
                    v-model="selectedLanguage"
                    :options="getConfigLanguages"
                    track-by="A2"
                    label="name"
                    searchable
                    :allow-empty="true"
                    :close-on-select="true"
                    :show-labels="false"
                    placeholder=""
                    no-margin
                    @onClear="clear"
                />
            </template>
            <span
                v-else-if="isLoading"
                class="details_value"
            >Loading...</span>
            <span
                v-else
                v-b-popover.hover.top="`${residenceCountryName} / ${selectedLanguageName}`"
                class="details_value details_value--no-margin details_value--icon text-right"
            >
                <span
                    v-if="leadDetails.residence_country"
                    class="details_value__country"
                >
                    <img
                        class="details_value__icon-flag"
                        :src="`/img/flags/${leadDetails.residence_country.A2.toLowerCase()}.svg`"
                    >
                    {{ residenceCountryName }}
                </span>
                / {{ selectedLanguageName }}
            </span>
        </div>
        <div
            :class="[
                'd-flex flex-row align-items-center client-detail-row__buttons',
                isOpen ? 'client-detail-row__buttons--active' : ''
            ]"
        >
            <edit-btn
                v-if="!isOpen && isPermissionEditClientsInformation"
                @onEdit="handleClickEdit"
            />
            <edit-mode-btns
                v-if="isOpen"
                @onOk="confirm"
                @onCancel="restore"
            />
        </div>
    </div>
</template>

<script>
import appMultiSelect from '~/components/parts/app-multiselect/app-multiselect.vue';
import EditBtn from './parts/editBtn';
import EditModeBtns from './parts/editModeBtns';
import leadDetailsMixin from '../../mixins/leadDetailsMixin';
import toggleMixin from '../../mixins/toggleMixin';
import loadingMixin from '../../mixins/loadingMixin';
import fieldModelMixin from '../../mixins/modelMixins/fieldModelMixin';
import { NotificationPrefabricated } from '~/helpers/modal-notification-services/modal-notification-services';
import configsMixin from '~/components/mixins/configs-mixin';
import { isPermissionEditClientsInformation, } from '~/helpers/user-permission';

export default {
    components: {
        EditBtn,
        EditModeBtns,
        appMultiSelect
    },
    mixins : [
        toggleMixin,
        leadDetailsMixin,
        loadingMixin,
        fieldModelMixin,
        configsMixin,
    ],
    data () {
        return {
            selectedLanguage : null
        };
    },
    computed: {
        isPermissionEditClientsInformation,
        residenceCountryName () {
            return this.leadDetails.residence_country ? this.leadDetails.residence_country.name : '-';
        },
        selectedLanguageName () {
            return this.leadDetails.language ? this.leadDetails.language.name : '-';
        },
    },
    methods : {
        setCurrentLanguage () {
            this.selectedLanguage = this.leadDetails.language;
        },
        handleClickEdit () {
            this.setCurrentLanguage();
            this.toggleOpen();
        },
        confirm () {
            this.startLoading();
            this.hide();
            this.updateLeadDetails({
                residence_country_id : (this.selected ? this.selected.id : null),
                language_a2 : (this.selectedLanguage ? this.selectedLanguage.A2 : null),
            })
                .catch(error => {
                    NotificationPrefabricated.Error(error);
                })
                .finally(() => {
                    this.endLoading();
                    NotificationPrefabricated.KycCountrySuccess();
                    NotificationPrefabricated.KycLanguageSuccess();
                    // TODO
                    this.handleFetchDetails();
                });
        },
        clear () {
            this.clearSelected();
        },
        restore () {
            this.restoreSelected();
            this.hide();
        },
        getInitialSelected () {
            return this.leadDetails.residence_country;
        }
    }
};
</script>
