export enum EIconsSettingsAffiliateStatus {
    'schedule' = 1,
    'highlight_off' = 2,
    'task_alt' = 3,
}

export enum EIconsSettingsColor {
    'clock' = 'pending',
    'check-circle-pre' = 'preapproved',
    'x-circle' = 'red-bright',
    'check-circle' = 'green',
    'alert-triangle' = 'warning',
    'alert-circle' = 'red-bright',
}

export enum EIconsSettingsPaymentHistoryStatus {
    'schedule' = 1,
    'task_alt' = 2,
    'highlight_off' = 3,
    'add_task' = 4,
    'cancel' = 5,
    'warning' = 6,
}

export enum EIconAccountStatus { // Material icons
    'error' = 1,
    'schedule' = 2,
    'check_circle' = 3,
    'cancel' = 4,
}

export enum EIconAccountStatusColor { // Material icons
    'purple' = 1,
    'yellow' = 2,
    'green' = 3,
    'red' = 4,
}
