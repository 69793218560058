<template v-if="accounts?.length">
    <kyc-tab-subtitle
        :subtitle="`${title} (${isLiveAccountCounts}):`"
        class="accounts"
    >
        <b-row
            v-if="isClientStatusUnverified && isAccountsLive"
            class="single_account"
        >
            <div>Didn't complete questionnaire</div>
        </b-row>
        <template v-else>
            <b-row
                v-for="account in accounts"
                :key="account.account"
                class="single_account"
            >
                <b-col class="single_account__col">
                    <b-row class="single_account__row">
                        <div>
                            Account Number:
                        </div>
                        <div class="dotted" />
                        <div>
                            {{ account.account }}
                        </div>
                    </b-row>
                    <b-row class="single_account__row">
                        <div>
                            Currency:
                        </div>
                        <div class="dotted" />
                        <div>
                            {{ currency(account.account_group) }}
                        </div>
                    </b-row>
                    <b-row class="single_account__row">
                        <div>
                            Trading type:
                        </div>
                        <div class="dotted" />
                        <div v-if="account.profit_method_type">
                            {{ profitType(account.profit_method_type.id) }}
                        </div>
                    </b-row>
                    <b-row
                        v-if="account.leverage"
                        class="single_account__row"
                    >
                        <div>
                            Leverage:
                        </div>
                        <div class="dotted" />
                        <div>
                            {{ profitType(account.leverage) }}
                        </div>
                    </b-row>
                </b-col>
            </b-row>
        </template>
    </kyc-tab-subtitle>
</template>

<script>
import { mapGetters } from 'vuex';
import { names as clientKycNames } from '~/store/modules/client-kyc/client-kyc';
import configsMixin from '~/components/mixins/configs-mixin';
import KycTabSubtitle from './KycTabSubtitle';
import leadDetailsMixin from '../mixins/leadDetailsMixin';

export default {
    components: {
        KycTabSubtitle

    },
    mixins: [
        leadDetailsMixin,
        configsMixin,
    ],
    props: {
        title: {
            type: String,
            required: true
        },
        accountType: {
            type: String,
            required: true
        },
        isAccountsLive: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        accounts () {
            return this.leadDetails[this.accountType];
        },
        ...mapGetters({
            kycEntity: clientKycNames.getters.entity,
            kycLoading: clientKycNames.getters.load,
        }),
    },
    methods: {
        currency (group) {
            return group.split('-')[1];

        },
        profitType (typeId) {
            const foundType = this.getConfigAccountProfitMethodTypes.find(p => p.id === typeId);
            return foundType ? foundType.name : '';
        }
    },
};
</script>
