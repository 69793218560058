import { ITableHeaderColumn } from '~/components/tables/interfaces/table-header-column.interfaces';
import { ETableFilterType, ETableFilterInputType } from '~/components/tables/enums/table-filter-type.enum';
import { tableHeaderParser } from '~/components/tables/helpers/table-header-parser';

const withdrawalsHeader: ITableHeaderColumn[] = [
    {
        name: 'id',
        value: 'ID',
        id: 1,
        cellType: 'plain-text',
        cellData: 'id',
        filterType: ETableFilterType.input,
        inputType: ETableFilterInputType.number,
    },
    {
        name: 'account',
        value: 'Account Number',
        id: 2,
        cellType: 'plain-text',
        cellData: 'account',
        filterType: ETableFilterType.input,
        inputType: ETableFilterInputType.number,
    },
    {
        name: 'client_name',
        value: 'Name',
        id: 3,
        cellType: 'plain-text',
        cellData: 'client_name',
        filterType: ETableFilterType.input,
        inputType: ETableFilterInputType.text,
    },
    {
        name: 'status_ids',
        value: 'Transaction Status',
        id: 4,
        cellType: 'payment-status',
        cellData: 'status_id',
        filterType: ETableFilterType.select,
        filterMultiple: true,
    },
    {
        name: 'amount',
        value: 'Amount',
        id: 5,
        cellType: 'amount',
        cellData: ['amount', 'currency_id'],
        textAlign: 'right',
    },
    {
        name: 'type',
        value: 'Type',
        id: 6,
        cellType: 'plain-text',
        cellData: 'type',
        filterType: ETableFilterType.select,
    },
    {
        name: 'source',
        value: 'Source',
        id: 7,
        cellType: 'plain-text',
        cellData: 'source',
        filterType: ETableFilterType.input,
        inputType: ETableFilterInputType.text,
    },
    {
        name: 'comment',
        value: 'Platform Comment',
        id: 8,
        cellType: 'plain-text',
        cellData: 'platform_comment',
        filterType: ETableFilterType.input,
        inputType: ETableFilterInputType.text,
        cellClass: 'text-lowercase up-first-latter',
    },
    {
        name: 'operation',
        value: 'Operation',
        id: 13,
        cellType: 'plain-text',
        cellData: 'operation',
        filterType: ETableFilterType.select,
        textAlign: 'left',
    },
    {
        name: 'created_at',
        value: 'Creation Time',
        id: 9,
        cellType: 'date-time-cell',
        cellData: 'created_at',
        filterType: ETableFilterType.data,
        textAlign: 'center',
    },
    {
        name: 'updated_at',
        value: 'Last Updated',
        id: 10,
        cellType: 'date-time-cell',
        cellData: 'updated_at',
        filterType: ETableFilterType.data,
        textAlign: 'center',
    },
    {
        name: 'client_status_id',
        value: 'Client Status',
        id: 11,
        cellType: 'account-status',
        cellData: 'client_status_id',
        filterType: ETableFilterType.select,
    },
];

export const withdrawalsHistoryHeader: ITableHeaderColumn[] = tableHeaderParser([
    ...withdrawalsHeader,
]);

export const withdrawalsPendingHeader: ITableHeaderColumn[] = tableHeaderParser([
    ...withdrawalsHeader,
    {
        name: 'withdrawal-options',
        value: 'Options',
        id: 12,
        cellType: 'withdrawal-options',
        cellData: ['client_id', 'id', 'amount', 'currency_id'],
    }
]);
