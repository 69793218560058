import { NotificationsMessagesModule } from '~/store/modules/notifications/notifications';
import ipAddress from '~/components/tables/table-cells/ip-address/ip-address.vue';
import accountStatus from '~/components/tables/table-cells/account-status/account-status.vue';
import { NotificationsSubTitleEnum, EStatusNotification, EClassNotification } from '~/components/notifications/enums/notifications.enum';

export default {
    components: {
        ipAddress,
        accountStatus,
    },
    props: {
        notifications: {
            type: Array,
            required: true
        },
        title: {
            type: String,
            required: false
        },
        addClass: {
            type: String
        }
    },
    data (): Object {
        return {
            statusNotification: EStatusNotification
        };
    },
    computed: {
        isUnread (): boolean {
            return this.title == NotificationsSubTitleEnum.Unread;
        },
        unreadCount (): number {
            return this.notifications.length;
        },
        typeTr (): string {
            return this.isUnread ?
                EClassNotification.Unread :
                EClassNotification.Read;
        },
    },
    methods: {
        handleReading (notificationId: number, status: number): void {
            const query = this.$route.query;
            NotificationsMessagesModule.handleNotificationUpdate({ notificationId, status, query });
        },
    }
};
